import React from 'react';
import { AppContext } from '../../../context';


class GeoPage extends React.Component {     
    static contextType = AppContext;
       
    render(){
        return <></>;
    }
}


export default GeoPage;