import {faDownload, faEdit, faFastBackward, faFile, faPencilAlt, faPlus, faSave, faTimes, faTrash, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react';
import { Card, Col, FormControl, Row, Table } from 'react-bootstrap';
import { Button, Form as BSForm, FormGroup } from "react-bootstrap";
import moment from 'moment';
import Request from "../../../request";
import fileSize from 'filesize';
import AddAnexos from '../../AddAnexos';
import { AppContext } from '../../../context';

class DeclaracaoForm extends React.Component {

    static contextType = AppContext;    

    state = {
        tipobens: [],
        vinculos: [],
        razoes: [],
        formulario: ""
    }

    formatter = new Intl.NumberFormat('pt-Br', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
    });

    save(values, callback) {
        const url = this.context.config.BACKEND_URL + '/declaracao';
        Request('POST', url, this.context.token)
            .send(values)
            .then(res => {
                this.setState(() => ({ success: true }));
                this.context.addToast({ titulo: "Sucesso", conteudo: "Declaração gravada com sucesso." });
                this.context.setContent("DeclaracaoList");
            })
            .catch(err => {
                this.setState(() => ({ success: false }));
                this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na gravação do registro: " + err.toString() });
            })
            .finally(() => callback());
    }

    componentDidMount() {

        const errorHandler = err => this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na pesquisa de tipos." });

        Request('GET', this.context.config.BACKEND_URL + '/tipobem/options', this.context.token).send()
            .then(res => {
                this.setState(() => ({ tipobens: res.body }));
            })
            .catch(errorHandler);

        Request('GET', this.context.config.BACKEND_URL + '/vinculo/options', this.context.token).send()
            .then(res => {
                this.setState(() => ({ vinculos: res.body }));
            })            
            .catch(errorHandler);

        Request('GET', this.context.config.BACKEND_URL + '/razao/options', this.context.token).send()
            .then(res => {
                this.setState(() => ({ razoes: res.body }));
            })
            .catch(errorHandler);
    }

    render() {

        return (
            <Formik
                initialValues={{
                    Anexos: [],
                    exercicio: moment().format("YYYY"),
                    RazaoId: null,
                    UsuarioId: this.context.usuario.id,
                    Bens: [],
                }}
                validate={values => {
                    const errors = {};
                    if (!values.exercicio) {
                        errors.exercicio = 'Campo obrigatório';
                    }
                    if (!values.RazaoId) {
                        errors.RazaoId = 'Campo obrigatório';
                    }
                    if (values.Bens.some(bem => !bem.TipoId || !bem.VinculoId || !bem.descricao || bem.valor_anterior === '' || bem.valor_atual === '')) {
                        errors.Bens = "Preencha todos os campos da tabela";
                    }
                    if (values.Bens.length === 0 && this.state.formulario === 'formul'){
                        errors.Bens = "Clique em Adicionar Bem/Valor para incluir um item na lista";
                    }
                    if (values.Anexos.length === 0 && this.state.formulario === 'copia'){
                        errors.Anexos = "Clique em Adicionar Anexo para enviar a Cópia da Declaração de Bens do IR";
                    }

                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    if (window.confirm("Deseja realmente enviar os dados declarados? Não será possível editar os dados pós o envio.")){
                        this.save(values, () => setSubmitting(false));
                    } else setSubmitting(false);
                }}
            >
                {({ isSubmitting, values, setFieldValue, setValues }) => {
                    return (
                        <Form>    
                            <h3 className="d-flex justify-content-between">
                                <div>
                                    <FontAwesomeIcon icon={faPencilAlt} />  Cadastro da Declaração
                                </div>
                            </h3>
                            <hr />
                            <FormGroup className='mt-3'>
                                <FormGroup>
                                    <Row>
                                        <Col md={8}>
                                            <BSForm.Label as="b">Declarante<span className="date"></span></BSForm.Label>
                                            <FormControl type="text" value={this.context.usuario.nome} disabled />
                                        </Col>
                                        <Col md={4}>
                                            <BSForm.Label as="b">CPF<span className="date"></span></BSForm.Label>
                                            <FormControl type="text" value={this.context.usuario.login} disabled />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <BSForm.Label as="b">Órgão<span className="date"></span></BSForm.Label>
                                            <FormControl type="text" value={this.context.usuario.Orgao.nome} disabled />
                                        </Col>
                                        <Col md={6}>
                                            <BSForm.Label as="b">Setor<span className="date"></span></BSForm.Label>
                                            <FormControl type="text" value={this.context.usuario.Setor.nome} disabled />
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup>
                                    <Row className='mt-3'>
                                        <Col md="6" > 
                                            <BSForm.Label as="b">Exercício<span className="date"></span></BSForm.Label><ErrorMessage name="exercicio" component="span" className="text-danger small ml-2" />
                                            <Field type="number" name="exercicio" className="form-control" min="2000" max={moment().format("YYYY")} />
                                        </Col>
                                        <Col md="6">
                                            <BSForm.Label as="b">Razão da Declaração</BSForm.Label><ErrorMessage name="RazaoId" component="span" className="text-danger small ml-2" />
                                            <Field as="select" name="RazaoId" className="form-control">
                                                <option value="">Selecione...</option>
                                                {this.state.razoes.map((razao, key) => <option key={key} value={razao.value}>{razao.label}</option>)}
                                            </Field>
                                        </Col>
                                    </Row>
                                </FormGroup>                         
                            </FormGroup>         
                                {this.state.formulario === ""?
                            <FormGroup className='mt-3'>                         
                                <h4>Como deseja enviar sua declaração?</h4>
                                <div className='mt-3' as="b">                                            
                                    <Button type="button" variant='primary' onClick={() => this.setState(()=>({formulario: "copia"}))} className="mr-2 form-control">
                                        <FontAwesomeIcon icon={faUpload} /> Enviar Cópia da Declaração de Bens do IR
                                    </Button>
                                </div>
                                <div className='mt-3'>                                            
                                    <Button type="button" variant='info' onClick={() => this.setState(()=>({formulario: "formul"}))} className="mr-2 form-control">
                                        <FontAwesomeIcon icon={faEdit} /> Preencher Formulário Online 
                                    </Button>
                                </div>  
                                <div className='mt-3'>                                            
                                    <Button type="button" variant='warning' onClick={() => this.setState(()=>({formulario: "vazio"}))} className="mr-2 form-control">
                                        <FontAwesomeIcon icon={faTimes} /> Não Possuo Bens ou Valores a Declarar 
                                    </Button>
                                </div>  
                            </FormGroup> : (
                                <>
                                {this.state.formulario === "copia"?                                                                                             
                                        <Table striped size="sm" responsive className='my-2'>                                            
                                            <thead className='bg-light'>
                                                <tr>
                                                    <th>Nome</th>
                                                    <th style={{minWidth: "1%"}}>Data</th>
                                                    <th style={{width: 120}}>Tamanho</th>
                                                    <th style={{width: 120, textAlign: 'center'}}>Ações</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {values.Anexos.filter(anexo => !anexo.deleted).map((anexo, key) => 
                                                <tr key={key}>
                                                    <td>
                                                        {anexo.nome}
                                                    </td>
                                                    <td style={{whiteSpace: 'nowrap'}}>
                                                        {moment(anexo.updatedAt).locale('pt-br').calendar()}
                                                    </td>
                                                    <td>
                                                        {fileSize(anexo.tamanho, {locale: 'pt', round: 0})}
                                                    </td>
                                                    <td className='text-center'>
                                                        {anexo.id?
                                                        <Button as="a" href={`${this.context.config.BACKEND_URL}/anexo/declaracao/${anexo.id}/${anexo.hash}/${anexo.nome}`} download={anexo.nome} size="sm"  variant='info' title='Download' className='mr-2'>
                                                            <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                                                        </Button> : null}
                                                        <Button size="sm" type='button' variant='danger' title='Excluir'
                                                            onClick={() => window.confirm("Deseja realmente excluir este arquivo?") &&  setFieldValue('Anexos', [])}
                                                        >
                                                            <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
                                                        </Button>
                                                    </td>
                                                </tr>
                                                )}
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td colspan='4'>
                                                        {values.Anexos.length === 0?
                                                        <AddAnexos
                                                            multiple={false}
                                                            onError={file => this.context.addToast({titulo: "Erro", conteudo: "Falha ao carregar o arquivo "+file.name})}
                                                            onLoad={anexos => setFieldValue('Anexos', [...values.Anexos, ...anexos])}
                                                        />:
                                                        null}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="4">
                                                        <ErrorMessage name="Anexos" component="span" className="text-danger small ml-2" />
                                                    </td>
                                                </tr>
                                            </tfoot>                                                                               
                                        </Table>
                                        
                                : (
                                    this.state.formulario === "formul"?
                                    <Card className='mt-3'>
                                    <Card.Body eventKey="formulario" title={
                                        <div title="formulario">
                                            <FontAwesomeIcon icon={faFile} /> <span className="d-none d-lg-inline">Formulário</span>
                                        </div>
                                        }>
                                            <FormGroup>
                                                <Table className='my-2'>
                                                    <thead className='bg-light'>
                                                        <tr>
                                                            <th style={{ width: '200px' }}>Tipo</th>
                                                            <th style={{ width: '200px' }}>Proprietário</th>
                                                            <th>Descrição</th>
                                                            <th style={{ width: '1%', whiteSpace: 'nowrap' }}>Valor {`em Dez/${values.exercicio - 1}`}</th>
                                                            <th style={{ width: '1%', whiteSpace: 'nowrap' }}>Valor {`em Dez/${values.exercicio}`}</th>
                                                            <th style={{ width: "1%", textAlign: 'center' }}>Ações</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {values.Bens.length > 0 ? values.Bens.map((bem, key) =>
                                                            <tr key={key}>
                                                                <td>
                                                                    <Field as="select" name={`Bens[${key}].TipoId`} className="form-control">
                                                                        <option value="">Selecione...</option>
                                                                        {this.state.tipobens.map((tipobem, key) => <option key={key} value={tipobem.value}>{tipobem.label}</option>)}
                                                                    </Field>
                                                                </td>
                                                                <td>
                                                                    <Field as="select" name={`Bens[${key}].VinculoId`} className="form-control">
                                                                        <option value="">Selecione...</option>
                                                                        {this.state.vinculos.map((vinculo, key) => <option key={key} value={vinculo.value}>{vinculo.label}</option>)}
                                                                    </Field>
                                                                </td>
                                                                <td>
                                                                    <Field className="form-control" type="text" name={`Bens[${key}].descricao`} />
                                                                </td>
                                                                <td>
                                                                    <Field className="form-control" type="number" min={0} step={0.01} name={`Bens[${key}].valor_anterior`} />
                                                                </td>
                                                                <td>
                                                                    <Field className="form-control" type="number" min={0} step={0.01} name={`Bens[${key}].valor_atual`} />
                                                                </td>

                                                                <td className='text-center'>
                                                                    <Button type="button" variant="danger" size="sm" onClick={() => window.confirm("Deseja realmente excluir este item?") && setFieldValue("Bens", values.Bens.filter(b => b !== bem))}>
                                                                        <FontAwesomeIcon icon={faTrash} />
                                                                    </Button>
                                                                </td>
                                                            </tr>
                                                        ) :
                                                            <tr>
                                                                <td colSpan={6} className="text-center">
                                                                    <b>Nenhum bem ou valor declarado.</b>
                                                                </td>
                                                            </tr>}
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <th colSpan={3}>
                                                                Total
                                                            </th>
                                                            <th>
                                                                {this.formatter.format(values.Bens.reduce((total, bem) => total + (Number.isNaN(Number.parseFloat(bem.valor_anterior)) ? 0 : Number.parseFloat(bem.valor_anterior)), 0))}
                                                            </th>
                                                            <th>
                                                                {this.formatter.format(values.Bens.reduce((total, bem) => total + (Number.isNaN(Number.parseFloat(bem.valor_atual)) ? 0 : Number.parseFloat(bem.valor_atual)), 0))}
                                                            </th>
                                                            <th style={{ whiteSpace: 'nowrap' }}>
                                                                {values.Bens.length} itens.
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={6}>
                                                                <Button type="button" variant='success' className="form-control btn btn-success" onClick={() => setFieldValue("Bens", [...values.Bens, {}])}>
                                                                    <FontAwesomeIcon icon={faPlus} />&nbsp;
                                                                    Adicionar Bem/Valor
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    </tfoot>
                                                </Table>
                                                <ErrorMessage name="Bens" component="span" className="text-danger small ml-2" />
                                            </FormGroup>
                                    </Card.Body>
                                </Card> :
                                <p>
                                    O Agente público estadual subscritor do presente instrumento, ciente dos termos do
                                    Decreto Estadual nº. 1.712, de 12 de julho de 2021, declara para os devidos fins que
                                    não possui bens ou direitos a informar que constem em seu nome ou de seu cônjuge/companheiro(a) e dependentes.
                                </p>
                                )}
                                    <FormGroup className="mt-5 d-flex justify-content-between">
                                        <Button variant='secondary' onClick={() => this.setState(() => ({formulario: ""}), (() => setValues({...values, Anexos: [],Bens: [],})))}>
                                            <FontAwesomeIcon icon={faFastBackward} /> Voltar
                                        </Button>
                                        <Button type="submit" disabled={isSubmitting} variant='primary'>
                                            <FontAwesomeIcon icon={faSave} />&nbsp;
                                            Gravar Alterações
                                        </Button>
                                    </FormGroup>
                                </>
                            )}

                       </Form>
                    )
                }}
            </Formik >
        );
    }
}

export default DeclaracaoForm;
