import React from 'react';
import ApostilamentoForm from './ApostilamentoForm';
import ModelList from '../ModelList';
import { AppContext } from '../../../context';

class ApostilamentoList extends React.Component {    

    static contextType = AppContext;

    render(){
        return (
            <ModelList 
                url={this.context.config.BACKEND_URL + '/apostilamento'}
                title="Lista de Apostilamentos"
                modelName="apostilamento"
                modelIcon="faFolderOpen"
                modelForm={ApostilamentoForm}
                modalSize="lg"
                useAdd={true}
            />
        );
    }
}

export default ApostilamentoList;

