import { faBell, faBullhorn, faClipboardList, faCalculator, faChartLine, faDownload, faFile, faPencilAlt, faSave, faTimes, faTrash, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import { Col, Row, Tab, Table, Tabs } from 'react-bootstrap';
import { Button, Form as BSForm, FormGroup } from "react-bootstrap";
import Request from "../../../request";
import CustomEditor from '../../Editor';
import moment from 'moment';
import filesize from 'filesize';
import 'moment/locale/pt-br';
import AddAnexos from '../../AddAnexos';
import { AppContext } from '../../../context';

class AcaoRelatorio extends React.Component {

    static contextType = AppContext;

    state = {
        showUpload: false,
        uploadProgress: 0,
        uploaded: 0,
        loading: false,
    }

    uploadRef = React.createRef();

    save(values, callback) {
        Request('PUT', this.context.config.BACKEND_URL + '/acao/relatorio/' + this.props.acao.id, this.context.token)
            .send(values)
            .then(res => {
                this.context.addToast({ titulo: "Successo", conteudo: "Registro atualizado com sucesso." });
                Request('GET', this.context.config.BACKEND_URL + '/operacao/dashboard/' + this.props.acao.OperacaoId, this.context.token)
                    .then(res => {
                        //Upload dos anexos
                        this.context.setContent("OperacaoDashboard", { dashboard: res.body });
                    })
                    .catch(err => {
                        this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na recuperação do registro: " + err.toString() });
                    })
            })
            .catch(err => {
                this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na gravação do registro: " + err.toString() });
            })
            .finally(() => {
                callback();
            });
    }

    cancel() {
        Request('GET', this.context.config.BACKEND_URL + '/operacao/dashboard/' + this.props.acao.OperacaoId, this.context.token)
            .then(res => {
                this.context.setContent("OperacaoDashboard", { dashboard: res.body });
            })
            .catch(err => {
                this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na recuperação do registro: " + err.toString() });
            });
    }

    render() {

        const datas = (() => {
            const data = moment(this.props.acao.data_inicial).utcOffset(0).set({ h: 0, m: 0, s: 0 });
            const fim = moment(this.props.acao.data_final).utcOffset(0).set({ h: 0, m: 0, s: 0 });
            const datas = [];
            do {
                datas.push(data.clone());
                data.add(1, 'days');
            } while (data <= fim)
            return datas;
        })();



        return (
            <Formik
                initialValues={{
                    Medicoes: this.props.acao.Cidades.map(
                        cidade => this.props.acao.Operacao.Indicadores.map(
                            indicador => datas.map(
                                data => this.props.acao.Medicoes.find(
                                    medicao =>
                                        medicao.CidadeId === cidade.id &&
                                        medicao.IndicadorId === indicador.id &&
                                        medicao.data === data.format("YYYY-MM-DD")
                                ) ?? {
                                    id: null,
                                    AcaoId: this.props.acao.id,
                                    IndicadorId: indicador.id,
                                    CidadeId: cidade.id,
                                    data: data.format("YYYY-MM-DD"),
                                    valor: 0
                                }
                            ))),
                    Efetivos: this.props.acao.Cidades.map(
                        cidade => this.props.acao.Participantes.map(
                            participante => this.props.acao.Efetivos.find(
                                efetivo =>
                                    efetivo.OrgaoId === participante.id &&
                                    efetivo.CidadeId === cidade.id
                            ) ?? {
                                id: null,
                                AcaoId: this.props.acao.id,
                                OrgaoId: participante.id,
                                CidadeId: cidade.id,
                                Orgao: { nome: participante.nome },
                                ordinario: 0,
                                extraordinario: 0,
                                externo: 0,
                            }
                        )),
                    Inventarios: this.props.acao.Cidades.map(
                        cidade => this.props.recursosDisponiveis.map(
                            recurso => this.props.acao.Inventarios.find(
                                inventario =>
                                    inventario.RecursoId === recurso.id &&
                                    inventario.CidadeId === cidade.id
                            ) ?? {
                                id: null,
                                AcaoId: this.props.acao.id,
                                RecursoId: recurso.id,
                                CidadeId: cidade.id,
                                Recurso: { nome: recurso.nome },
                                total: 0
                            }
                        )),
                    Anexos: this.props.acao.Anexos ?? [],
                    atividades: this.props.acao.atividades,
                    resultados: this.props.acao.resultados,
                    sugestoes: this.props.acao.sugestoes
                }}
                validateOnChange={false}
                validateOnBlur={true}
                validate={values => {
                    return false;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    this.save(values, () => setSubmitting(false));
                }}
            >
                {({ isSubmitting, values, setFieldValue, handleChange }) => {
                    return (
                        <Form>
                            <h3 className="d-flex justify-content-between">
                                <div>
                                    <FontAwesomeIcon icon={faPencilAlt} />  Relatório da Ação {this.props.acao.nome}
                                </div>
                                <div>
                                    <Button type="button" variant='secondary' onClick={() => this.cancel()} className="mr-2">
                                        <FontAwesomeIcon icon={faTimes} />&nbsp;
                                        Cancelar
                                    </Button>
                                    <Button type="submit" disabled={isSubmitting} variant='primary'>
                                        <FontAwesomeIcon icon={faSave} />&nbsp;
                                        Gravar Alterações
                                    </Button>
                                </div>
                            </h3>
                            <hr />
                            <Tabs defaultActiveKey="dados-gerais">
                                <Tab eventKey="dados-gerais" title={
                                    <div title="Dados Gerais">
                                        <FontAwesomeIcon icon={faPencilAlt} /> <span className="d-none d-lg-inline">Dados Gerais</span>
                                    </div>
                                } className="my-2">
                                    <FormGroup>
                                        <Row>
                                            <Col>
                                                <BSForm.Label as="b">Operação</BSForm.Label>
                                                <Field className="form-control" disabled value={this.props.acao.Operacao.nome} />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row>
                                            <Col>
                                                <BSForm.Label as="b">Ação</BSForm.Label>
                                                <Field className="form-control" disabled value={this.props.acao.nome} />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row>
                                            <Col>
                                                <BSForm.Label as="b">Órgão Líder</BSForm.Label>
                                                <Field className="form-control" disabled value={this.props.acao.Lider.nome} />
                                            </Col>
                                            <Col>
                                                <BSForm.Label as="b">Região de Integração</BSForm.Label>
                                                <Field className="form-control" disabled value={this.props.acao.Risp.nome} />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row>
                                            <Col>
                                                <BSForm.Label as="b">Data Inicial</BSForm.Label>
                                                <Field className="form-control" disabled value={moment(this.props.acao.data_inicial).utcOffset(0).format("DD/MM/YYYY HH:mm")} />
                                            </Col>
                                            <Col>
                                                <BSForm.Label as="b">Data Final</BSForm.Label>
                                                <Field className="form-control" disabled value={moment(this.props.acao.data_final).utcOffset(0).format("DD/MM/YYYY HH:mm")} />
                                            </Col>
                                            <Col>
                                                <BSForm.Label as="b">Execução</BSForm.Label>
                                                <Field className="form-control" disabled value={this.props.acao.execucao} />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Tab>
                                <Tab eventKey="medicoes" title={
                                    <div title="Medições">
                                        <FontAwesomeIcon icon={faCalculator} /> <span className="d-none d-lg-inline">Medições</span>
                                    </div>
                                }>
                                    <Tabs className="mt-2">
                                        {this.props.acao.Cidades.map((cidade, cidadeKey) =>
                                            <Tab eventKey={cidade.nome} title={cidade.nome} key={cidadeKey}>
                                                <Table striped size="sm" className='mt-2 sticky'>
                                                    <thead className='bg-light'>
                                                        <tr>
                                                            <th className='sticky bg-light'>Indicador</th>
                                                            {datas.map((data, key) => <th key={key} className="text-center sticky bg-light"><small>{data.format("DD/MM/YYYY")}</small></th>)}
                                                            <th style={{ width: "120px" }} className='sticky bg-light text-center'>Total</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.props.acao.Operacao.Indicadores.map((indicador, indicadorKey) =>
                                                            <tr key={indicadorKey}>
                                                                <td style={{ whiteSpace: 'nowrap' }}>{indicador.nome}</td>
                                                                {values.Medicoes[cidadeKey][indicadorKey].map((medicao, dataKey) =>
                                                                    <td key={dataKey}>
                                                                        <Field className="form-control" type="number" min="0" step="1"
                                                                            name={`Medicoes[${cidadeKey}][${indicadorKey}][${dataKey}].valor`}
                                                                            value={medicao.valor}
                                                                            onChange={event => setFieldValue(`Medicoes[${cidadeKey}][${indicadorKey}][${dataKey}].valor`, Number.isNaN(parseFloat(event.target.value)) ? 0 : parseFloat(event.target.value))} />
                                                                    </td>
                                                                )}
                                                                <td>
                                                                    <input className="form-control" disabled value={
                                                                        values.Medicoes[cidadeKey][indicadorKey].reduce((sum, medicao) => sum + parseFloat(medicao.valor), 0)
                                                                    } />
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                    <tfoot className='bg-light'>
                                                        <tr>
                                                            <th>Total</th>
                                                            {datas.map((data, key) =>
                                                                <th key={key}>
                                                                    <input className="form-control" disabled value={
                                                                        values.Medicoes[cidadeKey].reduce((sum, medicoes) => sum + medicoes.reduce((sum, medicao) => medicao.data === data.format("YYYY-MM-DD") ? sum + parseFloat(medicao.valor) : sum, 0), 0)
                                                                    } />
                                                                </th>)}
                                                            <th>
                                                                <input className="form-control" disabled value={
                                                                    values.Medicoes[cidadeKey].reduce((sum, medicoes) => sum + medicoes.reduce((sum, medicao) => sum + parseFloat(medicao.valor), 0), 0)
                                                                } />
                                                            </th>
                                                        </tr>
                                                    </tfoot>
                                                </Table>
                                            </Tab>
                                        )}
                                    </Tabs>
                                </Tab>
                                <Tab eventKey="efetivo" title={
                                    <div title="Efetivo">
                                        <FontAwesomeIcon icon={faUsers} /> <span className="d-none d-lg-inline">Efetivo</span>
                                    </div>
                                }>
                                    <Tabs className="mt-2">
                                        {this.props.acao.Cidades.map((cidade, cidadeKey) =>
                                            <Tab eventKey={cidade.nome} title={cidade.nome} key={cidadeKey}>
                                                <Table striped size="sm" responsive className='mt-2'>
                                                    <thead className='bg-light sticky'>
                                                        <tr>
                                                            <th className='bg-light sticky'>Órgão</th>
                                                            <th style={{ width: "120px" }} className='bg-light sticky text-center'>Ordinário</th>
                                                            <th style={{ width: "120px" }} className='bg-light sticky text-center'>Extraordinário</th>
                                                            <th style={{ width: "120px" }} className='bg-light sticky text-center'>Externo</th>
                                                            <th style={{ width: "120px" }} className="bg-light sticky text-center">Total</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {values.Efetivos[cidadeKey].map((efetivo, key) =>
                                                            <tr key={key}>
                                                                <td style={{ whiteSpace: 'nowrap' }}>{efetivo.Orgao.nome}</td>
                                                                <td>
                                                                    <Field className="form-control" type="number" min="0" step="1"
                                                                        name={`Efetivos[${cidadeKey}][${key}].ordinario`} />
                                                                </td>
                                                                <td>
                                                                    <Field className="form-control" type="number" min="0" step="1"
                                                                        name={`Efetivos[${cidadeKey}][${key}].extraordinario`} />
                                                                </td>
                                                                <td>
                                                                    <Field className="form-control" type="number" min="0" step="1"
                                                                        name={`Efetivos[${cidadeKey}][${key}].externo`} />
                                                                </td>
                                                                <td>
                                                                    <input className="form-control" disabled value={
                                                                        parseInt(efetivo.ordinario) + parseInt(efetivo.extraordinario) + parseInt(efetivo.externo)
                                                                    } />
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                    <tfoot className='bg-light'>
                                                        <tr>
                                                            <th>Total</th>
                                                            <th>
                                                                <input className="form-control" disabled value={
                                                                    values.Efetivos[cidadeKey].reduce((sum, participante) => sum + parseInt(participante.ordinario), 0)
                                                                } />
                                                            </th>
                                                            <th>
                                                                <input className="form-control" disabled value={
                                                                    values.Efetivos[cidadeKey].reduce((sum, participante) => sum + parseInt(participante.extraordinario), 0)
                                                                } />
                                                            </th>
                                                            <th>
                                                                <input className="form-control" disabled value={
                                                                    values.Efetivos[cidadeKey].reduce((sum, participante) => sum + parseInt(participante.externo), 0)
                                                                } />
                                                            </th>
                                                            <th>
                                                                <input className="form-control" disabled value={
                                                                    values.Efetivos[cidadeKey].reduce((sum, participante) => sum + parseInt(participante.ordinario) + parseInt(participante.extraordinario) + parseInt(participante.externo), 0)
                                                                } />
                                                            </th>
                                                        </tr>
                                                    </tfoot>
                                                </Table>
                                            </Tab>
                                        )}
                                    </Tabs>
                                </Tab>
                                <Tab eventKey="Inventario" title={
                                    <div title="Inventário">
                                        <FontAwesomeIcon icon={faClipboardList} /> <span className="d-none d-lg-inline">Inventário</span>
                                    </div>
                                }>
                                    <Tabs className="mt-2">
                                        {this.props.acao.Cidades.map((cidade, cidadeKey) =>
                                            <Tab eventKey={cidade.nome} title={cidade.nome} key={cidadeKey}>
                                                <Table striped size="sm" responsive className='mt-2'>
                                                    <thead className='bg-light'>
                                                        <tr>
                                                            <th className='bg-light sticky'>Recurso</th>
                                                            <th style={{ width: "120px" }} className='bg-light sticky text-center'>Total</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {values.Inventarios[cidadeKey].map((inventario, key) =>
                                                            <tr key={key}>
                                                                <td style={{ whiteSpace: 'nowrap' }}>{inventario.Recurso.nome}</td>
                                                                <td>
                                                                    <Field className="form-control" type="number" min="0" step="1"
                                                                        name={`Inventarios[${cidadeKey}][${key}].total`} />
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                    <tfoot className='bg-light'>
                                                        <tr>
                                                            <th>Total</th>
                                                            <th>
                                                                <input className="form-control" disabled value={
                                                                    values.Inventarios[cidadeKey].reduce((sum, inventario) => sum + parseInt(inventario.total), 0)
                                                                } />
                                                            </th>
                                                        </tr>
                                                    </tfoot>
                                                </Table>
                                            </Tab>
                                        )}
                                    </Tabs>
                                </Tab>
                                <Tab eventKey="atividades" title={
                                    <div title="Atividades">
                                        <FontAwesomeIcon icon={faBell} /> <span className="d-none d-lg-inline">Atividades</span>
                                    </div>
                                }>
                                    <Row className='mt-2'>
                                        <Col>
                                            <CustomEditor
                                                name="atividades"
                                                value={values.atividades}
                                                setFieldValue={setFieldValue}
                                            />
                                        </Col>
                                    </Row>
                                </Tab>
                                <Tab eventKey="resultados" title={
                                    <div title="Resultados">
                                        <FontAwesomeIcon icon={faChartLine} /> <span className="d-none d-lg-inline">Resultados</span>
                                    </div>
                                }>
                                    <Row className='mt-2'>
                                        <Col>
                                            <CustomEditor
                                                name="resultados"
                                                value={values.resultados}
                                                setFieldValue={setFieldValue}
                                            />
                                        </Col>
                                    </Row>
                                </Tab>
                                <Tab eventKey="sugestoes" title={
                                    <div title="Sugestões">
                                        <FontAwesomeIcon icon={faBullhorn} /> <span className="d-none d-lg-inline">Sugestões</span>
                                    </div>
                                }>
                                    <Row className='mt-2'>
                                        <Col>
                                            <CustomEditor
                                                name="sugestoes"
                                                value={values.sugestoes}
                                                setFieldValue={setFieldValue}
                                            />
                                        </Col>
                                    </Row>
                                </Tab>
                                <Tab eventKey="anexos" title={
                                    <div title="Anexos">
                                        <FontAwesomeIcon icon={faFile} /> <span className="d-none d-lg-inline">Anexos</span>
                                    </div>
                                }>
                                    <Table striped size="sm" responsive className='my-2'>
                                        <thead className='bg-light'>
                                            <tr>
                                                <th>Nome</th>
                                                <th style={{ minWidth: "1%" }}>Data</th>
                                                <th style={{ width: 120 }}>Tamanho</th>
                                                <th style={{ width: 120, textAlign: 'center' }}>Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {values.Anexos.filter(anexo => !anexo.deleted).map((anexo, key) =>
                                                <tr key={key}>
                                                    <td>
                                                        {anexo.nome}
                                                    </td>
                                                    <td style={{ whiteSpace: 'nowrap' }}>
                                                        {moment(anexo.updatedAt).locale('pt-br').calendar()}
                                                    </td>
                                                    <td>
                                                        {filesize(anexo.tamanho, { locale: 'pt', round: 0 })}
                                                    </td>
                                                    <td className='text-center'>
                                                        {anexo.id ?
                                                            <Button as="a" href={`${this.context.config.BACKEND_URL}/anexo/acao/${anexo.id}/${anexo.hash}/${anexo.nome}`} download={anexo.nome} size="sm" variant='info' title='Download' className='mr-2'>
                                                                <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                                                            </Button> : null}
                                                        <Button size="sm" type='button' variant='danger' title='Excluir'
                                                            onClick={() => window.confirm("Deseja realmente excluir este arquivo?") && setFieldValue('Anexos', values.Anexos.map(a =>
                                                                a === anexo ? { ...a, deleted: true } : a
                                                            ))}
                                                        >
                                                            <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
                                                        </Button>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                    <AddAnexos
                                        onError={file => this.context.addToast({ titulo: "Erro", conteudo: "Falha ao carregar o arquivo " + file.name })}
                                        onLoad={anexos => setFieldValue('Anexos', [...values.Anexos, ...anexos])}
                                    />
                                </Tab>
                            </Tabs>
                        </Form>
                    )
                }}
            </Formik >
        );
    }
}

export default AcaoRelatorio;
