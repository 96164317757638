import React from "react";
import { AppContext } from "../../../context";
import ModelList from "../ModelList";
import AlertaEscolaForm from "./AlertaEscolaForm";

class AlertaEscolaList extends React.Component {
  static contextType = AppContext;
  render() {

    return (
      <ModelList 
      url={this.context.config.BACKEND_URL + "/alertaescola"}
      title="Lista de Alertas"
      modelName="alerta"
      modelIcon="faBullhorn"
      modelForm={AlertaEscolaForm}
      useAdd={false}
  />
       
    );
  }
}

export default AlertaEscolaList;
