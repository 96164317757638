import React from 'react';
import NaturezaForm from './NaturezaForm';
import ModelList from '../ModelList';
import { AppContext } from "../../../context";

class NaturezaList extends React.Component {   
    
    static contextType = AppContext;

    render(){
        return (
            <ModelList 
                url={this.context.config.BACKEND_URL + '/natureza'}
                title="Lista de Naturezas de Denúncias"
                modelName="natureza"
                modelIcon="faList"
                modelForm={NaturezaForm}
                useAdd={true}
            />
        );
    }
}

export default NaturezaList;