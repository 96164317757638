import React from "react";
import {  Card, Col, Container, Row } from "react-bootstrap";
import moment from "moment";
import Request from "../../../request";
import { AppContext } from "../../../context";

class EleicoesFrontPage extends React.Component {

  static contextType = AppContext;

  state = {
    eleicoes: [],
  };

  componentDidMount() {
    Request("GET", this.context.config.BACKEND_URL + "/eleicoes/eleicao", this.context.token)
      .then((res) => {
        this.setState({
          eleicoes: res.body.map((eleicao) => {
            const agora = moment();
            const fim_inscricao = moment(eleicao.fim_inscricao);
            const inicio_inscricao = moment(eleicao.inicio_inscricao);
            const fim_votacao = moment(eleicao.fim_votacao);
            return {
              ...eleicao,
              andamento: agora < fim_votacao,
              aberta: agora < fim_inscricao && agora > inicio_inscricao,
            };
          }),
        });
      })
      .catch((err) => {
        this.context.addToast({
          titulo: "Erro",
          conteudo:
            "Erro na recuperação da lista de Eleições: " + err.toString(),
        });
      });
  }

  render() {
    const andamento = this.state.eleicoes.filter(({ andamento }) => andamento);
    const finalizada = this.state.eleicoes.filter(
      ({ andamento }) => !andamento
    );

    return (
      <Container fluid="lg">
        <Row className="pb-5">
          <Col lg={6}>
            <h3 className="mt-3 px-3 py-3 text-center">
              ELEIÇÕES EM ANDAMENTO
            </h3>
            {(andamento.length > 0 &&
              andamento.map((eleicao, key) => (
                <Card key={key}>
                  <Card.Header>{eleicao.nome}</Card.Header>
                  <Card.Body>
                    <ul>
                      <li>
                        Inscrições:
                        <ul>
                          <li>Início: {moment(eleicao.inicio_inscricao).format(
                            "DD/MM/YYYY [às] HH:mm"
                          )}
                          </li>
                          <li>Fim: {moment(eleicao.fim_inscricao).format(
                            "DD/MM/YYYY [às] HH:mm"
                          )}
                          </li>
                        </ul>

                      </li>
                      <li>
                        Votação:
                        <ul>
                          <li>Início: {moment(eleicao.inicio_votacao).format(
                            "DD/MM/YYYY [às] HH:mm"
                          )}
                          </li>
                          <li>Fim: {moment(eleicao.fim_votacao).format(
                            "DD/MM/YYYY [às] HH:mm"
                          )}
                          </li>
                        </ul>

                      </li>
                    </ul>
                  </Card.Body>                  
                </Card>
              ))) || (
                <h5 className="text-center mt-3">Nenhuma eleição em andamento</h5>
              )}
          </Col>
          <Col lg={6}>
          <h3 className="mt-3 px-3 py-3 text-center">
              ELEIÇÕES FINALIZADAS
            </h3>

            {(finalizada.length > 0 &&
              finalizada.map((eleicao, key) => (
                <Card key={key}>
                  <Card.Header>{eleicao.nome}</Card.Header>
                  <Card.Body>
                    <ul>
                      <li>
                        Inscrições:
                        <ul>
                          <li>Início: {moment(eleicao.inicio_inscricao).format(
                            "DD/MM/YYYY [às] HH:mm"
                          )}
                          </li>
                          <li>Fim: {moment(eleicao.fim_inscricao).format(
                            "DD/MM/YYYY [às] HH:mm"
                          )}
                          </li>
                        </ul>

                      </li>
                      <li>
                        Votação:
                        <ul>
                          <li>Início: {moment(eleicao.inicio_votacao).format(
                            "DD/MM/YYYY [às] HH:mm"
                          )}
                          </li>
                          <li>Fim: {moment(eleicao.fim_votacao).format(
                            "DD/MM/YYYY [às] HH:mm"
                          )}
                          </li>
                        </ul>

                      </li>
                    </ul>
                  </Card.Body>                  
                </Card>              
              ))) || (
                <h5 className="text-center mt-3">Nenhuma eleição finalizada</h5>
              )}
          </Col>
        </Row>        
      </Container>
    );
  }
}

export default EleicoesFrontPage;
