import React from 'react';
import SituacaoForm from './SituacaoForm';
import ModelList from '../ModelList';
import { AppContext } from '../../../context';

class SituacaoList extends React.Component {   
    
    static contextType = AppContext;

    render(){
        return (
            <ModelList 
                url={this.context.config.BACKEND_URL + '/situacao'}
                title="Lista de Situações"
                modelName="situacao"
                modelIcon="faList"
                modelForm={SituacaoForm}
                useAdd={true}
            />
        );
    }
}

export default SituacaoList;

