import React from 'react';
import { faHome, faBullhorn } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Col, Row } from 'react-bootstrap';
import { AppContext } from '../../../context';

class AlertaParaPage extends React.Component {

    static contextType = AppContext;

    render() {
        return this.context.usuario.Permissoes.some(p => p.nome === `alertapara_alerta`) &&
            <>
                <h3><FontAwesomeIcon icon={faHome} /> Página Inicial</h3>
                <hr />
                <Card>
                    <Card.Header>
                        Principais Tarefas
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col style={{ textAlign: 'center' }}>
                                <div role="button" onClick={() => this.context.setContent("AlertaPage")}><FontAwesomeIcon size='2x' icon={faBullhorn} /><br />Gerenciar Alertas</div>                        </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </>;
    }
}

export default AlertaParaPage;
