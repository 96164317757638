import React from "react";
import { Button, Card, Col, FormControl, Row, Table } from "react-bootstrap";
import {
  faBullhorn,
  faCubes,
  faEdit,
  faFastBackward,
  faPrint,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { AppContext } from "../../../context";

class BoletimPrint extends React.Component {
  static contextType = AppContext;

  render() {
    return (
      <div>
        <h3 className="text-center">
          <img src={this.props.boletim.Orgao.imagem} alt="logo" height="35px" />
          <br />
          {this.props.boletim.Orgao.nome}
          <br />
          Boletim de Atendimento n°&nbsp;
          {String(this.props.boletim.id).padStart(5, "0")}
        </h3>

        <h3 className="mt-5">
          <FontAwesomeIcon icon={faEdit} /> Dados do Atendimento
        </h3>
        <hr />
        <Row className="mt-1">
          <Col sm={8}>
            <strong>Nome do Solicitante</strong>

            <FormControl
              disabled={true}
              value={this.props.boletim.solicitante_nome}
            />
          </Col>
          <Col sm={4}>
            <strong>Registro do Solicitante</strong>
            <FormControl
              disabled={true}
              value={this.props.boletim.solicitante_registro}
            />
          </Col>
        </Row>
        <Row className="mt-1">
          <Col sm={3}>
            <strong>Data do Incidente</strong>
            <FormControl
              disabled={true}
              value={moment(this.props.boletim.incidente)
                .utcOffset(0)
                .format("DD/MM/YYYY HH:mm")}
            />
          </Col>
          <Col sm={3}>
            <strong>Data do Acionamento</strong>
            <FormControl
              disabled={true}
              value={moment(this.props.boletim.acionamento)
                .utcOffset(0)
                .format("DD/MM/YYYY HH:mm")}
            />
          </Col>

          <Col sm={3}>
            <strong>Início da Ação</strong>
            <FormControl
              disabled={true}
              value={moment(this.props.boletim.inicio)
                .utcOffset(0)
                .format("DD/MM/YYYY HH:mm")}
            />
          </Col>
          <Col sm={3}>
            <strong>Término da Ação</strong>
            <FormControl
              disabled={true}
              value={moment(this.props.boletim.termino)
                .utcOffset(0)
                .format("DD/MM/YYYY HH:mm")}
            />
          </Col>
        </Row>
        <Row className="mt-1">
          <Col sm={12}>
            <strong>Endereço da Ocorrência</strong>
            <FormControl
              as="textarea"
              disabled={true}
              value={this.props.boletim.endereco}
            />
          </Col>
        </Row>
        <Row className="mt-1">
          <Col sm={4}>
            <strong>Cidade</strong>
            <FormControl
              disabled={true}
              value={this.props.boletim.Cidade.nome}
            />
          </Col>
          <Col sm={4}>
            <strong>Visibilidade</strong>
            <FormControl
              disabled={true}
              value={this.props.boletim.visibilidade}
            />
          </Col>
          <Col sm={4}>
            <strong>Clima</strong>
            <FormControl disabled={true} value={this.props.boletim.clima} />
          </Col>
        </Row>

        <Row className="mt-1">
          <Col sm={4}>
            <strong>Origem da Ocorrência</strong>
            <FormControl
              disabled={true}
              value={this.props.boletim.Origem.descricao}
            />
          </Col>
          <Col sm={8}>
            <strong>Natureza da Ocorrência</strong>
            <FormControl
              disabled={true}
              value={this.props.boletim.Natureza.descricao}
            />
          </Col>
          <Col sm={8} className="mt-1">
            <strong>Ação Realizada</strong>
            <FormControl
              disabled={true}
              value={this.props.boletim.Acao.descricao}
            />
          </Col>
          <Col sm={4} className="mt-1">
            <strong>Tipo de Atuação</strong>

            <FormControl disabled={true} value={this.props.boletim.atuacao} />
          </Col>
        </Row>

        <Row className="mt-1">
          <Col>
            <strong>Descrição da Ocorrência</strong>
            <FormControl
              as="textarea"
              disabled={true}
              value={this.props.boletim.descricao}
            />
          </Col>
        </Row>

        <Row className="mt-1">
          <Col>
            <strong>Observações</strong>
            <FormControl
              disabled={true}
              as="textarea"
              value={this.props.boletim.observacao}
            />
          </Col>
        </Row>

        <h3 className="mt-5 mb-0">
          <FontAwesomeIcon icon={faUsers} /> Envolvidos
        </h3>
        <hr />
        {this.props.boletim.Envolvidos.map((envolvido, key) => (
          <Card key={key} className="mt-3 mb-0">
            <Card.Header>
              {envolvido.envolvimento} - {envolvido.nome}
            </Card.Header>
            <Card.Body className="pt-3">
              <Row>
                <Col sm={3}>
                  <strong>RG</strong>
                  <FormControl disabled={true} value={envolvido.rg} />
                </Col>
                <Col sm={3}>
                  <strong>Sexo</strong>
                  <FormControl disabled={true} value={envolvido.sexo} />
                </Col>
                <Col sm={3}>
                  <strong>Idade (anos)</strong>
                  <FormControl disabled={true} value={envolvido.idade} />
                </Col>
                <Col sm={3}>
                  <strong>Raça</strong>
                  <FormControl
                    disabled={true}
                    value={envolvido.Raca.descricao}
                  />
                </Col>
              </Row>
              <Row className="mt-1">
                <Col>
                  <strong>Endereço</strong>
                  <FormControl
                    as="textarea"
                    disabled={true}
                    value={envolvido.endereco}
                  />
                </Col>
              </Row>
              <Row className="mt-1">
                <Col sm={6}>
                  <strong>Contato</strong>
                  <FormControl disabled={true} value={envolvido.contato} />
                </Col>
                <Col sm={6}>
                  <strong>Integridade</strong>
                  <FormControl
                    disabled={true}
                    value={envolvido.Integridade.descricao}
                  />
                </Col>
              </Row>
              <Row className="mt-1">
                <Col sm={3}>
                  <strong>Destino</strong>
                  <FormControl
                    disabled={true}
                    value={envolvido.Destino.descricao}
                  />
                </Col>
                <Col sm={6}>
                  <strong>Unidade de Apresentação</strong>
                  <FormControl
                    disabled={true}
                    value={envolvido.apresentacao_unidade}
                  />
                </Col>
                <Col sm={3}>
                  <strong>Enquadramento</strong>
                  <FormControl
                    disabled={true}
                    value={envolvido.apresentacao_enquadramento}
                  />
                </Col>
              </Row>
              <Row className="mt-1">
                <Col sm={9}>
                  <strong>Nome da Autoridade</strong>
                  <FormControl
                    disabled={true}
                    value={envolvido.apresentacao_autoridade}
                  />
                </Col>
                <Col sm={3}>
                  <strong>Registro da Autoridade</strong>
                  <FormControl
                    disabled={true}
                    value={envolvido.apresentacao_registro}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        ))}
        {/* Apreensoes */}
        <h3 className="mt-3">
          <FontAwesomeIcon icon={faCubes} /> Apreensões de Materiais
        </h3>
        <hr />
        {/* style={{ width: "18rem" }} */}
        <Table condensed bordered striped>
          <thead>
            <tr>
              <th className="p-0">Material</th>
              <th className="p-0" style={{ minWidth: "1%" }}>
                Quantidade
              </th>
            </tr>
          </thead>
          <tbody>
            {(this.props.boletim.Apreensoes.length &&
              this.props.boletim.Apreensoes.map((apreensao, key) => (
                <tr key={key}>
                  <td className="p-0">{apreensao.Material.descricao}</td>
                  <td className="p-0">{apreensao.quantidade}</td>
                </tr>
              ))) || (
              <tr>
                <td className="p-0" colspan="2">
                  Nenhuma apreensão cadastrada.
                </td>
              </tr>
            )}
          </tbody>
        </Table>

        <h3 className="mt-5">
          <FontAwesomeIcon icon={faBullhorn} /> Providências
        </h3>
        {/* Providencias */}
        <hr />
        <Table condensed bordered striped>
          <thead>
            <tr>
              <th className="col-4 p-0">Órgão</th>
              <th className="col-8 p-0">Detalhamento</th>
            </tr>
          </thead>
          <tbody>
            {(this.props.boletim.Providencias.length &&
              this.props.boletim.Providencias.map((providencia, key) => (
                <tr key={key}>
                  <td className="p-0">{providencia.orgao}</td>
                  <td className="p-0">{providencia.detalhamento}</td>
                </tr>
              ))) || (
              <tr>
                <td className="p-0" colspan="2">
                  Nenhuma providência cadastrada.
                </td>
              </tr>
            )}
          </tbody>
        </Table>

        <Row className="d-flex justify-space-between mb-5 ">
          <Col className="d-flex justify-content-end">
            <i>
              Boletim registrado em&nbsp;
              {moment(this.props.boletim.createdAt)
                .utcOffset(0)
                .format("DD/MM/YYYY [às] HH:mm:ss")}
              .
            </i>
          </Col>
        </Row>

        <div className="text-right">
        <Button variant="secondary" className="mb-5 mr-3 d-print-none" onClick={() => this.context.setContent("BoletimList")}>
            <FontAwesomeIcon icon={faFastBackward} /> Voltar
          </Button>
          <Button className="mb-5 d-print-none" onClick={() => window.print()}>
            <FontAwesomeIcon icon={faPrint} /> Imprimir
          </Button>
        </div>
      </div>
    );
  }
}

export default BoletimPrint;
