import React from 'react';
import ObjetivoForm from './ObjetivoForm';
import ModelList from '../ModelList';
import { AppContext } from '../../../context';

class ObjetivoList extends React.Component {  
    
    static contextType = AppContext;

    render(){
        return (
            <ModelList 
                url={this.context.config.BACKEND_URL + '/objetivo'}
                title="Lista de Objetivos"
                modelName="objetivo"
                modelIcon="faBullseye"
                modelForm={ObjetivoForm}
                useAdd={true}
            />
        );
    }
}


export default ObjetivoList;