import { Formik, Form, Field, ErrorMessage } from "formik";
import React from "react";
import Request from "../../../request";
import { Form as BSForm, FormGroup, Button, Row, Col } from "react-bootstrap";
import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "../../../context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";

class EscolaForm extends React.Component {
  static contextType = AppContext;

  state = {
    cidades: [],
  };

  componentDidMount() {
    Request(
      "GET",
      this.context.config.BACKEND_URL + "/cidade/options",
      this.context.token
    )
      .send()
      .then((res) => {
        this.setState(() => ({ cidades: res.body }));
      })
      .catch((err) => {
        this.context.addToast({
          title: "Erro",
          text: "Falha na recuperação da lista das cidades: " + err.toString(),
        });
      });
  }
  save(values, callback) {
    const method = this.props.escola.id ? "PUT" : "POST";
    const url =
      this.context.config.BACKEND_URL +
      "/alertaescola/escola" +
      (this.props.escola.id ? "/" + this.props.escola.id : "");

    Request(method, url, this.context.token)
      .send(values)
      .then((res) => {
        this.context.addToast({
          titulo: "Successo",
          conteudo: "Registro atualizado com sucesso.",
        });
        callback();
        if (this.props.onSave) this.props.onSave();
        this.context.closeModal();
      })
      .catch((err) => {
        this.context.addToast({
          titulo: "Erro",
          conteudo: "Houve uma falha na gravação do registro.",
        });
        callback();
      });
  }

  render() {
    return (
      <Formik
        initialValues={{
          codigo: this.props.escola?.codigo ?? '',
          nome: this.props.escola?.nome ?? '',
          endereco: this.props.escola?.endereco ?? '',
          latitude: this.props.escola?.latitude ?? '',
          longitude: this.props.escola?.longitude ?? '',
          CidadeId: this.props.escola.CidadeId ?? ''
        }}
        validate={(values) => {
          const errors = {};
          if (!values.nome) {
            errors.nome = "Campo obrigatório";
          }
          if (!values.latitude) {
            errors.latitude = "Campo obrigatório";
          }
          if (!values.longitude) {
            errors.logintude = "Campo obrigatório";
          }
          if (!values.endereco) {
            errors.endereco = "Campo obrigatório";
          }
          if (!values.CidadeId) {
            errors.CidadeId = "Campo obrigatório";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          this.save(values, () => setSubmitting(false));
        }}
      >
        {({ isSubmitting, submitForm, values, setFieldValue, setValues }) => {
          return (
            <Form>
              <FormGroup>
                <BSForm.Label>Código INEP</BSForm.Label>
                <Field
                  type="text"
                  name="codigo"
                  className="form-control"
                />
              </FormGroup>
              <FormGroup>
                <BSForm.Label>Nome da Escola</BSForm.Label>
                <ErrorMessage
                  name="nome"
                  component="span"
                  className="text-danger small ml-2"
                />
                <Field
                  type="text"
                  name="nome"
                  className="form-control"
                  required
                />
              </FormGroup>
              <FormGroup>
                <BSForm.Label>Cidade</BSForm.Label>
                <ErrorMessage
                  name="CidadeId"
                  component="span"
                  className="text-danger small ml-2"
                  required
                />
                <Select
                  noOptionsMessage={() => "Nada encontrado."}
                  placeholder="Pesquisar..."
                  options={this.state.cidades}
                  value={this.state.cidades.find(
                    (option) => option.value === values.CidadeId
                  )}
                  onChange={(option) =>
                    setFieldValue("CidadeId", option?.value ?? null)
                  }
                />
              </FormGroup>
              <FormGroup>
                <BSForm.Label>Endereço</BSForm.Label>
                <ErrorMessage
                  name="endereco"
                  component="span"
                  className="text-danger small ml-2"
                  required
                />
                <Field
                  type="textarea"
                  name="endereco"
                  className="form-control"
                  required
                />
              </FormGroup>
              <Row>
                <Col>
                  <FormGroup>
                    <BSForm.Label>Latitude</BSForm.Label>
                    <ErrorMessage
                      name="latitude"
                      component="span"
                      className="text-danger small ml-2"
                    />
                    <Field
                      type="text"
                      name="latitude"
                      className="form-control"
                      required
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <BSForm.Label>Longitude</BSForm.Label>
                    <ErrorMessage
                      name="longitude"
                      component="span"
                      className="text-danger small ml-2"
                    />
                    <Field
                      type="text"
                      name="longitude"
                      className="form-control"
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup className="text-right d-flex justify-content-between">
                <Button
                  type="button"
                  variant="secondary"
                  onClick={() => this.context.closeModal()}
                >
                  <FontAwesomeIcon icon={faTimes} />
                  &nbsp; Fechar
                </Button>
                <Button
                  type="button"
                  disabled={isSubmitting}
                  variant="primary"
                  className="ml-2"
                  onClick={() => {
                    submitForm();
                  }}
                >
                  <FontAwesomeIcon icon={faSave} />
                  &nbsp; Gravar Alterações
                </Button>
              </FormGroup>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

export default EscolaForm;
