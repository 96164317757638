import React, { useEffect } from "react";
import L from 'leaflet';

import {
  Circle,
  MapContainer,
  TileLayer,
  useMap,
} from "react-leaflet";
import AlertaMarker from "./AlertaMarker";

const MapController = props => {

  const map = useMap();

  useEffect(() => {

    const length = props.alertas.length;

    if (!length) map.setView(AlertaMap.center);
    else if (length === 1) map.setView([props.alertas[0].latitude, props.alertas[0].longitude]);
    else {
      const corner1 = L.latLng(
        props.alertas.map(alerta => alerta.latitude).reduce((a, b) => Math.max(a, b), props.alertas[0].latitude),
        props.alertas.map(alerta => alerta.longitude).reduce((a, b) => Math.min(a, b), props.alertas[0].longitude)
      );
      const corner2 = L.latLng(
        props.alertas.map(alerta => alerta.latitude).reduce((a, b) => Math.min(a, b), props.alertas[0].latitude),
        props.alertas.map(alerta => alerta.longitude).reduce((a, b) => Math.max(a, b), props.alertas[0].longitude)
      );

      const bounds = L.latLngBounds(corner1, corner2);

      map.flyToBounds(bounds);
    }
  }, [props.loaded, props.alertas, map]);

  useEffect(() => {

    if (props.center) map.setView(props.center);

  }, [props.center, map]);


  return null;
}

class AlertaMap extends React.Component {

  static center = [-1.437972, -48.466901];

  state = {
    circle: false,
    center: AlertaMap.center
  }

  render() {
    return (
      <>
        <MapContainer
          center={this.state.center}
          zoom={13}
          scrollWheelZoom={true}
          style={{ height: "100%", width: "100%", minHeight: 400 }}
        >
          <MapController alertas={this.props.alertas} loaded={this.props.loaded} center={this.props.center} />
          <TileLayer url="http://{s}.tile.osm.org/{z}/{x}/{y}.png" />
          {this.props.alertas.map((alerta, key) => (
            <AlertaMarker
              eventHandlers={{
                click: () => {
                  this.setState({ circle: alerta })
                },
                dblclick:() => {
                  this.props.openAlertaModal(alerta)
                }
              }}
              alerta={alerta}
              key={key}
            />             
          ))}
          {this.props.alertas.indexOf(this.state.circle) > -1 && <Circle center={[this.state.circle.latitude, this.state.circle.longitude]} radius={this.state.circle.precisao} />}
        </MapContainer>
      </>
    );
  }
}

export default AlertaMap;
