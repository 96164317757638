import L from 'leaflet';
import circle from '../../../assets/vectors/circle-dark.svg';
import square from '../../../assets/vectors/square-dark.svg';

export const circleIcon = new L.Icon({
    iconUrl: circle,
    iconRetinaUrl: null,
    iconAnchor: null,
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(8, 8)
});

export const squareIcon = new L.Icon({
    iconUrl: square,
    iconRetinaUrl: null,
    iconAnchor: null,
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(8, 8)
});