import React from "react";
import { Button, Col, Form, FormControl, Row } from "react-bootstrap";

import { AppContext } from "../../../context";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class BoletimEnvolvidoForm extends React.Component {
  static contextType = AppContext;

  render() {
    const envolvido = this.props.envolvido;
    return (
      <div>
        <Form.Group className="d-flex flex-column justify-content-center align-items-center">
          <Form.Label>
            Boletim N° {String(envolvido.BoletimId).padStart(5, "0")}
          </Form.Label>
          <h3>{envolvido.nome}</h3>
        </Form.Group>
        <hr />
        <Row className="mt-1">
          <Col sm={3}>
            <strong>RG</strong>
            <FormControl disabled={true} value={envolvido.rg} />
          </Col>
          <Col sm={3}>
            <strong>Sexo</strong>
            <FormControl disabled={true} value={envolvido.sexo} />
          </Col>
          <Col sm={3}>
            <strong>Idade (anos)</strong>
            <FormControl disabled={true} value={envolvido.idade} />
          </Col>
          <Col sm={3}>
            <strong>Raça</strong>
            <FormControl disabled={true} value={envolvido.Raca.descricao} />
          </Col>
        </Row>
        <Row className="mt-1">
          <Col>
            <strong>Endereço</strong>
            <FormControl
              as="textarea"
              disabled={true}
              value={envolvido.endereco}
            />
          </Col>
        </Row>
        <Row className="mt-1">
          <Col sm={6}>
            <strong>Contato</strong>
            <FormControl disabled={true} value={envolvido.contato} />
          </Col>
          <Col sm={6}>
            <strong>Integridade</strong>
            <FormControl
              disabled={true}
              value={envolvido.Integridade.descricao}
            />
          </Col>
        </Row>
        <hr />
        <Row className="mt-1">
          <Col sm={3}>
            <strong>Destino</strong>
            <FormControl disabled={true} value={envolvido.Destino.descricao} />
          </Col>
          <Col sm={6}>
            <strong>Unidade de Apresentação</strong>
            <FormControl
              disabled={true}
              value={envolvido.apresentacao_unidade}
            />
          </Col>
          <Col sm={3}>
            <strong>Enquadramento</strong>
            <FormControl
              disabled={true}
              value={envolvido.apresentacao_enquadramento}
            />
          </Col>
        </Row>
        <Row className="mt-1">
          <Col sm={9}>
            <strong>Nome da Autoridade</strong>
            <FormControl
              disabled={true}
              value={envolvido.apresentacao_autoridade}
            />
          </Col>
          <Col sm={3}>
            <strong>Registro da Autoridade</strong>
            <FormControl
              disabled={true}
              value={envolvido.apresentacao_registro}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col className="text-right">
            <Button
              variant="secondary"
              className="mr-3 d-print-none"
              onClick={() => this.context.closeModal()}
            >
              <FontAwesomeIcon icon={faTimes} /> Fechar
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default BoletimEnvolvidoForm;
