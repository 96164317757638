import React from 'react';
import { AppContext } from '../../../context';
import ModelList from '../ModelList';
import MapaForm from './MapaForm';

class MapaList extends React.Component {
    static contextType = AppContext;
    onAction(action, callback, defaultHandler) {
        if (action.name === "manage") {
            this.context.setContent("MapaManage", { MapaId: action.id }, "Mapas Colaborativos", true);
        } else {
            defaultHandler(action, callback);
        }
    }
    render() {
        return (
            <ModelList
                url={this.context.config.BACKEND_URL + '/mapa'}
                title="Lista de Mapas"
                modelIcon="faMap"
                useAdd={true}
                modelName="mapa"
                modalSize="xl"
                modelForm={MapaForm}
                onAction={(action, callback, defaultHandler) => this.onAction(action, callback, defaultHandler)}
            />
        );
    }
}

export default MapaList;

