import React from 'react';
import CidadeForm from './CidadeForm';
import ModelList from '../ModelList';
import { AppContext } from '../../../context';

class CidadeList extends React.Component {    

    static contextType = AppContext;

    render(){
        return (
            <ModelList 
                url={this.context.config.BACKEND_URL + '/cidade'}
                title="Lista de Cidades"
                modelName="cidade"
                modelIcon="faCity"
                modelForm={CidadeForm}
                useAdd={true}
            />
        );
    }
}

export default CidadeList;