import React from 'react';
import InscricaoForm from './InscricaoForm';
import ModelList from '../ModelList';
import Request from '../../../request';
import { AppContext } from '../../../context';

class InscricaoList extends React.Component {    

    static contextType = AppContext;

    onAction(action, update, defaultHandler){
        switch (action.name){
            case 'confirmation':
                if (window.confirm(`Deseja realmente confirmar esta inscrição?`)){
                    const url = this.context.config.BACKEND_URL + "/inscricao/confirmar/" + action.id;
                    Request('PATCH', url, this.context.token)
                    .then( res => {
                        this.context.addToast({titulo: "Successo", conteudo: "Inscrição confirmada com sucesso."});
                        update();
                    })
                    .catch(err => {
                        this.context.addToast({titulo: "Erro", conteudo: "Falha na exclusão do registro: " + err.toString()});
                    });
                }            
                break;
            case 'approve':
                if (window.confirm(`Deseja realmente aprovada esta inscrição?`)){
                    const url = this.context.config.BACKEND_URL + "/inscricao/aprovar/" + action.id;
                    Request('PATCH', url, this.context.token)
                    .then( res => {
                        this.context.addToast({titulo: "Successo", conteudo: "Inscrição aprovada com sucesso."});
                        update();
                    })
                    .catch(err => {
                        this.context.addToast({titulo: "Erro", conteudo: "Falha na exclusão do registro: " + err.toString()});
                    });
                }            
                break;
            case 'reprobate':
                if (window.confirm(`Deseja realmente reprovada esta inscrição?`)){
                    const url = this.context.config.BACKEND_URL + "/inscricao/reprovar/" + action.id;
                    Request('PATCH', url, this.context.token)
                    .then( res => {
                        this.context.addToast({titulo: "Successo", conteudo: "Inscrição reprovada com sucesso."});
                        update();
                    })
                    .catch(err => {
                        this.context.addToast({titulo: "Erro", conteudo: "Falha na exclusão do registro: " + err.toString()});
                    });
                }            
                break;
            case 'accredit':
                if (window.confirm(`Deseja realmente confirmar esta inscrição?`)){
                    const url = this.context.config.BACKEND_URL + "/inscricao/credenciar/" + action.id;
                    Request('PATCH', url, this.context.token)
                    .then( res => {
                        this.context.addToast({titulo: "Successo", conteudo: "Inscrição credenciada com sucesso."});
                        update();
                    })
                    .catch(err => {
                        this.context.addToast({titulo: "Erro", conteudo: "Falha na exclusão do registro: " + err.toString()});
                    });
                }            
                break;
            default:
                defaultHandler();
                break;
        }
    }

    render(){
        return (
            <ModelList 
                url={this.context.config.BACKEND_URL + '/inscricao'}
                title={this.props.title ?? "Lista de Inscrições"}
                modelName="inscricao"
                modelIcon="faPenAlt"
                modelForm={InscricaoForm}
                useAdd={true}
                empty={{EventoId: this.props.EventoId}}
                onAction={(action, update, defaultHandler) => this.onAction(action, update, defaultHandler)}
                filter={{EventoId: this.props.EventoId}}
            />
        );
    }
}

export default InscricaoList;