import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Form as BSForm, FormGroup } from "react-bootstrap";
import Request from "../../../request";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { AppContext } from "../../../context";

class PermissaoForm extends React.Component {

    static contextType = AppContext;

    save(values, callback){
        const method = this.props.permissao.id? 'PUT' : 'POST';
        const url = this.context.config.BACKEND_URL + '/permissao' + (this.props.permissao.id? '/'+this.props.permissao.id : '');

        Request(method, url, this.context.token)
        .send(values)
        .then( () => {
                this.context.addToast({titulo: "Successo", conteudo: "Registro atualizado com sucesso."});
                callback();
                if (this.props.onSave) this.props.onSave();
                this.context.closeModal();                                
        })
        .catch( err => {
            this.context.addToast({titulo: "Erro", conteudo: "Houve uma falha na gravação do registro."});
            callback();
        });

    }

    render(){
        return (
            <Formik
                initialValues={{ 
                    nome: this.props.permissao.nome,
                    descricao: this.props.permissao.descricao
                }}
                validate={values => {     
                    const errors = {};     
                    if (!values.nome) {    
                        errors.nome = 'Campo obrigatório';     
                    }
                    if (!values.descricao) {    
                        errors.descricao = 'Campo obrigatório';     
                    }
                    return errors;     
                }}
                onSubmit={(values, { setSubmitting }) => {     
                    this.save(values, () => setSubmitting(false));
                }}     
            >     
                {({ isSubmitting }) => (
                    <Form>
                        <FormGroup>
                            <BSForm.Label>Nome</BSForm.Label><ErrorMessage name="nome" component="span" className="text-danger small ml-2"/>
                            <Field type="text" name="nome" className="form-control" required/>
                            
                        </FormGroup>
                        <FormGroup>
                            <BSForm.Label>Descrição</BSForm.Label><ErrorMessage name="descricao" component="span" className="text-danger small ml-2"/>
                            <Field type="text" name="descricao" className="form-control" required/>                            
                        </FormGroup>                        
                        <FormGroup className="text-right">
                            <Button type="button" variant='secondary' onClick={() => this.context.closeModal()}>
                                <FontAwesomeIcon icon={faTimes} />&nbsp;
                                Fechar
                            </Button>
                            <Button type="submit" disabled={isSubmitting} variant='primary' className="ml-2">
                                <FontAwesomeIcon icon={faSave} />&nbsp;
                                Gravar Alterações
                            </Button>
                        </FormGroup>
                    </Form>
                )}
            </Formik>
        );
    }
}

export default PermissaoForm;