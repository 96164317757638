import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Form as BSForm, FormGroup } from "react-bootstrap";
import Request from "../../../request";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Select from "react-select";
import moment from "moment";
import { AppContext } from "../../../context";

class InscricaoForm extends React.Component {

  static contextType = AppContext;

  state = {
    eventos: [],
    status: []
  };

  componentDidMount() {
    
    //catch eventos
    Request("GET", this.context.config.BACKEND_URL + "/evento/options", this.context.token)
      .then((res) => {
        let eventos = res.body
        let eventosFiltered = eventos.filter(event => moment(event.fimInscricao).utcOffset(0) >= moment().utcOffset(0))

        this.setState(() => ({ eventos: eventosFiltered }));
      })
      .catch((err) => {
        this.context.addToast({
          titulo: "Erro",
          conteudo: "Erro na recuperação da lista de Eventos",
        });
      });


    //catch status from submit
    this.setState(() => ({
      status: ["Solicitado", "Confirmado", "Aprovado", "Reprovado", "Credenciado"]
    }))
  }

  save(values, callback) {
    const method = this.props.inscricao.id ? "PUT" : "POST";
    const url =
      this.context.config.BACKEND_URL +
      "/inscricao" +
      (this.props.inscricao.id ? "/" + this.props.inscricao.id : "");

    Request(method, url, this.context.token)
      .send({ ...values })
      .then((res) => {
        this.context.addToast({
          titulo: "Successo",
          conteudo: "Registro atualizado com sucesso.",
        });
        callback();
        if (this.props.onSave) this.props.onSave();
        this.context.closeModal();
      })
      .catch((err) => {
        this.context.addToast({
          titulo: "Erro",
          conteudo: "Houve uma falha na gravação do registro.",
        });
        callback();
      });
  }

  render() {
    return (
      <Formik
        initialValues={{
          ...this.props.inscricao,
          status: this.props.inscricao.status ?? "Solicitado"
        }}
        validate={(values) => {
          const errors = {};

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          this.save(values, () => setSubmitting(false));
        }}
      >
        {({ isSubmitting, values, setFieldValue }) => {
          return (
            <Form>
              <FormGroup>
                <BSForm.Label>Nome teste</BSForm.Label>
                <ErrorMessage
                  name="nome"
                  component="span"
                  className="text-danger small ml-2"
                />
                <Field type="text" name="nome" className="form-control" />
              </FormGroup>
              <FormGroup>
                <BSForm.Label>Nome no Crachá</BSForm.Label>
                <ErrorMessage
                  name="nome_cracha"
                  component="span"
                  className="text-danger small ml-2"
                />
                <Field
                  type="text"
                  name="nome_cracha"
                  className="form-control"
                />
              </FormGroup>
              <FormGroup>
                <BSForm.Label>CPF</BSForm.Label>
                <ErrorMessage
                  name="cpf"
                  component="span"
                  className="text-danger small ml-2"
                />
                <Field type="text" name="cpf" className="form-control" />
              </FormGroup>
              <FormGroup>
                <BSForm.Label>Contato</BSForm.Label>
                <ErrorMessage
                  name="contato"
                  component="span"
                  className="text-danger small ml-2"
                />
                <Field type="text" name="contato" className="form-control" />
              </FormGroup>
              <FormGroup>
                <BSForm.Label>Email</BSForm.Label>
                <ErrorMessage
                  name="email"
                  component="span"
                  className="text-danger small ml-2"
                />
                <Field type="email" name="email" className="form-control" />
              </FormGroup>
              <FormGroup>
                <BSForm.Label>Instituição</BSForm.Label>
                <ErrorMessage
                  name="instituicao"
                  component="span"
                  className="text-danger small ml-2"
                />
                <Field
                  type="text"
                  name="instituicao"
                  className="form-control"
                />
              </FormGroup>
              <FormGroup>
                <BSForm.Label>Evento</BSForm.Label>
                <ErrorMessage
                  name="inicio_evento"
                  component="span"
                  className="text-danger small ml-2"
                />
                <Select
                  isDisabled={values.EventoId}
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  noOptionsMessage={() => "Nada encontrado."}
                  placeholder="Pesquisar..."
                  options={this.state.eventos}
                  value={this.state.eventos.find(
                    (option) => option.value === values.EventoId
                  )}
                  onChange={(option) =>
                    setFieldValue(`EventoId`, option ? option.value : null)
                  }
                />
              </FormGroup>
              <FormGroup>
                <BSForm.Label>Status</BSForm.Label>
                <Field
                  type="text"
                  name="status"
                  className="form-control"
                  readOnly={true}
                />
              </FormGroup>
              <FormGroup className="text-right">
                <Button
                  type="button"
                  variant="secondary"
                  onClick={() => this.context.closeModal()}
                >
                  <FontAwesomeIcon icon={faTimes} />
                  &nbsp; Fechar
                </Button>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  variant="primary"
                  className="ml-2"
                >
                  <FontAwesomeIcon icon={faSave} />
                  &nbsp; Gravar Alterações
                </Button>
              </FormGroup>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

export default InscricaoForm;
