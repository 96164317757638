import { faBackward, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  Button,
  Col,
  Container,
  Image,
  ResponsiveEmbed,
  Row,
} from "react-bootstrap";
import moment from "moment";
import EleitorFrontForm from "./EleitorFrontForm";
import Request from "../../../request";
import { useParams } from "react-router-dom";

import logo from "../../../img/LogoNova.png";
import { faFacebook, faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { AppContext } from "../../../context";

class EleicaoFrontPage extends React.Component {

  static contextType = AppContext;

  state = {
    eleicao: {},
  };

  componentDidMount() {
    Request("GET", this.context.config.BACKEND_URL + "/eleicoes/eleicao/" + this.props.params.id, this.context.token)
      .then((res) =>
        this.setState({
          eleicao: res.body,
        })
      )
      .catch(
        (err) =>
          (window.location = this.context.config.FRONTEND_URL + "/eleicoes")
      );
  }

  render() {
    const eleicao = this.state.eleicao;
    const agora = moment();

    return (
      <Container id="eleicoes">
        <Row className="py-3 bg-danger align-items-center">
          <Col lg={6}>
            <h1 className="text-white text-center font-weight-bold d-flex justify-content-between align-items-center">
              {eleicao.nome}
            </h1>
          </Col>
          <Col lg={6} className="text-right">
            <Row>
              <Col lg={12}>
                <Image className="d-block" fluid src={logo} />
              </Col>
              <Col>
                <Button
                  as="a"
                  variant="secondary"
                  className="form-control"
                  href={this.context.config.FRONTEND_URL + "/eleicoes"}
                >
                  <FontAwesomeIcon icon={faBackward} /> Voltar às Eleições
                </Button>
              </Col>
              {moment(eleicao.fim_inscricao) > agora &&
                agora > moment(eleicao.inicio_inscricao) && (
                  <Col>
                    <Button
                      variant="primary"
                      className="form-control"
                      onClick={() =>
                        this.context.openModal({
                          titulo: "Cadastro de Eleitor",
                          size: "lg",
                          conteudo: <EleitorFrontForm eleicao={eleicao} />,
                        })
                      }
                    >
                      <FontAwesomeIcon icon={faEdit} /> Inscrever-se como
                      Eleitor
                    </Button>
                  </Col>
                )}
            </Row>
          </Col>
        </Row>

        <Row className="bg-light">
          <Col md={6} className="my-3">
            <div className="bg-dark text-white">
              <div className="m-0 p-3 bg-danger">
                Detalhes da Eleição
              </div>

              <div
                className="p-3"
                dangerouslySetInnerHTML={{ __html: eleicao.descricao }}
              />
            </div>
          </Col>
          <Col className="my-3 text-white align-items-stretch d-flex flex-column">
            <Row className="flex-fill pb-2">
              <Col>
                <div className="h-100 bg-dark">
                  <div className="p-3 mb-3 titulo_eleicao bg-danger">
                    Período de Inscrição de Eleitores
                  </div>
                  <ul>
                    <li>
                      <b>Início</b>:
                      {moment(eleicao.inicio_inscricao).format(
                        "DD/MM/YYYY HH:mm"
                      )}
                    </li>
                    <li>
                      <b>Término</b>:
                      {moment(eleicao.fim_inscricao).format("DD/MM/YYYY HH:mm")}
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
            <Row className="flex-fill pt-2">
              <Col className="text-white">
                <div className="h-100 bg-dark">
                  <div className="p-3 mb-3 bg-danger">
                    Período de Votação
                  </div>
                  <ul>
                    <li>
                      <b>Início</b>:{" "}
                      {moment(eleicao.inicio_votacao).format(
                        "DD/MM/YYYY HH:mm"
                      )}
                    </li>
                    <li>
                      <b>Término</b>:{" "}
                      {moment(eleicao.fim_votacao).format("DD/MM/YYYY HH:mm")}
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        {eleicao.Cargos?.map((cargo, key) => (
          <Row key={key}>
            <h2 className="bg-danger text-white text-center p-3 m-0 w-100">
              Chapas Habilitadas ao Cargo de {cargo.nome}
            </h2>
            <Col lg={12} className="p-3">
              {cargo.descricao ? cargo.descricao : null}
            </Col>
            <Col lg={12}>
              {(cargo.Chapas.length > 0 &&
                cargo.Chapas.map((chapa, key) => (
                  <Row
                    key={key}
                    className={`align-items-stretch bg-${["primary", "danger", "white"][key % 3]
                      } text-${["white", "white", "dark"][key % 3]}`}
                  >
                    <Col lg={12}>
                      <h3 className="p-3">{chapa.nome_instituicao}</h3>
                    </Col>
                    {chapa.iframe_video && (
                      <Col md={6} className="mb-2">
                        <ResponsiveEmbed
                          aspectRatio="16by9"
                          style={{ height: "100%" }}
                        >
                          <div
                            className="teste"
                            dangerouslySetInnerHTML={{
                              __html: chapa.iframe_video,
                            }}
                          />
                        </ResponsiveEmbed>
                      </Col>
                    )}
                    {chapa.foto_instituicao && (
                      <Col md={6} className="text-center mb-2">
                        <img
                          alt="Foto da instituição"
                          className="img-fluid shadow-4 foto_instituicao"
                          src={chapa.foto_instituicao}
                          style={{ height: "100%" }}
                        />
                      </Col>
                    )}
                    {chapa.descricao && (
                      <Col md={6} className="my-3">
                        <h5>Histórico</h5>
                        <div
                          className="historico"
                          dangerouslySetInnerHTML={{
                            __html: chapa.descricao,
                          }}
                        />
                      </Col>
                    )}

                    <Col className="text-center my-3">
                      <h5>Titular</h5>
                      {chapa.foto_titular &&
                      <img
                        alt="Foto do titular"
                        className="img-fluid shadow-4 foto"
                        src={chapa.foto_titular}
                        style={{ width: "100%", maxWidth: 600 }}
                      />}
                      <br />
                      {chapa.nome_titular}
                    </Col>
                    <Col className="text-center my-3">
                      <h5>Suplente</h5>
                      {chapa.foto_suplente && 
                      <img
                        alt="Foto do suplente"
                        className="img-fluid shadow-4 foto"
                        src={chapa.foto_suplente}
                        style={{ width: "100%", maxWidth: 600 }}
                      />}
                      <br />
                      {chapa.nome_suplente}
                    </Col>
                  </Row>
                ))) || (
                  <h4 className="text-center">Nenhuma Chapa Habilitada</h4>
                )}
            </Col>
          </Row>
        ))}
        <Row className="bg-dark text-white d-flex align-items-center">
          <Col className="text-center py-3" lg={4}>
            <h4>
              Secretaria de Segurança
              <br />
              Pública e Defesa Social
            </h4>
            R. Arcipreste Manoel Teodoro, 305.
            <br />
            Belém - PA (91) 3184-2500
          </Col>

          <Col className="text-center py-3" lg={4}>
            <h4 className="text-uppercase mb-4">Siga-nos</h4>
            <a
              className="btn btn-outline-light btn-social mx-1"
              href="https://www.facebook.com/Seguppa"
              rel="noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a
              className="btn btn-outline-light btn-social mx-1"
              href="http://www.twitter.com/seguppara"
              rel="noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon icon={faTwitter} />
            </a>
            <a
              className="btn btn-outline-light btn-social mx-1"
              href="http://www.instagram.com/segup.pa"
              rel="noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          </Col>
          <Col className="text-center py-3" lg={4}>
            <h4>
              Eleições do Sistema Estadual de Segurança Pública e Defesa Social
            </h4>
          </Col>
          <Col className="text-center" lg={12}>
            <small>Copyright © Ditel/SEGUP-PA 2020</small>
          </Col>
        </Row>
      </Container>
    );
  }
}

const EleicaoFrontPageWrapped = (props) => <EleicaoFrontPage {...props} params={useParams()} />;

export default EleicaoFrontPageWrapped;
