import React from 'react';
import RecursoForm from './RecursoForm';
import ModelList from '../ModelList';
import { AppContext } from '../../../context';

class RecursoList extends React.Component {    

    static contextType = AppContext;

    render(){
        return (
            <ModelList 
                url={this.context.config.BACKEND_URL + '/recurso'}
                title="Lista de Recursos"
                modelName="recurso"
                modelIcon="faThList"
                modelForm={RecursoForm}
                useAdd={true}
            />
        );
    }
}


export default RecursoList;