import AlertaEscolaPage from "./AlertaEscolaPage";
import EscolaList from './EscolaList';
import EscolaForm from './EscolaForm';
import AlertaEscolaList from './AlertaEscolaList';
import AlertaEscolaForm from './AlertaEscolaForm';
import UsuarioAlertaEscolaList from './UsuarioAlertaEscolaList';
import UsuarioAlertaEscolaForm from './UsuarioAlertaEscolaForm';

const AlertaEscolaContents = {
    AlertaEscolaPage,
    EscolaList,
    EscolaForm,    
    AlertaEscolaList,
    AlertaEscolaForm,
    UsuarioAlertaEscolaList,
    UsuarioAlertaEscolaForm
};

export default AlertaEscolaContents;