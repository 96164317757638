import React from 'react';
import PermissaoForm from './PermissaoForm';
import { AppContext } from '../../../context';
import ModelList from '../ModelList';

class PermissaoList extends React.Component {
    
    static contextType = AppContext;

    render(){
        return (
            <ModelList
                url={this.context.config.BACKEND_URL + '/permissao'}
                title="Lista de Permissões"
                modelIcon="faKeys"
                useAdd={this.context.usuario.Permissoes.some(p => p.nome === 'admin_permissao_create')}
                modelName="permissao"
                modelForm={PermissaoForm}
            />
        );
    }
}


export default PermissaoList;