import { faPencilAlt, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Button, Form as BSForm, FormGroup } from "react-bootstrap";
import Select from 'react-select';
import Request from "../../../request";
import CustomEditor from '../../Editor';
import moment from 'moment';
import { AppContext } from '../../../context';

class OperacaoForm extends React.Component {

    static contextType = AppContext;

    state = {
        risps: [],
        orgaos: [],
        indicadores: [],
        execucoes: [
            { value: 'Agendada', label: 'Agendada' },
            { value: 'Em Execução', label: 'Em Execução' },
            { value: 'Finalizada', label: 'Finalizada' },
            { value: 'Cancelada', label: 'Cancelada' }
        ]
    }




    save(values, callback) {
        const method = this.props.operacao.id ? 'PUT' : 'POST';
        const url = this.context.config.BACKEND_URL + '/operacao' + (this.props.operacao.id ? '/' + this.props.operacao.id : '');

        Request(method, url, this.context.token)
            .send(values)
            .then(res => {
                this.context.addToast({ titulo: "Successo", conteudo: "Registro atualizado com sucesso." });

                Request('GET', this.context.config.BACKEND_URL + '/operacao/dashboard/' + res.body.id, this.context.token)
                    .then(res => {
                        this.context.setContent("OperacaoDashboard", { dashboard: res.body });
                    })
                    .catch(err => {
                        this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na recuperação do registro: " + err.toString() });
                    })
            })
            .catch(err => {
                this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na gravação do registro: " + err.toString() });
            })
            .finally(() => callback());
    }


    componentDidMount() {
        Request('GET', this.context.config.BACKEND_URL + '/risp/options', this.context.token).send().then(res => {
            const global = this.context.usuario.Permissoes.some(permissao => permissao.nome === "operacoes_risp_global");
            const risps = this.context.usuario.Risps;
            this.setState(() => ({ risps: res.body.filter(option => global || risps.find(risp => risp.id === option.value)) }));
        })
            .catch(err => this.context.addToast({ titulo: "Erro", conteudo: "Falha na recuperação das risps: " + err.toString() }));

        Request('GET', this.context.config.BACKEND_URL + '/orgao/options', this.context.token).send().then(res => {
            const global = this.context.usuario.Permissoes.some(permissao => permissao.nome === "operacoes_orgao_global");
            const orgao = this.context.usuario.Orgao;
            this.setState(() => ({ orgaos: res.body.filter(option => global || option.value === orgao.id) }));
        })
            .catch(err => this.context.addToast({ titulo: "Erro", conteudo: "Falha na recuperação dos órgãos: " + err.toString() }));

        Request('GET', this.context.config.BACKEND_URL + '/indicador/options', this.context.token).send().then(res => {
            this.setState(() => ({ indicadores: res.body }));
        })
            .catch(err => this.context.addToast({ titulo: "Erro", conteudo: "Falha na recuperação dos órgãos: " + err.toString() }));

    }


    render() {
        return (
            <Formik
                initialValues={{
                    lider_id: this.props.operacao.Lider.id,
                    nome: this.props.operacao.nome,
                    data_inicial: this.props.operacao.data_inicial ? moment(this.props.operacao.data_inicial).utcOffset(0).format("YYYY-MM-DDTHH:mm") : null,
                    data_final: this.props.operacao.data_final ? moment(this.props.operacao.data_final).utcOffset(0).format("YYYY-MM-DDTHH:mm") : null,
                    execucao: this.props.operacao.execucao,
                    resenha: this.props.operacao.resenha,
                    Risps: this.props.operacao.Risps ? this.props.operacao.Risps.map(risp => risp.id) : [],
                    Integrados: this.props.operacao.Integrados ? this.props.operacao.Integrados.map(orgao => orgao.id) : [],
                    Indicadores: this.props.operacao.Indicadores ? this.props.operacao.Indicadores.map(indicador => indicador.id) : []
                }}
                validateOnChange={false}
                validateOnBlur={true}
                validate={values => {
                    const errors = {};

                    if (!values.nome) {
                        errors.nome = 'Campo obrigatório';
                    }

                    if (!values.data_inicial) {
                        errors.data_inicial = 'Campo obrigatório';
                    }

                    if (!values.data_final) {
                        errors.data_final = 'Campo obrigatório';
                    }

                    if (!values.lider_id) {
                        errors.lider_id = 'Campo obrigatório';
                    }

                    if (moment(values.data_final) < moment(values.data_inicial)) {
                        errors.data_inicial = 'Data final deve ser posterior à inicial';
                        errors.data_final = 'Data final deve ser posterior à inicial';
                    }

                    if (!values.Integrados.some(orgao => orgao === values.lider_id)) {
                        errors.Integrados = 'O Órgão Líder deve integrar a Operação';
                    }

                    if (!values.execucao) {
                        errors.execucao = 'Campo obrigatório';
                    }

                    if (values.Integrados.length < 1) {
                        errors.Integrados = 'Campo obrigatório';
                    }

                    if (values.Risps.length < 1) {
                        errors.Risps = 'Campo obrigatório';
                    }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    this.save(values, () => setSubmitting(false));
                }}
            >
                {({ isSubmitting, values, setFieldValue }) => {
                    return (

                        <Form>
                            {console.log(this.props)}
                            <h3 className="d-flex justify-content-between">
                                <div>
                                    <FontAwesomeIcon icon={faPencilAlt} />  Cadastro de Operação
                                </div>
                                <div>
                                    <Button type="button" variant='secondary' onClick={() => this.context.setContent('OperacaoList')} className="mr-2">
                                        <FontAwesomeIcon icon={faTimes} />&nbsp;
                                        Cancelar
                                    </Button>
                                    <Button type="submit" disabled={isSubmitting} variant='primary'>
                                        <FontAwesomeIcon icon={faSave} />&nbsp;
                                        Gravar Alterações
                                    </Button>

                                </div>
                            </h3>
                            <hr />
                            <FormGroup>
                                <BSForm.Label as="b">Nome</BSForm.Label><ErrorMessage name="nome" component="span" className="text-danger small ml-2" />
                                <Field type="text" name="nome" className="form-control" value={values.nome} />
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Col md="4">
                                        <BSForm.Label as="b">Data Inicial<span className="date"></span></BSForm.Label><ErrorMessage name="data_inicial" component="span" className="text-danger small ml-2" />
                                        <Field type="datetime-local" name="data_inicial" className="form-control" value={values.data_inicial} onChange={(event) => setFieldValue('data_inicial', event.target.value)} />
                                    </Col>
                                    <Col md="4">
                                        <BSForm.Label as="b">Data Final<span className="date"></span></BSForm.Label><ErrorMessage name="data_final" component="span" className="text-danger small ml-2" />
                                        <Field type="datetime-local" name="data_final" className="form-control" value={values.data_final} onChange={(event) => setFieldValue('data_final', event.target.value)} />
                                    </Col>
                                    <Col md="4">
                                        <BSForm.Label as="b">Execução</BSForm.Label><ErrorMessage name="execucao" component="span" className="text-danger small ml-2" />
                                        <Select
                                            name="execucao"
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                            menuPortalTarget={document.body}
                                            placeholder="Selecione..."
                                            options={this.state.execucoes}
                                            value={this.state.execucoes.find(execucao => execucao.value === values.execucao)}
                                            onChange={option => {
                                                setFieldValue('execucao', option.value);
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Col md="6">
                                        <BSForm.Label as="b">Órgão Líder</BSForm.Label><ErrorMessage name="lider_id" component="span" className="text-danger small ml-2" />
                                        <Select
                                            name="lider_id"
                                            noOptionsMessage={text => text.inputValue + " não encontrado!"}
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                            menuPortalTarget={document.body}
                                            placeholder="Selecione..."
                                            options={this.state.orgaos}
                                            value={this.state.orgaos.find(option => option.value === values.lider_id)}
                                            onChange={option => {
                                                setFieldValue('lider_id', option.value);
                                            }}
                                        />
                                    </Col>
                                    <Col md="6">
                                        <BSForm.Label as="b">Regiões de Integração</BSForm.Label><ErrorMessage name="Risps" component="span" className="text-danger small ml-2" />
                                        <Select
                                            name="Risps"
                                            noOptionsMessage={text => text.inputValue + " não encontrado!"}
                                            isMulti={true}
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                            menuPortalTarget={document.body}
                                            placeholder="Selecione..."
                                            options={this.state.risps}
                                            value={this.state.risps.filter(option => values.Risps.some(RispId => RispId === option.value))}
                                            onChange={options => {
                                                setFieldValue('Risps', options.map(option => option.value));
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Col md="12">
                                        <BSForm.Label as="b">Órgãos Integrados</BSForm.Label><ErrorMessage name="Integrados" component="span" className="text-danger small ml-2" />
                                        <Select
                                            name="Integrados"
                                            noOptionsMessage={text => text.inputValue + " não encontrado!"}
                                            isMulti={true}
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                            menuPortalTarget={document.body}
                                            placeholder="Selecione..."
                                            options={this.state.orgaos}
                                            value={this.state.orgaos.filter(option => values.Integrados.some(id => id === option.value))}
                                            onChange={options => {
                                                setFieldValue('Integrados', options.map(option => option.value));
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Col size="12">
                                        <BSForm.Label as="b">Indicadores</BSForm.Label>
                                        <Select
                                            name="Indicadores"
                                            noOptionsMessage={text => text.inputValue + " não encontrado!"}
                                            isMulti={true}
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                            menuPortalTarget={document.body}
                                            placeholder="Selecione..."
                                            options={this.state.indicadores}
                                            value={this.state.indicadores.filter(option => values.Indicadores.some(IndicadorId => IndicadorId === option.value))}
                                            onChange={options => {
                                                setFieldValue('Indicadores', options.map(option => option.value));
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Col md="12">
                                        <BSForm.Label as="b"> Resenha</BSForm.Label>
                                        <CustomEditor
                                            name="resenha"
                                            value={values.resenha}
                                            setFieldValue={setFieldValue}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                        </Form>
                    )
                }}
            </Formik >
        );
    }
}

export default OperacaoForm;