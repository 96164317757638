import { faBullhorn, faCubes, faEdit, faFileAlt, faFileMedical, faPlus, faPrint, faSave, faTimes, faTrash, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Accordion, Button, Card, Col, Form as BSForm, FormGroup, Row, Tab, Tabs } from "react-bootstrap";
import Request from "../../../request";
import { Formik, Form, Field, ErrorMessage } from "formik";
import moment from "moment";
import { AppContext } from "../../../context";
import Select from 'react-select';

class BoletimForm extends React.Component {

  static contextType = AppContext;

  state = {
    materiais: [],
    cidades: [],
    atributos: [],
    climas: [
      { label: 'Bom', value: 'Bom' },
      { label: 'Nublado', value: 'Nublado' },
      { label: 'Chuvoso', value: 'Chuvoso' },
      { label: 'Neblina', value: 'Neblina' }
    ],
    visibilidades: [
      { label: 'Bem Iluminada', value: 'Bem Iluminada' },
      { label: 'Mal Iluminada', value: 'Mal Iluminada' },
      { label: 'Não Iluminada', value: 'Não Iluminada' }
    ],
    atuacoes: [
      { label: 'Execução', value: 'Execução' },
      { label: 'Apoio', value: 'Apoio' }
    ],
  }

  componentDidMount() {

    //catch eventos
    Request("GET", this.context.config.BACKEND_URL + "/materialBoletim/options", this.context.token)
      .then((res) => {
        this.setState({ materiais: res.body });
      })
      .catch((err) => {
        this.context.addToast({
          titulo: "Erro",
          conteudo: "Erro na recuperação da lista de Eventos",
        });
      });

    Request("GET", this.context.config.BACKEND_URL + "/cidade/options", this.context.token)
      .then((res) => {
        this.setState({ cidades: res.body });
      })
      .catch((err) => {
        this.context.addToast({
          titulo: "Erro",
          conteudo: "Erro na recuperação da lista de Cidades",
        });
      })

    Request("GET", this.context.config.BACKEND_URL + "/atributoBoletim/options", this.context.token)
      .then((res) => {
        this.setState({ atributos: res.body });
      })
      .catch((err) => {
        this.context.addToast({
          titulo: "Erro",
          conteudo: "Erro na recuperação da lista de Cidades",
        });
      })

  }

  save(values, callback) {

    // console.log(values)

    Request("POST", this.context.config.BACKEND_URL + "/boletim", this.context.token)
      .send(values)
      .then(res => {
        this.context.addToast({
          titulo: "Successo",
          conteudo: "Registro atualizado com sucesso.",
        });
        if (this.props.onSave) this.props.onSave();
        callback(true, res.body);
      })
      .catch((err) => {
        this.context.addToast({
          titulo: "Erro",
          conteudo: "Houve uma falha na gravação do registro: " + err.toString(),
        });
        callback(false);
      });
  }

  render() {
    return (
      <Formik
        initialValues={{
          solicitante_nome: this.props.boletim.solicitante_nome,
          solicitante_registro: this.props.boletim.solicitante_registro,

          incidente: this.props.boletim.incidente ? moment(this.props.boletim.incidente).utcOffset(0).format("YYYY-MM-DDTHH:mm") : null,
          acionamento: this.props.boletim.acionamento ? moment(this.props.boletim.acionamento).utcOffset(0).format("YYYY-MM-DDTHH:mm") : null,
          inicio: this.props.boletim.inicio ? moment(this.props.boletim.inicio).utcOffset(0).format("YYYY-MM-DDTHH:mm") : null,
          termino: this.props.boletim.termino ? moment(this.props.boletim.termino).utcOffset(0).format("YYYY-MM-DDTHH:mm") : null,

          endereco: this.props.boletim.endereco,

          CidadeId: this.props.boletim.CidadeId,
          visibilidade: this.props.boletim.visibilidade,
          clima: this.props.boletim.clima,

          origem_id: this.props.boletim.origem_id,
          natureza_id: this.props.boletim.natureza_id,

          acao_id: this.props.boletim.acao_id,
          atuacao: this.props.boletim.atuacao,

          descricao: this.props.boletim.descricao,
          observacao: this.props.boletim.observacao,

          Envolvidos: this.props.boletim.Envolvidos ?? [],
          Apreensoes: this.props.boletim.Apreensoes ?? [],
          Providencias: this.props.boletim.Providencias ?? []
        }}
        validate={(values) => {
          const errors = {};

          if (!values.solicitante_registro) {
            errors.solicitante_registro = "Campo obrigatório";
          }
          if (!values.solicitante_nome) {
            errors.solicitante_nome = "Campo obrigatório";
          }

          if (values.incidente) {
            if (moment(values.incidente) > moment(values.acionamento)) {
              errors.incidente =
                "O incidente não pode ter acontecido depois de ter sido acionado o orgão pertinente!";
            }
          } else {
            errors.incidente = "Campo obrigatório";
          }
          if (values.acionamento) {
            if (moment(values.acionamento) < moment(values.incidente)) {
              errors.acionamento =
                "O acionamento não pode ter ocorrido antes do acontecimento do incidente!";
            }
          } else {
            errors.acionamento = "Campo obrigatório";
          }
          if (values.inicio) {
            if (moment(values.inicio) > moment(values.termino)) {
              errors.inicio =
                "O início da ação não pode começar depois do término da mesma!";
            }
          } else {
            errors.inicio = "Campo obrigatório";
          }
          if (values.termino) {
            if (moment(values.termino) < moment(values.inicio)) {
              errors.termino =
                "Uma ação não pode ser terminada sem ter começada!";
            }
          } else {
            errors.termino = "Campo obrigatório";
          }

          if (!values.endereco) {
            errors.endereco = "Campo obrigatório";
          }
          if (!values.CidadeId) {
            errors.CidadeId = "Campo obrigatório";
          }
          if (!values.visibilidade) {
            errors.visibilidade = "Campo obrigatório";
          }
          if (!values.clima) {
            errors.clima = "Campo obrigatório";
          }


          if (!values.origem_id) {
            errors.origem_id = "Campo obrigatório";
          }
          if (!values.natureza_id) {
            errors.natureza_id = "Campo obrigatório";
          }

          if (!values.acao_id) {
            errors.acao_id = "Campo obrigatório";
          }
          if (!values.atuacao) {
            errors.atuacao = "Campo obrigatório";
          }

          if (!values.descricao) {
            errors.descricao = "Campo obrigatório";
          }

          if (Object.keys(errors).length) errors.info = true;

          const envolvidos = [];

          values.Envolvidos.forEach((envolvido, key) => {
            const error = {};
            if (!envolvido.nome) {
              error.nome = "Campo obrigatório";
            }
            if (!envolvido.sexo) {
              error.sexo = "Campo obrigatório";
            }
            if (!envolvido.idade) {
              error.idade = "Campo obrigatório";
            }
            if (!envolvido.envolvimento) {
              error.envolvimento = "Campo obrigatório";
            }
            if (!envolvido.destino_id) {
              error.destino_id = "Campo obrigatório";
            }
            if (!envolvido.integridade_id) {
              error.integridade_id = "Campo obrigatório";
            }
            if (!envolvido.raca_id) {
              error.raca_id = "Campo obrigatório";
            }


            if (Object.keys(error).length) envolvidos[key] = error;
          });

          if (envolvidos.length)
            errors.Envolvidos = envolvidos;

          //APREENSAO
          const apreensoes = [];

          values.Apreensoes.forEach((apreensao, key) => {
            const error = {};
            if (!apreensao.MaterialId) {
              error.MaterialId = "Campo obrigatório";
            }
            if (!apreensao.quantidade) {
              error.quantidade = "Campo obrigatório";
            }
            if (Object.keys(error).length) apreensoes[key] = error;
          });

          if (apreensoes.length) errors.Apreensoes = apreensoes;

          //PROVIDENCIAS
          const providencias = [];

          values.Providencias.forEach((providencia, key) => {
            const error = {};
            if (!providencia.orgao) {
              error.orgao = "Campo obrigatório";
            }
            if (!providencia.detalhamento) {
              error.detalhamento = "Campo obrigatório";
            }
            if (Object.keys(error).length) providencias[key] = error;
          });

          if (providencias.length) errors.Providencias = providencias;

          return errors;
        }}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={(values, { setSubmitting }) => {
          this.save(values, (success, body) => {
            setSubmitting(false);
            if (success) {
              this.context.openModal({
                titulo: "Registro gravado com sucesso!",
                onClose: () => {this.context.closeModal(); this.context.setContent("BoletimList");},
                conteudo:
                  <div>
                    <h3>O que deseja fazer?</h3>
                    <div className="d-flex justify-content-between mt-3">
                      <Button variant="primary" onClick={() => {this.context.closeModal(); this.context.setContent("BoletimPrint", { boletim: body });}}>
                        <FontAwesomeIcon icon={faPrint} /> Imprimir
                      </Button>
                      <Button variant="success" onClick={() => {this.context.closeModal(); this.context.setContent("BoletimForm", {boletim: {}});}}>
                        <FontAwesomeIcon icon={faFileMedical} /> Registrar novo Boletim
                      </Button>
                      <Button variant="light" onClick={() => {this.context.closeModal(); this.context.setContent("BoletimList");}}>
                        <FontAwesomeIcon icon={faTimes} /> Fechar
                      </Button>
                    </div>
                  </div>
              });
            } else {
              this.context.addToast({
                titulo: "Aviso",
                conteudo: "Verifique os dados informados e tente novamente.",
              });
            }
          });
        }}
      >
        {({ isSubmitting, values, setFieldValue, errors }) => {
          console.log(values, errors);
          return (
            <Form className="container-fluid">
              <h3 className="d-flex justify-content-between">
                <div><FontAwesomeIcon icon={faFileAlt} /> Editar Boletim</div>
                <FormGroup className="text-nowrap">
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={() => this.context.setContent("BoletimList")}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                    &nbsp; Fechar
                  </Button>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    variant="primary"
                    className="ml-2"
                  >
                    <FontAwesomeIcon icon={faSave} />
                    &nbsp; Gravar Alterações
                  </Button>
                </FormGroup>
              </h3>
              <hr />
              <Tabs className="mb-3">
                <Tab title={<span className={errors.info && "text-danger"}><FontAwesomeIcon icon={faEdit} /> Informações Principais</span>} eventKey="Informações Principais">
                  <Row>
                    <Col md={9}>
                      <FormGroup>
                        <BSForm.Label>Nome do Usuário</BSForm.Label>
                        <Field
                          type="text"
                          value={this.context.usuario.nome}
                          disabled={true}
                          className="form-control"
                        />
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <BSForm.Label>Órgão</BSForm.Label>
                        <Field
                          type="text"
                          value={this.context.usuario.Orgao.sigla}
                          disabled={true}
                          className="form-control"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={9}>
                      <FormGroup>
                        <BSForm.Label>Nome do Solicitante</BSForm.Label>
                        <ErrorMessage
                          name="solicitante_nome"
                          component="span"
                          className="text-danger small ml-2"
                        />
                        <Field
                          type="text"
                          name="solicitante_nome"
                          className="form-control"
                        />
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <BSForm.Label>Registro do Solicitante</BSForm.Label>
                        <ErrorMessage
                          name="solicitante_registro"
                          component="span"
                          className="text-danger small ml-2"
                        />
                        <Field
                          type="text"
                          name="solicitante_registro"
                          className="form-control"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3}>
                      <FormGroup>
                        <BSForm.Label>Data do Incidente</BSForm.Label>
                        <ErrorMessage
                          name="incidente"
                          component="span"
                          className="text-danger small ml-2"
                        />
                        <Field
                          type="datetime-local"
                          name="incidente"
                          className="form-control"
                        />
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <BSForm.Label>Data do Acionamento</BSForm.Label>
                        <ErrorMessage
                          name="acionamento"
                          component="span"
                          className="text-danger small ml-2"
                        />
                        <Field
                          type="datetime-local"
                          name="acionamento"
                          className="form-control"
                        />
                      </FormGroup>
                    </Col>

                    <Col md={3}>
                      <FormGroup>
                        <BSForm.Label>Início da Ação</BSForm.Label>
                        <ErrorMessage
                          name="inicio"
                          component="span"
                          className="text-danger small ml-2"
                        />
                        <Field
                          type="datetime-local"
                          name="inicio"
                          className="form-control"
                        />
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <BSForm.Label>Término da Ação</BSForm.Label>
                        <ErrorMessage
                          name="termino"
                          component="span"
                          className="text-danger small ml-2"
                        />
                        <Field
                          type="datetime-local"
                          name="termino"
                          className="form-control"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <FormGroup>
                        <BSForm.Label>Endereço da Ocorrência</BSForm.Label>
                        <ErrorMessage
                          name="endereco"
                          component="span"
                          className="text-danger small ml-2"
                        />
                        <Field
                          as="textarea"
                          name="endereco"
                          className="form-control"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <FormGroup>
                        <BSForm.Label>Cidade</BSForm.Label>
                        <ErrorMessage
                          name="CidadeId"
                          component="span"
                          className="text-danger small ml-2"
                        />
                        <Select
                          name="CidadeId"
                          noOptionsMessage={() => "Nada encontrado."}
                          placeholder="Pesquisar..."
                          options={this.state.cidades}
                          value={this.state.cidades ? this.state.cidades.find(option => option.value === values.CidadeId) : ''}
                          onChange={option => {
                            setFieldValue('CidadeId', option ? option.value : null);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <BSForm.Label>Visibilidade</BSForm.Label>
                        <ErrorMessage
                          name="visibilidade"
                          component="span"
                          className="text-danger small ml-2"
                        />
                        <Select
                          name="visibilidade"
                          noOptionsMessage={() => "Nada encontrado."}
                          placeholder="Pesquisar..."
                          options={this.state.visibilidades}
                          value={this.state.visibilidades ? this.state.visibilidades.find(option => option.value === values.visibilidade) : ''}
                          onChange={option => {
                            setFieldValue('visibilidade', option ? option.value : null);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <BSForm.Label>Clima</BSForm.Label>
                        <ErrorMessage
                          name="clima"
                          component="span"
                          className="text-danger small ml-2"
                        />
                        <Select
                          name="clima"
                          noOptionsMessage={() => "Nada encontrado."}
                          placeholder="Pesquisar..."
                          options={this.state.climas}
                          value={this.state.climas ? this.state.climas.find(option => option.value === values.clima) : ''}
                          onChange={option => {
                            setFieldValue('clima', option ? option.value : null);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <FormGroup>
                        <BSForm.Label>Origem da Ocorrência</BSForm.Label>
                        <ErrorMessage
                          name="origem_id"
                          component="span"
                          className="text-danger small ml-2"
                        />
                        <Select
                          name="origem_id"
                          noOptionsMessage={() => "Nada encontrado."}
                          placeholder="Pesquisar..."
                          options={this.state.atributos.filter(at => at.descricao === "Origem")}
                          value={this.state.atributos ? this.state.atributos.find(option => option.value === values.origem_id) : null}
                          onChange={option => {
                            setFieldValue('origem_id', option ? option.value : null);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={8}>
                      <FormGroup>
                        <BSForm.Label>Natureza da Ocorrência</BSForm.Label>
                        <ErrorMessage
                          name="natureza_id"
                          component="span"
                          className="text-danger small ml-2"
                        />
                        <Select
                          noOptionsMessage={() => "Nada encontrado."}
                          placeholder="Pesquisar..."
                          options={this.state.atributos.filter(at => at.descricao === "Natureza")}
                          value={this.state.atributos ? this.state.atributos.find(option => option.value === values.natureza_id) : ''}
                          onChange={option => {
                            setFieldValue('natureza_id', option ? option.value : null);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={8}>
                      <FormGroup>
                        <BSForm.Label>Ação Realizada</BSForm.Label>
                        <ErrorMessage
                          name="acao_id"
                          component="span"
                          className="text-danger small ml-2"
                        />
                        <Select
                          name="acao_id"
                          noOptionsMessage={() => "Nada encontrado."}
                          placeholder="Pesquisar..."
                          options={this.state.atributos.filter(at => at.descricao === "Ação")}
                          value={this.state.atributos.find(option => option.value === values.acao_id)}
                          onChange={option => {
                            setFieldValue('acao_id', option ? option.value : null);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <BSForm.Label>Tipo de Atuação</BSForm.Label>
                        <ErrorMessage
                          name="atuacao"
                          component="span"
                          className="text-danger small ml-2"
                        />
                        <Select
                          name="atuacao"
                          noOptionsMessage={() => "Nada encontrado."}
                          placeholder="Pesquisar..."
                          options={this.state.atuacoes}
                          value={this.state.atuacoes ? this.state.atuacoes.find(option => option.value === values.atuacao) : ''}
                          onChange={option => {
                            setFieldValue('atuacao', option ? option.value : null);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <BSForm.Label>Descrição da Ocorrência</BSForm.Label>
                        <ErrorMessage
                          name="descricao"
                          component="span"
                          className="text-danger small ml-2"
                        />
                        <Field
                          as="textarea"
                          name="descricao"
                          className="form-control"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <BSForm.Label>Observações</BSForm.Label>
                        <Field
                          as="textarea"
                          name="observacao"
                          className="form-control"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Tab>

                <Tab title={<span className={errors.Envolvidos && "text-danger"}><FontAwesomeIcon icon={faUsers} /> Envolvidos</span>} eventKey="Envolvidos">
                  <Row className="">
                    <Col>
                      <Button className="mb-3" variant="success" onClick={() => this.setState(({ envolvidoKey: values.Envolvidos.length + 1 }), () => setFieldValue("Envolvidos", [...values.Envolvidos, { nome: null, sexo: null, envolvimento: null, idade: null, raca_id: null, integridade_id: null, destino_id: null }]))}>
                        <FontAwesomeIcon icon={faPlus} /> Adicionar Envolvido
                      </Button>
                    </Col>
                  </Row>
                  <Accordion activeKey={this.state.envolvidoKey}>
                    {values.Envolvidos.map((envolvido, key) =>
                      <Card>
                        <Accordion.Toggle
                          className={"d-flex justify-content-between " + (errors.Envolvidos && errors.Envolvidos[key] ? "text-danger" : null)} as={Card.Header} variant="link" eventKey={key + 1} onClick={() => this.setState(state => ({ envolvidoKey: state.envolvidoKey === key + 1 ? null : key + 1 }))}>
                          <div>{envolvido.envolvimento && envolvido.nome ? `${envolvido.envolvimento} - ${envolvido.nome}` : "(Novo Envolvido)"}</div>
                          <div>
                            <Button size="sm" variant="danger"
                              onClick={e => { e.stopPropagation(); window.confirm("Deseja excluir o envolvido?") && setFieldValue("Envolvidos", values.Envolvidos.filter(env => env !== envolvido)) }}>
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>
                          </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={key + 1}>
                          <Card.Body>
                            <Row>
                              <Col md={3}>
                                <FormGroup>
                                  <BSForm.Label>Envolvimento</BSForm.Label>
                                  <ErrorMessage
                                    name={`Envolvidos[${key}].envolvimento`}
                                    component="span"
                                    className="text-danger small ml-2"
                                  />
                                  <Field
                                    as="select"
                                    name={`Envolvidos[${key}].envolvimento`}
                                    className="form-control"
                                  >
                                    <option value="">Selecione...</option>
                                    <option value="Testemunha">Testemunha</option>
                                    <option value="Vítima">Vítima</option>
                                    <option value="Acusado">Acusado</option>
                                    <option value="Condutor">Condutor</option>

                                  </Field>
                                </FormGroup>
                              </Col>
                              <Col md={9}>
                                <FormGroup>
                                  <BSForm.Label>Nome</BSForm.Label>
                                  <ErrorMessage
                                    name={`Envolvidos[${key}].nome`}
                                    component="span"
                                    className="text-danger small ml-2"
                                  />
                                  <Field
                                    type="text"
                                    name={`Envolvidos[${key}].nome`}
                                    className="form-control"
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={3}>
                                <FormGroup>
                                  <BSForm.Label>RG</BSForm.Label>
                                  <ErrorMessage
                                    name={`Envolvidos[${key}].rg`}
                                    component="span"
                                    className="text-danger small ml-2"
                                  />
                                  <Field
                                    type="text"
                                    name={`Envolvidos[${key}].rg`}
                                    className="form-control"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={3}>
                                <FormGroup>
                                  <BSForm.Label>Sexo</BSForm.Label>
                                  <ErrorMessage
                                    name={`Envolvidos[${key}].sexo`}
                                    component="span"
                                    className="text-danger small ml-2"
                                  />
                                  <Field
                                    as="select"
                                    name={`Envolvidos[${key}].sexo`}
                                    className="form-control"
                                  >
                                    <option value="">Selecione...</option>
                                    <option value="Masculino">Masculino</option>
                                    <option value="Feminino">Feminino</option>

                                  </Field>
                                </FormGroup>
                              </Col>
                              <Col md={3}>
                                <FormGroup>
                                  <BSForm.Label>Idade</BSForm.Label>
                                  <ErrorMessage
                                    name={`Envolvidos[${key}].idade`}
                                    component="span"
                                    className="text-danger small ml-2"
                                  />
                                  <Field
                                    type="number"
                                    name={`Envolvidos[${key}].idade`}
                                    className="form-control"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={3}>
                                <FormGroup>
                                  <BSForm.Label>Raça</BSForm.Label>
                                  <ErrorMessage
                                    name={`Envolvidos[${key}].raca_id`}
                                    component="span"
                                    className="text-danger small ml-2"
                                  />
                                  <Select
                                    noOptionsMessage={() => "Nada encontrado."}
                                    placeholder="Pesquisar..."
                                    options={this.state.atributos.filter(at => at.descricao === "Raça")}
                                    value={this.state.atributos ? this.state.atributos.find(option => option.value === envolvido.raca_id) : ''}
                                    onChange={option => {
                                      setFieldValue(`Envolvidos[${key}].raca_id`, option ? option.value : null);
                                    }}
                                  />
                                  {/* name={`Envolvidos[${key}].raca_id`} */}
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <FormGroup>
                                  <BSForm.Label>Endereço</BSForm.Label>
                                  <Field
                                    as="textarea"
                                    name={`Envolvidos[${key}].endereco`}
                                    className="form-control"
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6}>
                                <FormGroup>
                                  <BSForm.Label>Contato</BSForm.Label>
                                  <Field
                                    type="text"
                                    name={`Envolvidos[${key}].contato`}
                                    className="form-control"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={6}>
                                <FormGroup>
                                  <BSForm.Label>Integridade</BSForm.Label>
                                  <ErrorMessage
                                    name={`Envolvidos[${key}].integridade_id`}
                                    component="span"
                                    className="text-danger small ml-2"
                                  />
                                  <Select
                                    noOptionsMessage={() => "Nada encontrado."}
                                    placeholder="Pesquisar..."
                                    options={this.state.atributos.filter(at => at.descricao === "Integridade")}
                                    value={this.state.atributos ? this.state.atributos.find(option => option.value === envolvido.integridade_id) : ''}
                                    onChange={option => {
                                      setFieldValue(`Envolvidos[${key}].integridade_id`, option ? option.value : null);
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <hr />
                            <Row>
                              <Col md={3}>
                                <FormGroup>
                                  <BSForm.Label>Destino</BSForm.Label>
                                  <ErrorMessage
                                    name={`Envolvidos[${key}].destino_id`}
                                    component="span"
                                    className="text-danger small ml-2"
                                  />
                                  <Select
                                    noOptionsMessage={() => "Nada encontrado."}
                                    placeholder="Pesquisar..."
                                    menuPortalTarget={document.body}
                                    options={this.state.atributos.filter(at => at.descricao === "Destino")}
                                    value={this.state.atributos ? this.state.atributos.find(option => option.value === envolvido.destino_id) : ''} onChange={option => {
                                      setFieldValue(`Envolvidos[${key}].destino_id`, option ? option.value : null);
                                    }}
                                  />
                                </FormGroup>
                              </Col>

                              <Col md={6}>
                                <FormGroup>
                                  <BSForm.Label>Unidade de Apresentação</BSForm.Label>
                                  <Field
                                    type="text"
                                    name={`Envolvidos[${key}].apresentacao_unidade`}
                                    className="form-control"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={3}>
                                <FormGroup>
                                  <BSForm.Label>Enquadramento</BSForm.Label>
                                  <Field
                                    type="text"
                                    name={`Envolvidos[${key}].apresentacao_enquadramento`}
                                    className="form-control"
                                  />
                                </FormGroup>
                              </Col>

                            </Row>
                            <Row>
                              <Col md={9}>
                                <FormGroup>
                                  <BSForm.Label>Nome da Autoridade</BSForm.Label>
                                  <Field
                                    type="text"
                                    name={`Envolvidos[${key}].apresentacao_autoridade`}
                                    className="form-control"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={3}>
                                <FormGroup>
                                  <BSForm.Label>Registro da Autoridade</BSForm.Label>
                                  <Field
                                    type="text"
                                    name={`Envolvidos[${key}].apresentacao_registro`}
                                    className="form-control"
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    )}
                  </Accordion>
                </Tab>
                <Tab title={<span className={errors.Apreensoes && "text-danger"}><FontAwesomeIcon icon={faCubes} /> Apreensões</span>} eventKey="Apreensões">
                  <Row>
                    <Col>
                      <Button className="mb-3" variant="success" onClick={() => setFieldValue("Apreensoes", [...values.Apreensoes, { MaterialId: null, quantidade: 0 }])}>
                        <FontAwesomeIcon icon={faPlus} /> Adicionar Apreensão
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    {values.Apreensoes.map((apreensao, key) => {
                      return (
                        <Col md={4} key={key}>
                          <Card className="mb-5">
                            <Card.Header className="d-flex justify-content-between">

                              {this.state.materiais.find(material => material.value === apreensao.MaterialId)?.label ?? "(Nova Apreensão)"}
                              {apreensao.quantidade ? ` - ${apreensao.quantidade} un.` : ""}
                              <div>
                                <Button size="sm" variant="danger"
                                  onClick={e => { e.stopPropagation(); window.confirm("Deseja excluir a apreensão?") && setFieldValue("Apreensoes", values.Apreensoes.filter(apr => apr !== apreensao)) }}>
                                  <FontAwesomeIcon icon={faTrash} />
                                </Button>
                              </div>
                            </Card.Header>
                            <Card.Body>
                              <Row>
                                <Col>
                                  <FormGroup>
                                    <BSForm.Label>Material Apreendido</BSForm.Label>
                                    <ErrorMessage
                                      name={`Apreensoes[${key}].MaterialId`}
                                      component="span"
                                      className="text-danger small ml-2"
                                    />
                                    <Select
                                      noOptionsMessage={() => "Nada encontrado."}
                                      placeholder="Pesquisar..."
                                      options={this.state.materiais}
                                      value={this.state.materiais.find((option) => option.value === apreensao.MaterialId)}
                                      onChange={option => {
                                        setFieldValue(`Apreensoes[${key}].MaterialId`, option ? option.value : null);
                                      }}
                                    />

                                  </FormGroup>

                                </Col>
                                <Col>
                                  <FormGroup>
                                    <BSForm.Label>Quantidade</BSForm.Label>
                                    <ErrorMessage
                                      name={`Apreensoes[${key}].quantidade`}
                                      component="span"
                                      className="text-danger small ml-2"
                                    />
                                    <Field
                                      type="number"
                                      name={`Apreensoes[${key}].quantidade`}
                                      className="form-control"
                                    />
                                  </FormGroup>
                                </Col>


                              </Row>


                            </Card.Body>
                          </Card>
                        </Col>

                      )


                    })}
                  </Row>

                </Tab>
                <Tab title={<span className={errors.Providencias && "text-danger"}><FontAwesomeIcon icon={faBullhorn} /> Providências</span>} eventKey="Providências">
                  <Row>

                    <Col>
                      <Button className="mb-3" variant="success" onClick={() => setFieldValue("Providencias", [...values.Providencias, { orgao: null, detalhamento: null }])}>
                        <FontAwesomeIcon icon={faPlus} /> Adicionar Providência
                      </Button>
                    </Col>
                  </Row>
                  <Row>

                    {values.Providencias.map((providencia, key) => {
                      return (

                        <Col md={4}>
                          <Card className="mb-5">
                            <Card.Header className="d-flex justify-content-between">
                              Providência
                              <div>
                                <Button size="sm" variant="danger"
                                  onClick={e => { e.stopPropagation(); window.confirm("Deseja excluir o envolvido?") && setFieldValue("Providencias", values.Providencias.filter(prov => prov !== providencia)) }}>
                                  <FontAwesomeIcon icon={faTrash} />
                                </Button>
                              </div>
                            </Card.Header>
                            <Card.Body>

                              <FormGroup>
                                <BSForm.Label>Órgão</BSForm.Label>
                                <ErrorMessage
                                  name={`Providencias[${key}].orgao`}
                                  component="span"
                                  className="text-danger small ml-2"
                                />
                                <Field
                                  type="text"
                                  name={`Providencias[${key}].orgao`}
                                  className="form-control"
                                />
                              </FormGroup>

                              <FormGroup>
                                <BSForm.Label>Detalhamento</BSForm.Label>
                                <ErrorMessage
                                  name={`Providencias[${key}].detalhamento`}
                                  component="span"
                                  className="text-danger small ml-2"
                                />
                                <Field
                                  type="text"
                                  name={`Providencias[${key}].detalhamento`}
                                  className="form-control"
                                />
                              </FormGroup>
                            </Card.Body>
                          </Card>
                        </Col>)
                    })
                    }
                  </Row>
                </Tab>
              </Tabs>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

export default BoletimForm;
