import React from 'react';
import AcessoForm from './AcessoForm';
import { AppContext } from '../../../context';
import ModelList from '../ModelList';

class AcessoList extends React.Component {

    static contextType = AppContext;

    render() {
        return (
            <ModelList
                url={this.context.config.BACKEND_URL + '/acesso'}
                title="Lista de Acessos"
                modelIcon="faClock"               
                modelName="acesso"
                modelForm={AcessoForm}
            />
        );
    }
}

export default AcessoList;