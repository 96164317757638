import React from 'react';
import TipoBemForm from './TipoBemForm';
import ModelList from '../ModelList';
import { AppContext } from '../../../context';

class TipoBemList extends React.Component {   
    
    static contextType = AppContext;

    render(){
        return (
            <ModelList 
                url={this.context.config.BACKEND_URL + '/tipobem'}
                title="Lista de Tipos de Bens"
                modelName="tipobem"
                modelIcon="faList"
                modelForm={TipoBemForm}
                useAdd={true}
            />
        );
    }
}

export default TipoBemList;

