import * as Icons from "@fortawesome/free-solid-svg-icons";
import { faPlus, faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Form as BSForm, FormGroup, Tabs, Tab, Accordion, Card, AccordionToggle, AccordionCollapse } from "react-bootstrap";
import Request from "../../../request";
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { AppContext } from "../../../context";
import Contents from '../../contents';

class ModuloForm extends React.Component {

    static contextType = AppContext;

    state = {
        permissoes: [],
        conteudos: Object.keys(Contents).sort().map(content => ({ label: content, value: content })),
        icones: Object.keys(Icons).sort().filter(icon => icon !== 'fas' && icon !== 'prefix').map(icon => ({ label: <><FontAwesomeIcon icon={Icons[icon]}/>&nbsp;{icon.replace(/([A-Z])/g, ' $1').replace(/^fa /g, '')}</>, value: icon }))
    }

    componentDidMount() {
        Request('GET', this.context.config.BACKEND_URL + '/permissao/options', this.context.token)
            .then(res => {
                this.setState({ permissoes: res.body });
            })
            .catch(err => {
                this.context.addToast({ title: 'Erro', text: 'Falha na recuperação da lista de permissões: ' + err.toString() });
            });
    }

    save(values, callback) {
        const method = this.props.modulo.id ? 'PUT' : 'POST';
        const url = this.context.config.BACKEND_URL + '/modulo' + (this.props.modulo.id ? '/' + this.props.modulo.id : '');

        Request(method, url, this.context.token)
            .send(values)
            .then(res => {
                this.context.addToast({ titulo: "Successo", conteudo: "Registro atualizado com sucesso." });
                callback();
                if (this.props.onSave) this.props.onSave();
                this.context.closeModal();
            })
            .catch(err => {
                this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na gravação do registro." });
                callback();
            });

    }

    render() {
        return (
            <Formik
                initialValues={{
                    nome: this.props.modulo.nome,
                    titulo: this.props.modulo.titulo,
                    descricao: this.props.modulo.descricao,
                    imagem: this.props.modulo.imagem,
                    conteudo: this.props.modulo.conteudo,
                    Menus: (this.props.modulo.Menus ?? []).map((menu, i) => ({ ...menu, ordem: i, Submenus: menu.Submenus?.map((submenu, j) => ({ ...submenu, ordem: j })) ?? [] }))
                }}
                validateOnChange={false}
                validate={values => {
                    const errors = {};

                    if (!values.nome) errors.nome = 'Campo obrigatório';
                    if (!values.titulo) errors.titulo = 'Campo obrigatório';
                    if (!values.descricao) errors.descricao = 'Campo obrigatório';

                    if (errors.nome || errors.titulo || errors.descricao) errors.modulo = true;

                    errors.Menus = [];

                    values.Menus.forEach((menu, menuKey) => {
                        errors.Menus[menuKey] = {};

                        if (!menu.titulo) errors.Menus[menuKey] = {
                            titulo: "Campo Obrigatório",
                            error: true
                        };

                        errors.Menus[menuKey].Submenus = [];
                        menu.Submenus.forEach((submenu, submenuKey) => {
                            errors.Menus[menuKey].Submenus[submenuKey] = {};
                            if (!submenu.titulo) errors.Menus[menuKey].Submenus[submenuKey].titulo = "Campo Obrigatório";
                            if (!submenu.conteudo) errors.Menus[menuKey].Submenus[submenuKey].conteudo = "Campo Obrigatório";
                            if (!submenu.titulo || !submenu.conteudo) errors.Menus[menuKey].Submenus[submenuKey].error = true;
                        })
                        if (errors.Menus[menuKey].Submenus.some(submenu => submenu.error)) errors.Menus[menuKey].error = true;
                    });

                    if (errors.Menus.some(menu => menu.error)) errors.menus = true;

                    return (
                        errors.modulo || errors.menus
                    ) ? errors : {};
                }}
                onSubmit={(values, { setSubmitting }) => {
                    this.save(values, () => setSubmitting(false));
                }}
            >
                {({ isSubmitting, values, errors, setFieldValue, submitForm }) => (
                    <Form>
                        <Tabs defaultActiveKey="modulo">
                            <Tab eventKey="modulo" title={<span className={errors.modulo ? 'text-danger' : ''}>Módulo</span>}>
                                <FormGroup className="mt-2">
                                    <BSForm.Label>Nome</BSForm.Label><ErrorMessage name="nome" component="span" className="text-danger small ml-2" />
                                    <Field type="text" name="nome" className="form-control" value={values.nome} />
                                </FormGroup>
                                <FormGroup>
                                    <BSForm.Label>Título</BSForm.Label><ErrorMessage name="titulo" component="span" className="text-danger small ml-2" />
                                    <Field type="text" name="titulo" className="form-control" value={values.titulo} />
                                </FormGroup>
                                <FormGroup>
                                    <BSForm.Label>Imagem</BSForm.Label><ErrorMessage name="imagem" component="span" className="text-danger small ml-2" />
                                    <Field type="text" name="imagem" className="form-control" value={values.imagem} />
                                </FormGroup>
                                <FormGroup>
                                    <BSForm.Label>Descrição</BSForm.Label><ErrorMessage name="descricao" component="span" className="text-danger small ml-2" />
                                    <Field as="textarea" name="descricao" className="form-control" value={values.descricao} />
                                </FormGroup>
                                <FormGroup>
                                    <BSForm.Label>Conteúdo</BSForm.Label><ErrorMessage name="conteudo" component="span" className="text-danger small ml-2" />
                                    <CreatableSelect
                                        name={`conteudo`}
                                        menuShouldBlockScroll={true}
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                        noOptionsMessage={() => "Nada encontrado."}
                                        onCreateOption={label => {
                                            this.setState(state => ({
                                                conteudos: [...state.conteudos, { label, value: label }]
                                            }), () => setFieldValue('conteudo', label));
                                        }}
                                        placeholder="Pesquise ou Digite"
                                        formatCreateLabel={label => "Definir como " + label}
                                        options={this.state.conteudos}
                                        value={this.state.conteudos.find(option => option.value === values.conteudo)}
                                        onChange={option => setFieldValue(`conteudo`, option ? option.value : null)}
                                    />                                </FormGroup>
                            </Tab>
                            <Tab eventKey="menus" title={<span className={errors.menus ? 'text-danger' : ''}>Menus</span>}>
                                <FieldArray name="Menus" validateOnChange={false} render={({ remove, replace, push }) => (
                                    <div>
                                        <Accordion className="my-3">
                                            {values.Menus.sort((a, b) => a.ordem - b.ordem).map((menu, menuKey) => (
                                                <Card key={menuKey}>
                                                    <AccordionToggle role="button" as={Card.Header} eventKey={menuKey + 1} className="d-flex justify-content-between">
                                                        <div>
                                                            {menu.icone ? <FontAwesomeIcon icon={Icons[menu.icone]} /> : null} &nbsp; <span className={errors.Menus && errors.Menus[menuKey] && errors.Menus[menuKey].error ? 'text-danger' : ''}>{menu.titulo}</span>
                                                        </div>
                                                        <div>
                                                            {(menuKey !== 0) && <Button className="mr-1" variant="info" size="sm" onClick={(e) => { e.stopPropagation(); setFieldValue(`Menus[${menuKey}].ordem`, values.Menus[menuKey].ordem - 1); setFieldValue(`Menus[${menuKey - 1}].ordem`, values.Menus[menuKey - 1].ordem + 1) }}>
                                                                <FontAwesomeIcon icon={Icons.faCaretUp} />
                                                            </Button>}
                                                            {(menuKey !== values.Menus.length - 1) && <Button className="mr-1" variant="info" size="sm" onClick={(e) => { e.stopPropagation(); setFieldValue(`Menus[${menuKey}].ordem`, values.Menus[menuKey].ordem + 1); setFieldValue(`Menus[${menuKey + 1}].ordem`, values.Menus[menuKey + 1].ordem - 1) }}>
                                                                <FontAwesomeIcon icon={Icons.faCaretDown} />
                                                            </Button>}
                                                            <Button type="button" variant="danger" size="sm" onClick={(e) => { e.stopPropagation(); setFieldValue("Menus", values.Menus.filter((m, i) => i !== menuKey).map((menu, i) => ({ ...menu, ordem: i }))) }}>
                                                                <FontAwesomeIcon icon={faTimes} />
                                                            </Button>
                                                        </div>
                                                    </AccordionToggle>
                                                    <AccordionCollapse eventKey={menuKey + 1}>
                                                        <Card.Body>
                                                            <Field type="hidden" name={`Menus[${menuKey}].id`} value={values.Menus[menuKey].id} />
                                                            <Field type="hidden" name={`Menus[${menuKey}].ModuloId`} value={values.Menus[menuKey].ModuloId} />
                                                            <Field type="hidden" name={`Menus[${menuKey}].ordem`} value={values.Menus[menuKey].ordem} />
                                                            <FormGroup>
                                                                <BSForm.Label>Título</BSForm.Label><ErrorMessage name={`Menus[${menuKey}].titulo`} component="span" className="text-danger small ml-2" />
                                                                <Field type="text" name={`Menus[${menuKey}].titulo`} className="form-control" value={values.Menus[menuKey].titulo} />
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <BSForm.Label>Ícone</BSForm.Label><ErrorMessage name={`Menus[${menuKey}].icone`} component="span" className="text-danger small ml-2" />
                                                                <Select
                                                                    name={`Menus[${menuKey}].icone`}
                                                                    menuShouldBlockScroll={true}
                                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                                    noOptionsMessage={() => "Nada encontrado."}
                                                                    placeholder="Pesquise ou Digite"
                                                                    options={this.state.icones}
                                                                    value={this.state.icones.find(option => option.value === values.Menus[menuKey].icone)}
                                                                    onChange={option => setFieldValue(`Menus[${menuKey}].icone`, option ? option.value : null)}
                                                                />
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <BSForm.Label>Conteúdo</BSForm.Label><ErrorMessage name={`Menus[${menuKey}].conteudo`} component="span" className="text-danger small ml-2" />
                                                                <CreatableSelect
                                                                    isClearable={true}
                                                                    name={`Menus[${menuKey}].conteudo`}
                                                                    menuShouldBlockScroll={true}
                                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                                    noOptionsMessage={() => "Nada encontrado."}
                                                                    onCreateOption={label => {
                                                                        this.setState(state => ({
                                                                            conteudos: [...state.conteudos, { label, value: label }]
                                                                        }), () => setFieldValue(`Menus[${menuKey}].conteudo`, label));
                                                                    }}
                                                                    placeholder="Pesquise ou Digite"
                                                                    formatCreateLabel={label => "Definir como " + label}
                                                                    options={this.state.conteudos}
                                                                    value={this.state.conteudos.find(option => option.value === values.Menus[menuKey].conteudo)}
                                                                    onChange={option => setFieldValue(`Menus[${menuKey}].conteudo`, option ? option.value : null)}
                                                                />
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <BSForm.Label>Permissão</BSForm.Label><ErrorMessage name={`Menus[${menuKey}].PermissaoId`} component="span" className="text-danger small ml-2" />
                                                                <Select
                                                                    name={`Menus[${menuKey}].PermissaoId`}
                                                                    isClearable={true}
                                                                    menuShouldBlockScroll={true}
                                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                                    noOptionsMessage={() => "Nada encontrado."}
                                                                    placeholder="Pesquisar..."
                                                                    options={this.state.permissoes}
                                                                    value={this.state.permissoes ? this.state.permissoes.find(option => option.value === values.Menus[menuKey].PermissaoId) : ''}
                                                                    onChange={option => setFieldValue(`Menus[${menuKey}].PermissaoId`, option ? option.value : null)}
                                                                />
                                                            </FormGroup>
                                                            <BSForm.Label as="strong">Submenus</BSForm.Label>
                                                            <Accordion className="mb-3">
                                                                {menu.Submenus.sort((a, b) => a.ordem - b.ordem).map((submenu, submenuKey) => (
                                                                    <Card key={submenuKey}>
                                                                        <Field type="hidden" name={`Menus[${menuKey}].Submenus[${submenuKey}].id`} value={values.Menus[menuKey].Submenus[submenuKey].id} />
                                                                        <Field type="hidden" name={`Menus[${menuKey}].Submenus[${submenuKey}].MenuId`} value={values.Menus[menuKey].Submenus[submenuKey].MenuId} />
                                                                        <Field type="hidden" name={`Menus[${menuKey}].Submenus[${submenuKey}].ordem`} value={values.Menus[menuKey].Submenus[submenuKey].ordem} />
                                                                        <AccordionToggle role="button" as={Card.Header} eventKey={submenuKey + 1} className="d-flex justify-content-between">
                                                                            <div>
                                                                                {submenu.icone ? <FontAwesomeIcon icon={Icons[submenu.icone]} /> : null}&nbsp; <span className={errors.Menus && errors.Menus[menuKey] && errors.Menus[menuKey].Submenus && errors.Menus[menuKey].Submenus[submenuKey] && errors.Menus[menuKey].Submenus[submenuKey].error ? 'text-danger' : ''}>{submenu.titulo}</span>
                                                                            </div>
                                                                            <div>
                                                                                {(submenuKey !== 0) && <Button className="mr-1" variant="info" size="sm" onClick={(e) => { e.stopPropagation(); setFieldValue(`Menus[${menuKey}].Submenus[${submenuKey}].ordem`, values.Menus[menuKey].Submenus[submenuKey].ordem - 1); setFieldValue(`Menus[${menuKey}].Submenus[${submenuKey - 1}].ordem`, values.Menus[menuKey].Submenus[submenuKey - 1].ordem + 1) }}>
                                                                                    <FontAwesomeIcon icon={Icons.faCaretUp} />
                                                                                </Button>}
                                                                                {(submenuKey !== values.Menus[menuKey].Submenus.length - 1) && <Button className="mr-1" variant="info" size="sm" onClick={(e) => { e.stopPropagation(); setFieldValue(`Menus[${menuKey}].Submenus[${submenuKey}].ordem`, values.Menus[menuKey].Submenus[submenuKey].ordem + 1); setFieldValue(`Menus[${menuKey}].Submenus[${submenuKey + 1}].ordem`, values.Menus[menuKey].Submenus[submenuKey + 1].ordem - 1) }}>
                                                                                    <FontAwesomeIcon icon={Icons.faCaretDown} />
                                                                                </Button>}
                                                                                <Button type="button" variant="danger" size="sm" onClick={(e) => { e.stopPropagation(); setFieldValue(`Menus[${menuKey}].Submenus`, values.Menus[menuKey].Submenus.filter((m, i) => i !== submenuKey).map((submenu, i) => ({ ...submenu, ordem: i }))) }}>
                                                                                    <FontAwesomeIcon icon={faTimes} />
                                                                                </Button>
                                                                            </div>
                                                                        </AccordionToggle>
                                                                        <AccordionCollapse eventKey={submenuKey + 1}>
                                                                            <Card.Body>
                                                                                <FormGroup>
                                                                                    <BSForm.Label>Título</BSForm.Label><ErrorMessage name={`Menus[${menuKey}].Submenus[${submenuKey}].titulo`} component="span" className="text-danger small ml-2" />
                                                                                    <Field type="text" name={`Menus[${menuKey}].Submenus[${submenuKey}].titulo`} className="form-control" value={values.Menus[menuKey].Submenus[submenuKey].titulo} />
                                                                                </FormGroup>
                                                                                <FormGroup>
                                                                                    <BSForm.Label>Ícone</BSForm.Label><ErrorMessage name={`Menus[${menuKey}].Submenus[${submenuKey}].icone`} component="span" className="text-danger small ml-2" />
                                                                                    <Select
                                                                                        name={`Menus[${menuKey}].Submenus[${submenuKey}].icone`}
                                                                                        menuShouldBlockScroll={true}
                                                                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                                                        noOptionsMessage={() => "Nada encontrado."}
                                                                                        placeholder="Pesquise ou Digite"
                                                                                        options={this.state.icones}
                                                                                        value={this.state.icones.find(option => option.value === values.Menus[menuKey].Submenus[submenuKey].icone)}
                                                                                        onChange={option => setFieldValue(`Menus[${menuKey}].Submenus[${submenuKey}].icone`, option ? option.value : null)}
                                                                                    />
                                                                                </FormGroup>
                                                                                <FormGroup>
                                                                                    <BSForm.Label>Conteúdo</BSForm.Label><ErrorMessage name={`Menus[${menuKey}].Submenus[${submenuKey}].conteudo`} component="span" className="text-danger small ml-2" />
                                                                                    <CreatableSelect
                                                                                        name={`Menus[${menuKey}].Submenus[${submenuKey}].conteudo`}
                                                                                        menuShouldBlockScroll={true}
                                                                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                                                        noOptionsMessage={() => "Nada encontrado."}
                                                                                        onCreateOption={label => {
                                                                                            this.setState(state => ({
                                                                                                conteudos: [...state.conteudos, { label, value: label }]
                                                                                            }), () => setFieldValue(`Menus[${menuKey}].Submenus[${submenuKey}].conteudo`, label));
                                                                                        }}
                                                                                        placeholder="Pesquise ou Digite"
                                                                                        formatCreateLabel={label => "Definir como " + label}
                                                                                        options={this.state.conteudos}
                                                                                        value={this.state.conteudos.find(option => option.value === values.Menus[menuKey].Submenus[submenuKey].conteudo)}
                                                                                        onChange={option => setFieldValue(`Menus[${menuKey}].Submenus[${submenuKey}].conteudo`, option ? option.value : null)}
                                                                                    />
                                                                                </FormGroup>
                                                                                <FormGroup>
                                                                                    <BSForm.Label>Permissão</BSForm.Label><ErrorMessage name={`Menus[${menuKey}].Submenus[${submenuKey}].PermissaoId`} component="span" className="text-danger small ml-2" />
                                                                                    <Select
                                                                                        name={`Menus[${menuKey}].Submenus[${submenuKey}].PermissaoId`}
                                                                                        isClearable={true}
                                                                                        menuShouldBlockScroll={true}
                                                                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                                                        noOptionsMessage={() => "Nada encontrado."}
                                                                                        placeholder="Pesquisar..."
                                                                                        options={this.state.permissoes}
                                                                                        value={this.state.permissoes ? this.state.permissoes.find(option => option.value === values.Menus[menuKey].Submenus[submenuKey].PermissaoId) : ''}
                                                                                        onChange={option => setFieldValue(`Menus[${menuKey}].Submenus[${submenuKey}].PermissaoId`, option ? option.value : null)}
                                                                                    />
                                                                                </FormGroup>
                                                                            </Card.Body>
                                                                        </AccordionCollapse>
                                                                    </Card>
                                                                ))}
                                                            </Accordion>
                                                            <FormGroup>
                                                                <Button type="button" variant="info" className="form-control" size="sm" onClick={() => replace(menuKey, { ...menu, Submenus: [...menu.Submenus, { ordem: menu.Submenus.length }] })}>
                                                                    <FontAwesomeIcon icon={faPlus} /> Adicionar Submenu
                                                                </Button>
                                                            </FormGroup>
                                                        </Card.Body>
                                                    </AccordionCollapse>
                                                </Card>
                                            ))}
                                        </Accordion>
                                        <Button type="button" variant="success" className="form-control mb-3" onClick={() => push({ Submenus: [], ordem: values.Menus.length })}>
                                            <FontAwesomeIcon icon={faPlus} /> Adicionar Menu
                                        </Button>
                                    </div>
                                )} />
                            </Tab>
                        </Tabs>
                        <FormGroup className="text-right">
                            <Button type="button" variant='secondary' onClick={() => this.context.closeModal()}>
                                <FontAwesomeIcon icon={faTimes} />&nbsp;
                                Fechar
                            </Button>
                            <Button type="submit" disabled={isSubmitting} variant='primary' className="ml-2">
                                <FontAwesomeIcon icon={faSave} />&nbsp;
                                Gravar Alterações
                            </Button>
                        </FormGroup>
                    </Form>
                )}
            </Formik>
        );
    }
}

export default ModuloForm;