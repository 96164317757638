import React from 'react';
import FuncaoDocumentoForm from './FuncaoDocumentoForm';
import ModelList from '../ModelList';
import { AppContext } from '../../../context';

class FuncaoDocumentoList extends React.Component {    

    static contextType = AppContext;


    render(){
        return (
            <ModelList 
                url={this.context.config.BACKEND_URL + '/funcaoDocumento'}
                token={this.props.token}
                title="Lista de Funções Documentais"
                modelName="funcao"
                modelIcon="faList"
                modelForm={FuncaoDocumentoForm}
                useAdd={true}
            />
        );
    }
}

export default FuncaoDocumentoList;

