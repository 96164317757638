import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { ListGroup, Accordion, AccordionCollapse, AccordionToggle, Button, Card, Form as BSForm, FormGroup, ListGroupItem, Tab, Tabs } from "react-bootstrap";
import Request from "../../../request";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Select from 'react-select';
import { AppContext } from "../../../context";

class UsuarioForm extends React.Component {

    static contextType = AppContext;

    state = {
        orgaos:    [],
        setores:   [],
        modulos:   [],
        risps:     [],
        areas:     [],
        tab: 'usuario'
    }

    save(values, callback){
        const method = this.props.usuario.id? 'PUT' : 'POST';
        const url = this.context.config.BACKEND_URL  + (this.props.usuario.id? '/usuario/'+this.props.usuario.id : '/usuario');

        Request(method, url, this.context.token)
        .send(values)
        .then(res => {
            this.context.addToast({titulo: "Successo", conteudo: "Registro atualizado com sucesso."});
            this.context.closeModal();
            if (this.props.onSave) this.props.onSave();
        })
        .catch(err => {
            const text = err.toString();
            this.context.addToast({titulo: "Erro", conteudo: "Falha na gravação do registro: " + text});
            callback();
        });

    }

    componentDidMount(){
        Request('GET', this.context.config.BACKEND_URL + '/orgao/options', this.context.token).send().then(res => {
            this.setState(()=>({orgaos: res.body.filter(option => 
                option.value === this.context.usuario.Orgao.id || this.context.usuario.Permissoes.some(p => p.nome === 'admin_setor_global'))}));
        })
        .catch(err => this.context.addToast({titulo: "Erro", conteudo: "Falha na recuperação dos órgãos: " + err.toString()}));

        Request('GET', this.context.config.BACKEND_URL + '/modulo/options', this.context.token).send().then(res => {
            this.setState(()=>({modulos: res.body}));
        })
        .catch(err => this.context.addToast({titulo: "Erro", conteudo: "Falha na recuperação dos módulos: " + err.toString()}));

        Request('GET', this.context.config.BACKEND_URL + '/setor/options', this.context.token).send().then(res => {
            this.setState(()=>({setores: res.body}));
        })
        .catch(err => this.context.addToast({titulo: "Erro", conteudo: "Falha na recuperação dos setores: " + err.toString()}));

        Request('GET', this.context.config.BACKEND_URL + '/risp/options', this.context.token).send().then(res => {
            this.setState(()=>({risps: res.body}));
        })
        .catch(err => this.context.addToast({titulo: "Erro", conteudo: "Falha na recuperação das risps: " + err.toString()}));

        Request('GET', this.context.config.BACKEND_URL + '/area/options', this.context.token).send().then(res => {
            this.setState(()=>({areas: res.body}));
        })
        .catch(err => this.context.addToast({titulo: "Erro", conteudo: "Falha na recuperação das Áreas de Atuação: " + err.toString()}));
    }

    render(){

        const orgaos = this.state.orgaos
        .map( (orgao, key) => <option key={key+1}value={orgao.id}>{orgao.nome}</option>);    
        orgaos.unshift(<option key={0} value=''>Selecione uma opção</option>);

        const permOptions = [{value: 0, label: 'Não'},{value: 1, label: 'Sim'}];

        const modulos = this.state.modulos
        .map( (modulo, key) => {
            
            const perfis = modulo.Perfis?
            modulo.Perfis.map( (perfil, key) => {
                return (
                    <ListGroupItem key={key}>
                        <BSForm.Label style={{display: 'block', fontWeight: 'bold'}}>
                            <Field type="checkbox" name="Perfis" value={perfil.id.toString()} disabled={!this.context.usuario.Permissoes.some(p => p.nome === 'admin_usuario_perfil')}/>
                            &nbsp;{perfil.nome}
                        </BSForm.Label>
                    </ListGroupItem>
                );
            }) : [];
            return (
                <Card key={key}>
                    <AccordionToggle as={Card.Header} eventKey={key+1}>
                        <Card.Title as="span">{modulo.label}</Card.Title>
                    </AccordionToggle>
                    <AccordionCollapse as={ListGroup} variant="flush" eventKey={key+1}>
                        <div>{perfis}</div>
                    </AccordionCollapse>
                </Card>
            );

        });

        return (
            <Formik
                initialValues={{ 
                    nome: this.props.usuario.nome,
                    login: this.props.usuario.login,
                    email: this.props.usuario.email,
                    OrgaoId: this.props.usuario.OrgaoId,
                    SetorId: this.props.usuario.SetorId,
                    permanente: this.props.usuario.permanente ? 1 : 0,
                    Perfis: (this.props.usuario.Perfis ?? []).map(perfil => perfil.id.toString()),
                    Risps: (this.props.usuario.Risps ?? []).map(risp => risp.id.toString()),
                    Areas: (this.props.usuario.Areas ?? []).map(area => area.id.toString())
                }}
                validate={values => {     
                    const errors = {};     
                    if (!values.nome) {    
                        errors.nome = 'Campo obrigatório';     
                    }
                    if (!values.login) {    
                        errors.login = 'Campo obrigatório';     
                    } else if (!/^\d{11}$/i.test(values.login)){
                        errors.login = 'Login inválido. Utilize os 11 dígitos do CPF.';     
                    }  
                    if (!values.email) {    
                        errors.email = 'Campo obrigatório';     
                    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)){
                        errors.email = 'Endereço de email inválido';     
                    }
                    if (!values.OrgaoId) {    
                        errors.OrgaoId = 'Campo obrigatório';     
                    } 

                    return errors;     
                }}
                onSubmit={(values, { setSubmitting }) => {     
                    this.save(values, () => setSubmitting(false));
                }}     
            >     
                {({ isSubmitting, setFieldValue, values, setValues}) => (
                    <Form>
                        <Tabs
                            activeKey={this.state.tab}
                            onSelect={(tab) => this.setState(() => ({tab}))}
                            className="mb-2"
                        >
                            <Tab eventKey="usuario" title="Dados Gerais">
                                <FormGroup>
                                    <BSForm.Label>Nome</BSForm.Label><ErrorMessage name="nome" component="span" className="text-danger small ml-2" />
                                    <Field type="text" name="nome" className="form-control" required/>
                                    
                                </FormGroup>
                                <FormGroup>
                                    <BSForm.Label>Login</BSForm.Label><ErrorMessage name="login" component="span" className="text-danger small ml-2"/>
                                    <Field type="text" name="login" className="form-control" required/>                            
                                </FormGroup>
                                <FormGroup>
                                    <BSForm.Label>Email</BSForm.Label><ErrorMessage name="email" component="span" className="text-danger small ml-2"/>
                                    <Field type="email" name="email" className="form-control" required/>
                                </FormGroup>
                                <FormGroup>
                                    <BSForm.Label>Órgão</BSForm.Label><ErrorMessage name="OrgaoId" component="span" className="text-danger small ml-2"/>
                                    <Select 
                                        name="OrgaoId" 
                                        noOptionsMessage={()=> "Nada encontrado."}
                                        placeholder="Pesquisar..."
                                        options={this.state.orgaos}
                                        value={this.state.orgaos ? this.state.orgaos.find(option => option.value === values.OrgaoId) : ''}
                                        onChange={option => setValues({...values, OrgaoId: option.value, SetorId: null})}
                                    />                           
                                </FormGroup>
                                <FormGroup>
                                    <BSForm.Label>Setor</BSForm.Label><ErrorMessage name="SetorId" component="span" className="text-danger small ml-2"/>
                                    <Select 
                                        name="SetorId" 
                                        isClearable={true}
                                        noOptionsMessage={()=> "Nada encontrado."}
                                        placeholder="Pesquisar..."
                                        options={this.state.setores.filter(setor => setor.OrgaoId === values.OrgaoId)}
                                        value={this.state.setores.find(setor => setor.value === values.SetorId)}
                                        onChange={option => setFieldValue('SetorId', option? option.value : null)}
                                    />                           
                                </FormGroup>
                                <FormGroup>
                                    <BSForm.Label>Sessão Permanente</BSForm.Label><ErrorMessage name="permanente" component="span" className="text-danger small ml-2"/>
                                    <Select 
                                        name="permanente" 
                                        isClearable={false}
                                        noOptionsMessage={()=> "Nada encontrado."}
                                        placeholder="Pesquisar..."
                                        options={permOptions}
                                        value={permOptions[values.permanente]}
                                        onChange={option => setFieldValue('permanente', option? option.value : null)}
                                    />                           
                                </FormGroup>
                            </Tab>
                            <Tab eventKey="modulos" title="Módulos">
                                <Accordion className="my-3">
                                    {modulos}
                                </Accordion>
                            </Tab>
                            <Tab eventKey="risp" title="RISPs">
                                <ListGroup>
                                {this.state.risps.length > 0? this.state.risps.map((risp, key) => {
                                   return (
                                        <ListGroupItem key={key}>
                                            <BSForm.Label>
                                                <Field type="checkbox" name="Risps" value={risp.value.toString()} disabled={!this.context.usuario.Risps.some(r => r.id === risp.value)}/> {risp.label}
                                            </BSForm.Label>
                                        </ListGroupItem>
                                    )
                                }): <ListGroupItem>Nenhuma região disponível.</ListGroupItem>}
                                </ListGroup>
                            </Tab>
                            <Tab eventKey="areas" title="Áreas">
                                <ListGroup>
                                {this.state.areas.length > 0? this.state.areas.map((area, key) => {
                                   return (
                                        <ListGroupItem key={key}>
                                            <BSForm.Label>
                                                <Field type="checkbox" name="Areas" value={area.value.toString()} /> {area.label}
                                            </BSForm.Label>
                                        </ListGroupItem>
                                    )
                                }): <ListGroupItem>Nenhuma Área disponível.</ListGroupItem>}
                                </ListGroup>
                            </Tab>
                        </Tabs>
                        <FormGroup className="text-right mt-2">
                            <Button type="button" variant='secondary' onClick={() => this.context.closeModal()}>
                                <FontAwesomeIcon icon={faTimes} />&nbsp;
                                Fechar
                            </Button>
                            <Button type="submit" disabled={isSubmitting} variant='primary' className="ml-2">
                                <FontAwesomeIcon icon={faSave} />&nbsp;
                                Gravar Alterações
                            </Button>
                        </FormGroup>
                    </Form>
                )}
            </Formik>
        );
    }
}


export default UsuarioForm;