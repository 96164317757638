import { faSave, faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Col, Form as BSForm, FormControl, FormGroup, InputGroup, ListGroup, ListGroupItem, Row, Tab, Tabs } from "react-bootstrap";
import Request from "../../../request";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Select from 'react-select';
import { AppContext } from "../../../context";

class PerfilForm extends React.Component {

    static contextType = AppContext;

    state = {
        modulos: [],
        permissoes: [],
        filter: ''
    }

    componentDidMount(){
        Request('GET', this.context.config.BACKEND_URL + '/modulo/options', this.context.token)
        .then( res => {
            this.setState({modulos: res.body});
        })
        .catch( err => {
            this.context.addToast({title: 'Erro', text: 'Falha na recuperação da lista de módulos: ' + err.toString()});
        });

        Request('GET', this.context.config.BACKEND_URL + '/permissao/options', this.context.token)
        .then( res => {
            this.setState({permissoes: res.body});
        })
        .catch( err => {
            this.context.addToast({title: 'Erro', text: 'Falha na recuperação da lista de permissões: ' + err.toString()});
        });
    }

    save(values, callback){
        const method = this.props.perfil.id? 'PUT' : 'POST';
        const url = this.context.config.BACKEND_URL + (this.props.perfil.id? '/perfil/'+this.props.perfil.id : '/perfil');

        Request(method, url, this.context.token)
        .send(values)
        .then( res => {
            this.context.addToast({titulo: "Successo", conteudo: "Registro atualizado com sucesso."});
            callback();
            if (this.props.onSave) this.props.onSave();
            this.context.closeModal();                                
        })
        .catch( err => {
            this.context.addToast({titulo: "Erro", conteudo: "Houve uma falha na gravação do registro."});
            callback();
        });

    }

    render(){

        return (
            <Formik
                initialValues={{ 
                    nome: this.props.perfil.nome,
                    ModuloId: this.props.perfil.ModuloId,
                    Permissoes: (this.props.perfil.Permissoes ?? []).map(permissao => permissao.id.toString())
                }}
                validate={values => {     
                    const errors = {};     
                    if (!values.nome) {    
                        errors.nome = 'Campo obrigatório';     
                    }
                    if (!values.ModuloId) {    
                        errors.ModuloId = 'Campo obrigatório';     
                    }    
                    return errors;     
                }}
                onSubmit={(values, { setSubmitting }) => {     
                    this.save(values, () => setSubmitting(false));
                }}     
            >     
                {({ isSubmitting , setFieldValue, values}) => {
                    const permissoes = this.state.permissoes?
                    this.state.permissoes
                    .filter(permissao => permissao.label.includes(this.state.filter) || permissao.descricao.includes(this.state.filter) )
                    .map( (permissao, key) => (
                        <ListGroupItem as="label" key={key} className="my-0">                            
                            <Field
                                type="checkbox"
                                name="Permissoes"
                                value={permissao.value.toString()}
                                />
                            <strong>&nbsp;{permissao.label}</strong><br />
                            <i><small>{permissao.descricao}</small></i>
                        </ListGroupItem>
                    )) : [] ;

                    return (
                    <Form>
                        <Tabs defaultActiveKey='perfil' className="mb-2">
                            <Tab eventKey="perfil" title="Dados Gerais">
                                <FormGroup>
                                    <BSForm.Label>Nome</BSForm.Label><ErrorMessage name="nome" component="span" className="text-danger small ml-2"/>
                                    <Field type="text" name="nome" className="form-control" />                            
                                </FormGroup>
                                <FormGroup>
                                    <BSForm.Label>Módulo</BSForm.Label><ErrorMessage name="ModuloId" component="span" className="text-danger small ml-2"/>
                                    <Select
                                        name="ModuloId"
                                        noOptionsMessage={() => "Nada encontrado."}
                                        placeholder="Pesquisar..."
                                        options={this.state.modulos}
                                        value={this.state.modulos.find(option => option.value === values.ModuloId)}
                                        onChange={option => {
                                            setFieldValue('ModuloId', option? option.value : null);
                                        }}/>                                        
                                </FormGroup>
                            </Tab>
                            <Tab eventKey="permissoes" title="Permissões">                                   
                                <Row className="mb-2">
                                    <Col>
                                        <InputGroup>                                
                                            <FormControl
                                                type="text"
                                                placeholder="Pesquisa"
                                                value={this.state.filter}
                                                onChange={(event) => this.setState(state => ({filter: event.target.value}))}                                    
                                            />
                                            <InputGroup.Append>
                                                <Button>
                                                    <FontAwesomeIcon icon={faSearch} />
                                                </Button>
                                            </InputGroup.Append>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col><Button type="button" variant="outline-primary" className="form-control" onClick={() => setFieldValue('Permissoes', this.state.permissoes.map(p => p.id.toString()))}>Selecionar todos</Button></Col>
                                    <Col><Button type="button" variant="outline-danger" className="form-control" onClick={() => setFieldValue('Permissoes', [])}>Selecionar nenhum</Button></Col>
                                </Row>
                                <Row style={{overflow: 'scroll', maxHeight: '300px'}}>
                                    <Col>
                                        <ListGroup>
                                            {permissoes}
                                        </ListGroup>
                                    </Col>                                    
                                </Row>
                                
                            </Tab>
                        </Tabs>
                        <FormGroup className="text-right">
                            <Button type="button" variant='secondary' onClick={() => this.context.closeModal()}>
                                <FontAwesomeIcon icon={faTimes} />&nbsp;
                                Fechar
                            </Button>
                            <Button type="submit" disabled={isSubmitting} variant='primary' className="ml-2">
                                <FontAwesomeIcon icon={faSave} />&nbsp;
                                Gravar Alterações
                            </Button>
                        </FormGroup>
                    </Form>
                    );
                }}
            </Formik>
        );
    }
}

export default PerfilForm;