import CautelaPage from "./CautelaPage";
import TipoMaterialForm from "./TipoMaterialForm";
import TipoMaterialList from "./TipoMaterialList";
import MaterialForm from "./MaterialForm";
import MaterialList from "./MaterialList";
import CautelaForm from "./CautelaForm";
import CautelaList from "./CautelaList";
import CautelaPrint from "./CautelaPrint";

const CautelaContents = {
  CautelaPage,
  MaterialForm,
  MaterialList,
  TipoMaterialForm,
  TipoMaterialList,
  CautelaForm,
  CautelaList,
  CautelaPrint  
};

export default CautelaContents;
