import { faCamera, faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Col, Form as BSForm, FormGroup, Row, Tab, Tabs } from "react-bootstrap";
import Request from "../../../request";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Select from 'react-select';
import AddAnexos from '../../AddAnexos';
import CustomEditor from '../../Editor';
import { AppContext } from "../../../context";

class ChapaForm extends React.Component {

  static contextType = AppContext;

  state = {
    cargos: []
  }

  componentDidMount() {
    Request("GET", this.context.config.BACKEND_URL + "/cargoEleicao/options", this.context.token)
      .then(res => {
        this.setState({ cargos: res.body })
      })
      .catch((err) => {
        this.context.addToast({
          titulo: "Erro",
          conteudo: "Erro na recuperação da lista de Cargos",
        });
      });
  }

  save(values, callback) {
    const method = this.props.chapa.id ? "PUT" : "POST";
    const url =
      this.context.config.BACKEND_URL +
      "/chapa" +
      (this.props.chapa.id ? "/" + this.props.chapa.id : "");

    Request(method, url, this.context.token)
      .send(values)
      .then((res) => {
        this.context.addToast({
          titulo: "Successo",
          conteudo: "Registro atualizado com sucesso.",
        });
        callback();
        if (this.props.onSave) this.props.onSave();
        this.context.closeModal();
      })
      .catch((err) => {
        this.context.addToast({
          titulo: "Erro",
          conteudo: "Houve uma falha na gravação do registro.",
        });
        callback();
      });
  }

  render() {
    return (
      <Formik
        initialValues={{
          CargoId: this.props.chapa?.CargoId ?? null,
          nome_instituicao: this.props.chapa?.nome_instituicao ?? "",
          sigla_instituicao: this.props.chapa?.sigla_instituicao ?? "",
          nome_titular: this.props.chapa?.nome_titular ?? "",
          nome_suplente: this.props.chapa?.nome_suplente ?? "",
          foto_instituicao: this.props.chapa?.foto_instituicao ?? null,
          foto_titular: this.props.chapa?.foto_titular ?? null,
          foto_suplente: this.props.chapa?.foto_suplente ?? null,
          descricao: this.props.chapa?.descricao ?? "",
          iframe_video: this.props.chapa?.iframe_video ?? "",
        }}
        validate={(values) => {
          const errors = {};
          if (!values.nome_instituicao) {
            errors.nome_instituicao = "Campo obrigatório";
          }
          if (!values.sigla_instituicao) {
            errors.sigla_instituicao = "Campo obrigatório";
          }
          if (!values.nome_titular) {
            errors.nome_titular = "Campo obrigatório";
          }
          if (!values.nome_suplente) {
            errors.nome_suplente = "Campo obrigatório";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          this.save(values, () => setSubmitting(false));
        }}
      >
        {({ isSubmitting, values, setFieldValue, errors }) => {
          return (
            <Form>
              <Tabs>
                <Tab eventKey="detalhes" title="Detalhes">
                  <Row className="mt-3">
                    <Col md={12} className="mb-2">
                      <BSForm.Label>Cargo / Eleição</BSForm.Label>
                      <ErrorMessage
                        name="CargoId"
                        component="span"
                        className="text-danger small ml-2"
                      />
                      <Select
                        noOptionsMessage={text => text.inputValue + " não encontrado!"}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        menuPortalTarget={document.body}
                        placeholder="Selecione..."
                        options={this.state.cargos}
                        value={this.state.cargos.find(option => values.CargoId === option.value) ?? null}
                        onChange={option => {
                          setFieldValue('CargoId', option ? option.value : null);
                        }}
                      />
                    </Col>
                    <Col md={8}>
                      <FormGroup>
                        <BSForm.Label>Nome da Instituição</BSForm.Label>
                        <ErrorMessage
                          name="nome_instituicao"
                          component="span"
                          className="text-danger small ml-2"
                        />
                        <Field
                          type="text"
                          name="nome_instituicao"
                          className="form-control"
                        />
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <BSForm.Label>Sigla</BSForm.Label>
                        <ErrorMessage
                          name="sigla_instituicao"
                          component="span"
                          className="text-danger small ml-2"
                        />
                        <Field
                          type="text"
                          name="sigla_instituicao"
                          className="form-control"
                        />
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup>
                        <BSForm.Label>Nome do Titular</BSForm.Label>
                        <ErrorMessage
                          name="nome_titular"
                          component="span"
                          className="text-danger small ml-2"
                        />
                        <Field
                          type="text"
                          name="nome_titular"
                          className="form-control"
                        />
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup>
                        <BSForm.Label>Nome do Suplente</BSForm.Label>
                        <ErrorMessage
                          name="nome_suplente"
                          component="span"
                          className="text-danger small ml-2"
                        />
                        <Field
                          type="text"
                          name="nome_suplente"
                          className="form-control"
                        />
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup>
                        <BSForm.Label>Descrição</BSForm.Label>
                        <CustomEditor
                                name="descricao"
                                value={values.descricao}
                                setFieldValue={setFieldValue}
                            />  
                      </FormGroup>
                    </Col>
                  </Row>
                </Tab>
                <Tab eventKey="fotos" title={<><FontAwesomeIcon icon={faCamera} /> Imagens</>}>
                  <Row className="mt-3">
                    <Col md={12} className="mb-2">
                      <FormGroup>
                        <BSForm.Label>Código de incorporação do YouTube (16:9)</BSForm.Label>
                        <Field
                          as="textarea"
                          name="iframe_video"
                          className="form-control"
                        />
                      </FormGroup>
                    </Col>
                    <Col md={12} className="mb-2">
                      {(!values.foto_instituicao &&
                        <AddAnexos
                          title={<><FontAwesomeIcon icon={faCamera} /> Foto da Instituição (16:9)</>}
                          multiple={false}
                          asDataURL={true}
                          accept="image/*"
                          onError={file => this.context.addToast({ titulo: "Erro", conteudo: "Falha ao carregar o arquivo " + file.name })}
                          onLoad={anexos => anexos.length === 1 ? setFieldValue('foto_instituicao', anexos[0].conteudo) : undefined}
                        />) || (<>
                          <p>Clique na imagem para alterar</p>
                          <img className="mb-3"
                            alt="Imagem da Instituição"
                            src={values.foto_instituicao}
                            onClick={() => setFieldValue("foto_instituicao", null)} style={{ maxHeight: 320, maxWidth: '100%' }} />
                        </>)}
                    </Col>
                    <Col md={6} className="mb-2">
                      {(!values.foto_titular &&
                        <AddAnexos
                          title={<><FontAwesomeIcon icon={faCamera} /> Foto do Titular (9:16)</>}
                          multiple={false}
                          asDataURL={true}
                          accept="image/*"
                          onError={file => this.context.addToast({ titulo: "Erro", conteudo: "Falha ao carregar o arquivo " + file.name })}
                          onLoad={anexos => anexos.length === 1 ? setFieldValue('foto_titular', anexos[0].conteudo) : undefined}
                        />) || (
                          <>
                            <p>Clique na imagem para alterar</p>
                            <img className="mb-3"
                              alt="Imagem do Titular"
                              src={values.foto_titular}
                              onClick={() => setFieldValue("foto_titular", null)} style={{ maxHeight: 320, maxWidth: '100%' }} />
                          </>)}
                    </Col>
                    <Col md={6} className="mb-2">
                      {(!values.foto_suplente &&
                        <AddAnexos
                          title={<><FontAwesomeIcon icon={faCamera} /> Foto do Suplente (9:16)</>}
                          multiple={false}
                          asDataURL={true}
                          accept="image/*"
                          onError={file => this.context.addToast({ titulo: "Erro", conteudo: "Falha ao carregar o arquivo " + file.name })}
                          onLoad={anexos => anexos.length === 1 ? setFieldValue('foto_suplente', anexos[0].conteudo) : undefined}
                        />) || (
                          <>
                            <p>Clique na imagem para alterar</p>
                            <img className="mb-3"
                              src={values.foto_suplente}
                              alt="Imagem do Suplente"
                              onClick={() => setFieldValue("foto_suplente", null)} style={{ maxHeight: 320, maxWidth: '100%' }} />
                          </>)}
                    </Col>
                  </Row>
                </Tab>
              </Tabs>
              <FormGroup className="text-right mt-3">
                <Button
                  type="button"
                  variant="secondary"
                  onClick={() => this.context.closeModal()}
                >
                  <FontAwesomeIcon icon={faTimes} />
                  &nbsp; Fechar
                </Button>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  variant="primary"
                  className="ml-2"
                >
                  <FontAwesomeIcon icon={faSave} />
                  &nbsp; Gravar Alterações
                </Button>
              </FormGroup>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

export default ChapaForm;
