import React from "react";
import { AppContext } from "../../../context";
import ModelList from "../ModelList";
import EscolaForm from "./EscolaForm";

class EscolaList extends React.Component {
  static contextType = AppContext;
  render() {

    return (
      <ModelList 
      url={this.context.config.BACKEND_URL + "/alertaescola/escola"}
      title="Lista de Escolas"
      modelName="escola"
      modelIcon="faSchool"
      modelForm={EscolaForm}
      useAdd={true}
  />
       
    );
  }
}

export default EscolaList;
