import OperacoesPage from "./OperacoesPage";
import OperacaoList from "./OperacaoList";
import OperacaoForm from "./OperacaoForm";
import EixoList from "./EixoList";
import EixoForm from "./EixoForm";
import RecursoList from "./RecursoList";
import RecursoForm from "./RecursoForm";
import ObjetivoList from "./ObjetivoList";
import ObjetivoForm from "./ObjetivoForm";
import IndicadorList from "./IndicadorList";
import IndicadorForm from "./IndicadorForm";
import AcaoForm from "./AcaoForm";
import AcaoRelatorio from "./AcaoRelatorio";
import OperacaoDashboard from "./OperacaoDashboard";
import OperacaoResultado from "./OperacaoResultado";

const OperacoesContents = {
    OperacoesPage,
    OperacaoList,
    OperacaoForm,
    EixoList,
    EixoForm,
    RecursoList,
    RecursoForm,
    ObjetivoList,
    ObjetivoForm,
    IndicadorList,
    IndicadorForm,
    AcaoForm,
    AcaoRelatorio,
    OperacaoDashboard,
    OperacaoResultado
};

export default OperacoesContents;