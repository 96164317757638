import { faCamera, faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Col, Form as BSForm, Row } from "react-bootstrap";
import Request from "../../../request";
import { Formik, Form, Field, ErrorMessage } from "formik";
import moment from "moment";
import Select from 'react-select';
import AddAnexos from "../../AddAnexos";
import { AppContext } from "../../../context";

class EleitorFrontForm extends React.Component {

  static contextType = AppContext;

  state = {
    cidades: [],
    eleicoes: []
  }

  componentDidMount() {
    Request("GET", this.context.config.BACKEND_URL + "/eleicoes/cidades", this.context.token)
      .then(res => {
        this.setState({ cidades: res.body })
      })
      .catch((err) => {
        this.context.addToast({
          titulo: "Erro",
          conteudo: "Erro na recuperação da lista de Cidades",
        });
      });

    Request("GET", this.context.config.BACKEND_URL + "/eleicao/options", this.context.token)
      .then(res => {
        this.setState({ eleicoes: res.body })
      })
      .catch((err) => {
        this.context.addToast({
          titulo: "Erro",
          conteudo: "Erro na recuperação da lista de Eleições",
        });
      });
  }

  save(values, callback) {
    const method = this.props.eleitor.id ? "PUT" : "POST";
    Request(method, this.context.config.BACKEND_URL + "/eleitor" + (this.props.eleitor.id ? ("/" + this.props.eleitor.id) : ""), this.context.token)
      .send(values)
      .end((err, res) => {
        if (err) {
          this.context.addToast({
            titulo: "Erro",
            conteudo: res?.text ?? "Ocorreu um Erro. Tente mais tarde."
          });
        } else {
          this.context.addToast({
            titulo: "Sucesso",
            conteudo: "Registro atualizado com sucesso!"
          });
          if (this.props.onSave) this.props.onSave();
          this.context.closeModal();
        }
        callback();
      });
  }

  render() {
    return (
      <Formik
        initialValues={{
          EleicaoId: this.props.eleitor.EleicaoId ?? null,
          nome: this.props.eleitor.nome ?? null,
          cpf: this.props.eleitor.cpf?.replaceAll(/\D/ig, '') ?? null,
          nascimento: this.props.eleitor.nascimento ? moment(this.props.eleitor.nascimento).format("YYYY-MM-DD") : null,
          nome_mae: this.props.eleitor.nome_mae ?? null,
          email: this.props.eleitor.email ?? null,
          CidadeId: this.props.eleitor.CidadeId ?? null,
          foto_frente: this.props.eleitor.foto_frente ?? null,
          foto_verso: this.props.eleitor.foto_verso ?? null,
        }}
        validate={(values) => {
          const errors = {};
          if (!values.EleicaoId) {
            errors.nome = "Campo obrigatório";
          }
          if (!values.nome) {
            errors.nome = "Campo obrigatório";
          }
          if (!values.nome_mae) {
            errors.nome_mae = "Campo obrigatório";
          }

          if (!values.cpf) {
            errors.cpf = "Obrigatório";
          } else if (!/^\d{11}$/.test(values.cpf)) {
            errors.cpf = "Inválido";
          }

          if (!values.nascimento) {
            errors.nascimento = "Obrigatório";
          }
          if (!values.email) {
            errors.email = "Campo obrigatório";
          } else if (!/^[^@]+@[^@]+$/.test(values.email)) {
            errors.email = "Formato inválido de email";
          }

          if (!values.CidadeId) {
            errors.CidadeId = "Campo obrigatório";
          }

          if (!values.foto_frente) {
            errors.foto_frente = "Campo obrigatório";
          }
          if (!values.foto_verso) {
            errors.foto_verso = "Campo obrigatório";
          }

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          this.save(values, () => setSubmitting(false));
        }}
      >
        {({ isSubmitting, values, setFieldValue }) => {
          return (
            <Form>
              <Row>
                <Col md={12} className="mb-2">
                  <BSForm.Label>Eleição</BSForm.Label>
                  <ErrorMessage
                    name="EleicaoId"
                    component="span"
                    className="text-danger small ml-2"
                  />
                  <Select
                    noOptionsMessage={text => text.inputValue + " não encontrado!"}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    menuPortalTarget={document.body}
                    placeholder="Selecione..."
                    options={this.state.eleicoes}
                    value={this.state.eleicoes.find(option => values.EleicaoId === option.value) ?? null}
                    onChange={option => {
                      setFieldValue('EleicaoId', option ? option.value : null);
                    }}
                  />
                </Col>
                <Col md={8} className="mb-2">
                  <BSForm.Label>Nome do Eleitor</BSForm.Label>
                  <ErrorMessage
                    name="nome"
                    component="span"
                    className="text-danger small ml-2"
                  />
                  <Field
                    type="text"
                    name="nome"
                    className="form-control"
                  />
                </Col>
                <Col md={4} className="mb-2">
                  <BSForm.Label>CPF (somente dígitos)</BSForm.Label>
                  <ErrorMessage
                    name="cpf"
                    component="span"
                    className="text-danger small ml-2"
                  />
                  <Field
                    type="text"
                    name="cpf"
                    className="form-control"
                  />
                </Col>
                <Col md={4} className="mb-2">
                  <BSForm.Label>Data de Nascimento</BSForm.Label>
                  <ErrorMessage
                    name="nascimento"
                    component="span"
                    className="text-danger small ml-2"
                  />
                  <Field
                    type="date"
                    name="nascimento"
                    className="form-control"
                  />
                </Col>
                <Col md={8} className="mb-2">
                  <BSForm.Label>Nome da Mãe</BSForm.Label>
                  <ErrorMessage
                    name="nome_mae"
                    component="span"
                    className="text-danger small ml-2"
                  />
                  <Field
                    type="text"
                    name="nome_mae"
                    className="form-control"
                  />
                </Col>
                <Col md={6} className="mb-2">
                  <BSForm.Label>Email</BSForm.Label>
                  <ErrorMessage
                    name="email"
                    component="span"
                    className="text-danger small ml-2"
                  />
                  <Field
                    type="email"
                    name="email"
                    className="form-control"
                  />
                </Col>
                <Col md={6} className="mb-2">
                  <BSForm.Label>Cidade</BSForm.Label>
                  <ErrorMessage
                    name="CidadeId"
                    component="span"
                    className="text-danger small ml-2"
                  />
                  <Select
                    noOptionsMessage={text => text.inputValue + " não encontrado!"}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    menuPortalTarget={document.body}
                    placeholder="Selecione..."
                    options={this.state.cidades}
                    value={this.state.cidades.find(option => values.CidadeId === option.value) ?? null}
                    onChange={option => {
                      setFieldValue('CidadeId', option ? option.value : null);
                    }}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={6} className="mb-2">
                  <ErrorMessage
                    name="foto_frente"
                    component="span"
                    className="text-danger small ml-2"
                  />{(!values.foto_frente &&
                    <AddAnexos
                      title={<><FontAwesomeIcon icon={faCamera} /> Frente do Doc. de Identidade</>}
                      multiple={false}
                      asDataURL={true}
                      accept="image/*"
                      onError={file => this.context.addToast({ titulo: "Erro", conteudo: "Falha ao carregar o arquivo " + file.name })}
                      onLoad={anexos => anexos.length === 1 ? setFieldValue('foto_frente', anexos[0].conteudo) : undefined}
                    />) || (
                      <>
                        <p>Clique na imagem para alterar</p>
                        <img className="mb-3"
                          src={values.foto_frente}
                          alt="Frente do RG"
                          onClick={() => setFieldValue("foto_frente", null)} style={{ maxHeight: 320, maxWidth: '100%' }} />
                      </>)}
                </Col>
                <Col md={6} className="mb-2">
                  <ErrorMessage
                    name="foto_verso"
                    component="span"
                    className="text-danger small ml-2"
                  />{(!values.foto_verso &&
                    <AddAnexos
                      title={<><FontAwesomeIcon icon={faCamera} /> Verso do Doc. de Identidade</>}
                      multiple={false}
                      asDataURL={true}
                      accept="image/*"
                      onError={file => this.context.addToast({ titulo: "Erro", conteudo: "Falha ao carregar o arquivo " + file.name })}
                      onLoad={anexos => anexos.length === 1 ? setFieldValue('foto_verso', anexos[0].conteudo) : undefined}
                    />) || (
                      <>
                        <p>Clique na imagem para alterar</p>
                        <img className="mb-3"
                          src={values.foto_verso}
                          alt="Verso do RG"
                          onClick={() => setFieldValue("foto_verso", null)} style={{ maxHeight: 320, maxWidth: '100%' }} />
                      </>)}
                </Col>
              </Row>
              <Row className="mt-3">
                <Col className="text-right">
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={() => this.context.closeModal()}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                    &nbsp; Cancelar
                  </Button>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    variant="primary"
                    className="ml-2"
                  >
                    <FontAwesomeIcon icon={faSave} />
                    &nbsp; Gravar Alterações
                  </Button>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

export default EleitorFrontForm;
