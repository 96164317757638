import React from 'react';
import { Col, FormControl, Row } from 'react-bootstrap';
import logoSegup from '../img/segup.png';
import { faPhotoVideo, faUserShield } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { AppContext } from '../../../context';

class CadastroPrint extends React.Component {

    static contextType = AppContext;

    componentDidMount() {
        window.print();
    }

    render() {
        return (

            <div>
                <h2 className="d-flex justify-content-between align-items-center">
                    <div>
                        Plataforma Integrada de Segurança Pública - PISP<br />
                        <small>Módulo de Cadastro de Faces</small>
                    </div>
                    <img src={logoSegup} alt="logo" height="35px" />
                </h2>
                <h5>
                    <FontAwesomeIcon icon={faUserShield} />  Registro de Face
                </h5>
                <hr />
                <Row>
                    <Col sm={6}>
                        <strong >Registrador</strong>
                        <FormControl disabled={true} value={this.context.usuario.nome} />
                    </Col>
                    <Col sm={6}>
                        <strong >Data de Atualização</strong>
                        <FormControl disabled={true} value={moment(this.props.cadastro.createdAt).locale('pt-br').calendar()} />
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col sm={6}>
                        <strong >Nome</strong>
                        <FormControl disabled={true} value={this.props.cadastro.nome} />
                    </Col>
                    <Col sm={6}>
                        <strong >Situação</strong>
                        <FormControl disabled={true} value={this.props.cadastro.Situacao.nome} />
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col sm={12}>
                        <strong >Informação complementar</strong>
                        <FormControl disabled={true} value={this.props.cadastro.descricao} />
                    </Col>
                </Row>
                <h5 className='my-3'>
                    <FontAwesomeIcon icon={faPhotoVideo} /> Imagens Cadastradas
                </h5>
                <hr />
                <div class="col mx-auto text-center">
                    {this.props.cadastro.Anexos.map(anexo =>
                        <img alt="Imagem" src={Buffer.from(anexo.conteudo).toString()} width="600px" />
                    )}
                </div>
            </div>
        );
    }
}

export default CadastroPrint;
