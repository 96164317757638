import {
    faEdit,
    faFileCsv,
    faPrint,
    faTimes,
  } from "@fortawesome/free-solid-svg-icons";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import React from "react";
  import { Button, Col, Row, Tab, Table, Tabs } from "react-bootstrap";
  import { AppContext } from "../../../context";
  import Request from "../../../request";
  import RelatorioForm from "./RelatorioForm";
  import { ExportToCsv } from "export-to-csv";
  import Chart from "../../Chart";
  
  class RelatorioView extends React.Component {
    static contextType = AppContext;
  
    state = {
      data: [],
      chartData: [
          {label: "Teste 1", total: [{label: 'A', total: 1},{label: 'B', total: 2}]},
      ]
    };
  
    componentDidMount() {
      Request(
        "GET",
        this.context.config.BACKEND_URL +
          "/relatorioModulo/" +
          this.props.RelatorioId +
          "/result",
        this.context.token
      )
        .then((res) => {
          this.props.setTitulo && this.props.setTitulo(res.body.relatorio.nome);

          const data = res.body.result;
  
          var chartData = this.state.chartData;
  
          if (data.length){
              const columns = Object.keys(data[0]);
              console.log(data.map(row => row[columns[0]]));
              if (columns.length === 1){
                  chartData = [{label: columns[0], total: data.map(row => ({label: columns[0], total: row[columns[0]]}))}];
              }
              else if (columns.length === 2){
                  chartData = data.map(row => ({label: row[columns[0]], total: [{label: columns[1], total: row[columns[1]]}]}));
              }             
              else if (columns.length === 3){
                  var labels1 = [];
                  var labels2 = [];
  
                  data.forEach(row => {
                      if (labels1.indexOf(row[columns[0]]) < 0) labels1.push(row[columns[0]]);
                      if (labels2.indexOf(row[columns[1]]) < 0) labels2.push(row[columns[1]]);
                  });
  
                  labels1 = labels1.filter(label1 => data.some(row => row[columns[0]] === label1 && row[columns[2]] > 0));
                  labels2 = labels2.filter(label2 => data.some(row => row[columns[1]] === label2 && row[columns[2]] > 0));
  
                  chartData = labels1.map(label1 => ({label: label1, total: labels2.map(label2 => ({
                      label: label2, 
                      total: (data.find(row => row[columns[0]] === label1 && row[columns[1]] === label2) ?? {[columns[2]]: 0})[columns[2]] 
                  }))}));
              }
              else {
                  //chartData = null;
              }
          }        
  
          this.setState({ data, chartData }, () => {
            if (this.props.print) window.print();
            
          });
        })
        .catch((err) => {
          this.context.addToast({
            titulo: "Erro",
            conteudo: "Houve um erro ao recuperar os dados",
          });
        });
    }
  
    edit() {
      Request(
        "GET",
        this.context.config.BACKEND_URL +
          "/relatorioModulo/" +
          this.props.RelatorioId,
        this.context.token
      )
        .then((res) => {
          this.context.openModal({
            titulo: "Editar Registro",
            conteudo: (
              <RelatorioForm
                relatorio={res.body}
                onSave={() => this.props.onSave()}
              />
            ),
            size: "xl",
          });
        })
        .catch((err) => {
          this.context.addToast({
            titulo: "Erro",
            conteudo: "Houve um erro ao recuperar os dados",
          });
        });
    }
  
    export() {
      const options = {
        fieldSeparator: ",",
        quoteStrings: '"',
        decimalSeparator: ".",
        showLabels: true,
        showTitle: false,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
      };
  
      const csvExporter = new ExportToCsv(options);
  
      csvExporter.generateCsv(this.state.data);
    }
  
    print() {
      this.context.closeModal();
      setTimeout(() => {
        this.context.setContent("RelatorioPrint", {
          RelatorioId: this.props.RelatorioId,
        });
      }, 1000);
    }
  
    render() {
      const headers = Object.keys(
        this.state.data.length ? this.state.data[0] : {}
      );
      const rows = this.state.data.map((row, key) => (
        <tr key={key}>
          {headers.map((header, key) => (
            <td key={key}>{row[header]}</td>
          ))}
        </tr>
      ));
  
      return (
        <>
          <Tabs
          defaultActiveKey={"tabela"}
          >
            <Tab
            title="Tabela"
            eventKey={"tabela"}
            
            >
              <Row>
                <Col>
                  <Table striped condensed>
                    <thead>
                      <tr>
                        {headers.map((header, key) => (
                          <th key={key}>{header}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>{rows}</tbody>
                  </Table>
                </Col>
              </Row>
              
            </Tab>
            <Tab
            title="Grafico"
            eventKey={"grafico"}
            >
  
                          {this.state.chartData && <Chart data={this.state.chartData} />}
            </Tab>
  
          </Tabs>
          {!this.props.hideButtons && (
                <Row className="mt-3">
                  <Col className="text-right">
                    <Button
                      onClick={() => this.context.closeModal()}
                      className="mr-2"
                      variant="light"
                    >
                      <FontAwesomeIcon icon={faTimes} /> Cancelar
                    </Button>
                    <Button
                      onClick={() => this.print()}
                      className="mr-2"
                      variant="dark"
                    >
                      <FontAwesomeIcon icon={faPrint} /> Imprimir
                    </Button>
                    <Button
                      onClick={() => this.export()}
                      className="mr-2"
                      variant="success"
                    >
                      <FontAwesomeIcon icon={faFileCsv} /> Exportar para CSV
                    </Button>
                    {this.context.usuario.Permissoes.some(
                      (p) => p.nome === "relatorios_relatorio_edit"
                    ) && (
                      <Button
                        onClick={() => this.edit()}
                        className="mr-2"
                        variant="primary"
                      >
                        <FontAwesomeIcon icon={faEdit} /> Editar
                      </Button>
                    )}
                  </Col>
                </Row>
              )}
        </>
      );
    }
  }
  
  export default RelatorioView;
  