import React from 'react';
import ContratoForm from './ContratoForm';
import ModelList from '../ModelList';
import { AppContext } from '../../../context';
import { Form as BSForm } from "react-bootstrap";
import Request from "../../../request";
class ContratoList extends React.Component {

    static contextType = AppContext;

    state = {
        modalidades_list: []
    }

    componentDidMount() {
        Request("GET", this.context.config.BACKEND_URL + "/modalidade/options", this.context.token)
            .send()
            .then((res) => {
                this.setState(() => ({ modalidades_list: res.body }));
            })
            .catch((err) => {
                this.context.addToast({
                    titulo: "Erro",
                    conteudo:
                        "Falha na recuperação da lista dos setores: " + err.toString(),
                });
            });
    }

    getRandomColor = () => {
        const colors = ['#87CEFA', '#7FFFD4', '#00FF7F', '#F5DEB3', '#DA70D6'];
        return colors[Math.floor(Math.random() * colors.length)];
    }

    render() {
        const modalidadesColors = {};
        this.state.modalidades_list.forEach(modalidade => {
            modalidadesColors[modalidade.label] = this.getRandomColor();
        });
        return (

            <>
                <h3>Lista de Modalidades</h3>
                <div style={{ display: 'flex', whiteSpace: 'nowrap' }}>
                    {this.state.modalidades_list.map((modalidade, index) => (
                        <div key={index} style={{ backgroundColor: modalidadesColors[modalidade.label], padding: '5px', margin: '5px' }}>
                            {modalidade.label}
                        </div>
                    ))}
                </div>
                <hr />
                <ModelList
                    url={this.context.config.BACKEND_URL + '/contrato'}
                    title="Lista de Contratos"
                    modelName="contrato"
                    modelIcon="faFileSignature"
                    modelForm={ContratoForm}
                    modalSize="xl"
                    useAdd={this.context.usuario.Permissoes.some(p => p.nome === 'contratos_contrato_create')}
                />
            </>

        );
    }
}

export default ContratoList;

