import React from 'react';
import Request from '../../../request';
import { AppContext } from "../../../context";
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Datatable from '../../Datatable';

class AcompanharDenunciasList extends React.Component {

    static contextType = AppContext;

    updateDatatable = () => {
        this.refs.datatable.updateDatatable(); 
    }

    onAction(action) {
        switch (action.name) {
            case "class":
                Request('GET', this.context.config.BACKEND_URL + '/denuncia/acompanhar/' + action.id, this.context.token)
                    .then(res => {
                        const denuncia = res.body;
                        this.context.setContent("AcompanharDenunciasForm", { acompanhar: denuncia });
                    })
                    .catch(err => {
                        this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na recuperação do registro: " + err.toString() });
                    });
                break;
            default:
        }
    }

    render() {
        return (
            <>
                <h3><FontAwesomeIcon icon={faFolderOpen} /> Acompanhar Denúncias</h3>
                <label>
                    <ol>
                        <li>
                            <span style={{ display: 'inline-block', width: '10px', height: '10px', backgroundColor: '#0ee824', marginRight: '5px' }}></span>
                            até 15 dias - No prazo
                        </li>
                        <li>
                            <span style={{ display: 'inline-block', width: '10px', height: '10px', backgroundColor: '#ffff00', marginRight: '5px' }}></span>
                            até 25 dias - Prorrogado
                        </li>
                        <li>
                            <span style={{ display: 'inline-block', width: '10px', height: '10px', backgroundColor: '#d61515', marginRight: '5px' }}></span>
                            até 30 dias - Atrasado
                            </li>
                        <li>
                            <span style={{ display: 'inline-block', width: '10px', height: '10px', backgroundColor: '#7d5b8c', marginRight: '5px' }}></span>
                            mais de 31 dias - Muito atrasado
                        </li>
                    </ol>
                </label>
                <Datatable
                    ref="datatable"
                    url={this.context.config.BACKEND_URL + '/denuncia/acompanhar'}
                    autoSeach={true}
                    onError={(err) => this.context.addToast({ titulo: "Erro", conteudo: err.toString() })}
                    onAction={(action, update) => this.onAction(action, update)}
                    updateDatatable={this.updateDatatable}
                />
            </>
        );
    }
}

export default AcompanharDenunciasList;
