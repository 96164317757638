import { faFlag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { AppContext } from '../../../context';
import Request from '../../../request';
import Datatable from '../../Datatable';

class OperacaoList extends React.Component {

    static contextType = AppContext;

    onAction(action, callback) {
        switch (action.name) {
            case "edit":
                Request('GET', this.context.config.BACKEND_URL + '/operacao/' + action.id, this.context.token)
                    .then(res => {
                        this.context.setContent("OperacaoForm", { operacao: res.body });
                    })
                    .catch(err => {
                        this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na recuperação do registro: " + err.toString() });
                    });
                break;
            case "print":
                Request('GET', this.context.config.BACKEND_URL + '/operacao/print/' + action.id, this.context.token)
                    .responseType('blob')
                    .then(res => {
                        const url = window.URL.createObjectURL(res.body);
                        var element = document.createElement('a');
                        element.setAttribute('href', url);
                        element.setAttribute('target', "_blank");

                        element.style.display = 'none';
                        document.body.appendChild(element);

                        element.click();

                        document.body.removeChild(element);
                    })
                    .catch(err => {
                        this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na recuperação do arquivo: " + err.toString() });
                    });
                break;
            case "dashboard":
                Request('GET', this.context.config.BACKEND_URL + '/operacao/dashboard/' + action.id, this.context.token)
                    .then(res => {
                        this.context.setContent("OperacaoDashboard", { dashboard: res.body });
                    })
                    .catch(err => {
                        this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na recuperação do registro: " + err.toString() });
                    });
                break;
            case "resultado":
                Request('GET', this.context.config.BACKEND_URL + '/operacao/resultado/' + action.id, this.context.token)
                    .then(res => {
                        this.context.setContent("OperacaoResultado", { dashboard: res.body });
                    })
                    .catch(err => {
                        this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na recuperação do registro: " + err.toString() });
                    });
                break;
            case "relatorio":
                Request('GET', this.context.config.BACKEND_URL + '/operacao/' + action.id, this.context.token)
                    .then(res => {
                        this.context.setContent("OperacaoGrafico", { operacao: res.body });
                    })
                    .catch(err => {
                        this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na recuperação do registro: " + err.toString() });
                    });
                break;
            case "grafico":
                Request('GET', this.context.config.BACKEND_URL + '/operacao/relatorio/' + action.id, this.context.token)
                    .responseType('blob')
                    .then(res => {
                        const reader = new FileReader();
                        reader.onload = () => {
                            this.context.setContent("Viewer", { data: reader.result, type: "application/pdf" });
                        };
                        reader.readAsDataURL(res.body);
                    })
                    .catch(err => {
                        this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na recuperação do registro: " + err.toString() });
                    });
                break;
            case "delete":
                if (window.confirm("Deseja realmente excluir este registro?")) {
                    Request('DELETE', this.context.config.BACKEND_URL + '/operacao/' + action.id, this.context.token)
                        .then(res => {
                            callback();
                        })
                        .catch(err => {
                            this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na exclusão do registro: " + err.toString() });
                        });
                }
                break;
            case "clonar":
                if (window.confirm("Deseja realmente clonar esta operação?")) {
                    Request('POST', this.context.config.BACKEND_URL + '/operacao/clonar/' + action.id, this.context.token)
                        .then(res => {
                            callback();  // Atualiza a tabela após o clone
                        })
                        .catch(err => {
                            this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na clonagem da operação: " + err.toString() });
                        });
                }
                break;
            default:
                break;
        }
    }

    render() {
        return (<>
            <h3><FontAwesomeIcon icon={faFlag} /> Lista de Operações</h3>
            <hr />
            <Datatable
                url={this.context.config.BACKEND_URL + '/operacao'}
                useAdd={this.context.usuario.Permissoes.some(p => p.nome === 'operacoes_operacao_create')}
                onError={(err) => this.context.addToast({ titulo: "Erro", conteudo: err.toString() })}
                onAction={(action, update) => this.onAction(action, update)}
                onClickAdd={() => this.context.setContent('OperacaoForm', { operacao: { Orgaos: [], Risps: [], Indicadores: [], Lider: {} } })}
            />
            <div id="pdfViewer" className='w-100' style={{ height: "600px" }} />
        </>);
    }
}

export default OperacaoList;