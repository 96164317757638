import { faBook, faFlag, faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { AppContext } from "../../../context";

class OperacaoPage extends React.Component {
  static contextType = AppContext;

  render() {
    return (
      <>
        <h3>
          <FontAwesomeIcon icon={faHome} /> Página Inicial
        </h3>
        <hr />
        <Card>
          <Card.Header>Principais Tarefas</Card.Header>
          <Card.Body>
            <Row>
              <Col style={{ textAlign: "center" }}>
                <div
                  role="button"
                  onClick={() => this.context.setContent("OperacaoList")}
                >
                  <FontAwesomeIcon size="2x" icon={faFlag} />
                  <br />
                  Acompanhar
                  <br />
                  Operações
                </div>
              </Col>
               <Col style={{ textAlign: "center" }}>
                <a
                  className="btn btn-link-dark"
                  target="_blank"
                  rel="noreferrer"
                  href={`${this.context.config.FRONTEND_URL}/doc/MANUAL_DO_USUARIO.pdf`}
                  download="Manual_do_usuario.pdf"
                >
                  <FontAwesomeIcon size="2x" icon={faBook} />
                  <br />
                  Manual
                  <br />
                  do Usuário
                </a>
              </Col>
          
            </Row>
          </Card.Body>
        </Card>
      </>
    );
  }
}

export default OperacaoPage;
