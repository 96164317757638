import { faBan, faCarAlt, faCheck, faComments, faCopy, faDownload, faGlobeAmericas, faPaperPlane, faPhotoVideo, faShareAlt, faThumbsDown, faThumbsUp, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React from "react";
import { Alert, Badge, Button, Col, Form, FormControl, InputGroup, ListGroup, ListGroupItem, Row, Table } from "react-bootstrap";
import AlertaModalMap from "./AlertaModalMap"
import L from 'leaflet';
import Request from "../../../request";
import AlertaMap from "./AlertaMap";
import { AppContext } from "../../../context";
import { useParams } from "react-router-dom";

class AlertaModal extends React.Component {

    static contextType = AppContext;

    static defaultProps = {
        uuid: ""
    };

    static badges = {
        Pendente: "warning",
        Aceito: "success",
        Despachado: "info",
        Recusado: "danger",
        Cancelado: "dark",
        Bloqueado: "dark",
        Finalizado: "primary",
    };

    state = {
        endereco: 'Buscando...',
        mensagem: "",
        alerta: {
            Chats: [],
            Localizacoes: [],
            latitude: AlertaMap.center[0],
            longitude: AlertaMap.center[1]
        },
        online: false
    }

    socket = null;
    interval = null;

    componentDidMount() {

        if (!this.context.token) {
            Request('GET', this.context.config.CONFIGURE_URL)
                .end((err, res) => {
                    if (!err) this.context.setConfig(res.body);
                    this.update();
                });
        } else {
            this.update();
        }

        this.interval = setInterval(() => {
            if (!this.state.online) this.update();
        }, 30000);
    }

    componentWillUnmount() {
        if (this.socket) {
            this.socket.close();
        }
        clearInterval(this.interval);
    }

    update() {

        const request = this.context.token ?
            Request("GET", this.context.config.BACKEND_URL + "/alerta/" + this.props.uuid, this.context.token)
            : Request("GET", this.context.config.BACKEND_URL + "/alerta/despacho/" + this.props.uuid)

        request.then(res => {
            this.setState({ alerta: res.body }, () => {

                const latlng = new L.LatLng(this.state.alerta.latitude, this.state.alerta.longitude);

                const geocoder = L.Control.Geocoder.nominatim();

                geocoder.reverse(latlng, 15, results => {
                    if (results[0]) {

                        const address = results[0].properties.address;

                        const cidade = address.city ?? address.town ?? address.city_district ?? "";
                        const bairro = address.neighbourhood ?? address.suburb ?? address.place ?? address.village ?? address.city_district ?? "";
                        const logradouro = address.road ?? address.street ?? address.street_name ?? address.place ?? "";

                        this.setState({ endereco: `${logradouro}, ${bairro}, ${cidade}` });

                    }
                    else {
                        this.setState({ endereco: `Não encontrado` });
                    }
                });


                this.socket = new WebSocket(this.context.config.WEBSOCKET_URL);

                this.socket.onerror = err => {
                    this.setState({ online: false });
                };

                this.socket.onclose = err => {
                    this.setState({ online: false });
                };

                this.socket.onopen = () => {
                    this.setState({ online: true }, () => {
                        this.socket.send(this.state.alerta.ticket);
                    });
                };

                this.socket.onmessage = msg => {
                    try {
                        const alerta = JSON.parse(msg.data);
                        if (alerta.uuid === this.props.uuid) {
                            this.setState({ alerta });
                        }
                    } catch (err) {
                        this.context.addToast({ titulo: "Erro", conteudo: "Erro na atualização do alerta: " + msg.data });
                    }
                };
            });
        })
            .catch(err => {
                this.context.addToast({ titulo: "Erro", conteudo: "Houve um erro na recuperação dos alertas: " + err.toString() })
            })
    }

    send(event) {
        event.preventDefault();

        const request = this.context.token ?
            Request("POST", this.context.config.BACKEND_URL + "/chat", this.context.token)
            : Request("POST", this.context.config.BACKEND_URL + "/alerta/despacho/chat");

        request.send({
            AlertaUuid: this.props.uuid,
            mensagem: this.state.mensagem
        }).then(() => {
            this.setState({ mensagem: '' });
        })
            .catch(err => {
                this.context.addToast({ titulo: "Erro", conteudo: "Houve um erro no envio da mensagem: " + err.toString() });
            });
    }

    mudarStatus(status) {

        var motivo = null;

        if (status === "Recusado" || status === "Cancelado" || status === "Bloqueado") {
            motivo = window.prompt("Digite o motivo do bloqueio, rejeição ou cancelamento:");
            if (!motivo) return;
        }

        const request = this.context.token ?
            Request("PATCH", this.context.config.BACKEND_URL + "/alerta/" + this.props.uuid, this.context.token)
            : Request("PATCH", this.context.config.BACKEND_URL + "/alerta/despacho/" + this.props.uuid)


        request.send({ status, motivo }).then(() => {
            this.context.addToast({ titulo: "Sucesso", conteudo: "Status do Alerta alterado com sucesso!" });
            this.context.closeModal();
        })
            .catch(err => {
                this.context.addToast({ titulo: "Erro", conteudo: "Houve um erro na alteração do status: " + err.toString() });
            });
    }

    copyData() {
        const shareUrl = this.context.config.FRONTEND_URL + '/alertapara/' + this.props.uuid;
        navigator.clipboard.writeText(
            `Desenvolvedor: ${this.state.alerta.DesenvolvedorNome}
Classificação: ${this.state.alerta.ClassificacaoNome}
Identificação: ${this.state.alerta.identificacao}
Telefone: ${this.state.alerta.telefone}
Coordenadas: ${this.state.alerta.latitude}, ${this.state.alerta.longitude}
Endereço Cadastrado: ${this.state.alerta.endereco}
Endereço Atual (aprox.): , ${this.state.endereco}
Data/Hora: ${moment(this.state.alerta.datahora).format("DD/MM/YYYY[, às ]HH:mm")}
Link: ${shareUrl}`
        );
        this.context.addToast({ titulo: "Copiado!", conteudo: "Informações copiadas para a área de transferência." })
    }

    render() {

        const chatConfig = {
            "Cliente": { className: "mr-auto alert-secondary text-left mt-1 small", style: { width: '50%' } },
            "Servidor": { className: "ml-auto alert-success text-right mt-1 small", style: { width: '50%' } },
            "Sistema": { className: "ml-auto mr-auto alert-warning text-center mt-1 small p-0", style: { width: '80%' } }
        };

        const midias = this.state.alerta.Chats.filter(chat => chat.url !== null);

        return (
            <div className="h-100 d-flex flex-column">
                <Row className="flex-grow-1 mb-2">
                    <Col md={4} className="">
                        <ListGroup className="small" variant="flush">
                            <ListGroupItem className="m-1 p-0">
                                <b>Status</b>: <Badge size="lg" variant={AlertaModal.badges[this.state.alerta.status]}>{this.state.alerta.status}</Badge>
                            </ListGroupItem>
                            <ListGroupItem className="m-1 p-0">
                                <b>Conexão</b>: {this.state.online ? <Badge variant="success">Online</Badge> : <Badge variant="danger">Offline</Badge>}
                            </ListGroupItem>
                            <ListGroupItem className="m-1 p-0">
                                <b>Desenvolvedor</b>: {this.state.alerta.DesenvolvedorNome}
                            </ListGroupItem>
                            <ListGroupItem className="m-1 p-0">
                                <b>Classificação</b><br />{this.state.alerta.ClassificacaoNome}
                            </ListGroupItem>
                            <ListGroupItem className="m-1 p-0">
                                <b>Identificação</b><br />{this.state.alerta.identificacao}
                            </ListGroupItem>
                            <ListGroupItem className="m-1 p-0">
                                <b>Telefone</b><br />{this.state.alerta.telefone}
                            </ListGroupItem>
                            <ListGroupItem className="m-1 p-0">
                                <b>Coordenadas</b><br />{this.state.alerta.latitude}, {this.state.alerta.longitude}
                            </ListGroupItem>
                            <ListGroupItem className="m-1 p-0">
                                <b>Endereço Cadastrado</b><br />{this.state.alerta.endereco}
                            </ListGroupItem>
                            <ListGroupItem className="m-1 p-0">
                                <b>Endereço Atual (aprox.)</b><br />{this.state.endereco}
                            </ListGroupItem>
                            <ListGroupItem className="m-1 p-0">
                                <b>Data/Hora</b><br />{moment(this.state.alerta.datahora).format("DD/MM/YYYY[, às ]HH:mm")}
                            </ListGroupItem>
                            <ListGroupItem className="m-1 p-0">
                                <b>Última Atualização</b><br />{moment(this.state.alerta.atualizacao).calendar()}
                            </ListGroupItem>
                            {this.state.alerta.revogacao &&
                                <ListGroupItem className="text-danger m-1 p-0">
                                    <b>Revogação</b><br />{moment(this.state.alerta.revogacao).calendar()}
                                </ListGroupItem>}
                        </ListGroup>
                    </Col>
                    <Col md={8} className="d-flex flex-column">
                        <ul className="nav nav-tabs mb-2" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" id="localizacao-tab" data-toggle="tab" href="#localizacao" role="tab" aria-controls="localizacao" aria-selected="true"><FontAwesomeIcon icon={faGlobeAmericas} /> Localização</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="chat-tab" data-toggle="tab" href="#chat" role="tab" aria-controls="chat" aria-selected="false"><FontAwesomeIcon icon={faComments} /> Chat</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="midia-tab" data-toggle="tab" href="#midia" role="tab" aria-controls="midia" aria-selected="false"><FontAwesomeIcon icon={faPhotoVideo} /> Mídia</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="share-tab" data-toggle="tab" href="#share" role="tab" aria-controls="share" aria-selected="false"><FontAwesomeIcon icon={faShareAlt} /> Compartilhar</a>
                            </li>
                        </ul>
                        <div className="tab-content flex-grow-1 d-flex flex-column">
                            <div style={{ minHeight: 400 }} className="tab-pane fade show active flex-grow-1" id="localizacao" role="tabpanel" aria-labelledby="localizacao-tab">
                                <AlertaModalMap alerta={this.state.alerta} />
                            </div>
                            <div className="tab-pane fade flex-grow-1" id="chat" role="tabpanel" aria-labelledby="chat-tab">
                                <div className="d-flex flex-column h-100">
                                    <div className="d-flex flex-column-reverse overflow-auto mb-2 flex-grow-1">
                                        {this.state.alerta.Chats.map((chat, key) =>
                                            <Alert {...chatConfig[chat.origem]} key={key}>
                                                {chat.mensagem}
                                                <div className="small">
                                                    {moment(chat.datahora).format("DD/MM/YYYY HH:mm")}
                                                </div>
                                            </Alert>
                                        )}
                                    </div>
                                    {!this.props.bloqueado &&
                                        <Form onSubmit={event => this.send(event)}>
                                            <Row>
                                                <Col md={12}>
                                                    <InputGroup>
                                                        <FormControl placeholder="Digite a mensagem..." as='input' value={this.state.mensagem} onChange={event => this.setState({ mensagem: event.target.value })} />
                                                        <InputGroup.Append>
                                                            <Button type="submit" variant="secondary">
                                                                <FontAwesomeIcon icon={faPaperPlane} />
                                                            </Button>
                                                        </InputGroup.Append>
                                                    </InputGroup>
                                                </Col>
                                            </Row>
                                        </Form>
                                    }
                                </div>
                            </div>
                            <div className="tab-pane fade flex-grow-1" id="midia" role="tabpanel" aria-labelledby="midia-tab">
                                <Table className="mt-2">
                                    <thead>
                                        <tr>
                                            <th>Data/Hora</th>
                                            <th width="1%">Ações</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {midias.map((midia, key) =>
                                            <tr key={key}>
                                                <td>{moment(midia.datahora).calendar()}</td>
                                                <td>
                                                    <Button as="a" href={midia.url} target="_blank" variant="primary" title="Download">
                                                        <FontAwesomeIcon icon={faDownload} />
                                                    </Button>
                                                </td>
                                            </tr>)}
                                    </tbody>
                                </Table>
                            </div>
                            <div className="tab-pane fade flex-grow-1" id="share" role="tabpanel" aria-labelledby="share-tab">
                                <Row className="mt-3">
                                    <Col md={12}>
                                        <b>Compartilhe o endereço abaixo para dar acesso a usuários do PISP:</b>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <InputGroup>
                                            <FormControl value={this.context.config.FRONTEND_URL + '/alertapara/' + this.props.uuid} disabled />
                                            <InputGroup.Append>
                                                <Button title="Copiar" type="button" variant="secondary" onClick={() => {
                                                    navigator.clipboard.writeText(this.context.config.FRONTEND_URL + '/alertapara/' + this.props.uuid);
                                                    this.context.addToast({ titulo: "Copiado!", conteudo: "Endereço copiado para a área de transferência." })
                                                }}>
                                                    <FontAwesomeIcon icon={faCopy} />
                                                </Button>
                                            </InputGroup.Append>
                                        </InputGroup>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
                {!this.props.bloqueado &&
                    <Row className="">{this.context.token &&
                        <Col lg={{ span: 2 }}>
                            <Button className="form-control" variant="dark" onClick={() => this.mudarStatus("Bloqueado")}>
                                <FontAwesomeIcon icon={faBan} /> Bloquear
                            </Button>
                        </Col>}
                        <Col lg={{ span: this.context.token ? 2 : 4 }}>
                            <Button className="form-control" variant="info" onClick={() => this.copyData()}>
                                <FontAwesomeIcon icon={faCopy} /> Copiar Dados
                            </Button>
                        </Col>
                        <Col lg={{ span: 2, offset: 4 }}>
                            {(this.state.alerta.status === "Pendente" &&
                                <Button className="form-control" variant="danger" onClick={() => this.mudarStatus("Recusado")}>
                                    <FontAwesomeIcon icon={faThumbsDown} /> Rejeitar
                                </Button>) ||
                                (this.state.alerta.status === "Aceito" &&
                                    <Button className="form-control" variant="dark" onClick={() => this.mudarStatus("Cancelado")}>
                                        <FontAwesomeIcon icon={faTimes} /> Cancelar
                                    </Button>) ||
                                (this.state.alerta.status === "Despachado" &&
                                    <Button className="form-control" variant="dark" onClick={() => this.mudarStatus("Cancelado")}>
                                        <FontAwesomeIcon icon={faTimes} /> Cancelar
                                    </Button>)}
                        </Col>
                        <Col lg={{ span: 2 }}>
                            {(this.state.alerta.status === "Pendente" &&
                                <Button className="form-control" variant="success" onClick={() => this.mudarStatus("Aceito")}>
                                    <FontAwesomeIcon icon={faThumbsUp} /> Aceitar
                                </Button>) ||
                                (this.state.alerta.status === "Aceito" &&
                                    <Button className="form-control" variant="info" onClick={() => this.mudarStatus("Despachado")}>
                                        <FontAwesomeIcon icon={faCarAlt} /> Despachar
                                    </Button>) ||
                                (this.state.alerta.status === "Despachado" &&
                                    <Button className="form-control" variant="primary" onClick={() => this.mudarStatus("Finalizado")}>
                                        <FontAwesomeIcon icon={faCheck} /> Finalizar
                                    </Button>)}
                        </Col>
                    </Row>
                }
            </div>
        );
    }
}

export default AlertaModal;
export const WrappedAlertaModal = props => <AlertaModal uuid={useParams().uuid} {...props} />