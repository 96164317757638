
import ViaturaForm from "./ViaturaForm"
import ViaturaList from "./ViaturaList";
import InicialMap from "./InicialMap";

const TrackerContents = {
    ViaturaForm,
    ViaturaList,
    InicialMap
};

export default TrackerContents;