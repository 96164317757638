import {faComments, faDownload, faGlobeAmericas, faPhotoVideo,  } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React from "react";
import { Alert, Badge, Button, Col, ListGroup, ListGroupItem, Row, Table } from "react-bootstrap";
import { AppContext } from "../../../context";
import AlertaModalMap from "./AlertaModalMap"

class AlertaHistoricoModal extends React.Component {

    static contextType = AppContext;

    state = {
        endereco: ""
    };

    static badges = {
        Pendente: "warning",
        Aceito: "success",
        Despachado: "info",
        Recusado: "danger",
        Cancelado: "dark",
        Finalizado: "primary"
    };

    render() {        

        const chatConfig = {
            "Cliente": { className: "mr-auto alert-secondary text-left mt-1 small", style: { width: '50%' } },
            "Servidor": { className: "ml-auto alert-success text-right mt-1 small", style: { width: '50%' } },
            "Sistema": { className: "ml-auto mr-auto alert-warning text-center mt-1 small p-0", style: { width: '80%' } }
        };

        const midias = this.props.alerta.Chats.filter(chat => chat.url !== null);

        return (
            <Row className="d-flex align-items-stretch">
                <Col md={4}>
                    <ListGroup>
                        <ListGroupItem>
                            <b>Status: </b> <Badge size="lg" variant={AlertaHistoricoModal.badges[this.props.alerta.status]}>{this.props.alerta.status}</Badge>
                        </ListGroupItem>
                        <ListGroupItem>
                            <b>Desenvolvedor: </b> {this.props.alerta.DesenvolvedorNome}
                        </ListGroupItem>
                        <ListGroupItem>
                            <b>Classificação: </b> {this.props.alerta.ClassificacaoNome}
                        </ListGroupItem>
                        <ListGroupItem>
                            <b>Identificação: </b> {this.props.alerta.identificacao}
                        </ListGroupItem>
                        <ListGroupItem>
                            <b>Telefone: </b> {this.props.alerta.telefone}
                        </ListGroupItem>
                        <ListGroupItem>
                            <b>Coordenadas: </b> {this.props.alerta.latitude}, {this.props.alerta.longitude}
                        </ListGroupItem>
                        <ListGroupItem>
                            <b>Endereço (aprox.): </b> {this.state.endereco}
                        </ListGroupItem>
                        <ListGroupItem>
                            <b>Data/Hora: </b> {moment(this.props.alerta.datahora).format("DD/MM/YYYY[, às ]HH:mm")}
                        </ListGroupItem>
                        <ListGroupItem>
                            <b>Última Atualização: </b> {moment(this.props.alerta.atualizacao).calendar()}
                        </ListGroupItem>
                        {this.props.alerta.revogacao &&
                            <ListGroupItem className="text-danger">
                                <b>Revogação: </b> {moment(this.props.alerta.revogacao).calendar()}
                            </ListGroupItem>}
                    </ListGroup>
                </Col>
                <Col md={8} className="mt-2 d-flex flex-column">
                    <ul className="nav nav-tabs" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active" id="localizacao-tab" data-toggle="tab" href="#localizacao" role="tab" aria-controls="localizacao" aria-selected="true"><FontAwesomeIcon icon={faGlobeAmericas} /> Localização</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" id="chat-tab" data-toggle="tab" href="#chat" role="tab" aria-controls="chat" aria-selected="false"><FontAwesomeIcon icon={faComments} /> Chat</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" id="midia-tab" data-toggle="tab" href="#midia" role="tab" aria-controls="midia" aria-selected="false"><FontAwesomeIcon icon={faPhotoVideo} /> Mídia</a>
                        </li>
                    </ul>
                    <div className="tab-content">
                        <div className="tab-pane fade show active mt-2" id="localizacao" role="tabpanel" aria-labelledby="localizacao-tab" style={{ height: 400, width: '100%' }}>
                            <AlertaModalMap alerta={this.props.alerta} />
                        </div>
                        <div className="tab-pane fade" id="chat" role="tabpanel" aria-labelledby="chat-tab">
                            <div className="d-flex flex-column-reverse overflow-auto mt-2" style={{ height: 396 }}>
                                {this.props.alerta.Chats.map((chat, key) =>
                                    <Alert {...chatConfig[chat.origem]} key={key}>
                                        {chat.mensagem}
                                        <div className="small w-100">
                                            {moment(chat.datahora).format("DD/MM/YYYY HH:mm")}
                                        </div>
                                    </Alert>
                                )}
                            </div>
                        </div>
                        <div className="tab-pane fade" id="midia" role="tabpanel" aria-labelledby="midia-tab">
                            <Table className="mt-2">
                                <thead>
                                    <tr>
                                        <th>Data/Hora</th>
                                        <th width="1%">Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {midias.map((midia, key) =>
                                        <tr key={key}>
                                            <td>{moment(midia.datahora).calendar()}</td>
                                            <td>
                                                <Button as="a" href={midia.url} target="_blank" variant="primary" title="Download">
                                                    <FontAwesomeIcon icon={faDownload} />
                                                </Button>
                                            </td>
                                        </tr>)}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </Col>
            </Row>
        );
    }
}

export default AlertaHistoricoModal;