import React from 'react';
import { faHome, faMap } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Col, Row } from 'react-bootstrap';
import { AppContext } from '../../../context';

class MapasPage extends React.Component {

    static contextType = AppContext;

    render() {
        return <>
            <h3><FontAwesomeIcon icon={faHome} /> Página Inicial</h3>
            <hr />
            <Card>
                <Card.Header>
                    Principais Tarefas
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col style={{ textAlign: 'center' }}>
                            <div role="button" onClick={() => this.context.setContent("MapaList")}><FontAwesomeIcon size='2x' icon={faMap} /><br />Gerenciar Mapas</div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>;
    }
}

export default MapasPage;
