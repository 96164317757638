import React from 'react';
import { AppContext } from '../../../context';
import ModelList from '../ModelList';
import AlertaHistoricoModal from './AlertaHistoricoModal';

class AlertaList extends React.Component {
    
    static contextType = AppContext;

    render(){
        return (
            <ModelList 
                url={this.context.config.BACKEND_URL + '/alerta'}
                title="Histórico de Alertas"
                modelIcon="faHistory"
                modelName="alerta"
                modelForm={AlertaHistoricoModal}
                modalSize="xl"
            />
        );
    }
}

export default AlertaList;

