import React from 'react';
import TipoMaterialForm from './TipoMaterialForm';
import ModelList from '../ModelList';
import { AppContext } from '../../../context';

class TipoMaterialList extends React.Component {  
    
    static contextType = AppContext;

    render(){
        return (
            <ModelList 
                url={this.context.config.BACKEND_URL + '/tipoMaterial'}
                title="Lista de Tipos de Material"
                modelName="tipo"
                modelIcon="faCubes"
                modelForm={TipoMaterialForm}
                useAdd={true}
            />
        );
    }
}

export default TipoMaterialList;