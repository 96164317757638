import React from 'react';
import AreaForm from './AreaForm';
import ModelList from '../ModelList';
import { AppContext } from '../../../context';

class AreaList extends React.Component {    

    static contextType = AppContext;

    render(){
        return (
            <ModelList 
                url={this.context.config.BACKEND_URL + '/area'}
                title="Lista de Áreas de Atuação"
                modelName="area"
                modelIcon="faDrawPolygon"
                modelForm={AreaForm}
                useAdd={true}
            />
        );
    }
}

export default AreaList;