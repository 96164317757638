import React from 'react';
import SubfuncaoDocumentoForm from './SubfuncaoDocumentoForm';
import ModelList from '../ModelList';
import { AppContext } from '../../../context';

class SubfuncaoDocumentoList extends React.Component {    
    
    static contextType = AppContext;

    render(){
        return (
            <ModelList 
                url={this.context.config.BACKEND_URL + '/subfuncaoDocumento'}
                title="Lista de Subfunções Documentais"
                modelName="subfuncao"
                modelIcon="faList"
                modelForm={SubfuncaoDocumentoForm}
                useAdd={true}
            />
        );
    }
}

export default SubfuncaoDocumentoList;

