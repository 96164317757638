import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Form as BSForm, FormGroup } from "react-bootstrap";
import { AppContext } from "../../../context";

class BloqueioForm extends React.Component {

    static contextType = AppContext;

    render() {
        return <>
            <FormGroup>
                <BSForm.Label>Alerta</BSForm.Label>
                <input type="text" disabled value={this.props.bloqueio.AlertaUuid} className="form-control" />
            </FormGroup>
            <FormGroup>
                <BSForm.Label>Telefone</BSForm.Label>
                <input type="text" disabled value={this.props.bloqueio.telefone} className="form-control" />
            </FormGroup>
            <FormGroup>
                <BSForm.Label>Tentativa</BSForm.Label>
                <input type="text" disabled value={this.props.bloqueio.tentativa} className="form-control" />
            </FormGroup>
            <FormGroup>
                <BSForm.Label>Motivo</BSForm.Label>
                <input type="text" disabled value={this.props.bloqueio.motivo} className="form-control" />
            </FormGroup>
            <FormGroup>
                <BSForm.Label>Usuário</BSForm.Label>
                <input type="text" disabled value={this.props.bloqueio.Usuario.nome} className="form-control" />
            </FormGroup>
            <FormGroup>
                <BSForm.Label>Cadastro</BSForm.Label>
                <input type="text" disabled value={this.props.bloqueio.createdAt} className="form-control" />
            </FormGroup>
            <FormGroup>
                <BSForm.Label>Expiração</BSForm.Label>
                <input type="text" disabled value={this.props.bloqueio.expiracao} className="form-control" />
            </FormGroup>
            <FormGroup className="text-right">
                <Button type="button" variant='secondary' onClick={() => this.context.closeModal()}>
                    <FontAwesomeIcon icon={faTimes} />&nbsp;
                    Fechar
                </Button>
            </FormGroup>
        </>;
    }
}

export default BloqueioForm;