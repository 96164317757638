import { faPlus, faSave, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Card, Col, Form as BSForm, FormGroup, Row, Tab, Tabs } from "react-bootstrap";
import Request from "../../../request";
import { Formik, Form, Field, ErrorMessage } from "formik";
import moment from "moment";
import CustomEditor from '../../Editor';
import { AppContext } from "../../../context";

class EleicaoForm extends React.Component {

  static contextType = AppContext;

  save(values, callback) {
    const method = this.props.eleicao.id ? "PUT" : "POST";
    const url =
      this.context.config.BACKEND_URL +
      "/eleicao" +
      (this.props.eleicao.id ? "/" + this.props.eleicao.id : "");

    Request(method, url, this.context.token)
      .send(values)
      .then((res) => {
        this.context.addToast({
          titulo: "Successo",
          conteudo: "Registro atualizado com sucesso.",
        });
        callback();
        if (this.props.onSave) this.props.onSave();
        this.context.closeModal();
      })
      .catch((err) => {
        this.context.addToast({
          titulo: "Erro",
          conteudo: "Houve uma falha na gravação do registro.",
        });
        callback();
      });
  }

  render() {
    return (
      <Formik
        initialValues={{
          nome: this.props.eleicao?.nome ?? "",
          descricao: this.props.eleicao?.descricao ?? "",
          inicio_inscricao: !this.props.eleicao?.inicio_inscricao ? "" : moment(this.props.eleicao?.inicio_inscricao).format("YYYY-MM-DDTHH:mm"),
          fim_inscricao: !this.props.eleicao?.fim_inscricao ? "" : moment(this.props.eleicao?.fim_inscricao).format("YYYY-MM-DDTHH:mm"),
          fim_votacao: !this.props.eleicao?.fim_votacao ? "" : moment(this.props.eleicao?.fim_votacao).format("YYYY-MM-DDTHH:mm"),
          inicio_votacao: !this.props.eleicao?.fim_votacao ? "" : moment(this.props.eleicao?.inicio_votacao).format("YYYY-MM-DDTHH:mm"),
          Cargos: this.props.eleicao?.Cargos ?? []
        }}
        validate={(values) => {
          const errors = {};
          if (!values.nome) {
            errors.nome = "Campo obrigatório";
          }
          if (!values.descricao) {
            errors.descricao = "Campo obrigatório";
          }
          if (!values.inicio_inscricao) {
            errors.inicio_inscricao = "Campo obrigatório";
          }
          if (!values.fim_inscricao) {
            errors.fim_inscricao = "Campo obrigatório";
          }
          if (!values.inicio_votacao) {
            errors.inicio_votacao = "Campo obrigatório";
          }
          if (!values.fim_votacao) {
            errors.fim_votacao = "Campo obrigatório";
          }
          if (values.Cargos.some(cargo => !cargo.nome || !cargo.descricao || !cargo.vagas)) {
            errors.Cargos = {};
            values.Cargos.forEach((cargo, i) => {
              if (!cargo.nome || !cargo.descricao || !cargo.vagas) errors.Cargos[i] = {};
              if (!cargo.nome) errors.Cargos[i].nome = "Campo Obrigatório";
              if (!cargo.descricao) errors.Cargos[i].descricao = "Campo Obrigatório";
              if (!cargo.vagas) errors.Cargos[i].vagas = "Campo Obrigatório";
            })
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          this.save(values, () => setSubmitting(false));
        }}
      >
        {({ isSubmitting, values, setFieldValue, errors }) => {
          return (
            <Form>
              <Tabs>
                <Tab eventKey="detalhes" title="Detalhes">
                  <Row className="mt-3">
                    <Col md={12}>
                      <FormGroup>
                        <BSForm.Label>Nome</BSForm.Label>
                        <ErrorMessage
                          name="nome"
                          component="span"
                          className="text-danger small ml-2"
                        />
                        <Field
                          type="text"
                          name="nome"
                          className="form-control"
                        />
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup>
                        <BSForm.Label>Descrição</BSForm.Label>
                        <ErrorMessage
                          name="descricao"
                          component="span"
                          className="text-danger small ml-2"
                        />
                        <CustomEditor
                                name="descricao"
                                value={values.descricao}
                                setFieldValue={setFieldValue}
                            /> 
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <BSForm.Label>Início das Inscrições</BSForm.Label>
                        <ErrorMessage
                          name="inicio_inscricao"
                          component="span"
                          className="text-danger small ml-2"
                        />
                        <Field name="inicio_inscricao" type="datetime-local" className="form-control" />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <BSForm.Label>Fim das Inscrições</BSForm.Label>
                        <ErrorMessage
                          name="fim_inscricao"
                          component="span"
                          className="text-danger small ml-2"
                        />
                        <Field name="fim_inscricao" type="datetime-local" className="form-control" />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <BSForm.Label>Início da Votação</BSForm.Label>
                        <ErrorMessage
                          name="inicio_votacao"
                          component="span"
                          className="text-danger small ml-2"
                        />
                        <Field name="inicio_votacao" type="datetime-local" className="form-control" />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <BSForm.Label>Fim da Votação</BSForm.Label>
                        <ErrorMessage
                          name="fim_votacao"
                          component="span"
                          className="text-danger small ml-2"
                        />
                        <Field name="fim_votacao" type="datetime-local" className="form-control" />
                      </FormGroup>
                    </Col>
                  </Row>
                </Tab>
                <Tab eventKey="cargos" title={<span className={errors.Cargos ? 'text-danger' : ''}>Cargos</span>}>
                  <Button variant="success" className="form-control mt-3" onClick={() => setFieldValue("Cargos", [...values.Cargos, { id: null, nome: "", descricao: "", vagas: 1 }])}>
                    <FontAwesomeIcon icon={faPlus} /> Adicionar Cargo
                  </Button>
                  {values.Cargos.map((cargo, key) =>
                    <Card key={key} className="mt-3">
                      <Card.Header>
                        <div className="d-flex justify-content-between">
                          <div>{cargo.nome}</div>
                          <Button variant="danger" size="sm" onClick={() => setFieldValue("Cargos", values.Cargos.filter(c => c !== cargo))}>
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                        </div>
                      </Card.Header>
                      <Card.Body>
                        <Row>
                          <Col md={6}>
                            <FormGroup>
                              <BSForm.Label>Nome do Cargo</BSForm.Label>
                              <ErrorMessage
                                name={`Cargos[${key}].nome`}
                                component="span"
                                className="text-danger small ml-2"
                              />
                              <Field name={`Cargos[${key}].nome`} type="text" className="form-control" />
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <BSForm.Label>Vagas</BSForm.Label>
                              <ErrorMessage
                                name={`Cargos[${key}].vagas`}
                                component="span"
                                className="text-danger small ml-2"
                              />
                              <Field name={`Cargos[${key}].vagas`} type="number" step={1} min={1} className="form-control" />
                            </FormGroup>
                          </Col>
                          <Col md={12}>
                            <FormGroup>
                              <BSForm.Label>Descrição do Cargo</BSForm.Label>
                              <ErrorMessage
                                name={`Cargos[${key}].descricao`}
                                component="span"
                                className="text-danger small ml-2"
                              />
                              <Field name={`Cargos[${key}].descricao`} as="textarea" className="form-control" />
                            </FormGroup>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  )}
                </Tab>
              </Tabs>
              <FormGroup className="text-right mt-3">
                <Button
                  type="button"
                  variant="secondary"
                  onClick={() => this.context.closeModal()}
                >
                  <FontAwesomeIcon icon={faTimes} />
                  &nbsp; Fechar
                </Button>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  variant="primary"
                  className="ml-2"
                >
                  <FontAwesomeIcon icon={faSave} />
                  &nbsp; Gravar Alterações
                </Button>
              </FormGroup>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

export default EleicaoForm;
