import React from 'react';
import { EditorState, convertFromRaw, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

class CustomEditor extends React.Component {
  constructor(props) {
    super(props);

    let editorState;

    if (props.value) {
      const blocksFromHtml = htmlToDraft(props.value);
      if (blocksFromHtml) {
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        editorState = EditorState.createWithContent(contentState);
      } else {
        editorState = EditorState.createEmpty();
      }
    } else {
      editorState = EditorState.createEmpty();
    }

    this.state = {
      editorState,
    };
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });

    const contentState = editorState.getCurrentContent();
    const htmlContent = draftToHtml(convertToRaw(contentState));

    this.props.setFieldValue(this.props.name, htmlContent);
  };

  render() {
    //teste
    const { editorState } = this.state;

    return (
      <Editor
        editorState={editorState}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        toolbarClassName="toolbar-class"
        onEditorStateChange={this.onEditorStateChange}
      />
    );
  }
}

export default CustomEditor;
