import { useEffect, useRef, useState } from "react";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMap,
  useMapEvents,
} from "react-leaflet";
import L from "leaflet";
import { AppContext } from "../context";

function Map(props) {
  const [position, setPosition] = useState(null);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          const { latitude, longitude } = position.coords;
          setPosition([latitude, longitude]);
        },
        function (error) {
          console.log("Erro ao obter localização inicial: " + error.message);
        }
      );

      navigator.geolocation.watchPosition(
        function (position) {
          const { latitude, longitude } = position.coords;
          setPosition([latitude, longitude]);
        },
        function (error) {
          console.log(
            "Erro ao obter localização em tempo real: " + error.message
          );
        }
      );
    } else {
      console.log("Geolocalização não é suportada por este navegador.");
    }
  }, []);

  const update = (data) => {
    setPosition([data.latitude, data.longitude]);
    props.onChange(data);
  };

  const center = props.center;

  return (
    <>
  {!center && (
    <>
      {position && (
        <MapContainer
          center={position}
          zoom={18}
          scrollWheelZoom="center"
          style={{ minHeight: "300px", height: "100%", width: "100wh" }}
        >
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <LeafletControlGeocoder />
          {position && (
            <MyMarker position={position} onChange={(data) => update(data)} />
          )}
        </MapContainer>
      )}

      {!position && (
        <MapContainer
          center={[-1.4631897998991463, -48.4955653127071868]}
          inertia={false}
          zoom={15}
          scrollWheelZoom="center"
          style={{ minHeight: "300px", height: "100%", width: "100wh" }}
        >
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <LeafletControlGeocoder />
          <MyMarker
            position={[-1.4631897998991463, -48.4955653127071868]}
            onChange={(data) => {
              update(data);
            }}
          />
        </MapContainer>
      )}
    </>
  )}
  {center && (
    <MapContainer
      center={center}
      zoom={18}
      scrollWheelZoom="center"
      style={{ minHeight: "300px", height: "100%", width: "100wh" }}
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <LeafletControlGeocoder />
      <MyMarker
        position={center}
        onChange={(data) => {
          update(data);
        }}
      />
    </MapContainer>
  )}
</>
  );
}

function MyMarker(props) {
  const markerRef = useRef(null);
  const [text, setText] = useState("Arraste o mapa para posicionar o cursor.");

  const map = useMapEvents({
    drag() {
      if (markerRef.current) {
        markerRef.current.setLatLng(map.getCenter());
      }
    },
    dragend(e) {
      if (markerRef.current) {
        markerRef.current.setLatLng(map.getCenter());
        locate();
      }
    }
  });

  const locate = () => {
    const latlng = map.getCenter();

    const lat = latlng.lat;
    const lon = latlng.lng;
    const zoom = map.getZoom();
    const addressDetails = 1;
    const format = "json";

    const apiUrl = `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lon}&zoom=${zoom}&addressdetails=${addressDetails}&format=${format}`;

    // Fazendo a solicitação usando fetch
    fetch(apiUrl)
      .then((response) => response.json())
      .then((result) => {
        if (result.address) {
          const address = result.address;
          const cidade =
            address.city ?? address.town ?? address.city_district ?? "";
          const bairro =
            address.neighbourhood ??
            address.suburb ??
            address.place ??
            address.village ??
            address.city_district ??
            "";
          const logradouro =
            address.road ??
            address.street ??
            address.street_name ??
            address.place ??
            "";
          const numero = address.house_number ?? "";

          setText(
            logradouro + ", " + numero + ", " + bairro + ", " + cidade + "."
          );

          props.onChange({
            latitude: latlng.lat,
            longitude: latlng.lng,
            cidade,
            bairro,
            logradouro,
            numero,
          });
        } else {
          props.onChange({
            latitude: latlng.lat,
            longitude: latlng.lng,
          });
        }
      })
      .catch((error) => {
        console.error("Erro na solicitação:", error);
      });
  };

  return (
    <Marker position={props.position} ref={markerRef}>
      <Popup>{text}</Popup>
    </Marker>
  );
}

function LeafletControlGeocoder(props) {
  const map = useMap();

  useEffect(() => {
    const geocoder = L.Control.geocoder({
      query: "",
      placeholder: "Pesquisar Logradouro...",
      defaultMarkGeocode: false,
      geocoder: L.Control.Geocoder.nominatim(),
    });

    geocoder.on("markgeocode", ({ geocode }) => {
      const latitude = geocode.center.lat;
      const longitude = geocode.center.lng;
      map.setView([latitude, longitude], map.getZoom());
      map.fire("dragend");
    });

    geocoder.addTo(map);

    return () => {
      geocoder
        ? map.removeControl(geocoder)
        : console.log("geocoder não foi definido.");
    };
  }, [map]);

  return null;
}

LeafletControlGeocoder.contextType = AppContext;

MyMarker.contextType = AppContext;

Map.contextType = AppContext;

export default Map;
