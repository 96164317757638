import AdminPage from "./AdminPage";
import ConfiguracaoList from "./ConfiguracaoList";
import ConfiguracaoForm from "./ConfiguracaoForm";
import ModuloForm from "./ModuloForm";
import ModuloList from "./ModuloList";
import OrgaoForm from "./OrgaoForm";
import OrgaoList from "./OrgaoList";
import PerfilList from "./PerfilList.js";
import PerfilForm from "./PerfilForm";
import PermissaoList from "./PermissaoList";
import PermissaoForm from "./PermissaoForm";
import UsuarioForm from "./UsuarioForm";
import UsuarioList from "./UsuarioList";
import SetorForm from "./SetorForm";
import SetorList from "./SetorList";
import AcessoForm from "./AcessoForm";
import AcessoList from "./AcessoList";
import AvisoForm from "./AvisoForm";
import AvisoList from "./AvisoList";


const AdminContents = {
    AdminPage,
    ConfiguracaoList,
    ConfiguracaoForm,
    ModuloList,
    ModuloForm,
    OrgaoForm,
    OrgaoList,
    PerfilList,
    PerfilForm,
    PermissaoList,
    PermissaoForm,
    UsuarioForm,
    UsuarioList,
    SetorForm,
    SetorList,
    AcessoForm,
    AcessoList,
    AvisoForm,
    AvisoList
};

export default AdminContents;