import React from 'react';
import ClassificacaoDocumentoForm from './ClassificacaoDocumentoForm';
import ModelList from '../ModelList';
import { AppContext } from '../../../context';

class ClassificacaoDocumentoList extends React.Component {    
    
    static contextType = AppContext;

    render(){
        return (
            <ModelList 
                url={this.context.config.BACKEND_URL + '/classificacaoDocumento'}
                title="Lista de Classificações Documentais"
                modelName="classificacao"
                modelIcon="faList"
                modelForm={ClassificacaoDocumentoForm}
                useAdd={true}
            />
        );
    }
}

export default ClassificacaoDocumentoList;

