import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Form as BSForm, FormGroup } from "react-bootstrap";
import Request from "../../../request";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { AppContext } from "../../../context";
import CustomEditor from '../../Editor';

class AvisoForm extends React.Component {

    static contextType = AppContext;

    save(values, callback) {
        const method = this.props.aviso.id ? 'PUT' : 'POST';
        const url = this.context.config.BACKEND_URL + '/aviso' + (this.props.aviso.id ? '/' + this.props.aviso.id : '');

        Request(method, url, this.context.token)
            .send(values)
            .then(() => {
                this.context.addToast({ titulo: "Successo", conteudo: "Registro atualizado com sucesso." });
                callback();
                if (this.props.onSave) this.props.onSave();
                this.context.closeModal();
            })
            .catch(err => {
                this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na gravação do registro." });
                callback();
            });

    }

    render() {
        return (
            <Formik
                initialValues={{
                    titulo: this.props.aviso.titulo,
                    conteudo: this.props.aviso.conteudo,
                }}
                validate={values => {
                    const errors = {};
                    if (!values.titulo) {
                        errors.nome = 'Campo obrigatório';
                    }
                    if (!values.conteudo) {
                        errors.sigla = 'Campo obrigatório';
                    }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    this.save(values, () => setSubmitting(false));
                }}
            >
                {({ isSubmitting, values, setFieldValue }) => (
                    <Form>
                        <FormGroup>
                            <BSForm.Label>Título</BSForm.Label><ErrorMessage name="titulo" component="span" className="text-danger small ml-2" />
                            <Field type="text" name="titulo" className="form-control" required />

                        </FormGroup>
                        <FormGroup>
                            <BSForm.Label>Conteúdo</BSForm.Label><ErrorMessage name="conteudo" component="span" className="text-danger small ml-2" />
                            <CustomEditor
                                name="conteudo"
                                value={values.conteudo}
                                setFieldValue={setFieldValue}
                            />                  </FormGroup>
                        <FormGroup className="text-right">
                            <Button type="button" variant='secondary' onClick={() => this.context.closeModal()}>
                                <FontAwesomeIcon icon={faTimes} />&nbsp;
                                Fechar
                            </Button>
                            <Button type="submit" disabled={isSubmitting} variant='primary' className="ml-2">
                                <FontAwesomeIcon icon={faSave} />&nbsp;
                                Gravar Alterações
                            </Button>
                        </FormGroup>
                    </Form>
                )}
            </Formik>
        );
    }
}

export default AvisoForm;