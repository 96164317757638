import React from 'react';
import { AppContext } from '../../../context';
import Request from '../../../request';
import ModelList from '../ModelList';
import EleicaoForm from './EleicaoForm';

class EleicaoList extends React.Component {   

    static contextType = AppContext;
    
    onAction(action, update, defaultHandler){
        if (action.name === 'notify'){
            window.confirm("Esta ação irá disparar um envio em massa para todos os eleitores confirmados e ausentes, juntamente com o link de votação que estará disponível no perído cadastrado para esta eleição. Tem certeza que deseja executar esta operação?") &&
            Request("GET", this.context.config.BACKEND_URL + "/eleicao/notificar/" + action.id, this.context.token)
            .then(() => {
                this.context.addToast({titulo: "Notificação", conteudo: "Os eleitores confirmados foram notificados com o link para votação."});
            })
            .catch(err => {
                this.context.addToast({titulo: "Erro", conteudo: "Houve um erro no envio da notificação para votação: "+err.toString()});
            })
        } else if (action.name === 'dashboard'){
            Request("GET", this.context.config.BACKEND_URL + "/eleicoes/dashboard/" + action.id)
            .then(res => {
                this.context.setContent("EleicaoResultado", {dashboard: res.body})
            })
            .catch(err => {
                this.context.addToast({titulo: "Erro", conteudo: "Houve um erro na recuperação da eleição: "+err.toString()});
            })
        } else defaultHandler();
    }

    render(){
        return (
            
            <ModelList 
                url={this.context.config.BACKEND_URL + '/eleicao'}
                title="Lista de Eleições"
                modelName="eleicao"
                modelIcon="faVoteYea"
                modelForm={EleicaoForm}
                modalSize="lg"
                useAdd={this.context.usuario.Permissoes.some(p => p.nome === 'eleicoes_eleicao_create')}
                onAction={(action, update, defaultHandler) => this.onAction(action, update, defaultHandler)}
            />
        );
    }
    
}

export default EleicaoList;