import React from 'react';
import { AppContext } from '../../../context';
import ModelList from '../ModelList';
import EixoForm from './EixoForm';

class EixoList extends React.Component {

    static contextType = AppContext;

    render() {
        return (
            <ModelList
                url={this.context.config.BACKEND_URL + '/eixo'}
                title="Lista de Eixos"
                modelName="eixo"
                modelIcon="faPeopleArrows"
                modelForm={EixoForm}
                useAdd={true}
            />
        );
    }
}

export default EixoList;