import EleicoesFrontPage from "./EleicoesFrontPage";
import EleicoesPage from "./EleicoesPage";
import EleicaoList from "./EleicaoList";
import EleicaoForm from "./EleicaoForm";
import EleitorList from "./EleitorList";
import EleitorForm from "./EleitorForm";
import ChapaList from "./ChapaList";
import ChapaForm from "./ChapaForm";
import EleicaoFrontPage from "./EleicaoFrontPage";
import EleicoesVotarDetalhes from "./EleicoesVotarDetalhes";
import EleicaoResultado from "./EleicaoResultado";
import ChapaDetalhes from "./ChapaDetalhes";
import EleicoesDashboardDetalhes from "./EleicoesDashboardDetalhes"



const EleicoesContents = {
    EleicoesFrontPage,
    EleicoesPage,
    EleicaoList,
    EleicaoForm,
    EleitorList,
    EleitorForm,
    ChapaList,
    ChapaForm,
    EleicaoFrontPage,
    EleicoesVotarDetalhes,
    EleicaoResultado,
    ChapaDetalhes,
    EleicoesDashboardDetalhes
};

export default EleicoesContents;