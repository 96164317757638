import ContratosPage from "./ContratosPage";
import StatusContratoForm from "./StatusContratoForm";
import StatusContratoList from "./StatusContratoList";
import NaturezaContratoForm from "./NaturezaContratoForm";
import NaturezaContratoList from "./NaturezaContratoList";
import AtributoContratoForm from "./AtributoContratoForm";
import AtributoContratoList from "./AtributoContratoList";
import ModalidadeForm from "./ModalidadeForm";
import ModalidadeList from "./ModalidadeList";
import FuncaoMembroForm from "./FuncaoMembroForm";
import FuncaoMembroList from "./FuncaoMembroList";
import ComissaoForm from "./ComissaoForm";
import ComissaoList from "./ComissaoList";
import ContratoForm from "./ContratoForm";
import ContratoList from "./ContratoList";
import AditivoList from "./AditivoList";
import AditivoForm from "./AditivoForm";
import ApostilamentoList from "./ApostilamentoList";
import ApostilamentoForm from "./ApostilamentoForm";
import AcompanhamentoList from "./AcompanhamentoList";
import AcompanhamentoForm from "./AcompanhamentoForm";

const ContratosContents = {
    ContratosPage,
    StatusContratoForm,
    StatusContratoList,
    NaturezaContratoForm,
    NaturezaContratoList,
    AtributoContratoForm,
    AtributoContratoList,
    ModalidadeForm,
    ModalidadeList,
    FuncaoMembroForm,
    FuncaoMembroList,
    ContratoForm,
    ContratoList,
    ComissaoForm,
    ComissaoList,
    AditivoList,
    AditivoForm,
    ApostilamentoList,
    ApostilamentoForm,
    AcompanhamentoList,
    AcompanhamentoForm,    
};

export default ContratosContents;