import React from 'react';
import IndicadorForm from './IndicadorForm';
import ModelList from '../ModelList';
import { AppContext } from '../../../context';

class IndicadorList extends React.Component {    

    static contextType = AppContext;

    render(){
        return (
            <ModelList 
                url={this.context.config.BACKEND_URL + '/indicador'}
                title="Lista de Indicadores"
                modelName="indicador"
                modelIcon="faChartBar"
                modelForm={IndicadorForm}
                useAdd={true}
            />
        );
    }
}

export default IndicadorList;