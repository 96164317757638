import React from 'react';
import NaturezaContratoForm from './NaturezaContratoForm';
import ModelList from '../ModelList';
import { AppContext } from '../../../context';

class NaturezaContratoList extends React.Component {   
    
    static contextType = AppContext;

    render(){
        return (
            <ModelList 
                url={this.context.config.BACKEND_URL + '/naturezaContrato'}
                title="Lista de Natureza de Contrato"
                modelName="natureza"
                modelIcon="faThList"
                modelForm={NaturezaContratoForm}
                useAdd={true}
            />
        );
    }
}

export default NaturezaContratoList;

