import React from 'react';
import BoletimAtributoForm from './BoletimAtributoForm';
import ModelList from '../ModelList';
import { AppContext } from '../../../context';

class BoletimAtributoList extends React.Component {   

    static contextType = AppContext;


    render(){
        return (
            <ModelList 
                url={this.context.config.BACKEND_URL + '/atributoBoletim'}
                title="Lista de Atributos do Boletim"
                modelName="atributo"
                modelIcon="faList"
                modelForm={BoletimAtributoForm}
                modalSize="lg"
                useAdd={true}
            />
        );
    }
}

export default BoletimAtributoList;