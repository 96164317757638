import { faCalculator, faDownload, faFileDownload, faFolderOpen, faList, faPencilAlt, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Col, Form as BSForm, FormControl, Row, Tab, Table, Tabs } from "react-bootstrap";
import fileSize from "filesize";
import moment from "moment";
import { AppContext } from "../../../context";


const formatter = new Intl.NumberFormat('pt-Br', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
});

class PublicContratoForm extends React.Component {

    static contextType = AppContext;

    render() {
        const contrato = this.props.contrato;

        return (
            <Tabs defaultActiveKey="detalhes">
                <Tab eventKey="detalhes" title={
                    <div>
                        <FontAwesomeIcon icon={faPencilAlt} /> <span className="d-none d-lg-inline">Detalhes</span>
                    </div>
                }>
                    <Row className="mt-2">
                        <Col md={2}>
                            <BSForm.Label>Número</BSForm.Label>
                            <FormControl type="text" disabled value={contrato.numero} />
                        </Col>
                        <Col md={5}>
                            <BSForm.Label>Órgão Contratante</BSForm.Label>
                            <FormControl type="text" disabled value={contrato.Orgao.sigla} />
                        </Col>
                        <Col md={5}>
                            <BSForm.Label>Setor Demandante</BSForm.Label>
                            <FormControl type="text" disabled value={contrato.Setor.sigla} />
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col md={9}>
                            <BSForm.Label>Nome do Contratado</BSForm.Label>
                            <FormControl type="text" disabled value={contrato.nome} />
                        </Col>
                        <Col md={3}>
                            <BSForm.Label>CPF/CNPJ</BSForm.Label>
                            <FormControl type="text" disabled value={contrato.cpf_cnpj} />
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col md={4}>
                            <BSForm.Label>Natureza</BSForm.Label>
                            <FormControl type="text" disabled value={contrato.Natureza.nome} />
                        </Col>
                        <Col md={4}>
                            <BSForm.Label>Modalidade da Licitacao</BSForm.Label>
                            <FormControl type="text" disabled value={contrato.Modalidade.nome} />

                        </Col>
                        <Col md={4}>
                            <BSForm.Label>Status do Contrato</BSForm.Label>
                            <FormControl type="text" disabled value={contrato.Status.nome} />

                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col md={12}>
                            <BSForm.Label>Objeto</BSForm.Label>
                            <FormControl as="textarea" disabled value={contrato.objeto} />

                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col md={3}>
                            <BSForm.Label>Data de Publicação</BSForm.Label>
                            <FormControl type="date" disabled value={contrato.data_publicacao} />
                        </Col>
                        <Col md={3}>
                            <BSForm.Label>Início da Vigência</BSForm.Label>
                            <FormControl type="date" disabled value={contrato.inicio_vigencia} />
                        </Col>
                        <Col md={3}>
                            <BSForm.Label>Término da Vigência</BSForm.Label>
                            <FormControl type="date" disabled value={contrato.fim_vigencia} />
                        </Col>
                        <Col md={3}>
                            <BSForm.Label>Valor Global</BSForm.Label>
                            <FormControl type="text" disabled value={formatter.format(contrato.valor_global)} />
                        </Col>
                    </Row>
                </Tab>
                <Tab eventKey="atributos" title={
                    <div>
                        <FontAwesomeIcon icon={faList} /> <span className="d-none d-lg-inline">Atributos</span>
                    </div>
                }>
                    <Table bordered condensed responsive striped className="mt-3" size="sm">
                        <thead>
                            <tr>
                                <th>Atributo</th>
                                <th>Valor</th>
                            </tr>
                        </thead>
                        <tbody>
                            {contrato.Atributos.map((atributo, key) => (
                                <tr key={key}>
                                    <td>{atributo.nome}</td>
                                    <td>{atributo.AtributoContrato.valor}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Tab>
                <Tab eventKey="comissao" title={
                    <div>
                        <FontAwesomeIcon icon={faUsers} /> <span className="d-none d-lg-inline">Comissão</span>
                    </div>
                }>
                    <Row>
                        <Col md={12} className="mt-3">
                            <legend>Membros da Comissão</legend>
                            <Table striped condensed>
                                <thead>
                                    <tr>
                                        <th>Nome</th>
                                        <th>Função</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {contrato.Comissao?.Membros.map((membro, key) =>
                                        <tr key={key}>
                                            <td>{membro.Usuario.nome}</td>
                                            <td>{membro.Funcao.nome}</td>
                                        </tr>)}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Tab>
                <Tab eventKey="aditivos" title={
                    <div>
                        <FontAwesomeIcon icon={faCalculator} /> <span className="d-none d-lg-inline">Aditivos/Supressões</span>
                    </div>
                }>
                    <Table striped size="sm" responsive className='mt-3'>
                        <thead className='bg-light'>
                            <tr>
                                <th style={{ minWidth: "1%" }}>Nº</th>
                                <th style={{ minWidth: "1%" }}>Operação</th>
                                <th>Objeto</th>
                                <th style={{ minWidth: "1%" }}>Publicação</th>
                                <th style={{ width: "120px" }}>Vigência</th>
                                <th style={{ minWidth: "1%" }}>V. Global</th>
                            </tr>
                        </thead>
                        <tbody>
                            {contrato.Aditivos.map((aditivo, key) =>
                                <tr key={key}>
                                    <td>{aditivo.numero}º</td>
                                    <td style={{ whiteSpace: 'nowrap' }}>{aditivo.operacao} de {aditivo.alvo}</td>
                                    <td>{aditivo.objeto}</td>
                                    <td>{moment(aditivo.data_publicacao).format("DD/MM/YYYY")}</td>
                                    <td>{moment(aditivo.inicio_vigencia).format("DD/MM/YYYY")} a<br />
                                        {moment(aditivo.fim_vigencia).format("DD/MM/YYYY")}</td>
                                    <td>{formatter.format(aditivo.valor_global)}</td>
                                </tr>)}
                        </tbody>
                    </Table>
                </Tab>
                <Tab eventKey="apostilamentos" title={
                    <div>
                        <FontAwesomeIcon icon={faFolderOpen} /> <span className="d-none d-lg-inline">Apostilamentos</span>
                    </div>
                }>
                    <Table striped size="sm" responsive className='mt-3'>
                        <thead className='bg-light'>
                            <tr>
                                <th style={{ minWidth: "1%" }}>Nº</th>
                                <th>Objeto</th>
                                <th style={{ minWidth: "1%" }}>Publicação</th>
                            </tr>
                        </thead>
                        <tbody>
                            {contrato.Apostilamentos.map((apostilamento, key) =>
                                <tr key={key}>
                                    <td>{apostilamento.numero}º</td>
                                    <td>{apostilamento.objeto}</td>
                                    <td>{moment(apostilamento.data_publicacao).format("DD/MM/YYYY")}</td>
                                </tr>)}
                        </tbody>
                    </Table>
                </Tab>
                <Tab eventKey="anexos" title={
                    <div>
                        <FontAwesomeIcon icon={faFileDownload} /> <span className="d-none d-lg-inline">Anexos</span>
                    </div>
                }>
                    <Table striped size="sm" responsive className='mt-3'>
                        <thead className='bg-light'>
                            <tr>
                                <th>Nome</th>
                                <th style={{ minWidth: "1%" }}>Data</th>
                                <th style={{ width: 120 }}>Tamanho</th>
                                <th style={{ width: 120, textAlign: 'center' }}>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {contrato.Anexos.map((anexo, key) =>
                                <tr key={key}>
                                    <td>
                                        {anexo.nome}
                                    </td>
                                    <td style={{ whiteSpace: 'nowrap' }}>
                                        {moment(anexo.updatedAt).locale('pt-br').calendar()}
                                    </td>
                                    <td>
                                        {fileSize(anexo.tamanho, { locale: 'pt', round: 0 })}
                                    </td>
                                    <td className='text-center'>
                                        {anexo.id ?
                                            <Button as="a" href={`${this.context.config.BACKEND_URL}/anexo/contrato/${anexo.id}/${anexo.hash}/${anexo.nome}`} download={anexo.nome} size="sm" variant='info' title='Download' className='mr-2'>
                                                <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                                            </Button> : null}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Tab>
            </Tabs>);
    }
}

export default PublicContratoForm;