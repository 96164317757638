import FacesPage from "./FacesPage";
import CadastroList from "./CadastroList";
import CadastroForm from "./CadastroForm";
import SituacaoList from "./SituacaoList";
import SituacaoForm from "./SituacaoForm";
import CadastroPrint from "./CadastroPrint";



const FaceContents = {
  FacesPage,
  CadastroList,
  CadastroForm,
  SituacaoList,
  SituacaoForm,
  CadastroPrint

};

export default FaceContents;
