import React from 'react';
import { AppContext } from "../../../context";
import ModelList from '../ModelList';
import DenunciasArquivadasModal from './DenunciasArquivadasModal';

class DenunciasArquivadasList extends React.Component {
    static contextType = AppContext;
    render() {
        return (

            <ModelList
                url={this.context.config.BACKEND_URL + '/denuncia/arquivadas'}
                title="Listas de Denuncias Arquivadas"
                modelName="arquivadas"
                modelIcon="faFileExport"
                modelForm={DenunciasArquivadasModal}
                useAdd={false} />

        );
    }
}


export default DenunciasArquivadasList;