import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Col, Form as BSForm, Row } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { AppContext } from "../../../context";
import CustomEditor from '../../Editor';

class CamadaForm extends React.Component {

    static contextType = AppContext;

    render() {
        return (
            <Formik
                initialValues={{
                    id: this.props.camada.id,
                    MapaId: this.props.camada.MapaId,
                    nome: this.props.camada.nome,
                    descricao: this.props.camada.descricao,
                    Componentes: this.props.camada.Componentes ?? [],
                    oculto: this.props.camada.oculto ?? false
                }}
                validate={values => {
                    const errors = {};
                    if (!values.nome) {
                        errors.nome = 'Campo obrigatório';
                    }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    this.context.isAuthorized("mapas_mapa_edit") && this.props.onSave(values, () => setSubmitting(false));
                }}
            >
                {({ isSubmitting, setFieldValue, values }) => {
                    return (
                        <Form>
                            <Row className="mb-3">
                                <Col>
                                    <BSForm.Label>Nome</BSForm.Label><ErrorMessage name="nome" component="span" className="text-danger small ml-2" />
                                    <Field type="text" name="nome" className="form-control" />
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col>
                                    <BSForm.Label>Descrição</BSForm.Label>
                                    <CustomEditor
                                name="descricao"
                                value={values.descricao}
                                setFieldValue={setFieldValue}
                            /> 
                                </Col>
                            </Row>
                            <Row className="text-right">
                                <Col>
                                    <Button type="button" variant='secondary' onClick={() => this.context.closeModal()}>
                                        <FontAwesomeIcon icon={faTimes} />&nbsp;
                                        Fechar
                                    </Button>
                                    {this.context.isAuthorized("mapas_mapa_edit") &&
                                    <Button type="submit" disabled={isSubmitting} variant='primary' className="ml-2">
                                        <FontAwesomeIcon icon={faSave} />&nbsp;
                                        Gravar Alterações
                                    </Button>
                                    }
                                </Col>
                            </Row>
                        </Form>
                    );
                }}
            </Formik >
        );
    }
}

export default CamadaForm;