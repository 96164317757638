import React from "react";
import { Modal as BSModal } from "react-bootstrap";
import { AppContext } from "../context";

class Modal extends React.Component {

    static contextType = AppContext;

    render() {
        return (
            <BSModal
                size={this.context.modal?.size ?? 'md'}
                show={this.context.modal?.show}
                scrollable={this.context.modal?.scrollable}
                onHide={() => {
                    typeof this.context.modal?.onClose === "function" && this.context.modal.onClose();
                    this.context.closeModal();
                }}>
                <BSModal.Header closeButton>
                    <BSModal.Title>{this.context.modal?.titulo}</BSModal.Title>
                </BSModal.Header>
                <BSModal.Body id="modal-body-container">
                    {this.context.modal?.conteudo}
                </BSModal.Body>
            </BSModal>
        );
    }
}

export default Modal;