import AlertaParaPage from "./AlertaParaPage";
import AlertaPage from "./AlertaPage";
import ClassificacaoAlertaList from "./ClassificacaoAlertaList";
import ClassificacaoAlertaForm from "./ClassificacaoAlertaForm";
import DesenvolvedorForm from "./DesenvolvedorForm";
import DesenvolvedorList from "./DesenvolvedorList";
import BloqueioForm from "./BloqueioForm";
import BloqueioList from "./BloqueioList";
import AlertaList from "./AlertaList";


const AlertaParaContents = {
    AlertaParaPage,
    AlertaPage,
    ClassificacaoAlertaForm,
    ClassificacaoAlertaList,
    DesenvolvedorList,
    DesenvolvedorForm,
    BloqueioList,
    BloqueioForm,
    AlertaList
};

export default AlertaParaContents;