import React from 'react';
import ModalidadeForm from './ModalidadeForm';
import ModelList from '../ModelList';
import { AppContext } from '../../../context';

class ModalidadeList extends React.Component {   
    
    static contextType = AppContext;

    render(){
        return (
            <ModelList 
                url={this.context.config.BACKEND_URL + '/modalidade'}
                title="Lista de Modalidade de Licitação"
                modelName="modalidade"
                modelIcon="faThList"
                modelForm={ModalidadeForm}
                useAdd={true}
            />
        );
    }
}

export default ModalidadeList;

