import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Form as BSForm, FormGroup } from "react-bootstrap";
import Request from "../../../request";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { AppContext } from "../../../context";
import AlertaMap from "./AreaMap";

class AreaForm extends React.Component {

    static contextType = AppContext;

    state = {
        risps: []
    }

    componentDidMount() {
        Request('GET', this.context.config.BACKEND_URL + '/risp/options', this.context.token)
            .then(res => {
                this.setState(() => ({ risps: res.body }))
            })
            .catch(err => {
                this.context.addToast({
                    titulo: 'Erro',
                    conteudo: 'Erro na recuperação da lista de Risps'
                })
            })
    }

    save(values, callback) {
        const method = this.props.area.id ? 'PUT' : 'POST';
        const url = this.context.config.BACKEND_URL + '/area' + (this.props.area.id ? '/' + this.props.area.id : '');

        Request(method, url, this.context.token)
            .send(values)
            .then(res => {
                this.context.addToast({ titulo: "Successo", conteudo: "Registro atualizado com sucesso." });
                callback();
                if (this.props.onSave) this.props.onSave();
                this.context.closeModal();
            })
            .catch(err => {
                this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na gravação do registro." });
                callback();
            });

    }

    render() {
        return (
            <Formik
                initialValues={{
                    ...this.props.area,
                    RispId: this.props.area.RispId,
                    Pontos: this.props.area.Pontos ?? []
                }}
                validate={values => {
                    const errors = {};
                    if (!values.nome) {
                        errors.nome = 'Campo obrigatório';
                    }
                    if (!values.RispId) {    
                        errors.RispId = 'Campo obrigatório';     
                    }
                    if (values.Pontos.length < 3){
                        errors.Pontos = 'Indique ao menos três pontos para delimitar a área.';
                    }
                    console.log(errors);
                    return errors;   
                }}
                onSubmit={(values, { setSubmitting }) => {
                    this.save(values, () => setSubmitting(false));
                }}
            >
                {({ isSubmitting, values, setFieldValue }) => {
                    return (
                        <Form>
                            <FormGroup>
                                <BSForm.Label>Nome</BSForm.Label><ErrorMessage name="nome" component="span" className="text-danger small ml-2" />
                                <Field type="text" name="nome" className="form-control" value={values.nome} />
                            </FormGroup>
                            <FormGroup>
                                <BSForm.Label>RISP</BSForm.Label><ErrorMessage name="RispId" component="span" className="text-danger small ml-2" />
                                <Field as="select" name="RispId" className="form-control" value={values.RispId}>
                                    <option value={null}>Selecione uma RISP</option>
                                    {this.state.risps.map((risp, key) =>
                                        <option value={risp.value} key={key}>{risp.label}</option>
                                    )}
                                </Field>
                            </FormGroup>
                            <FormGroup>
                                    <span className="text-info">
                                        Dê dois cliques sobre o mapa para adicionar um ponto ou um clique duplo sobre o ponto para removê-lo.
                                    </span>
                                    <AlertaMap 
                                        pontos={values.Pontos} 
                                        delPonto={ponto => setFieldValue('Pontos', values.Pontos.filter(p => p !== ponto))} 
                                        addPonto={(latitude, longitude) => setFieldValue('Pontos', [...values.Pontos, {latitude, longitude}])}
                                        savePonto={(ponto, latitude, longitude) => setFieldValue('Pontos', values.Pontos.map(p => p === ponto ? {...ponto, latitude, longitude} : p))}
                                    />
                                    <ErrorMessage name="Pontos" component="span" className="text-danger small ml-2" />
                            </FormGroup>
                            <FormGroup className="text-right">
                                <Button type="button" variant='secondary' onClick={() => this.context.closeModal()}>
                                    <FontAwesomeIcon icon={faTimes} />&nbsp;
                                    Fechar
                                </Button>
                                <Button type="submit" disabled={isSubmitting} variant='primary' className="ml-2">
                                    <FontAwesomeIcon icon={faSave} />&nbsp;
                                    Gravar Alterações
                                </Button>
                            </FormGroup>
                        </Form>
                    );
                }}
            </Formik>
        );
    }
}

export default AreaForm;