import React from "react";
import "./css/index.css";
import { AppContext } from "../../../context";
import {
  Button,
  Col,
  Form as BSForm,
  Row,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import Request from "../../../request";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMinusSquare,
  faPlusSquare,
  faSave,
  faTrash,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import Diacritics from "diacritic";

import Select from "react-select";
import Map from "../../Map";

import CreatableSelect from "react-select/creatable";

class CartaoProgramaForm extends React.Component {
  static contextType = AppContext;
  state = {
    areas: [],
    cidades: [],
    bairros: [],
    orgaos: [],
    setores: [],
    isTbodyMinimized: true,
  };

  componentDidMount() {
    const errorHandler = (err) =>
      this.context.addToast({
        titulo: "Erro",
        conteudo: "Houve uma falha na pesquisa.",
      });

    Request(
      "GET",
      this.context.config.BACKEND_URL + "/proMulher/area/options",
      this.context.token
    )
      .send()
      .then((res) => {
        this.setState(() => ({ areas: res.body }));
      })
      .catch(errorHandler);

    Request(
      "GET",
      this.context.config.BACKEND_URL + "/cidade/options",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ cidades: options.body }));
      })
      .catch(errorHandler);

    Request(
      "GET",
      this.context.config.BACKEND_URL + "/bairro/options",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ bairros: options.body }));
      })
      .catch(errorHandler);

    Request(
      "GET",
      this.context.config.BACKEND_URL + "/orgao/options",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ orgaos: options.body }));
      })
      .catch(errorHandler);

    Request(
      "GET",
      this.context.config.BACKEND_URL + "/setor/options",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ setores: options.body }));
      })
      .catch(errorHandler);
  }

  save(values, callback) {
    const method = this.props.cartaoPrograma.id ? "PUT" : "POST";
    const url =
      this.context.config.BACKEND_URL +
      "/cartaoPrograma" +
      (this.props.cartaoPrograma.id ? "/" + this.props.cartaoPrograma.id : "");
    Request(method, url, this.context.token)
      .send(values)
      .then(() => {
        this.context.addToast({
          titulo: "Successo",
          conteudo: "Registro atualizado com sucesso.",
        });
        callback();
        this.context.setContent("CartaoProgramaList");
      })
      .catch((err) => {
        this.context.addToast({
          titulo: "Erro",
          conteudo: "Houve uma falha na gravação do registro.",
        });
        callback();
      });
  }

  async handleSubmit(values, { setSubmitting }) {
    this.save(values, () => {
      setSubmitting(false);
    });
  }

  render() {
    const { cartaoPrograma } = this.props;

    return (
      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={{
          numero_cartao: cartaoPrograma.numero_cartao || "",
          data_cartao: cartaoPrograma.data_cartao || "",
          areaId: cartaoPrograma.AreaId || "",
          Vitimas: cartaoPrograma.Vitimas
            ? cartaoPrograma.Vitimas.map((vitima) => ({ ...vitima }))
            : [],
          Visitas: cartaoPrograma.Visitas
            ? cartaoPrograma.Visitas.map((visita) => ({ ...visita }))
            : [],
        }}
        onSubmit={this.handleSubmit.bind(this)}
        validate={(values) => {
          const errors = {};
          if (!values.numero_cartao) {
            errors.numero_cartao = "Número do Cartão é obrigatório";
          }
          if (!values.data_cartao) {
            errors.data_cartao = "Data do Cartão é obrigatória";
          }
          if (!values.areaId) {
            errors.areaId = "Área é obrigatória";
          }
          return errors;
        }}
        render={({
          values,
          errors,
          handleChange,
          handleSubmit,
          isSubmitting,
          setValues,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Tabs defaultActiveKey="cartao">
              <Tab
                eventKey="cartao"
                title={
                  <div>
                    <FontAwesomeIcon icon={faUser} />{" "}
                    <span className="d-none d-lg-inline">Cartão Programa</span>
                  </div>
                }
              >
                <div className="form-group">
                  <label>Número do Cartão</label>
                  <span className="text-danger">*</span>
                  <input
                    type="text"
                    name="numero_cartao"
                    value={values.numero_cartao}
                    onChange={handleChange}
                    className="form-control"
                  />
                  {errors.numero_cartao && (
                    <div className="error">{errors.numero_cartao}</div>
                  )}
                </div>
                <div className="form-group">
                  <label>Data do Cartão</label>
                  <span className="text-danger">*</span>
                  <input
                    type="date"
                    name="data_cartao"
                    value={values.data_cartao}
                    onChange={handleChange}
                    className="form-control"
                  />
                  {errors.data_cartao && (
                    <div className="error">{errors.data_cartao}</div>
                  )}
                </div>
                <div className="form-group">
                  <label>Área</label>
                  <span className="text-danger">*</span>
                  <select
                    name="areaId"
                    value={values.areaId}
                    onChange={handleChange}
                    className="form-control"
                  >
                    <option value="">Selecione...</option>
                    {this.state.areas?.map((area) => (
                      <option key={area.id} value={area.id}>
                        {area.descricao}
                      </option>
                    ))}
                  </select>
                  {errors.areaId && (
                    <div className="error">{errors.areaId}</div>
                  )}
                </div>
              </Tab>
              <Tab
                eventKey="vitima"
                title={
                  <div>
                    <FontAwesomeIcon icon={faUser} />{" "}
                    <span className="d-none d-lg-inline">Vítima</span>
                  </div>
                }
              >
                <Table
                  striped
                  bordered
                  hover
                  size="sm"
                  responsive
                  className="mt-3"
                >
                  {values.Vitimas.map((vitima, index) => (
                    <div key={index} style={{ marginBottom: "20px" }}>
                      <table className="table">
                        <thead>
                          <tr>
                            <th
                              className="d-flex justify-content-between"
                              style={{ height: "3.8rem" }}
                            >
                              Vitima: {vitima.nome}
                              <Button
                                style={{
                                  width: "2.4rem",
                                  height: "2.4rem",
                                  marginTop: "0.4rem",
                                }}
                                onClick={() =>
                                  this.setState((prevState) => ({
                                    isTbodyMinimized: {
                                      ...prevState.isTbodyMinimized,
                                      [index]:
                                        !prevState.isTbodyMinimized[index],
                                    },
                                  }))
                                }
                              >
                                <FontAwesomeIcon
                                  icon={
                                    this.state.isTbodyMinimized[index]
                                      ? faPlusSquare
                                      : faMinusSquare
                                  }
                                />
                              </Button>
                            </th>
                            <th style={{ width: "1%", textAlign: "center" }}>
                              Excluir Vítima
                            </th>
                          </tr>
                        </thead>
                        <tbody
                          style={{
                            display: this.state.isTbodyMinimized[index]
                              ? "none"
                              : "table-row-group",
                          }}
                        >
                          <tr>
                            <td>
                              <Row>
                                <Col md={6}>
                                  <label>Nome</label>
                                  <span className="text-danger">*</span>
                                  <input
                                    type="text"
                                    name={`Vitimas[${index}].nome`}
                                    value={vitima.nome}
                                    onChange={handleChange}
                                    className="form-control"
                                  />
                                </Col>
                                <Col md={6}>
                                  <label>Agressor</label>
                                  <span className="text-danger">*</span>
                                  <input
                                    type="text"
                                    name={`Vitimas[${index}].agressor`}
                                    value={vitima.agressor}
                                    onChange={handleChange}
                                    className="form-control"
                                  />
                                </Col>
                                <Col md={6}>
                                  <label>Data do fato</label>
                                  <span className="text-danger">*</span>
                                  <input
                                    type="date"
                                    name={`Vitimas[${index}].data_fato`}
                                    value={vitima.data_fato}
                                    onChange={handleChange}
                                    className="form-control"
                                  />
                                </Col>
                                <Col md={6}>
                                  <label>Telefone</label>
                                  <span className="text-danger">*</span>
                                  <input
                                    type="text"
                                    name={`Vitimas[${index}].telefone`}
                                    value={vitima.telefone}
                                    onChange={handleChange}
                                    className="form-control"
                                  />
                                </Col>
                                <Col lg={6}>
                                  <BSForm.Label>Órgão</BSForm.Label>
                                  <span className="text-danger">*</span>
                                  <ErrorMessage
                                    name={`Vitimas[${index}].OrgaoId`}
                                    component="span"
                                  />
                                  <Select
                                    name={`Vitimas[${index}].OrgaoId`}
                                    noOptionsMessage={() => "Nada encontrado."}
                                    placeholder="Pesquisar..."
                                    menuPortalTarget={document.body}
                                    menuShouldBlockScroll={true}
                                    options={this.state.orgaos}
                                    styles={{
                                      menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 1000000000000,
                                      }),
                                    }}
                                    value={
                                      this.state.orgaos
                                        ? this.state.orgaos.find(
                                            (option) =>
                                              option.value === vitima.OrgaoId
                                          )
                                        : ""
                                    }
                                    onChange={(option) => {
                                      setFieldValue(
                                        `Vitimas[${index}].OrgaoId`,
                                        option ? option.value : null
                                      );
                                    }}
                                  />
                                </Col>
                                <Col lg={6}>
                                  <BSForm.Label>Setor</BSForm.Label>
                                  <span className="text-danger">*</span>
                                  <ErrorMessage
                                    name={`Vitimas[${index}].SetorId`}
                                    component="span"
                                  />
                                  <Select
                                    name="SetorId"
                                    noOptionsMessage={() => "Nada encontrado."}
                                    placeholder="Pesquisar..."
                                    menuPortalTarget={document.body}
                                    menuShouldBlockScroll={true}
                                    value={
                                      this.state.setores
                                        ? this.state.setores.find(
                                            (option) =>
                                              option.value === vitima.SetorId
                                          )
                                        : ""
                                    }
                                    options={this.state.setores.filter(
                                      (setor) =>
                                        setor.OrgaoId === vitima.OrgaoId
                                    )}
                                    onChange={(option) => {
                                      setFieldValue(
                                        `Vitimas[${index}].SetorId`,
                                        option.value
                                      );
                                    }}
                                  />
                                </Col>
                                <Col md={6}>
                                  <label>Parentesco</label>
                                  <span className="text-danger">*</span>
                                  <select
                                    name={`Vitimas[${index}].parentesco`}
                                    value={vitima.parentesco}
                                    onChange={handleChange}
                                    className="form-control"
                                  >
                                    <option value="Cônjuge">Cônjuge</option>
                                    <option value="Ex-cônjuge">
                                      Ex-cônjuge
                                    </option>
                                    <option value="Namorado">Namorado</option>
                                    <option value="Ex-namorado">
                                      Ex-namorado
                                    </option>
                                    <option value="Pai">Pai</option>
                                    <option value="Filho">Filho</option>
                                    <option value="Padrasto">Padrasto</option>
                                    <option value="Irmão">Irmão</option>
                                    <option value="Outro">Outro</option>
                                  </select>
                                </Col>
                                <Col md={6}>
                                  <label>Resumo do Fato</label>
                                  <span className="text-danger">*</span>
                                  <input
                                    type="text"
                                    name={`Vitimas[${index}].resumo_fato`}
                                    value={vitima.resumo_fato}
                                    onChange={handleChange}
                                    className="form-control"
                                  />
                                </Col>
                                <Col md={12}>
                                  <small>
                                    Arraste o mapa ou clique no botão de
                                    pesquisa para encontrar um endereço.
                                  </small>
                                </Col>
                              </Row>
                              <Row className="flex-grow-1">
                                <Col md="12">
                                  <Map
                                    onChange={(endereco) => {
                                      const CidadeId = (
                                        this.state.cidades.find(
                                          (cidade) =>
                                            Diacritics.clean(
                                              cidade.label
                                            ).toUpperCase() ===
                                            Diacritics.clean(
                                              endereco.cidade
                                            ).toUpperCase()
                                        ) ?? { value: null }
                                      ).value;
                                      const BairroId = (
                                        this.state.bairros.find(
                                          (bairro) =>
                                            bairro.CidadeId === CidadeId &&
                                            Diacritics.clean(
                                              bairro.label
                                            ).toUpperCase() ===
                                              Diacritics.clean(
                                                endereco.bairro
                                              ).toUpperCase()
                                        ) ?? { value: null }
                                      ).value;
                                      setFieldValue(
                                        `Vitimas[${index}].CidadeId`,
                                        CidadeId
                                      );
                                      setFieldValue(
                                        `Vitimas[${index}].BairroId`,
                                        BairroId
                                      );
                                      setFieldValue(
                                        `Vitimas[${index}].latitude`,
                                        endereco.latitude
                                      );
                                      setFieldValue(
                                        `Vitimas[${index}].longitude`,
                                        endereco.longitude
                                      );
                                      setFieldValue(
                                        `Vitimas[${index}].logradouro`,
                                        endereco.logradouro
                                      );
                                      setFieldValue(
                                        `Vitimas[${index}].numero`,
                                        endereco.numero
                                      );
                                    }}
                                    position={[
                                      values.latitude,
                                      values.longitude,
                                    ]}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col md="6">
                                  <BSForm.Label as="b">
                                    Latitude
                                    <span className="text-danger">*</span>
                                  </BSForm.Label>
                                  <ErrorMessage
                                    name={`Vitimas[${index}].latitude`}
                                    component="span"
                                    className="text-danger small ml-2"
                                  />
                                  <Field
                                    type="text"
                                    name={`Vitimas[${index}].latitude`}
                                    className="form-control"
                                    value={vitima.latitude}
                                    readOnly
                                  />
                                </Col>
                                <Col md="6">
                                  <BSForm.Label as="b">
                                    Longitude
                                    <span className="text-danger">*</span>
                                  </BSForm.Label>
                                  <ErrorMessage
                                    name={`Vitimas[${index}].longitude`}
                                    component="span"
                                    className="text-danger small ml-2"
                                  />
                                  <Field
                                    type="text"
                                    name={`Vitimas[${index}].longitude`}
                                    className="form-control"
                                    value={vitima.longitude}
                                    readOnly
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col md="6">
                                  <BSForm.Label as="b">
                                    Cidade<span className="text-danger">*</span>
                                  </BSForm.Label>
                                  <ErrorMessage
                                    name={`Vitimas[${index}].CidadeId`}
                                    component="span"
                                    className="text-danger small ml-2"
                                  />
                                  <Select
                                    name={`Vitimas[${index}].CidadeId`}
                                    noOptionsMessage={() => "Nada encontrado."}
                                    placeholder="Pesquisar..."
                                    options={this.state.cidades}
                                    value={
                                      this.state.cidades
                                        ? this.state.cidades.find(
                                            (option) =>
                                              option.value === vitima.CidadeId
                                          )
                                        : ""
                                    }
                                    onChange={(option) => {
                                      setFieldValue(
                                        `Vitimas[${index}].CidadeId`,
                                        option ? option.value : null
                                      );
                                      setFieldValue(
                                        `Vitimas[${index}].BairroId`,
                                        null
                                      );
                                    }}
                                  />
                                </Col>
                                <Col md="6">
                                  <BSForm.Label as="b">Bairro</BSForm.Label>
                                  <span className="text-danger">*</span>
                                  <ErrorMessage
                                    name={`Vitimas[${index}].BairroId`}
                                    component="span"
                                    className="text-danger small ml-2"
                                  />
                                  <CreatableSelect
                                    name={`Vitimas[${index}].BairroId`}
                                    noOptionsMessage={() => "Nada encontrado."}
                                    onCreateOption={(label) => {
                                      this.setState(
                                        (state) => ({
                                          bairros: [
                                            {
                                              value: "new",
                                              label: label,
                                              CidadeId: vitima.CidadeId,
                                            },
                                            ...state.bairros,
                                          ],
                                        }),
                                        () => {
                                          setFieldValue(
                                            `Vitimas[${index}].BairroId`,
                                            "new"
                                          );
                                          setFieldValue(
                                            `Vitimas[${index}].BairroId`,
                                            label
                                          );
                                        }
                                      );
                                    }}
                                    placeholder="Pesquisar..."
                                    formatCreateLabel={(label) =>
                                      "Adicionar " + label
                                    }
                                    options={this.state.bairros.filter(
                                      (bairro) =>
                                        bairro.CidadeId === vitima.CidadeId
                                    )}
                                    value={this.state.bairros.find(
                                      (bairro) =>
                                        bairro.value === vitima.BairroId
                                    )}
                                    onChange={(option) => {
                                      setFieldValue(
                                        `Vitimas[${index}].BairroId`,
                                        option.value
                                      );
                                      setFieldValue(
                                        `Vitimas[${index}].bairro`,
                                        option.label
                                      );
                                    }}
                                  />
                                </Col>
                                <Col md="6">
                                  <BSForm.Label as="b">
                                    Número
                                    <span className="text-danger">*</span>
                                  </BSForm.Label>
                                  <ErrorMessage
                                    name={`Vitimas[${index}].numero`}
                                    component="span"
                                    className="text-danger small ml-2"
                                  />
                                  <Field
                                    type="text"
                                    name={`Vitimas[${index}].numero`}
                                    className="form-control"
                                    value={vitima.numero}
                                  />
                                </Col>
                                <Col md="6">
                                  <BSForm.Label as="b">
                                    Logradouro
                                    <span className="text-danger">*</span>
                                  </BSForm.Label>
                                  <ErrorMessage
                                    name={`Vitimas[${index}].logradouro`}
                                    component="span"
                                    className="text-danger small ml-2"
                                  />
                                  <Field
                                    type="text"
                                    name={`Vitimas[${index}].logradouro`}
                                    className="form-control"
                                    value={vitima.logradouro}
                                  />
                                </Col>
                              </Row>
                            </td>
                            <td className="text-center align-middle">
                              <Button
                                variant="danger"
                                size="sm"
                                onClick={() => {
                                  const updatedVitimas = [...values.Vitimas];
                                  updatedVitimas.splice(index, 1);
                                  setValues({
                                    ...values,
                                    Vitimas: updatedVitimas,
                                  });
                                }}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </Button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ))}
                  <Button
                    type="button"
                    onClick={() => {
                      const updatedVitimas = [
                        ...values.Vitimas,
                        {
                          nome: "",
                          agressor: "",
                          parentesco: "Outro",
                          resumo_fato: "",
                          cidade: "",
                          bairro: "",
                          latitude: "",
                          longitude: "",
                          logradouro: "",
                          numero: "",
                        },
                      ];
                      const isTbodyMinimized = values.Vitimas.reduce(
                        (acc, _, idx) => {
                          acc[idx] = true;
                          return acc;
                        },
                        {}
                      );
                      isTbodyMinimized[values.Vitimas.length] = false;
                      this.setState((prevState) => ({
                        isTbodyMinimized: {
                          ...prevState.isTbodyMinimized,
                          ...isTbodyMinimized,
                        },
                      }));
                      setValues({
                        ...values,
                        Vitimas: updatedVitimas,
                      });
                    }}
                  >
                    Adicionar Vítima
                  </Button>
                </Table>
              </Tab>
            </Tabs>
            <Button
              type="submit"
              disabled={isSubmitting}
              variant="success"
              className="float-right"
            >
              <FontAwesomeIcon icon={faSave} /> Salvar
            </Button>
          </Form>
        )}
      />
    );
  }
}

export default CartaoProgramaForm;
