import React from "react";
import { AppContext } from "../../../context";
import Request from "../../../request";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  Button,
  Col,
  Form as BSForm,
  Row,
  Tab,
  Tabs,
  FormGroup,
  Table,
  Card,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFile,
  faPlus,
  faSave,
  faTimes,
  faTrash,
  faUser,
  faTools,
} from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import moment from "moment";

class GrupamentoFluvialForm extends React.Component {
  static contextType = AppContext;

  state = {
    risps: [],
    cidades: [],
    orgaos: [],
    setores: [],
    showModal: false,
    success: false,
  };

  save(values, callback) {
    const method = this.props.embarcacao.id ? "PUT" : "POST";
    const url =
      this.context.config.BACKEND_URL +
      "/grupamentoFluvial/embarcacao" +
      (this.props.embarcacao.id ? "/" + this.props.embarcacao.id : "");
    Request(method, url, this.context.token)
      .send(values)
      .then((res) => {
        this.context.addToast({
          titulo: "Successo",
          conteudo: "Registro atualizado com sucesso.",
        });
        callback();
        this.context.setContent("EmbarcacaoList");
      })
      .catch((err) => {
        this.context.addToast({
          titulo: "Erro",
          conteudo: "Houve uma falha na gravação do registro.",
        });
        callback();
      });
  }

  componentDidMount() {
    const errorHandler = (err) =>
      this.context.addToast({
        titulo: "Erro",
        conteudo: "Houve uma falha na pesquisa.",
      });

    Request(
      "GET",
      this.context.config.BACKEND_URL + "/cidade/options",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ cidades: options.body }));
      })
      .catch(errorHandler);

    Request(
      "GET",
      this.context.config.BACKEND_URL + "/risp/options",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ risps: options.body }));
      })
      .catch(errorHandler);

    Request(
      "GET",
      this.context.config.BACKEND_URL + "/orgao/options",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ orgaos: options.body }));
      })
      .catch(errorHandler);

    Request(
      "GET",
      this.context.config.BACKEND_URL + "/setor/options",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ setores: options.body }));
      })
      .catch(errorHandler);
  }

  render() {
    const statusEmbarcacao = [
      { value: "Ativa", label: "Ativa" },
      { value: "Inativa", label: "Inativa" },
    ];

    const resolvido = [
      { value: "Sim", label: "Sim" },
      { value: "Não", label: "Não" },
    ];

    const cidade_origem = [
      { value: "Sim", label: "Sim" },
      { value: "Não", label: "Não" },
    ];

    const exito = [
      { value: "Sim", label: "Sim" },
      { value: "Não", label: "Não" },
    ];

    const statusManutencao = [
      { value: "Em Andamento", label: "Em Andamento" },
      { value: "Finalizado", label: "Finalizado" },
    ];

    const tipoManutencao = [
      { value: "Corretiva", label: "Corretiva" },
      { value: "Preventiva", label: "Preventiva" },
    ];

    const tipoAtividade = [
      { value: "Mandado de Prisão", label: "Mandado de Prisão" },
      { value: "Busca e Apreensão", label: "Busca e Apreensão" },
      { value: "Ronda", label: "Ronda" },
      { value: "Abordagem a Embarcação", label: "Abordagem a Embarcação" },
      { value: "Intimação", label: "Intimação" },
      { value: "Diligência", label: "Diligência" },
      { value: "Disque Denúncia", label: "Disque Denúncia" },
      { value: "Operação Extraordinária", label: "Operação Extraordinária" },
      { value: "Ocorrência", label: "Ocorrência" },
      { value: "Transferência de Preso", label: "Transferência de Preso" },
      { value: "Apoio a outro órgão", label: "Apoio a outro órgão" },
      { value: "Ação Social", label: "Ação Social" },
    ];

    const naturezaAtividade = [
      { value: "Roubo", label: "Roubo" },
      { value: "Homicídio", label: "Homicídio" },
      { value: "Feminicídio", label: "Feminicídio" },
      { value: "Outro", label: "Outro" },
    ];

    const optionTipoPrisao = [
      { value: "PRISÃO EM FLAGRANTE", label: "PRISÃO EM FLAGRANTE" },
      { value: "PRISÃO PREVENTIVA", label: "PRISÃO PREVENTIVA" },
      { value: "PRISÃO TEMPORÁRIA", label: "PRISÃO TEMPORÁRIA" },
      { value: "APREENSÃO", label: "APREENSÃO" },
      { value: "RECAPTURADO", label: "RECAPTURADO" },
    ];

    const optionsCoisa = [
      { value: "ARMA DE FOGO", label: "ARMA DE FOGO" },
      { value: "MUNIÇÕES", label: "MUNIÇÕES" },
      { value: "ARMA BRANCA", label: "ARMA BRANCA" },
      { value: "ENTORPECENTES", label: "ENTORPECENTES" },
      { value: "VEÍCULOS", label: "VEÍCULOS" },
      { value: "ANIMAIS", label: "ANIMAIS" },
      { value: "APARELHOS ELETRÔNICOS", label: "APARELHOS ELETRÔNICOS" },
      { value: "DINHEIRO", label: "DINHEIRO" },
      { value: "OUTRAS APREENSÕES", label: "OUTRAS APREENSÕES" },
    ];

    const optionsCalibre = [
      { value: "22", label: "22" },
      { value: "32", label: "32" },
      { value: "38", label: "38" },
      { value: "357", label: "357" },
      { value: "765", label: "765" },
      { value: "380", label: "380" },
      { value: ".40", label: ".40" },
      { value: "6.35", label: "6.35" },
      { value: "45", label: "45" },
      { value: "9", label: "9" },
      { value: "OUTROS", label: "OUTROS" },
    ];

    const getOptionsBasedOnTipoCoisa = (tipoCoisa) => {
      switch (tipoCoisa) {
        case "ARMA DE FOGO":
          return optionsTipoArmaMunicao;
        case "MUNIÇÕES":
          return optionsTipoArmaMunicao;
        case "ENTORPECENTES":
          return optionsEntorpecentes;
        case "VEÍCULOS":
          return optionsVeiculos;
        case "APARELHOS ELETRÔNICOS":
          return optionsAparelhoEletronico;
        default:
          return [];
      }
    };

    const optionsTipoArmaMunicao = [
      { value: "REVÓLVER", label: "REVÓLVER" },
      { value: "PISTOLA", label: "PISTOLA" },
      { value: "OUTROS", label: "OUTROS" },
    ];

    const optionsEntorpecentes = [
      { value: "MACONHA", label: "MACONHA" },
      { value: "COCAINA", label: "COCAINA" },
      { value: "PASTA BASE", label: "PASTA BASE" },
      { value: "CRACK", label: "CRACK" },
      { value: "OXI", label: "OXI" },
      { value: "LIMÃOZINHO", label: "LIMÃOZINHO" },
      { value: "LSD", label: "LSD" },
      { value: "ECSTASY", label: "ECSTASY" },
      { value: "OUTROS", label: "OUTROS" },
    ];

    const optionsVeiculos = [
      { value: "CARRO", label: "CARRO" },
      { value: "MOTO", label: "MOTO" },
      { value: "OUTROS", label: "OUTROS" },
    ];

    const optionsAparelhoEletronico = [
      { value: "CELULAR", label: "CELULAR" },
      {
        value: "COMPUTADOR/NOTEBOOK/TABLET",
        label: "COMPUTADOR/NOTEBOOK/TABLET",
      },
      { value: "TELEVISÃO", label: "TELEVISÃO" },
      { value: "OUTROS", label: "OUTROS" },
    ];

    return (
      <Formik
        initialValues={{
          ...this.props.embarcacao,
          RelatorioGflus: (this.props.embarcacao.RelatorioGflus ?? []).map(
            (relatorio) => ({
              ...relatorio,
              AtividadeGflus: relatorio.AtividadeGflus
                ? [...relatorio.AtividadeGflus]
                : [],

              PrisoesGflu: relatorio.PrisoesGflu
                ? [...relatorio.PrisoesGflu]
                : [],

              ApreensoesGflu: relatorio.ApreensoesGflu
                ? [...relatorio.ApreensoesGflu]
                : [],
            })
          ),
          Manutencaos: (this.props.embarcacao.Manutencaos ?? []).map(
            (manutencao) => ({
              ...manutencao,
            })
          ),
        }}
        validate={(values) => {
          const errors = {};

          if (!values.CidadeId) {
            errors.CidadeId = "Campo obrigatório";
          }
          if (!values.RispId) {
            errors.RispId = "Campo obrigatório";
          }
          if (!values.nome) {
            errors.nome = "Campo obrigatório";
          }

          if (values.RelatorioGflus.length > 0) {
            values.RelatorioGflus.forEach((relatorio, index) => {
              if (!relatorio.OrgaoId) {
                errors[`RelatorioGflus[${index}].OrgaoId`] =
                  "Campo obrigatório";
              }
              if (relatorio?.AtividadeGflus?.length > 0) {
                relatorio.AtividadeGflus.forEach(
                  (atividade, atividadeIndex) => {
                    if (!atividade.tipo) {
                      errors[
                        `RelatorioGflus[${index}].AtividadeGflus[${atividadeIndex}].tipo`
                      ] = "Campo obrigatório";
                    }
                  }
                );
              }
              if (relatorio.PrisoesGflu?.length > 0) {
                relatorio.PrisoesGflu.forEach((prisao, prisaoIndex) => {
                  if (!prisao.tipo) {
                    errors[
                      `RelatorioGflus[${index}].PrisoesGflu[${prisaoIndex}].tipo`
                    ] = "Campo obrigatório";
                  }
                  if (!prisao.nome_preso) {
                    errors[
                      `RelatorioGflus[${index}].PrisoesGflu[${prisaoIndex}].nome_preso`
                    ] = "Campo obrigatório";
                  }
                });
              }

              if (relatorio.ApreensoesGflu?.length > 0) {
                relatorio.ApreensoesGflu.forEach(
                  (apreensao, apreensaoIndex) => {
                    if (!apreensao.coisa) {
                      errors[
                        `RelatorioGflus[${index}].ApreensoesGflu[${apreensaoIndex}].coisa`
                      ] = "Campo obrigatório";
                    }
                  }
                );
              }
            });
          }

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          this.save(values, () => setSubmitting(false));
        }}
      >
        {({ isSubmitting, values, setFieldValue, setValues }) => {
          const isStatusInativa = values.status === "Inativa";

          return (
            <>
              <Form>
                <Tabs defaultActiveKey="embarcacao">
                  <Tab
                    eventKey="embarcacao"
                    title={
                      <div>
                        <FontAwesomeIcon icon={faUser} />{" "}
                        <span className="d-none d-lg-inline">Embarcação</span>
                      </div>
                    }
                  >
                    <Row>
                      <Col md={10}>
                        <Card className="mt-3">
                          <Card.Header>
                            <strong>Dados Básicos</strong>
                          </Card.Header>
                          <Card.Body>
                            <Row>
                              <Col md={4} className="mt-2">
                                <BSForm.Label>Status</BSForm.Label>
                                <ErrorMessage
                                  name="status"
                                  component="span"
                                  className="text-danger small ml-2"
                                />
                                <Select
                                  name="status"
                                  styles={{
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                  }}
                                  menuPortalTarget={document.body}
                                  placeholder="Selecione..."
                                  options={statusEmbarcacao}
                                  value={statusEmbarcacao.find(
                                    (status) => status.value === values.status
                                  )}
                                  onChange={(option) => {
                                    setFieldValue("status", option.value);
                                  }}
                                />
                              </Col>

                              {isStatusInativa && (
                                <Col md={4} className="mt-2">
                                  <BSForm.Label>
                                    Data de Inatividade
                                  </BSForm.Label>
                                  <ErrorMessage
                                    name="data_inatividade"
                                    component="span"
                                    className="text-danger small ml-2"
                                  />
                                  <Field
                                    type="date"
                                    name="data_inatividade"
                                    className="form-control"
                                    value={values.data_inatividade}
                                  />
                                </Col>
                              )}

                              <Col md={4} className="mt-2">
                                <BSForm.Label>
                                  Regiões de Integração
                                </BSForm.Label>
                                <span className="text-danger">*</span>
                                <ErrorMessage
                                  name="Risps"
                                  component="span"
                                  className="text-danger small ml-2"
                                />
                                <Select
                                  name="Risps"
                                  noOptionsMessage={(text) =>
                                    text.inputValue + " não encontrado!"
                                  }
                                  styles={{
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                  }}
                                  menuPortalTarget={document.body}
                                  placeholder="Selecione..."
                                  options={this.state.risps}
                                  value={this.state.risps.filter(
                                    (option) => values.RispId === option.value
                                  )}
                                  onChange={(options) => {
                                    setFieldValue(
                                      "RispId",
                                      options ? options.value : null
                                    );
                                  }}
                                />
                              </Col>

                              <Col md={4} className="mt-2">
                                <BSForm.Label>Cidade</BSForm.Label>
                                <span className="text-danger">*</span>
                                <ErrorMessage
                                  name="CidadeId"
                                  component="span"
                                  className="text-danger small ml-2"
                                />
                                <Select
                                  name="CidadeId"
                                  noOptionsMessage={() => "Nada encontrado."}
                                  placeholder="Pesquisar..."
                                  options={this.state.cidades}
                                  value={
                                    this.state.cidades
                                      ? this.state.cidades.find(
                                          (option) =>
                                            option.value === values.CidadeId
                                        )
                                      : ""
                                  }
                                  onChange={(option) => {
                                    setFieldValue(
                                      "CidadeId",
                                      option ? option.value : null
                                    );
                                  }}
                                />
                              </Col>

                              <Col md={4} className="mt-2">
                                <BSForm.Label>Orgão</BSForm.Label>
                                <ErrorMessage
                                  name="OrgaoId"
                                  component="span"
                                  className="text-danger small ml-2"
                                />
                                <Select
                                  name="OrgaoId"
                                  noOptionsMessage={() => "Nada encontrado."}
                                  placeholder="Pesquisar..."
                                  options={this.state.orgaos}
                                  value={
                                    this.state.orgaos
                                      ? this.state.orgaos.find(
                                          (option) =>
                                            option.value === values.OrgaoId
                                        )
                                      : ""
                                  }
                                  onChange={(option) => {
                                    setFieldValue(
                                      "OrgaoId",
                                      option ? option.value : null
                                    );
                                  }}
                                />
                              </Col>

                              <Col md={4} className="mt-2">
                                <BSForm.Label>Setor</BSForm.Label>
                                <ErrorMessage
                                  name="SetorId"
                                  component="span"
                                  className="text-danger small ml-2"
                                />
                                <Select
                                  name="SetorId"
                                  noOptionsMessage={() => "Nada encontrado."}
                                  placeholder="Pesquisar..."
                                  options={this.state.setores.filter(
                                    (setor) => setor.OrgaoId === values.OrgaoId
                                  )}
                                  value={
                                    this.state.setores
                                      ? this.state.setores.find(
                                          (option) =>
                                            option.value === values.SetorId
                                        )
                                      : ""
                                  }
                                  onChange={(option) => {
                                    setFieldValue(
                                      "SetorId",
                                      option ? option.value : null
                                    );
                                  }}
                                />
                              </Col>

                              <Col md={4} className="mt-2">
                                <BSForm.Label>Nome</BSForm.Label>
                                <span className="text-danger">*</span>
                                <ErrorMessage
                                  name="nome"
                                  component="span"
                                  className="text-danger small ml-2"
                                />
                                <Field
                                  type="text"
                                  name="nome"
                                  className="form-control"
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col md={4} className="mt-2">
                                <BSForm.Label>
                                  Registro Patrimonial
                                </BSForm.Label>
                                <ErrorMessage
                                  name="registro_patrimonial"
                                  component="span"
                                  className="text-danger small ml-2"
                                />
                                <Field
                                  type="text"
                                  name="registro_patrimonial"
                                  className="form-control"
                                />
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </Tab>
                  <Tab
                    eventKey="manutencaos"
                    title={
                      <div>
                        <FontAwesomeIcon icon={faTools} />{" "}
                        <span className="d-none d-lg-inline">Manutenções</span>
                      </div>
                    }
                  >
                    {values.Manutencaos.length > 0 ? (
                      values.Manutencaos.map((manutencao, index) => (
                        <Table
                          key={index}
                          striped
                          size="sm"
                          responsive
                          className="mt-3"
                        >
                          <thead className="bg-light mt-5">
                            <tr>
                              <th>Problema Relatado</th>
                              <th>Tipo</th>
                              <th>Serviço Realizado</th>
                              <th>Resolvido?</th>
                              <th>Observação</th>
                              <th>Status</th>
                              <th style={{ minWidth: "1%" }}>Data</th>
                              <th style={{ width: 120, textAlign: "center" }}>
                                Ações
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <ErrorMessage
                                  name={`Manutencaos[${index}].problema_relatado`}
                                  component="span"
                                  className="text-danger small ml-2"
                                />
                                <Field
                                  type="text"
                                  name={`Manutencaos[${index}].problema_relatado`}
                                  className="form-control"
                                />
                              </td>
                              <td>
                                <ErrorMessage
                                  name={`Manutencaos[${index}].tipo`}
                                  component="span"
                                  className="text-danger small ml-2"
                                />
                                <Select
                                  name={`Manutencaos[${index}].tipo`}
                                  styles={{
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                  }}
                                  menuPortalTarget={document.body}
                                  placeholder="Selecione..."
                                  options={tipoManutencao}
                                  value={tipoManutencao.find(
                                    (tipo) => tipo.value === manutencao.tipo
                                  )}
                                  onChange={(option) =>
                                    setFieldValue(
                                      `Manutencaos[${index}].tipo`,
                                      option.value
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <ErrorMessage
                                  name={`Manutencaos[${index}].servico_realizado`}
                                  component="span"
                                  className="text-danger small ml-2"
                                />
                                <Field
                                  type="text"
                                  name={`Manutencaos[${index}].servico_realizado`}
                                  className="form-control"
                                />
                              </td>
                              <td>
                                <ErrorMessage
                                  name={`Manutencaos[${index}].resolvido`}
                                  component="span"
                                  className="text-danger small ml-2"
                                />
                                <Select
                                  name={`Manutencaos[${index}].resolvido`}
                                  styles={{
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                  }}
                                  menuPortalTarget={document.body}
                                  placeholder="Selecione..."
                                  options={resolvido}
                                  value={resolvido.find(
                                    (res) => res.value === manutencao.resolvido
                                  )}
                                  onChange={(option) =>
                                    setFieldValue(
                                      `Manutencaos[${index}].resolvido`,
                                      option.value
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <ErrorMessage
                                  name={`Manutencaos[${index}].observacao`}
                                  component="span"
                                  className="text-danger small ml-2"
                                />
                                <Field
                                  type="text"
                                  name={`Manutencaos[${index}].observacao`}
                                  className="form-control"
                                />
                              </td>
                              <td>
                                <ErrorMessage
                                  name={`Manutencaos[${index}].status`}
                                  component="span"
                                  className="text-danger small ml-2"
                                />
                                <Select
                                  name={`Manutencaos[${index}].status`}
                                  styles={{
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                  }}
                                  menuPortalTarget={document.body}
                                  placeholder="Selecione..."
                                  options={statusManutencao}
                                  value={statusManutencao.find(
                                    (status) =>
                                      status.value === manutencao.status
                                  )}
                                  onChange={(option) =>
                                    setFieldValue(
                                      `Manutencaos[${index}].status`,
                                      option.value
                                    )
                                  }
                                />
                              </td>
                              <td style={{ whiteSpace: "nowrap" }}>
                                <ErrorMessage
                                  name={`Manutencaos[${index}].data`}
                                  component="span"
                                  className="text-danger small ml-2"
                                />
                                <Field
                                  type="date"
                                  name={`Manutencaos[${index}].data`}
                                  className="form-control"
                                />
                              </td>
                              <td>
                                <Button
                                  type="button"
                                  variant="danger"
                                  size="sm"
                                  onClick={() => {
                                    if (
                                      window.confirm(
                                        "Tem certeza de que deseja excluir esta manutenção?"
                                      )
                                    ) {
                                      const updatedManutencoes =
                                        values.Manutencaos.filter(
                                          (item, i) => i !== index
                                        );
                                      setFieldValue(
                                        "Manutencaos",
                                        updatedManutencoes
                                      );
                                    }
                                  }}
                                >
                                  <FontAwesomeIcon icon={faTrash} />
                                </Button>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      ))
                    ) : (
                      <h5 className="text-center mt-3">
                        Nenhuma manutenção criada
                      </h5>
                    )}

                    <Row className="mt-3">
                      <Col md={12}>
                        <Button
                          type="button"
                          variant="primary"
                          onClick={() => {
                            setFieldValue("Manutencaos", [
                              ...values.Manutencaos,
                              {
                                problema_relatado: "",
                                tipo: "",
                                servico_realizado: "",
                                resolvido: "",
                                observacao: "",
                                status: "",
                                data: moment().format("YYYY-MM-DD"),
                              },
                            ]);
                          }}
                        >
                          <FontAwesomeIcon icon={faPlus} /> Adicionar Manutenção
                        </Button>
                      </Col>
                    </Row>
                  </Tab>
                  <Tab
                    eventKey="relatorios"
                    title={
                      <div>
                        <FontAwesomeIcon icon={faFile} />{" "}
                        <span className="d-none d-lg-inline">Relatórios</span>
                      </div>
                    }
                  >
                    {values.RelatorioGflus?.length > 0 ? (
                      values.RelatorioGflus?.map((relatorio, key) => (
                        <React.Fragment key={key}>
                          <hr />
                          <Tabs
                            className="mt-2"
                            defaultActiveKey={`dados-basicos-relatorio-${key}`}
                          >
                            <Tab
                              eventKey={`dados-basicos-relatorio-${key}`}
                              title="Dados Básicos do Relatório"
                            >
                              <Table striped bordered hover className="mt-1">
                                <thead>
                                  <tr>
                                    <td>{`Relatório Número ${key + 1}`}</td>
                                    <td>
                                      {relatorio?.createdAt ? (
                                        <strong>
                                          Criado em:{" "}
                                          {moment(relatorio?.createdAt).format(
                                            "DD/MM/YYYY"
                                          )}
                                        </strong>
                                      ) : null}
                                    </td>
                                    <td>
                                      <Button
                                        type="button"
                                        variant="danger"
                                        onClick={() => {
                                          if (
                                            window.confirm(
                                              "Deseja realmente excluir esse Relatório? "
                                            )
                                          ) {
                                            const updatedRelatorios =
                                              values.RelatorioGflus.filter(
                                                (_, index) => index !== key
                                              );
                                            setFieldValue(
                                              "RelatorioGflus",
                                              updatedRelatorios
                                            );
                                          }
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          icon={faTrash}
                                        ></FontAwesomeIcon>
                                      </Button>
                                    </td>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td colSpan={2}>
                                      <Row className="mt-2">
                                        <Col>
                                          <BSForm.Label as="b">
                                            Orgão
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </BSForm.Label>
                                          <ErrorMessage
                                            name="OrgaoId"
                                            component="span"
                                            className="text-danger small ml-2"
                                          />
                                          <Select
                                            name={`RelatorioGflus[${key}].OrgaoId`}
                                            menuShouldBlockScroll={true}
                                            styles={{
                                              menuPortal: (base) => ({
                                                ...base,
                                                zIndex: 9999,
                                              }),
                                            }}
                                            options={this.state.orgaos}
                                            value={this.state.orgaos.find(
                                              (option) =>
                                                option.value ===
                                                relatorio.OrgaoId
                                            )}
                                            onChange={(selectedOption) => {
                                              const selectedValue =
                                                selectedOption
                                                  ? selectedOption.value
                                                  : null;
                                              setFieldValue(
                                                `RelatorioGflus[${key}].OrgaoId`,
                                                selectedValue
                                              );
                                            }}
                                          />
                                        </Col>
                                        <Col>
                                          <BSForm.Label as="b">
                                            Setor
                                          </BSForm.Label>
                                          <ErrorMessage
                                            name="SetorId"
                                            component="span"
                                            className="text-danger small ml-2"
                                          />
                                          <Select
                                            name={`RelatorioGflus[${key}].SetorId`}
                                            menuShouldBlockScroll={true}
                                            styles={{
                                              menuPortal: (base) => ({
                                                ...base,
                                                zIndex: 9999,
                                              }),
                                            }}
                                            options={this.state.setores.filter(
                                              (setor) =>
                                                setor.OrgaoId ===
                                                relatorio.OrgaoId
                                            )}
                                            value={this.state.setores.find(
                                              (option) =>
                                                option.value ===
                                                relatorio.SetorId
                                            )}
                                            onChange={(selectedOption) => {
                                              const selectedValue =
                                                selectedOption
                                                  ? selectedOption.value
                                                  : null;
                                              setFieldValue(
                                                `RelatorioGflus[${key}].SetorId`,
                                                selectedValue
                                              );
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                      <Row className="mt-2">
                                        <Col>
                                          <BSForm.Label as="b">
                                            Data
                                          </BSForm.Label>
                                          <ErrorMessage
                                            name={`RelatorioGflus[${key}].data`}
                                            component="span"
                                            className="text-danger small ml-2"
                                          />
                                          <Field
                                            type="date"
                                            name={`RelatorioGflus[${key}].data`}
                                            className="form-control"
                                          />
                                        </Col>
                                        <Col>
                                          <BSForm.Label as="b">
                                            Horímetro
                                          </BSForm.Label>
                                          <ErrorMessage
                                            name={`RelatorioGflus[${key}].horimetro`}
                                            component="span"
                                            className="text-danger small ml-2"
                                          />
                                          <Field
                                            type="text"
                                            name={`RelatorioGflus[${key}].horimetro`}
                                            className="form-control"
                                          />
                                        </Col>
                                      </Row>
                                      <Row className="mt-2">
                                        <Col lg={3}>
                                          <BSForm.Label as="b">
                                            Data de Coleta do Horímetro
                                          </BSForm.Label>
                                          <ErrorMessage
                                            name={`RelatorioGflus[${key}].data_coleta_horimetro`}
                                            component="span"
                                            className="text-danger small ml-2"
                                          />
                                          <Field
                                            type="date"
                                            name={`RelatorioGflus[${key}].data_coleta_horimetro`}
                                            className="form-control"
                                          />
                                        </Col>
                                        <Col>
                                          <BSForm.Label as="b">
                                            Cidade de Origem?
                                          </BSForm.Label>
                                          <ErrorMessage
                                            name={`RelatorioGflus[${key}].cidade_origem`}
                                            component="span"
                                            className="text-danger small ml-2"
                                          />
                                          <Select
                                            name={`RelatorioGflus[${key}].cidade_origem`}
                                            menuShouldBlockScroll={true}
                                            styles={{
                                              menuPortal: (base) => ({
                                                ...base,
                                                zIndex: 9999,
                                              }),
                                            }}
                                            options={cidade_origem}
                                            value={cidade_origem.find(
                                              (cidade) =>
                                                cidade.value ===
                                                relatorio.cidade_origem
                                            )}
                                            onChange={(selectedOption) => {
                                              const selectedValue =
                                                selectedOption
                                                  ? selectedOption.value
                                                  : null;
                                              setFieldValue(
                                                `RelatorioGflus[${key}].cidade_origem`,
                                                selectedValue
                                              );
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                      {relatorio.cidade_origem === "Não" && (
                                        <Row className="mt-2">
                                          <Col lg={3}>
                                            <BSForm.Label as="b">
                                              Cidade
                                            </BSForm.Label>
                                            <ErrorMessage
                                              name={`RelatorioGflus[${key}].CidadeId`}
                                              component="span"
                                              className="text-danger small ml-2"
                                            />
                                            <Select
                                              name={`RelatorioGflus[${key}].CidadeId`}
                                              menuShouldBlockScroll={true}
                                              styles={{
                                                menuPortal: (base) => ({
                                                  ...base,
                                                  zIndex: 9999,
                                                }),
                                              }}
                                              options={this.state.cidades}
                                              value={this.state.cidades.find(
                                                (option) =>
                                                  option.value ===
                                                  relatorio.CidadeId
                                              )}
                                              onChange={(selectedOption) => {
                                                const selectedValue =
                                                  selectedOption
                                                    ? selectedOption.value
                                                    : null;
                                                setFieldValue(
                                                  `RelatorioGflus[${key}].CidadeId`,
                                                  selectedValue
                                                );
                                              }}
                                            />
                                          </Col>
                                          <Col lg={3}>
                                            <BSForm.Label as="b">
                                              Motivo
                                            </BSForm.Label>
                                            <ErrorMessage
                                              name={`RelatorioGflus[${key}].motivo`}
                                              component="span"
                                              className="text-danger small ml-2"
                                            />
                                            <Field
                                              type="text"
                                              name={`RelatorioGflus[${key}].motivo`}
                                              className="form-control"
                                            />
                                          </Col>
                                        </Row>
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </Tab>
                            <Tab
                              eventKey={`atividades-${key}`}
                              title="Atividades"
                            >
                              {values.RelatorioGflus[key]?.AtividadeGflus
                                ?.length > 0 ? (
                                values.RelatorioGflus[key]?.AtividadeGflus?.map(
                                  (atividades, atividadesIndex) => (
                                    <Table
                                      striped
                                      bordered
                                      hover
                                      key={atividadesIndex}
                                    >
                                      <thead>
                                        <tr>
                                          <th>{`Atividade Número ${
                                            atividadesIndex + 1
                                          }`}</th>
                                          <th
                                            style={{
                                              width: "1%",
                                              textAlign: "center",
                                            }}
                                          >
                                            Excluir
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>
                                            <Row className="mt-2">
                                              <Col lg={6}>
                                                <BSForm.Label as="b">
                                                  Tipo de Atividade
                                                </BSForm.Label>
                                                <ErrorMessage
                                                  name={`RelatorioGflus[${key}].AtividadeGflus[${atividadesIndex}].tipo`}
                                                  component="span"
                                                  className="text-danger small ml-2"
                                                />
                                                <Select
                                                  name={`RelatorioGflus[${key}].AtividadeGflus[${atividadesIndex}].tipo`}
                                                  menuShouldBlockScroll={true}
                                                  styles={{
                                                    menuPortal: (base) => ({
                                                      ...base,
                                                      zIndex: 9999,
                                                    }),
                                                  }}
                                                  options={tipoAtividade}
                                                  value={tipoAtividade.find(
                                                    (tipo) =>
                                                      tipo.value ===
                                                      atividades.tipo
                                                  )}
                                                  onChange={(
                                                    selectedOption
                                                  ) => {
                                                    const selectedValue =
                                                      selectedOption
                                                        ? selectedOption.value
                                                        : null;
                                                    setFieldValue(
                                                      `RelatorioGflus[${key}].AtividadeGflus[${atividadesIndex}].tipo`,
                                                      selectedValue
                                                    );
                                                  }}
                                                />
                                              </Col>
                                              {atividades.tipo ===
                                                "Mandado de Prisão" ||
                                              atividades.tipo ===
                                                "Busca e Apreensão" ? (
                                                <>
                                                  <Col lg={6}>
                                                    <BSForm.Label as="b">
                                                      Êxito
                                                    </BSForm.Label>
                                                    <ErrorMessage
                                                      name={`RelatorioGflus[${key}].AtividadeGflus[${atividadesIndex}].exito`}
                                                      component="span"
                                                      className="text-danger small ml-2"
                                                    />
                                                    <Select
                                                      name={`RelatorioGflus[${key}].AtividadeGflus[${atividadesIndex}].exito`}
                                                      menuShouldBlockScroll={
                                                        true
                                                      }
                                                      styles={{
                                                        menuPortal: (base) => ({
                                                          ...base,
                                                          zIndex: 9999,
                                                        }),
                                                      }}
                                                      options={exito}
                                                      value={exito.find(
                                                        (exito) =>
                                                          exito.value ===
                                                          atividades.exito
                                                      )}
                                                      onChange={(
                                                        selectedOption
                                                      ) => {
                                                        const selectedValue =
                                                          selectedOption
                                                            ? selectedOption.value
                                                            : null;
                                                        setFieldValue(
                                                          `RelatorioGflus[${key}].AtividadeGflus[${atividadesIndex}].exito`,
                                                          selectedValue
                                                        );
                                                      }}
                                                    />
                                                  </Col>

                                                  <Col lg={6}>
                                                    <BSForm.Label as="b">
                                                      Quantidade
                                                    </BSForm.Label>
                                                    <ErrorMessage
                                                      name={`RelatorioGflus[${key}].AtividadeGflus[${atividadesIndex}].quantidade`}
                                                      component="span"
                                                      className="text-danger small ml-2"
                                                    />
                                                    <Field
                                                      type="number"
                                                      name={`RelatorioGflus[${key}].AtividadeGflus[${atividadesIndex}].quantidade`}
                                                      className="form-control"
                                                      onChange={(e) =>
                                                        setFieldValue(
                                                          `RelatorioGflus[${key}].AtividadeGflus[${atividadesIndex}].quantidade`,
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </Col>
                                                  {atividades.exito ===
                                                    "Sim" && (
                                                    <>
                                                      <Col lg={6}>
                                                        <BSForm.Label as="b">
                                                          Local
                                                        </BSForm.Label>
                                                        <ErrorMessage
                                                          name={`RelatorioGflus[${key}].AtividadeGflus[${atividadesIndex}].local`}
                                                          component="span"
                                                          className="text-danger small ml-2"
                                                        />
                                                        <Field
                                                          type="text"
                                                          name={`RelatorioGflus[${key}].AtividadeGflus[${atividadesIndex}].local`}
                                                          className="form-control"
                                                          onChange={(e) =>
                                                            setFieldValue(
                                                              `RelatorioGflus[${key}].AtividadeGflus[${atividadesIndex}].local`,
                                                              e.target.value
                                                            )
                                                          }
                                                        />
                                                      </Col>
                                                      <Col lg={6}>
                                                        <BSForm.Label as="b">
                                                          Descrição
                                                        </BSForm.Label>
                                                        <ErrorMessage
                                                          name={`RelatorioGflus[${key}].AtividadeGflus[${atividadesIndex}].descricao`}
                                                          component="span"
                                                          className="text-danger small ml-2"
                                                        />
                                                        <Field
                                                          type="text"
                                                          name={`RelatorioGflus[${key}].AtividadeGflus[${atividadesIndex}].descricao`}
                                                          className="form-control"
                                                          onChange={(e) =>
                                                            setFieldValue(
                                                              `RelatorioGflus[${key}].AtividadeGflus[${atividadesIndex}].descricao`,
                                                              e.target.value
                                                            )
                                                          }
                                                        />
                                                      </Col>
                                                    </>
                                                  )}
                                                </>
                                              ) : atividades.tipo === "Ronda" ||
                                                atividades.tipo ===
                                                  "Abordagem a Embarcação" ||
                                                atividades.tipo ===
                                                  "Disque Denúncia" ||
                                                atividades.tipo ===
                                                  "Intimação" ||
                                                atividades.tipo ===
                                                  "Diligência" ||
                                                atividades.tipo ===
                                                  "Apoio a outro órgão" ||
                                                atividades.tipo ===
                                                  "Ação Social" ||
                                                atividades.tipo ===
                                                  "Operação Extraordinária" ? (
                                                <>
                                                  <Col lg={6}>
                                                    <BSForm.Label as="b">
                                                      Quantidade
                                                    </BSForm.Label>
                                                    <ErrorMessage
                                                      name={`RelatorioGflus[${key}].AtividadeGflus[${atividadesIndex}].quantidade`}
                                                      component="span"
                                                      className="text-danger small ml-2"
                                                    />
                                                    <Field
                                                      type="number"
                                                      name={`RelatorioGflus[${key}].AtividadeGflus[${atividadesIndex}].quantidade`}
                                                      className="form-control"
                                                      onChange={(e) =>
                                                        setFieldValue(
                                                          `RelatorioGflus[${key}].AtividadeGflus[${atividadesIndex}].quantidade`,
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </Col>
                                                  <Col lg={6}>
                                                    <BSForm.Label as="b">
                                                      Local
                                                    </BSForm.Label>
                                                    <ErrorMessage
                                                      name={`RelatorioGflus[${key}].AtividadeGflus[${atividadesIndex}].local`}
                                                      component="span"
                                                      className="text-danger small ml-2"
                                                    />
                                                    <Field
                                                      type="text"
                                                      name={`RelatorioGflus[${key}].AtividadeGflus[${atividadesIndex}].local`}
                                                      className="form-control"
                                                      onChange={(e) =>
                                                        setFieldValue(
                                                          `RelatorioGflus[${key}].AtividadeGflus[${atividadesIndex}].local`,
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </Col>
                                                  <Col lg={6}>
                                                    <BSForm.Label as="b">
                                                      Descrição
                                                    </BSForm.Label>
                                                    <ErrorMessage
                                                      name={`RelatorioGflus[${key}].AtividadeGflus[${atividadesIndex}].descricao`}
                                                      component="span"
                                                      className="text-danger small ml-2"
                                                    />
                                                    <Field
                                                      type="text"
                                                      name={`RelatorioGflus[${key}].AtividadeGflus[${atividadesIndex}].descricao`}
                                                      className="form-control"
                                                      onChange={(e) =>
                                                        setFieldValue(
                                                          `RelatorioGflus[${key}].AtividadeGflus[${atividadesIndex}].descricao`,
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </Col>
                                                </>
                                              ) : atividades.tipo ===
                                                "Ocorrência" ? (
                                                <>
                                                  <Col lg={6}>
                                                    <BSForm.Label as="b">
                                                      Quantidade
                                                    </BSForm.Label>
                                                    <ErrorMessage
                                                      name={`RelatorioGflus[${key}].AtividadeGflus[${atividadesIndex}].quantidade`}
                                                      component="span"
                                                      className="text-danger small ml-2"
                                                    />
                                                    <Field
                                                      type="number"
                                                      name={`RelatorioGflus[${key}].AtividadeGflus[${atividadesIndex}].quantidade`}
                                                      className="form-control"
                                                      onChange={(e) =>
                                                        setFieldValue(
                                                          `RelatorioGflus[${key}].AtividadeGflus[${atividadesIndex}].quantidade`,
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </Col>
                                                  <Col lg={6}>
                                                    <BSForm.Label as="b">
                                                      Local
                                                    </BSForm.Label>
                                                    <ErrorMessage
                                                      name={`RelatorioGflus[${key}].AtividadeGflus[${atividadesIndex}].local`}
                                                      component="span"
                                                      className="text-danger small ml-2"
                                                    />
                                                    <Field
                                                      type="text"
                                                      name={`RelatorioGflus[${key}].AtividadeGflus[${atividadesIndex}].local`}
                                                      className="form-control"
                                                      onChange={(e) =>
                                                        setFieldValue(
                                                          `RelatorioGflus[${key}].AtividadeGflus[${atividadesIndex}].local`,
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </Col>
                                                  <Col lg={6}>
                                                    <BSForm.Label as="b">
                                                      Descrição
                                                    </BSForm.Label>
                                                    <ErrorMessage
                                                      name={`RelatorioGflus[${key}].AtividadeGflus[${atividadesIndex}].descricao`}
                                                      component="span"
                                                      className="text-danger small ml-2"
                                                    />
                                                    <Field
                                                      type="text"
                                                      name={`RelatorioGflus[${key}].AtividadeGflus[${atividadesIndex}].descricao`}
                                                      className="form-control"
                                                      onChange={(e) =>
                                                        setFieldValue(
                                                          `RelatorioGflus[${key}].AtividadeGflus[${atividadesIndex}].descricao`,
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </Col>
                                                  <Col lg={6}>
                                                    <BSForm.Label as="b">
                                                      Natureza
                                                    </BSForm.Label>
                                                    <ErrorMessage
                                                      name={`RelatorioGflus[${key}].AtividadeGflus[${atividadesIndex}].natureza`}
                                                      component="span"
                                                      className="text-danger small ml-2"
                                                    />
                                                    <Select
                                                      name={`RelatorioGflus[${key}].AtividadeGflus[${atividadesIndex}].natureza`}
                                                      menuShouldBlockScroll={
                                                        true
                                                      }
                                                      styles={{
                                                        menuPortal: (base) => ({
                                                          ...base,
                                                          zIndex: 9999,
                                                        }),
                                                      }}
                                                      options={
                                                        naturezaAtividade
                                                      }
                                                      value={naturezaAtividade.find(
                                                        (natureza) =>
                                                          natureza.value ===
                                                          atividades.natureza
                                                      )}
                                                      onChange={(
                                                        selectedOption
                                                      ) => {
                                                        const selectedValue =
                                                          selectedOption
                                                            ? selectedOption.value
                                                            : null;
                                                        setFieldValue(
                                                          `RelatorioGflus[${key}].AtividadeGflus[${atividadesIndex}].natureza`,
                                                          selectedValue
                                                        );
                                                      }}
                                                    />
                                                  </Col>
                                                </>
                                              ) : atividades.tipo ===
                                                "Transferência de Preso" ? (
                                                <>
                                                  <Col lg={6}>
                                                    <BSForm.Label as="b">
                                                      Quantidade
                                                    </BSForm.Label>
                                                    <ErrorMessage
                                                      name={`RelatorioGflus[${key}].AtividadeGflus[${atividadesIndex}].quantidade`}
                                                      component="span"
                                                      className="text-danger small ml-2"
                                                    />
                                                    <Field
                                                      type="number"
                                                      name={`RelatorioGflus[${key}].AtividadeGflus[${atividadesIndex}].quantidade`}
                                                      className="form-control"
                                                      onChange={(e) =>
                                                        setFieldValue(
                                                          `RelatorioGflus[${key}].AtividadeGflus[${atividadesIndex}].quantidade`,
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </Col>
                                                  <Col lg={6}>
                                                    <BSForm.Label as="b">
                                                      Descrição
                                                    </BSForm.Label>
                                                    <ErrorMessage
                                                      name={`RelatorioGflus[${key}].AtividadeGflus[${atividadesIndex}].descricao`}
                                                      component="span"
                                                      className="text-danger small ml-2"
                                                    />
                                                    <Field
                                                      type="text"
                                                      name={`RelatorioGflus[${key}].AtividadeGflus[${atividadesIndex}].descricao`}
                                                      className="form-control"
                                                      onChange={(e) =>
                                                        setFieldValue(
                                                          `RelatorioGflus[${key}].AtividadeGflus[${atividadesIndex}].descricao`,
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </Col>
                                                  <Col lg={6}>
                                                    <BSForm.Label as="b">
                                                      Cidade de Origem
                                                    </BSForm.Label>
                                                    <ErrorMessage
                                                      name={`RelatorioGflus[${key}].AtividadeGflus[${atividadesIndex}].OrigemId`}
                                                      component="span"
                                                      className="text-danger small ml-2"
                                                    />
                                                    <Select
                                                      name={`RelatorioGflus[${key}].AtividadeGflus[${atividadesIndex}].OrigemId`}
                                                      menuShouldBlockScroll={
                                                        true
                                                      }
                                                      styles={{
                                                        menuPortal: (base) => ({
                                                          ...base,
                                                          zIndex: 9999,
                                                        }),
                                                      }}
                                                      options={
                                                        this.state.cidades
                                                      }
                                                      value={this.state.cidades.find(
                                                        (option) =>
                                                          option.value ===
                                                          atividades.OrigemId
                                                      )}
                                                      onChange={(
                                                        selectedOption
                                                      ) => {
                                                        const selectedValue =
                                                          selectedOption
                                                            ? selectedOption.value
                                                            : null;
                                                        setFieldValue(
                                                          `RelatorioGflus[${key}].AtividadeGflus[${atividadesIndex}].OrigemId`,
                                                          selectedValue
                                                        );
                                                      }}
                                                    />
                                                  </Col>
                                                  <Col lg={6}>
                                                    <BSForm.Label as="b">
                                                      Cidade de Destino
                                                    </BSForm.Label>
                                                    <ErrorMessage
                                                      name={`RelatorioGflus[${key}].AtividadeGflus[${atividadesIndex}].DestinoId`}
                                                      component="span"
                                                      className="text-danger small ml-2"
                                                    />
                                                    <Select
                                                      name={`RelatorioGflus[${key}].AtividadeGflus[${atividadesIndex}].DestinoId`}
                                                      menuShouldBlockScroll={
                                                        true
                                                      }
                                                      styles={{
                                                        menuPortal: (base) => ({
                                                          ...base,
                                                          zIndex: 9999,
                                                        }),
                                                      }}
                                                      options={
                                                        this.state.cidades
                                                      }
                                                      value={this.state.cidades.find(
                                                        (option) =>
                                                          option.value ===
                                                          atividades.DestinoId
                                                      )}
                                                      onChange={(
                                                        selectedOption
                                                      ) => {
                                                        const selectedValue =
                                                          selectedOption
                                                            ? selectedOption.value
                                                            : null;
                                                        setFieldValue(
                                                          `RelatorioGflus[${key}].AtividadeGflus[${atividadesIndex}].DestinoId`,
                                                          selectedValue
                                                        );
                                                      }}
                                                    />
                                                  </Col>
                                                </>
                                              ) : null}
                                            </Row>
                                          </td>
                                          <td className="text-center align-middle">
                                            <Button
                                              size="sm"
                                              type="button"
                                              variant="danger"
                                              title="Excluir"
                                              onClick={() => {
                                                if (
                                                  window.confirm(
                                                    "Deseja realmente excluir essa Atividade?"
                                                  )
                                                ) {
                                                  const updatedAtividades = [
                                                    ...values.RelatorioGflus[
                                                      key
                                                    ]?.AtividadeGflus,
                                                  ];
                                                  updatedAtividades.splice(
                                                    atividadesIndex,
                                                    1
                                                  );
                                                  setFieldValue(
                                                    `RelatorioGflus[${key}].AtividadeGflus`,
                                                    updatedAtividades
                                                  );
                                                }
                                              }}
                                            >
                                              <FontAwesomeIcon icon={faTrash} />
                                            </Button>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </Table>
                                  )
                                )
                              ) : (
                                <h5 className="text-center mt-3">
                                  Nenhuma atividade criada
                                </h5>
                              )}
                              <Row className="mt-2">
                                <Col mb={3}>
                                  <Button
                                    type="button"
                                    variant="secondary"
                                    className="btn btn-warning my-3"
                                    onClick={() => {
                                      if (
                                        Array.isArray(
                                          values.RelatorioGflus[key]
                                            ?.AtividadeGflus
                                        )
                                      ) {
                                        setFieldValue(
                                          `RelatorioGflus[${key}].AtividadeGflus`,
                                          [
                                            ...values.RelatorioGflus[key]
                                              .AtividadeGflus,
                                            {},
                                          ]
                                        );
                                      } else {
                                        setFieldValue(
                                          `RelatorioGflus[${key}].AtividadeGflus`,
                                          [{}]
                                        );
                                      }
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faPlus} />
                                    &nbsp; Adicionar Atividade
                                  </Button>
                                </Col>
                              </Row>
                            </Tab>
                            <Tab eventKey={`prisoes-${key}`} title="Prisões">
                              {values.RelatorioGflus[key]?.PrisoesGflu?.length >
                              0 ? (
                                values.RelatorioGflus[key]?.PrisoesGflu?.map(
                                  (prisoes, prisoesIndex) => (
                                    <Table
                                      striped
                                      bordered
                                      hover
                                      key={prisoesIndex}
                                    >
                                      <thead>
                                        <tr>
                                          <th>{`Prisão Número ${
                                            prisoesIndex + 1
                                          }`}</th>
                                          <th
                                            style={{
                                              width: "1%",
                                              textAlign: "center",
                                            }}
                                          >
                                            Excluir
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>
                                            <Row
                                              key={prisoesIndex}
                                              className="my=3"
                                            >
                                              <Col>
                                                <BSForm.Label as="b">
                                                  TIPO DE PRISÃO OU APREENSÃO
                                                  <span className="text-danger">
                                                    *
                                                  </span>
                                                </BSForm.Label>
                                                <ErrorMessage
                                                  name={`RelatorioGflus[${key}].PrisoesGflu[${prisoesIndex}].tipo`}
                                                  component="span"
                                                  className="text-danger small ml-2"
                                                />
                                                <Select
                                                  name={`RelatorioGflus[${key}]..PrisoesGflu[${prisoesIndex}].tipo`}
                                                  menuShouldBlockScroll={true}
                                                  styles={{
                                                    menuPortal: (base) => ({
                                                      ...base,
                                                      zIndex: 9999,
                                                    }),
                                                  }}
                                                  options={optionTipoPrisao}
                                                  value={optionTipoPrisao.find(
                                                    (option) =>
                                                      option.value ===
                                                      prisoes.tipo
                                                  )}
                                                  onChange={(
                                                    selectedOption
                                                  ) => {
                                                    const selectedValue =
                                                      selectedOption
                                                        ? selectedOption.value
                                                        : null;
                                                    setFieldValue(
                                                      `RelatorioGflus[${key}].PrisoesGflu[${prisoesIndex}].tipo`,
                                                      selectedValue
                                                    );
                                                  }}
                                                />
                                              </Col>
                                              <Col>
                                                <BSForm.Label as="b">
                                                  NOME COMPLETO DO PRESO
                                                  <span className="text-danger">
                                                    *
                                                  </span>
                                                </BSForm.Label>
                                                <ErrorMessage
                                                  name={`RelatorioGflus[${key}].PrisoesGflu[${prisoesIndex}].nome_preso`}
                                                  component="span"
                                                  className="text-danger small ml-2"
                                                />
                                                <Field
                                                  name={`RelatorioGflus[${key}].PrisoesGflu[${prisoesIndex}].nome_preso`}
                                                  type="text"
                                                  className="form-control"
                                                  onChange={(e) =>
                                                    setFieldValue(
                                                      `RelatorioGflus[${key}].PrisoesGflu[${prisoesIndex}].nome_preso`,
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </Col>
                                            </Row>
                                          </td>
                                          <td className="text-center align-middle">
                                            <Button
                                              size="sm"
                                              type="button"
                                              variant="danger"
                                              title="Excluir"
                                              onClick={() => {
                                                if (
                                                  window.confirm(
                                                    "Deseja realmente excluir essa Prisão ?"
                                                  )
                                                ) {
                                                  const updatedPrisoes = [
                                                    ...values.RelatorioGflus[
                                                      key
                                                    ]?.PrisoesGflu,
                                                  ];
                                                  updatedPrisoes.splice(
                                                    prisoesIndex,
                                                    1
                                                  );
                                                  setFieldValue(
                                                    `RelatorioGflus[${key}].PrisoesGflu`,
                                                    updatedPrisoes
                                                  );
                                                }
                                              }}
                                            >
                                              <FontAwesomeIcon
                                                icon={faTrash}
                                              ></FontAwesomeIcon>
                                            </Button>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </Table>
                                  )
                                )
                              ) : (
                                <h5 className="text-center mt-3">
                                  Nenhuma prisão criada
                                </h5>
                              )}
                              <Row className="mt-2">
                                <Col mb={3}>
                                  <Button
                                    type="button"
                                    variant="secondary"
                                    className="btn btn-warning my-3"
                                    onClick={() => {
                                      if (
                                        Array.isArray(
                                          values.RelatorioGflus[key]
                                            ?.PrisoesGflu
                                        )
                                      ) {
                                        setFieldValue(
                                          `RelatorioGflus[${key}].PrisoesGflu`,
                                          [
                                            ...values.RelatorioGflus[key]
                                              .PrisoesGflu,
                                            {},
                                          ]
                                        );
                                      } else {
                                        setFieldValue(
                                          `RelatorioGflus[${key}].PrisoesGflu`,
                                          [{}]
                                        );
                                      }
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faPlus} />
                                    &nbsp; Adicionar Prisão
                                  </Button>
                                </Col>
                              </Row>
                            </Tab>
                            <Tab
                              eventKey={`apreensoes-${key}`}
                              title="Apreensões"
                            >
                              {values.RelatorioGflus[key]?.ApreensoesGflu
                                ?.length > 0 ? (
                                values.RelatorioGflus[key]?.ApreensoesGflu?.map(
                                  (apreensoes, apreensoesIndex) => (
                                    <Table
                                      striped
                                      bordered
                                      hover
                                      key={apreensoesIndex}
                                    >
                                      <thead>
                                        <tr>
                                          <th>{`Apreensão Número ${
                                            apreensoesIndex + 1
                                          }`}</th>
                                          <th
                                            style={{
                                              width: "1%",
                                              textAlign: "center",
                                            }}
                                          >
                                            Excluir
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>
                                            <Row
                                              key={apreensoesIndex}
                                              className="mt-2"
                                            >
                                              <Col lg={6}>
                                                <BSForm.Label as="b">
                                                  OBJETO APREENDIDO{" "}
                                                  <span className="text-danger">
                                                    *
                                                  </span>
                                                </BSForm.Label>
                                                <ErrorMessage
                                                  name={`RelatorioGflus[${key}].ApreensoesGflu[${apreensoesIndex}].coisa`}
                                                  component="span"
                                                  className="text-danger small ml-2"
                                                />
                                                <Select
                                                  name={`RelatorioGflus[${key}].ApreensoesGflu[${apreensoesIndex}].coisa`}
                                                  menuShouldBlockScroll={true}
                                                  styles={{
                                                    menuPortal: (base) => ({
                                                      ...base,
                                                      zIndex: 9999,
                                                    }),
                                                  }}
                                                  options={optionsCoisa}
                                                  value={optionsCoisa.find(
                                                    (option) =>
                                                      option.value ===
                                                      apreensoes.coisa
                                                  )}
                                                  onChange={(
                                                    selectedOption
                                                  ) => {
                                                    const selectedValue =
                                                      selectedOption
                                                        ? selectedOption.value
                                                        : null;
                                                    setFieldValue(
                                                      `RelatorioGflus[${key}].ApreensoesGflu[${apreensoesIndex}.coisa]`,
                                                      selectedValue
                                                    );
                                                  }}
                                                />
                                              </Col>
                                              {apreensoes.coisa ===
                                                "ARMA DE FOGO" ||
                                              apreensoes.coisa === "MUNIÇÕES" ||
                                              apreensoes.coisa ===
                                                "ENTORPECENTES" ||
                                              apreensoes.coisa === "VEÍCULOS" ||
                                              apreensoes.coisa ===
                                                "APARELHOS ELETRÔNICOS" ? (
                                                <>
                                                  <Col lg={6}>
                                                    <BSForm.Label as="b">
                                                      TIPO DE OBJETO APREENDIDO{" "}
                                                      <span className="text-danger">
                                                        *
                                                      </span>
                                                    </BSForm.Label>
                                                    <ErrorMessage
                                                      name={`RelatorioGflus[${key}].ApreensoesGflu[${apreensoesIndex}].tipo`}
                                                      component="span"
                                                      className="text-danger small ml-2"
                                                    />
                                                    <Select
                                                      name={`RelatorioGflus[${key}].ApreensoesGflu[${apreensoesIndex}].tipo`}
                                                      menuShouldBlockScroll={
                                                        true
                                                      }
                                                      styles={{
                                                        menuPortal: (base) => ({
                                                          ...base,
                                                          zIndex: 9999,
                                                        }),
                                                      }}
                                                      options={getOptionsBasedOnTipoCoisa(
                                                        apreensoes.coisa
                                                      )}
                                                      value={getOptionsBasedOnTipoCoisa(
                                                        apreensoes.coisa
                                                      ).find(
                                                        (option) =>
                                                          option.value ===
                                                          apreensoes.tipo
                                                      )}
                                                      onChange={(
                                                        selectedOption
                                                      ) => {
                                                        const selectedValue =
                                                          selectedOption
                                                            ? selectedOption.value
                                                            : null;
                                                        setFieldValue(
                                                          `RelatorioGflus[${key}].ApreensoesGflu[${apreensoesIndex}].tipo`,
                                                          selectedValue
                                                        );
                                                      }}
                                                    />
                                                  </Col>
                                                </>
                                              ) : null}
                                              {(apreensoes.coisa ===
                                                "ARMA DE FOGO" ||
                                                apreensoes.coisa ===
                                                  "MUNIÇÕES") &&
                                              (apreensoes.tipo === "REVÓLVER" ||
                                                apreensoes.tipo ===
                                                  "PISTOLA") ? (
                                                <Col lg={6}>
                                                  <BSForm.Label as="b">
                                                    CALIBRE{" "}
                                                    <span className="text-danger">
                                                      *
                                                    </span>
                                                  </BSForm.Label>
                                                  <ErrorMessage
                                                    name={`RelatorioGflus[${key}].ApreensoesGflu[${apreensoesIndex}].calibre`}
                                                    component="span"
                                                    className="text-danger small ml-2"
                                                  />
                                                  <Select
                                                    name={`RelatorioGflus[${key}].ApreensoesGflu[${apreensoesIndex}.calibre]`}
                                                    menuShouldBlockScroll={true}
                                                    styles={{
                                                      menuPortal: (base) => ({
                                                        ...base,
                                                        zIndex: 9999,
                                                      }),
                                                    }}
                                                    options={optionsCalibre}
                                                    value={optionsCalibre.find(
                                                      (option) =>
                                                        option.value ===
                                                        apreensoes.calibre
                                                    )}
                                                    onChange={(
                                                      selectedOption
                                                    ) => {
                                                      const selectedValue =
                                                        selectedOption
                                                          ? selectedOption.value
                                                          : null;
                                                      setFieldValue(
                                                        `RelatorioGflus[${key}].ApreensoesGflu[${apreensoesIndex}.calibre]`,
                                                        selectedValue
                                                      );
                                                    }}
                                                  />
                                                </Col>
                                              ) : null}
                                              {apreensoes.coisa !==
                                              "DINHEIRO" ? (
                                                <Col lg={6}>
                                                  <BSForm.Label as="b">
                                                    QUANTIDADE{" "}
                                                    <span className="text-danger">
                                                      *
                                                    </span>
                                                  </BSForm.Label>
                                                  <ErrorMessage
                                                    name={`RelatorioGflus[${key}].ApreensoesGflu[${apreensoesIndex}].quantidade`}
                                                    component="span"
                                                    className="text-danger small ml-2"
                                                  />
                                                  <Field
                                                    type="number"
                                                    name={`RelatorioGflus[${key}].ApreensoesGflu[${apreensoesIndex}].quantidade`}
                                                    className="form-control"
                                                    onChange={(e) =>
                                                      setFieldValue(
                                                        `RelatorioGflus[${key}].ApreensoesGflu[${apreensoesIndex}].quantidade`,
                                                        e.target.value
                                                      )
                                                    }
                                                  />
                                                </Col>
                                              ) : null}
                                              {apreensoes.coisa ===
                                              "DINHEIRO" ? (
                                                <Col lg={6}>
                                                  <BSForm.Label as="b">
                                                    VALOR{" "}
                                                    <span className="text-danger">
                                                      *
                                                    </span>
                                                  </BSForm.Label>
                                                  <ErrorMessage
                                                    name={`RelatorioGflus[${key}].ApreensoesGflu[${apreensoesIndex}].valor`}
                                                    component="span"
                                                    className="text-danger small ml-2"
                                                  />
                                                  <Field
                                                    type="text"
                                                    name={`RelatorioGflus[${key}].ApreensoesGflu[${apreensoesIndex}].valor`}
                                                    className="form-control"
                                                    onChange={(e) =>
                                                      setFieldValue(
                                                        `RelatorioGflus[${key}].ApreensoesGflu[${apreensoesIndex}].valor`,
                                                        e.target.value
                                                      )
                                                    }
                                                  />
                                                </Col>
                                              ) : null}

                                              {apreensoes.coisa !==
                                                "DINHEIRO" &&
                                              (apreensoes.coisa === "ANIMAIS" ||
                                                apreensoes.coisa ===
                                                  "OUTRAS APREENSÕES" ||
                                                apreensoes.calibre ===
                                                  "OUTROS" ||
                                                apreensoes.coisa ===
                                                  "APARELHOS ELETRÔNICOS" ||
                                                apreensoes.tipo === "OUTROS" ||
                                                apreensoes.coisa ===
                                                  "ARMA BRANCA") ? (
                                                <Col lg={6}>
                                                  <BSForm.Label as="b">
                                                    DESCRIÇÃO{" "}
                                                    <span className="text-danger">
                                                      *
                                                    </span>
                                                  </BSForm.Label>
                                                  <ErrorMessage
                                                    name={`RelatorioGflus[${key}].ApreensoesGflu[${apreensoesIndex}].descricao`}
                                                    component="span"
                                                    className="text-danger small ml-2"
                                                  />
                                                  <Field
                                                    type="text"
                                                    name={`RelatorioGflus[${key}].ApreensoesGflu[${apreensoesIndex}].descricao`}
                                                    className="form-control"
                                                    onChange={(e) =>
                                                      setFieldValue(
                                                        `RelatorioGflus[${key}].ApreensoesGflu[${apreensoesIndex}].descricao`,
                                                        e.target.value
                                                      )
                                                    }
                                                  />
                                                </Col>
                                              ) : null}
                                            </Row>
                                          </td>
                                          <td className="text-center align-middle">
                                            <Button
                                              size="sm"
                                              type="button"
                                              variant="danger"
                                              title="Excluir"
                                              onClick={() => {
                                                if (
                                                  window.confirm(
                                                    "Deseja realmente excluir essa Apreensão ? "
                                                  )
                                                ) {
                                                  const updatedApreensoes = [
                                                    ...values.RelatorioGflus[
                                                      key
                                                    ]?.ApreensoesGflu,
                                                  ];
                                                  updatedApreensoes.splice(
                                                    apreensoesIndex,
                                                    1
                                                  );
                                                  setFieldValue(
                                                    `RelatorioGflus[${key}].ApreensoesGflu`,
                                                    updatedApreensoes
                                                  );
                                                }
                                              }}
                                            >
                                              <FontAwesomeIcon
                                                icon={faTrash}
                                              ></FontAwesomeIcon>
                                            </Button>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </Table>
                                  )
                                )
                              ) : (
                                <h5 className="text-center mt-3">
                                  Nenhuma apreensão criada
                                </h5>
                              )}
                              <Row className="mt-2">
                                <Col mb={3}>
                                  <Button
                                    type="button"
                                    variant="secondary"
                                    className="btn btn-warning my-3"
                                    onClick={() => {
                                      if (
                                        Array.isArray(
                                          values.RelatorioGflus[key]
                                            ?.ApreensoesGflu
                                        )
                                      ) {
                                        setFieldValue(
                                          `RelatorioGflus[${key}].ApreensoesGflu`,
                                          [
                                            ...values.RelatorioGflus[key]
                                              .ApreensoesGflu,
                                            {},
                                          ]
                                        );
                                      } else {
                                        setFieldValue(
                                          `RelatorioGflus[${key}].ApreensoesGflu`,
                                          [{}]
                                        );
                                      }
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faPlus} />
                                    &nbsp; Adicionar Apreensão
                                  </Button>
                                </Col>
                              </Row>
                            </Tab>
                          </Tabs>
                        </React.Fragment>
                      ))
                    ) : (
                      <h5 className="text-center mt-3">
                        {" "}
                        Nenhum relatório criado
                      </h5>
                    )}
                    {this.props.embarcacao?.RelatorioGflus?.length > 0 ||
                    !this.props.embarcacao?.RelatorioGflus?.length ? (
                      <Button
                        type="button"
                        variant="success"
                        className="form-control btn btn-success mt-3"
                        onClick={() =>
                          setFieldValue("RelatorioGflus", [
                            ...values.RelatorioGflus,
                            {},
                          ])
                        }
                      >
                        <FontAwesomeIcon icon={faPlus} />
                        &nbsp; Adicionar Relatório
                      </Button>
                    ) : null}
                  </Tab>
                </Tabs>
                <FormGroup className="text-right mt-4">
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={() => {
                      this.context.setContent("EmbarcacaoList");
                      setValues({
                        ...values,
                        Manutencaos: [],
                        RelatorioGflus: [],
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                    &nbsp; Fechar
                  </Button>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    variant="primary"
                    className="ml-2"
                  >
                    <FontAwesomeIcon icon={faSave} />
                    &nbsp; Gravar Alterações
                  </Button>
                </FormGroup>
              </Form>
            </>
          );
        }}
      </Formik>
    );
  }
}

export default GrupamentoFluvialForm;
