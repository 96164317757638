import VeiculosPage from "./VeiculosPage";
import VeiculosList from "./VeiculosList";
import VeiculosForm from "./VeiculosForm";




const VeiculosContents = {
  VeiculosPage,
  VeiculosList,
  VeiculosForm,

};

export default VeiculosContents;
