import React from "react";
import { AppContext } from "../context";
import Toast from './Toast';

class Toasts extends React.Component {

    static contextType = AppContext;

    render(){
        
        const toasts = this.context.toasts.map ( ({titulo, conteudo, delay, toastKey}) =>
            <Toast titulo={titulo} conteudo={conteudo} delay={delay} key={toastKey}  delToast={() => this.context.delToast(toastKey)}/>
        );

       return (
            <div
                aria-live="polite"
                aria-atomic="true"
                className="d-print-none"
                style={{
                    position: 'fixed',
                    bottom: 20,
                    right: 20,
                    zIndex: 10000
                }}
            >
                {toasts}
            </div>
       );
    }
}

export default Toasts;