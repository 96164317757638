import React from 'react';
import ModuloForm from './ModuloForm';
import { AppContext } from '../../../context';
import ModelList from '../ModelList';

class ModuloList extends React.Component {
    
    static contextType = AppContext;

    render(){
        return (
            <ModelList
                url={this.context.config.BACKEND_URL + '/modulo'}
                title="Lista de Módulos"
                modelIcon="faCubes"
                useAdd={this.context.usuario.Permissoes.some(p => p.nome === 'admin_modulo_create')}
                modelName="modulo"
                modelForm={ModuloForm}
            />
        );
    }
}


export default ModuloList;