import { Formik, Form, Field } from "formik";
import React from "react";
import { Form as BSForm, FormGroup, Button, Row, Col } from "react-bootstrap";
import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "../../../context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

class AlertaEscolaForm extends React.Component {
  static contextType = AppContext;

  render() {
    return (
      <Formik
        initialValues={{
          ...this.props.alerta,
          createdAt: moment(this.props.alerta.createdAt).toLocaleString(),
          updatedAt: moment(this.props.alerta.updatedAt).toLocaleString()
        }}
      >
        {({ isSubmitting, submitForm, values, setFieldValue, setValues }) => {
          return (
            <Form>
              <FormGroup>
                <BSForm.Label>Nome do Usuário</BSForm.Label>
                <Field
                  type="text"
                  name="Usuario.nome"
                  className="form-control"
                  required
                  disabled
                />
              </FormGroup>
              <FormGroup>
                <BSForm.Label>Telefone</BSForm.Label>
                <Field
                  type="text"
                  name="Usuario.telefone"
                  className="form-control"
                  required
                  disabled
                />
              </FormGroup>
              <FormGroup>
                <BSForm.Label>Nome da Escola</BSForm.Label>
                <Field
                  type="text"
                  name="Escola.nome"
                  className="form-control"
                  required
                  disabled                  
                />
              </FormGroup>
              <FormGroup>
                <BSForm.Label>Cidade</BSForm.Label>
                <Field
                  type="text"
                  name="Escola.Cidade.nome"
                  className="form-control"
                  required
                  disabled
                />
              </FormGroup>
              <FormGroup>
                <BSForm.Label>Endereço</BSForm.Label>
                <Field
                  type="textarea"
                  name="Escola.endereco"
                  className="form-control"
                  required
                  disabled
                />
              </FormGroup>
              <Row>
                <Col>
                  <FormGroup>
                    <BSForm.Label>Latitude</BSForm.Label>
                    <Field
                      type="text"
                      name="Escola.latitude"
                      className="form-control"
                      required
                      disabled
                        />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <BSForm.Label>Longitude</BSForm.Label>
                    <Field
                      type="text"
                      name="Escola.longitude"
                      className="form-control"
                      required
                      disabled
                        />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <BSForm.Label>Data/Hora Criação</BSForm.Label>
                    <Field
                      type="text"
                      name="createdAt"
                      className="form-control"
                      required
                      disabled
                        />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <BSForm.Label>Data/Hora Atualização</BSForm.Label>
                    <Field
                      type="text"
                      name="updatedAt"
                      className="form-control"
                      required
                      disabled
                        />
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <BSForm.Label>Status</BSForm.Label>
                <Field
                  type="text"
                  name="status"
                  className="form-control"
                  required
                  disabled
                />
              </FormGroup>
              <FormGroup className="text-right d-flex justify-content-between">
                <Button
                  type="button"
                  variant="secondary"
                  onClick={() => this.context.closeModal()}
                >
                  <FontAwesomeIcon icon={faTimes} />
                  &nbsp; Fechar
                </Button>
                <Button
                  type="button"
                  disabled={isSubmitting}
                  variant="primary"
                  className="ml-2"
                  onClick={() => {
                    submitForm();
                  }}
                >
                  <FontAwesomeIcon icon={faSave} />
                  &nbsp; Gravar Alterações
                </Button>
              </FormGroup>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

export default AlertaEscolaForm;
