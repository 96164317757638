import { useMemo } from "react";
import { Marker, Popup } from "react-leaflet";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import L from 'leaflet';
import { renderToStaticMarkup } from 'react-dom/server';
import { divIcon } from "leaflet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";

const MyMarker = ({ ponto, componente, camada, pontoEventHandlers, delPonto, isEditor, editComponente }) => {

    const icon = useMemo(() => {
        
        const size = componente.tipo === "Nuvem"? 40 : componente.tamanho;

        if (!componente.icone) {
            return new L.Icon.Default();
        } else {
            return new divIcon({
                html: renderToStaticMarkup(
                    <span style={{ color: componente.linha, fontSize: `${size}px`, lineHeight: `${size}px` }}>
                        <FontAwesomeIcon icon={Icons[componente.icone]} />
                    </span>),
                iconSize: [size, size],
                iconAnchor: [size / 2, size / 2]

            });
        }
    }, [componente.icone, componente.tamanho, componente.linha, componente.tipo]);

    return <Marker icon={icon} position={[ponto.latitude, ponto.longitude]} draggable={isEditor} eventHandlers={pontoEventHandlers(ponto, componente, camada)}>
        <Popup autoPan={false}>
            <h3 className="mb-1">
                {componente.nome}
            </h3>
            <small><i>{camada.nome}</i></small>
            <div dangerouslySetInnerHTML={{ __html: componente.descricao }} />
            <small>
                <b>Latitude:</b>{ponto.latitude}<br />
                <b>Longitude:</b>{ponto.longitude}
            </small>
            {isEditor && <div className="text-right mt-3">
                <Button className="mr-2" size="sm" variant="info" onClick={() => editComponente()}>
                    <FontAwesomeIcon icon={Icons.faEdit} />
                </Button>
                <Button size="sm" variant="danger" onClick={() => delPonto()}>
                    <FontAwesomeIcon icon={Icons.faTrash} />
                </Button>
            </div>}
        </Popup>
    </Marker>
};

export default MyMarker;