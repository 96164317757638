import React from 'react';
import { AppContext } from '../../../context';
import Request from '../../../request';
import ModelList from '../ModelList';
import EleitorForm from './EleitorForm';

class EleitorList extends React.Component {   

    static contextType = AppContext;
    
    onAction(action, update, defaultHandler){
        if (action.name === 'confirm'){
            window.confirm("Esta ação irá reenviar o email para que o eleitor possa clicar no link de confirmação. Tem certeza que deseja executar esta operação?") &&
            Request("GET", this.context.config.BACKEND_URL + "/eleitor/confirmar/" + action.id, this.context.token)
            .then(() => {
                this.context.addToast({titulo: "Notificação", conteudo: "O email foi enviado com o link para confirmação."});
            })
            .catch(err => {
                this.context.addToast({titulo: "Erro", conteudo: "Houve um erro no envio do link para confirmação: "+err.toString()});
            })
        } else if (action.name === 'notify'){
            window.confirm("Esta ação irá enviar o email para que o eleitor possa registrar seu voto. Tem certeza que deseja executar esta operação?") &&
            Request("GET", this.context.config.BACKEND_URL + "/eleitor/notificar/" + action.id, this.context.token)
            .then(() => {
                this.context.addToast({titulo: "Notificação", conteudo: "Os eleitor foi notificado com o link para votação."});
            })
            .catch(err => {
                this.context.addToast({titulo: "Erro", conteudo: "Houve um erro no envio da notificação para votação: "+err.toString()});
            })
        } else defaultHandler();
    }

    render(){
        return (
            <ModelList 
                url={this.context.config.BACKEND_URL + '/eleitor'}
                title="Lista de Eleitores"
                modelName="eleitor"
                modelIcon="faIdCard"
                modelForm={EleitorForm}
                modalSize="lg"
                useAdd={this.context.usuario.Permissoes.some(p => p.nome === 'eleicoes_eleitor_create')}
                onAction={(action, update, defaultHandler) => this.onAction(action, update, defaultHandler)}
            />
        );
    }
    
}

export default EleitorList;