import superagent from 'superagent';

const Request = (method, url, token) => {

    const request = superagent(method, url)
        .set({ Accept: 'json', Authorization: `Bearer ${token}` })

    return request;
}

export default Request;