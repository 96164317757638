import * as Icons from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Datatable from '../Datatable';
import Request from '../../request';
import { AppContext } from '../../context';


class ModelList extends React.Component {

    static contextType = AppContext;

    static defaultProps = {
        url: "",
        title: "Lista de Registros",
        modelIcon: "",
        modelForm: "div",
        modelName: "model",
        modalSize: "md",
        empty: {},
        useAdd: false,
        filter: ""
    }

    onAction(action, update) {

        const defaultHandler = () => {
            const url = `${this.props.url}/${action.id}`;
            switch (action.name) {
                case 'print':
                    Request('GET', url, this.context.token)
                        .then(res => {
                            const props = {
                                [this.props.modelName]: res.body,
                            };
                            this.context.setContent(this.props.modelPrint, props);
                        })
                        .catch(err => {
                            this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na execução da operação: " + err.toString() });
                        });
                    break;
                case 'view':
                case 'info':
                case 'edit':
                    Request('GET', url, this.context.token)
                        .then(res => {
                            const Form = this.props.modelForm;
                            const props = {
                                [this.props.modelName]: res.body,
                                onSave: () => update()
                            };
                            this.context.openModal({
                                titulo: action.name === 'edit' ? `Editar Registro #${action.id}` : 'Visualizar Registro',
                                conteudo: <Form {...props} />,
                                size: this.props.modalSize ?? "md"
                            });
                        })
                        .catch(err => {
                            this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na execução da operação: " + err.toString() });
                        });
                    break;
                case 'delete':
                    if (window.confirm(`Deseja realmente excluir o registro com id ${action.id}?`)) {
                        Request('DELETE', url, this.context.token)
                            .then(() => {
                                this.context.addToast({ titulo: "Successo", conteudo: "Registro excluído com sucesso." });
                                update();
                            })
                            .catch(err => {
                                this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na execução da operação: " + err.toString() });
                            });
                    }
                    break;
                default:
                    if(action.name === "cancel"){
                        if (window.confirm(`Deseja realmente cancelar envio da Denúncia id ${action.id}?`)) {
                            Request('PATCH', `${url}/${action.name}`, this.context.token)
                                .then(() => {
                                    this.context.addToast({ titulo: "Successo", conteudo: "Registro atualizado com sucesso." });
                                    update();
                                })
                                .catch(err => {
                                    this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na execução da operação: " + err.toString() });
                                });
                        }
                    } else {
                        Request('PATCH', `${url}/${action.name}`, this.context.token)
                            .then(() => {
                                this.context.addToast({ titulo: "Successo", conteudo: "Registro atualizado com sucesso." });
                                update();
                            })
                            .catch(err => {
                                this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na execução da operação: " + err.toString() });
                            });
                    }
                    
                    break;
            }
        }

        if (this.props.onAction) this.props.onAction(action, update, defaultHandler);
        else defaultHandler();
    }

    render() {
        const EmptyForm = this.props.modelForm ? this.props.modelForm : "div";
        const emptyProps = {
            [this.props.modelName]: this.props.empty
        };

        return (
            <div className='h-100 d-flex flex-column'>
                <h3 className="mb-3">
                    <div>
                        {this.props.modelIcon && <FontAwesomeIcon icon={Icons[this.props.modelIcon]} />}
                        &nbsp;
                        {this.props.title}
                    </div>
                </h3>
                <div className='flex-grow-1 flex-shrink-1 overflow-auto'>
                    <Datatable
                        url={this.props.url}
                        useAdd={this.props.useAdd}
                        onError={(err) => this.context.addToast({ titulo: "Erro", conteudo: err.toString() })}
                        onAction={(action, update) => this.onAction(action, update)}
                        onClickAdd={update => this.props.onClickAdd ? this.props.onClickAdd(update) : this.context.openModal({ titulo: 'Adicionar', conteudo: <EmptyForm {...emptyProps} onSave={() => update()} />, size: this.props.modalSize ?? "md" })}
                        filter={this.props.filter}
                    />
                </div>
            </div>
        );
    }
}

export default ModelList;