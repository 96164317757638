import React from 'react';
import DiligenciaForm from './DiligenciaForm';
import ModelList from '../ModelList';
import { AppContext } from "../../../context";

class DiligenciaList extends React.Component {    

    static contextType = AppContext;

    render(){
        return (
            <ModelList 
                url={this.context.config.BACKEND_URL + '/diligencia'}
                title="Lista de Diligências em Históricos"
                modelName="diligencia"
                modelIcon="faBalanceScale"
                modelForm={DiligenciaForm}
                useAdd={true}
            />
        );
    }
}

export default DiligenciaList;