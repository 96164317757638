import { faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Card } from "react-bootstrap";
import { AppContext } from "../context";

class ExpiredOverlay extends React.Component {

    static contextType = AppContext;

    render() {
        return this.context.expirado &&
            <div
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(1,1,1,.5)",
                    zIndex: 10000000,
                }}
                className="d-flex justify-content-center align-items-center"
            >
                <Card>
                    <Card.Header>Sessão Expirada</Card.Header>
                    <Card.Body>
                        Clique no botão abaixo para inicar uma nova sessão.
                    </Card.Body>
                    <Card.Footer>
                        <Button
                            type="button"
                            variant="primary"
                            onClick={() => this.context.resetToken()}
                            className="form-control"
                        >
                            <FontAwesomeIcon icon={faSignInAlt} /> Entrar novamente
                        </Button>
                    </Card.Footer>
                </Card>
            </div>
    }
}

export default ExpiredOverlay;