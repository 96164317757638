import React from "react";
import { AppContext } from "../../../../context";
import { Card, Col, Row, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTable } from "@fortawesome/free-solid-svg-icons";
import Request from "../../../../request";
import { Bar, PolarArea } from "react-chartjs-2";
import Select from "react-select";

class DashBoard extends React.Component {
  static contextType = AppContext;

  state = {
    emRegistro: [],
    registrado: [],
    avaliada: [],
    countRegistradaCidades: [],
    countNatureza: [],
    countDenunciasHora: {},
    countCanal: [],
    countFinalizadaSetor: [],
    setores: [],
    orgaos: [], 
    selectedOrgao: null,
    selectedSetor: null,
  };

  componentDidMount() {
    this.fetchData();

    const errorHandler = (err) =>
      this.context.addToast({
        titulo: "Erro",
        conteudo: "Houve uma falha na pesquisa.",
      });

    Request(
      "GET",
      this.context.config.BACKEND_URL + "/orgao/options",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ orgaos: options.body }));
      })
      .catch(errorHandler);

    Request(
      "GET",
      this.context.config.BACKEND_URL + "/setor/options",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ setores: options.body }));
      })
      .catch(errorHandler);
  }

  fetchData() {
    const { selectedSetor } = this.state;

    const queryParams = [];
    if (selectedSetor) queryParams.push(`setorId=${selectedSetor}`);

    const queryString =
      queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

    Request(
      "GET",
      this.context.config.BACKEND_URL + "/denuncia/dashboard" + queryString,
      this.context.token
    )
      .then((res) => {
        const emRegistro = Array.isArray(res.body.countEmRegistro)
          ? res.body.countEmRegistro
          : [{ status: "Em Registro", count: res.body.countEmRegistro }];
        const registrado = Array.isArray(res.body.countRegistrada)
          ? res.body.countRegistrada
          : [{ status: "Registrada", count: res.body.countRegistrada }];
        const avaliada = Array.isArray(res.body.countAvaliada)
          ? res.body.countAvaliada
          : [{ status: "Avaliada", count: res.body.countAvaliada }];

        this.setState({
          emRegistro,
          registrado,
          avaliada,
          countRegistradaCidades: res.body.countRegistradaCidade,
          countNatureza: res.body.countNatureza,
          countDenunciasHora: res.body.countDenunciasHora,
          countCanal: res.body.countCanal,
          countFinalizadaSetor: res.body.countFinalizadaSetor,
        });
      })
      .catch((err) => {
        this.context.addToast({
          title: "Erro",
          text:
            "Falha na recuperação da lista de permissões: " + err.toString(),
        });
      });
  }

  handleOrgaoChange = (selectedOption) => {
    this.setState({ selectedOrgao: selectedOption.value });
  };

  handleSetorChange = (selectedOption) => {
    this.setState({ selectedSetor: selectedOption.value }, this.fetchData);
  };

  render() {
    const variants = ["primary", "warning", "danger", "success", "info"];
    const randomNumberInRange = (min, max) => {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    const labelsCountRegCid = this.state.countRegistradaCidades.map(
      (item) => item.nome
    );
    const dataCountRegCid = this.state.countRegistradaCidades.map(
      (item) => item.count
    );
    const labelsCountFinalizadaSetor = this.state.countFinalizadaSetor.map(
      (item) => item.nome
    );
    const dataCountFinalizadaSetor = this.state.countFinalizadaSetor.map(
      (item) => item.count
    );
    const labelsCountNat = this.state.countNatureza.map(
      (item) => item.Natureza?.nome
    );
    const dataCountNat = this.state.countNatureza.map((item) => item.count);
    const labelsCountCanal = this.state.countCanal.map(
      (item) => item.Canal?.nome
    );
    const dataCountCanal = this.state.countCanal.map((item) => item.count);

    const {
      ultimaHora,
      ultimas24Horas,
      ultimos7Dias,
      ultimos30Dias,
      ultimos365Dias,
    } = this.state.countDenunciasHora;

    const colors = [
      "rgba(255, 99, 132, 0.2)",
      "rgba(54, 162, 235, 0.2)",
      "rgba(255, 206, 86, 0.2)",
      "rgba(75, 192, 192, 0.2)",
      "rgba(153, 102, 255, 0.2)",
      "rgba(255, 159, 64, 0.2)",
    ];

    const borderColor = [
      "rgba(255, 99, 132, 1)",
      "rgba(54, 162, 235, 1)",
      "rgba(255, 206, 86, 1)",
      "rgba(75, 192, 192, 1)",
      "rgba(153, 102, 255, 1)",
      "rgba(255, 159, 64, 1)",
    ];

    const chartDataRegCid = {
      labels: labelsCountRegCid,
      datasets: [
        {
          label: "Total Registrada por Cidade",
          backgroundColor: colors,
          borderColor: borderColor,
          borderWidth: 1,
          hoverBackgroundColor: colors.map((color) => color.replace("0.2", "0.4")),
          hoverBorderColor: borderColor,
          data: dataCountRegCid,
        },
      ],
    };

    const chartDataFinalizadaSetor = {
      labels: labelsCountFinalizadaSetor,
      datasets: [
        {
          label: "Total Registrada por Setor",
          backgroundColor: colors,
          borderColor: borderColor,
          borderWidth: 1,
          hoverBackgroundColor: colors.map((color) => color.replace("0.2", "0.4")),
          hoverBorderColor: borderColor,
          data: dataCountFinalizadaSetor,
        },
      ],
    };

    const chartDataNat = {
      labels: labelsCountNat,
      datasets: [
        {
          label: "Top 10 Naturezas Mais Registradas",
          backgroundColor: colors,
          borderColor: borderColor,
          borderWidth: 1,
          hoverBackgroundColor: colors.map((color) => color.replace("0.2", "0.4")),
          hoverBorderColor: borderColor,
          data: dataCountNat,
        },
      ],
    };

    const chartDataCanal = {
      labels: labelsCountCanal,
      datasets: [
        {
          label: "Top 10 Canais Mais Registrados",
          backgroundColor: colors,
          borderColor: borderColor,
          borderWidth: 1,
          hoverBackgroundColor: colors.map((color) => color.replace("0.2", "0.4")),
          hoverBorderColor: borderColor,
          data: dataCountCanal,
        },
      ],
    };

    const chartDataDenuncias = {
      labels: [
        "Última Hora",
        "Últimas 24 Horas",
        "Últimos 7 Dias",
        "Últimos 30 Dias",
        "Últimos 365 Dias",
      ],
      datasets: [
        {
          label: "Denúncias",
          backgroundColor: colors,
          borderColor: borderColor,
          borderWidth: 1,
          hoverBackgroundColor: colors.map((color) => color.replace("0.2", "0.4")),
          hoverBorderColor: borderColor,
          data: [
            ultimaHora,
            ultimas24Horas,
            ultimos7Dias,
            ultimos30Dias,
            ultimos365Dias,
          ],
        },
      ],
    };

    const { setores } = this.state;
    const { orgaos } = this.state;

    return (
      <>
        <Row className="mt-1">
          <Col>
            <Card>
              <Card.Header>
                <FontAwesomeIcon icon={faTable} />{" "}
                <strong className="text-uppercase">
                  Quantidade de Denuncias{" "}
                </strong>
              </Card.Header>
              <Card.Body>
                <Form>
                  <Row>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label>Órgão</Form.Label>
                        <Select
                          name="OrgaoId"
                          noOptionsMessage={() => "Nada encontrado."}
                          placeholder="Pesquisar..."
                          menuPortalTarget={document.body}
                          menuShouldBlockScroll={true}
                          options={orgaos}
                          value={orgaos.find(
                            (option) => option.value === this.state.selectedOrgao
                          )}
                          onChange={this.handleOrgaoChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label>Setor</Form.Label>
                        <Select
                          name="SetorId"
                          noOptionsMessage={() => "Nada encontrado."}
                          placeholder="Pesquisar..."
                          menuPortalTarget={document.body}
                          menuShouldBlockScroll={true}
                          options={setores.filter(
                            (setor) => setor.OrgaoId === this.state.selectedOrgao
                          )}
                          onChange={this.handleSetorChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
                <Row>
                  <Col>
                    {this.state.emRegistro.map((emRegistro, index) => (
                      <Card
                        key={index}
                        bg={variants[randomNumberInRange(0, 4)]}
                        className="text-white mb-3"
                        style={{ height: "150px" }}
                      >
                        <Card.Header>{emRegistro.status}</Card.Header>
                        <Card.Body className="d-flex align-items-end justify-content-end">
                          <h1>{emRegistro.count}</h1>
                        </Card.Body>
                      </Card>
                    ))}
                  </Col>
                  <Col>
                    {this.state.registrado.map((registrado, index) => (
                      <Card
                        key={index}
                        bg={variants[randomNumberInRange(0, 4)]}
                        className="text-white mb-3"
                        style={{ height: "150px" }}
                      >
                        <Card.Header>{registrado.status}</Card.Header>
                        <Card.Body className="d-flex align-items-end justify-content-end">
                          <h1>{registrado.count}</h1>
                        </Card.Body>
                      </Card>
                    ))}
                  </Col>
                  <Col>
                    {this.state.avaliada.map((avaliada, index) => (
                      <Card
                        key={index}
                        bg={variants[randomNumberInRange(0, 4)]}
                        className="text-white mb-3"
                        style={{ height: "150px" }}
                      >
                        <Card.Header>{avaliada?.status}</Card.Header>
                        <Card.Body className="d-flex align-items-end justify-content-end">
                          <h1>{avaliada?.count}</h1>
                        </Card.Body>
                      </Card>
                    ))}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col className="border">
            <Bar
              data={chartDataRegCid}
              width={100}
              height={200}
              options={{
                maintainAspectRatio: false,
              }}
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col className="border">
            <PolarArea
              data={chartDataNat}
              width={100}
              height={600}
              options={{
                maintainAspectRatio: false,
              }}
            />
          </Col>

          <Col className="border">
            <PolarArea
              data={chartDataCanal}
              width={100}
              height={600}
              options={{
                maintainAspectRatio: false,
              }}
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col className="border">
            <Bar
              data={chartDataDenuncias}
              width={100}
              height={200}
              options={{
                maintainAspectRatio: false,
              }}
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col className="border">
            <Bar
              data={chartDataFinalizadaSetor}
              width={100}
              height={200}
              options={{
                maintainAspectRatio: false,
              }}
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default DashBoard;
