import moment from 'moment';
import React from 'react';
import { Col, Form, FormControl, Row } from 'react-bootstrap';
import logoSegup from '../img/segup.png';
import md5 from "md5";
import { AppContext } from '../../../context';

class DeclaracaoComprovante extends React.Component {

    static contextType = AppContext;

    formatter = new Intl.NumberFormat('pt-Br', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
    });

    componentDidMount() {
        window.print();
    }

    render() {
        return (

            <Form>

                <h2 className="text-center d-flex justify-content-between">
                    <div>
                        <b>Comprovante de Declaração de Bens e Valores</b>
                    </div>
                    <img src={logoSegup} alt="logo" height="35px" />
                </h2>
                <hr />
                <fieldset className='mt-3'>
                    <Row>
                        <Col sm={6}>
                            <strong >Declarante</strong>
                            <FormControl disabled={true} value={this.props.declaracao.Usuario.nome} />
                        </Col>
                        <Col sm={4}>
                            <strong >CPF</strong>
                            <FormControl disabled={true} value={this.props.declaracao.Usuario.login} />
                        </Col>
                        <Col sm={2}>
                            <strong>Exercício</strong>
                            <FormControl disabled={true} value={this.props.declaracao.exercicio} />
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col>
                            <strong >Órgão</strong>
                            <FormControl disabled={true} value={this.props.declaracao.Usuario.Orgao.nome} />
                        </Col>
                        <Col>
                            <strong >Setor</strong>
                            <FormControl disabled={true} value={this.props.declaracao.Usuario.Setor.nome} />
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col>
                            <strong >Razão da Apresentação da Declaração de Bens e Valores</strong> 
                            <FormControl disabled={true} as="textarea" value={this.props.declaracao.Razao.descricao} />
                        </Col>
                    </Row>
                </fieldset>               
                <p className='mt-3'>
                    Declaramos para os devidos fins que o agente público acima identificado apresentou declaração de bens e valores à unidade de gestão de pessoas deste órgão/entidade, em atenção às disposições do Decreto Estadual nº. 1.712, de 12 de julho de 2021.
                </p>
                <p className='mt-5'>
                    <b>Data/Hora da Entrega: </b>{moment(this.props.declaracao.updatedAt).utcOffset(0).format('DD [de] MMMM [de] YYYY HH:mm:ss')}.
                </p>
                <p>
                    <b>Data/Hora da Emissão: </b>{moment().format('DD [de] MMMM [de] YYYY HH:mm:ss')}
                </p>                
                <p className='mt-3'>
                    <b>Código de Controle: </b>{md5(`${this.props.declaracao.id}${this.props.declaracao.Usuario.login}${this.props.declaracao.updatedAt}`)}
                </p>
            </Form>
        );
    }
}


export default DeclaracaoComprovante;
