import BoletimPage from "./BoletimPage";
import BoletimForm from "./BoletimForm";
import BoletimList from "./BoletimList";
import BoletimEnvolvidoList from "./BoletimEnvolvidoList";
import BoletimPrint from "./BoletimPrint";

import BoletimMaterialForm from "./BoletimMaterialForm";
import BoletimMaterialList from "./BoletimMaterialList";
import BoletimAtributoForm from "./BoletimAtributoForm";
import BoletimAtributoList from "./BoletimAtributoList";

const BoletimContents = {
    BoletimPage,
    BoletimForm,
    BoletimList,
    BoletimPrint,
    BoletimEnvolvidoList,
    BoletimAtributoForm,
    BoletimAtributoList,
    BoletimMaterialForm,
    BoletimMaterialList

};

export default BoletimContents;