import React from "react";
import { Bar, Line, Pie, PolarArea } from "react-chartjs-2";
import { AppContext } from "../../../context";
import { Form as BSForm, Button, Col, Container, Row } from "react-bootstrap";
import "./css/index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalculator,
  faCalendarAlt,
  faFileCsv,
  faMap,
  faPrint,
  faSearch,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import Request from "../../../request";
import { MapContainer, TileLayer, GeoJSON, Popup, Marker } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet.heat";
import geoJsonData from "./GeoJsPara.json";
import icon from "leaflet/dist/images/marker-icon.png";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import html2canvas from "html2canvas";
import { ExportToCsv } from "export-to-csv";
import jsPDF from "jspdf";
import imageURL from "../../../img/logo1.jpeg";
import "leaflet/dist/leaflet.css";

class DashboardProMulher extends React.Component {
  static contextType = AppContext;

  state = {
    cidadeSelecionada: null,
    cidades: [],
    filterCidade: null,
    ocorrencias: [],
    ocorrenciaCount: null,
    dataInicial: "",
    dataFinal: "",
    coordenadasCidadeSelecionada: null,
    mapCenter: [-3.5, -53], // Coordenadas do centro do mapa
    mapZoom: 6,
    idadeAgressorData: {},
    grauParentescoData: {},
    idadeVitimaData: {},
    tipoAcionamentoData: {},
    situacaoOcorrenciaData: {},
    horaFatoData: {},
    conclusaoOcorrenciaData: {},
    procedimentoRealizadoData: {},
    orgaoData: {},
    selectedCityGeoJSON: null,
  };

  componentDidMount() {
    const errorHandler = (err) =>
      this.context.addToast({
        titulo: "Erro",
        conteudo: "Houve uma falha na pesquisa.",
      });

    // Fetch city options
    Request(
      "GET",
      this.context.config.BACKEND_URL + "/proMulher/cidade/options",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ cidades: options.body }));
      })
      .catch(errorHandler);

    // Fetch all data initially
    this.fetchData();
  }

  fetchData = async () => {
    const errorHandler = (err) =>
      this.context.addToast({
        titulo: "Erro",
        conteudo: "Houve uma falha na pesquisa.",
      });
    let { dataInicial, dataFinal, cidadeSelecionada } = this.state;
    if (dataInicial && dataFinal) {
      if (dataInicial > dataFinal) {
        this.context.addToast({
          titulo: "Erro",
          conteudo: "Data inicial não pode ser maior que a data final.",
        });
      }
    }
    let url = `${this.context.config.BACKEND_URL}/proMulher/dashboard`;
    if (dataInicial && dataFinal) {
      url += `/${dataInicial}/${dataFinal}`;
    } else if (dataInicial) {
      url += `/${dataInicial}/2100-12-25`;
    } else if (dataFinal) {
      url += `/1950-01-01/${dataFinal}`;
    }

    if (cidadeSelecionada) {
      if (!dataInicial && !dataFinal) {
        url += `/1950-01-01/2100-12-25`;
      }
      url += `/${cidadeSelecionada}`;
    }
    Request("GET", url, this.context.token)
      .send()
      .then((res) => {
        this.setState(
          {
            ocorrencias: res.body,
            ocorrenciaCount: Object.keys(res.body).length,
          },
          this.processData
        );
      })
      .catch(errorHandler);
  };

  exportToCSV = () => {
    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(this.state.ocorrencias);
  };

  print = () => {

    setTimeout(() => {
      const container = document.getElementById("print-container");
      if (container) {
        container.classList.add("print-style"); // Adiciona a classe de estilo de impressão
        const pdf = new jsPDF("p", "mm", "a4"); // Tamanho padrão A4 em paisagem
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        const margin = 10; // Margem de 10mm
        const sections = [
          "row",
          "row1",
          "row2",
          "row3",
          "row4",
          "row5",
          "row6",
          "row7",
          "row8",
        ];
        pdf.addPage();
        pdf.addImage(
          imageURL,
          "JPEG",
          margin,
          margin,
          pageWidth - 2 * margin,
          40
        );
        let yPosition = 50;

        for (let i = 1; i <= 16; i++) {
          // Define a cor com base na paridade de 'i'
          if (i % 2 === 1) {
            pdf.setTextColor(0, 0, 255); // Cor azul para índices ímpares
          } else {
            pdf.setTextColor(0); // Cor padrão (preto) para índices pares
          }

          // Adiciona cada texto com a posição Y incrementada
          switch (i) {
            case 1:
              pdf.text(
                "GOVERNADOR DO ESTADO DO PARÁ",
                pageWidth / 2,
                yPosition,
                { align: "center" }
              );
              break;
            case 2:
              pdf.text("HELDER BARBALHO", pageWidth / 2, yPosition, {
                align: "center",
              });
              break;
            case 3:
              pdf.text(
                "SECRETÁRIO DE SEGURANÇA PÚBLICA E DEFESA SOCIAL",
                pageWidth / 2,
                yPosition,
                { align: "center" }
              );
              break;
            case 4:
              pdf.text("UALAME FIALHO MACHADO", pageWidth / 2, yPosition, {
                align: "center",
              });
              break;
            case 5:
              pdf.text(
                "SECRETÁRIO ADJUNTO DE GESTÃO ADMINISTRATIVA",
                pageWidth / 2,
                yPosition,
                { align: "center" }
              );
              break;
            case 6:
              pdf.text("PAULO ROBERTO", pageWidth / 2, yPosition, {
                align: "center",
              });
              break;
            case 7:
              pdf.text(
                "SECRETÁRIO ADJUNTO DE GESTÃO OPERACIONAL",
                pageWidth / 2,
                yPosition,
                { align: "center" }
              );
              break;
            case 8:
              pdf.text("ALEXANDRE MASCARENHAS", pageWidth / 2, yPosition, {
                align: "center",
              });
              break;
            case 9:
              pdf.text(
                "SECRETÁRIO ADJUNTO DE INTELIGÊNCIA E ANÁLISE CRIMINAL",
                pageWidth / 2,
                yPosition,
                { align: "center" }
              );
              break;
            case 10:
              pdf.text(
                "CARLOS ANDRÉ VIANA DA COSTA",
                pageWidth / 2,
                yPosition,
                { align: "center" }
              );
              break;
            default:
              pdf.text("DASHBOARD PRÓ MULHER", pageWidth / 2, yPosition, {
                align: "center",
              });
              break;
          }
          yPosition += 10; // Incrementa a posição Y para o próximo texto
        }
        const addWatermark = () => {
          pdf.setFontSize(30);
          pdf.setTextColor(150, 150, 150);
          pdf.text(
            "Secretaria de Estado de Segurança Pública e Defesa Social",
            pageWidth / 2,
            pageHeight / 2,
            {
              align: "center",
              angle: 15,
            }
          );
          pdf.setTextColor(0, 0, 0);
          pdf.setFontSize(12);
        };
        addWatermark();
        const generatePDF = (index = 0) => {
          if (index >= sections.length) {
            const currentDate = new Date()
              .toLocaleDateString()
              .split("/")
              .join("-");
            pdf.save(`ocorrencias_${currentDate}.pdf`);
            container.classList.remove("print-style"); // Remove a classe de estilo de impressão após salvar o PDF
            return;
          }
          const section = sections[index];
          const sectionContainer = document.getElementById(section);
          if (sectionContainer) {
            html2canvas(sectionContainer, { scale: 0.99 }).then(
              (sectionCanvas) => {
                const sectionImgData = sectionCanvas.toDataURL("image/png");
                const imgWidth = pageWidth - 2 * margin;
                const imgHeight =
                  sectionCanvas.height * (imgWidth / sectionCanvas.width);

                pdf.addPage();
                pdf.addImage(
                  sectionImgData,
                  "PNG",
                  margin,
                  margin,
                  imgWidth,
                  imgHeight
                );
                addWatermark();

                generatePDF(index + 1);
              }
            );
          } else {
            generatePDF(index + 1);
          }
        };

        generatePDF();
      } else {
        console.error('Element with ID "print-container" not found.');
      }
    }, 1000);
  };

  handleFilterButtonClick = () => {
    const { dataInicial, dataFinal } = this.state;

    if (dataInicial && dataFinal) {
      this.setState(
        {
          dataInicial: this.formatarDataFinal(dataInicial),
          dataFinal: this.formatarDataFinal(dataFinal),
        },
        this.fetchData
      );
    } else if (dataFinal) {
      this.setState(
        {
          dataFinal: this.formatarDataFinal(dataFinal),
        },
        this.fetchData
      );
    } else if (dataInicial) {
      this.setState(
        {
          dataInicial: this.formatarDataFinal(dataInicial),
        },
        this.fetchData
      );
    } else {
      this.fetchData();
    }

    let filteredOcorrencias = this.state.ocorrencias;
    if (this.state.cidadeSelecionada) {
      filteredOcorrencias = filteredOcorrencias.filter(
        (ocorrencia) => ocorrencia.municipio === this.state.cidadeSelecionada
      );
      const cidadeGeoJSON = this.findCityGeoJSON(this.state.cidadeSelecionada);
      if (cidadeGeoJSON) {
        const cidadeCoordenadas = this.findCityCoordinates(cidadeGeoJSON);
        this.setState({
          mapCenter: [cidadeCoordenadas.lat, cidadeCoordenadas.lng],
          mapZoom: 10,
        });
        if (this.mapRef) {
          this.mapRef.setView(
            [cidadeCoordenadas.lat, cidadeCoordenadas.lng],
            10
          );
        }
      } else {
        console.log(
          `City not found in GeoJSON: ${this.state.cidadeSelecionada}`
        );
      }
    }
  };

  handleResetFilters = () => {
    this.setState(
      {
        cidadeSelecionada: null,
        filterCidade: null,
        dataInicial: "",
        dataFinal: "",
        cidade: null,
        coordenadasCidadeSelecionada: null,
        mapCenter: [-3.5, -52.490278], // Coordenadas do centro do mapa
        mapZoom: 6,
        idadeAgressorData: {},
        grauParentescoData: {},
      },
      () => {
        this.fetchData();
      }
    );
  };

  findCityCoordinates(cityGeoJSON) {
    const coordinates = cityGeoJSON.geometry.coordinates[0][0];
    return { lat: coordinates[1], lng: coordinates[0] };
  }

  findCityGeoJSON(cityName) {
    const data = geoJsonData;
    for (let feature of data.features) {
      if (feature.properties.name.toLowerCase() === cityName.toLowerCase()) {
        return feature;
      }
    }
    return null;
  }

  processData = () => {
    const { ocorrencias } = this.state;
    const idadeAgressorData = {};
    const grauParentescoData = {};
    const idadeVitimaData = {};
    const tipoAcionamentoData = {};
    const situacaoOcorrenciaData = {};
    const horaFatoData = {};
    const conclusaoOcorrenciaData = {};
    const procedimentoRealizadoData = {};
    const orgaoData = {};

    ocorrencias.forEach((ocorrencia) => {
      const idade = ocorrencia.idade_agressor;
      if (idadeAgressorData[idade]) {
        idadeAgressorData[idade]++;
      } else {
        idadeAgressorData[idade] = 1;
      }

      const grauParentesco = ocorrencia.grau_parentesco;
      if (grauParentescoData[grauParentesco]) {
        grauParentescoData[grauParentesco]++;
      } else {
        grauParentescoData[grauParentesco] = 1;
      }

      const idadeVitima = ocorrencia.idade_vitima;
      if (idadeVitimaData[idadeVitima]) {
        idadeVitimaData[idadeVitima]++;
      } else {
        idadeVitimaData[idadeVitima] = 1;
      }

      const tipoAcionamento = ocorrencia.tipo_acionamento;
      if (tipoAcionamentoData[tipoAcionamento]) {
        tipoAcionamentoData[tipoAcionamento]++;
      } else {
        tipoAcionamentoData[tipoAcionamento] = 1;
      }

      const situacaoOcorrencia = ocorrencia.situacao_ocorrencia;
      if (situacaoOcorrenciaData[situacaoOcorrencia]) {
        situacaoOcorrenciaData[situacaoOcorrencia]++;
      } else {
        situacaoOcorrenciaData[situacaoOcorrencia] = 1;
      }
      const horaFato = ocorrencia.hora_fato;
      if (horaFatoData[horaFato]) {
        horaFatoData[horaFato]++;
      } else {
        horaFatoData[horaFato] = 1;
      }

      const conclusaoOcorrencia = ocorrencia.conclusao_ocorrencia;
      if (conclusaoOcorrenciaData[conclusaoOcorrencia]) {
        conclusaoOcorrenciaData[conclusaoOcorrencia]++;
      } else {
        conclusaoOcorrenciaData[conclusaoOcorrencia] = 1;
      }

      const procedimentoDelegacia = ocorrencia.procedimento_delegacia;
      if (procedimentoRealizadoData[procedimentoDelegacia]) {
        procedimentoRealizadoData[procedimentoDelegacia]++;
      } else {
        procedimentoRealizadoData[procedimentoDelegacia] = 1;
      }

      const orgao = ocorrencia.orgao_atendimento;
      if (orgaoData[orgao]) {
        orgaoData[orgao]++;
      } else {
        orgaoData[orgao] = 1;
      }
    });
    this.setState({
      idadeAgressorData,
      grauParentescoData,
      idadeVitimaData,
      tipoAcionamentoData,
      situacaoOcorrenciaData,
      horaFatoData,
      conclusaoOcorrenciaData,
      procedimentoRealizadoData,
      orgaoData,
    });
  };

  render() {
    const colors = [
      "rgba(255, 99, 132, 0.6)",
      "rgba(54, 162, 235, 0.6)",
      "rgba(255, 206, 86, 0.6)",
      "rgba(75, 192, 192, 0.6)",
      "rgba(153, 102, 255, 0.6)",
      "rgba(255, 159, 64, 0.6)",
      "rgba(199, 199, 199, 0.6)",
      "rgba(83, 102, 255, 0.6)",
      "rgba(255, 99, 255, 0.6)",
      "rgba(99, 255, 132, 0.6)",
    ];

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    };

    const chartOptions = {
      maintainAspectRatio: false,
      responsive: true,
      plugins: {
        legend: {
          labels: {
            font: {
              weight: "bold",
            },
          },
        },
      },
    };
    return (
      <Container fluid className="container_principal">
        <div id="print-container" style={{ width: "100%", height: "100%" }}>
          <Row className="d-flex justify-content-center backgroundTitle borda-arredondada-topo">
            <h1 className="mt-1 mx-2">DASHBOARD PRÓ MULHER</h1>
            <hr className="hr-full-width" />
          </Row>
          <Row className="backgroundBody d-flex justify-content-between">
            <Col className="mt-2 d-flex align-items-center">
              <FontAwesomeIcon icon={faMap} className="mr-2" />
              <BSForm.Label as="b" style={{ whiteSpace: "nowrap" }}>
                Cidade
              </BSForm.Label>
              <Select
                name="cidade"
                placeholder="Pesquisar..."
                className="ml-2 full-width-select"
                isClearable={true}
                menuShouldBlockScroll={true}
                styles={{
                  control: (base) => ({ ...base, zIndex: 10001 }),
                  menu: (base) => ({ ...base, zIndex: 10001 }),
                }}
                options={this.state.cidades}
                onChange={(selectedOption) => {
                  const selectedCidade = selectedOption
                    ? selectedOption.label
                    : null;
                  this.setState({ cidadeSelecionada: selectedCidade });
                }}
              />
            </Col>

            <Col className="mt-2 d-flex align-items-center">
              <FontAwesomeIcon icon={faCalendarAlt} className="mr-2" />
              <span style={{ whiteSpace: "nowrap" }}>Data Inicial</span>
              <input
                type="date"
                name="dataInicial"
                className="form-control ml-2"
                value={this.state.dataInicial}
                onChange={handleInputChange}
              />
            </Col>
            <Col className="mt-2 d-flex align-items-center">
              <FontAwesomeIcon icon={faCalendarAlt} className="mr-2" />
              <span style={{ whiteSpace: "nowrap" }}>Data Final</span>
              <input
                type="date"
                name="dataFinal"
                className="form-control ml-2"
                value={this.state.dataFinal}
                onChange={handleInputChange}
              />
            </Col>

            <Col className="mt-2 d-flex align-items-center justify-content-between">
              <FontAwesomeIcon icon={faCalculator} className="mr-2" />
              <span
                className="mr-2"
                style={{ fontWeight: "bold", whiteSpace: "nowrap" }}
              >
                Total:
              </span>
              <label className="form-control mt-2">
                {" "}
                {this.state.ocorrenciaCount}
              </label>
              <Button
                className="mx-2"
                variant="primary"
                style={{ whiteSpace: "nowrap" }}
                onClick={this.handleFilterButtonClick}
              >
                <FontAwesomeIcon icon={faSearch} className="mr-2" />
                Filtrar
              </Button>
              <Button
                className="mr-2"
                variant="danger"
                style={{ whiteSpace: "nowrap" }}
                onClick={this.handleResetFilters}
              >
                <FontAwesomeIcon icon={faTrashAlt} className="mr-2" />
                Limpar
              </Button>
            </Col>
          </Row>
          <Row className="backgroundBody">
            <Col md={12} id="row">
              <div className="chart-container-map">
                <h3 className="titulo-suave">Mapa das Ocorrências</h3>

                <hr className="hr-full-width" />
                <MapContainer
                  center={this.state.mapCenter}
                  zoom={this.state.mapZoom}
                  style={{ height: "600px", width: "100%" }}
                  whenCreated={(map) => {
                    this.mapRef = map;
                  }}
                >
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <GeoJSON
                    key={
                      this.selectedCityGeoJSON
                        ? this.selectedCityGeoJSON.properties.id
                        : "default"
                    }
                    data={geoJsonData}
                    style={(feature) => {
                      const cityName = feature.properties.name.toLowerCase();
                      const selectedCityName = this.state.cidadeSelecionada
                        ? this.state.cidadeSelecionada.toLowerCase()
                        : "";
                      if (cityName === selectedCityName) {
                        return {
                          color: "blue",
                          weight: 2,
                          opacity: 1,
                          fillOpacity: 0.1,
                          fillColor: "blue",
                        };
                      } else {
                        return {
                          color: "black",
                          weight: 2,
                          opacity: 1,
                          fillOpacity: 0,
                          fillColor: "transparent",
                        };
                      }
                    }}
                  />
                  {this.state.ocorrencias?.map((ocorrencia, index) =>
                    ocorrencia.latitude && ocorrencia.longitude ? (
                      <Marker
                        key={index}
                        position={[ocorrencia.latitude, ocorrencia.longitude]}
                        style={{ height: "600px", width: "100%" }}
                        icon={L.icon({
                          iconUrl: icon,
                          iconSize: [25, 41],
                          iconAnchor: [12, 41],
                          popupAnchor: [0, -41],
                        })}
                      >
                        <Popup>
                          <div>
                            <p>
                              <strong>Visitas:</strong>{" "}
                              {ocorrencia.quantidade_reincidencias}
                            </p>
                            <p>
                              <strong>Nome:</strong> {ocorrencia.nome_vitima}
                            </p>
                            <p>
                              <strong>Endereço:</strong>{" "}
                              {ocorrencia.endereco_vitima}
                            </p>
                            <p>
                              <strong>Data do Fato:</strong>{" "}
                              {ocorrencia.data_fato}
                            </p>
                            <p>
                              <strong>Descrição:</strong>{" "}
                              {ocorrencia.descricao_outro}
                            </p>
                          </div>
                        </Popup>
                      </Marker>
                    ) : null
                  )}
                </MapContainer>
              </div>
            </Col>
          </Row>
          <Row className="backgroundBody text-center">
            <Col className="mt-2 " id="row1">
              <div className="chart-container">
                <h3>Idade do Agressor</h3>
                <hr className="hr-full-width" />
                <div className="chart-wrapper">
                  <Bar
                    data={{
                      labels: Object.keys(this.state.idadeAgressorData),
                      datasets: [
                        {
                          label: "Idade do Agressor ",
                          data: Object.values(this.state.idadeAgressorData),
                          backgroundColor: colors,
                        },
                      ],
                    }}
                    options={chartOptions}
                  />
                </div>
              </div>
            </Col>
            <Col className="mt-2 " id="row2">
              <div className="chart-container">
                <h3>Grau de Parentesco do Agressor</h3>
                <hr className="hr-full-width" />
                <div className="chart-wrapper">
                  <Pie
                    data={{
                      labels: Object.keys(this.state.grauParentescoData),
                      datasets: [
                        {
                          label: "Grau de Parentesco",
                          data: Object.values(this.state.grauParentescoData),
                          backgroundColor: colors,
                        },
                      ],
                    }}
                    options={chartOptions}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row className="backgroundBody text-center">
            <Col md={6} id="row3">
              <div className="chart-container">
                <h3>Idade da Vítima</h3>
                <hr className="hr-full-width" />
                <div className="chart-wrapper">
                  <PolarArea
                    data={{
                      labels: Object.keys(this.state.idadeVitimaData),
                      datasets: [
                        {
                          label: "Idade da Vítima",
                          data: Object.values(this.state.idadeVitimaData),
                          backgroundColor: colors,
                        },
                      ],
                    }}
                    options={chartOptions}
                  />
                </div>
              </div>
            </Col>
            <Col md={6} id="row4">
              <div className="chart-container">
                <h3>Tipos de Acionamento</h3>
                <hr className="hr-full-width" />
                <div className="chart-wrapper">
                  <Bar
                    data={{
                      labels: Object.keys(this.state.tipoAcionamentoData),
                      datasets: [
                        {
                          label: "Tipo de Acionamento",
                          data: Object.values(this.state.tipoAcionamentoData),
                          backgroundColor: colors,
                        },
                      ],
                    }}
                    options={chartOptions}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row className="backgroundBody text-center">
            <Col md={6} id="row5">
              <div className="chart-container">
                <h3>Situação da Ocorrencia</h3>
                <hr className="hr-full-width" />
                <div className="chart-wrapper">
                  <Bar
                    data={{
                      labels: Object.keys(this.state.situacaoOcorrenciaData),
                      datasets: [
                        {
                          label: "Ocorrencias",
                          data: Object.values(
                            this.state.situacaoOcorrenciaData
                          ),
                          backgroundColor: colors,
                        },
                      ],
                    }}
                    options={chartOptions}
                  />
                </div>
              </div>
            </Col>
            <Col md={6} id="row6">
              <div className="chart-container">
                <h3>Hora do Fato</h3>
                <hr className="hr-full-width" />
                <div className="chart-wrapper">
                  <Line
                    data={{
                      labels: Object.keys(this.state.horaFatoData),
                      datasets: [
                        {
                          label: "Hora do Fato",
                          data: Object.values(this.state.horaFatoData),
                          backgroundColor: colors,
                        },
                      ],
                    }}
                    options={chartOptions}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row className="backgroundBody text-center">
            <Col md={6} id="row7">
              <div className="chart-container">
                <h3>Conclusão da Ocorrência</h3>
                <hr className="hr-full-width" />
                <div className="chart-wrapper">
                  <Bar
                    data={{
                      labels: Object.keys(this.state.conclusaoOcorrenciaData),
                      datasets: [
                        {
                          label: "Conclusão",
                          data: Object.values(
                            this.state.conclusaoOcorrenciaData
                          ),
                          backgroundColor: colors,
                        },
                      ],
                    }}
                    options={chartOptions}
                  />
                </div>
              </div>
            </Col>
            <Col md={6} id="row8">
              <div className="chart-container">
                <h3>Procedimento Realizado na Delegacia Policial</h3>
                <hr className="hr-full-width" />
                <div className="chart-wrapper">
                  <Pie
                    data={{
                      labels: Object.keys(this.state.procedimentoRealizadoData),
                      datasets: [
                        {
                          label: "Procedimento",
                          data: Object.values(
                            this.state.procedimentoRealizadoData
                          ),
                          backgroundColor: colors,
                        },
                      ],
                    }}
                    options={chartOptions}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row className="backgroundBody text-center">
            <Col md={6} id="row9">
              <div className="chart-container">
                <h3>Orgãos que mais Atenderam</h3>
                <hr className="hr-full-width" />
                <div className="chart-wrapper">
                  <PolarArea
                    data={{
                      labels: Object.keys(this.state.orgaoData),
                      datasets: [
                        {
                          label: "Orgao",
                          data: Object.values(this.state.orgaoData),
                          backgroundColor: colors,
                        },
                      ],
                    }}
                    options={chartOptions}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <Row className="backgroundBody text-center mb-2 ">
          <Col className=" p-2 d-flex justify-content-end">
            <Button onClick={this.print} className="mr-2" variant="dark">
              <FontAwesomeIcon icon={faPrint} /> Imprimir
            </Button>
            <Button
              onClick={this.exportToCSV}
              className="mr-2"
              variant="success"
            >
              <FontAwesomeIcon icon={faFileCsv} /> Exportar para CSV
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default DashboardProMulher;
