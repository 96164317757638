import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Form as BSForm, FormGroup} from "react-bootstrap";
import Request from "../../../request";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Select from "react-select";
import { AppContext } from "../../../context";

class SetorForm extends React.Component {

    static contextType = AppContext;

    state = {
        orgaos: [],
        setores: [],
        filter: ''
    }

    componentDidMount(){

        Request('GET', this.context.config.BACKEND_URL + '/orgao/options', this.context.token)
        .then( res => {
            this.setState(state => ({orgaos: res.body}));
        })
        .catch( err => {
            this.context.addToast({title: 'Erro', text: 'Falha na recuperação da lista de órgãos: ' + err.toString()});
        });

        Request('GET', this.context.config.BACKEND_URL + '/setor/options', this.context.token)
        .then( res => {
            this.setState(state => ({setores: res.body }));
        })
        .catch( err => {
            this.context.addToast({title: 'Erro', text: 'Falha na recuperação da lista de setores: ' + err.toString()});
        });
    }

    save(values, callback){
        const method = this.props.setor.id? 'PUT' : 'POST';
        const url = this.context.config.BACKEND_URL + (this.props.setor.id? '/setor/'+this.props.setor.id : '/setor');

        Request(method, url, this.context.token)
        .send(values)
        .then( res => {
            this.context.addToast({titulo: "Successo", conteudo: "Registro atualizado com sucesso."});
            callback();
            if (this.props.onSave) this.props.onSave();
            this.context.closeModal();                                
        })
        .catch( err => {
            this.context.addToast({titulo: "Erro", conteudo: "Houve uma falha na gravação do registro."});
            callback();
        });

    }

    render(){

        return (
            <Formik
                initialValues={{ 
                    nome: this.props.setor.nome,
                    sigla: this.props.setor.sigla,
                    OrgaoId: this.props.setor.OrgaoId,
                    SuperiorId: this.props.setor.SuperiorId
                }}
                validate={values => {     
                    const errors = {};     
                    if (!values.nome) {    
                        errors.nome = 'Campo obrigatório';     
                    }
                    if (!values.sigla) {    
                        errors.sigla = 'Campo obrigatório';     
                    }
                    if (!values.OrgaoId) {    
                        errors.OrgaoId = 'Campo obrigatório';     
                    }    
                    return errors;     
                }}
                onSubmit={(values, { setSubmitting }) => {     
                    this.save(values, () => setSubmitting(false));
                }}     
            >     
                {({ isSubmitting , setFieldValue, values}) => {                                  
                    return (
                    <Form>
                        <FormGroup>
                            <BSForm.Label>Nome</BSForm.Label><ErrorMessage name="nome" component="span" className="text-danger small ml-2"/>
                            <Field type="text" name="nome" className="form-control" />                            
                        </FormGroup>
                        <FormGroup>
                            <BSForm.Label>Sigla</BSForm.Label><ErrorMessage name="sigla" component="span" className="text-danger small ml-2"/>
                            <Field type="text" name="sigla" className="form-control" />                            
                        </FormGroup>
                        <FormGroup>
                            <BSForm.Label>Órgão</BSForm.Label><ErrorMessage name="OrgaoId" component="span" className="text-danger small ml-2"/>
                            <Select 
                                name="OrgaoId" 
                                noOptionsMessage={()=> "Nada encontrado."}
                                placeholder="Pesquisar..."
                                options={this.state.orgaos?.filter(option => option.value === values.OrgaoId || this.context.usuario.Permissoes.some(p => p.nome === 'admin_setor_global') || option.value === this.props.usuario.OrgaoId )}
                                value={this.state.orgaos?.find(option => option.value === values.OrgaoId) ?? null}
                                onChange={option => {
                                    setFieldValue('OrgaoId', option? option.value : null);
                                    setFieldValue('SuperiorId', null);
                                }}
                            />                                  
                        </FormGroup>
                        <FormGroup>
                            <BSForm.Label>Superior</BSForm.Label>
                            <Select 
                                name="SuperiorId" 
                                noOptionsMessage={()=> "Nada encontrado."}
                                placeholder="Pesquisar..."
                                options={this.state.setores.filter(option => option.OrgaoId === values.OrgaoId && option.value !== this.props.setor.id)}
                                value={this.state.setores?.find(option => option.value === values.SuperiorId) ?? null}
                                onChange={option => setFieldValue('SuperiorId', option? option.value : null)}
                            />                                  
                        </FormGroup>
                        <FormGroup className="text-right">
                            <Button type="button" variant='secondary' onClick={() => this.context.closeModal()}>
                                <FontAwesomeIcon icon={faTimes} />&nbsp;
                                Fechar
                            </Button>
                            <Button type="submit" disabled={isSubmitting} variant='primary' className="ml-2">
                                <FontAwesomeIcon icon={faSave} />&nbsp;
                                Gravar Alterações
                            </Button>
                        </FormGroup>
                    </Form>
                    );
                }}
            </Formik>
        );
    }
}

export default SetorForm;