import React from 'react';
import { AppContext } from '../../../context';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Datatable from '../../Datatable';
import Request from '../../../request';


class DenunciaRegistradaList extends React.Component {

    static contextType = AppContext;

    onAction(action, callback) {
        switch (action.name){
            case "class":
                Request('GET', this.context.config.BACKEND_URL + '/denuncia/emRegistro/' + action.id, this.context.token)
                    .then(res => {
                        this.context.setContent("RegistrarDenunciaForm", {denuncia: res.body});
                    })
                    .catch(err => {
                        this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na recuperação do registro: " + err.toString() });
                    });
                break;
            default:
        }
    }


    render(){
        return (<>
            <h3><FontAwesomeIcon icon={faList}/> Lista de Denúncias</h3>
            <hr />
            <Datatable
                url={this.context.config.BACKEND_URL + '/denuncia/emRegistro'}
                autoSeach={true}
                useAdd={this.context.usuario.Permissoes.some(p => p.nome === 'disquedenuncia_emRegistro')}
                onError={(err) => this.context.addToast({titulo: "Erro", conteudo: err.toString()})}
                onAction={(action, update) => this.onAction(action, update)}
                onClickAdd={ () => this.context.setContent('RegistrarDenunciaForm', {denuncia: {}})}
            />
        </>);
    }
}


export default DenunciaRegistradaList;
