import React from 'react';
import ConfiguracaoForm from './ConfiguracaoForm';
import ModelList from '../ModelList';
import { AppContext } from '../../../context';

class ConfiguracaoList extends React.Component {    

    static contextType = AppContext;

    render(){
        return (
            <ModelList 
                url={this.context.config.BACKEND_URL + '/configuracao'}
                title="Lista de Configurações"
                modelName="configuracao"
                modelIcon="faCogs"
                modelForm={ConfiguracaoForm}
                useAdd={this.context.usuario.Permissoes.some(p => p.nome === 'admin_config_create')}
            />
        );
    }
}

export default ConfiguracaoList;