import React from "react";
import { Accordion, AccordionCollapse, AccordionToggle, Button, Card, Col, ListGroup, ListGroupItem, Row } from "react-bootstrap";
import Request from "../../../request";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faDrawPolygon, faEdit, faExpandArrowsAlt, faEye, faEyeSlash, faFastBackward, faLayerGroup, faMapMarkedAlt, faMapMarkerAlt, faProjectDiagram, faRedo, faSyncAlt, faTimes, faUndo } from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "../../../context";
import MapaManageMap from './MapaManageMap';
import MapaForm from "./MapaForm";
import CamadaForm from "./CamadaForm";
import ComponenteForm from "./ComponenteForm";
import ImportKML from "./ImportKML";
import * as Icons from '@fortawesome/free-solid-svg-icons';

class MapaManage extends React.Component {

    static contextType = AppContext;

    state = {
        mapa: {
            Camadas: []
        },
        heatId: null,
        historico: [],
        mapaAtual: -1,
        CamadaId: null,
        ComponenteId: null,
        online: false,
        ticket: null,
        pontos: [],
        latitude: -1.437972,
        longitude: -48.466901
    };

    socket = null;
    interval = null;

    undo = () => {

        this.setState(state => ({
            mapa: state.historico[state.mapaAtual - 1],
            mapaAtual: state.mapaAtual - 1
        }), () => {
            this.save(() => { }, false);
        });

    }

    redo = () => {
        this.setState(state => ({
            mapa: state.historico[state.mapaAtual + 1],
            mapaAtual: state.mapaAtual + 1
        }), () => {
            this.save(() => { }, false);
        });
    }

    savePonto(ponto, componente, camada, latitude, longitude) {
        this.setState(state => ({
            mapa: {
                ...state.mapa,
                Camadas: state.mapa.Camadas.map(c => c !== camada ? c : {
                    ...c,
                    Componentes: c.Componentes.map(c => c !== componente ? c : {
                        ...c,
                        Pontos: c.Pontos.map(p => p !== ponto ? p : {
                            ...p,
                            latitude, longitude
                        })
                    })
                })
            }
        }), () => this.save());
    }

    save(callback = () => { }, history = true) {

        const url = `${this.context.config.BACKEND_URL}/mapa/${this.props.MapaId}`;

        Request("PUT", url, this.context.token)
            .send(this.state.mapa)
            .then(res => {
                this.setState(state => {
                    const mapa = {
                        ...res.body,
                        Camadas: res.body.Camadas.map((camada, i) => ({
                            ...camada,
                            oculto: state.mapa.Camadas[i].oculto,
                            Componentes: camada.Componentes.map((componente, j) => ({
                                ...componente,
                                oculto: state.mapa.Camadas[i].Componentes[j].oculto,
                            }))
                        }))
                    };                    
                    return {
                        historico: history ? [
                            ...(state.mapaAtual < 0 ? [] : state.historico.slice(0, state.mapaAtual + 1)),
                            mapa
                        ] : state.historico,
                        mapaAtual: history ? state.mapaAtual + 1 : state.mapaAtual,
                        mapa
                    }
                }, callback);
            })
            .catch(err => {
                this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na gravação do registro." });
            });
    }

    update() {
        Request("GET", this.context.config.BACKEND_URL + "/mapa/" + this.props.MapaId, this.context.token)
            .then((res) => {
                this.setState(state => ({
                    mapa: res.body.mapa,
                    historico: [...(state.mapaAtual < 0 ? [] : state.historico.slice(0, state.mapaAtual + 1)), res.body.mapa],
                    mapaAtual: state.mapaAtual + 1,
                    loaded: true,
                    ticket: res.body.ticket,
                }), () => {

                    this.trigger();

                    if (this.socket) this.socket.close();

                    this.socket = new WebSocket(this.context.config.WEBSOCKET_URL);

                    this.socket.onerror = err => {
                        this.context.addToast({ titulo: "Erro", conteudo: "Falha na comunicação com o servidor: " + err.toString() });
                        this.setState({ online: false });
                    };

                    this.socket.onclose = err => {
                        this.setState({ online: false });
                    };

                    this.socket.onopen = () => {
                        this.socket.send(this.state.ticket);
                        this.context.addToast({ titulo: "Sucesso", conteudo: "Conectado ao servidor." });
                        this.setState({ online: true });
                    };

                    this.socket.onmessage = (msg) => {
                        try {
                            const mapa = JSON.parse(msg.data);
                            if (mapa.id === this.state.mapa.id) {
                                this.setState(state => ({
                                    mapa: {
                                        ...mapa,
                                        Camadas: mapa.Camadas.map((camada, i) => ({
                                            ...camada,
                                            oculto: state.mapa.Camadas.find(c => c.id === camada.id)?.oculto,
                                            Componentes: camada.Componentes.map((componente, j) => ({
                                                ...componente,
                                                oculto: state.mapa.Camadas.find(c => c.id === camada.id)?.Componentes.find(c => c.id === componente.id)?.oculto,
                                            }))
                                        }))
                                    }
                                }));
                            }
                        } catch (err) {
                            this.context.addToast({
                                titulo: "Erro",
                                conteudo: "Erro na atualização do mapa: " + msg.data,
                            });
                        }
                    };
                });
            })
            .catch((err) => {
                this.context.addToast({
                    titulo: "Erro",
                    conteudo: "Erro na inicialização do mapa: " + err.toString(),
                });
            });
    }

    componentDidMount() {
        this.update();
        this.interval = setInterval(() => {
            if (!this.state.online) this.update();
        }, 30000);
    }

    componentWillUnmount() {
        if (this.socket) {
            this.socket.close();
        }
        clearInterval(this.interval);
    }

    uploadKml() {
        this.context.openModal({
            titulo: "Importar Arquivo KML", conteudo: <ImportKML MapaId={this.props.MapaId} import={camada => {
                this.setState(state => ({
                    mapa: {
                        ...state.mapa,
                        Camadas: [
                            ...state.mapa.Camadas,
                            camada
                        ]
                    }
                }), () => {
                    this.save(() => {
                        this.setState(state => ({
                            CamadaId: state.mapa.Camadas[state.mapa.Camadas.length - 1].id,
                            ComponenteId: null
                        }), () => {
                            this.context.closeModal();
                        });
                    })
                });
            }} />
        });
    }

    downloadKml() {
        const url = this.context.config.BACKEND_URL + '/mapa/' + this.props.MapaId + '/download';

        Request("GET", url, this.context.token).responseType('blob')
            .then(res => {
                const url = URL.createObjectURL(res.body);
                
                const a = document.createElement('a');

                // Set the href and download attributes for the anchor element
                // You can optionally set other attributes like `title`, etc
                // Especially, if the anchor element will be attached to the DOM
                a.href = url;
                a.download = this.state.mapa.nome + ".kml";

                // Click handler that releases the object URL after the element has been clicked
                // This is required for one-off downloads of the blob content
                const clickHandler = () => {
                    setTimeout(() => {
                        URL.revokeObjectURL(url);
                        a.removeEventListener('click', clickHandler);
                    }, 150);
                };

                // Add the click event listener on the anchor element
                // Comment out this line if you don't want a one-off download of the blob content
                a.addEventListener('click', clickHandler, false);

                // Programmatically trigger a click on the anchor element
                // Useful if you want the download to happen automatically
                // Without attaching the anchor element to the DOM
                // Comment out this line if you don't want an automatic download of the blob content
                a.click();
            })
            .catch(err => {
                this.context.addToast({ titulo: "Erro", conteudo: "Erro no download: " + err.toString() });
            })

    }

    addCamada() {

        const saveCamada = (camada, callback) => {
            this.setState(state => ({
                mapa: {
                    ...state.mapa,
                    Camadas: [
                        ...state.mapa.Camadas,
                        camada
                    ]
                }
            }), () => {
                this.save(() => {
                    this.setState(state => ({
                        CamadaId: state.mapa.Camadas[state.mapa.Camadas.length - 1].id,
                        ComponenteId: null
                    }), () => {
                        callback();
                        this.context.closeModal();
                    });
                })
            });
        };

        const camada = {
            id: null,
            MapaId: this.props.MapaId,
            nome: "",
            descricao: "",
            oculto: false,
            Componentes: []
        };

        this.context.openModal({ titulo: "Adicionar Camada", conteudo: <CamadaForm camada={camada} onSave={(camada, callback) => saveCamada(camada, callback)} /> });
    }

    addComponente(CamadaId, tipo) {

        const saveComponente = (componente, callback) => {
            this.setState(state => ({
                mapa: {
                    ...state.mapa,
                    Camadas: state.mapa.Camadas.map(camada => camada.id !== CamadaId ? camada : {
                        ...camada,
                        Componentes: [
                            ...camada.Componentes,
                            {
                                ...componente,
                                Pontos: [
                                    {
                                        latitude: this.state.latitude,
                                        longitude: this.state.longitude
                                    }
                                ]
                            }
                        ]
                    })
                }
            }), () => {
                this.save(() => {
                    this.setState(state => {
                        const camada = state.mapa.Camadas.find(c => c.id === CamadaId);
                        return {
                            ComponenteId: camada.Componentes[camada.Componentes.length - 1].id
                        };
                    }, () => {
                        callback();
                        this.context.closeModal();
                    });
                });
            });
        };

        const componente = {
            id: null,
            CamadaId,
            tipo,
            nome: "",
            descricao: "",
            oculto: false,
            Pontos: []
        };

        this.context.openModal({ titulo: `Adicionar Componente ${tipo}`, conteudo: <ComponenteForm componente={componente} onSave={(componente, callback) => saveComponente(componente, callback)} /> });
    }

    toggleCamada(CamadaId) {
        this.setState(state => ({
            mapa: {
                ...state.mapa,
                Camadas: state.mapa.Camadas.map(camada => camada.id !== CamadaId ? camada : {
                    ...camada,
                    oculto: !camada.oculto,
                    Componentes: camada.Componentes.map(componente => ({
                        ...componente,
                        oculto: !camada.oculto
                    }))
                })
            }
        }));
    }

    deleteCamada(CamadaId) {
        window.confirm("Deseja excluir esta camada?") && this.setState(state => ({
            CamadaId: null,
            ComponenteId: null,
            mapa: {
                ...state.mapa,
                Camadas: state.mapa.Camadas.filter(camada => camada.id !== CamadaId)
            }
        }), () => this.save(() => {

        }));
    }

    editCamada(CamadaId) {

        const saveCamada = (camada, callback) => {
            this.setState(state => ({
                mapa: {
                    ...state.mapa,
                    Camadas: state.mapa.Camadas.map(c => c.id !== CamadaId ? c : camada)
                }
            }), () => {
                this.save(() => {
                    callback();
                    this.context.closeModal();
                });
            });
        };

        const camada = this.state.mapa.Camadas.find(camada => camada.id === CamadaId);
        this.setState({ CamadaId }, () => {
            this.context.openModal({ titulo: "Editar Camada", conteudo: <CamadaForm camada={camada} onSave={(camada, callback) => saveCamada(camada, callback)} /> });
        });
    }

    toggleComponente(ComponenteId, CamadaId) {
        this.setState(state => ({
            mapa: {
                ...state.mapa,
                Camadas: state.mapa.Camadas.map(camada => camada.id !== CamadaId ? camada : {
                    ...camada,
                    oculto: camada.Componentes.every(componente => (componente.id === ComponenteId && !componente.oculto) || (componente.id !== ComponenteId && componente.oculto)),
                    Componentes: camada.Componentes.map(componente => componente.id !== ComponenteId ? componente : {
                        ...componente,
                        oculto: !componente.oculto
                    })
                })
            }
        }));
    }

    deleteComponente(ComponenteId, CamadaId) {
        window.confirm("Deseja excluir este componente?") && this.setState(state => ({
            ComponenteId: null,
            mapa: {
                ...state.mapa,
                Camadas: state.mapa.Camadas.map(camada => camada.id !== CamadaId ? camada : {
                    ...camada,
                    Componentes: camada.Componentes.filter(componente => componente.id !== ComponenteId)
                })
            }
        }), () => this.save(() => {

        }));
    }

    editComponente(ComponenteId, CamadaId) {

        const saveComponente = (componente, callback) => {
            this.setState(state => ({
                mapa: {
                    ...state.mapa,
                    Camadas: state.mapa.Camadas.map(camada => camada.id !== CamadaId ? camada : {
                        ...camada,
                        Componentes: camada.Componentes.map(c => c.id !== ComponenteId ? c : componente)
                    })
                }
            }), () => {
                this.save(() => {
                    callback();
                    this.context.closeModal();
                });
            });
        };

        const componente = this.state.mapa.Camadas.find(camada => camada.id === CamadaId).Componentes.find(componente => componente.id === ComponenteId);
        this.setState({ ComponenteId }, () => {
            this.context.openModal({ titulo: "Editar Componente", conteudo: <ComponenteForm componente={componente} onSave={(componente, callback) => saveComponente(componente, callback)} /> });
        });
    }

    delPonto(ponto, componente, camada) {
        window.confirm("Deseja excluir este ponto?") && this.setState(state => ({
            mapa: {
                ...state.mapa,
                Camadas: state.mapa.Camadas.map(c => c !== camada ? c : {
                    ...c,
                    Componentes: c.Componentes.filter(c => c !== componente || c.tipo !== "Ponto").map(c => c !== componente ? c : {
                        ...c,
                        Pontos: c.Pontos.filter(p => p !== ponto)
                    })
                })
            }
        }), () => this.save());
    }

    addPonto(latitude, longitude) {

        if (!this.context.isAuthorized("mapas_mapa_edit")) return;

        const camada = this.state.mapa.Camadas.find(c => c.id === this.state.CamadaId && !c.oculto);
        const componente = camada?.Componentes.find(c => c.id === this.state.ComponenteId && !c.oculto && (c.tipo !== "Ponto" || !c.Pontos.length));

        if (componente) {
            this.setState(state => ({
                mapa: {
                    ...state.mapa,
                    Camadas: state.mapa.Camadas.map(c => c !== camada ? c : {
                        ...c,
                        Componentes: c.Componentes.map(c => c !== componente ? c : {
                            ...c,
                            Pontos: [
                                ...c.Pontos,
                                {
                                    ComponenteId: componente.id,
                                    latitude, longitude,
                                }
                            ]
                        })
                    })
                }
            }), () => this.save());
        }
    }

    trigger(componente = null) {

        var pontos = [];

        if (!componente) {
            this.state.mapa.Camadas.filter(c => (!this.state.CamadaId || c.id === this.state.CamadaId) && !c.oculto).forEach(camada =>
                camada.Componentes.filter(c => (!this.state.ComponenteId || c.id === this.state.ComponenteId) && !c.oculto).forEach(componente =>
                    componente.Pontos.forEach(ponto => pontos.push(ponto))));
        } else {
            pontos = componente.Pontos;
        }

        this.setState(({ pontos }));
    }

    select(componente, camada, callback) {
        this.setState({
            CamadaId: camada.id,
            ComponenteId: componente.id
        }, callback);
    }

    toggleHeat(componente) {
        this.setState({ heatId: this.state.heatId === componente.id ? null : componente.id });
    }

    render() {

        const icons = {
            "Caminho": faProjectDiagram,
            "Área": faDrawPolygon
        };

        const isEditor = this.context.isAuthorized("mapas_mapa_edit");

        const camadas = this.state.mapa.Camadas.filter(c => !c.oculto && c.Componentes.length > 0);

        return (
            <>
                <Row>
                    <Col>
                        <h3 className="d-flex">
                            <FontAwesomeIcon icon={faMapMarkedAlt} />
                            &nbsp;{this.state.mapa.nome}
                            <div className="ml-auto d-print-none">
                                <Button
                                    variant="secondary"
                                    className="mr-2"
                                    onClick={() => this.context.setContent("MapaList", {}, "Mapas Colaborativos", false)}
                                >
                                    <FontAwesomeIcon icon={faFastBackward} /> Voltar
                                </Button>
                                <Button
                                    variant="info"
                                    className="mr-2"
                                    onClick={() => this.context.openModal({ titulo: "Editar Mapa", size: "xl", conteudo: <MapaForm mapa={this.state.mapa} /> })}
                                >
                                    <FontAwesomeIcon icon={faEdit} /> Editar
                                </Button>
                                {(this.state.online &&
                                    <Button
                                        variant="success"
                                        onClick={() => this.update()}
                                    >
                                        <FontAwesomeIcon icon={faSyncAlt} /> Online
                                    </Button>) ||
                                    <Button
                                        variant="danger"
                                        onClick={() => this.update()}
                                    >
                                        <FontAwesomeIcon icon={faSyncAlt} /> Offline
                                    </Button>
                                }
                            </div>
                        </h3>
                        <hr />
                    </Col>
                </Row>
                <Row>
                    <Col className="d-none d-print-block">
                        <div dangerouslySetInnerHTML={{ __html: this.state.mapa.descricao }} />
                    </Col>
                </Row>
                <Row className="mb-3 d-print-none">
                    <Col className="d-flex">
                        {isEditor &&
                            <Button size="sm" variant="outline-dark" className="mr-4" onClick={() => this.addCamada()} title="Adicionar Camada">
                                <FontAwesomeIcon icon={faLayerGroup} />
                            </Button>}

                        <Button size="sm" variant="outline-dark" className="mr-4" title="Imprimir" onClick={() => window.print()}>
                            <FontAwesomeIcon icon={Icons.faPrint} />
                        </Button>

                        <Button size="sm" variant="outline-dark" className="mr-4" title="Enquadrar" onClick={() => this.setState({ CamadaId: null, ComponenteId: null }, () => this.trigger())}>
                            <FontAwesomeIcon icon={faExpandArrowsAlt} />
                        </Button>

                        {isEditor &&
                            <Button size="sm" variant="outline-dark" className="mr-2" title="Desfazer" onClick={() => this.undo()} disabled={this.state.mapaAtual === 0}>
                                <FontAwesomeIcon icon={faUndo} />
                            </Button>}
                        {isEditor &&
                            <Button size="sm" variant="outline-dark" className="mr-4" title="Refazer" onClick={() => this.redo()} disabled={this.state.historico.length - 1 === this.state.mapaAtual}>
                                <FontAwesomeIcon icon={faRedo} />
                            </Button>}

                        {isEditor && this.state.CamadaId && !this.state.mapa.Camadas.find(c => c.id === this.state.CamadaId)?.oculto &&
                            <div>
                                <Button size="sm" variant="outline-dark" className="mr-2" title="Adicionar Ponto" onClick={() => this.addComponente(this.state.CamadaId, "Ponto")}>
                                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                                </Button>
                                <Button size="sm" variant="outline-dark" className="mr-2" title="Adicionar Nuvem" onClick={() => this.addComponente(this.state.CamadaId, "Nuvem")}>
                                    <FontAwesomeIcon icon={Icons.faThumbtack} />
                                </Button>
                                <Button size="sm" variant="outline-dark" className="mr-2" title="Adicionar Caminho" onClick={() => this.addComponente(this.state.CamadaId, "Caminho")}>
                                    <FontAwesomeIcon icon={faProjectDiagram} />
                                </Button>
                                <Button size="sm" variant="outline-dark" className="mr-2" title="Adicionar Área" onClick={() => this.addComponente(this.state.CamadaId, "Área")}>
                                    <FontAwesomeIcon icon={faDrawPolygon} />
                                </Button>
                            </div>
                        }
                        {isEditor &&
                            <div>
                                <Button size="sm" variant="outline-dark" className="mr-2" title="Importar KML" onClick={() => this.uploadKml()}>
                                    <FontAwesomeIcon icon={Icons.faUpload} />
                                </Button>
                                <Button size="sm" variant="outline-dark" title="Exportar KML" onClick={() => this.downloadKml()}>
                                    <FontAwesomeIcon icon={Icons.faDownload} />
                                </Button>
                            </div>
                        }
                    </Col>
                </Row>
                <Row className="mb-3 flex-grow-1">
                    <Col md={9} className="mb-3">
                        <MapaManageMap
                            mapa={this.state.mapa}
                            heatId={this.state.heatId}
                            pontos={this.state.pontos}
                            setLatLng={(latitude, longitude) => this.setState({ latitude, longitude })}
                            savePonto={(ponto, componente, camada, latitude, longitude) => this.savePonto(ponto, componente, camada, latitude, longitude)}
                            delPonto={(ponto, componente, camada) => this.delPonto(ponto, componente, camada)}
                            delComponente={(componente, camada) => this.deleteComponente(componente.id, camada.id)}
                            addPonto={(latitude, longitude) => this.addPonto(latitude, longitude)}
                            editComponente={(componente, camada) => this.editComponente(componente.id, camada.id)}
                            select={(componente, camada, callback) => this.select(componente, camada, callback)}
                        />
                    </Col>
                    <Col md={3} className="d-print-none">
                        <Accordion className="small">
                            {this.state.mapa.Camadas.map((camada, key) =>
                                <Card key={key}>
                                    <AccordionToggle as={Card.Header} eventKey={key + 1} className="d-flex justify-content-between align-items-center" role="button" onClick={() => this.setState(state => ({ CamadaId: camada.id, ComponenteId: state.CamadaId === camada.id ? state.ComponenteId : null }))}>
                                        <div className={"text-truncate" + (camada.id === this.state.CamadaId ? " font-weight-bolder" : (camada.oculto ? " text-muted" : ""))} title={camada.nome}>
                                            {camada.nome}
                                        </div>
                                        <div className="d-flex">
                                            <FontAwesomeIcon icon={camada.oculto ? faEyeSlash : faEye} className="ml-2" title="Mostrar/Ocultar" onClick={event => { event.stopPropagation(); this.toggleCamada(camada.id) }} />
                                            {isEditor && <FontAwesomeIcon icon={faCog} className="ml-2" title="Opções" onClick={event => { event.stopPropagation(); this.editCamada(camada.id) }} />}
                                            {isEditor && <FontAwesomeIcon icon={faTimes} className="ml-2" title="Excluir" onClick={event => { event.stopPropagation(); this.deleteCamada(camada.id) }} />}
                                        </div>
                                    </AccordionToggle>
                                    <AccordionCollapse eventKey={key + 1} style={{ maxHeight: 240, overflowY: 'scroll' }}>
                                        <ListGroup variant="flush">
                                            {camada.Componentes.map(((componente, key) =>
                                                <ListGroupItem key={key} className="d-flex justify-content-between align-items-center" role="button" onClick={() => this.setState({ ComponenteId: componente.id, CamadaId: camada.id }, () => this.trigger(componente))}>
                                                    <div className={"text-truncate" + (componente.id === this.state.ComponenteId ? " font-weight-bolder" : (componente.oculto || camada.oculto ? " text-muted" : ""))} title={componente.nome}>
                                                        <FontAwesomeIcon icon={componente.tipo === "Ponto" || componente.tipo === "Nuvem" ? (componente.icone ? Icons[componente.icone] : faMapMarkerAlt) : icons[componente.tipo]} className="mr-2" style={{ color: componente.linha }} />
                                                        {componente.nome}
                                                    </div>
                                                    <div className="d-flex">
                                                        {componente.tipo === "Nuvem" &&
                                                            <FontAwesomeIcon icon={this.state.heatId === componente.id ? Icons.faToggleOn : Icons.faToggleOff} className="ml-2" title="Mapa de Calor" onClick={event => { event.stopPropagation(); this.toggleHeat(componente) }} />
                                                        }
                                                        <FontAwesomeIcon icon={componente.oculto ? faEyeSlash : faEye} className="ml-2" title="Mostrar/Ocultar" onClick={event => { event.stopPropagation(); this.toggleComponente(componente.id, camada.id) }} />
                                                        {isEditor && <FontAwesomeIcon icon={faCog} className="ml-2" title="Opções" onClick={event => { event.stopPropagation(); this.editComponente(componente.id, camada.id) }} />}
                                                        {isEditor && <FontAwesomeIcon icon={faTimes} className="ml-2" title="Excluir" onClick={event => { event.stopPropagation(); this.deleteComponente(componente.id, camada.id) }} />}
                                                    </div>
                                                </ListGroupItem>))}
                                        </ListGroup>
                                    </AccordionCollapse>
                                </Card>
                            )}
                        </Accordion>
                    </Col>
                    {camadas.length > 0 &&
                        <Col md={12} className="d-none d-print-block mt-3 map-legend">
                            <h3>
                                <FontAwesomeIcon icon={faLayerGroup} /> Camadas</h3>
                            <hr />
                            {camadas.map((camada, camadaKey) =>
                                <div className="mt-3">
                                    <h4>{camada.nome}</h4>
                                    <div dangerouslySetInnerHTML={{ __html: camada.descricao }} />
                                    <ListGroup>
                                        {camada.Componentes.map(((componente, componenteKey) =>
                                            <ListGroupItem key={camadaKey * 1000 + componenteKey}>
                                                <b><FontAwesomeIcon icon={componente.tipo === "Ponto" || componente.tipo === "Nuvem" ? (componente.icone ? Icons[componente.icone] : faMapMarkerAlt) : icons[componente.tipo]} className="mr-2" style={{ color: componente.linha }} /> {componente.nome}</b>
                                                <div dangerouslySetInnerHTML={{ __html: componente.descricao }} />
                                            </ListGroupItem>
                                        ))}
                                    </ListGroup>
                                </div>
                            )}
                        </Col>}
                </Row>
            </>
        );
    }
}

export default MapaManage;
