import { faBackward, faBullhorn, faChartBar, faInfoCircle, faPencilAlt, faTable, faTachometerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Card, Col, Container, Image, Row } from 'react-bootstrap';
import { Button } from "react-bootstrap";
import Request from "../../../request";
import moment from 'moment';
import Datatable from '../../Datatable';
import { AppContext } from '../../../context';

const formatter = new Intl.NumberFormat('pt-Br', {
    minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
});

class OperacaoDashboard extends React.Component {

    static contextType = AppContext;

    state = {
        imagens: [],
        size: 50,
        dashboard: this.props.dashboard
    }

    onAcaoAction(action, update) {
        switch (action.name) {
            case "print":
                this.context.setContent('AcaoPrint', { acao_id: action.id });
                break;
            case "edit":
                Request("GET", this.context.config.BACKEND_URL + '/acao/' + action.id, this.context.token)
                    .then(res => {
                        this.context.setContent('AcaoForm', { operacao: this.state.dashboard.operacao, acao: res.body });
                    })
                    .catch(err => {
                        this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na recuperação do registro: " + err.toString() });
                    });
                break;
            case "relatorio":
                Request("GET", this.context.config.BACKEND_URL + '/acao/relatorio/' + action.id, this.context.token)
                    .then(res => {
                        this.context.setContent('AcaoRelatorio', { acao: res.body.acao, recursosDisponiveis: res.body.recursosDisponiveis });
                    })
                    .catch(err => {
                        this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na recuperação do registro: " + err.toString() });
                    });
                break;
            case "delete":
                if (window.confirm("Deseja realmente excluir esta ação?"))
                    Request("DELETE", this.context.config.BACKEND_URL + '/acao/' + action.id, this.context.token)
                        .then(res => {
                            this.context.addToast({ titulo: "Sucesso", conteudo: "Registro excluído com sucesso!" });
                            Request('GET', this.context.config.BACKEND_URL + '/operacao/dashboard/' + this.state.dashboard.operacao.id)
                                .then(res => {
                                    this.context.setContent("OperacaoDashboard", { dashboard: res.body });
                                })
                                .catch(err => {
                                    this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na recuperação do registro: " + err.toString() });
                                })
                            update();
                        })
                        .catch(err => {
                            this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na recuperação do registro: " + err.toString() });
                        });
                break;
            default:
                break;
        }
    }

    editOperacao(){
        Request("GET", this.context.config.BACKEND_URL + '/operacao/' + this.props.dashboard.operacao.id, this.context.token)
        .then(res => {
            this.context.setContent('OperacaoForm', { operacao: res.body });
        })
        .catch(err => {
            this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na recuperação do registro: " + err.toString() });
        });
    }

    openResults(){
        Request("GET", this.context.config.BACKEND_URL + '/operacao/resultado/' + this.props.dashboard.operacao.id, this.context.token)
        .then(res => {
            this.context.setContent('OperacaoResultado', { dashboard: res.body });
        })
        .catch(err => {
            this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na recuperação do registro: " + err.toString() });
        });
    }

    render() {
        const variants = ['primary', 'danger', 'warning', 'success', 'info'];
        return <Container fluid className='mb-5'>
            <h3 className="d-flex justify-content-between">
                <div>
                    <FontAwesomeIcon icon={faTachometerAlt} /> {this.state.dashboard.operacao.nome}
                </div>
                <div>
                    <Button variant="secondary" className="mr-2" onClick={() => this.context.setContent('OperacaoList')}>
                        <FontAwesomeIcon icon={faBackward} /> Voltar para Lista de Operações
                    </Button>
                    <Button variant="info" className="mr-2" onClick={() => this.openResults()}>
                        <FontAwesomeIcon icon={faChartBar} /> Resultados
                    </Button>
                    {this.context.usuario.Permissoes.some(p => p.nome === 'operacoes_operacao_update') ?
                        <Button variant="primary" onClick={() => this.editOperacao()}>
                            <FontAwesomeIcon icon={faPencilAlt} /> Editar
                        </Button> : null}
                </div>
            </h3>
            <hr />
            <Row className="mt-4">
                <Col>
                    <Card>
                        <Card.Header>
                            <FontAwesomeIcon icon={faTable} /> Indicadores
                        </Card.Header>
                        <Card.Body>
                            <div className="d-flex justify-content-around align-items-stretch flex-wrap">
                                {this.state.size > 50 ? this.state.imagens.map((imagem, key) =>
                                    <Image key={key} src={imagem.base64} className="d-block m-3" style={{ height: this.state.size }} />
                                ) : null}
                            </div>
                            <Row className="d-flex">
                                {this.state.dashboard.totalIndicadores.map((totalIndicador, key) =>
                                    <Col md={key < (this.state.dashboard.totalIndicadores.length - this.state.dashboard.totalIndicadores.length % 4) ? 3 : 12 / (this.state.dashboard.totalIndicadores.length % 4)}>
                                        <Card key={key} bg={variants[key % variants.length]} className="text-white mb-3" style={{ height: "150px" }}>
                                            <Card.Header>{totalIndicador.label}</Card.Header>
                                            <Card.Body className="d-flex align-items-end justify-content-end">
                                                {formatter.format(totalIndicador.total).length < 10 ? <h1>{formatter.format(totalIndicador.total)}</h1> : <h3>{formatter.format(totalIndicador.total)}</h3>}
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                )}
                            </Row>
                        </Card.Body>
                        <Card.Footer><b>Última atualização:</b> {moment(this.state.dashboard.ultimaAtualizacao).format("DD/MM/YYYY HH:mm:ss")}</Card.Footer>
                    </Card>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col>
                    <Card>
                        <Card.Header>
                            <div><FontAwesomeIcon icon={faInfoCircle} /> Informações</div>
                        </Card.Header>
                        <Card.Body>
                            <b>Status</b>
                            <ul>
                                <li>{this.state.dashboard.operacao.execucao}</li>
                            </ul>
                            <b>Início e Término</b>
                            <ul>
                                <li>{moment(this.state.dashboard.operacao.data_inicial).utcOffset(0).format("DD/MM/YYYY HH:mm")}</li>
                                <li>{moment(this.state.dashboard.operacao.data_final).utcOffset(0).format("DD/MM/YYYY HH:mm")}</li>
                            </ul>
                            <b>Resenha</b>
                            <Card bg="light" className="p-3" dangerouslySetInnerHTML={{ __html: this.state.dashboard.operacao.resenha }} />
                        </Card.Body>
                    </Card>
                </Col>                
            </Row>
            <Row className="mt-4">
                <Col>
                    <Card>
                        <Card.Header>
                            <FontAwesomeIcon icon={faBullhorn} /> Ações
                        </Card.Header>
                        <Card.Body>
                            {!['Agendada', 'Em Execução'].find(status => status === this.state.dashboard.operacao.execucao) ?
                                <span className="text-danger">A Operação deve estar Agendada ou Em Execução para a adição de novas ações.</span> :
                                !this.context.usuario.Permissoes.some(p => p.nome === 'operacoes_acao_create') ?
                                    <span className="text-danger">Usuário sem permissão para criar novas ações.</span> : null}
                            <Datatable
                                url={this.context.config.BACKEND_URL + '/acao/operacao/' + this.state.dashboard.operacao.id}
                                useAdd={this.context.usuario.Permissoes.some(p => p.nome === 'operacoes_acao_create') && ['Agendada', 'Em Execução'].find(status => status === this.state.dashboard.operacao.execucao)}
                                onClickAdd={() => this.context.setContent('AcaoForm', { operacao: this.state.dashboard.operacao, acao: { OperacaoId: this.state.dashboard.operacao.id, Participantes: [], Cidades: [], Lider: {}, RispId: null } })}
                                onError={(err) => this.context.addToast({ titulo: "Erro", conteudo: err.toString() })}
                                onAction={(action, update) => this.onAcaoAction(action, update)}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>;
    }
}

export default OperacaoDashboard;
