import React, { useEffect } from "react";

import {
  Circle,
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  Polyline,
  useMap,
} from "react-leaflet";

import L from 'leaflet';
import moment from "moment";

const MapController = props => {

  const map = useMap();

  useEffect(() => {

    const length = props.alerta.Localizacoes.length;

    if (!length) map.setView([props.alerta.latitude, props.alerta.longitude]);
    else if (length === 1) map.setView([props.alerta.Localizacoes[0].latitude, props.alerta.Localizacoes[0].longitude]);
    else {
      const corner1 = L.latLng(
        props.alerta.Localizacoes.map(localizacao => localizacao.latitude).reduce((a, b) => Math.max(a, b), props.alerta.Localizacoes[0].latitude),
        props.alerta.Localizacoes.map(localizacao => localizacao.longitude).reduce((a, b) => Math.min(a, b), props.alerta.Localizacoes[0].longitude)
      );
      const corner2 = L.latLng(
        props.alerta.Localizacoes.map(localizacao => localizacao.latitude).reduce((a, b) => Math.min(a, b), props.alerta.Localizacoes[0].latitude),
        props.alerta.Localizacoes.map(localizacao => localizacao.longitude).reduce((a, b) => Math.max(a, b), props.alerta.Localizacoes[0].longitude)
      );

      const bounds = L.latLngBounds(corner1, corner2);

      map.flyToBounds(bounds);
    }
  }, [props, map]);

  return null;
}


class AlertaModalMap extends React.Component {

  state = {
    circle: false,
  }

  render() {  
      return (
          <MapContainer
            center={[this.props.alerta.latitude, this.props.alerta.longitude]}
            zoom={13}
            scrollWheelZoom={true}
            style={{ height: "100%", width: "100%" }}
          >
            <TileLayer url="http://{s}.tile.osm.org/{z}/{x}/{y}.png" />
            <MapController alerta={this.props.alerta} />
            {this.props.alerta.Localizacoes.map((localizacao, key) => (
            <Marker
              eventHandlers={{
                click: () => {
                  this.setState({ circle: { latitude: localizacao.latitude, longitude: localizacao.longitude, radius: localizacao.precisao } })
                },
              }}
              position={[localizacao.latitude, localizacao.longitude]}

              key={key}
            >
              <Popup autoPan={false}>
                <h3>{moment(localizacao.datahora).format("DD/MM/YYYY HH:mm")}</h3>
                <b>Coordenadas: </b>
                Lat.: {localizacao.latitude}, Lng: {localizacao.longitude}<br />
                <b>Direção (Norte-Leste): </b>
                {localizacao.sentido ?? 0}&deg; <br />
                <b>Velocidade: </b>
                {localizacao.velocidade ?? 0} m/s<br />
              </Popup>
            </Marker>
          ))}
            <Polyline positions={this.props.alerta.Localizacoes.map(localizacao => [localizacao.latitude, localizacao.longitude])} />
            {this.state.circle && <Circle center={[this.state.circle.latitude, this.state.circle.longitude]} radius={this.state.circle.radius} />}
          </MapContainer>      
      );
    }
  }

export default AlertaModalMap;
