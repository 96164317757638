import React from 'react';
import ModelList from '../ModelList';
import { AppContext } from '../../../context';
import BoletimMaterialForm from './BoletimMaterialForm';

class BoletimList extends React.Component {   

    static contextType = AppContext;


    render(){
        return (
            <ModelList 
                url={this.context.config.BACKEND_URL + '/materialBoletim'}
                title="Lista de Materiais de Apreensão"
                modelName="material"
                modelIcon="faCubes"
                modelForm={BoletimMaterialForm}
                modalSize="lg"
                useAdd={true}
            />
        );
    }
}

export default BoletimList;