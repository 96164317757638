import React from 'react';
import PublicContratoForm from './PublicContratoForm';
import ModelList from '../ModelList';
import { AppContext } from '../../../context';

class PublicContratoList extends React.Component {    

    static contextType = AppContext;

    render(){
        return (
            <ModelList 
                url={this.context.config.BACKEND_URL + '/consulta/contrato'}
                title="Lista de Contratos"
                modelName="contrato"
                modelIcon="faFileSignature"
                modelForm={PublicContratoForm}
                modalSize="xl"
                useAdd={false}
            />
        );
    }
}

export default PublicContratoList;

