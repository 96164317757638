import React from 'react';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppContext } from '../../../context';

class ContratosPage extends React.Component {        
    static contextType = AppContext;

    render(){
        return (<>
            <h3><FontAwesomeIcon icon={faHome}/> Página Inicial</h3>
            <hr />                
        </>);
    }
}

export default ContratosPage;
