
import { faEye, faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Card, Col, Container, FormControl, ListGroup, ListGroupItem, Row } from "react-bootstrap";
import { AppContext } from "../../../context";
import Request from "../../../request";
import Select from 'react-select';
import RelatorioView from "./RelatorioView";

class RelatorioForm extends React.Component {

    static contextType = AppContext;

    state = {
        modulos: [],
        tabelas: [],
        campos: [],
        campo: null,
        nome: this.props.relatorio.nome,
        modulo: this.props.relatorio.modulo,
        tabela: this.props.relatorio.tabela,
        filtros: this.props.relatorio.Filtros ?? [],
        ordens: this.props.relatorio.Ordens ?? [],
        colunas: this.props.relatorio.Colunas ?? [],
        resultados: this.props.relatorio.Resultados ?? []
    }

    componentDidMount() {
        Request("GET", this.context.config.BACKEND_URL + "/modulo/options", this.context.token)
            .then(res => {
                this.setState({ modulos: res.body })
            }).catch(err => {
                this.context.addToast({ titulo: "Erro", conteudo: "Erro na recuperação dos módulos: " + err.toString() });
            });

        if (this.state.modulo) {
            Request("GET", this.context.config.BACKEND_URL + "/modulo/" + this.state.modulo + "/tabela/options", this.context.token)
                .then(res => {
                    this.setState({ tabelas: res.body })
                }).catch(err => {
                    this.context.addToast({ titulo: "Erro", conteudo: "Erro na recuperação das tabelas: " + err.toString() });
                });
            if (this.state.tabela) {
                Request("GET", this.context.config.BACKEND_URL + `/modulo/${this.state.modulo}/tabela/${this.state.tabela}/campo/options`, this.context.token)
                    .then(res => {
                        this.setState({ campos: res.body.map(campo => ({ nome: campo.value })) })
                    }).catch(err => {
                        this.context.addToast({ titulo: "Erro", conteudo: "Erro na recuperação dos campos: " + err.toString() });
                    });
            }
        }
    }

    updateTabelas() {
        Request("GET", this.context.config.BACKEND_URL + `/modulo/${this.state.modulo}/tabela/options`, this.context.token)
            .then(res => {
                this.setState({ tabelas: res.body, campos: [], filtros: [], colunas: [], resultados: [] })
            }).catch(err => {
                this.context.addToast({ titulo: "Erro", conteudo: "Erro na recuperação das tabelas: " + err.toString() });
            });
    }

    updateCampos() {
        Request("GET", this.context.config.BACKEND_URL + `/modulo/${this.state.modulo}/tabela/${this.state.tabela}/campo/options`, this.context.token)
            .then(res => {
                this.setState({ campos: res.body.map(campo => ({ nome: campo.value })), filtros: [], colunas: [], resultados: [] })
            }).catch(err => {
                this.context.addToast({ titulo: "Erro", conteudo: "Erro na recuperação dos campos: " + err.toString() });
            });
    }

    view() {
        this.save(relatorio => {
            this.context.openModal({ titulo: "Resultado", conteudo: <RelatorioView RelatorioId={relatorio.id} onSave={() => this.props.onSave()}/>, size: "xl" });
        });
    }

    save(callback = () => { }) {

        if (!this.state.colunas.length && !this.state.resultados.length) {
            return window.alert("Inclua uma coluna ou resultado");
        }

        const method = this.props.relatorio.id ? "PUT" : "POST";
        const url =
            this.context.config.BACKEND_URL +
            "/relatorioModulo" +
            (this.props.relatorio.id ? "/" + this.props.relatorio.id : "");

        Request(method, url, this.context.token)
            .send({
                nome: this.state.nome,
                modulo: this.state.modulo,
                tabela: this.state.tabela,
                sigilo: "",
                Filtros: this.state.filtros,
                Ordens: this.state.ordens,
                Colunas: this.state.colunas,
                Resultados: this.state.resultados
            })
            .then(res => {
                this.context.addToast({
                    titulo: "Sucesso",
                    conteudo: "Registro gravado com sucesso!"
                });
                this.context.closeModal();
                this.props.onSave();
                callback(res.body);
            })
            .catch(err => {
                this.context.addToast({
                    titulo: "Falha ao enviar dados",
                    conteudo: "Houve um erro: " + err.toString()
                });
            });
    }


    render() {

        const mapfilter = {
            "Valor": "text",
            "Hora": "time",
            "Data/Hora": "datetime-local",
            "Data": "date",
            "Dia do Mês": "number",
            "Mês": "number",
            "Mês/Ano": "text",
            "Ano": "number"
        }

        return (
            <Container fluid className="d-flex flex-grow-1 flex-column">
                <Row className="mb-4">
                    <Col><b>Nome do Relatório</b>
                        <FormControl value={this.state.nome} onChange={event => this.setState({ nome: event.target.value })} />
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col><b>Módulo</b>
                        <Select
                            noOptionsMessage={() => "Nada encontrado."}
                            placeholder="Pesquisar..."
                            options={this.state.modulos}
                            value={this.state.modulos.find(
                                (option) => option.nome === this.state.modulo
                            )}
                            onChange={(option) => this.setState({ modulo: option.nome }, () => this.updateTabelas())}
                        />
                    </Col>
                    <Col><b>Tabela</b>
                        <Select
                            noOptionsMessage={() => "Nada encontrado."}
                            placeholder="Pesquisar..."
                            options={this.state.tabelas}
                            value={this.state.tabelas.find(
                                (option) => option.value === this.state.tabela
                            )}
                            onChange={(option) => this.setState({ tabela: option.value }, () => this.updateCampos())}
                        />
                    </Col>
                </Row>
                <Row className="flex-grow-1 mb-4" style={{ minHeight: 300 }}>
                    <Col md={3}>
                        <Card className="h-100">
                            <Card.Header>
                                Campos
                            </Card.Header>

                            <ListGroup variant="flush">
                                {
                                    this.state.campos.map((campo, key) => <ListGroupItem key={key} draggable style={{ cursor: 'move' }} onDragStart={() => this.setState({ campo })}>{campo.nome}</ListGroupItem>)
                                }
                            </ListGroup>

                        </Card>
                    </Col>
                    <Col className="d-flex flex-column" md={3}>
                        <Card className="flex-grow-1" onDragOver={e => e.preventDefault()} onDrop={() => this.setState(state => ({ filtros: [...state.filtros, { campo: state.campo.nome, tipo: 'Valor', operacao: 'eq', valor: '' }] }))}>
                            <Card.Header>
                                Filtros
                            </Card.Header>
                            <ListGroup variant="flush">
                                {
                                    this.state.filtros.map((filtro, key) => <ListGroupItem key={key}>
                                        <div className="d-flex justify-content-between">{filtro.campo} <FontAwesomeIcon icon={faTimes} onClick={() => this.setState(state => ({ filtros: state.filtros.filter(option => option !== filtro) }))} /></div>
                                        <div>
                                            <FormControl as="select" value={filtro.tipo} onChange={e => this.setState(state => ({ filtros: state.filtros.map(c => c === filtro ? { ...filtro, tipo: e.target.value } : c) }))}>
                                                {Object.keys(mapfilter).map((option, key) => <option key={key}>{option}</option>)}
                                            </FormControl>
                                            <FormControl as="select" value={filtro.operacao} onChange={e => this.setState(state => ({ filtros: state.filtros.map(f => f === filtro ? { ...filtro, operacao: e.target.value } : f) }))}>
                                                <option value="eq">É igual a</option>
                                                <option value="ne">É diferente de</option>
                                                <option value="in">É Nulo</option>
                                                <option value="nn">É Não-Nulo</option>
                                                <option value="gt">É maior que</option>
                                                <option value="ge">É maior ou igual a</option>
                                                <option value="lt">É menor que</option>
                                                <option value="le">É menor ou igual a</option>
                                                <option value="ct">Contém</option>
                                            </FormControl>
                                            <FormControl type={mapfilter[filtro.tipo]} value={filtro.valor} onChange={e => this.setState(state => ({ filtros: state.filtros.map(f => f === filtro ? { ...filtro, valor: e.target.value } : f) }))} />
                                        </div>
                                    </ListGroupItem>)
                                }
                            </ListGroup>
                        </Card>
                        <Card className="mt-2 flex-grow-1" onDragOver={e => e.preventDefault()} onDrop={() => this.setState(state => ({ ordens: [...state.ordens, { campo: state.campo.nome, tipo: 'Valor', direcao: 'ASC'}] }))}>
                            <Card.Header>
                                Ordens
                            </Card.Header>
                            <ListGroup variant="flush">
                                {
                                    this.state.ordens.map((ordem, key) => <ListGroupItem key={key}>
                                        <div className="d-flex justify-content-between">{ordem.campo} <FontAwesomeIcon icon={faTimes} onClick={() => this.setState(state => ({ ordens: state.ordens.filter(option => option !== ordem) }))} /></div>
                                        <div>
                                            <FormControl as="select" value={ordem.tipo} onChange={e => this.setState(state => ({ ordens: state.ordens.map(c => c === ordem ? { ...ordem, tipo: e.target.value } : c) }))}>
                                                {Object.keys(mapfilter).map((option, key) => <option key={key}>{option}</option>)}
                                            </FormControl>
                                            <FormControl as="select" value={ordem.direcao} onChange={e => this.setState(state => ({ ordens: state.ordens.map(f => f === ordem ? { ...ordem, direcao: e.target.value } : f) }))}>
                                                <option value="ASC">Ascendente</option>
                                                <option value="DESC">Descendente</option>
                                            </FormControl>
                                        </div>
                                    </ListGroupItem>)
                                }
                            </ListGroup>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className="h-100" onDragOver={e => e.preventDefault()} onDrop={() => this.setState(state => ({ colunas: [...state.colunas, { campo: state.campo.nome, tipo: 'Valor' }] }))}>
                            <Card.Header>
                                Colunas
                            </Card.Header>
                            <ListGroup variant="flush">
                                {
                                    this.state.colunas.map((coluna, key) => <ListGroupItem key={key}>
                                        <div className="d-flex justify-content-between">{coluna.campo} <FontAwesomeIcon icon={faTimes} onClick={() => this.setState(state => ({ colunas: state.colunas.filter(option => option !== coluna) }))} /></div>
                                        <div>
                                            <FormControl as="select" value={coluna.tipo} onChange={e => this.setState(state => ({ colunas: state.colunas.map(c => c === coluna ? { ...coluna, tipo: e.target.value } : c) }))}>
                                                {Object.keys(mapfilter).map((option, key) => <option key={key}>{option}</option>)}
                                            </FormControl>
                                        </div>
                                    </ListGroupItem>)
                                }
                            </ListGroup>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className="h-100" onDragOver={e => e.preventDefault()} onDrop={() => this.setState(state => ({ resultados: [...state.resultados, { campo: state.campo.nome, grupo: 'Total', tipo: 'Valor' }] }))}>
                            <Card.Header>
                                Resultados
                            </Card.Header>
                            <ListGroup variant="flush">
                                {
                                    this.state.resultados.map((resultado, key) => <ListGroupItem key={key}>
                                        <div className="d-flex justify-content-between">{resultado.campo} <FontAwesomeIcon icon={faTimes} onClick={() => this.setState(state => ({ resultados: state.resultados.filter(option => option !== resultado) }))} /></div>
                                        <div>
                                            <FormControl as="select" value={resultado.tipo} onChange={e => this.setState(state => ({ resultados: state.resultados.map(c => c === resultado ? { ...resultado, tipo: e.target.value } : c) }))}>
                                                {Object.keys(mapfilter).map((option, key) => <option key={key}>{option}</option>)}
                                            </FormControl>

                                            <FormControl as="select" value={resultado.grupo} onChange={e => this.setState(state => ({ resultados: state.resultados.map(c => c === resultado ? { ...resultado, grupo: e.target.value } : c) }))}>
                                                <option>Total</option>
                                                <option>Total Distinto</option>
                                                <option>Lista</option>
                                                <option>Lista Distinta</option>
                                                <option>Máximo</option>
                                                <option>Mínimo</option>
                                                <option>Soma</option>
                                                <option>Média</option>
                                                <option>Variância</option>
                                                <option>Desvio Padrão</option>
                                            </FormControl>
                                        </div>
                                    </ListGroupItem>)
                                }

                            </ListGroup>
                        </Card>
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col className="text-right">
                        <Button onClick={() => this.context.closeModal()} className="mr-2" variant="light"><FontAwesomeIcon icon={faTimes} /> Cancelar</Button>
                        {this.context.usuario.Permissoes.some(p => p.nome === 'relatorios_relatorio_edit') && <>
                        <Button onClick={() => this.save()} className="mr-2" variant="success"><FontAwesomeIcon icon={faSave} /> Gravar e Fechar</Button>
                        <Button onClick={() => this.view()} variant="primary"><FontAwesomeIcon icon={faEye} /> Gravar e Visualizar</Button></>}
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default RelatorioForm;