import { faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { AppContext } from '../../../context';
import Request from '../../../request';
import Datatable from '../../Datatable';

class DeclaracaoList extends React.Component {  

    static contextType = AppContext;

    onAction(action, callback) {
        switch (action.name){        
            case "print":
                Request('GET', this.context.config.BACKEND_URL + '/declaracao/' + action.id, this.context.token)
                .then(res => {
                    this.context.setContent("DeclaracaoPrint", {declaracao: res.body});
                })
                .catch(err => {
                    this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na recuperação do registro: " + err.toString() });
                });
                break;
            case "comprovante":
                    Request('GET', this.context.config.BACKEND_URL + '/declaracao/' + action.id, this.context.token)
                    .then(res => {
                        this.context.setContent("DeclaracaoComprovante", {declaracao: res.body});
                    })
                    .catch(err => {
                        this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na recuperação do registro: " + err.toString() });
                    });
                    break;
            case "delete":
                if (window.confirm("Deseja realmente excluir este registro?"))
                {
                    Request('DELETE', this.context.config.BACKEND_URL + '/declaracao/' + action.id, this.context.token)
                    .then(res => {
                        callback();
                    })
                    .catch(err => {
                        this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na exclusão do registro: " + err.toString() });
                    });
                }
                break;
            default:
                break;                  
        }
    }

    render(){
        return (<>
            <h3><FontAwesomeIcon icon={faFile}/> Lista de Declarações</h3>
            <hr />
            <Datatable 
                url={this.context.config.BACKEND_URL + '/declaracao'}                
                useAdd={true}
                onError={(err) => this.context.addToast({titulo: "Erro", conteudo: err.toString()})}
                onAction={(action, update) => this.onAction(action, update)}
                onClickAdd={ () => this.context.setContent('DeclaracaoForm', {declaracao: {}})}
            />
        </>);
    }
}

export default DeclaracaoList;

