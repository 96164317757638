import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Form as BSForm, FormGroup } from "react-bootstrap";
import Request from "../../../request";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Select from 'react-select';
import { AppContext } from "../../../context";

class AtividadeDocumentoForm extends React.Component {

    static contextType = AppContext;

    state = {
        funcoes: [],
        subfuncoes: []
    }

    componentDidMount() {
        Request("GET", this.context.config.BACKEND_URL + "/funcaoDocumento/options", this.context.token)
          .then((res) => {
            this.setState(() => ({ funcoes: res.body }));
          })
          .catch((err) => {
            this.context.addToast({
              titulo: "Erro",
              conteudo: "Erro na recuperação da lista de Funções",
            });
          });
    
        Request("GET", this.context.config.BACKEND_URL + "/subfuncaoDocumento/options", this.context.token)
          .then((res) => {
            this.setState(() => ({ subfuncoes: res.body }));
          })
          .catch((err) => {
            this.context.addToast({
              titulo: "Erro",
              conteudo: "Erro na recuperação da lista de Subfunções",
            });
          });        
      }

    save(values, callback) {

        const method = this.props.atividade.id ? 'PUT' : 'POST';
        const url = this.context.config.BACKEND_URL + '/atividadeDocumento' + (this.props.atividade.id ? '/' + this.props.atividade.id : '');

        Request(method, url, this.context.token)
            .send(values)
            .then(res => {
                this.context.addToast({ titulo: "Successo", conteudo: "Registro atualizado com sucesso." });
                callback();
                if (this.props.onSave) this.props.onSave();
                this.context.closeModal();
            })
            .catch(err => {
                this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na gravação do registro." });
                callback();
            });

    }


    render() {
        return (
            <Formik
                initialValues={{
                    nome: this.props.atividade.nome,
                    codigo: this.props.atividade.codigo,
                    FuncaoId: this.props.atividade.FuncaoId,
                    SubfuncaoId: this.props.atividade.SubfuncaoId,
                }}
                validate={values => {
                    const errors = {};
                    if (!values.nome) {
                        errors.nome = 'Campo obrigatório';
                    }
                    if (!values.codigo) {
                        errors.nome = 'Campo obrigatório';
                    }
                    if (!values.FuncaoId) {
                        errors.nome = 'Campo obrigatório';
                    }
                    if (!values.SubfuncaoId) {
                        errors.nome = 'Campo obrigatório';
                    }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    this.save(values, () => setSubmitting(false));
                }}
            >
                {({ isSubmitting, values, setFieldValue }) => {
                    return (
                        <Form>
                            <FormGroup>
                                <BSForm.Label>Nome</BSForm.Label><ErrorMessage name="nome" component="span" className="text-danger small ml-2" />
                                <Field type="text" name="nome" className="form-control" />
                            </FormGroup>
                            <FormGroup>
                                <BSForm.Label>Código</BSForm.Label><ErrorMessage name="codigo" component="span" className="text-danger small ml-2" />
                                <Field type="text" name="codigo" className="form-control" />
                            </FormGroup>
                            <FormGroup>
                                <BSForm.Label>Função</BSForm.Label>
                                <ErrorMessage
                                    name="FuncaoId"
                                    component="span"
                                    className="text-danger small ml-2"
                                />
                                <Select
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    noOptionsMessage={() => "Nada encontrado."}
                                    placeholder="Pesquisar..."
                                    options={this.state.funcoes}
                                    value={this.state.funcoes.find(
                                        (option) => option.value === values.FuncaoId
                                    ) ?? null}
                                    onChange={(option) => {
                                        setFieldValue(`FuncaoId`, option ? option.value : null);
                                        setFieldValue(`SubfuncaoId`, null);
                                    }}
                                />
                            </FormGroup>
                            <FormGroup>
                                <BSForm.Label>Subfunção</BSForm.Label>
                                <ErrorMessage
                                    name="SubfuncaoId"
                                    component="span"
                                    className="text-danger small ml-2"
                                />
                                <Select
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    noOptionsMessage={() => "Nada encontrado."}
                                    placeholder="Pesquisar..."
                                    options={this.state.subfuncoes.filter(option => option.FuncaoId === values.FuncaoId)}
                                    value={this.state.subfuncoes.find(
                                        (option) => option.value === values.SubfuncaoId
                                    ) ?? null}
                                    onChange={(option) => {
                                        setFieldValue(`SubfuncaoId`, option ? option.value : null);
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className="text-right">
                                <Button type="button" variant='secondary' onClick={() => this.context.closeModal()}>
                                    <FontAwesomeIcon icon={faTimes} />&nbsp;
                                    Fechar
                                </Button>
                                <Button type="submit" disabled={isSubmitting} variant='primary' className="ml-2">
                                    <FontAwesomeIcon icon={faSave} />&nbsp;
                                    Gravar Alterações
                                </Button>
                            </FormGroup>
                        </Form>
                    );
                }}
            </Formik>
        );
    }
}

export default AtividadeDocumentoForm;