import { faDownload, faSave, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Col, Form as BSForm, Row, Tab, Table, Tabs } from "react-bootstrap";
import Request from "../../../request";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Select from 'react-select';
import moment from 'moment';
import fileSize from "filesize";
import AddAnexos from "../../AddAnexos";
import { AppContext } from "../../../context";

class ApostilamentoForm extends React.Component {

    static contextType = AppContext;

    state = {
        contratos: []
    }

    componentDidMount() {
        Request("GET", this.context.config.BACKEND_URL + "/contrato/options", this.context.token)
            .send()
            .then((res) => {
                this.setState(() => ({ contratos: res.body }));
            })
            .catch((err) => {
                this.context.addToast({
                    titulo: "Erro",
                    conteudo:
                        "Falha na recuperação da lista dos contratos: " + err.toString(),
                });
            });
    }

    save(values, callback) {

        const method = this.props.apostilamento.id ? 'PUT' : 'POST';
        const url = this.context.config.BACKEND_URL + '/apostilamento' + (this.props.apostilamento.id ? '/' + this.props.apostilamento.id : '');

        Request(method, url, this.context.token)
            .send(values)
            .then(res => {
                this.context.addToast({ titulo: "Successo", conteudo: "Registro atualizado com sucesso." });
                if (this.props.onSave) this.props.onSave();
                this.context.closeModal();
            })
            .catch(err => {
                this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na gravação do registro." });

            })
            .finally(() => callback());

    }

    render() {
        return (
            <Formik
                initialValues={{
                    ContratoId: this.props.apostilamento.ContratoId,
                    numero: this.props.apostilamento.numero,
                    objeto: this.props.apostilamento.objeto,
                    data_publicacao: this.props.apostilamento.data_publicacao ? moment(this.props.apostilamento.data_publicacao).utcOffset(0).format("YYYY-MM-DD") : "",
                    Anexos: []
                }}
                validate={values => {
                    const errors = {};
                    if (!values.numero) {
                        errors.numero = 'Campo obrigatório';
                    }
                    if (!values.ContratoId) {
                        errors.ContratoId = 'Campo obrigatório';
                    }
                    if (!values.objeto) {
                        errors.objeto = 'Campo obrigatório';
                    }
                    if (!values.data_publicacao) {
                        errors.data_publicacao = 'Campo obrigatório';
                    }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    this.save(values, () => setSubmitting(false))
                }}
            >
                {({ isSubmitting, values, setFieldValue }) => {
                    return (
                        <Form>
                            <Tabs defaultActiveKey="detalhes">
                                <Tab eventKey="detalhes" title="Detalhes">
                                    <Row>
                                        <Col md={12} className="mt-2">
                                            <BSForm.Label>Contrato</BSForm.Label><ErrorMessage name="ContratoId" component="span" className="text-danger small ml-2" />
                                            <Select
                                                isDisabled={values.ContratoId || false}
                                                noOptionsMessage={() => "Nada encontrado."}
                                                placeholder="Pesquisar..."
                                                options={this.state.contratos.filter(option =>
                                                    this.context.usuario.Permissoes.some(p => p.nome === 'contratos_orgao_global') ?
                                                        true :
                                                        this.context.usuario.Permissoes.some(p => p.nome === 'contratos_orgao_local') ?
                                                            option.OrgaoId === this.context.usuario.Orgao.id :
                                                            option.SetorId === this.context.usuario.Setor.id
                                                )}
                                                value={this.state.contratos.find(
                                                    (option) => option.value === values.ContratoId
                                                )}
                                                onChange={(option) => setFieldValue("ContratoId", option ? option.value : null)}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4} className="mt-2">
                                            <BSForm.Label>Número</BSForm.Label><ErrorMessage name="numero" component="span" className="text-danger small ml-2" />
                                            <Field type="number" min={0} step={1} name="numero" className="form-control" />
                                        </Col>
                                        <Col md={8} className="mt-2">
                                            <BSForm.Label>Data da Publicação</BSForm.Label><ErrorMessage name="data_publicacao" component="span" className="text-danger small ml-2" />
                                            <Field type="date" name="data_publicacao" className="form-control" />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} className="mt-2">
                                            <BSForm.Label>Objeto</BSForm.Label><ErrorMessage name="objeto" component="span" className="text-danger small ml-2" />
                                            <Field as="textarea" name="objeto" className="form-control" />
                                        </Col>
                                    </Row>
                                </Tab>
                                <Tab eventKey="anexos" title="Anexos">
                                    <Table striped size="sm" responsive className='mt-3'>
                                        <thead className='bg-light'>
                                            <tr>
                                                <th>Nome</th>
                                                <th style={{ minWidth: "1%" }}>Data</th>
                                                <th style={{ width: 120 }}>Tamanho</th>
                                                <th style={{ width: 120, textAlign: 'center' }}>Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {values.Anexos.map((anexo, key) =>
                                                <tr key={key}>
                                                    <td>
                                                        {anexo.nome}
                                                    </td>
                                                    <td style={{ whiteSpace: 'nowrap' }}>
                                                        {moment(anexo.updatedAt).locale('pt-br').calendar()}
                                                    </td>
                                                    <td>
                                                        {fileSize(anexo.tamanho, { locale: 'pt', round: 0 })}
                                                    </td>
                                                    <td className='text-center'>
                                                        {anexo.id ?
                                                            <Button as="a" href={`${this.context.config.BACKEND_URL}/anexo/contrato/${anexo.id}/${anexo.hash}/${anexo.nome}`} download={anexo.nome} size="sm" variant='info' title='Download' className='mr-2'>
                                                                <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                                                            </Button> : null}
                                                        <Button size="sm" type='button' variant='danger' title='Excluir'
                                                            onClick={() => window.confirm("Deseja realmente excluir este arquivo?") && setFieldValue('Anexos', values.Anexos.filter(a => a !== anexo))}
                                                        >
                                                            <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
                                                        </Button>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colspan='4'>
                                                    <AddAnexos
                                                        multiple={true}
                                                        onError={file => this.context.addToast({ titulo: "Erro", conteudo: "Falha ao carregar o arquivo " + file.name })}
                                                        onLoad={anexos => setFieldValue('Anexos', [...values.Anexos, ...anexos])}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="4">
                                                    <ErrorMessage name="Anexos" component="span" className="text-danger small ml-2" />
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </Table>
                                </Tab>
                            </Tabs>
                            <Row className="text-right">
                                <Col md={12} className="mt-3">
                                    <Button type="button" variant='secondary' onClick={() => this.context.closeModal()}>
                                        <FontAwesomeIcon icon={faTimes} />&nbsp;
                                        Fechar
                                    </Button>
                                    <Button type="submit" disabled={isSubmitting} variant='primary' className="ml-2">
                                        <FontAwesomeIcon icon={faSave} />&nbsp;
                                        Gravar Alterações
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    );
                }}
            </Formik>
        );
    }
}


export default ApostilamentoForm;