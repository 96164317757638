import React from "react";
import L from 'leaflet';
import { AppContext } from '../../../context';
import square from '../../../assets/vectors/square-dark.svg';

import 'leaflet-draw';

import {
    LayerGroup,
    MapContainer,
    Marker,
    Polygon,
    ScaleControl,
    TileLayer,
    useMap,
    useMapEvents,
} from "react-leaflet";

const squareIcon = new L.Icon({
    iconUrl: square,
    iconRetinaUrl: null,
    iconAnchor: null,
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(8, 8)
});

const MapController = props => {

    const map = useMap();

    //useEffect(() => {

        if (!props.pontos.length) {

        } else if (props.pontos.length === 1) {
            map.setView([props.pontos[0].latitude, props.pontos[0].longitude]);
        } else {
            const corner1 = L.latLng(
                props.pontos.map(ponto => ponto.latitude).reduce((a, b) => Math.max(a, b), props.pontos[0].latitude),
                props.pontos.map(ponto => ponto.longitude).reduce((a, b) => Math.min(a, b), props.pontos[0].longitude)
            );
            const corner2 = L.latLng(
                props.pontos.map(ponto => ponto.latitude).reduce((a, b) => Math.min(a, b), props.pontos[0].latitude),
                props.pontos.map(ponto => ponto.longitude).reduce((a, b) => Math.max(a, b), props.pontos[0].longitude)
            );

            const bounds = L.latLngBounds(corner1, corner2);

            map.flyToBounds(bounds);
        }

    //}, [map]);

    useMapEvents({
        dblclick: event => props.addPonto(event.latlng.lat, event.latlng.lng),
    });

    return null;
}

class AlertaMap extends React.Component {

    static contextType = AppContext;

    static center = [-1.437972, -48.466901];

    state = {
        center: AlertaMap.center,
        dragPonto: null,
        dragLatitude: null,
        dragLongitude: null
    }

    render() {

        const markerEventHandlers = (ponto) => ({
            dblclick: () => this.props.delPonto(ponto),
            dragstart: event => {
              const latitude = event.target.getLatLng().lat;
              const longitude = event.target.getLatLng().lng;
              this.setState({ dragPonto: ponto, dragLatitude: latitude, dragLongitude: longitude });
            },
            drag: event => {
              const latitude = event.target.getLatLng().lat;
              const longitude = event.target.getLatLng().lng;
              this.state.dragPonto && this.setState({ dragLatitude: latitude, dragLongitude: longitude });
            },
            dragend: event => {
              const latitude = event.target.getLatLng().lat;
              const longitude = event.target.getLatLng().lng;
              this.setState({ dragPonto: null }, () =>
                this.props.savePonto(ponto, latitude, longitude));
            }
          });

        return (
            <MapContainer
                center={this.state.center}
                zoom={13}
                scrollWheelZoom={true}
                doubleClickZoom={false}
                style={{ height: "100%", width: "100%", minHeight: 400 }}
            >
                <TileLayer url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
                    attribution='Map data © <a href="http://openstreetmap.org">OpenStreetMap</a> contributors' />
                <LayerGroup >
                    <Polygon positions={this.props.pontos.map(ponto => ponto === this.state.dragPonto ? [this.state.dragLatitude, this.state.dragLongitude] : [ponto.latitude, ponto.longitude])} />
                    {this.props.pontos.map((ponto, key) => <Marker icon={squareIcon} position={ponto === this.state.dragPonto ? [this.state.dragLatitude, this.state.dragLongitude] : [ponto.latitude, ponto.longitude]} key={key} draggable={true} eventHandlers={markerEventHandlers(ponto)} />)}
                </LayerGroup>
                <MapController
                    pontos={this.props.pontos}
                    addPonto={this.props.addPonto}
                />
                <ScaleControl imperial={false} />
            </MapContainer >
        );
    }
}

export default AlertaMap;
