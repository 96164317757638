import React from 'react';
import { AppContext } from '../../../context';
import ModelList from '../ModelList';
import DesenvolvedorForm from './DesenvolvedorForm';

class DesenvolvedorList extends React.Component {
    static contextType = AppContext;
    render(){
        return (
            <ModelList 
                url={this.context.config.BACKEND_URL + '/desenvolvedor'}
                title="Lista de Desenvolvedores"                
                modelIcon="faCode"
                useAdd={true}
                modelName="desenvolvedor"
                modelForm={DesenvolvedorForm}
            />
        );
    }
}

export default DesenvolvedorList;

