import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Form as BSForm, FormGroup, Tab, Tabs } from "react-bootstrap";
import Request from "../../../request";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Select from 'react-select';
import { AppContext } from "../../../context";

class VeiculosForm extends React.Component {
  static contextType = AppContext;

  save(values, callback) {
    const method = this.props.veiculo.id ? 'PUT' : 'POST';
    const url = this.context.config.BACKEND_URL + '/veiculo' + (this.props.veiculo.id ? '/' + this.props.veiculo.id : '');


    Request(method, url, this.context.token)
      .send(values)
      .then((res) => {
        this.context.addToast({
          titulo: "Successo",
          conteudo: "Registro atualizado com sucesso.",
        });
        callback();
        if (this.props.onSave) this.props.onSave();
        this.context.closeModal();
      })
      .catch((err) => {
        this.context.addToast({
          titulo: "Erro",
          conteudo: "Houve uma falha na gravação do registro.",
        });
        callback();
      });
  }

  render() {

    const status = [
      { value: 'Retirado', label: 'Retirado' },
      { value: 'Recuperado', label: 'Recuperado' }
    ];

    return (
      <Formik
        initialValues={{
          ...this.props.veiculo,
         }}
        validate={(values) => {
          const errors = {};
          if (!values.placa) {
            errors.placa = "Campo obrigatório";
          }
          if (!values.modelo) {
            errors.modelo = "Campo obrigatório";
          }
          if (!values.chassi) {
            errors.chassi = "Campo obrigatório";
          }

          if (!values.ano) {
            errors.ano = "Campo obrigatório";
          }

          if (!values.data_recuperacao) {
            errors.data_recuperacao = "Campo obrigatório";
          }

          if (!values.status) {
            errors.status = "Campo obrigatório";
          }

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          this.save(values, () => setSubmitting(false));
        }}
      >
        {({ isSubmitting, values, setFieldValue}) => {
          return (
            <Form>
              <Tabs defaultActiveKey="veiculos" className="mb-3">
                <Tab eventKey="veiculos" title="Veículos">
                  <FormGroup>
                    <BSForm.Label>Nome da Placa</BSForm.Label>
                    <ErrorMessage
                      name="placa"
                      component="span"
                      className="text-danger small ml-2"
                    />
                    <Field
                      type="text"
                      name="placa"
                      className="form-control"
                    />
                  </FormGroup>
                  <FormGroup>
                    <BSForm.Label>Númeração do Chassi</BSForm.Label>
                    <ErrorMessage
                      name="chassi"
                      component="span"
                      className="text-danger small ml-2"
                    />
                    <Field
                      type="text"
                      name="chassi"
                      className="form-control"
                    />
                  </FormGroup>
                  <FormGroup>
                    <BSForm.Label>Renavam</BSForm.Label>
                    <ErrorMessage
                      name="renavam"
                      component="span"
                      className="text-danger small ml-2"
                    />
                    <Field
                      type="text"
                      name="renavam"
                      className="form-control"
                    />
                  </FormGroup>
                  <FormGroup>
                    <BSForm.Label>Modelo</BSForm.Label>
                    <ErrorMessage
                      name="modelo"
                      component="span"
                      className="text-danger small ml-2"
                    />
                    <Field
                      type="text"
                      name="modelo"
                      className="form-control"
                    />
                  </FormGroup>
                  <FormGroup>
                    <BSForm.Label>Ano de Fabricação</BSForm.Label>
                    <ErrorMessage
                      name="ano"
                      component="span"
                      className="text-danger small ml-2"
                    />
                    <Field
                      type="number"
                      min="0"
                      step="1"
                      name="ano"
                      className="form-control"
                    />
                  </FormGroup>
                  <FormGroup>
                    <BSForm.Label>Data de recuperação</BSForm.Label>
                    <ErrorMessage
                      name="data_recuperacao"
                      component="span"
                      className="text-danger small ml-2"
                    />
                    <Field
                      type="date"
                      name="data_recuperacao"
                      className="form-control"
                    />
                  </FormGroup>
                  <FormGroup>
                    <BSForm.Label as="b">Status de recuperação</BSForm.Label><ErrorMessage name="status" component="span" className="text-danger small ml-2" />
                      <Select
                          name="status"
                          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                          menuPortalTarget={document.body}
                          placeholder="Selecione..."
                          options={status}
                          value={status.find(status => status.value === values.status)}
                          onChange={option => {
                              setFieldValue('status', option.value);
                          }}
                      />
                  </FormGroup>
                  <FormGroup className="text-right">
                    <Button
                      type="button"
                      variant="secondary"
                      onClick={() => this.context.closeModal()}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                      &nbsp; Fechar
                    </Button>
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      variant="primary"
                      className="ml-2"
                    >
                      <FontAwesomeIcon icon={faSave} />
                      &nbsp; Gravar Alterações
                    </Button>
                  </FormGroup>
                </Tab>
              </Tabs>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

export default VeiculosForm;
