import OcorrenciasForm from "./OcorrenciasForm";
import OcorrenciasList from "./OcorrenciasList";
import DashboardProMulher from "./DashboardProMulher";
import CartaoProgramaList from "./CartaoProgramaList";
import AreaList from "./AreaList";
import CartaoProgramaForm from "./CartaoProgramaForm";
import HomeProMulher from "./HomeProMulher";

const ProMulherContents = {
    OcorrenciasList,
    OcorrenciasForm,
    DashboardProMulher,
    CartaoProgramaList,
    CartaoProgramaForm,
    AreaList,
    HomeProMulher,
};

export default ProMulherContents;