import { faSave, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  Button,
  Form as BSForm,
  FormControl,
  FormGroup,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import Request from "../../../request";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Select from "react-select";
import moment from "moment";
import { AppContext } from "../../../context";

class CautelaForm extends React.Component {
  static contextType = AppContext;

  state = {
    materiais: [],
    setores: [],
  };

  componentDidMount() {
    Request(
      "GET",
      this.context.config.BACKEND_URL + "/material/options",
      this.context.token
    )
      .send()
      .then((res) => {
        this.setState(() => ({ materiais: res.body }));
      })
      .catch((err) => {
        this.context.addToast({
          title: "Erro",
          text:
            "Falha na recuperação da lista dos materiais: " + err.toString(),
        });
      });
    Request(
      "GET",
      this.context.config.BACKEND_URL + "/setor/options",
      this.context.token
    )
      .send()
      .then((res) => {
        this.setState(() => ({ setores: res.body }));
      })
      .catch((err) => {
        this.context.addToast({
          title: "Erro",
          text:
            "Falha na recuperação da lista dos materiais: " + err.toString(),
        });
      });
  }

  save(values, callback) {
    const method = this.props.cautela.id ? "PUT" : "POST";
    const url =
      this.context.config.BACKEND_URL +
      "/cautela" +
      (this.props.cautela.id ? "/" + this.props.cautela.id : "");

    Request(method, url, this.context.token)
      .send(values)
      .then((res) => {
        this.context.addToast({
          titulo: "Successo",
          conteudo: "Registro atualizado com sucesso.",
        });
        callback();
        if (this.props.onSave) this.props.onSave();
        this.context.closeModal();
      })
      .catch((err) => {
        this.context.addToast({
          titulo: "Erro",
          conteudo: "Houve uma falha na gravação do registro.",
        });
        callback();
      });
  }

  render() {
    return (
      <Formik
        initialValues={{
          ...this.props.cautela,
          rascunho: this.props.cautela.rascunho ?? false,
          cautelante: this.props.cautela.cautelante ?? "",
          motivo: this.props.cautela.motivo ?? "",
          prazo: this.props.cautela.prazo ?? "",
          EntregadorId:
            this.props.cautela.EntregadorId ?? this.context.usuario.id,
          RecebedorId: this.props.cautela.id
            ? this.props.cautela.RecebedorId ?? this.context.usuario.id
            : null,
          OrigemId: this.props.cautela.OrigemId
            ? this.props.cautela.OrigemId
            : this.context.usuario.Setor.id,
          DestinoId: this.props.cautela.DestinoId,
          entrega: this.props.cautela.entrega
            ? moment(this.props.cautela.entregal)
                .utcOffset(0)
                .format("YYYY-MM-DDTHH:mm")
            : "",
          devolucao: (!this.props.cautela.id || this.props.cautela.rascunho)
            ? null
            : moment().format("YYYY-MM-DDTHH:mm"),
          Materiais: this.props.cautela.Materiais
            ? this.props.cautela.Materiais.map((material) => material.id)
            : [],
        }}
        validate={(values) => {
          const errors = {};
          if (!values.DestinoId) {
            errors.DestinoId = "Campo obrigatório";
          }
          if (!values.motivo) {
            errors.motivo = "Campo obrigatório";
          }
          if (values.Materiais.length === 0) {
            errors.Materiais = "Campo obrigatório";
          }
          if (!values.entrega) {
            errors.entrega = "Campo obrigatório";
          }
          if (!values.prazo) {
            errors.prazo = "Campo obrigatório";
          }
          if ((!this.props.cautela.rascunho && this.props.cautela.id  ) && !values.descautelante) {
            errors.descautelante = "Campo obrigatório";
          }
          if ((!this.props.cautela.rascunho && this.props.cautela.id  ) && !values.contato_descautelante) {
            errors.contato_descautelante = "Campo obrigatório";
          }
          if ((!this.props.cautela.rascunho && this.props.cautela.id  ) && !values.devolucao) {
            errors.devolucao = "Campo obrigatório";
          }
          if (values.cautelante && !values.contato_cautelante) {
            errors.contato_cautelante = "Campo obrigatório";
          }
          if (!values.rascunho && !values.cautelante) {
            errors.cautelante = "Campo obrigatório";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          //console.log(values); setSubmitting(false);
          this.save(values, () => setSubmitting(false));
        }}
      >
        {({ isSubmitting, values, errors, setFieldValue, submitForm }) => {
          console.log(values, errors);
          return (
            <Form>
              <Tabs defaultActiveKey="detalhes" className="mb-3">
                <Tab eventKey="detalhes" title="Detalhes">
                  <FormGroup>
                    <BSForm.Label>Setor de Origem</BSForm.Label>
                    <FormControl
                      disabled={true}
                      value={
                        this.props.cautela.Origem
                          ? this.props.cautela.Origem.nome
                          : this.context.usuario.Setor.nome
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <BSForm.Label>Setor de Destino</BSForm.Label>
                    <ErrorMessage
                      name="DestinoId"
                      component="span"
                      className="text-danger small ml-2"
                    />
                    <Select
                      noOptionsMessage={() => "Nada encontrado."}
                      placeholder="Pesquisar..."
                      options={this.state.setores}
                      value={this.state.setores.find(
                        (option) => option.value === values.DestinoId
                      )}
                      onChange={(option) =>
                        setFieldValue("DestinoId", option ? option.value : null)
                      }
                      isDisabled={
                        this.props.cautela.id && !this.props.cautela.rascunho
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <BSForm.Label>Motivo</BSForm.Label>
                    <ErrorMessage
                      name="motivo"
                      component="span"
                      className="text-danger small ml-2"
                    />
                    <Field
                      as="textarea"
                      name="motivo"
                      className="form-control"
                      disabled={
                        this.props.cautela.id && !this.props.cautela.rascunho
                      }
                      value={values.motivo}
                    />
                  </FormGroup>
                  <FormGroup>
                    <BSForm.Label>Observação</BSForm.Label>
                    <Field
                      as="textarea"
                      name="observacao"
                      className="form-control"
                    />
                  </FormGroup>
                </Tab>
                <Tab eventKey="entrega" title="Entrega">
                  {this.props.cautela.id && !this.props.cautela.rascunho? null : (
                    <FormGroup>
                      <BSForm.Label>Materiais</BSForm.Label>
                      <ErrorMessage
                        name="Materiais"
                        component="span"
                        className="text-danger small ml-2"
                      />
                      <Select
                        name="Materiais"
                        noOptionsMessage={() => "Nada encontrado."}
                        placeholder="Pesquisar..."
                        options={this.state.materiais.filter(
                          (option) =>
                            !values.Materiais.find((id) => id === option.value)
                        )}
                        value={null}
                        onChange={(option) =>
                          setFieldValue("Materiais", [
                            ...values.Materiais,
                            option.value,
                          ])
                        }
                      />
                    </FormGroup>
                  )}
                  <Table className="mt-2">
                    <thead>
                      <tr>
                        <th>Material</th>
                        {this.props.cautela.id ? null : (
                          <th style={{ width: "1%" }}>Ação</th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.materiais
                        .filter((option) =>
                          values.Materiais.find((id) => id === option.value)
                        )
                        .map((option, key) => (
                          <tr key={key}>
                            <td>{option.label}</td>
                            {this.props.cautela.id && !this.props.cautela.rascunho? null : (
                              <td>
                                <Button
                                  size="sm"
                                  variant="danger"
                                  onClick={() =>
                                    setFieldValue(
                                      "Materiais",
                                      values.Materiais.filter(
                                        (id) => id !== option.value
                                      )
                                    )
                                  }
                                >
                                  <FontAwesomeIcon icon={faTrash} />
                                </Button>
                              </td>
                            )}
                          </tr>
                        ))}
                    </tbody>
                    <tfoot></tfoot>
                  </Table>
                  <FormGroup>
                    <BSForm.Label>Data Entrega</BSForm.Label>
                    <ErrorMessage
                      name="entrega"
                      component="span"
                      className="text-danger small ml-2"
                    />
                    <Field
                      type="datetime-local"
                      name="entrega"
                      className="form-control"
                      value={values.entrega}
                      disabled={
                        this.props.cautela.id && !this.props.cautela.rascunho
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <BSForm.Label>Prazo (dias)</BSForm.Label>
                    <ErrorMessage
                      name="prazo"
                      component="span"
                      className="text-danger small ml-2"
                    />
                    <Field
                      type="number"
                      name="prazo"
                      className="form-control"
                      value={values.prazo}
                      disabled={
                        this.props.cautela.id && !this.props.cautela.rascunho
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <BSForm.Label>Data a Receber</BSForm.Label>
                    <Field
                      disabled={true}
                      type="date"
                      className="form-control"
                      value={moment(values.entrega)
                        .add(values.prazo, "days")
                        .format("YYYY-MM-DD")}
                    />
                  </FormGroup>
                  <FormGroup>
                    <BSForm.Label>Entregador</BSForm.Label>
                    <FormControl
                      disabled={true}
                      value={
                        values.Usuario
                          ? values.Usuario.nome
                          : this.context.usuario.nome
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <BSForm.Label>Contato do Entregador</BSForm.Label>
                    <Field
                      type="text"
                      name="contato_entregador"
                      className="form-control"
                      disabled={
                        this.props.cautela.id && !this.props.cautela.rascunho
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <BSForm.Label>Cautelante</BSForm.Label>
                    <ErrorMessage
                      name="cautelante"
                      component="span"
                      className="text-danger small ml-2"
                    />
                    <Field
                      type="text"
                      name="cautelante"
                      className="form-control"
                      disabled={this.props.cautela.id && !this.props.cautela.rascunho}
                    />
                  </FormGroup>
                  <FormGroup>
                    <BSForm.Label>Contato do Cautelante</BSForm.Label>
                    <ErrorMessage
                      name="contato_cautelante"
                      component="span"
                      className="text-danger small ml-2"
                    />
                    <Field
                      type="text"
                      name="contato_cautelante"
                      className="form-control"
                      disabled={this.props.cautela.id && !this.props.cautela.rascunho}
                    />
                  </FormGroup>
                </Tab>
                {(this.props.cautela.id && !this.props.cautela.rascunho) && (
                  <Tab eventKey="devolucao" title="Devolução">
                    <FormGroup>
                      <BSForm.Label>Data do Recebimento</BSForm.Label>
                      <ErrorMessage
                        name="devolucao"
                        component="span"
                        className="text-danger small ml-2"
                      />
                      <Field
                        type="datetime-local"
                        name="devolucao"
                        className="form-control"
                      />
                    </FormGroup>
                    <FormGroup>
                      <BSForm.Label>Recebedor</BSForm.Label>
                      <FormControl
                        disabled={true}
                        value={
                          values.Usuario
                            ? values.Usuario.nome
                            : this.context.usuario.nome
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <BSForm.Label>Descautelante</BSForm.Label>
                      <ErrorMessage
                        name="descautelante"
                        component="span"
                        className="text-danger small ml-2"
                      />
                      <Field
                        type="text"
                        name="descautelante"
                        className="form-control"
                      />
                    </FormGroup>
                    <FormGroup>
                    <BSForm.Label>Contato do Descautelante</BSForm.Label>
                    <ErrorMessage
                      name="contato_descautelante"
                      component="span"
                      className="text-danger small ml-2"
                    />
                    <Field
                      type="text"
                      name="contato_descautelante"
                      className="form-control"
                    />
                  </FormGroup>
                  </Tab>
                )}
              </Tabs>
              {(!this.props.cautela.id || this.props.cautela.rascunho) && (
                <>
                  <Field type="checkbox" name="rascunho" /> Salvar como rascunho
                </>
              )}
              <FormGroup className="text-right d-flex justify-content-between">
                <Button
                  type="button"
                  variant="secondary"
                  onClick={() => this.context.closeModal()}
                >
                  <FontAwesomeIcon icon={faTimes} />
                  &nbsp; Fechar
                </Button>
                <Button
                  type="button"
                  disabled={isSubmitting}
                  variant="primary"
                  className="ml-2"  
                  onClick={()=>{
                    submitForm();
                  }}    
                >
                  <FontAwesomeIcon icon={faSave} />
                  &nbsp; Gravar Alterações
                </Button>
              </FormGroup>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

export default CautelaForm;
