import React from 'react';
import EventoForm from './EventoForm';
import ModelList from '../ModelList';
import { AppContext } from '../../../context';

class InscricaoHandle extends React.Component {   

    static contextType = AppContext;

    render(){
        return (
            <ModelList 
                url={this.context.config.BACKEND_URL + '/inscricao'}
                title={this.props.title}
                modelName="inscricao"
                modelIcon="faUser"
                modelForm={EventoForm}
                useAdd={true}
                filter={{InscricaoId: this.props.InscricaoId}}
            />
        );
    }
}

export default InscricaoHandle;