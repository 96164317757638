import React from "react";
import { AppContext } from "../../../context";
import ModelList from "../ModelList";
import UsuarioAlertaEscolaForm from "./UsuarioAlertaEscolaForm";

class UsuarioAlertaEscolaList extends React.Component {
  static contextType = AppContext;
  render() {


    return (
      <>
      <ModelList 
                url={this.context.config.BACKEND_URL + '/alertaescola/usuario'}
                title="Lista Usuários da Escola"
                modelName="usuario"
                modelIcon="faUserAlt"
                modelForm={UsuarioAlertaEscolaForm}
                useAdd={true}
            />
  </>)
  }
}

export default UsuarioAlertaEscolaList;
