import React from 'react';
import ModelList from '../ModelList';
import { AppContext } from '../../../context';

class BoletimList extends React.Component {

    static contextType = AppContext;

    render() {
        return (
            <ModelList
                url={this.context.config.BACKEND_URL + '/boletim'}
                title="Lista de Boletins"
                modelName="boletim"
                modelIcon="faFileAlt"
                modelPrint="BoletimPrint"
                modelForm="BoletimForm"
                useAdd={this.context.usuario.Permissoes.find(p => p.nome === "boletins_boletim_create")}
                onClickAdd={() =>   this.context.setContent("BoletimForm", { boletim: {}} )}
            />
        );
    }
}

export default BoletimList;