import React from 'react';
import { AppContext } from '../../../context';
import SetorForm from './SetorForm';
import ModelList from '../ModelList';

class SetorList extends React.Component {

    static contextType = AppContext;

    render() {

        return (
            <ModelList
                url={this.context.config.BACKEND_URL + '/setor'}
                title="Lista de Setores"
                modelIcon="faFolderOpen"
                useAdd={this.context.usuario.Permissoes.some(p => p.nome === 'admin_setor_create')}
                modelName="setor"
                modelForm={SetorForm}
            />
        );
    }
}

export default SetorList;