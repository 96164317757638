import { faCamera, faForward, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Card, Col, Form as BSForm, Row } from "react-bootstrap";
import Request from "../../../request";
import { Formik, Form, Field, ErrorMessage } from "formik";
import moment from "moment";
import Select from 'react-select';
import AddAnexos from "../../AddAnexos";
import { AppContext } from "../../../context";

class EleitorFrontForm extends React.Component {

  static contextType = AppContext;

  state = {
    cidades: []
  }

  componentDidMount() {
    Request("GET", this.context.config.BACKEND_URL + "/eleicoes/cidades", this.context.token)
      .then(res => {
        this.setState({ cidades: res.body })
      })
      .catch((err) => {
        this.context.addToast({
          titulo: "Erro",
          conteudo: "Erro na recuperação da lista de Cidades",
        });
      });
  }

  save(values, callback) {
    Request("POST", this.context.config.BACKEND_URL + "/eleicoes/eleitor", this.context.token)
      .send(values)
      .end((err, res) => {
        if (err) {
          this.context.addToast({
            titulo: "Erro",
            conteudo: res?.text ?? "Ocorreu um Erro. Tente mais tarde."
          });
        } else {
          this.context.closeModal();
          this.context.openModal({
            titulo: "Verifique seu email!",
            conteudo: <p>
              Uma mensagem foi enviada para {res.body.email}. Verifique sua caixa de entrada (ou de spam),
              abra a mensagem e clique no link de confirmação. Esta etapá é necessária para a confirmação de seu cadastro.
              Caso tenha informado incorretamente seu email, será necessário realizar uma nova inscrição.
            </p>
          })
        }
        callback();
      });
  }

  render() {
    return (
      <Formik
        initialValues={{
          EleicaoId: this.props.eleicao.id,
          nome: "",
          cpf: "",
          nascimento: "",
          nome_mae: "",
          email: "",
          CidadeId: null,
          foto_frente: null,
          foto_verso: null,
        }}
        validate={(values) => {
          const errors = {};
          if (!values.nome) {
            errors.nome = "Campo obrigatório";
          }
          if (!values.nome_mae) {
            errors.nome_mae = "Campo obrigatório";
          }

          if (!values.cpf) {
            errors.cpf = "Obrigatório";
          } else if (!/^\d{11}$/.test(values.cpf)) {
            errors.cpf = "Inválido";
          }

          if (!values.nascimento) {
            errors.nascimento = "Obrigatório";
          } else if (moment(this.props.eleicao.fim_votacao).subtract(16, 'years') < moment(values.nascimento)) {
            errors.nascimento = "Idade mínima exigida de 16 anos no dia da votação";
          }
          if (!values.email) {
            errors.email = "Campo obrigatório";
          } else if (!/^[^@]+@[^@]+$/.test(values.email)) {
            errors.email = "Formato inválido de email";
          }

          if (!values.CidadeId) {
            errors.CidadeId = "Campo obrigatório";
          }

          if (!values.foto_frente) {
            errors.foto_frente = "Campo obrigatório";
          }
          if (!values.foto_verso) {
            errors.foto_verso = "Campo obrigatório";
          }

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          this.save(values, () => setSubmitting(false));
        }}
      >
        {({ isSubmitting, values, setFieldValue }) => {
          return (
            <Form>
              <Card className="mb-3">
                <Card.Header>
                  <p className="text-center"><b>{this.props.eleicao.nome}</b></p>
                  <ul>
                    <li>
                      Inscrições:
                      <ul>
                        <li>Início: {moment(this.props.eleicao.inicio_inscricao).format(
                          "DD/MM/YYYY [às] HH:mm"
                        )}
                        </li>
                        <li>Fim: {moment(this.props.eleicao.fim_inscricao).format(
                          "DD/MM/YYYY [às] HH:mm"
                        )}
                        </li>
                      </ul>

                    </li>
                    <li>
                      Votação:
                      <ul>
                        <li>Início: {moment(this.props.eleicao.inicio_votacao).format(
                          "DD/MM/YYYY [às] HH:mm"
                        )}
                        </li>
                        <li>Fim: {moment(this.props.eleicao.fim_votacao).format(
                          "DD/MM/YYYY [às] HH:mm"
                        )}
                        </li>
                      </ul>

                    </li>
                  </ul>
                </Card.Header>
              </Card>
              <Row>
                <Col md={8} className="mb-2">
                  <BSForm.Label>Nome do Eleitor</BSForm.Label>
                  <ErrorMessage
                    name="nome"
                    component="span"
                    className="text-danger small ml-2"
                  />
                  <Field
                    type="text"
                    name="nome"
                    className="form-control"
                  />
                </Col>
                <Col md={4} className="mb-2">
                  <BSForm.Label>CPF (somente dígitos)</BSForm.Label>
                  <ErrorMessage
                    name="cpf"
                    component="span"
                    className="text-danger small ml-2"
                  />
                  <Field
                    type="text"
                    name="cpf"
                    className="form-control"
                  />
                </Col>
                <Col md={4} className="mb-2">
                  <BSForm.Label>Data de Nascimento</BSForm.Label>
                  <ErrorMessage
                    name="nascimento"
                    component="span"
                    className="text-danger small ml-2"
                  />
                  <Field
                    type="date"
                    name="nascimento"
                    className="form-control"
                  />
                </Col>
                <Col md={8} className="mb-2">
                  <BSForm.Label>Nome da Mãe</BSForm.Label>
                  <ErrorMessage
                    name="nome_mae"
                    component="span"
                    className="text-danger small ml-2"
                  />
                  <Field
                    type="text"
                    name="nome_mae"
                    className="form-control"
                  />
                </Col>
                <Col md={6} className="mb-2">
                  <BSForm.Label>Email</BSForm.Label>
                  <ErrorMessage
                    name="email"
                    component="span"
                    className="text-danger small ml-2"
                  />
                  <Field
                    type="email"
                    name="email"
                    className="form-control"
                  />
                </Col>
                <Col md={6} className="mb-2">
                  <BSForm.Label>Cidade</BSForm.Label>
                  <ErrorMessage
                    name="CidadeId"
                    component="span"
                    className="text-danger small ml-2"
                  />
                  <Select
                    noOptionsMessage={text => text.inputValue + " não encontrado!"}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    menuPortalTarget={document.body}
                    placeholder="Selecione..."
                    options={this.state.cidades}
                    value={this.state.cidades.find(option => values.CidadeId === option.value) ?? null}
                    onChange={option => {
                      setFieldValue('CidadeId', option ? option.value : null);
                    }}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={6} className="mb-2">
                  <ErrorMessage
                    name="foto_frente"
                    component="span"
                    className="text-danger small ml-2"
                  />{(!values.foto_frente &&
                    <AddAnexos
                      title={<><FontAwesomeIcon icon={faCamera} /> Frente do Doc. de Identidade</>}
                      multiple={false}
                      asDataURL={true}
                      accept="image/*"
                      onError={file => this.context.addToast({ titulo: "Erro", conteudo: "Falha ao carregar o arquivo " + file.name })}
                      onLoad={anexos => anexos.length === 1 ? setFieldValue('foto_frente', anexos[0].conteudo) : undefined}
                    />) || (
                      <>
                        <p>Clique na imagem para alterar</p>
                        <img className="mb-3"
                          src={values.foto_frente}
                          alt="Frente do RG"
                          onClick={() => setFieldValue("foto_frente", null)} style={{ maxHeight: 320, maxWidth: '100%' }} />
                      </>)}
                </Col>
                <Col md={6} className="mb-2">
                  <ErrorMessage
                    name="foto_verso"
                    component="span"
                    className="text-danger small ml-2"
                  />{(!values.foto_verso &&
                    <AddAnexos
                      title={<><FontAwesomeIcon icon={faCamera} /> Verso do Doc. de Identidade</>}
                      multiple={false}
                      asDataURL={true}
                      accept="image/*"
                      onError={file => this.context.addToast({ titulo: "Erro", conteudo: "Falha ao carregar o arquivo " + file.name })}
                      onLoad={anexos => anexos.length === 1 ? setFieldValue('foto_verso', anexos[0].conteudo) : undefined}
                    />) || (
                      <>
                        <p>Clique na imagem para alterar</p>
                        <img className="mb-3"
                          src={values.foto_verso}
                          alt="Verso do RG"
                          onClick={() => setFieldValue("foto_verso", null)} style={{ maxHeight: 320, maxWidth: '100%' }} />
                      </>)}
                </Col>
              </Row>
              <Row className="mt-3">
                <Col className="text-right">
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={() => this.context.closeModal()}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                    &nbsp; Cancelar
                  </Button>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    variant="primary"
                    className="ml-2"
                  >
                    <FontAwesomeIcon icon={faForward} />
                    &nbsp; Enviar Inscrição
                  </Button>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

export default EleitorFrontForm;
