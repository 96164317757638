import { faDownload, faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Col, Form as BSForm, FormGroup, Row } from "react-bootstrap";
import Request from "../../../request";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import AddAnexos from '../../AddAnexos';
import moment from "moment";
import { AppContext } from "../../../context";

class DocumentoForm extends React.Component {

  static contextType = AppContext;


  state = {
    classificacoes: [],
    setores: []
  }


  save(values, callback) {
    const method = this.props.documento.id ? "PUT" : "POST";
    const url =
      this.context.config.BACKEND_URL +
      "/documento" +
      (this.props.documento.id ? "/" + this.props.documento.id : "");

    Request(method, url, this.context.token)
      .send({ ...values })
      .then((res) => {
        this.context.addToast({
          titulo: "Successo",
          conteudo: "Registro atualizado com sucesso.",
        });
        callback();
        if (this.props.onSave) this.props.onSave();
        this.context.closeModal();
      })
      .catch((err) => {
        this.context.addToast({
          titulo: "Erro",
          conteudo: "Houve uma falha na gravação do registro.",
        });
        callback();
      });
  }

  componentDidMount() {
    Request("GET", this.context.config.BACKEND_URL + "/classificacaoDocumento/options", this.context.token)
      .then((res) => {
        this.setState(() => ({ classificacoes: res.body }));
      })
      .catch((err) => {
        this.context.addToast({
          titulo: "Erro",
          conteudo: "Erro na recuperação da lista de Classificações",
        });
      });

    Request("GET", this.context.config.BACKEND_URL + "/setor/options", this.context.token)
      .then((res) => {
        this.setState(() => ({ setores: res.body }));
      })
      .catch((err) => {
        this.context.addToast({
          titulo: "Erro",
          conteudo: "Erro na recuperação da lista de Assuntos",
        });
      });
  }

  render() {
    return (
      <Formik
        initialValues={{
          numero: this.props.documento.numero ?? "",
          expedicao: this.props.documento.expedicao ?? "",
          ClassificacaoId: this.props.documento.ClassificacaoId ?? "",
          SetorId: this.props.documento.SetorId ?? "",
          localizacao: this.props.documento.localizacao ?? "",
          descritores: this.props.documento.descritores ?? "",
          nome: this.props.documento.nome ?? "",
        }}
        validate={(values) => {
          const errors = {};
          if (!values.numero) {
            errors.numero = "Campo obrigatório";
          }
          if (!values.expedicao) {
            errors.expedicao = "Campo obrigatório";
          }
          if (!values.ClassificacaoId) {
            errors.ClassificacaoId = "Campo obrigatório";
          }
          if (!values.SetorId) {
            errors.SetorId = "Campo obrigatório";
          }
          if (!values.nome) {
            errors.nome = "Campo obrigatório";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          this.save(values, () => setSubmitting(false));
        }}
      >
        {({ isSubmitting, values, setFieldValue }) => {
          return (
            <Form>
              <Row>
                <Col md={3}>
                  <FormGroup>
                    <BSForm.Label>Número</BSForm.Label>
                    <ErrorMessage
                      name="numero"
                      component="span"
                      className="text-danger small ml-2"
                    />
                    <Field
                      type="text"
                      name="numero"
                      className="form-control"
                    />
                  </FormGroup>
                </Col>
                <Col md={9}>
                  <FormGroup>
                    <BSForm.Label>Classificação</BSForm.Label>
                    <ErrorMessage
                      name="ClassificacaoId"
                      component="span"
                      className="text-danger small ml-2"
                    />
                    <AsyncSelect
                      isClearable
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                      noOptionsMessage={() => "Nada encontrado."}
                      placeholder="Pesquisar..."
                      loadOptions={(inputValue, callback) => {
                        callback(this.state.classificacoes.filter(option => option.label.toLowerCase().includes(inputValue.toLowerCase())).slice(0, 50))
                      }}
                      value={this.state.classificacoes.find(
                        (option) => option.value === values.ClassificacaoId
                      ) ?? null}
                      onChange={(option) =>
                        setFieldValue(`ClassificacaoId`, option ? option.value : null)
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={9}>
                  <FormGroup>
                    <BSForm.Label>Setor de Origem</BSForm.Label>
                    <ErrorMessage
                      name="SetorId"
                      component="span"
                      className="text-danger small ml-2"
                    />
                    <Select
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                      noOptionsMessage={() => "Nada encontrado."}
                      placeholder="Pesquisar..."
                      options={this.state.setores.filter(option => option.value === values.SetorId || this.context.usuario.Permissoes.some(p => p.nome === 'documentos_orgao_global') || option.OrgaoId === this.context.usuario.Orgao.id)}
                      value={this.state.setores.find(
                        (option) => option.value === values.SetorId
                      ) ?? null}
                      onChange={(option) =>
                        setFieldValue(`SetorId`, option ? option.value : null)
                      }
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <BSForm.Label>Data de Expedição</BSForm.Label>
                    <ErrorMessage
                      name="expedicao"
                      component="span"
                      className="text-danger small ml-2"
                    />
                    <Field
                      type="date"
                      name="expedicao"
                      className="form-control"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <FormGroup>
                    <BSForm.Label>Data de Arquivamento</BSForm.Label>
                    <Field
                      disabled
                      value={moment(this.props.documento.createdAt).format("YYYY-MM-DDTHH:mm:ss")}
                      type="datetime-local"
                      name="createdAt"
                      className="form-control"
                    />
                  </FormGroup>
                </Col>
                <Col md={8}>
                  <FormGroup>
                    <BSForm.Label>Descritores (Palavras-chaves)</BSForm.Label>
                    <Field name="descritores" className="form-control" />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <BSForm.Label>Localização Física</BSForm.Label>
                    <Field
                      name="localizacao"
                      className="form-control"
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <BSForm.Label className="d-flex">
                    Arquivo
                    <ErrorMessage
                      name="nome"
                      component="span"
                      className="text-danger small ml-2"
                    />
                    {values.nome && <Button as="a" variant="link" size="sm" className="ml-auto p-0 text-danger" onClick={() => {
                      setFieldValue("arquivo", null);
                      setFieldValue("nome", null);
                    }}>Excluir</Button>}</BSForm.Label>
                  {!values.nome ? <AddAnexos
                    title="Adicionar Arquivo PDF"
                    accept="application/pdf"
                    multiple={false}
                    onError={file => this.context.addToast({ titulo: "Erro", conteudo: "Falha ao carregar o arquivo " + file.name })}
                    onLoad={anexos => {
                      setFieldValue('nome', anexos[0].nome);
                      setFieldValue('arquivo', anexos[0].conteudo);
                    }}
                  /> :
                    <Button className="form-control" variant="outline-secondary"><FontAwesomeIcon icon={faDownload} /> {values.nome}</Button>
                  }
                </Col>
              </Row>
              <FormGroup className="text-right mt-3">
                <Button
                  type="button"
                  variant="secondary"
                  onClick={() => this.context.closeModal()}
                >
                  <FontAwesomeIcon icon={faTimes} />
                  &nbsp; Fechar
                </Button>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  variant="primary"
                  className="ml-2"
                >
                  <FontAwesomeIcon icon={faSave} />
                  &nbsp; Gravar Alterações
                </Button>
              </FormGroup>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

export default DocumentoForm;
