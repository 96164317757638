import { faCalculator, faDownload, faFileAlt, faFileDownload, faFolderOpen, faList, faPencilAlt, faSave, faTimes, faTrash, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Col, Form as BSForm, Row, Tab, Table, Tabs } from "react-bootstrap";
import Request from "../../../request";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Select from 'react-select';
import CreatableSelect from "react-select/creatable";
import fileSize from "filesize";
import AddAnexos from "../../AddAnexos";
import moment from "moment";
import { AppContext } from "../../../context";


const formatter = new Intl.NumberFormat('pt-Br', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
});

class ContratoForm extends React.Component {

    static contextType = AppContext;

    state = {
        setores: [],
        orgaos: [],
        naturezas: [],
        modalidades: [],
        status: [],
        atributos: [],
        comissoes: [],
    }

    componentDidMount() {
        Request("GET", this.context.config.BACKEND_URL + "/setor/options", this.context.token)
            .send()
            .then((res) => {
                this.setState(() => ({ setores: res.body }));
            })
            .catch((err) => {
                this.context.addToast({
                    titulo: "Erro",
                    conteudo:
                        "Falha na recuperação da lista dos setores: " + err.toString(),
                });
            });
        Request("GET", this.context.config.BACKEND_URL + "/orgao/options", this.context.token)
            .send()
            .then((res) => {
                this.setState(() => ({ orgaos: res.body }));
            })
            .catch((err) => {
                this.context.addToast({
                    titulo: "Erro",
                    conteudo:
                        "Falha na recuperação da lista dos órgãos: " + err.toString(),
                });
            });

        Request("GET", this.context.config.BACKEND_URL + "/naturezaContrato/options", this.context.token)
            .send()
            .then((res) => {
                this.setState(() => ({ naturezas: res.body }));
            })
            .catch((err) => {
                this.context.addToast({
                    titulo: "Erro",
                    conteudo:
                        "Falha na recuperação da lista das naturezas: " + err.toString(),
                });
            });

        Request("GET", this.context.config.BACKEND_URL + "/statusContrato/options", this.context.token)
            .send()
            .then((res) => {
                this.setState(() => ({ status: res.body }));
            })
            .catch((err) => {
                this.context.addToast({
                    titulo: "Erro",
                    conteudo:
                        "Falha na recuperação da lista dos status do contrato: " + err.toString(),
                });
            });

        Request("GET", this.context.config.BACKEND_URL + "/modalidade/options", this.context.token)
            .send()
            .then((res) => {
                this.setState(() => ({ modalidades: res.body }));
            })
            .catch((err) => {
                this.context.addToast({
                    titulo: "Erro",
                    conteudo:
                        "Falha na recuperação da lista das modalidades: " + err.toString(),
                });
            });

        Request("GET", this.context.config.BACKEND_URL + "/comissao/options", this.context.token)
            .send()
            .then((res) => {
                this.setState(() => ({ comissoes: res.body }));
            })
            .catch((err) => {
                this.context.addToast({
                    titulo: "Erro",
                    conteudo:
                        "Falha na recuperação da lista das comissoes: " + err.toString(),
                });
            });

        Request("GET", this.context.config.BACKEND_URL + "/atributoContrato/options", this.context.token)
            .send()
            .then((res) => {
                this.setState(() => ({
                    atributos: res.body.map(atributo => {

                        const valores = (atributo.valores ? atributo.valores.split('\n').map(valor => ({
                            label: valor,
                            value: valor
                        })) : []);

                        const a = this.props.contrato.Atributos ? this.props.contrato.Atributos.find(a => a.id === atributo.value) : false;

                        if (a && !valores.find(valor => valor.value === a.AtributoContrato.valor)) {
                            valores.push({
                                label: a.AtributoContrato.valor,
                                value: a.AtributoContrato.valor
                            });
                        }

                        return { ...atributo, valores };
                    })
                }));
            })
            .catch((err) => {
                this.context.addToast({
                    title: "Erro",
                    text:
                        "Falha na recuperação da lista dos atributos do contrato: " + err.toString(),
                });
            });
    }

    save(values, callback) {

        const method = this.props.contrato.id ? 'PUT' : 'POST';
        const url = this.context.config.BACKEND_URL + '/contrato' + (this.props.contrato.id ? '/' + this.props.contrato.id : '');

        Request(method, url, this.context.token)
            .send(values)
            .then(res => {
                this.context.addToast({ titulo: "Successo", conteudo: "Registro atualizado com sucesso." });
                callback();
                if (this.props.onSave) this.props.onSave();
                this.context.closeModal();
            })
            .catch(err => {
                this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na gravação do registro." });
                callback();
            });

    }

    render() {
        return (
            <>

                <Formik
                    initialValues={{
                        ...this.props.contrato,
                        Atributos: this.props.contrato.Atributos ?? [],
                        Aditivos: this.props.contrato.Aditivos ?? [],
                        Apostilamentos: this.props.contrato.Apostilamentos ?? [],
                        AcompanhamentosVigentes: this.props.contrato.AcompanhamentosVigentes ?? [],
                        AcompanhamentosOutros: this.props.contrato.AcompanhamentosOutros ?? [],
                        Anexos: this.props.contrato.Anexos ?? []
                    }}
                    validate={values => {
                        const errors = {};

                        if (!values.numero) errors.numero = "Campo Obrigatório";
                        if (!values.data_publicacao) errors.data_publicacao = "Campo Obrigatório";
                        if (!values.OrgaoId) errors.OrgaoId = "Campo Obrigatório";
                        if (!values.SetorId) errors.SetorId = "Campo Obrigatório";
                        if (!values.cpf_cnpj) errors.cpf_cnpj = "Campo Obrigatório";
                        if (!values.nome) errors.nome = "Campo Obrigatório";
                        if (!values.NaturezaId) errors.NaturezaId = "Campo Obrigatório";
                        if (!values.ModalidadeId) errors.ModalidadeId = "Campo Obrigatório";
                        if (!values.StatusId) errors.StatusId = "Campo Obrigatório";
                        if (!values.objeto) errors.objeto = "Campo Obrigatório";
                        if (!values.inicio_vigencia) errors.inicio_vigencia = "Campo Obrigatório";
                        if (!values.fim_vigencia) errors.fim_vigencia = "Campo Obrigatório";
                        if (!values.valor_global) errors.valor_global = "Campo Obrigatório";


                        return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        this.save(values, () => setSubmitting(false));
                    }}
                >
                    {({ isSubmitting, values, setFieldValue, setValues }) => {
                        var saldo = values.valor_global;
                        return (
                            <>
                               
                                <Form>
                                    {console.log(this.state.modalidades)}
                                    <Tabs defaultActiveKey="detalhes">
                                        <Tab eventKey="detalhes" title={
                                            <div>
                                                <FontAwesomeIcon icon={faPencilAlt} /> <span className="d-none d-lg-inline">Detalhes</span>
                                            </div>
                                        }>
                                            <Row className="mt-2">
                                                <Col md={2}>
                                                    <BSForm.Label>Número</BSForm.Label><ErrorMessage name="numero" component="span" className="text-danger small ml-2" />
                                                    <Field type="text" name="numero" className="form-control" />
                                                </Col>
                                                <Col md={5}>
                                                    <BSForm.Label>Órgão Contratante</BSForm.Label><ErrorMessage name="OrgaoId" component="span" className="text-danger small ml-2" />
                                                    <Select
                                                        noOptionsMessage={() => "Nada encontrado."}
                                                        placeholder="Pesquisar..."
                                                        options={this.state.orgaos.filter(option => values.OrgaoId === option.value || this.context.usuario.Permissoes.some(p => p.nome === 'contratos_orgao_global') || option.value === this.context.usuario.Orgao.id)}
                                                        value={this.state.orgaos.find(
                                                            (option) => option.value === values.OrgaoId
                                                        )}
                                                        onChange={(option) => {
                                                            setValues({ ...values, OrgaoId: option ? option.value : null, SetorId: null });
                                                        }}
                                                    />
                                                </Col>
                                                <Col md={5}>
                                                    <BSForm.Label>Setor Demandante</BSForm.Label><ErrorMessage name="SetorId" component="span" className="text-danger small ml-2" />
                                                    <Select
                                                        noOptionsMessage={() => "Nada encontrado."}
                                                        placeholder="Pesquisar..."
                                                        options={this.state.setores.filter(option => (option.OrgaoId === values.OrgaoId) && (values.SetorId === option.value || this.context.usuario.Permissoes.some(p => p.nome === 'contratos_orgao_local') || option.value === this.context.usuario.Setor.id))}
                                                        value={this.state.setores.find(
                                                            (option) => option.value === values.SetorId
                                                        ) ?? null}
                                                        onChange={(option) =>
                                                            setFieldValue("SetorId", option ? option.value : null)
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="mt-2">
                                                <Col md={9}>
                                                    <BSForm.Label>Nome do Contratado</BSForm.Label><ErrorMessage name="nome" component="span" className="text-danger small ml-2" />
                                                    <Field type="text" name="nome" className="form-control" />
                                                </Col>
                                                <Col md={3}>
                                                    <BSForm.Label>CPF/CNPJ</BSForm.Label><ErrorMessage name="cpf_cnpj" component="span" className="text-danger small ml-2" />
                                                    <Field type="text" name="cpf_cnpj" className="form-control" />
                                                </Col>
                                            </Row>
                                            <Row className="mt-2">
                                                <Col md={4}>
                                                    <BSForm.Label>Natureza</BSForm.Label><ErrorMessage name="NaturezaId" component="span" className="text-danger small ml-2" />
                                                    <Select
                                                        noOptionsMessage={() => "Nada encontrado."}
                                                        placeholder="Pesquisar..."
                                                        options={this.state.naturezas}
                                                        value={this.state.naturezas.find(
                                                            (option) => option.value === values.NaturezaId
                                                        )}
                                                        onChange={(option) => {
                                                            setFieldValue("NaturezaId", option ? option.value : null)
                                                        }}
                                                    />
                                                </Col>
                                                <Col md={4}>
                                                    <BSForm.Label>Modalidade da Licitação</BSForm.Label><ErrorMessage name="ModalidadeId" component="span" className="text-danger small ml-2" />
                                                    <Select
                                                        noOptionsMessage={() => "Nada encontrado."}
                                                        placeholder="Pesquisar..."
                                                        options={this.state.modalidades}
                                                        value={this.state.modalidades.find(
                                                            (option) => option.value === values.ModalidadeId
                                                        )}
                                                        onChange={(option) => {
                                                            setFieldValue("ModalidadeId", option ? option.value : null)
                                                        }}
                                                    />
                                                </Col>
                                                <Col md={4}>
                                                    <BSForm.Label>Status do Contrato</BSForm.Label><ErrorMessage name="StatusId" component="span" className="text-danger small ml-2" />
                                                    <Select
                                                        noOptionsMessage={() => "Nada encontrado."}
                                                        placeholder="Pesquisar..."
                                                        options={this.state.status}
                                                        value={this.state.status.find(
                                                            (option) => option.value === values.StatusId
                                                        )}
                                                        onChange={(option) => {
                                                            setFieldValue("StatusId", option ? option.value : null)
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="mt-2">
                                                <Col md={12}>
                                                    <BSForm.Label>Objeto do Contrato</BSForm.Label><ErrorMessage name="objeto" component="span" className="text-danger small ml-2" />
                                                    <Field as="textarea" name="objeto" className="form-control" />
                                                </Col>
                                            </Row>
                                            <Row className="mt-2">
                                                <Col md={3}>
                                                    <BSForm.Label>Data da Publicação</BSForm.Label><ErrorMessage name="data_publicacao" component="span" className="text-danger small ml-2" />
                                                    <Field type="date" name="data_publicacao" className="form-control" />
                                                </Col>
                                                <Col md={3}>
                                                    <BSForm.Label>Início da Vigência</BSForm.Label><ErrorMessage name="inicio_vigencia" component="span" className="text-danger small ml-2" />
                                                    <Field type="date" name="inicio_vigencia" className="form-control" />
                                                </Col>
                                                <Col md={3}>
                                                    <BSForm.Label>Fim da Vigência</BSForm.Label><ErrorMessage name="fim_vigencia" component="span" className="text-danger small ml-2" />
                                                    <Field type="date" name="fim_vigencia" className="form-control" />
                                                </Col>
                                                <Col md={3}>
                                                    <BSForm.Label>Valor Global</BSForm.Label><ErrorMessage name="valor_global" component="span" className="text-danger small ml-2" />
                                                    <Field type="number" name="valor_global" step="0.01" className="form-control" />
                                                </Col>
                                            </Row>
                                        </Tab>
                                        <Tab eventKey="atributos" title={
                                            <div>
                                                <FontAwesomeIcon icon={faList} /> <span className="d-none d-lg-inline">Atributos</span>
                                            </div>
                                        }>
                                            <Table bordered condensed responsive striped className="mt-3" size="sm">
                                                <thead>
                                                    <tr>
                                                        <th>Atributo</th>
                                                        <th>Valor</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.atributos.map((atributo, key) => (
                                                        <tr key={key}>
                                                            <td>{atributo.label}</td>
                                                            <td>
                                                                <CreatableSelect
                                                                    isClearable={true}
                                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                                    noOptionsMessage={() => "Nada encontrado. Digite para incluir."}
                                                                    onCreateOption={label => {
                                                                        this.setState(state => ({
                                                                            atributos: state.atributos.map(option => ({
                                                                                ...option,
                                                                                valores: atributo !== option ? option.valores : [...option.valores, { label, value: label }]
                                                                            }))
                                                                        }), () => {
                                                                            const a = values.Atributos.find(a => a.id === atributo.value);
                                                                            if (!a) {
                                                                                values.Atributos.push({
                                                                                    id: atributo.value,
                                                                                    AtributoContrato: {
                                                                                        AtributoId: atributo.value,
                                                                                        ContratoId: values.id,
                                                                                        valor: label,
                                                                                    }
                                                                                })
                                                                            } else {
                                                                                a.AtributoContrato.valor = label;
                                                                            }
                                                                            setFieldValue(`Atributos`, values.Atributos);
                                                                        });
                                                                    }}
                                                                    placeholder="Pesquise ou Digite"
                                                                    formatCreateLabel={label => "Definir como " + label}
                                                                    options={atributo.valores}
                                                                    value={atributo.valores.find(option => option.value === (values.Atributos.find(a => a.id === atributo.value) ?? { AtributoContrato: { valor: null } }).AtributoContrato.valor)}
                                                                    onChange={option => {
                                                                        const a = values.Atributos.find(a => a.id === atributo.value);
                                                                        if (!a) {
                                                                            values.Atributos.push({
                                                                                id: atributo.value,
                                                                                AtributoContrato: {
                                                                                    AtributoId: atributo.value,
                                                                                    ContratoId: values.id,
                                                                                    valor: option.value
                                                                                }
                                                                            })
                                                                        } else {
                                                                            a.AtributoContrato.valor = option ? option.value : null;
                                                                        }
                                                                        setFieldValue(`Atributos`, values.Atributos);
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </Tab>
                                        <Tab eventKey="comissao" title={
                                            <div>
                                                <FontAwesomeIcon icon={faUsers} /> <span className="d-none d-lg-inline">Comissão</span>
                                            </div>
                                        }>
                                            <Row>
                                                <Col md={12} className="mt-3">
                                                    <BSForm.Label>Comissão de Recebimento/Fiscalização</BSForm.Label>
                                                    <Select
                                                        isClearable={true}
                                                        noOptionsMessage={() => "Nada encontrado."}
                                                        placeholder="Sem comissão definida"
                                                        options={this.state.comissoes.filter(option => this.context.usuario.Permissoes.some(p => p.nome === 'contratos_orgao_global') ? true : option.OrgaoId === this.context.usuario.Orgao.id)}
                                                        value={this.state.comissoes.find(
                                                            (option) => option.value === values.ComissaoId
                                                        )}
                                                        onChange={(option) => {
                                                            setFieldValue('ComissaoId', option ? option.value : null);
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                            {this.state.comissoes.find(c => c.value === values.ComissaoId) ?
                                                <Row>
                                                    <Col md={12} className="mt-3">
                                                        <legend>Membros da Comissão</legend>
                                                        <Table striped condensed>
                                                            <thead>
                                                                <tr>
                                                                    <th>Nome</th>
                                                                    <th>Função</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.comissoes.find(c => c.value === values.ComissaoId).Membros.map((membro, key) =>
                                                                    <tr key={key}>
                                                                        <td>{membro.Usuario.nome}</td>
                                                                        <td>{membro.Funcao.nome}</td>
                                                                    </tr>)}
                                                            </tbody>
                                                        </Table>
                                                    </Col>
                                                </Row> : null}
                                        </Tab>
                                        <Tab eventKey="aditivos" title={
                                            <div>
                                                <FontAwesomeIcon icon={faCalculator} /> <span className="d-none d-lg-inline">Aditivos/Supressões</span>
                                            </div>
                                        }>
                                            <Table striped size="sm" responsive className='mt-3'>
                                                <thead className='bg-light'>
                                                    <tr>
                                                        <th style={{ minWidth: "1%" }}>Nº</th>
                                                        <th style={{ minWidth: "1%" }}>Operação</th>
                                                        <th>Objeto</th>
                                                        <th style={{ minWidth: "1%" }}>Publicação</th>
                                                        <th style={{ width: "120px" }}>Vigência</th>
                                                        <th style={{ minWidth: "1%" }}>V. Global</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {values.Aditivos.map((aditivo, key) =>
                                                        <tr key={key}>
                                                            <td>{aditivo.numero}º</td>
                                                            <td style={{ whiteSpace: 'nowrap' }}>{aditivo.operacao} de {aditivo.alvo}</td>
                                                            <td>{aditivo.objeto}</td>
                                                            <td>{moment(aditivo.data_publicacao).format("DD/MM/YYYY")}</td>
                                                            <td>{moment(aditivo.inicio_vigencia).format("DD/MM/YYYY")} a<br />
                                                                {moment(aditivo.fim_vigencia).format("DD/MM/YYYY")}</td>
                                                            <td>{formatter.format(aditivo.valor_global)}</td>
                                                        </tr>)}
                                                </tbody>
                                            </Table>
                                        </Tab>
                                        <Tab eventKey="apostilamentos" title={
                                            <div>
                                                <FontAwesomeIcon icon={faFolderOpen} /> <span className="d-none d-lg-inline">Apostilamentos</span>
                                            </div>
                                        }>
                                            <Table striped size="sm" responsive className='mt-3'>
                                                <thead className='bg-light'>
                                                    <tr>
                                                        <th style={{ minWidth: "1%" }}>Nº</th>
                                                        <th>Objeto</th>
                                                        <th style={{ minWidth: "1%" }}>Publicação</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {values.Apostilamentos.map((apostilamento, key) =>
                                                        <tr key={key}>
                                                            <td>{apostilamento.numero}º</td>
                                                            <td>{apostilamento.objeto}</td>
                                                            <td>{moment(apostilamento.data_publicacao).format("DD/MM/YYYY")}</td>
                                                        </tr>)}
                                                </tbody>
                                            </Table>
                                        </Tab>
                                        <Tab eventKey="acompanhamentos" title={
                                            <div>
                                                <FontAwesomeIcon icon={faFileAlt} /> <span className="d-none d-lg-inline">Acompanhamentos</span>
                                            </div>
                                        }>
                                            <Tabs defaultActiveKey="acompanhamentos-vigentes" className="mt-3">
                                                <Tab title="Vigentes" eventKey="acompanhamentos-vigentes">
                                                    <Table striped size="sm" responsive className='mt-3'>
                                                        <thead className='bg-light'>
                                                            <tr>
                                                                <th>Relator</th>
                                                                <th style={{ width: "120px" }}>Criação</th>
                                                                <th style={{ width: "120px" }}>Referência</th>
                                                                <th style={{ width: "120px" }}>V. Faturado</th>
                                                                <th style={{ width: "120px" }}>Saldo</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {values.AcompanhamentosVigentes.map((acompanhamento, key) =>
                                                                <tr key={key}>
                                                                    <td>{acompanhamento.Usuario.nome}</td>
                                                                    <td>{moment(acompanhamento.createdAt).format("DD/MM/YYYY")}</td>
                                                                    <td>{moment(acompanhamento.inicio_referencia).format("MM/YYYY")}</td>
                                                                    <td>{formatter.format(acompanhamento.valor_faturado)}</td>
                                                                    <td>{formatter.format(saldo = saldo - acompanhamento.valor_faturado)}</td>
                                                                </tr>)}
                                                        </tbody>
                                                    </Table>
                                                </Tab>
                                                <Tab title="Outras Vigências" eventKey="acompanhamentos-outros">
                                                    <Table striped size="sm" responsive className='mt-3'>
                                                        <thead className='bg-light'>
                                                            <tr>
                                                                <th>Relator</th>
                                                                <th style={{ width: "120px" }}>Criação</th>
                                                                <th style={{ width: "120px" }}>Referência</th>
                                                                <th style={{ width: "120px" }}>V. Faturado</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {values.AcompanhamentosOutros.map((acompanhamento, key) =>
                                                                <tr key={key}>
                                                                    <td>{acompanhamento.Usuario.nome}</td>
                                                                    <td>{moment(acompanhamento.createdAt).format("DD/MM/YYYY")}</td>
                                                                    <td>{moment(acompanhamento.inicio_referencia).format("MM/YYYY")}</td>
                                                                    <td>{formatter.format(acompanhamento.valor_faturado)}</td>
                                                                </tr>)}
                                                        </tbody>
                                                    </Table>
                                                </Tab>
                                            </Tabs>
                                        </Tab>
                                        <Tab eventKey="anexos" title={
                                            <div>
                                                <FontAwesomeIcon icon={faFileDownload} /> <span className="d-none d-lg-inline">Anexos</span>
                                            </div>
                                        }>
                                            <Table striped size="sm" responsive className='mt-3'>
                                                <thead className='bg-light'>
                                                    <tr>
                                                        <th>Nome</th>
                                                        <th style={{ minWidth: "1%" }}>Data</th>
                                                        <th style={{ width: 120 }}>Tamanho</th>
                                                        <th style={{ width: 120, textAlign: 'center' }}>Ações</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {values.Anexos.map((anexo, key) =>
                                                        <tr key={key}>
                                                            <td>
                                                                {anexo.nome}
                                                            </td>
                                                            <td style={{ whiteSpace: 'nowrap' }}>
                                                                {moment(anexo.updatedAt).locale('pt-br').calendar()}
                                                            </td>
                                                            <td>
                                                                {fileSize(anexo.tamanho, { locale: 'pt', round: 0 })}
                                                            </td>
                                                            <td className='text-center'>
                                                                {anexo.id ?
                                                                    <Button as="a" href={`${this.context.config.BACKEND_URL}/anexo/contrato/${anexo.id}/${anexo.hash}/${anexo.nome}`} download={anexo.nome} size="sm" variant='info' title='Download' className='mr-2'>
                                                                        <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                                                                    </Button> : null}
                                                                <Button size="sm" type='button' variant='danger' title='Excluir'
                                                                    onClick={() => window.confirm("Deseja realmente excluir este arquivo?") && setFieldValue('Anexos', values.Anexos.filter(a => a !== anexo))}
                                                                >
                                                                    <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td colspan='4'>
                                                            <AddAnexos
                                                                multiple={true}
                                                                onError={file => this.context.addToast({ titulo: "Erro", conteudo: "Falha ao carregar o arquivo " + file.name })}
                                                                onLoad={anexos => setFieldValue('Anexos', [...values.Anexos, ...anexos])}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="4">
                                                            <ErrorMessage name="Anexos" component="span" className="text-danger small ml-2" />
                                                        </td>
                                                    </tr>
                                                </tfoot>
                                            </Table>
                                        </Tab>
                                    </Tabs>
                                    <Row className="text-right mt-3">
                                        <Col>
                                            <Button type="button" variant='secondary' onClick={() => this.context.closeModal()}>
                                                <FontAwesomeIcon icon={faTimes} />&nbsp;
                                                Fechar
                                            </Button>
                                            {(this.props.contrato.id && this.context.usuario.Permissoes.some(p => p.nome === 'contratos_contrato_update')) ||
                                                (!this.props.contrato.id && this.context.usuario.Permissoes.some(p => p.nome === 'contratos_contrato_create')) ?
                                                <Button type="submit" disabled={isSubmitting} variant='primary' className="ml-2">
                                                    <FontAwesomeIcon icon={faSave} />&nbsp;
                                                    Gravar Alterações
                                                </Button> : null}
                                        </Col>
                                    </Row>
                                </Form>
                            </>
                        );
                    }}
                </Formik>
            </>
        );
    }
}

export default ContratoForm;