import React from 'react';

import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackward, faCheck, faExclamationTriangle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Button, Card, Col, Row } from 'react-bootstrap';
import Request from '../../../request';
import { AppContext } from '../../../context';

class EleicoesConfirmPage extends React.Component {

    static contextType = AppContext;

    state = {
        loading: true,
        confirmed: false,
        mensagem: ""
    }

    componentDidMount() {
        Request("GET", this.context.config.BACKEND_URL + "/eleicoes/confirmar/" + this.props.params.uuid, this.context.token)
            .end((err, res) => {
                this.setState({ loading: false, confirmed: !err, mensagem: res?.text })
            });
    }

    render() {
        return <Row className='mt-5'>
            <Col lg={{ offset: 3, span: 6 }}>
                {(this.state.loading &&
                    <h3>
                        <FontAwesomeIcon spin icon={faSpinner} />&nbsp;Carregando...
                    </h3>
                ) || (this.state.confirmed &&
                    <>
                        <h3>
                            <FontAwesomeIcon icon={faCheck} />&nbsp;
                            Inscrição confirmada com sucesso!
                        </h3>
                        <Card className='mt-5'>
                            <Card.Header>{this.state.mensagem}</Card.Header>
                        </Card>
                    </>
                    ) || (
                        <>
                            <h3>
                                <FontAwesomeIcon icon={faExclamationTriangle} />&nbsp;
                                Não foi possível confirmar esta inscrição. Verifique os detalhes do erro abaixo:
                            </h3>
                            <Card className='mt-5'>
                                <Card.Header>{this.state.mensagem}</Card.Header>
                            </Card>
                        </>
                    )}
                <Button as="a" href={this.context.config.FRONTEND_URL + "/eleicoes"} className="mt-5">
                    <FontAwesomeIcon icon={faBackward} /> Voltar para o painel de Eleições
                </Button>
            </Col>
        </Row>
    }
}

const EleicoesConfirmPageWrapped =  (props) => <EleicoesConfirmPage {...props} params={useParams()} />;

export default EleicoesConfirmPageWrapped;
