
import RelatorioForm from "./RelatorioForm"
import RelatorioList from "./RelatorioList";
import RelatorioView from "./RelatorioView";
import RelatorioPrint from "./RelatorioPrint";

const RelatorioContents = {
    RelatorioForm,
    RelatorioList,
    RelatorioView,
    RelatorioPrint
};

export default RelatorioContents;