import React from 'react';
import EventoForm from './EventoForm';
import ModelList from '../ModelList';
import { AppContext } from '../../../context';

class EventoList extends React.Component {   

    static contextType = AppContext;
    
    //caso eu queira adicionar um novo botao, declaro explicitamente o onAction para assim atribuir novas funcionalidades sem quebrar o que ja existe
    onAction(action, update, defaultHandler){ 
        switch (action.name){
            case 'inscricoes':
                this.context.setContent("InscricaoList", {EventoId: action.id, title: "Lista de Inscrições do Evento " + action.evento_nome});
                break;
            default:
                defaultHandler();
                break;                
        }
    }

    render(){
        return (
            <ModelList 
                url={this.context.config.BACKEND_URL + '/evento'}
                title="Lista de Eventos"
                modelName="evento"
                modelIcon="faChalkboardTeacher"
                modelForm={EventoForm}
                useAdd={true}
                onAction={(action, update, defaultHandler) => this.onAction(action, update, defaultHandler)}
            />
        );
    }
}

export default EventoList;