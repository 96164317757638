import moment from 'moment';
import React from 'react';
import { Col, Form, FormControl, Row, Table } from 'react-bootstrap';
import logoSegup from '../img/segup.png';
import QRCode from "react-qr-code";
import { AppContext } from '../../../context';

class DeclaracaoPrint extends React.Component {

    static contextType = AppContext;

    formatter = new Intl.NumberFormat('pt-Br', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
    });

    componentDidMount() {
        window.print();
    }

    render() {
        return (

            <Form>

                <h2 className="text-center d-flex justify-content-between">
                    <div>
                        <b>Declaração de Bens e Valores</b>
                    </div>
                    <img src={logoSegup} alt="logo" height="35px" />
                </h2>
                <hr />
                <fieldset className='mt-3'>
                    <Row>
                        <Col sm={6}>
                            <strong >Declarante</strong>
                            <FormControl disabled={true} value={this.props.declaracao.Usuario.nome} />
                        </Col>
                        <Col sm={4}>
                            <strong >CPF</strong>
                            <FormControl disabled={true} value={this.props.declaracao.Usuario.login} />
                        </Col>
                        <Col sm={2}>
                            <strong>Exercício</strong>
                            <FormControl disabled={true} value={this.props.declaracao.exercicio} />
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col>
                            <strong >Órgão</strong>
                            <FormControl disabled={true} value={this.props.declaracao.Usuario.Orgao.nome} />
                        </Col>
                        <Col>
                            <strong >Setor</strong>
                            <FormControl disabled={true} value={this.props.declaracao.Usuario.Setor.nome} />
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col>
                            <strong >Razão da Apresentação da Declaração de Bens e Valores</strong> 
                            <FormControl disabled={true} as="textarea" value={this.props.declaracao.Razao.descricao} />
                        </Col>
                    </Row>
                </fieldset>
                <p className='mt-3'>
                    O Agente público estadual subscritor do presente instrumento, ciente dos termos do
                    Decreto Estadual nº. 1.712, de 12 de julho de 2021, declara para os devidos fins que:
                </p>

                {this.props.declaracao.Bens.length > 0 ?
                    <fieldset className='mt-3'>
                        <p>
                            Apresento a declaração de bens e valores que compõem o meu patrimônio, incluindo
                            cônjuge/companheiro(a) e dependentes, conforme segue:
                        </p>
                        <legend className='text-center'>Bens e Valores Declarados</legend>
                        <Table striped size="sm" responsive>
                            <thead className='bg-light'>
                                <tr>
                                    <th>Tipo</th>
                                    <th>Proprietário</th>
                                    <th>Descrição</th>
                                    <th style={{ width: `1%`, whiteSpace: `nowrap` }}>Valor (Dez/{this.props.declaracao.exercicio - 1})</th>
                                    <th style={{ width: `1%`, whiteSpace: `nowrap` }}>Valor (Dez/{this.props.declaracao.exercicio})</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.declaracao.Bens.map((bens, key) => (
                                    <tr key={key}>
                                        <td>
                                            {bens.Tipo.nome}
                                        </td>
                                        <td>
                                            {bens.Vinculo.nome}
                                        </td>
                                        <td>
                                            {bens.descricao}
                                        </td>
                                        <td className='text-right'>
                                            {this.formatter.format(bens.valor_anterior)}
                                        </td>
                                        <td className='text-right'>
                                            {this.formatter.format(bens.valor_atual)}
                                        </td>
                                    </tr>

                                ))}

                            </tbody>
                            <tfoot>
                                <tr className='bg-light'>
                                    <th colSpan={3}>
                                        Total
                                    </th>
                                    <th className='text-right'>
                                        {this.formatter.format(this.props.declaracao.Bens.reduce((total, bem) => total + (Number.isNaN(Number.parseFloat(bem.valor_anterior)) ? 0 : Number.parseFloat(bem.valor_anterior)), 0))}
                                    </th>
                                    <th className='text-right'>
                                        {this.formatter.format(this.props.declaracao.Bens.reduce((total, bem) => total + (Number.isNaN(Number.parseFloat(bem.valor_atual)) ? 0 : Number.parseFloat(bem.valor_atual)), 0))}
                                    </th>
                                </tr>
                            </tfoot>
                        </Table>
                    </fieldset> : (this.props.declaracao.Anexos.length > 0 ?
                    <>
                    <p>Apresento a declaração de bens e valores que compõem o meu patrimônio, incluindo
                            cônjuge/companheiro(a) e dependentes, conforme arquivos anexos e disponibilizados no(s) link(s) abaixo:
                    </p>
                    <p className='text-center'>
                        {this.props.declaracao.Anexos.map((anexo, key) => <QRCode size={200} key={key} value={this.context.config.BACKEND_URL + '/anexo/declaracao/' + anexo.id + '/' + anexo.hash} />)}
                    </p>
                    </> : 
                    <p className='mt-3'>
                        Não possuo bens ou direitos a informar que constem em meu nome ou de meu
                        cônjuge/companheiro(a) e dependentes.
                    </p>)}
                <p className='text-right mt-5'>
                    {this.props.declaracao.Usuario.Orgao.nome}, {moment(this.props.declaracao.updatedAt).utcOffset(0).format('DD [de] MMMM [de] YYYY')}.
                </p>
                <p className='text-center' style={{ marginTop: '4cm' }}>
                    <hr width='50%' />
                    {this.props.declaracao.Usuario.nome}<br />
                    CPF {this.props.declaracao.Usuario.login}
                </p>
            </Form>
        );
    }
}

export default DeclaracaoPrint;
