import {
  faClipboardList,
  faTicketAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { AppContext } from "../../../context";

class CautelaPage extends React.Component {
  
  static contextType = AppContext;

  render() {
    return (
      <div>
        <h1>Olá {this.context.usuario.nome}</h1>
        <h2>Bem-vindo ao painel de cautelas</h2>
        <hr />
        <Card>
          <Card.Header>Principais Tarefas</Card.Header>
          <Card.Body>
            <Row>
              <Col style={{ textAlign: "center" }}>
                <div
                  role="button"
                  onClick={() => this.context.setContent("MaterialList")}
                >
                  <FontAwesomeIcon size="2x" icon={faClipboardList} />
                  <br />
                  Ver lista de
                  <br />
                  Materiais
                </div>
              </Col>
              <Col style={{ textAlign: "center" }}>
                <div
                  role="button"
                  onClick={() => this.context.setContent("CautelaList")}
                >
                  <FontAwesomeIcon size="2x" icon={faTicketAlt} />
                  <br />
                  Ver lista de
                  <br />
                  Cautelas
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    );
  }
}

export default CautelaPage;