import React from 'react';
import CaracteristicaForm from './CaracteristicaForm';
import ModelList from '../ModelList';
import { AppContext } from "../../../context";


class CaracteristicaList extends React.Component {  
    
    static contextType = AppContext;

    render(){
        return (
            <ModelList 
                url={this.context.config.BACKEND_URL + '/caracteristica'}
                title="Lista de Características de Envolvidos"
                modelName="caracteristica"
                modelIcon="faUserSecret"
                modelForm={CaracteristicaForm}
                useAdd={true}
            />
        );
    }
}

export default CaracteristicaList;