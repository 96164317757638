import { Formik, Form, Field, ErrorMessage } from "formik";
import React from "react";
import Request from "../../../request";
import { Form as BSForm, FormGroup, Button } from "react-bootstrap";
import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "../../../context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import InputMask from "react-input-mask";

class UsuarioForm extends React.Component {
  static contextType = AppContext;

  state = {
    funcoes: [
      { value: "Aluno(a)", label: "Aluno(a)" },
      { value: "Psicólogo(a)", label: "Psicólogo(a)" },
      { value: "Assistente Social", label: "Assistente Social" },
      { value: "Professor(a)", label: "Professor(a)" },
      { value: "Diretor(a)", label: "Diretor(a)" },
    ],
    cidades: [],
    escolas: [],
  };

  componentDidMount() {
    Request(
      "GET",
      this.context.config.BACKEND_URL + "/cidade/options",
      this.context.token
    )
      .send()
      .then((res) => {
        this.setState(() => ({ cidades: res.body }));
      })
      .catch((err) => {
        this.context.addToast({
          title: "Erro",
          text: "Falha na recuperação da lista das cidades: " + err.toString(),
        });
      });

    Request(
      "GET",
      this.context.config.BACKEND_URL + "/alertaescola/escola/options",
      this.context.token
    )
      .send()
      .then((res) => {
        this.setState(() => ({ escolas: res.body }));
      })
      .catch((err) => {
        this.context.addToast({
          title: "Erro",
          text: "Falha na recuperação da lista das escolas: " + err.toString(),
        });
      });
  }
  save(values, callback) {
    const method = this.props.usuario.id ? "PUT" : "POST";
    const url =
      this.context.config.BACKEND_URL +
      "/alertaescola/usuario" +
      (this.props.usuario.id ? "/" + this.props.usuario.id : "");

    Request(method, url, this.context.token)
      .send(values)
      .then((res) => {
        this.context.addToast({
          titulo: "Successo",
          conteudo: "Registro atualizado com sucesso.",
        });
        callback();
        if (this.props.onSave) this.props.onSave();
        this.context.closeModal();
      })
      .catch((err) => {
        this.context.addToast({
          titulo: "Erro",
          conteudo: "Houve uma falha na gravação do registro.",
        });
        callback();
      });
  }

  render() {
    return (
      <Formik
        initialValues={{
          ...this.props.usuario,
          CidadeId: this.props.usuario?.Escola?.CidadeId
        }}
        validate={(values) => {
          const errors = {};
          if (!values.nome) {
            errors.nome = "Campo obrigatório";
          }
          if (!values.funcao) {
            errors.funcao = "Campo obrigatório";
          }
          if (!values.telefone) {
            errors.telefone = "Campo obrigatório";
          }
          if (!values.cpf) {
            errors.cpf = "Campo obrigatório";
          }
          if (!values.matricula) {
            errors.matricula = "Campo obrigatório";
          }
          if (!values.EscolaId) {
            errors.EscolaId = "Campo obrigatório";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          this.save(values, () => setSubmitting(false));
        }}
      >
        {({ isSubmitting, submitForm, values, setFieldValue, setValues }) => {
          return (
            <Form>
              <FormGroup>
                <BSForm.Label>Nome Completo</BSForm.Label>
                <ErrorMessage
                  name="nome"
                  component="span"
                  className="text-danger small ml-2"
                />
                <Field
                  type="text"
                  name="nome"
                  className="form-control"
                  placeholder="Digite seu Nome Completo"
                  required
                />
              </FormGroup>
              <FormGroup>
                <BSForm.Label>Função</BSForm.Label>
                <ErrorMessage
                  name="funcao"
                  component="span"
                  className="text-danger small ml-2"
                  required
                />
                <Select
                  name="funcao"
                  noOptionsMessage={() => "Nada encontrado."}
                  placeholder="Pesquisar..."
                  options={this.state.funcoes}
                  value={this.state.funcoes.find(
                    (option) => option.value === values.funcao
                  )}
                  onChange={(option) =>
                    setFieldValue("funcao", option?.value ?? null)
                  }
                />
              </FormGroup>
              <FormGroup>
                <BSForm.Label>Telefone</BSForm.Label>
                <ErrorMessage
                  name="telefone"
                  component="span"
                  className="text-danger small ml-2"
                  required
                />
                <InputMask
                  className="form-control"
                  mask="(99) 99999-9999"
                  onChange={(e) => setFieldValue("telefone", e.target.value)}
                  value={values.telefone}
                  required
                />
              </FormGroup>
              <FormGroup>
                <BSForm.Label>CPF</BSForm.Label>
                <ErrorMessage
                  name="cpf"
                  component="span"
                  className="text-danger small ml-2"
                />
                <InputMask
                  className="form-control"
                  mask="999.999.999-99"
                  onChange={(e) => setFieldValue("cpf", e.target.value)}
                  value={values.cpf}
                  required
                />
              </FormGroup>
              <FormGroup>
                <BSForm.Label>Matrícula</BSForm.Label>
                <ErrorMessage
                  name="matricula"
                  component="span"
                  className="text-danger small ml-2"
                />
                <Field
                  type="text"
                  name="matricula"
                  className="form-control"
                  placeholder="Apenas letras e numeros"
                  required
                />
              </FormGroup>

              <FormGroup>
                <BSForm.Label>Cidades</BSForm.Label>
                <ErrorMessage
                  name="CidadeId"
                  component="span"
                  className="text-danger small ml-2"
                />
                <Select
                  noOptionsMessage={() => "Nada encontrado."}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  menuPortalTarget={document.body}
                  placeholder="Pesquisar..."
                  options={this.state.cidades}
                  value={this.state.cidades.find(
                    (option) => option.value === values.CidadeId
                  )}
                  onChange={(option) => {
                    setValues({
                      ...values,
                      CidadeId: option.value,
                      EscolaId: null,
                    });
                  }}
                />
              </FormGroup>
              <FormGroup>
                <BSForm.Label>Escola</BSForm.Label>
                <ErrorMessage
                  name="EscolaId"
                  component="span"
                  className="text-danger small ml-2"
                />
                <Select
                  noOptionsMessage={() => "Nada encontrado."}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  menuPortalTarget={document.body}
                  placeholder="Pesquisar..."
                  options={this.state.escolas.filter(
                    (option) => option.CidadeId === values.CidadeId
                  )}
                  value={
                    this.state.escolas.find(
                      (escola) => escola.value === values.EscolaId
                    ) ?? null
                  }
                  onChange={(option) => setFieldValue(`EscolaId`, option.value)}
                />
              </FormGroup>
              <FormGroup className="text-right d-flex justify-content-between">
                <Button
                  type="button"
                  variant="secondary"
                  onClick={() => this.context.closeModal()}
                >
                  <FontAwesomeIcon icon={faTimes} />
                  &nbsp; Fechar
                </Button>
                <Button
                  type="button"
                  disabled={isSubmitting}
                  variant="primary"
                  className="ml-2"
                  onClick={() => {
                    submitForm();
                  }}
                >
                  <FontAwesomeIcon icon={faSave} />
                  &nbsp; Gravar Alterações
                </Button>
              </FormGroup>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

export default UsuarioForm;
