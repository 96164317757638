import { faEdit, faListAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faTwitter, faInstagram } from "@fortawesome/free-brands-svg-icons"
import React from "react";
import { Badge, Button, Col, Container, Image, Row } from "react-bootstrap";
import moment from "moment";
import EleitorFrontForm from "./EleitorFrontForm";
import Request from "../../../request";
import logo from '../../../img/LogoNova.png';
import { AppContext } from "../../../context";

class EleicoesFrontPage extends React.Component {

  static contextType = AppContext;

  state = {
    eleicoes: [],
  };

  componentDidMount() {
    Request("GET", this.context.config.BACKEND_URL + "/eleicoes/eleicao", this.context.token)
      .then((res) => {
        this.setState({
          eleicoes: res.body.map((eleicao) => {
            const agora = moment();
            const fim_inscricao = moment(eleicao.fim_inscricao);
            const inicio_inscricao = moment(eleicao.inicio_inscricao);
            const fim_votacao = moment(eleicao.fim_votacao);

            return {
              ...eleicao,
              andamento: agora < fim_votacao,
              aberta: agora < fim_inscricao && agora > inicio_inscricao,
            };
          }),
        });
      })
      .catch((err) => {
        this.context.addToast({
          titulo: "Erro",
          conteudo:
            "Erro na recuperação da lista de Eleições: " + err.toString(),
        });
      });
  }

  render() {
    const andamento = this.state.eleicoes.filter(({ andamento }) => andamento);
    const finalizada = this.state.eleicoes.filter(
      ({ andamento }) => !andamento
    );

    return (
      <Container fluid="lg" id="eleicoes">
        <Row className="bg-danger">
          <Col lg={8}>
            <h1 className="text-white text-center m-0 px-5 py-4 font-weight-bold">
              Eleições do Sistema Estadual de Segurança Pública e Defesa Social
            </h1>
          </Col>
          <Col lg={4} className="d-lg-flex d-md-none align-items-center">
            <Image fluid src={logo} />
          </Col>
        </Row>
        <Row className="bg-light pb-5">
          <Col lg={6}>
            <h3 className="mt-3 px-3 py-3 text-center">
              ELEIÇÕES EM ANDAMENTO
            </h3>
            {(andamento.length > 0 &&
              andamento.map((eleicao, key) => (
                <div key={key} className="my-3 text-white bg-dark">
                  <h5 className="m-0 p-3 bg-danger">{eleicao.nome}</h5>
                  <div className="mt-2 text-left">
                    <ul>
                      <li>
                        Inscrições:
                        <ul>
                          <li>Início: {moment(eleicao.inicio_inscricao).format(
                            "DD/MM/YYYY [às] HH:mm"
                          )}
                          </li>
                          <li>Fim: {moment(eleicao.fim_inscricao).format(
                            "DD/MM/YYYY [às] HH:mm"
                          )}
                          </li>
                        </ul>

                      </li>
                      <li>
                        Votação:
                        <ul>
                          <li>Início: {moment(eleicao.inicio_votacao).format(
                            "DD/MM/YYYY [às] HH:mm"
                          )}
                          </li>
                          <li>Fim: {moment(eleicao.fim_votacao).format(
                            "DD/MM/YYYY [às] HH:mm"
                          )}
                          </li>
                        </ul>

                      </li>
                    </ul>
                  </div>
                  <div className="m-2 p-2">                    
                    <Button
                      as="a"
                      href={
                        this.context.config.FRONTEND_URL +
                        "/eleicoes/eleicao/" +
                        eleicao.id
                      }
                      variant="outline-light"
                      className="botao my-2 w-100"
                    >
                      <FontAwesomeIcon icon={faListAlt} /> Ver detalhes
                    </Button>
                    {eleicao.aberta && (
                      <Button
                        variant="primary"
                        className="w-100 my-2 botao"
                        onClick={() =>
                          this.context.openModal({
                            titulo: "Cadastro de Eleitor",
                            size: "lg",
                            conteudo: (
                              <EleitorFrontForm eleicao={eleicao} />
                            ),
                          })
                        }
                      >
                        <FontAwesomeIcon icon={faEdit} /> Inscrever-se como
                        Eleitor
                      </Button>
                    )}
                  </div>
                </div>
              ))) || (
                <h5 className="text-center mt-3">Nenhuma eleição em andamento</h5>
              )}
          </Col>
          <Col lg={6}>
          <h3 className="mt-3 px-3 py-3 text-center">
              ELEIÇÕES FINALIZADAS
            </h3>

            {(finalizada.length > 0 &&
              finalizada.map((eleicao, key) => (
                <div key={key} className="my-3 text-white bg-dark">
                  <h5 className="m-0 p-3 bg-danger">{eleicao.nome}</h5>
                  <div className="mt-2 text-left">
                    <ul>
                      <li>
                        Inscrições:
                        <ul>
                          <li>Início: {moment(eleicao.inicio_inscricao).format(
                            "DD/MM/YYYY [às] HH:mm"
                          )}
                          </li>
                          <li>Fim: {moment(eleicao.fim_inscricao).format(
                            "DD/MM/YYYY [às] HH:mm"
                          )}
                          </li>
                        </ul>

                      </li>
                      <li>
                        Votação:
                        <ul>
                          <li>Início: {moment(eleicao.inicio_votacao).format(
                            "DD/MM/YYYY [às] HH:mm"
                          )}
                          </li>
                          <li>Fim: {moment(eleicao.fim_votacao).format(
                            "DD/MM/YYYY [às] HH:mm"
                          )}
                          </li>
                        </ul>

                      </li>
                    </ul>
                  </div>
                  <div className="m-2 p-2">                    
                    <Button
                      as="a"
                      href={
                        this.context.config.FRONTEND_URL +
                        "/eleicoes/eleicao/" +
                        eleicao.id
                      }
                      variant="outline-light"
                      className="botao my-2 w-100"
                    >
                      <FontAwesomeIcon icon={faListAlt} /> Ver detalhes
                    </Button>
                  </div>
                </div>
              ))) || (
                <h5 className="text-center mt-3">Nenhuma eleição finalizada</h5>
              )}
          </Col>
        </Row>
        <Row className="bg-danger">
          <Col lg={{ offset: 2, span: 8 }}>
            <h2 className="p-3 m-3 text-white text-center">DÚVIDAS FREQUENTES</h2>
          </Col>
        </Row>
        <Row className="bg-light text-dark py-5">
          <Col className="text-center" lg={4}>
            <h1 className="text-title text-center text-uppercase">
              Quem pode se inscrever como eleitor?
            </h1>
          </Col>
          <Col lg={8} className="d-flex align-items-center">
            <h5 className="flex-fill">
              São permitidos cadastros de cidadão brasileiro, nato ou naturalizado,
              com idade igual ou superior a 16 anos.
            </h5>
          </Col>
        </Row>

        <Row className="cor-eleicao text-white py-5">
          <Col className=" d-flex text-center align-items-center" lg={4}>
            <h1 className="text-title text-center text-uppercase">
              Quais os requisitos para se inscrever como eleitor?
            </h1>
          </Col>
          <Col lg={8} className="d-flex align-items-center">
            <h5 className="text flex-fill">
              Para a inscrição, são necessários os seguintes dados:
              <ol>
                <li>Nome completo</li>
                <li>CPF</li>
                <li>Data de Nascimento</li>
                <li>Nome da Mãe</li>
                <li>Email</li>
                <li>Município de Residência</li>
                <li>Foto (frente e verso) do documento de identidade</li>
              </ol>
            </h5>
          </Col>
        </Row>

        <Row className="bg-danger text-white py-5">
          <Col className="d-flex text-center align-items-center" lg={4}>
            <h1 className="text-title text-center text-uppercase">
              Como requerer a inscrição como eleitor?
            </h1>
          </Col>
          <Col lg={8} className="d-flex align-items-center">
            <h5 className="text">
              <ol>
                <li>
                  Acessar a Plataforma Integrada de Segurança Pública - PISP,
                  através do endereço eletrônico&nbsp;
                  <a
                    href={this.context.config.FRONTEND_URL + "/eleicoes"}
                    className="text-white"
                  >
                    {this.context.config.FRONTEND_URL + "/eleicoes"}
                  </a>
                  ;
                </li>
                <li>
                  Clicar na eleição correspondente e preencher o formulário de
                  cadastro on-line;
                </li>
                <li>
                  Todos os campos são de preenchimento obrigatório, sendo
                  admitida uma única inscrição por CPF em cada eleição;
                </li>
                <li>
                  Ao receber o e-mail de confirmação, deverá acessá-lo e
                  clicar no link <Badge variant="primary" >Confirmar Inscrição</Badge>.
                  A inscrição não será considerada válida se esta etapa não for concluída até o último dia da votação.
                </li>
                <li>
                  Caso o eleitor não receba o email de confirmação ou digite
                  incorretamente as informações, poderá realizar novo
                  cadastro.
                </li>
                <li>
                  O eleitor deverá requerer novo cadastro para cada eleição em
                  que desejar votar.
                </li>
              </ol>
            </h5>
          </Col>
        </Row>
        <Row className="bg-light text-dark py-5">
          <Col className="text-center" lg={4}>
            <h1 className="text-title text-center text-uppercase">
              Como são realizadas as votações?
            </h1>
          </Col>
          <Col lg={8} className="d-flex align-items-center">
            <h5 className="text flex-fill">
              <ul>
                <li>
                  No dia estipulado pelo edital, o eleitor receberá um email
                  com o link para registrar seu voto;
                </li>
                <li>
                  O link para votação é de uso único, não sendo possível
                  reutilizá-lo após o registro;
                </li>
                <li>
                  A validade do link também expira com o término do período de
                  votação;
                </li>
                <li>
                  O voto é sigiloso, não sendo possível identificar o eleitor
                  no momento do registro de sua opção.
                </li>
              </ul>
            </h5>
          </Col>
        </Row>
        <Row className="cor-eleicao text-white py-5">
          <Col className="text-center" lg={4}>
            <h1 className="text-title text-center text-uppercase">
              Como são divulgados os resultados das eleições?
            </h1>
          </Col>
          <Col lg={8} className="d-flex align-items-center">
            <h5 className="text flex-fill">
              O resultado das votações estará disponível conforme as regras do
              edital.
            </h5>
          </Col>
        </Row>
        <Row className="bg-dark text-white d-flex align-items-center">
          <Col className="text-center py-3" lg={4}>
            <h4>Secretaria de Segurança<br />Pública e Defesa Social</h4>
            R. Arcipreste Manoel Teodoro, 305.<br />
            Belém - PA (91) 3184-2500
          </Col>

          <Col className="text-center py-3" lg={4}>
            <h4 className="text-uppercase mb-4">Siga-nos</h4>
            <a
              className="btn btn-outline-light btn-social mx-1"
              href="https://www.facebook.com/Seguppa"
              rel="noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a
              className="btn btn-outline-light btn-social mx-1"
              href="http://www.twitter.com/seguppara"
              rel="noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon icon={faTwitter} />
            </a>
            <a
              className="btn btn-outline-light btn-social mx-1"
              href="http://www.instagram.com/segup.pa"
              rel="noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          </Col>
          <Col className="text-center py-3" lg={4}>
            <h4>Eleições do Sistema Estadual de Segurança Pública e Defesa Social</h4>
          </Col>
          <Col className="text-center" lg={12}>
            <small>Copyright © Ditel/SEGUP-PA 2020</small>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default EleicoesFrontPage;
