import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faFile, faSave, faTimes, faTrash, faUserShield } from '@fortawesome/free-solid-svg-icons';
import { Button, Card, Form as BSForm, FormControl, FormGroup, Image as BSImage, ListGroup, ListGroupItem, ProgressBar, Table } from "react-bootstrap";
import { ErrorMessage, Field, Formik, Form } from 'formik';
import { Col, Row } from 'react-bootstrap';
import AddAnexos from '../../AddAnexos';
import moment from 'moment';
import Request from "../../../request";
import fileSize from 'filesize';
import ReactSelect from 'react-select';

import * as faceapi from 'face-api.js';
import { AppContext } from '../../../context';

class CadastroForm extends React.Component {

    static contextType = AppContext;

    state = {
        situacoes: []
    };

    save(values, callback) {

        const method = this.props.cadastro.id ? 'PUT' : 'POST';
        const url = this.context.config.BACKEND_URL + '/cadastro' + (this.props.cadastro.id ? '/' + this.props.cadastro.id : '');

        Request(method, url, this.context.token)
            .send(values)
            .then(res => {
                this.context.addToast({
                    titulo: "Successo",
                    conteudo: "Registro atualizado com sucesso."
                });
                callback();
                this.context.setContent("CadastroList");
            })
            .catch(err => {
                this.context.addToast({
                    titulo: "Erro",
                    conteudo: "Houve uma falha na gravação do registro."
                });
                callback();
            });
    };

    setAnexos(anexos, callback) {
        Promise.all(anexos.map(anexo => {
            const img = new Image();
            img.src = anexo.conteudo;
            return new Promise((resolve, reject) => {
                faceapi.detectAllFaces(img).withFaceLandmarks().withAgeAndGender().withFaceExpressions()
                    .then(faces => {
                        faceapi.extractFaces(img, faces.map(face => face.detection.box))
                            .then(canvases =>
                                resolve(canvases.map((canvas, i) => ({
                                    id: null,
                                    conteudo: canvas.toDataURL(),
                                    score: faces[i].detection.score,
                                    box: faces[i].detection.box,
                                    expressao: faces[i].expressions,
                                    idade: faces[i].age,
                                    sexo: faces[i].gender,
                                    sexoP: faces[i].genderProbability,
                                    nome: anexos[0].nome,
                                    updatedAt: new Date(),
                                    dimensoes: `${Math.floor(canvas.width)}x${Math.floor(canvas.height)}`,
                                    tamanho: canvas.toDataURL().split(",")[1].length * 3 / 4,
                                })))
                            );
                    })
                    .catch(reject);
            });
        }))
            .then(results => {
                const faces = [];
                results.forEach(result => result.forEach(face => faces.push(face)));

                if (faces.length === 0) {
                    this.context.addToast({ titulo: "Erro", conteudo: "Não foi possível detectar uma face nas imagens!" });
                    return;
                } else if (faces.length > 1) {
                    this.context.addToast({ titulo: "Erro", conteudo: "Foi detectada mais de uma face na imagem!" });
                    return;
                } else {

                    const variants = ['danger', 'primary', 'info', 'success', 'secondary', 'dark', 'warning', 'light'];
                    const genders = { male: "Masculno", female: "Feminino" };
                    const gallery = faces.map((face, key) =>
                        <Row key={key}>
                            <Col md={12} className="lead my-3">
                                É recomendável que a fotografia seja no padrão passaporte, tendo pelo menos 200x200 pixels na região da face, com orelhas à mostra, sem obstrução nos olhos ou nariz, etc.
                            </Col>
                            <Col md={4}>
                                <BSImage width="100%" src={face.conteudo} />
                            </Col>
                            <Col>
                                <ListGroup>
                                    <ListGroupItem><b>Score:</b> {Math.floor(face.score * 100)}%</ListGroupItem>
                                    <ListGroupItem><b>Tamanho:</b> {Math.floor(face.box.width)} x {Math.floor(face.box.height)} px</ListGroupItem>
                                    <ListGroupItem><b>Idade:</b> {Math.floor(face.idade)} anos</ListGroupItem>
                                    <ListGroupItem><b>Sexo:</b> {genders[face.sexo]} ({Math.floor(face.sexoP * 100)}%)</ListGroupItem>
                                    <ListGroupItem><b>Expressões:</b>
                                        <ProgressBar>
                                            {Object.keys(face.expressao).map((tipo, key) =>
                                                <ProgressBar min={0} max={1} now={face.expressao[tipo]} label={`${Math.floor(face.expressao[tipo] * 100)} %`} title={tipo} variant={variants[key % variants.length]} />
                                            )}
                                        </ProgressBar>
                                    </ListGroupItem>
                                </ListGroup>
                            </Col>
                            <Col md={12} className="text-right">
                                <Button variant="secondary" className="mr-3" onClick={() => { this.context.closeModal() }} >
                                    <FontAwesomeIcon icon={faTimes} /> Cancelar
                                </Button>
                                <Button variant="primary" onClick={() => { this.context.closeModal(); callback(anexos) }} >
                                    <FontAwesomeIcon icon={faCheck} /> Incluir Imagem
                                </Button>
                            </Col>
                        </Row >
                    );

                    this.context.openModal({
                        size: "xl",
                        titulo: "Verifique se a imagem atende aos critérios",
                        conteudo: gallery
                    });

                };

            })
            .catch(err => {
                this.context.addToast({ titulo: "Erro", conteudo: "Erro na detecção de face:" + err.toString() });
            });;



    }

    componentDidMount() {
        Promise.all([
            faceapi.nets.ssdMobilenetv1.loadFromUri('/models'),
            faceapi.nets.ageGenderNet.loadFromUri('/models'),
            faceapi.nets.faceExpressionNet.loadFromUri('/models'),
            faceapi.nets.faceLandmark68Net.loadFromUri('/models')
        ]).then(() => {
            this.context.addToast({ titulo: "Sucesso", conteudo: "Módulos de detecção facial carregados com sucesso." })
        }).catch(err => {
            this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha no carregamento dos módulos de detecção facial." })
        });

        Request('GET', this.context.config.BACKEND_URL + '/situacao/options', this.context.token).send()
            .then(res => {
                this.setState(() => ({ situacoes: res.body }));
            })
            .catch(err => {
                this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na pesquisa das situações." })
            });
    };

    render() {
        return (
            <Formik
                initialValues={{
                    ...this.props.cadastro,
                    UsuarioId: this.context.usuario.id,
                    Anexos: (this.props.cadastro.Anexos ?? []).map(anexo => ({
                        ...anexo,
                        conteudo: Buffer.from(anexo.conteudo).toString()
                    })),
                }}
                validate={values => {
                    const errors = {};
                    if (!values.nome) {
                        errors.nome = 'Campo obrigatório';
                    }
                    if (!values.infopen) {
                        errors.infopen = 'Campo obrigatório';
                    } 
                    if (!values.SituacaoId) {
                        errors.SituacaoId = 'Campo obrigatório';
                    }
                    if (!values.descricao) {
                        errors.descricao = 'Campo obrigatório';
                    }
                    return errors;
                }}

                onSubmit={(values, { setSubmitting }) => {
                    this.save(values, () => setSubmitting(false));
                }}
            >
                {({ isSubmitting, values, setFieldValue }) => {
                    return (<>


                        <Form>

                            <h3 className="d-flex justify-content-between">
                                <div>
                                    <FontAwesomeIcon icon={faUserShield} />  Cadastro de Faces
                                </div>
                            </h3>
                            <hr />

                            <Row>
                                <Col md={6} className="mt-3">
                                    <BSForm.Label as="b">Registrador<span className="text"></span></BSForm.Label>
                                    <FormControl type="text" value={this.context.usuario.nome} disabled />
                                </Col>
                                <Col md={6} className="mt-3">
                                    <BSForm.Label as="b">Numero Infopen<span className="text"></span></BSForm.Label><ErrorMessage name="infopen" component="span" className="text-danger small ml-2" />
                                    <Field type="text" name="infopen" className="form-control" />
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6} className="mt-3">
                                    <BSForm.Label>Nome</BSForm.Label><ErrorMessage name="nome" component="span" className="text-danger small ml-2" />
                                    <Field type="text" name="nome" className="form-control" />
                                </Col>
                                <Col md={6} className="mt-3">
                                    <BSForm.Label>Situação</BSForm.Label><ErrorMessage name="SituacaoId" component="span" className="text-danger small ml-2" />
                                    <ReactSelect
                                        noOptionsMessage={() => "Nada encontrado."}
                                        placeholder="Selecione..."
                                        options={this.state.situacoes}
                                        value={this.state.situacoes.find(situacao =>
                                            situacao.value === values.SituacaoId)}
                                        onChange={option => {
                                            setFieldValue('SituacaoId', option.value ? option.value : null);
                                        }}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col md={12} className="mt-3">
                                    <BSForm.Label>Informação complementar</BSForm.Label><ErrorMessage name="descricao" component="span" className="text-danger small ml-2" />
                                    <Field as="textarea" name="descricao" className="form-control" />
                                </Col>
                            </Row>

                            <p className="mt-3">É recomendável que a fotografia seja no padrão passaporte, tendo pelo menos 200x200 pixels na região da face, com orelhas à mostra, sem obstrução nos olhos ou nariz, etc.</p>

                            <Card className='mt-3'>
                                <Card.Body title={
                                    <div title="Anexos">
                                        <FontAwesomeIcon icon={faFile} /> <span className="d-none d-lg-inline">Anexos</span>
                                    </div>
                                }>
                                    <Table striped size="sm" responsive className='my-2'>
                                        <thead className='bg-light'>
                                            <tr>
                                                <th style={{ width: 200 }}>Imagem</th>
                                                <th>Nome</th>
                                                <th style={{ minWidth: "1%" }}>Data</th>
                                                <th style={{ width: 120 }}>Tamanho</th>
                                                <th style={{ width: 120, textAlign: 'center' }}>Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {values.Anexos.filter(anexo => !anexo.deleted).map((anexo, key) =>
                                                <tr key={key}>
                                                    <td>
                                                        <img alt='Imagem' style={{ width: 200 }} src={anexo.conteudo} />
                                                    </td>
                                                    <td>
                                                        {anexo.nome}
                                                    </td>
                                                    <td style={{ whiteSpace: 'nowrap' }}>
                                                        {moment(anexo.updatedAt).locale('pt-br').calendar()}
                                                    </td>
                                                    <td>
                                                        {fileSize(anexo.tamanho, { locale: 'pt', round: 0 })}
                                                    </td>
                                                    <td className='text-center'>
                                                        <Button size="sm" type='button' variant='danger' title='Excluir'
                                                            onClick={() => window.confirm("Deseja realmente excluir este arquivo?") && setFieldValue('Anexos', [])}
                                                        >
                                                            <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
                                                        </Button>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                    {values.Anexos.length === 0 ? <AddAnexos
                                        accept="image/*"
                                        multiple={false}
                                        asDataURL={true}
                                        onError={error => { this.context.addToast({ titulo: "Erro", conteudo: "Falha ao carregar o arquivo " + error.toString() }) }}
                                        onLoad={anexos => this.setAnexos(anexos, anexos => setFieldValue('Anexos', anexos))}
                                    /> : null}
                                </Card.Body>
                            </Card>
                            <FormGroup className="mt-3 text-right">
                                <Button type="button" variant='secondary' onClick={() => this.context.setContent("CadastroList")}>
                                    <FontAwesomeIcon icon={faTimes} />&nbsp;
                                    Fechar
                                </Button>
                                <Button type="submit" disabled={isSubmitting} variant='primary' className="ml-2">
                                    <FontAwesomeIcon icon={faSave} />&nbsp;
                                    Gravar Alterações
                                </Button>
                            </FormGroup>
                        </Form>
                    </>
                    );
                }}
            </Formik>
        );
    }
}

export default CadastroForm;
