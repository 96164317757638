import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { AppContext } from "../../../context";
import RelatorioView from "./RelatorioView";

class RelatorioPrint extends React.Component {

    static contextType = AppContext;

    state = {
        titulo: ""
    }

    render() {
        return <>
            <h3>
                <FontAwesomeIcon icon={faFileAlt} /> {this.state.titulo}
            </h3>
            <hr />
            <RelatorioView RelatorioId={this.props.RelatorioId} hideButtons print setTitulo={titulo => this.setState({titulo})}/>
        </>;
    }

}

export default RelatorioPrint;