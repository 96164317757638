import EventosPage from "./EventosPage";
import EventoList from "./EventoList";
import EventoForm from "./EventoForm";
import InscricaoList from "./InscricaoList";
import InscricaoForm from "./InscricaoForm";
import InscricaoHandle from "./InscricaoHandle";

const EventosContents = {
    EventosPage,
    EventoList,
    EventoForm,
    InscricaoList,
    InscricaoForm,
    InscricaoHandle
};

export default EventosContents;