import React from 'react';
import AtividadeDocumentoForm from './AtividadeDocumentoForm';
import ModelList from '../ModelList';
import { AppContext } from '../../../context';

class AtividadeDocumentoList extends React.Component { 
    
    static contextType = AppContext;


    render(){
        return (
            <ModelList 
                url={this.context.config.BACKEND_URL + '/atividadeDocumento'}
                title="Lista de Atividades Documentais"
                modelName="atividade"
                modelIcon="faList"
                modelForm={AtividadeDocumentoForm}
                useAdd={true}
            />
        );
    }
}

export default AtividadeDocumentoList;

