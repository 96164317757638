import React from "react";
import { AppContext } from "../../context";

class Viewer extends React.Component {

    static contextType = AppContext;

    render(){
        return <object data={this.props.data} type={this.props.type} style={{width: "100%", minHeight: "600px"}}>
            Sem suporte a este tipo de objeto
        </object>;
    }
}

export default Viewer;