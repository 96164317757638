import { faBullhorn, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { AppContext } from "../../../context";

class HomeProMulher extends React.Component {
  static contextType = AppContext;

  render() {
    return (
      <>
        <hr />
        <Card>
          <Card.Body>
            <Row>
              <h3>
                <FontAwesomeIcon icon={faBullhorn} /> Seja bem-vindo ao Pró
                Mulher
              </h3>
              <hr />
              <Card className="p-3 bg-light text-dark mb-5 text-justify">
                <p>
                  O PROGRAMA PRÓ MULHER PARÁ foi lançado dia 08 de março do ano
                  de 2022 pelo Governo do Estado do Pará, na programação alusiva
                  ao Dia Internacional da Mulher. Desenvolvido pela Secretaria
                  de Estado de Segurança Pública e Defesa Social (SEGUP), o
                  programa é uma ação do Governo do Estado que oferece ações de
                  prevenção, repressão, assistência e gestão e governança. Uma
                  rede de serviços mobilizada a atender/ acolher mulheres em
                  situação de violência doméstica, bem como prevenir que a
                  violência aconteça. A necessidade da elaboração do presente
                  programa deu-se a partir do quadro de aumento da violência
                  doméstica no Pará. O levantamento da série histórica, entre os
                  anos de 2019 a 2022, no estado do Pará, aponta que houve
                  considerável número de registros de crimes cometidos contra
                  mulheres como lesão corporal, ameaça, injúria e estupro de
                  vulnerável.
                </p>
                <p>
                  Desenvolvido a partir de quatro eixos de trabalho: prevenção,
                  repressão, assistência e gestão e governança, com foco na
                  desconstrução da masculinidade tóxica. As ações repressivas
                  ocorrem a partir das ligações recebidas pelo canal de urgência
                  e emergência (190) em casos de necessidade de atendimento
                  imediato. Após os atendimentos, o Centro Integrado de
                  Operações - CIOP, realizada uma contagem do número de chamadas
                  realizadas pela mesma vítima, as ocorrências recorrentes
                  entram automaticamente no cadastro do “Cartão Programa”,
                  garantindo visitas periódicas e acompanhamento por meio das
                  equipes de patrulhamento especializado. Desde sua implantação,
                  o Programa já atendeu mais de 6.000 (seis mil) mulheres em
                  situação de violência doméstica. Implementado em 21
                  Municípios: Abaetetuba, Altamira, Ananindeua, Barcarena,
                  Belém, Bragança, Breves, Cametá, Castanhal, Dom Eliseu,
                  Marabá, Marituba, Paragominas, Redenção, Salinópolis, São
                  Miguel do Guamá, Santarém, Soure, Tailândia, Tucuruí, Xinguara
                  e Distrito de Mosqueiro.
                </p>
                <p>
                  Tem parceria com os órgãos de segurança pública do Estado,
                  guardas municipais e rede de atendimento e garantia de
                  direitos da mulher. A estes profissionais têm sido oferecidas
                  qualificações, dentro de suas competências, para o atendimento
                  das mulheres vítimas de violência doméstica nos municípios
                  onde o projeto foi lançado, visando contribuir para a
                  manutenção da integração de todos os órgãos que compõem a
                  rede. O presente projeto fornecerá um atendimento qualificado
                  célere, efetivo e com foco nas especificidades encontradas no
                  cenário de violência contra a mulher, bem como o protagonismo
                  das forças de segurança pública em educação e atendimento com
                  o diferencial de que os atendimento serão realizados por
                  profissionais previamente gabaritados por equipe especializada
                  da SEGUP, além de promover a aproximação entre Estado e
                  Sociedade Civil a partir dos cursos ofertados nos municípios,
                  bem como facilitar o acesso a informações sobre os Direitos da
                  Mulher e elevar a reflexão atinente a desconstrução da
                  masculinidade tóxica.
                </p>
                <p className="mt-3 text-right">
                  Fonte: Manual do Usuário Pró Mulher
                </p>
              </Card>
            </Row>
            {/*
            <Row>
              <Col style={{ textAlign: "center" }}>
                <h4>Deseja registrar uma denúncia?</h4>
                <div
                  role="button"
                  onClick={() =>
                    (window.location.href =
                      "/pro-mulher")
                  }
                >
                  <FontAwesomeIcon size="2x" icon={faEdit} />
                  <br />
                  Registrar
                  <br />
                  Denúncia
                </div>
              </Col>
            </Row>
            */}
          </Card.Body>
        </Card>
      </>
    );
  }
}

export default HomeProMulher;
