import React from "react";
import {Toast as BSToast} from 'react-bootstrap';
import { AppContext } from "../context";

class Toast extends React.Component {

    static contextType = AppContext;
    
    state = {
        show: true
    };

    close(){
        this.setState(state => ({show: false}));
        this.props.delToast();
    }

    render(){
        return (
            <BSToast onClose={() => this.close()} show={this.state.show} delay={this.props.delay? this.props.delay : 4000} autohide>
                <BSToast.Header>
                    <strong className="mr-auto">{this.props.titulo}</strong>
                </BSToast.Header>
                <BSToast.Body>{this.props.conteudo}</BSToast.Body>
            </BSToast>
        );
    }
}

export default Toast;