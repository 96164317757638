/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import LogoSegup from "./../../../img/segup.png";
import {
  Card,
  Col,
  Container,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Row,
  Table,
} from "react-bootstrap";
import moment from "moment";
import { AppContext } from "../../../context";

class CautelaPrint extends React.Component {
  static contextType = AppContext;

  componentDidMount() {
    //window.print();
  }

  render() {
    return (
      <>
        <Container>
          <div class="d-flex justify-content-between">
            <b>
              Governo do Estado do Pará
              <br />
              {this.props.cautela.Origem.Orgao.nome}
              <br />
              {this.props.cautela.Origem.nome}
            </b>

            <img src={LogoSegup} height={60} />
          </div>
          <hr />
          <Form>
            <h3 className="text-center mt-4">
              <b>Cautela de Materiais</b>
            </h3>
            <Row className="mt-2">
              <Col sm="2">
                <FormGroup>
                  <FormLabel>
                    <h4>Número</h4>
                  </FormLabel>
                  <FormControl disabled={true} value={this.props.cautela.id} />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <FormLabel>
                    <h4>Origem</h4>
                  </FormLabel>
                  <FormControl
                    disabled={true}
                    value={`${this.props.cautela.Origem.Orgao.sigla}/ ${this.props.cautela.Origem.sigla}`}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <FormLabel>
                    <h4>Destino</h4>
                  </FormLabel>
                  <FormControl
                    disabled={true}
                    value={`${this.props.cautela.Destino.Orgao.sigla}/ ${this.props.cautela.Destino.sigla}`}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <FormGroup>
                  <FormLabel>
                    <h4>Motivo</h4>
                  </FormLabel>
                  <br />
                  <Card>
                    <Card.Body>{this.props.cautela.motivo}</Card.Body>
                  </Card>
                </FormGroup>
              </Col>
            </Row>

            <h4 className="mt-2">Materiais</h4>
            <Table condensed striped className="tabela-cautela">
              <tbody className="text-nowrap text-center tabela-cautela">
                <tr>
                  <th className="col-4">
                    <h5 className="my-0">Descrição</h5>
                  </th>
                  <th className="col-2">
                    <h5 className="my-0">Marca</h5>
                  </th>
                  <th className="col-2">
                    <h5 className="my-0">Modelo</h5>
                  </th>
                  <th className="col-2">
                    <h5 className="my-0">Nº Série</h5>
                  </th>
                  <th className="col-2">
                    <h5 className="my-0">Patrimônio</h5>
                  </th>
                </tr>

                {this.props.cautela.Materiais.map((material, key) => (
                  <tr key={key}>
                    <td>{material.descricao}</td>
                    <td className="text-center">{material.marca}</td>
                    <td className="text-center">{material.modelo}</td>
                    <td className="text-center">{material.serial}</td>
                    <td className="text-center">{material.patrimonio}</td>
                  </tr>
                ))}
              </tbody>
              <tfoot></tfoot>
            </Table>
            <Table condensed striped className="tabela-cautela">
              <tbody className="text-nowrap tabela-cautela">
                <tr>
                  <th><h5>Quantidade</h5></th>
                  <td className="text-center">{this.props.cautela.Materiais.length}</td>
                </tr>
              </tbody>
              <tfoot></tfoot>
            </Table>
            <h4 className="mt-2">Entrega</h4>
            <Table
              condensed
              striped
              className="tabela-cautela text-center mb-0"
            >
              <tbody>
                <tr>
                  <th className="col-4">
                    <h5>Data da Entrega</h5>
                  </th>
                  <th className="col-4">
                    <center>
                      <h5>Prazo</h5>
                    </center>
                  </th>
                  <th className="col-4">
                    <h5>Data a Receber</h5>
                  </th>
                </tr>

                <td>
                  {moment(this.props.cautela.entrega)
                    .utcOffset(0)
                    .format("DD/MM/YYYY HH:mm")}
                </td>
                <td>
                  <center>{this.props.cautela.prazo} Dias</center>
                </td>
                <td>
                  {moment(this.props.cautela.entrega)
                    .add(this.props.cautela.prazo, "days")
                    .format("DD/MM/YYYY HH:mm")}
                </td>
              </tbody>
            </Table>
            <Table condensed striped className="tabela-cautela mt-0">
              <tbody>
                <tr className="text-center">
                  <th className="col-6">
                    <h5>Entregador</h5>
                  </th>
                  <th className="col-6">
                    <h5>Cautelante</h5>
                  </th>
                </tr>
                <tr>
                  <td>
                    <p>Assinatura:_______________________________________</p>
                    <p>Nome: {this.props.cautela.Entregador.nome}</p>
                    <p>Contato: {this.props.cautela.contato_entregador}</p>
                  </td>
                  <td>
                    <p>Assinatura:_______________________________________</p>
                    <p>Nome: {this.props.cautela.cautelante}</p>
                    <p>Contato: {this.props.cautela.contato_cautelante}</p>
                  </td>
                </tr>
              </tbody>
            </Table>

            <h4 className="mt-2">Devolução</h4>
            <Table condensed striped className="tabela-cautela">
              <tbody>
                <tr className="text-center">
                  <th className="col-6">
                    <h5>Recebedor</h5>
                  </th>
                  <th className="col-6">
                    <h5>Descautelante</h5>
                  </th>
                </tr>
                <tr>
                  <td>
                    <p>Assinatura:_______________________________________</p>
                    <p>
                      Nome:{" "}
                      {this.props.cautela.Recebedor
                        ? this.props.cautela.Recebedor.nome
                        : null}
                    </p>
                  </td>
                  <td>
                    <p>Assinatura:_______________________________________</p>
                    <p>Nome: {this.props.cautela.descautelante ?? null}</p>
                    <p>
                      Contato:{" "}
                      {this.props.cautela.contato_descautelante ?? null}
                    </p>
                  </td>
                </tr>
              </tbody>
            </Table>

            <Row className="mt-2">
              <Col>
                <FormGroup>
                  <FormLabel>
                    <h4>Observação</h4>
                  </FormLabel>
                  <Card>
                    <Card.Body>{this.props.cautela.observacao}</Card.Body>
                  </Card>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </Container>
      </>
    );
  }
}

export default CautelaPrint;
