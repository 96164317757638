import React from 'react';
import ComissaoForm from './ComissaoForm';
import ModelList from '../ModelList';

import { AppContext } from '../../../context';


class ComissaoList extends React.Component {

    static contextType = AppContext;

    render() {
        return (
            <>
            
                <ModelList
                    url={this.context.config.BACKEND_URL + '/comissao'}
                    title="Lista de Comissões"
                    modelName="comissao"
                    modelIcon="faUsers"
                    modelForm={ComissaoForm}
                    modalSize="lg"
                    useAdd={true}
                />

            </>

        );
    }
}

export default ComissaoList;

