import React from 'react';
import AtributoContratoForm from './AtributoContratoForm';
import ModelList from '../ModelList';
import { AppContext } from '../../../context';

class AtributoContratoList extends React.Component {    

    static contextType = AppContext;

    render(){
        return (
            <ModelList 
                url={this.context.config.BACKEND_URL + '/atributoContrato'}
                title="Lista de Atributos de Contrato"
                modelName="atributo"
                modelIcon="faThList"
                modelForm={AtributoContratoForm}
                useAdd={true}
            />
        );
    }
}


export default AtributoContratoList;

