import React from 'react';
import { AppContext } from '../../../context';
import ModelList from '../ModelList';
import ChapaForm from './ChapaForm';

class ChapaList extends React.Component {   

    static contextType = AppContext;

    render(){
        return (
            <ModelList 
                url={this.context.config.BACKEND_URL + '/chapa'}
                title="Lista de Chapas"
                modelName="chapa"
                modelIcon="faUserFriends"
                modelForm={ChapaForm}
                modalSize="lg"
                useAdd={this.context.usuario.Permissoes.some(p => p.nome === 'eleicoes_chapa_create')}
            />
        );
    }
    
}




export default ChapaList;