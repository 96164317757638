import { faClock, faIdCard, faKey, faSignOutAlt, faTachometerAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import logoSegup from '../img/segup.png';
import logoEleitoral from '../img/segup-eleitoral.png';
import MenuLeft from "./MenuLeft";
import { AppContext } from "../context";

class MenuTop extends React.Component {
    
    static contextType = AppContext;

    render() {
        return (
            <Navbar collapseOnSelect expand="lg" bg="light" variant="light" className="mb-3">
                <Navbar.Brand
                    role="button" as="span"
                    onClick={() => this.context.usuario ?
                        this.context.setContent("DashboardPage", {}, "Painel de Módulos", true) :
                        this.context.setContent("LoginForm", {}, 'PISP - Plataforma Integrada de Segurança Pública', true)}>
                    <img src={this.context.config.PERIODO_ELEITORAL ? logoEleitoral : logoSegup} alt="logo" height="35px" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">
                        {this.context.titulo}
                    </Nav>
                    {this.context.menus.length > 0 ?
                        <div className="d-block d-lg-none mt-2">
                            <MenuLeft menus={this.context.menus} onSelect={menu => this.context.setContent(menu.conteudo)} />
                        </div>
                        : null}
                    {this.context.usuario ?
                        <Nav>
                            <NavDropdown title={this.context.usuario.nome + ' - ' + this.context.usuario.Orgao.sigla}>
                                <NavDropdown.Item as='span' role="button" onClick={() => this.context.setContent("DashboardPage", {}, "Painel de Módulos", true)}><FontAwesomeIcon icon={faTachometerAlt} /> Dashboard</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item as='span' role="button" onClick={() => this.context.setContent("MyProfilePage", {}, "Dados Pessoais", true)}><FontAwesomeIcon icon={faIdCard} /> Meus Dados</NavDropdown.Item>
                                <NavDropdown.Item as='span' role="button" onClick={() => this.context.setContent("MyReportPage", {}, "Relatório de Acesso", true)}><FontAwesomeIcon icon={faClock} /> Relatório de Acesso</NavDropdown.Item>
                                <NavDropdown.Item as='span' role="button" onClick={() => this.context.setContent("ChangePasswordPage", {}, "Alteração de Senha", true)}><FontAwesomeIcon icon={faKey} /> Alteração de Senha</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item a='span' role="button" onClick={() => this.context.resetToken()}><FontAwesomeIcon icon={faSignOutAlt} /> Efetuar Logout</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                        : null}
                </Navbar.Collapse>
            </Navbar>
        );
    }
}

export default MenuTop;
