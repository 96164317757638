import React from 'react';
import { Button, Col, Row, Container, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faChartBar, faChartLine, faChartPie, faUserFriends, faVoteYea } from '@fortawesome/free-solid-svg-icons';
import Chart from '../../Chart';
import EleicoesDashboardDetalhes from './EleicoesDashboardDetalhes';
import moment from "moment";
import { AppContext } from '../../../context';

class EleicoesResultado extends React.Component {

    static contextType = AppContext;

    state = {
        dashboard: this.props.dashboard,
        eleicao: {
            Cargos: [],
        },
        periodo: "DD/MM/YYYY"
    }

    render() {
        const chapas = [];
        this.state.dashboard.votos.forEach(voto => {
            if (chapas.indexOf(voto.chapa) < 0) {
                chapas.push(voto.chapa);
            }
        })

        const resumo = [];
        this.state.dashboard.votos.forEach(voto => {
            const data = this.state.periodo ? moment(voto.data).format(this.state.periodo) : "Todos os Votos";
            const elemento = resumo.find(item => item.label === data);
            if (!elemento) {
                resumo.push({
                    label: data,
                    total: chapas.map(chapa => ({
                        label: chapa,
                        total: chapa === voto.chapa ? 1 : 0
                    }))
                });
            } else {
                elemento.total.find(item => item.label === voto.chapa).total++;
            }

        });

        const variants = ['primary', 'danger', 'warning', 'success', 'info'];
        return (

            <Container>
                <h3 className="">
                    <FontAwesomeIcon icon={faVoteYea} />
                    {this.props.dashboard.nome}
                </h3>
                <hr />
                <Row className="text-dark mt-3">
                    <Col>
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div><FontAwesomeIcon icon={faChartBar} /> Resultados</div>
                            </Card.Header>
                            <Card.Body>
                                <Row className="d-flex">
                                    {this.state.dashboard.totalEleitores.map((totalEleitor, key) =>
                                        <Col>
                                            <Card key={key} bg={variants[key % variants.length]} className="text-white mb-3" style={{ height: "150px" }}>
                                                <Card.Header>{totalEleitor.label}</Card.Header>
                                                <Card.Body className="d-flex align-items-end justify-content-end">
                                                    <h1>{totalEleitor.total}</h1>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    )}
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div><FontAwesomeIcon icon={faChartPie} /> Total de Eleitores</div>
                            </Card.Header>
                            <Card.Body>
                                <Chart data={this.state.dashboard.totalEleitores} />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col>
                        <h3><FontAwesomeIcon icon={faUserFriends} /> Chapas e Cargos</h3>
                    </Col>
                </Row>
                <hr />
                {this.state.dashboard.Cargos.map((cargo, keyCargo) =>
                    <Row key={keyCargo}>
                        <Col className="text-center">
                            <h2 className="duvidas_titulo text-center font-weight-bold">
                                {cargo.nome}
                            </h2>
                            <p className='text-justify'>
                                {cargo.descricao}
                            </p>
                        </Col>
                        <Col className="text-center justify-content-center d-flex flex-column">
                            <Row>
                                {cargo.Chapas.map((chapa, key) =>
                                    <Button className="form-control my-2"
                                        size="lg"
                                        variant="info"
                                        onClick={() => this.context.openModal({ titulo: chapa.nome_instituicao, size: "xl", conteudo: <EleicoesDashboardDetalhes chapa={chapa} /> })}
                                        key={key}
                                    >
                                        {chapa.sigla_instituicao ?? `Chapa ${key + 1}`}
                                    </Button>
                                )}
                            </Row>
                            <Row className='mt-3'>
                                <Card className='w-100'>
                                    <Card.Header className="d-flex justify-content-between">
                                        <div><FontAwesomeIcon icon={faChartPie} /> Total de Votos</div>
                                    </Card.Header>
                                    <Card.Body>
                                        <Chart hide={true} data={this.state.dashboard.totalVotos[keyCargo].total} />
                                    </Card.Body>
                                </Card>
                            </Row>

                        </Col>
                    </Row>
                )}
                <Row className="mt-5">
                    <Col>
                        <h3><FontAwesomeIcon icon={faChartLine} /> Histórico de Votos</h3>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col>
                        <Chart stacked={true} hide={true} data={resumo} />
                    </Col>
                </Row>
                <Row className='my-3'>
                    <Col className='d-flex justify-content-between'>
                        <Button className='mx-1 flex-grow-1' onClick={() => this.setState({ periodo: false })}>
                            <FontAwesomeIcon icon={faCalendar} /> <span className="d-none d-lg-inline">Todos os Votos</span>
                        </Button>
                        <Button className='mx-1 flex-grow-1' onClick={() => this.setState({ periodo: "DD/MM/YYYY" })}>
                            <FontAwesomeIcon icon={faCalendar} /> <span className="d-none d-lg-inline">Votos por Dia</span>
                        </Button>
                        <Button className='mx-1 flex-grow-1' onClick={() => this.setState({ periodo: "DD/MM/YYYY H[h]" })}>
                            <FontAwesomeIcon icon={faCalendar} /> <span className="d-none d-lg-inline">Votos por Hora</span>
                        </Button>
                        <Button className='mx-1 flex-grow-1' onClick={() => this.setState({ periodo: "DD/MM/YYYY HH:mm" })}>
                            <FontAwesomeIcon icon={faCalendar} /> <span className="d-none d-lg-inline">Votos por Minuto</span>
                        </Button>

                    </Col>
                </Row>
            </Container>

        );
    }
}

export default EleicoesResultado;