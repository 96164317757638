import React from 'react';
import ModelList from '../ModelList';
import { AppContext } from '../../../context';
import TipoMilitanciaForm from './TipoMilitanciaForm';

class TipoMilitanciaList extends React.Component {    

    static contextType = AppContext;

    render(){
        return (
            <ModelList 
                url={this.context.config.BACKEND_URL + '/ppddh/tipo_militancia'}
                title="Tipos de militancia"
                modelName="tipo_militancia"
                modelIcon="faThList"
                modelForm={TipoMilitanciaForm}
                useAdd={true}
            />
        );
    }
}


export default TipoMilitanciaList;
