import { Component } from 'react';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { AppContext } from '../../../context';
import Request from '../../../request';
import { Button, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import Select from "react-select";
import MarkerClusterGroup from 'react-leaflet-cluster';
import ViaturaMap from './ViaturaMap';
import L from 'leaflet';
import iconsUrl from '../../../assets/icons/police64.png';

const customIcon = L.icon({
    iconUrl: iconsUrl,
    iconSize: [64, 64], // substitua width e height pelas dimensões reais do seu ícone
  });


class InicialMap extends Component {

    static contextType = AppContext;


    state = {
        center: [-1.4631897998991463, -48.4955653127071868],
        viaturas: [],
        loaded: false,
        inicio: moment().subtract(1, 'days'),
        indexes: [0, 0],
        fim: moment(),
        orgaos: [],
        orgao: [],
        status: [
            { label: "Ligado", value: 0 },
            { label: "Desligado", value: 1 },
            { label: "Parado há pouco tempo", value: 2 },
            { label: "Parado há algumas horas", value: 3 },
            { label: "Parado há muito tempo", value: 4 },
        ],
        stats: []
    }

    fetch() {
        const query = this.state.orgao.map(orgao => `orgao=${orgao.sigla}`).join("&") + '&' + this.state.stats.map(stat => `status=${stat.value}`).join("&");
        Request('GET', this.context.config.BACKEND_URL + "/viatura/localizacoes/?" + query, this.context.token)
            .then(res => {

                const viaturas = res.body;

                this.setState({viaturas});                          

            })
            .catch(err => {
                console.log(err);
                this.context.addToast({ titulo: "Erro", conteudo: "Houve um erro ao recuperar os dados" });
            });
    }

    componentDidMount() {
        this.fetch();

        Request(
            "GET",
            this.context.config.BACKEND_URL + "/orgao/options",
            this.context.token
        )
            .send()
            .then((res) => {
                this.setState(() => ({ orgaos: res.body }));
            })
            .catch((err) => {
                this.context.addToast({
                    title: "Erro",
                    text: "Falha na recuperação da lista dos Órgãos: " + err.toString(),
                });
            });
        this.interval = setInterval(() => {
            this.fetch();
        }, 5000);    
    }

    componentWillUnmount(){
        clearInterval(this.interval);
    }


    render() {

        const mapOptions = {
            inertia: {
              // Configurações da inércia
              timeConstant: 200, // Constante de tempo da inércia em milissegundos
              maxSpeed: 1000, // Velocidade máxima em pixels por segundo
              easingFn: (t) => t * (2 - t), // Função de suavização para a inércia
            },
          };

        return <Row>
            <Col lg={12}>
                <h3><FontAwesomeIcon icon={faMapMarkedAlt} /> Localização em Tempo-Real</h3>
                <hr />
            </Col>
            <Col lg={6}>
                <b>Órgão</b>
                <Select className="mb-2"
                    options={this.state.orgaos}
                    isMulti={true}
                    value={this.state.orgaos.filter(option => this.state.orgao.indexOf(option) > -1)}
                    onChange={options => this.setState({ orgao: options }, () => this.fetch())}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    menuPortalTarget={document.body}
                />
            </Col>
            <Col lg={6}>
                <b>Status</b>
                <Select className="mb-2"
                    options={this.state.status}
                    isMulti={true}
                    value={this.state.status.filter(option => this.state.stats.indexOf(option) > -1)}
                    onChange={options => this.setState({ stats: options }, () => this.fetch())}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    menuPortalTarget={document.body}
                />
            </Col>
            <Col lg={12}>
                <MapContainer
                    className="markercluster-map"
                    center={this.state.center}
                    inertia={mapOptions.inertia}
                    zoom={15}
                    scrollWheelZoom='center'
                    style={{ minHeight: '70vh', height: '100%', width: '100wh' }}>
                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                    <MarkerClusterGroup >
                        {this.state.viaturas.map((viatura, key) =>
                            <Marker key={key} position={[viatura.latitude, viatura.longitude]} icon={customIcon}>
                                <Popup>
                                    <h2>{viatura.nome}</h2>
                                    <b>Placa/Prefixo:</b> {viatura.placa}/{viatura.prefixo}<br />
                                    <b>Órgão:</b> {viatura.orgao}<br />
                                    <b>Data/Hora:</b> {new Date(viatura.datahora).toLocaleString()}<br />
                                    <b>Posição:</b> {viatura.latitude}, {viatura.longitude}<br />
                                    <b>Velocidade:</b> {viatura.velocidade * 3.6} km/h<br />
                                    <Button className='form-control' onClick={() => this.context.openModal({ titulo: "Histórico de Localizações", conteudo: <ViaturaMap ViaturaId={viatura.id} />, size: "lg" })}>Rastrear Últimas 24h</Button>
                                </Popup>
                            </Marker>
                        )}
                    </MarkerClusterGroup>
                </MapContainer>
            </Col>
        </Row>
    }
}

export default InicialMap;