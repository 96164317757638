import {
  faDownload,
  faExclamationCircle,
  faSave,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  Button,
  Col,
  Form as BSForm,
  FormGroup,
  Row,
  Tab,
  Table,
  Tabs,
  Card,
} from "react-bootstrap";
import Request from "../../../request";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Map from "./pontos/MapEncaminhada";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import AddAnexos from "../../AddAnexos";
import Diacritics from "diacritic";
import { AppContext } from "../../../context";
import policeUnitsGeoJSON from './pontos/unidadesPoliciais.json';

class EncaminharDenunciaForm extends React.Component {
  static contextType = AppContext;

  state = {
    cidades: [],
    bairros: [],
    canais: [],
    caracteristicas: [],
    denuncias: [],
    atendentes: [],
    relacionamentos: [],
    orgaos: [],
    setores: [],
    idDoOrgao: 0,
    filteredPoliceUnits: [], // Lista de unidades filtradas
  };

  anexoRef = React.createRef();
  timeout = null;


  haversineDistance(coords1, coords2) {

    const lat1 = parseFloat(coords1.latitude);
    const lng1 = parseFloat(coords1.longitude);
    const lat2 = parseFloat(coords2.latitude);
    const lng2 = parseFloat(coords2.longitude);

    // Verifique se as coordenadas são números válidos
    if (isNaN(lat1) || isNaN(lng1) || isNaN(lat2) || isNaN(lng2)) {
      console.error("Valores de latitude ou longitude inválidos:", lat1, lng1, lat2, lng2);
      return NaN;
    }

    const toRad = (angle) => (Math.PI / 180) * angle;
    const R = 6371; // Raio da Terra em quilômetros

    const dLat = toRad(lat2 - lat1);
    const dLng = toRad(lng2 - lng1);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
      Math.sin(dLng / 2) * Math.sin(dLng / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c; // Retorne a distância em quilômetros
  }

  correctCoordinates(coordinates) {
    if (!coordinates || coordinates.length !== 2) {
      console.error("Coordenadas inválidas:", coordinates);
      return { latitude: NaN, longitude: NaN };
    }

    // Verifique a ordem e garanta que latitude vem primeiro
    const [longitude, latitude] = coordinates.map((coord) => parseFloat(coord));

    // Verifique se a conversão para número foi bem-sucedida
    if (isNaN(latitude) || isNaN(longitude)) {
      console.error("Valores de coordenadas inválidos após conversão:", latitude, longitude);
      return { latitude: NaN, longitude: NaN };
    }

    // Retorne as coordenadas no formato correto (latitude primeiro)
    return { latitude, longitude };
  }

  getNearestPoliceUnits(denunciaCoords) {
    if (!denunciaCoords.latitude || !denunciaCoords.longitude) {
      console.error("Coordenadas da denúncia inválidas:", denunciaCoords);
      return [];
    }
  
    const units = policeUnitsGeoJSON.features.map((feature) => {
      const { latitude, longitude, Name } = feature.properties;
  
      const correctedCoords = { latitude, longitude };
  
      console.log("Denúncia Coordinates:", denunciaCoords);
      console.log("Unidade Policial Coordinates:", correctedCoords);
  
      const distance = this.haversineDistance(denunciaCoords, correctedCoords);
  
      console.log(`Unit Name: ${Name}, Distance: ${distance}`);
  
      return { name: Name, ...correctedCoords, distance };
    });
  
    const nearestUnits = units
      .filter((unit) => !isNaN(unit.distance)) // Filtra unidades com distância válida
      .sort((a, b) => a.distance - b.distance)
      .slice(0, 3);
  
    console.log("Nearest Police Units: ", nearestUnits);
  
    return nearestUnits;
  }
  
  handleOrgaoAndStatusChange = (values, idDoOrgao) => {
    if (values.status === "Encaminhada" && idDoOrgao === 3) {
      const nearestUnits = this.getNearestPoliceUnits({
        latitude: values.latitude,
        longitude: values.longitude,
      });

      this.setState({ filteredPoliceUnits: nearestUnits });
    } else {
      this.setState({ filteredPoliceUnits: [] });
    }
  };


  save(values, update) {
    const url =
      this.context.config.BACKEND_URL +
      "/denuncia/encaminhar/" +
      this.props.encaminhar.id;
    Request("PUT", url, this.context.token)
      .send(values)
      .then((res) => {
        this.context.addToast({
          titulo: "Successo",
          conteudo: "Registro atualizado com sucesso.",
        });
        this.context.setContent("EncaminharDenunciaList");
      })
      .catch((err) => {
        this.context.addToast({
          titulo: "Erro",
          conteudo: "Houve uma falha na gravação do registro.",
          err,
        });
      });
  }

  handleTabSelect = () => {
    const errorHandler = (err) =>
      this.context.addToast({
        titulo: "Erro",
        conteudo: "Houve uma falha na pesquisa: " + err.toString(),
      });
    Request(
      "GET",
      this.context.config.BACKEND_URL +
      "/denuncia/relacionamentos/" +
      this.props.encaminhar.id,
      this.context.token
    )
      .send()
      .then((response) => {
        this.setState(() => ({ relacionamentos: response.body }));
      })
      .catch(errorHandler);
  };


  componentDidMount() {
    const errorHandler = (err) =>
      this.context.addToast({
        titulo: "Erro",
        conteudo: "Houve uma falha na pesquisa: " + err.toString(),
      });

    Request(
      "GET",
      this.context.config.BACKEND_URL + "/usuario/disquedenuncia/options",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ atendentes: options.body }));
      })
      .catch(errorHandler);

    Request(
      "GET",
      this.context.config.BACKEND_URL + "/canal/options",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ canais: options.body }));
      })
      .catch(errorHandler);
    Request(
      "GET",
      this.context.config.BACKEND_URL + "/natureza/options",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ naturezas: options.body }));
      })
      .catch(errorHandler);
    Request(
      "GET",
      this.context.config.BACKEND_URL + "/orgao/options",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ orgaos: options.body }));
      })
      .catch(errorHandler);
    Request(
      "GET",
      this.context.config.BACKEND_URL + "/setor/options",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ setores: options.body }));
      })
      .catch(errorHandler);

    Request(
      "GET",
      this.context.config.BACKEND_URL + "/cidade/options",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ cidades: options.body }));
      })
      .catch(errorHandler);

    Request(
      "GET",
      this.context.config.BACKEND_URL + "/bairro/options",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ bairros: options.body }));
      })
      .catch(errorHandler);
  }


  render() {
    const values = this.props.values || {};

    const center = [
      this.props.encaminhar?.latitude || 0,
      this.props.encaminhar?.longitude || 0,
    ];

    const options = [
      // { value: 'Em Registro', label: 'Em Registro' },
      // { value: 'Registrada', label: 'Registrada' },
      // { value: 'Devolvida', label: 'Devolvida' },
      { value: "Avaliada", label: "Avaliada" },
      { value: "Encaminhada", label: "Encaminhada" },
      // { value: 'Em Averiguação', label: 'Em Averiguação' },
      { value: "Finalizada", label: "Finalizada" },
      { value: "Arquivada", label: "Arquivada" },
    ];

    const motivo_arquivamento = [
      {
        value: "Denuncia com dados incompletos (Denunciado/Endereço/Relato",
        label: "Denuncia com dados incompletos (Denunciado/Endereço/Relato",
      },
      {
        value: "Denuncia direcionada a outro orgao",
        label: "Denuncia direcionada a outro orgao",
      },
      { value: "Denuncia duplicada", label: "Denuncia duplicada" },
      {
        value: "Denuncia com dados incompreensiveis/confusos",
        label: "Denuncia com dados incompreensiveis/confusos",
      },
    ];

    const motivo_devolucao = [
      { value: "Gramatica", label: "Gramatica" },
      { value: "Estrutura", label: "Estrutura" },
      { value: "Natureza", label: "Natureza" },
    ];



    return (
      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={{
          ...this.props.encaminhar,
          Anexos: this.props.encaminhar?.Anexos ?? [],
          Apensos: this.props.encaminhar?.Apensos ?? [],
          Historicos: this.props.encaminhar?.Historicos ?? [],
          CidadeId: this.props.encaminhar?.CidadeId ?? [],
          SetorId: this.props.encaminhar?.SetorId ?? [],
          BairroId: this.props.encaminhar?.BairroId,
          NaturezaId: this.props.encaminhar?.NaturezaId,
          UsuarioId:
            this.props.encaminhar?.UsuarioId ?? this.context.usuario.id,
          urgente: this.props.encaminhar?.urgente ?? false,
          copia: this.props.encaminhar?.copia ?? null,
        }}
        validate={(values) => {
          const errors = {};

          if (!values.descricao) {
            errors.descricao = "Campo obrigatório";
          }
          if (!values.CanalId) {
            errors.CanalId = "Campo obrigatório";
          }
          if (errors.length > 0) {
            this.context.addToast({
              titulo: "Erro",
              conteudo: "Verifique os campos obrigatórios e tente novamente.",
            });
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          if (values.status !== "Encaminhada") {
            delete values.OrgaoId;
            delete values.SetorId;
            delete values.copia;
          }

          this.save(values, () => setSubmitting(false));
        }}
      >
        {({ isSubmitting, values, setValues, setFieldValue }) => {

          return (
            <>
              <Form>
                <Tabs defaultActiveKey="dados-basicos" className="mb-3">
                  <Tab eventKey="dados-basicos" title={<span style={{ fontWeight: "bold" }}>DENUNCIA: {this.props.encaminhar.id}</span>}>
                    <FormGroup>
                      <Row>
                        <Col md={6} className="d-flex flex-column">
                          <Row>
                            <Col md={12}>
                              <small>
                                Arraste o mapa ou clique no botão de pesquisa
                                para encontrar um endereço.
                              </small>
                            </Col>
                          </Row>
                          <Row className="flex-grow-1">
                            <Col md="12">
                              <Map
                                onChange={(data) => {
                                  const CidadeId = (
                                    this.state.cidades.find(
                                      (cidade) =>
                                        Diacritics.clean(
                                          cidade.label
                                        ).toUpperCase() ===
                                        Diacritics.clean(
                                          data.cidade
                                        ).toUpperCase()
                                    ) ?? { value: null }
                                  ).value;
                                  const BairroId = (
                                    this.state.bairros.find(
                                      (bairro) =>
                                        bairro.CidadeId === CidadeId &&
                                        Diacritics.clean(
                                          bairro.label
                                        ).toUpperCase() ===
                                        Diacritics.clean(
                                          data.bairro
                                        ).toUpperCase()
                                    ) ?? { value: null }
                                  ).value;

                                  setFieldValue({
                                    ...values,
                                    ...data,
                                    CidadeId,
                                    BairroId,
                                  });
                                }}
                                center={center}
                                policeUnits={this.state.filteredPoliceUnits} // Delegacias mais próximas
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md="6">
                              <BSForm.Label as="b">Latitude</BSForm.Label>

                              <Field
                                type="text"
                                name="latitude"
                                className="form-control"
                                value={values.latitude}
                                readOnly
                              />
                            </Col>
                            <Col md="6">
                              <BSForm.Label as="b">Longitude</BSForm.Label>

                              <Field
                                type="text"
                                name="longitude"
                                className="form-control"
                                value={values.longitude}
                                readOnly
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Row>
                              <Col lg={4}>
                                <BSForm.Label as="b">
                                  Canal<span className="text-danger">*</span>
                                </BSForm.Label>
                                <ErrorMessage
                                  name="CanalId"
                                  component="span"
                                  className="text-danger small ml-2"
                                />
                                <Select
                                  name="CanalId"
                                  noOptionsMessage={() => "Nada encontrado."}
                                  placeholder="Pesquisar..."
                                  options={this.state.canais}
                                  value={
                                    this.state.canais
                                      ? this.state.canais.find(
                                        (option) =>
                                          option.value === values.CanalId
                                      )
                                      : ""
                                  }
                                  onChange={(option) =>
                                    setFieldValue(
                                      "CanalId",
                                      option ? option.value : null
                                    )
                                  }
                                />
                              </Col>
                              <Col lg={8}>
                                <BSForm.Label as="b">Natureza</BSForm.Label>

                                <Select
                                  name="NaturezaId"
                                  noOptionsMessage={() => "Nada encontrado."}
                                  placeholder="Pesquisar..."
                                  options={this.state.naturezas}
                                  value={
                                    this.state.naturezas
                                      ? this.state.naturezas.find(
                                        (option) =>
                                          option.value === values.NaturezaId
                                      )
                                      : ""
                                  }
                                  onChange={(option) => {
                                    setFieldValue(
                                      "NaturezaId",
                                      option ? option.value : null
                                    );
                                  }}
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                          {values.descricao_original != null ? (
                            <FormGroup>
                              <BSForm.Label as="b">
                                Descrição Original
                              </BSForm.Label>
                              <Field
                                readOnly
                                as="textarea"
                                name="descricao_original"
                                className="form-control"
                                value={values.descricao_original}
                              />
                            </FormGroup>
                          ) : null}
                          <FormGroup>
                            <BSForm.Label as="b">
                              Descrição<span className="text-danger">*</span>
                            </BSForm.Label>
                            <ErrorMessage
                              name="descricao"
                              component="span"
                              className="text-danger small ml-2"
                            />
                            <Field
                              as="textarea"
                              name="descricao"
                              className="form-control"
                              value={values.descricao}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Row>
                              <Col md="9">
                                <BSForm.Label as="b">Logradouro</BSForm.Label>

                                <Field
                                  type="text"
                                  name="logradouro"
                                  className="form-control"
                                  value={values.logradouro}
                                />
                              </Col>
                              <Col md="3">
                                <BSForm.Label as="b">Número</BSForm.Label>
                                <Field
                                  type="text"
                                  name="numero"
                                  className="form-control"
                                  value={values.numero}
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup>
                            <Row>
                              <Col md="6">
                                <BSForm.Label as="b">Cidade</BSForm.Label>

                                <Select
                                  name="CidadeId"
                                  noOptionsMessage={() => "Nada encontrado."}
                                  placeholder="Pesquisar..."
                                  options={this.state.cidades}
                                  value={
                                    this.state.cidades
                                      ? this.state.cidades.find(
                                        (option) =>
                                          option.value === values.CidadeId
                                      )
                                      : ""
                                  }
                                  onChange={(option) => {
                                    setFieldValue(
                                      "CidadeId",
                                      option ? option.value : null
                                    );
                                    setFieldValue("BairroId", null);
                                  }}
                                />
                              </Col>
                              <Col md="6">
                                <BSForm.Label as="b">Bairro</BSForm.Label>

                                <CreatableSelect
                                  name="BairroId"
                                  noOptionsMessage={() => "Nada encontrado."}
                                  onCreateOption={(label) => {
                                    this.setState(
                                      (state) => ({
                                        bairros: [
                                          {
                                            value: "new",
                                            label: label,
                                            CidadeId: values.CidadeId,
                                          },
                                          ...state.bairros,
                                        ],
                                      }),
                                      () => {
                                        setFieldValue("BairroId", "new");
                                        setFieldValue("bairro", label);
                                      }
                                    );
                                  }}
                                  placeholder="Pesquisar..."
                                  formatCreateLabel={(label) =>
                                    "Adicionar " + label
                                  }
                                  options={this.state.bairros.filter(
                                    (bairro) =>
                                      bairro.CidadeId === values.CidadeId
                                  )}
                                  value={this.state.bairros.find(
                                    (bairro) => bairro.value === values.BairroId
                                  )}
                                  onChange={(option) => {
                                    setFieldValue("BairroId", option.value);
                                    setFieldValue("bairro", option.label);
                                  }}
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup>
                            <BSForm.Label as="b">Estabelecimento</BSForm.Label>
                            <Field
                              type="text"
                              name="estabelecimento"
                              className="form-control"
                              value={values.estabelecimento}
                            />
                          </FormGroup>
                          <Row>
                            <Col lg={6}>
                              <BSForm.Label as="b">Referência</BSForm.Label>
                              <Field
                                type="text"
                                name="referencia"
                                className="form-control"
                                value={values.referencia}
                              />
                            </Col>
                            <Col lg={6}>
                              <BSForm.Label as="b">Status</BSForm.Label>
                              <Select
                                name="status"
                                options={options}
                                value={options.find(
                                  (option) => option.value === values.status
                                )}
                                onChange={(selectedOption) => {
                                  const selectedValue = selectedOption
                                    ? selectedOption.value
                                    : null;
                                  setFieldValue("status", selectedValue);
                                }}
                              />
                            </Col>
                          </Row>
                          {values.status === "Arquivada" ? (
                            <Row>
                              <Col lg={12}>
                                <BSForm.Label as="b">Motivo</BSForm.Label>
                                <Select
                                  name="motivo"
                                  noOptionsMessage={() => "Nada encontrado."}
                                  placeholder="Pesquisar..."
                                  options={motivo_arquivamento}
                                  value={
                                    values.motivo
                                      ? motivo_arquivamento.find(
                                        (motivo) =>
                                          motivo.value === values.motivo
                                      )
                                      : ""
                                  }
                                  onChange={(selectedMotivo) => {
                                    const motivoValue = selectedMotivo
                                      ? selectedMotivo.value
                                      : null;
                                    setFieldValue("motivo", motivoValue);
                                  }}
                                />
                              </Col>
                            </Row>
                          ) : null}

                          {values.status === "Devolvida" ? (
                            <Row>
                              <Col lg={12}>
                                <BSForm.Label as="b">Motivo</BSForm.Label>
                                <Select
                                  name="motivo"
                                  noOptionsMessage={() => "Nada encontrado."}
                                  placeholder="Pesquisar..."
                                  options={motivo_devolucao}
                                  value={
                                    values.motivo
                                      ? motivo_devolucao.find(
                                        (motivo) =>
                                          motivo.value === values.motivo
                                      )
                                      : ""
                                  }
                                  onChange={(selectedMotivo) => {
                                    const motivoValue = selectedMotivo
                                      ? selectedMotivo.value
                                      : null;
                                    setFieldValue("motivo", motivoValue);
                                  }}
                                />
                              </Col>
                              <Col>
                                <BSForm.Label as="b">Atendente </BSForm.Label>
                                <Select
                                  name="UsuarioID"
                                  noOptionsMessage={() => "Nada encontrado."}
                                  placeholder="Pesquisar..."
                                  options={this.state.atendentes}
                                  value={
                                    values.UsuarioId
                                      ? this.state.atendentes.find(
                                        (atendente) =>
                                          atendente.value === values.UsuarioId
                                      )
                                      : ""
                                  }
                                  onChange={(selectedAtendente) => {
                                    setFieldValue(
                                      "UsuarioId",
                                      selectedAtendente
                                        ? selectedAtendente.value
                                        : null
                                    );
                                  }}
                                />
                              </Col>
                            </Row>
                          ) : null}
                          <br></br>
                          <Row>
                            <Col lg={3}>
                              <div
                                style={{
                                  backgroundColor: "#ff6947",
                                  padding: "5px",
                                  borderRadius: "5px",
                                }}
                              >
                                <Field
                                  type="checkbox"
                                  name="urgente"
                                  className="text-right"
                                />
                                &nbsp;&nbsp;
                                <FontAwesomeIcon icon={faExclamationCircle} />
                                <BSForm.Label
                                  as="b"
                                  style={{ marginLeft: "10px" }}
                                >
                                  Urgente
                                </BSForm.Label>
                              </div>
                            </Col>
                          </Row>
                          {values.status === "Encaminhada" ? (
                            <Row>
                              <Col lg={12}>
                                <BSForm.Label as="b">Órgão</BSForm.Label>
                                <ErrorMessage
                                  name="OrgaoId"
                                  component="span"
                                  className="text-danger small ml-2"
                                />
                                <Select
                                  name="OrgaoId"
                                  noOptionsMessage={() => "Nada encontrado."}
                                  placeholder="Pesquisar..."
                                  menuPortalTarget={document.body}
                                  menuShouldBlockScroll={true}
                                  options={this.state.orgaos}
                                  value={
                                    this.state.orgaos
                                      ? this.state.orgaos.find(
                                        (option) => option.value === values.OrgaoId
                                      )
                                      : ""
                                  }
                                  onChange={(option) => {
                                    setFieldValue("OrgaoId", option ? option.value : null);
                                    const selectedOrgaoId = option ? option.value : null;
                                    // Atualiza o estado e, após a atualização, chama `handleOrgaoAndStatusChange`
                                    this.setState({ idDoOrgao: selectedOrgaoId }, () => {
                                      this.handleOrgaoAndStatusChange(values, this.state.idDoOrgao);
                                    });
                                  }}
                                />
                              </Col>

                              <Col lg={12} className="mt-2">
                                <BSForm.Label>Setor</BSForm.Label>
                                <ErrorMessage
                                  name="SetorId"
                                  component="span"
                                  className="text-danger small ml-2"
                                />
                                <Select
                                  name="SetorId"
                                  noOptionsMessage={() => "Nada encontrado."}
                                  placeholder="Pesquisar..."
                                  menuPortalTarget={document.body}
                                  menuShouldBlockScroll={true}
                                  options={this.state.setores.filter(
                                    (setor) => setor.OrgaoId === values.OrgaoId
                                  )}
                                  onChange={(option) => {
                                    setFieldValue("SetorId", option.value);
                                  }}
                                />
                              </Col>
                              <Col className="mt-3">
                                {values.status === "Encaminhada" &&
                                  values.SetorId &&
                                  this.state.setores.find(
                                    (setor) =>
                                      setor.value === values.SetorId &&
                                      setor.SuperiorNome
                                  ) && (
                                    <>
                                      <BSForm.Label>
                                        Setor Superior
                                      </BSForm.Label>
                                      <Card lg={12} className="bg-light">
                                        <Card.Body className="p-1">
                                          {
                                            this.state.setores.find(
                                              (setor) =>
                                                setor.value === values.SetorId
                                            ).SuperiorNome
                                          }
                                        </Card.Body>
                                      </Card>
                                    </>
                                  )}
                              </Col>

                              <Col lg={12} className="mt-2">
                                <BSForm.Label>Cópias</BSForm.Label>
                                <ErrorMessage
                                  name="copias"
                                  component="span"
                                  className="text-danger small ml-2"
                                />
                                <Select
                                  name="copia"
                                  isMulti
                                  menuPortalTarget={document.body}
                                  menuShouldBlockScroll={true}
                                  noOptionsMessage={() => "Nada encontrado."}
                                  placeholder="Pesquisar..."
                                  options={
                                    values.OrgaoId === undefined
                                      ? null
                                      : this.state.setores.filter(
                                        (setor) =>
                                          setor.OrgaoId === values.OrgaoId &&
                                          setor.value !== values.SetorId
                                      )
                                  }
                                  value={
                                    values.OrgaoId === undefined
                                      ? null
                                      : this.state.setores.filter((option) =>
                                        values.copia?.includes(option.value)
                                      )
                                  }
                                  onChange={(options) => {
                                    setFieldValue(
                                      "copia",
                                      options.map((option) => option.value)
                                    );
                                  }}
                                />
                              </Col>
                            </Row>
                          ) : null}
                        </Col>
                      </Row>
                    </FormGroup>
                  </Tab>
                  <Tab eventKey="anexos" title="Anexos">
                    <input
                      type="file"
                      multiple
                      ref={this.anexoRef}
                      className="d-none"
                      onChange={(event) => {
                        setFieldValue("Anexos", [
                          ...values.Anexos,
                          ...event.currentTarget.files,
                        ]);
                      }}
                    />
                    <Table striped size="sm" responsive className="mt-2">
                      <thead className="bg-light">
                        <tr>
                          <th>Nome</th>
                          <th style={{ width: 120, textAlign: "center" }}>
                            Ações
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {values.Anexos.map((anexo, key) => (
                          <tr key={key}>
                            <td>{anexo.nome ?? ""}</td>
                            <td className="text-center">
                              {anexo.id ? (
                                <Button
                                  as="a"
                                  href={`${this.context.config.BACKEND_URL}/anexo/disquedenuncia/${anexo.id}/${anexo.uuid}`}
                                  target="_blank"
                                  download={anexo.nome}
                                  size="sm"
                                  variant="info"
                                  title="Download"
                                  className="mr-2"
                                >
                                  <FontAwesomeIcon
                                    icon={faDownload}
                                  ></FontAwesomeIcon>
                                </Button>
                              ) : null}
                              <Button
                                size="sm"
                                type="button"
                                variant="danger"
                                title="Excluir"
                                onClick={() =>
                                  window.confirm(
                                    "Deseja realmente excluir este arquivo?"
                                  ) &&
                                  setFieldValue(
                                    "Anexos",
                                    values.Anexos.filter((a) => a !== anexo)
                                  )
                                }
                              >
                                <FontAwesomeIcon
                                  icon={faTrash}
                                ></FontAwesomeIcon>
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colspan="4">
                            <AddAnexos
                              multiple={true}
                              onError={(file) =>
                                this.context.addToast({
                                  titulo: "Erro",
                                  conteudo:
                                    "Falha ao carregar o arquivo " + file.name,
                                })
                              }
                              onLoad={(anexos) =>
                                setFieldValue("Anexos", [
                                  ...values.Anexos,
                                  ...anexos,
                                ])
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colspan="4">
                            <ErrorMessage
                              name="Anexos"
                              component="span"
                              className="text-danger small ml-2"
                            />
                          </td>
                        </tr>
                      </tfoot>
                    </Table>
                  </Tab>
                  {this.props.encaminhar?.Apensos?.length > 0 ? (
                    <Tab eventKey="apensos" title="Apenso">
                      {values.Apensos &&
                        values.Apensos.map((apenso, index) => (
                          <Row key={index} className="mb-2">
                            <Col>
                              <BSForm.Label as="b">Conteúdo</BSForm.Label>
                              <Field
                                as="textarea"
                                type="text"
                                name={`Apensos[${index}].conteudo`}
                                className="form-control"
                                disabled={true}
                                onChange={(e) =>
                                  setFieldValue(
                                    `Apensos[${index}].conteudo`,
                                    e.target.value
                                  )
                                }
                              />
                            </Col>
                          </Row>
                        ))}
                    </Tab>
                  ) : null}

                  <Tab
                    eventKey="historicos"
                    title={
                      <div>
                        <span className="d-none d-lg-inline">Histórico</span>
                      </div>
                    }
                  >
                    <Table striped size="sm" responsive className="mt-2">
                      <thead className="bg-light">
                        <tr>
                          <th>Descrição</th>
                          <th>Status</th>
                          <th>Usuário</th>
                        </tr>
                      </thead>
                      <tbody>
                        {values.Historicos.map((historico, key) => (
                          <tr key={key}>
                            <td>{historico.texto}</td>
                            <td style={{ width: "20%" }}>{historico.status}</td>
                            <td style={{ width: "30%" }}>
                              {historico.Usuario.nome}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <ErrorMessage
                      name="Processos"
                      component="span"
                      className="text-danger small ml-2"
                    />
                  </Tab>

                  <Tab
                    eventKey="relacionamentos"
                    title="Relacionamentos"
                    onEntering={this.handleTabSelect}
                  >
                    <Table striped responsive>
                      <thead className="bg-light">
                        <tr>
                          <th style={{ width: "16%" }}>Número da Denúncia</th>
                          <th>Descrição</th>
                          <th>Status</th>
                          <th style={{ width: "1%" }}>Coeficiente</th>
                          <th style={{ width: "1%" }}>Ações</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.relacionamentos.map(
                          (relacionamento, key) => (
                            <tr key={key}>
                              <td>{relacionamento.id}</td>
                              <td>{relacionamento.descricao}</td>
                              <td>{relacionamento.status}</td>
                              <td>{`${relacionamento.porcentagem}%`}</td>
                              <td></td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </Table>
                  </Tab>
                </Tabs>
                <FormGroup className="text-right">
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    variant="primary"
                    className="ml-2"
                  >
                    <FontAwesomeIcon icon={faSave} />
                    &nbsp; Gravar Alterações
                  </Button>
                </FormGroup>
              </Form>
            </>
          );
        }}
      </Formik>
    );
  }

}
export default EncaminharDenunciaForm;
