import GeoPage from "./GeoPage";
import CidadeList from "./CidadeList";
import BairroList from "./BairroList";
import RispList from "./RispList";
import CidadeForm from "./CidadeForm";
import BairroForm from "./BairroForm";
import RispForm from "./RispForm";
import AreaList from "./AreaList";
import AreaForm from "./AreaForm";


const GeoContents = {
    GeoPage,
    RispList,
    RispForm,
    CidadeForm,
    CidadeList,
    BairroForm,
    BairroList,
    AreaForm,
    AreaList    
};

export default GeoContents;