import React from 'react';
import StatusContratoForm from './StatusContratoForm';
import ModelList from '../ModelList';
import { AppContext } from '../../../context';

class StatusContratoList extends React.Component {    

    static contextType = AppContext;

    render(){
        return (
            <ModelList 
                url={this.context.config.BACKEND_URL + '/statusContrato'}
                title="Lista de Status de Contrato"
                modelName="status"
                modelIcon="faThList"
                modelForm={StatusContratoForm}
                useAdd={true}
            />
        );
    }
}

export default StatusContratoList;

