import React from 'react';
import BoletimEnvolvidoForm from './BoletimEnvolvidoForm';
import ModelList from '../ModelList';
import { AppContext } from '../../../context';
import Request from '../../../request';

class BoletimEnvolvidoList extends React.Component {

    static contextType = AppContext;

    //caso eu queira adicionar um novo botao, declaro explicitamente o onAction para assim atribuir novas funcionalidades sem quebrar o que ja existe
    onAction(action, update, defaultHandler) {
        switch (action.name) {
            case "print":
                Request('GET', this.context.config.BACKEND_URL + '/boletim/' + action.boletimId, this.context.token)
                    .then(res => {
                        this.context.setContent("BoletimPrint", { boletim: res.body });
                    })
                    .catch(err => {
                        this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na recuperação do registro: " + err.toString() });
                    });
                break;
            default:
                defaultHandler();
                break;
        }
    }

    render() {
        return (
            <ModelList
                url={this.context.config.BACKEND_URL + '/envolvido'}
                title="Lista de Envolvidos"
                modelName="envolvido"
                modelIcon="faUsers"
                modelForm={BoletimEnvolvidoForm}
                modalSize="xl"
                useAdd={false}
                onAction={(action, update, defaultHandler) => this.onAction(action, update, defaultHandler)}
            />
        );
    }
}

export default BoletimEnvolvidoList;