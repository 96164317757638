import { faCubes, faFileAlt, faFileMedical, faHome, faList, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Card } from 'react-bootstrap';
import { AppContext } from '../../../context';

class BoletimPage extends React.Component {
    static contextType = AppContext;
    render() {
        return <>
            <h3><FontAwesomeIcon icon={faHome} /> Página Inicial</h3>
            <hr />
            <Card>
                <Card.Header>
                    Principais Tarefas
                </Card.Header>
                <Card.Body className='d-flex justify-content-around'>
                    <div role="button" style={{ textAlign: 'center' }} onClick={() => this.context.setContent("BoletimList")}><FontAwesomeIcon size='3x' icon={faFileAlt} /><br />Listar Boletins</div>
                    <div role="button" style={{ textAlign: 'center' }} onClick={() => this.context.setContent("BoletimForm", { boletim: {} })}><FontAwesomeIcon size='3x' icon={faFileMedical} /><br />Adicionar Boletim</div>
                    <div role="button" style={{ textAlign: 'center' }} onClick={() => this.context.setContent("BoletimEnvolvidoList")}><FontAwesomeIcon size='3x' icon={faUsers} /><br />Buscar Envolvidos</div>
                    {this.context.usuario.Permissoes.find(p => p.nome === "boletins_admin") &&
                        <div role="button" style={{ textAlign: 'center' }} onClick={() => this.context.setContent("BoletimMaterialList")}><FontAwesomeIcon size='3x' icon={faCubes} /><br />Administrar Materiais</div>
                    }
                    {this.context.usuario.Permissoes.find(p => p.nome === "boletins_admin") &&
                        <div role="button" style={{ textAlign: 'center' }} onClick={() => this.context.setContent("BoletimAtributoList")}><FontAwesomeIcon size='3x' icon={faList} /><br />Administrar Atributos</div>
                    }
                </Card.Body>
            </Card>
        </>;
    }
}


export default BoletimPage;