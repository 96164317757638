import React from 'react';
import { AppContext } from '../../../context';
import Datatable from '../../Datatable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCarAlt } from '@fortawesome/free-solid-svg-icons';
import Request from '../../../request';
import VeiculosForm from "./VeiculosForm";


class VeiculosList extends React.Component {   

    static contextType = AppContext;
        
    onAction(action, callback) {
        switch (action.name) {
            case "edit":
                Request('GET', this.context.config.BACKEND_URL + '/veiculo/' + action.id, this.context.token)
                    .then(res => {
                        this.context.openModal({ titulo: "Busca de Veículos", conteudo: <VeiculosForm veiculo={res.body} onSave={() => callback()} /> });
                    })
                    .catch(err => {
                        this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na recuperação do registro: " + err.toString() });
                    });
                break;
            case "delete":
                if (window.confirm("Deseja realmente excluir este registro?")) {
                    Request('DELETE', this.context.config.BACKEND_URL + '/veiculo/' + action.id, this.context.token)
                        .then(res => {
                            callback();
                        })
                        .catch(err => {
                            this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na exclusão do registro: " + err.toString() });
                        });
                }
                break;
            default:
                break;
        }
    }

    render(){
        return (<>
            <h3><FontAwesomeIcon icon={faCarAlt}/> Lista de Veículos</h3>
            <hr />
            <Datatable 
                url={this.context.config.BACKEND_URL + '/veiculo'}                
                useAdd={true}
                onError={(err) => this.context.addToast({ titulo: "Erro", conteudo: err.toString() })}
                onAction={(action, update) => this.onAction(action, update)}
                onClickAdd={update => this.context.openModal({ titulo: 'Adicionar', conteudo: <VeiculosForm veiculo={{}} onSave={() => update()} /> })}
            />
        </>);
    }
}

export default VeiculosList;