import React from 'react';
import AditivoForm from './AditivoForm';
import ModelList from '../ModelList';
import { AppContext } from '../../../context';

class AditivoList extends React.Component {    

    static contextType = AppContext;

    render(){
        return (
            <ModelList 
                url={this.context.config.BACKEND_URL + '/aditivo'}
                title="Lista de Aditivos/Supressões"
                modelName="aditivo"
                modelIcon="faCalculator"
                modelForm={AditivoForm}
                modalSize="lg"
                useAdd={true}
            />
        );
    }
}

export default AditivoList;

