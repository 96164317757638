import { faPlus, faSave, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Col, Form as BSForm, Row, Tab, Table, Tabs } from "react-bootstrap";
import Request from "../../../request";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Select from 'react-select';
import moment from 'moment';
import { AppContext } from "../../../context";

const formatter = new Intl.NumberFormat('pt-Br', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
});

class ComissaoForm extends React.Component {

    static contextType = AppContext;

    state = {
        orgaos: [],
        usuarios: [],
        funcoes: [],
        usuario: {},
        funcao: {}
    }

    componentDidMount() {
        Request("GET", this.context.config.BACKEND_URL + "/orgao/options", this.context.token)
            .send()
            .then((res) => {
                this.setState(() => ({ orgaos: res.body }));
            })
            .catch((err) => {
                this.context.addToast({
                    titulo: "Erro",
                    conteudo:
                        "Falha na recuperação da lista dos orgaos: " + err.toString(),
                });
            });
        Request("GET", this.context.config.BACKEND_URL + "/usuario/options", this.context.token)
            .send()
            .then((res) => {
                this.setState(() => ({ usuarios: res.body }));
            })
            .catch((err) => {
                this.context.addToast({
                    titulo: "Erro",
                    conteudo:
                        "Falha na recuperação da lista dos usuarios: " + err.toString(),
                });
            });
        Request("GET", this.context.config.BACKEND_URL + "/funcaoMembro/options", this.context.token)
            .send()
            .then((res) => {
                this.setState(() => ({ funcoes: res.body }));
            })
            .catch((err) => {
                this.context.addToast({
                    titulo: "Erro",
                    conteudo:
                        "Falha na recuperação da lista das funções: " + err.toString(),
                });
            });
    }

    save(values, callback) {

        const method = this.props.comissao.id ? 'PUT' : 'POST';
        const url = this.context.config.BACKEND_URL + '/comissao' + (this.props.comissao.id ? '/' + this.props.comissao.id : '');

        Request(method, url, this.context.token)
            .send(values)
            .then(res => {
                this.context.addToast({ titulo: "Successo", conteudo: "Registro atualizado com sucesso." });
                if (this.props.onSave) this.props.onSave();
                this.context.closeModal();
            })
            .catch(err => {
                this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na gravação do registro." });

            })
            .finally(() => callback());

    }

    render() {
        return (
            <Formik
                initialValues={{
                    ...this.props.comissao,
                    data_publicacao: this.props.comissao.data_publicacao ? moment(this.props.comissao.data_publicacao).utcOffset(0).format("YYYY-MM-DD") : "",
                    Membros: this.props.comissao.Membros ?? []
                }}
                validate={values => {
                    const errors = {};
                    if (!values.portaria) {
                        errors.portaria = 'Campo obrigatório';
                    }
                    if (!values.data_publicacao) {
                        errors.data_publicacao = 'Campo obrigatório';
                    }
                    if (!values.OrgaoId) {
                        errors.OrgaoId = 'Campo obrigatório';
                    }
                    if (!values.descricao) {
                        errors.descricao = 'Campo obrigatório';
                    }
                    if (values.Membros.length < 1) {
                        errors.Membros = 'Inclua ao menos um membro';
                    }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    this.save(values, () => setSubmitting(false));
                }}
            >
                {({ isSubmitting, values, setFieldValue }) => {
                    return (
                        <Form>
                            <Tabs defaultActiveKey="detalhes">
                                <Tab eventKey="detalhes" title="Detalhes">
                                    <Row>
                                        <Col md={6} className="mt-2">
                                            <BSForm.Label>Nº da Portaria</BSForm.Label><ErrorMessage name="portaria" component="span" className="text-danger small ml-2" />
                                            <Field type="text" name="portaria" className="form-control" />
                                        </Col>
                                        <Col md={6} className="mt-2">
                                            <BSForm.Label>Data da Publicação</BSForm.Label><ErrorMessage name="data_publicacao" component="span" className="text-danger small ml-2" />
                                            <Field type="date" name="data_publicacao" className="form-control" />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} className="mt-2">
                                            <BSForm.Label>Órgão Gerenciador</BSForm.Label><ErrorMessage name="OrgaoId" component="span" className="text-danger small ml-2" />
                                            <Select
                                                noOptionsMessage={() => "Nada encontrado."}
                                                placeholder="Pesquisar..."
                                                options={this.state.orgaos.filter(option => this.context.usuario.Permissoes.some(p => p.nome === 'contratos_orgao_global') ? true : option.value === this.context.usuario.Orgao.id)}
                                                value={this.state.orgaos.find(
                                                    (option) => option.value === values.OrgaoId
                                                )}
                                                onChange={(option) => setFieldValue("OrgaoId", option ? option.value : null)}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} className="mt-2">
                                            <BSForm.Label>Descrição</BSForm.Label><ErrorMessage name="descricao" component="span" className="text-danger small ml-2" />
                                            <Field as="textarea" name="descricao" className="form-control" />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} className="mt-2"></Col>
                                    </Row>
                                    <legend>Membros da Comissão</legend>
                                    <Table striped condensed>
                                        <thead>
                                            <tr>
                                                <th>Nome</th>
                                                <th>Função</th>
                                                <th style={{ width: '1%' }}>Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {values.Membros.map((membro, key) =>
                                                <tr key={key}>
                                                    <td>{membro.Usuario.nome}</td>
                                                    <td>{membro.Funcao.nome}</td>
                                                    <td>
                                                        <Button className="form-control" variant="danger" size="sm" onClick={() => setFieldValue('Membros', values.Membros.filter(m => m !== membro))}>
                                                            <FontAwesomeIcon icon={faTrash} />
                                                        </Button>
                                                    </td>
                                                </tr>)}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td>
                                                    <Select
                                                        noOptionsMessage={() => "Nada encontrado."}
                                                        placeholder="Pesquisar..."
                                                        options={this.state.usuarios.filter(option => this.context.usuario.Permissoes.some(p => p.nome === 'contratos_orgao_global') || option.OrgaoId === this.context.usuario.Orgao.id)}
                                                        value={this.state.usuario}
                                                        onChange={(usuario) => this.setState({ usuario })}
                                                    />
                                                </td>
                                                <td>
                                                    <Select
                                                        noOptionsMessage={() => "Nada encontrado."}
                                                        placeholder="Pesquisar..."
                                                        options={this.state.funcoes}
                                                        value={this.state.funcao}
                                                        onChange={(funcao) => this.setState({ funcao })}
                                                    />
                                                </td>
                                                <td>
                                                    <Button className="form-control" size="sm" variant="success" onClick={() => setFieldValue('Membros', [...values.Membros, {
                                                        id: null,
                                                        Usuario: {
                                                            id: this.state.usuario.value,
                                                            nome: this.state.usuario.label,
                                                        },
                                                        Funcao: {
                                                            id: this.state.funcao.value,
                                                            nome: this.state.funcao.label,
                                                        }
                                                    }])}>
                                                        <FontAwesomeIcon icon={faPlus} />
                                                    </Button>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </Table>
                                    <ErrorMessage name="Membros" component="span" className="text-danger small ml-2" />
                                </Tab>
                                {this.props.comissao.Contratos?
                                <Tab eventKey="contratos" title="Contratos">
                                    <Table striped size="sm" responsive className='mt-3'>
                                        <thead className='bg-light'>
                                            <tr>
                                                <th style={{ minWidth: "1%" }}>Número</th>
                                                <th style={{ minWidth: "1%" }}>Órgão/Setor</th>
                                                <th>Objeto</th>
                                                <th style={{ width: 120 }}>Vigência</th>
                                                <th style={{ width: 120 }}>V. Global</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.comissao.Contratos.map((contrato, key) =>
                                                <tr key={key}>
                                                    <td style={{whiteSpace: 'nowrap'}}>{contrato.numero}</td>
                                                    <td style={{whiteSpace: 'nowrap'}}>{contrato.Orgao.sigla}/{contrato.Setor.sigla}</td>
                                                    <td>{contrato.objeto}</td>
                                                    <td>
                                                        {moment(contrato.inicio_vigencia).format("DD/MM/YYYY")} a {moment(contrato.fim_vigencia).format("DD/MM/YYYY")}
                                                    </td>
                                                    <td style={{whiteSpace: 'nowrap'}}>{formatter.format(contrato.valor_global)}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </Tab>:null}
                            </Tabs>
                            <Row className="text-right">
                                <Col md={12}>
                                    <Button type="button" variant='secondary' onClick={() => this.context.closeModal()}>
                                        <FontAwesomeIcon icon={faTimes} />&nbsp;
                                        Fechar
                                    </Button>
                                    <Button type="submit" disabled={isSubmitting} variant='primary' className="ml-2">
                                        <FontAwesomeIcon icon={faSave} />&nbsp;
                                        Gravar Alterações
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    );
                }}
            </Formik>
        );
    }
}

export default ComissaoForm;