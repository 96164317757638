import React from "react";
import { Badge, Button, Col, Row, Tab, Tabs } from "react-bootstrap";
import AlertaMap from "./AlertaMap";
import moment from "moment";
import AlertaModal from "./AlertaModal";
import Request from "../../../request";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faBellSlash, faBullhorn, faSyncAlt, faVolumeMute, faVolumeUp } from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "../../../context";

import notification from '../../../assets/sounds/notification.wav';
import alert from '../../../assets/sounds/alert.wav';

class AlertaPage extends React.Component {

  static contextType = AppContext;

  state = {
    alertas: [],
    online: false,
    ticket: null,
    uuid: null,
    loaded: false,
    center: null,
    playAlert: true
  };

  socket = null;
  interval = null;

  alertWav = new Audio(alert);
  notificationWav = new Audio(notification);

  update() {
    Request("GET", this.context.config.BACKEND_URL + "/alerta/painel", this.context.token)
      .then((res) => {
        this.setState({ ...res.body, loaded: true }, () => {
          if (this.socket) this.socket.close();

          this.socket = new WebSocket(this.context.config.WEBSOCKET_URL);

          this.socket.onerror = err => {
            this.context.addToast({ titulo: "Erro", conteudo: "Falha na comunicação com o servidor: " + err.toString() });
            this.setState({ online: false });
          };

          this.socket.onclose = err => {
            this.setState({ online: false });
          };

          this.socket.onopen = () => {
            this.socket.send(this.state.ticket);
            this.context.addToast({ titulo: "Sucesso", conteudo: "Conectado ao servidor." });
            this.setState({ online: true });
          };

          this.socket.onmessage = (msg) => {
            try {
              const update = JSON.parse(msg.data);

              if (update.uuid !== this.state.uuid) update.blink = true;

              if (this.state.alertas.some((a) => a.uuid === update.uuid)) {
                this.setState((state) => ({
                  alertas: state.alertas.map((alerta) =>
                    alerta.uuid !== update.uuid ? alerta : update
                  )
                }), () => this.state.playNotification && this.notificationWav.play());
              } else {
                this.setState((state) => ({
                  alertas: [...state.alertas, update],
                }), () => this.state.playAlert && this.alertWav.play());
              }
            } catch (err) {
              this.context.addToast({
                titulo: "Erro",
                conteudo: "Erro na atualização dos alertas: " + msg.data,
              });
            }
          };
        });
      })
      .catch((err) => {
        this.context.addToast({
          titulo: "Erro",
          conteudo: "Erro na recperação dos alertas: " + err.toString(),
        });
      });
  }

  componentDidMount() {
    this.update();
    this.interval = setInterval(() => {
      if (!this.state.online) this.update();
    }, 30000);
  }

  componentWillUnmount() {
    if (this.socket) {
      this.socket.close();
    }
    clearInterval(this.interval);
  }

  openAlertaModal(alerta) {
    this.context.openModal({
      size: "xl",
      scrollable: false,
      conteudo: <AlertaModal uuid={alerta.uuid} />,
      titulo: "Gerenciamento do Alerta",
      onClose: () => this.setState({ uuid: null }),
    })
  }

  render() {
    const tabs = [
      {
        title: "Pendentes",
        status: "pendentes",
        alertas: this.state.alertas.filter(
          (alerta) => alerta.status === "Pendente"
        ),
        variant: "warning",
      },
      {
        title: "Aceitos",
        status: "aceitos",
        alertas: this.state.alertas.filter(
          (alerta) => alerta.status === "Aceito"
        ),
        variant: "success",
      },
      {
        title: "Despachados",
        status: "despachados",
        alertas: this.state.alertas.filter(
          (alerta) => alerta.status === "Despachado"
        ),
        variant: "info",
      },
    ];

    return (
      <div className="h-100 d-flex flex-column">
        <h3 className="d-flex align-items-center">
          <div>
            <FontAwesomeIcon icon={faBullhorn} />
            &nbsp;Painel de Gerenciamento de Alertas
          </div>
          <div className="ml-auto d-flex">
            <Button
              className="mr-2 flex-grow-1"
              variant={this.state.playNotification ? "success" : "danger"}
              onClick={() => this.setState(state => ({ playNotification: !state.playNotification }))}
            >
              <FontAwesomeIcon icon={this.state.playNotification ? faBell : faBellSlash} /> Notificações
            </Button>
            <Button
              className="mr-2 flex-grow-1"
              variant={this.state.playAlert ? "success" : "danger"}
              onClick={() => this.setState(state => ({ playAlert: !state.playAlert }))}
            >
              <FontAwesomeIcon icon={this.state.playAlert ? faVolumeUp : faVolumeMute} /> Alarmes
            </Button>
            <Button
              className="flex-grow-1"
              variant={this.state.online ? "success" : "danger"}
              onClick={() => this.update()}
            >
              <FontAwesomeIcon icon={faSyncAlt} /> {this.state.online ? "Online" : "Offline"}
            </Button>
          </div>
        </h3>
        <Row className="mb-3 flex-grow-1 flex-shrink-1 overflow-auto">
          <Col md={8} className="h-100">
            <AlertaMap alertas={this.state.alertas.filter(
              (alerta) => alerta.status === "Pendente" || alerta.status === "Aceito" || alerta.status === "Despachado"
            )} loaded={this.state.loaded} center={this.state.center} openAlertaModal={alerta => this.openAlertaModal(alerta)} />
          </Col>
          <Col md={4} className="h-100 d-flex flex-column overflow-auto">
            <Tabs
              variant="tabs"
              defaultActiveKey="pendentes"
              fill              
              className="mb-2"
            >
              {tabs.map((tab, key) => (
                <Tab
                  key={key}
                  title={
                    <small
                      className={`text-${tab.variant} ${tab.alertas.some((alerta) => alerta.blink) && "blink"
                        }`}
                    >
                      <Badge pill variant={tab.variant} className="text-white">
                        {tab.alertas.length}
                      </Badge><br />
                      {tab.title}
                    </small>
                  }
                  className="flex-grow-1 flex-shrink-1 overflow-auto"
                  eventKey={tab.status}
                >                  
                    {(tab.alertas.length &&
                      tab.alertas.map((alerta, key) => (
                        <Button
                          key={key}
                          variant={alerta.uuid === this.state.uuid ? tab.variant : `outline-${tab.variant}`}
                          className={`w-100 text-left text-dark mb-1 ${alerta.blink && "blink"}`}
                          onDoubleClick={() => this.openAlertaModal(alerta)}
                          onClick={() =>
                            this.setState(
                              (state) => ({
                                uuid: alerta.uuid,
                                alertas: state.alertas.map((a) =>
                                  a === alerta ? { ...alerta, blink: false } : a
                                ),
                                center: [alerta.latitude, alerta.longitude]
                              })
                            )
                          }
                        >
                          <h5>{alerta.ClassificacaoNome}</h5>
                          <div className="d-flex justify-content-between align-items-end">
                            <div>
                              <small>
                                <b>Identificação: </b>
                                {alerta.identificacao}
                                <br />
                                <b>Telefone: </b>
                                {alerta.telefone}
                                <br />
                                <b>Atualização: </b>
                                {moment(alerta.atualizacao).calendar()}
                                <br />
                                {alerta.revogacao && (
                                  <span className="text-dark blink">
                                    <b>Revogação: </b>
                                    {moment(alerta.datahora).calendar()}
                                  </span>
                                )}
                              </small>
                            </div>
                          </div>
                        </Button>
                      ))) || (
                        <h5 className="text-center mt-2">Nenhum alerta</h5>
                      )}
                </Tab>
              ))}
            </Tabs>
          </Col>
        </Row>
      </div>
    );
  }
}

export default AlertaPage;
