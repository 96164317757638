import MapasPage from "./MapasPage";
import MapaList from "./MapaList";
import MapaForm from "./MapaForm";
import MapaManage from "./MapaManage";

const MapasContents = {
    MapasPage,
    MapaList,
    MapaForm,
    MapaManage
};

export default MapasContents;