import React from "react";
import { AppContext } from "../../../../context";
import { Card, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTable } from "@fortawesome/free-solid-svg-icons";
import Request from "../../../../request";
import { Bar, PolarArea } from "react-chartjs-2";

class ScoreCard extends React.Component {
  static contextType = AppContext;

  state = {
    emRegistro: [],
    registrado: [],
    avaliada: [],
    countRegistradaCidades: [],
    countNatureza: [],
    countDenunciasHora: {},
    countCanal: [],
    countFinalizadaSetor: [],
  };

  componentDidMount() {
    Request(
      "GET",
      this.context.config.BACKEND_URL + "/denuncia/scorecard",
      this.context.token
    )
      .then((res) => {
        const emRegistro = Array.isArray(res.body.countEmRegistro)
          ? res.body.countEmRegistro
          : [{ status: "Em Registro", count: res.body.countEmRegistro }];
        const registrado = Array.isArray(res.body.countRegistrada)
          ? res.body.countRegistrada
          : [{ status: "Registrada", count: res.body.countRegistrada }];
        const avaliada = Array.isArray(res.body.countAvaliada)
          ? res.body.countAvaliada
          : [{ status: "Avaliada", count: res.body.countAvaliada }];

        this.setState({
          emRegistro,
          registrado,
          avaliada,
          countRegistradaCidades: res.body.countRegistradaCidade,
          countNatureza: res.body.countNatureza,
          countDenunciasHora: res.body.countDenunciasHora,
          countCanal: res.body.countCanal,
          countFinalizadaSetor: res.body.countFinalizadaSetor,
        });
      })
      .catch((err) => {
        this.context.addToast({
          title: "Erro",
          text:
            "Falha na recuperação da lista de permissões: " + err.toString(),
        });
      });
  }

  render() {
    const variants = ["primary", "warning", "danger", "success", "info"];
    const randomNumberInRange = (min, max) => {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    const labelsCountRegCid = this.state.countRegistradaCidades.map(
      (item) => item.nome
    );
    const dataCountRegCid = this.state.countRegistradaCidades.map(
      (item) => item.count
    );
    const labelsCountFinalizadaSetor = this.state.countFinalizadaSetor.map(
      (item) => item.nome
    );
    const dataCountFinalizadaSetor = this.state.countFinalizadaSetor.map(
      (item) => item.count
    );
    const labelsCountNat = this.state.countNatureza.map(
      (item) => item.Natureza?.nome
    );
    const dataCountNat = this.state.countNatureza.map((item) => item.count);
    const labelsCountCanal = this.state.countCanal.map(
      (item) => item.Canal?.nome
    );
    const dataCountCanal = this.state.countCanal.map((item) => item.count);

    const {
      ultimaHora,
      ultimas24Horas,
      ultimos7Dias,
      ultimos30Dias,
      ultimos365Dias,
    } = this.state.countDenunciasHora;

    const chartColors = [
      "#FF6384",
      "#36A2EB",
      "#FFCE56",
      "#4BC0C0",
      "#9966FF",
      "#FF9F40",
      "#FFCD56",
      "#36A2EB",
      "#FF6384",
    ];

    const chartDataRegCid = {
      labels: labelsCountRegCid,
      datasets: [
        {
          label: "Total Registrada por Cidade",
          backgroundColor: chartColors,
          borderColor: "rgba(75,192,192,1)",
          borderWidth: 1,
          hoverBackgroundColor: chartColors,
          hoverBorderColor: "rgba(75,192,192,1)",
          data: dataCountRegCid,
        },
      ],
    };

    const chartDataFinalizadaSetor = {
      labels: labelsCountFinalizadaSetor,
      datasets: [
        {
          label: "Total Registrada por Setor",
          backgroundColor: chartColors,
          borderColor: "rgba(75,192,192,1)",
          borderWidth: 1,
          hoverBackgroundColor: chartColors,
          hoverBorderColor: "rgba(75,192,192,1)",
          data: dataCountFinalizadaSetor,
        },
      ],
    };

    const chartDataNat = {
      labels: labelsCountNat,
      datasets: [
        {
          label: "Top 10 Naturezas Mais Registradas",
          backgroundColor: chartColors,
          borderColor: "rgba(75,192,192,1)",
          borderWidth: 1,
          hoverBackgroundColor: chartColors,
          hoverBorderColor: "rgba(75,192,192,1)",
          data: dataCountNat,
        },
      ],
    };

    const chartDataCanal = {
      labels: labelsCountCanal,
      datasets: [
        {
          label: "Top 10 Canais Mais Registrados",
          backgroundColor: chartColors,
          borderColor: "rgba(75,192,192,1)",
          borderWidth: 1,
          hoverBackgroundColor: chartColors,
          hoverBorderColor: "rgba(75,192,192,1)",
          data: dataCountCanal,
        },
      ],
    };

    const chartDataDenuncias = {
      labels: [
        "Última Hora",
        "Últimas 24 Horas",
        "Últimos 7 Dias",
        "Últimos 30 Dias",
        "Últimos 365 Dias",
      ],
      datasets: [
        {
          label: "Denúncias",
          backgroundColor: chartColors,
          borderColor: "rgba(75,192,192,1)",
          borderWidth: 1,
          hoverBackgroundColor: chartColors,
          hoverBorderColor: "rgba(75,192,192,1)",
          data: [
            ultimaHora,
            ultimas24Horas,
            ultimos7Dias,
            ultimos30Dias,
            ultimos365Dias,
          ],
        },
      ],
    };

    return (
      <>
        <Row className="mt-1">
          <Col>
            <Card>
              <Card.Header>
                <FontAwesomeIcon icon={faTable} />{" "}
                <strong className="text-uppercase">
                  Quantidade de Denuncias{" "}
                </strong>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    {this.state.emRegistro.map((emRegistro, index) => (
                      <Card
                        key={index}
                        bg={variants[randomNumberInRange(0, 4)]}
                        className="text-white mb-3"
                        style={{ height: "150px" }}
                      >
                        <Card.Header>{emRegistro.status}</Card.Header>
                        <Card.Body className="d-flex align-items-end justify-content-end">
                          <h1>{emRegistro.count}</h1>
                        </Card.Body>
                      </Card>
                    ))}
                  </Col>
                  <Col>
                    {this.state.registrado.map((registrado, index) => (
                      <Card
                        key={index}
                        bg={variants[randomNumberInRange(0, 4)]}
                        className="text-white mb-3"
                        style={{ height: "150px" }}
                      >
                        <Card.Header>{registrado.status}</Card.Header>
                        <Card.Body className="d-flex align-items-end justify-content-end">
                          <h1>{registrado.count}</h1>
                        </Card.Body>
                      </Card>
                    ))}
                  </Col>
                  <Col>
                    {this.state.avaliada.map((avaliada, index) => (
                      <Card
                        key={index}
                        bg={variants[randomNumberInRange(0, 4)]}
                        className="text-white mb-3"
                        style={{ height: "150px" }}
                      >
                        <Card.Header>{avaliada?.status}</Card.Header>
                        <Card.Body className="d-flex align-items-end justify-content-end">
                          <h1>{avaliada?.count}</h1>
                        </Card.Body>
                      </Card>
                    ))}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col className="border">
            <Bar
              data={chartDataRegCid}
              width={100}
              height={200}
              options={{
                maintainAspectRatio: false,
              }}
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col className="border">
            <PolarArea
              data={chartDataNat}
              width={100}
              height={600}
              options={{
                maintainAspectRatio: false,
              }}
            />
          </Col>

          <Col className="border">
            <PolarArea
              data={chartDataCanal}
              width={100}
              height={600}
              options={{
                maintainAspectRatio: false,
              }}
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col className="border">
            <Bar
              data={chartDataDenuncias}
              width={100}
              height={200}
              options={{
                maintainAspectRatio: false,
              }}
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col className="border">
            <Bar
              data={chartDataFinalizadaSetor}
              width={100}
              height={200}
              options={{
                maintainAspectRatio: false,
              }}
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default ScoreCard;
