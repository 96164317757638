import { faDownload, faSave, faTrash, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Form as BSForm, FormGroup, Tab, Table, Tabs } from "react-bootstrap";
import Request from "../../../request";
import { Formik, Form, Field, ErrorMessage, } from 'formik';
import AddAnexos from "../../AddAnexos";
import { AppContext } from "../../../context";


class DenunciaApensoModal extends React.Component {

    static contextType = AppContext;

    save(values, callback) {
        const method = 'POST';
        const url = this.context.config.BACKEND_URL + '/apenso/';

        Request(method, url, this.context.token)
            .send({ ...values })
            .then(res => {
                this.context.addToast({ titulo: "Successo", conteudo: "Registro atualizado com sucesso." });
                callback();
                if (this.props.onSave) this.props.onSave();
                this.context.closeModal();
            })
            .catch(err => {
                this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na gravação do registro." });
                callback();
            });

    }
    render() {
        return (
            <Formik
                initialValues={{
                    ...this.props.apenso,
                    Anexos: this.props.apenso?.Anexos ?? [],
                    status: null,
                    DenunciaId: this.props.apenso?.id
                }}
                validate={values => {
                    const errors = {};
                    if (!values.conteudo) {
                        errors.conteudo = 'Campo obrigatório';
                    }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    this.save(values, () => setSubmitting(false));
                }}
            >
                {({ isSubmitting, values, setValues, setFieldValue }) => {
                    return (
                        <>
                            <Form>
                                <Tabs defaultActiveKey="apenso" className="mb-3">
                                    <Tab eventKey="apenso" title="Apenso">
                                        <FormGroup>
                                            <BSForm.Label>Conteudo</BSForm.Label><ErrorMessage name="conteudo" component="span" className="text-danger small ml-2" />
                                            <Field as="textarea" name="conteudo" className="form-control" value={values.conteudo} />
                                        </FormGroup>
                                    </Tab>
                                    <Tab eventKey="anexos" title="Anexos">
                                        <input type="file" multiple ref={this.anexoRef} className="d-none" onChange={event => {
                                            setFieldValue('Anexos', [...values.Anexos, ...event.currentTarget.files]);
                                        }} />
                                        <Table striped size="sm" responsive className='mt-2'>
                                            <thead className='bg-light'>
                                                <tr>
                                                    <th>Nome</th>
                                                    <th style={{ width: 120, textAlign: 'center' }}>Ações</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {values.Anexos.map((anexo, key) =>
                                                    <tr key={key}>
                                                        <td>
                                                            {anexo.nome ?? ""}
                                                        </td>
                                                        <td className='text-center'>
                                                            {anexo.id ?
                                                                <Button as="a" href={`${this.context.config.BACKEND_URL}/anexo/disquedenuncia/${anexo.id}/${anexo.uuid}`} target="_blank" download={anexo.nome} size="sm" variant='info' title='Download' className='mr-2'>
                                                                    <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                                                                </Button> : null}
                                                            <Button size="sm" type='button' variant='danger' title='Excluir'
                                                                onClick={() => window.confirm("Deseja realmente excluir este arquivo?") && setFieldValue('Anexos', values.Anexos.filter(a => a !== anexo))}
                                                            >
                                                                <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td colspan='4'>
                                                        <AddAnexos
                                                            multiple={true}
                                                            onError={file => this.context.addToast({ titulo: "Erro", conteudo: "Falha ao carregar o arquivo " + file.name })}
                                                            onLoad={anexos => setFieldValue('Anexos', [...values.Anexos, ...anexos])}
                                                            extraInfo="Apenso" // Especificando informações adicionais para o nome do arquivo
                                                        />

                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="4">
                                                        <ErrorMessage name="Anexos" component="span" className="text-danger small ml-2" />
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </Table>
                                    </Tab>
                                </Tabs>


                                <FormGroup className="text-right">
                                    <Button type="button" variant='secondary' onClick={() => this.context.closeModal()}>
                                        <FontAwesomeIcon icon={faTimes} />&nbsp;
                                        Fechar
                                    </Button>
                                    <Button type="submit" disabled={isSubmitting} variant='primary' className="ml-2">
                                        <FontAwesomeIcon icon={faSave} />&nbsp;
                                        Gravar Alterações
                                    </Button>
                                </FormGroup>
                            </Form>
                        </>
                    );
                }}
            </Formik>
        );
    }
}

export default DenunciaApensoModal;