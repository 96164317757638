import React from 'react';
import Request from '../../../request';
import { AppContext } from "../../../context";
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Datatable from '../../Datatable';

class OcorrenciasList extends React.Component {

    static contextType = AppContext;

    updateDatatable = () => {
        this.refs.datatable.updateDatatable(); 
    }

    onAction(action) {
        switch (action.name) {
            case "class":
                    Request('GET', this.context.config.BACKEND_URL + '/ocorrencias/' + action.id, this.context.token)
                        .then(res => {
                            const ocorrencia = res.body;
                            this.context.setContent("OcorrenciasForm", { ocorrencia: ocorrencia });
                        })
                        .catch(err => {
                            this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na recuperação do registro: " + err.toString() });
                        });
                break;
            default:
        }
    }

    render() {
        return (
            <>
                <h3><FontAwesomeIcon icon={faFolderOpen} /> Ocorrências Registradas</h3>
                <hr />
                <Datatable
                    ref="datatable"
                    url={this.context.config.BACKEND_URL + '/ocorrencias'}
                    autoSeach={true}
                    onError={(err) => this.context.addToast({ titulo: "Erro", conteudo: err.toString() })}
                    updateDatatable={this.updateDatatable}
                    onAction={(action, update) => this.onAction(action, update)}
                />
            </>
        );
    }
}

export default OcorrenciasList;
