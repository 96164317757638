import React from 'react';
import { AppContext } from '../../../context';
import ModelList from '../ModelList';

class ObservacaoUsuarioList extends React.Component {
    
    static contextType = AppContext;

    render(){
        return (
            <ModelList
                url={this.context.config.BACKEND_URL + '/observacao'}
                title="Lista de Observações"
                modelIcon="faUsers"
                modelName="observacao"
            />
        );
    }
}

export default ObservacaoUsuarioList;