import React from "react";
import { AppContext } from "../../../context";
import { Col, Form as BSForm, Row, Image, Button, Container, Table, Card } from "react-bootstrap";
import banner from "./img/logo.png"
import Map from "../../Map";
import Request from "../../../request";
import './css/index.css';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Select from 'react-select';
import AddAnexos from "../../AddAnexos";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faSave, faTrash } from "@fortawesome/free-solid-svg-icons";
import Diacritics from 'diacritic';
import CreatableSelect from 'react-select/creatable';

class DenunciaPage extends React.Component {
    static contextType = AppContext;
    state = {
        cidades: [],
        bairros: [],
    }

    componentDidMount() {
        const errorHandler = err => this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na pesquisa." });
        Request('GET', this.context.config.BACKEND_URL + '/denuncie/cidade/options').send()
            .then(options => {
                this.setState(() => ({ cidades: options.body }));
            })
            .catch(errorHandler);

        Request('GET', this.context.config.BACKEND_URL + '/denuncie/bairro/options').send()
            .then(options => {
                this.setState(() => ({ bairros: options.body }));
            })
            .catch(errorHandler);
    }

    save(values, callback) {
        const url = this.context.config.BACKEND_URL + '/DenunciaWeb';
        this.setState(() => ({ showModal: true }), () => {
            Request('POST', url)
                .send(values)
                .then(res => {
                    this.context.openModal({
                        titulo: `Denúncia nº ${res.body.DenunciaId} Registrada com sucesso!`,
                        conteudo: <>
                            <Card className='p-3 bg-light text-dark mb-5 text-justify'>
                                <p>
                                    Em breve será avaliada e tamada outras providências.
                                </p>
                                <p>
                                    Não esqueça de <span className="text-danger">ANOTAR</span> o número da denúncia.
                                </p>
                                <p>
                                    Sua ajuda, é muito importante, obrigado!.
                                </p>
                                <p>
                                    SEGURANÇA, VOCÊ FAZ PARTE!
                                </p>
                            </Card>
                            <Button
                                type="button" variant='secondary' onClick={() => {
                                    this.context.closeModal();
                                    window.location.reload();
                                }
                                }>
                                <FontAwesomeIcon icon={faCheck} />&nbsp;
                                Ok
                            </Button>
                        </>,
                        size: "md"
                    })

                })
                .catch(err => {
                    this.setState(() => ({ success: false }));
                    this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na gravação do registro: " + err.toString() });
                })
                .finally(() => callback());
        });

    };


    render() {
        return (
            <Formik
                validateOnBlur={false}
                validateOnChange={false}
                initialValues={{
                    Anexos: [],
                    CidadeId: [],
                    BairroId: [],
                    descricao: "",
                    referencia: "",
                    CanalId: 6,
                    logradouro: "",
                    numero: "",
                    status: "Em Registro"
                }}
                validate={values => {
                    const errors = {};
                    if (!values.descricao) {
                        errors.descricao = 'Campo obrigatório';
                    }
                    if (!values.logradouro) {
                        errors.descricao = 'Campo obrigatório';
                    }
                    if (!values.CidadeId) {
                        errors.descricao = 'Campo obrigatório';
                    }
                    if (!values.BairroId) {
                        errors.descricao = 'Campo obrigatório';
                    }
                    if (Object.keys(errors).length > 0) {
                        this.context.addToast({ titulo: "Erro", conteudo: "Verifique os campos obrigatórios e tente novamente." });
                    }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }, resetForm) => {
                    this.save(values, () => setSubmitting(false));
                    resetForm();
                }}
            >
                {({ isSubmitting, values, setFieldValue, setValues }) => {
                    return (
                        <div className="background">
                            <Container>
                                <Form >
                                    <Row className="white-background">
                                        <Image src={banner} className="rounded-image" />
                                        <Col md="12">
                                            <h4 className="mt-1 "><strong>Este é o formulário virtual para denúncia do 181!</strong></h4>
                                            <BSForm.Label className="justify-text">
                                                Por meio do formulário virtual, a Secretaria de Segurança e Defesa Social do Pará garante
                                                o sigilo e o anonimato de sua denúncia, mas se preferir, pode denunciar adicionando o número
                                                (91) 98115-9181 nos seus contatos e iniciar uma conversa pelo WhatsApp. Pode, ainda, utilizar
                                                o Chatbot presente nos sites de segurança do Estado ou falar diretamente com atendentes qualificados,
                                                ligando gratuitamente para o 181.
                                                <br />
                                                <br />
                                                Para começar, escreva abaixo a sua denuncia. </BSForm.Label>
                                            <ErrorMessage name="descricao" component="span" className="text-danger small ml-2" />
                                            <Field
                                                as="textarea"
                                                className="form-control"
                                                placeholder="Escreva aqui o que está acontecendo"
                                                name="descricao"
                                                rows="8"
                                                value={values.descricao}
                                            />
                                            <BSForm.Label className="justify-text">
                                                <small className="form-text text-muted">
                                                    Dica: Se souber, informe nomes ou apelidos dos denunciados e suas características físicas.
                                                    Se for magro, gordo, forte, fraco, cor da pele, dos cabelos e dos olhos,
                                                    idade e altura aproximada, se têm sinais, cicatrizes e tatuagens, se possuem armas de fogo,
                                                    drogas, veículos ou pessoas apreendidas, etc.
                                                </small>
                                                <br />
                                                Agora, localize no mapa onde está acontecendo o fato denunciado.
                                                O endereço localizado no mapa é:
                                            </BSForm.Label>
                                        </Col>
                                    </Row>
                                    <Row className="white-background">
                                        <Col md="12" className="mb-4">
                                            <small>Arraste o mapa ou clique no botão de pesquisa para encontrar um endereço (Clique na lupa,
                                                digite o endereço, aperte enter e escolha o local)

                                            </small>
                                            <Map
                                                onChange={data => {
                                                    const CidadeId = (this.state.cidades.find(cidade => Diacritics.clean(cidade.label).toUpperCase() === Diacritics.clean(data.cidade).toUpperCase()) ?? { value: null }).value;
                                                    const BairroId = (this.state.bairros.find(bairro => bairro.CidadeId === CidadeId && Diacritics.clean(bairro.label).toUpperCase() === Diacritics.clean(data.bairro).toUpperCase()) ?? { value: null }).value;
                                                    setValues({
                                                        ...values,
                                                        ...data,
                                                        CidadeId,
                                                        BairroId
                                                    });
                                                }}
                                                position={[values.latitude, values.longitude]}
                                            />
                                        </Col>
                                        <Col md="6">
                                            <BSForm.Label as="b">Latitude<span className="text-danger">*</span></BSForm.Label><ErrorMessage name="latitude" component="span" className="text-danger small ml-2" />
                                            <Field type="text" name="latitude" className="form-control" value={values.latitude} readOnly />
                                        </Col>
                                        <Col md="6">
                                            <BSForm.Label as="b">Longitude<span className="text-danger">*</span></BSForm.Label><ErrorMessage name="longitude" component="span" className="text-danger small ml-2" />
                                            <Field type="text" name="longitude" className="form-control" value={values.longitude} readOnly />
                                        </Col>
                                    </Row>
                                    <Row className="white-background">
                                        <Col md="6">
                                            <BSForm.Label as="b">Cidade<span className="text-danger">*</span></BSForm.Label><ErrorMessage name="CidadeId" component="span" className="text-danger small ml-2" />
                                            <Select
                                                name="CidadeId"
                                                noOptionsMessage={() => "Nada encontrado."}
                                                placeholder="Pesquisar..."
                                                options={this.state.cidades}
                                                value={this.state.cidades ? this.state.cidades.find(option => option.value === values.CidadeId) : ''}
                                                onChange={option => {
                                                    setFieldValue('CidadeId', option ? option.value : null);
                                                    setFieldValue('BairroId', null);
                                                }}
                                            />
                                        </Col>
                                        <Col md="6">
                                            <BSForm.Label as="b">Bairro<span className="text-danger">*</span></BSForm.Label><ErrorMessage name="BairroId" component="span" className="text-danger small ml-2" />
                                            {/* <Select
                                                name="BairroId"
                                                noOptionsMessage={() => "Nada encontrado."}
                                                placeholder="Pesquisar..."
                                                options={this.state.bairros}
                                                value={this.state.bairros ? this.state.bairros.find(bairro => bairro.value === values.BairroId) : ""}
                                                onChange={option => {
                                                    setFieldValue('BairroId', option ? option.value : null);
                                                    setFieldValue('bairro', option.label)
                                                }}
                                            /> */}
                                            <CreatableSelect
                                                name="BairroId"
                                                noOptionsMessage={() => "Nada encontrado."}
                                                onCreateOption={label => {
                                                    this.setState(state => ({
                                                        bairros: [
                                                            {
                                                                value: 'new',
                                                                label: label,
                                                                CidadeId: values.CidadeId
                                                            },
                                                            ...state.bairros
                                                        ]
                                                    }), () => {
                                                        setFieldValue('BairroId', 'new');
                                                        setFieldValue('bairro', label);
                                                    });
                                                }}
                                                placeholder="Pesquisar..."
                                                formatCreateLabel={label => "Adicionar " + label}
                                                options={this.state.bairros.filter(bairro => bairro.CidadeId === values.CidadeId)}
                                                value={this.state.bairros.find(bairro => bairro.value === values.BairroId)}
                                                onChange={option => {
                                                    setFieldValue('BairroId', option.value);
                                                    setFieldValue('bairro', option.label)
                                                }}
                                            />
                                        </Col>
                                        <Col md="9">
                                            <BSForm.Label as="b">Logradouro<span className="text-danger">*</span></BSForm.Label><ErrorMessage name="logradouro" component="span" className="text-danger small ml-2" />
                                            <Field type="text" name="logradouro" className="form-control" value={values.logradouro} />
                                        </Col>
                                        <Col md="3">
                                            <BSForm.Label as="b">Número</BSForm.Label>
                                            <Field type="text" name="numero" className="form-control" value={values.numero} />
                                        </Col>
                                    </Row>
                                    <Row className="white-background">
                                        <Col lg={12}>
                                            <BSForm.Label as="b" >Se souber, escreva abaixo alguma referência do local denunciado.</BSForm.Label>
                                            <Field type="text" name="referencia" className="form-control mb-3" value={values.referencia} />
                                            <BSForm.Label as="b">Se desejar enviar fotos, audios e videos, escolha abaixo um ou mais arquivos.</BSForm.Label>
                                        </Col>
                                        <Col>
                                            <Table striped size="sm" responsive className='mt-2'>
                                                <thead className='bg-light'>
                                                    <tr>
                                                        <th>Nome</th>
                                                        <th style={{ width: 120, textAlign: 'center' }}>Ações</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {values.Anexos.map((anexo, key) =>
                                                        <tr key={key}>
                                                            <td>
                                                                {anexo.nome ?? ""}
                                                            </td>
                                                            <td className='text-center'>
                                                                <Button size="sm" type='button' variant='danger' title='Excluir'
                                                                    onClick={() => window.confirm("Deseja realmente excluir este arquivo?") && setFieldValue('Anexos', values.Anexos.filter(a => a !== anexo))}
                                                                >
                                                                    <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td colspan='4'>
                                                            <AddAnexos
                                                                multiple={true}
                                                                onError={file => this.context.addToast({ titulo: "Erro", conteudo: "Falha ao carregar o arquivo " + file.name })}
                                                                onLoad={anexos => setFieldValue('Anexos', [...values.Anexos, ...anexos])}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="4">
                                                            <ErrorMessage name="Anexos" component="span" className="text-danger small ml-2" />
                                                        </td>
                                                    </tr>
                                                </tfoot>
                                            </Table>
                                            <BSForm.Label as="b"> Pronto! Agora é só clicar no botão DENUNCIAR para registrar sua denúncia.</BSForm.Label>
                                        </Col>
                                    </Row>
                                    <Row className="white-background">
                                        <Col md="12" className="text-right mb-4">
                                            <Button type="submit" disabled={isSubmitting} variant='primary' className="ml-2">
                                                <FontAwesomeIcon icon={faSave} />&nbsp; Registrar Denuncia
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Container>

                        </div>
                    )
                }}
            </Formik>
        );
    }
}


export default DenunciaPage;