import React from 'react';
import { AppContext } from '../../../context';
import AvisoForm from './AvisoForm';
import ModelList from '../ModelList';

class AvisoList extends React.Component {

    static contextType = AppContext;

    render() {

        return (
            <ModelList
                url={this.context.config.BACKEND_URL + '/aviso'}
                title="Lista de Avisos"
                modelIcon="faNewspaper"
                useAdd={true}
                modelName="aviso"
                modelForm={AvisoForm}
            />
        );
    }
}

export default AvisoList;