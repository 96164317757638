import { faDrawPolygon, faEdit, faList, faMapMarkerAlt, faProjectDiagram, faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Col, Form, FormControl, FormLabel, ListGroup, ListGroupItem, Row, Tab, Tabs } from "react-bootstrap";
import { AppContext } from "../../../context";
import AddAnexos from "../../AddAnexos";
import togeojson from "@mapbox/togeojson";
import { Editor } from "@tinymce/tinymce-react";

class ImportKML extends React.Component {

    static contextType = AppContext;

    state = {
        camada: {
            id: null,
            MapaId: this.props.MapaId,
            nome: "",
            descricao: "",
            Componentes: []
        },
        arquivo: null,
        nuvem: false
    }

    save() {
        if (!this.state.camada.nome) window.alert("Preencha um nome para a camada a ser importada.");
        else if (!this.state.arquivo) window.alert("Selecione um arquivo para importação.");
        else if (!this.state.camada.Componentes.filter(c => c.selecionado).length) window.alert("Selecione ao menos um componente para importação.");
        else {
            if (this.state.nuvem) {
                const pontos = [];
                this.state.camada.Componentes.filter(c => c.selecionado).forEach(componente => {
                    componente.Pontos.forEach(ponto => pontos.push(ponto));
                });
                const camada = {
                    ...this.state.camada,
                    Componentes: [
                        {
                            tipo: "Nuvem",
                            nome: "Nuvem importada",
                            descrição: "Nuvem importada de " + this.state.arquivo.nome,
                            Pontos: pontos
                        }
                    ]
                }
                this.props.import(camada);
            } else {
                this.props.import({
                    ...this.state.camada,
                    Componentes: this.state.camada.Componentes.filter(c => c.selecionado)
                });
            }
        }
    }

    parse(anexos) {

        if (anexos && anexos[0]) {

            const parser = new DOMParser();

            const kml = parser.parseFromString(anexos[0].conteudo, 'text/xml');
            const data = togeojson.kml(kml);

            const Componentes = data.features
                .filter(feature => ["Point", "Polygon", "LineString"].indexOf(feature.geometry.type) > -1)
                .map(feature => ({
                    tipo: { Point: "Ponto", Polygon: "Área", LineString: "Caminho" }[feature.geometry.type],
                    nome: feature.properties.name,
                    descricao: feature.properties.description ?? "",
                    selecionado: true,
                    Pontos: feature.geometry.type === "Point" ?
                        [{
                            latitude: feature.geometry.coordinates[1],
                            longitude: feature.geometry.coordinates[0]
                        }] :
                        feature.geometry.type === "LineString" ?
                            feature.geometry.coordinates.map(coordinate => ({
                                latitude: coordinate[1],
                                longitude: coordinate[0]
                            })) :
                            feature.geometry.coordinates[0].map(coordinate => ({
                                latitude: coordinate[1],
                                longitude: coordinate[0]
                            }))

                }));

            if (!Componentes.length) {
                window.alert("Não foram encontrados componentes no arquivo selecionado.")
            } else {
                this.setState(state => ({ arquivo: anexos[0], camada: { ...state.camada, Componentes } }));
            }

        }
    }

    render() {
        return (
            <Form>
                <Tabs defaultActiveKey="detalhes">
                    <Tab eventKey="detalhes" title={<><FontAwesomeIcon icon={faEdit} /> Importar</>} className="mt-3">
                        <Row className="mb-3">
                            <Col>
                                <FormLabel>Nome da camada</FormLabel>
                                <FormControl type="text" name="nome" value={this.state.camada.nome} onChange={event => this.setState(state => ({ camada: { ...state.camada, nome: event.target.value } }))} className="form-control" />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <FormLabel>Descrição da camada</FormLabel>
                                <Editor
                                    apiKey={this.context.config.TINYMCE_KEY}
                                    value={this.state.camada.descricao}
                                    onEditorChange={value => this.setState(state => ({ camada: { ...state.camada, descricao: value } }))}
                                    init={{
                                        language: "pt_BR",
                                        language_url: "langs/pt_BR.js",
                                        height: 300,
                                        plugins: 'print preview paste searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image imagetools link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
                                        menubar: 'file edit view insert format tools table',
                                        toolbar: 'undo redo | bold italic underline | fontselect fontsizeselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat emoticons | fullscreen  preview save print | insertfile image media link anchor',
                                        paste_data_images: true,
                                        automatic_uploads: false,
                                        images_upload_handler: (blobInfo, success, failure) => success(),

                                    }}
                                />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <AddAnexos title={this.state.arquivo?.nome ?? "Selecionar Arquivo..."} multiple={false} asBinaryString onLoad={anexos => this.parse(anexos)} />
                            </Col>
                        </Row>
                    </Tab>
                    {this.state.arquivo &&
                        <Tab eventKey="componentes" title={<><FontAwesomeIcon icon={faList} /> Componentes</>} className="mt-3">
                            <Row className="mt-3">
                                <Col xl={12}>
                                    <input type="radio" name="nuvem" onChange={event => this.setState(state => ({ nuvem: !event.target.checked }))} checked={!this.state.nuvem} />&nbsp;
                                    &nbsp;
                                    Importar os componentes selecionados separadamente.
                                </Col>
                                <Col xl={12}>
                                    <input type="radio" name="nuvem" onChange={event => this.setState(state => ({ nuvem: event.target.checked }))} checked={this.state.nuvem} />&nbsp;
                                    &nbsp;
                                    Importar os pontos selecionados como uma única nuvem.
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col sm={6}>
                                    <Button className="form-control" variant="primary" onClick={() => this.setState(state => ({ camada: { ...state.camada, Componentes: state.camada.Componentes.map(c => ({ ...c, selecionado: true })) } }))}>
                                        Marcar todos
                                    </Button>
                                </Col>
                                <Col sm={6}>
                                    <Button className="form-control" variant="danger" onClick={() => this.setState(state => ({ camada: { ...state.camada, Componentes: state.camada.Componentes.map(c => ({ ...c, selecionado: false })) } }))}>
                                        Desmarcar todos
                                    </Button>
                                </Col>
                            </Row>
                            <ListGroup className="mt-3">
                                {this.state.camada.Componentes.map(componente =>
                                    <ListGroupItem>
                                        <div className="d-flex align-items-center">
                                            <input type="checkbox" value={componente.selecionado}
                                                onChange={event => this.setState(state => ({
                                                    camada: {
                                                        ...state.camada,
                                                        Componentes: state.camada.Componentes.map(c => c === componente ? {
                                                            ...componente,
                                                            selecionado: !componente.selecionado
                                                        } : c)
                                                    }
                                                }))} checked={componente.selecionado} />&nbsp;
                                            {componente.nome}&nbsp;
                                            <span className="ml-auto">
                                                <FontAwesomeIcon icon={componente.tipo === "Ponto" ? faMapMarkerAlt : (componente.tipo === "Área" ? faDrawPolygon : faProjectDiagram)} />
                                            </span>
                                        </div>
                                        <small><i>{componente.descricao}</i></small>
                                    </ListGroupItem>
                                )}
                            </ListGroup>

                        </Tab>}
                </Tabs>
                <Row className="text-right mt-3">
                    <Col>
                        <Button type="button" variant='secondary' onClick={() => this.context.closeModal()}>
                            <FontAwesomeIcon icon={faTimes} />&nbsp;
                            Fechar
                        </Button>
                        <Button type="button" variant='primary' className="ml-2" onClick={() => this.save()}>
                            <FontAwesomeIcon icon={faSave} />&nbsp;
                            Gravar Alterações
                        </Button>
                    </Col>
                </Row>
            </Form>
        );
    }
}

export default ImportKML;