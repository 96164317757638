import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Col, Row } from 'react-bootstrap';
import { faCarAlt, faHome } from '@fortawesome/free-solid-svg-icons';
import { AppContext } from '../../../context';

class VeiculosPage extends React.Component {  
    
    static contextType = AppContext;

    render(){
        return (<>
            <h3><FontAwesomeIcon icon={faHome}/> Página Inicial</h3>
                <hr />
                <Card>
                    <Card.Header>
                        Principais Tarefas
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col style={{textAlign: 'center'}}>
                            <div role ="button" onClick={() => this.context.setContent("VeiculosList")}><FontAwesomeIcon size='2x' icon={faCarAlt}/><br/>Cadastrar<br/>Veículos</div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
        </>);
    }
}

export default VeiculosPage;
