import React from 'react';
import VinculoForm from './VinculoForm';
import ModelList from '../ModelList';
import { AppContext } from '../../../context';

class VinculoList extends React.Component {  
    
    static contextType = AppContext;

    render(){
        return (
            <ModelList 
                url={this.context.config.BACKEND_URL + '/vinculo'}
                title="Lista de Vínculos"
                modelName="vinculo"
                modelIcon="faLink"
                modelForm={VinculoForm}
                useAdd={true}
            />
        );
    }
}

export default VinculoList;

