import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Form as BSForm, FormGroup } from "react-bootstrap";
import Request from "../../../request";
import { Formik, Form, Field, ErrorMessage } from "formik";

import { AppContext } from "../../../context";

class BoletimAtributoForm extends React.Component {

  static contextType = AppContext;

  state = {
    arrayTipoAtributos: ['Raça', 'Integridade', 'Destino', 'Situação', 'Ação', 'Natureza', 'Origem']
  }

  save(values, callback) {
    const method = this.props.atributo.id ? "PUT" : "POST";
    const url =
      this.context.config.BACKEND_URL +
      "/atributoBoletim" +
      (this.props.atributo.id ? "/" + this.props.atributo.id : "");

    Request(method, url, this.context.token)
      .send({ ...values })
      .then((res) => {
        this.context.addToast({
          titulo: "Successo",
          conteudo: "Registro atualizado com sucesso.",
        });
        callback();
        if (this.props.onSave) this.props.onSave();
        this.context.closeModal();
      })
      .catch((err) => {
        this.context.addToast({
          titulo: "Erro",
          conteudo: "Houve uma falha na gravação do registro.",
        });
        callback();
      });
  }


  render() {


    return (
      <Formik
        initialValues={{
          tipo: this.props.atributo.tipo,
          descricao: this.props.atributo.descricao
        }}
        validate={(values) => {
          const errors = {};
          if (!values.tipo) {
            errors.tipo = "Campo obrigatório";
          }
          if (!values.descricao) {
            errors.descricao = "Campo obrigatório";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          this.save(values, () => setSubmitting(false));
        }}
      >
        {({ isSubmitting, values }) => {
          return (
            <Form>
              <FormGroup>
                <BSForm.Label>Tipo de Atributo</BSForm.Label>
                <ErrorMessage
                  name="tipo"
                  component="span"
                  className="text-danger small ml-2"
                />
                <Field className="form-control" as="select" name="tipo" value={this.state.arrayTipoAtributos.find((option) => option === values.tipo)}>
                <option>Selecione o Tipo</option>
                  {this.state.arrayTipoAtributos.map((option, key) => {
                    return (
                      <option key={key} value={option} >{option}</option>
                    );
                  })}

                </Field>
              </FormGroup>
              <FormGroup>
                <BSForm.Label>Descrição</BSForm.Label>
                <ErrorMessage
                  name="descricao"
                  component="span"
                  className="text-danger small ml-2"
                />
                <Field
                  type="text"
                  name="descricao"
                  className="form-control"
                />
              </FormGroup>
              <FormGroup className="text-right">
                <Button
                  type="button"
                  variant="secondary"
                  onClick={() => this.context.closeModal()}
                >
                  <FontAwesomeIcon icon={faTimes} />
                  &nbsp; Fechar
                </Button>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  variant="primary"
                  className="ml-2"
                >
                  <FontAwesomeIcon icon={faSave} />
                  &nbsp; Gravar Alterações
                </Button>
              </FormGroup>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

export default BoletimAtributoForm;
