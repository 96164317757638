import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Form as BSForm, FormGroup } from "react-bootstrap";
import Request from "../../../request";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { AppContext } from "../../../context";


class CaracteristicaForm extends React.Component {

    static contextType = AppContext;

    save(values, callback){
        const method = this.props.caracteristica.id? 'PUT' : 'POST';
        const url = this.context.config.BACKEND_URL +'/caracteristica' + (this.props.caracteristica.id? '/'+this.props.caracteristica.id : '');

        Request(method, url, this.context.token)
        .send({...values})
        .then( res => {
                this.context.addToast({titulo: "Successo", conteudo: "Registro atualizado com sucesso."});
                callback();
                if (this.props.onSave) this.props.onSave();
                this.context.closeModal();                                
        })
        .catch( err => {
            this.context.addToast({titulo: "Erro", conteudo: "Houve uma falha na gravação do registro."});
            callback();
        });

    }

    render(){
        return (
            <Formik
                initialValues={{ 
                    ...this.props.caracteristica
                }}
                validate={values => {     
                    const errors = {};     
                    if (!values.nome) {    
                        errors.nome = 'Campo obrigatório';     
                    }                
                    return errors;     
                }}
                onSubmit={(values, { setSubmitting }) => {     
                    this.save(values, () => setSubmitting(false));
                }}     
            >     
                {({ isSubmitting, values }) => {
                    return (
                      <>
                      {console.log(this.props.caracteristica)}
                        <Form>
                            <FormGroup>
                                <BSForm.Label>Nome</BSForm.Label><ErrorMessage name="nome" component="span" className="text-danger small ml-2"/>
                                <Field type="text" name="nome" className="form-control" value={values.nome}/>                            
                            </FormGroup>       
                            <FormGroup>
                                <BSForm.Label>Lista de Valores (um por linha)</BSForm.Label><ErrorMessage name="nome" component="span" className="text-danger small ml-2"/>
                                <Field as="textarea" rows={10} name="valores" className="form-control" value={values.valores}/>                            
                            </FormGroup>                            
                     
                            <FormGroup className="text-right">
                                <Button type="button" variant='secondary' onClick={() => this.context.closeModal()}>
                                    <FontAwesomeIcon icon={faTimes} />&nbsp;
                                    Fechar
                                </Button>
                                <Button type="submit" disabled={isSubmitting} variant='primary' className="ml-2">
                                    <FontAwesomeIcon icon={faSave} />&nbsp;
                                    Gravar Alterações
                                </Button>
                            </FormGroup>
                        </Form>
                      </>
                    );
                }}
            </Formik>
        );
    }
}

export default CaracteristicaForm;