import React, { useEffect } from "react";
import L, { LatLng } from 'leaflet';
import { squareIcon, circleIcon } from "./MarkerIcon";
import { AppContext } from '../../../context';
import { HeatmapLayer } from 'react-leaflet-heatmap-layer-v3';
import 'leaflet-draw';

import {
  LayerGroup,
  MapContainer,
  Marker,
  Polygon,
  Polyline,
  Popup,
  ScaleControl,
  TileLayer,
  useMap,
  useMapEvents,
} from "react-leaflet";

import MyMarker from "./MyMarker";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";

function LeafletControlGeocoder(props) {
    
  const map = useMap();    

  useEffect(() => {

      const geocoder = L.Control.Geocoder.nominatim();

      L.Control.geocoder({
          query: "",
          placeholder: "Pesquisar Logradouro...",
          defaultMarkGeocode: false,
          geocoder
      })
      .on("markgeocode", ({geocode}) => {
          const latitude = geocode.center.lat;
          const longitude = geocode.center.lng;
          map.setView([latitude, longitude], map.getZoom());
          map.fire('dragend');
      })
      .addTo(map);
  }, [map]);

  return null;
}

const MapController = props => {

  const map = useMap();

  useEffect(() => {

    if (!props.pontos.length) {

    } else if (props.pontos.length === 1) {
      map.setView([props.pontos[0].latitude, props.pontos[0].longitude]);
    } else {
      const corner1 = L.latLng(
        props.pontos.map(ponto => ponto.latitude).reduce((a, b) => Math.max(a, b), props.pontos[0].latitude),
        props.pontos.map(ponto => ponto.longitude).reduce((a, b) => Math.min(a, b), props.pontos[0].longitude)
      );
      const corner2 = L.latLng(
        props.pontos.map(ponto => ponto.latitude).reduce((a, b) => Math.min(a, b), props.pontos[0].latitude),
        props.pontos.map(ponto => ponto.longitude).reduce((a, b) => Math.max(a, b), props.pontos[0].longitude)
      );

      const bounds = L.latLngBounds(corner1, corner2);

      map.flyToBounds(bounds);
    }

  }, [props.pontos, map]);

  useMapEvents({
    zoomend: () => {
      const mpp = 40075016.686 * Math.abs(Math.cos(map.getCenter()?.lat * Math.PI / 180)) / Math.pow(2, (map.getZoom() + 8) ?? 21);
      props.setMpp(mpp);
    },
    dblclick: event => props.addPonto(event.latlng.lat, event.latlng.lng),
    move: () => props.setLatLng(map.getCenter().lat, map.getCenter().lng)
  });

  return null;
}

class MapaManageMap extends React.Component {

  static contextType = AppContext;

  static center = [-1.437972, -48.466901];

  state = {
    circle: false,
    center: MapaManageMap.center,
    mpp: 0,
    dragPonto: null,
    dragLatitude: null,
    dragLongitude: null
  }

  measure(componente) {

    if (componente.tipo === "Caminho") {
      var distancia = 0;
      componente.Pontos.forEach((ponto, i) => {
        if (i > 0) {
          const p = new LatLng(ponto.latitude, ponto.longitude);
          distancia += p.distanceTo(new LatLng(componente.Pontos[i - 1].latitude, componente.Pontos[i - 1].longitude));
        }
      });
      const d = distancia > 10000 ? distancia / 1000 : distancia;
      const formatter = new Intl.NumberFormat('pt-Br', {
        minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: distancia < 10000 ? 0 : 2, // (causes 2500.99 to be printed as $2,501)
      });
      return `${formatter.format(d)} ${distancia > 10000 ? 'km' : 'm'}`;
    } else {
      const area = L.GeometryUtil.geodesicArea(componente.Pontos.map(ponto => new LatLng(ponto.latitude, ponto.longitude)));
      const a = area > 10000 ? area / 1000000 : area;
      const formatter = new Intl.NumberFormat('pt-Br', {
        minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: area < 10000 ? 0 : 2, // (causes 2500.99 to be printed as $2,501)
      });
      return `${formatter.format(a)} ${area > 10000 ? 'km²' : 'm²'}`;
    }
  }

  render() {

    const isEditor = this.context.isAuthorized("mapas_mapa_edit");

    const markerEventHandlers = (ponto, componente, camada) => ({
      click: () => this.props.select(componente, camada),
      dblclick: () => this.props.select(componente, camada, () => {
        isEditor && this.props.delPonto(ponto, componente, camada);
      }),
      dragstart: event => this.props.select(componente, camada, () => {
        const latitude = event.target.getLatLng().lat;
        const longitude = event.target.getLatLng().lng;
        isEditor && this.setState({ dragPonto: ponto, dragLatitude: latitude, dragLongitude: longitude });
      }),
      drag: event => {
        const latitude = event.target.getLatLng().lat;
        const longitude = event.target.getLatLng().lng;
        this.state.dragPonto && this.setState({ dragLatitude: latitude, dragLongitude: longitude });
      },
      dragend: event => {
        const latitude = event.target.getLatLng().lat;
        const longitude = event.target.getLatLng().lng;
        this.setState({ dragPonto: null }, () =>
          isEditor && this.props.savePonto(ponto, componente, camada, latitude, longitude));
      }
    });

    const pontoEventHandlers = (ponto, componente, camada) => ({
      click: () => this.props.select(componente, camada),
      dragstart: () => this.props.select(componente, camada),
      dragend: event => {
        const latitude = event.target.getLatLng().lat;
        const longitude = event.target.getLatLng().lng;
        this.setState({ dragPonto: null }, () =>
          isEditor && this.props.savePonto(ponto, componente, camada, latitude, longitude));
      }
    });

    const componenteEventHandlers = (componente, camada) => ({
      click: () => this.props.select(componente, camada)
    });


    return (
      <MapContainer
        center={this.state.center}
        zoom={13}
        scrollWheelZoom={true}
        doubleClickZoom={false}
        style={{ height: "100%", width: "100%", minHeight: 400 }}
      >
        <TileLayer url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
          attribution='Map data © <a href="http://openstreetmap.org">OpenStreetMap</a> contributors' />
        <LayerGroup >
          {this.props.mapa.Camadas.filter(camada => !camada.oculto).map((camada, key) =>
            <LayerGroup key={key}>
              {camada.Componentes.filter(componente => !componente.oculto).map((componente, key) =>
                ((componente.tipo === "Nuvem" && componente.id === this.props.heatId &&
                  <HeatmapLayer
                    key={key}
                    radius={componente.tamanho / this.state.mpp}
                    minOpacity={0.7}
                    opacity={0.7}
                    blur={componente.tamanho / this.state.mpp / 5}
                    points={componente.Pontos}
                    longitudeExtractor={p => p.longitude}
                    latitudeExtractor={p => p.latitude}
                    intensityExtractor={p => 0.2}
                    maxZoom={18}
                    max={1}
                    useLocalExtrema={false}
                  />) ||
                  ((componente.tipo === "Ponto" || (componente.tipo === "Nuvem" && componente.id !== this.props.heatId)) && componente.Pontos.map((ponto, key) =>
                    <MyMarker
                      isEditor={isEditor}
                      key={key}
                      ponto={ponto}
                      componente={componente}
                      camada={camada}
                      pontoEventHandlers={pontoEventHandlers}
                      delPonto={() => this.props.delPonto(ponto, componente, camada)}
                      editComponente={() => this.props.editComponente(componente, camada)}
                    />
                  ))) || (componente.tipo === "Caminho" &&
                    <LayerGroup key={key}>
                      <Polyline
                        positions={componente.Pontos.map(ponto => ponto === this.state.dragPonto ? [this.state.dragLatitude, this.state.dragLongitude] : [ponto.latitude, ponto.longitude])}
                        eventHandlers={componenteEventHandlers(componente, camada)}
                        pathOptions={{
                          color: componente.linha
                        }}>
                        <Popup autoPan={false}>
                          <h3 className="mb-1">
                            {componente.nome}
                          </h3>
                          <small><i>{camada.nome}</i></small>
                          <div dangerouslySetInnerHTML={{ __html: componente.descricao }} />
                          <p className="text-right">
                            <i>Percurso: {this.measure(componente)}</i>
                          </p>
                          {isEditor && <div className="text-right mt-3">
                            <Button className="mr-2" size="sm" variant="info" onClick={() => this.props.editComponente(componente, camada)}>
                              <FontAwesomeIcon icon={faEdit} />
                            </Button>
                            <Button size="sm" variant="danger" onClick={() => this.props.delComponente(componente, camada)}>
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>
                          </div>}
                        </Popup>
                      </Polyline>
                      {componente.Pontos.map((ponto, key) => <Marker icon={circleIcon} position={ponto === this.state.dragPonto ? [this.state.dragLatitude, this.state.dragLongitude] : [ponto.latitude, ponto.longitude]} key={key} draggable={isEditor} eventHandlers={markerEventHandlers(ponto, componente, camada)} />)}
                    </LayerGroup>
                ) || (componente.tipo === "Área" &&
                  <LayerGroup key={key}>
                    <Polygon positions={componente.Pontos.map(ponto => ponto === this.state.dragPonto ? [this.state.dragLatitude, this.state.dragLongitude] : [ponto.latitude, ponto.longitude])} eventHandlers={componenteEventHandlers(componente, camada)}
                      pathOptions={{
                        color: componente.linha,
                        fillColor: componente.preenchimento,
                      }}>
                      <Popup autoPan={false}>
                        <h3 className="mb-1">
                          {componente.nome}
                        </h3>
                        <small><i>{camada.nome}</i></small>
                        <div dangerouslySetInnerHTML={{ __html: componente.descricao }} />
                        <p className="text-right">
                          <i>Área: {this.measure(componente)}</i>
                        </p>
                        {isEditor && <div className="text-right mt-3">
                          <Button className="mr-2" size="sm" variant="info" onClick={() => this.props.editComponente(componente, camada)}>
                            <FontAwesomeIcon icon={faEdit} />
                          </Button>
                          <Button size="sm" variant="danger" onClick={() => this.props.delComponente(componente, camada)}>
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                        </div>}
                      </Popup>
                    </Polygon>
                    {componente.Pontos.map((ponto, key) => <Marker icon={squareIcon} position={ponto === this.state.dragPonto ? [this.state.dragLatitude, this.state.dragLongitude] : [ponto.latitude, ponto.longitude]} key={key} draggable={isEditor} eventHandlers={markerEventHandlers(ponto, componente, camada)} />)}
                  </LayerGroup>
                )
              )}
            </LayerGroup>
          )}
        </LayerGroup>
        <MapController
          pontos={this.props.pontos}
          mapa={this.props.mapa}
          heat={this.props.heat}
          setLatLng={(latitude, longitude) => this.props.setLatLng(latitude, longitude)}
          addPonto={(latitude, longitude) => this.props.addPonto(latitude, longitude)}
          setMpp={mpp => this.setState({ mpp })} />
        <ScaleControl imperial={false} />
        <LeafletControlGeocoder />
      </MapContainer>
    );
  }
}

export default MapaManageMap;
