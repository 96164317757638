import { Component } from 'react';
import { MapContainer, Marker, Polyline, Popup, TileLayer } from 'react-leaflet';
import { AppContext } from '../../../context';
import Request from '../../../request';
import { Button, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import ReactSlider from 'react-slider';
import L from 'leaflet';
import iconsUrl from '../../../assets/icons/police64.png';


import styled from 'styled-components';

const StyledSlider = styled(ReactSlider)`
    width: 100%;
    height: 25px;
`;

const customIcon = L.icon({
    iconUrl: iconsUrl,
    iconSize: [64, 64], // substitua width e height pelas dimensões reais do seu ícone
  });

const StyledThumb = styled.div`
    height: 25px;
    line-height: 25px;
    width: 25px;
    text-align: center;
    background-color: #000;
    color: #fff;
    border-radius: 50%;
    cursor: grab;
`;

const Thumb = (props) => <StyledThumb {...props}>&nbsp;</StyledThumb>;

const StyledTrack = styled.div`
    top: 0;
    bottom: 0;
    background: ${props => (props.index === 2 ? '#ddd' : props.index === 1 ? '#444' : '#ddd')};
    border-radius: 999px;
`;

const Track = (props, state) => <StyledTrack {...props} index={state.index} />;

class ViaturaMap extends Component {

    static contextType = AppContext;

    state = {
        center: [-1.4631897998991463, -48.4955653127071868],
        viatura: {
            Localizacoes: []
        },
        loaded: false,
        inicio: moment().subtract(1, 'days'),
        indexes: [0, 0],
        fim: moment()
    }

    fetch() {
        const query = "inicio=" + this.state.inicio.toISOString() + "&fim=" + this.state.fim.toISOString();
        Request('GET', this.context.config.BACKEND_URL + "/viatura/" + this.props.ViaturaId + "/localizacoes/?" + query, this.context.token)
            .then(res => {

                const viatura = res.body;

                const center = [
                    viatura.latitude ?? this.state.center[0],
                    viatura.longitude ?? this.state.center[1],
                ];

                this.setState({ viatura, center, loaded: true, indexes: [0, viatura.Localizacoes.length-1] })

            })
            .catch(err => {
                console.log(err);
                this.context.addToast({ titulo: "Erro", conteudo: "Houve um erro ao recuperar os dados" });
            });
    }

    componentDidMount() {
        this.fetch();
    }

    render() {
        return (this.state.loaded &&
            <>
                <Row>
                    <Col><input type="datetime-local" value={this.state.inicio.format("YYYY-MM-DDTHH:mm")} onChange={e => this.setState({ inicio: moment(e.target.value) })} className='form-control' /></Col>
                    <Col><input type="datetime-local" value={this.state.fim.format("YYYY-MM-DDTHH:mm")} onChange={e => this.setState({ fim: moment(e.target.value) })} className='form-control' /></Col>
                    <Col><Button className='form-control' variant="primary" onClick={() => this.fetch()}><FontAwesomeIcon icon={faSearch} /> Buscar</Button></Col>
                </Row>
                <Row className='mt-3'>
                    <Col>
                        <StyledSlider min={0} max={this.state.viatura.Localizacoes.length-1} value={this.state.indexes} onChange={values => this.setState({indexes: values})} renderTrack={Track} renderThumb={Thumb} />
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className='mt-3'>
                        <MapContainer
                            center={this.state.center}
                            inertia={false}
                            zoom={15}
                            scrollWheelZoom='center'
                            style={{ minHeight: '70vh', height: '100%', width: '100wh' }}>
                            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                            <Polyline positions={this.state.viatura.Localizacoes.slice(...this.state.indexes).map(localizacao => [localizacao.latitude, localizacao.longitude])} />
                            {this.state.viatura.Localizacoes.slice(...this.state.indexes).map((localizacao, key) =>
                                <Marker key={key} position={[localizacao.latitude, localizacao.longitude]} icon={customIcon}>
                                    <Popup>
                                        <b>Data/Hora:</b> {new Date(localizacao.datahora).toLocaleString()}<br />
                                        <b>Posição:</b> {localizacao.latitude}, {localizacao.longitude}<br />
                                        <b>Velocidade:</b> {localizacao.velocidade * 3.6} km/h<br />
                                    </Popup>
                                </Marker>
                            )}
                        </MapContainer>
                    </Col>
                </Row>
            </>
        )
    }
}

export default ViaturaMap;