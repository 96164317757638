import React from "react";
import { AppContext } from "../../../context";
import ModelList from '../ModelList';

class DenunciasEncaminhadasList extends React.Component {
  static contextType = AppContext;

  render() {
    
    return (
      <ModelList
        url={this.context.config.BACKEND_URL + '/denuncia/encaminhadas'}
        title="Denuncias Encaminhadas"
        modelIcon="faFolderOpen"
        modelName="denuncia"
      />
    );
  }

}

export default DenunciasEncaminhadasList;
