import React from 'react';
import { AppContext } from '../../../context';
import ModelList from '../ModelList';
import BloqueioForm from './BloqueioForm';

class BloqueioList extends React.Component {
    static contextType = AppContext;
    render(){
        return (
            <ModelList 
                url={this.context.config.BACKEND_URL + '/bloqueio'}
                title="Lista de Bloqueios"                
                modelIcon="faBan"
                useAdd={false}
                modelName="bloqueio"
                modelForm={BloqueioForm}
            />
        );
    }
}

export default BloqueioList;

