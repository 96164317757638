import React from 'react';
import MaterialForm from './MaterialForm';
import ModelList from '../ModelList';
import { AppContext } from '../../../context';

class MaterialList extends React.Component {  
    
    static contextType = AppContext;

    render(){
        return (
            <ModelList 
                url={this.context.config.BACKEND_URL + '/material'}
                title="Lista de Material"
                modelName="material"
                modelIcon="faClipboardList"
                modelForm={MaterialForm}
                useAdd={true}
            />
        );
    }
}

export default MaterialList;