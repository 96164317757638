import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Form as BSForm, FormGroup } from "react-bootstrap";
import Request from "../../../request";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { AppContext } from "../../../context";

class ConfiguracaoForm extends React.Component {

    static contextType = AppContext;

    save(values, callback){
        const method = this.props.configuracao.id? 'PUT' : 'POST';
        const url = this.context.config.BACKEND_URL +'/configuracao' + (this.props.configuracao.id? '/'+this.props.configuracao.id : '');

        Request(method, url, this.context.token)
        .send(values)
        .then( res => {
                this.context.addToast({titulo: "Successo", conteudo: "Registro atualizado com sucesso."});
                callback();
                if (this.props.onSave) this.props.onSave();
                this.context.closeModal();                                
        })
        .catch( err => {
            this.context.addToast({titulo: "Erro", conteudo: "Houve uma falha na gravação do registro."});
            callback();
        });
    }

    render(){
        return (
            <Formik
                initialValues={{ 
                    chave: this.props.configuracao.chave,
                    tipo: this.props.configuracao.tipo,
                    valor_numero: this.props.configuracao.valor_numero,
                    valor_logico: this.props.configuracao.valor_logico,
                    valor_texto: this.props.configuracao.valor_texto,
                    descricao: this.props.configuracao.descricao,
                    publico: this.props.configuracao.publico,
                }}
                validate={values => {     
                    const errors = {};     
                    if (!values.chave) {    
                        errors.chave = 'Campo obrigatório';     
                    }
                    if (!values.tipo) {    
                        errors.tipo = 'Campo obrigatório';     
                    }
                    if (values.tipo === 'numero' && values.valor_numero === '') {
                        errors.valor_numero = 'Campo obrigatório';     
                    }
                    if (values.publico === '') {
                        errors.publico = 'Campo obrigatório';     
                    }
                    if (values.tipo === 'logico' && values.valor_logico === '') {
                        errors.valor_logico = 'Campo obrigatório';     
                    }
                    if (!values.descricao) {    
                        errors.descricao = 'Campo obrigatório';     
                    }    
                    return errors;     
                }}
                onSubmit={(values, { setSubmitting }) => {     
                    this.save(values, () => setSubmitting(false));
                }}     
            >     
                {({ isSubmitting, values }) => {
                    const field = values.tipo === 'texto' ?
                        <Field as='textarea' name="valor_texto" className='form-control' />
                        : values.tipo === 'numero' ?
                        <Field type='number' name="valor_numero" className='form-control' />
                        : values.tipo === 'logico' ?
                        <Field as='select' name="valor_logico" className='form-control'>
                            <option value=''>Selecione um valor</option>
                            <option value={true}>Verdadeiro</option>
                            <option value={false}>Falso</option>
                        </Field>
                        : // Outro tipo
                        <Field disabled value="Selecione um tipo" className='form-control' />
                    

                    return (
                        <Form>
                            <FormGroup>
                                <BSForm.Label>Chave</BSForm.Label><ErrorMessage name="chave" component="span" className="text-danger small ml-2"/>
                                <Field type="text" name="chave" className="form-control"/>                            
                            </FormGroup>
                            <FormGroup>
                                <BSForm.Label>Tipo</BSForm.Label><ErrorMessage name="tipo" component="span" className="text-danger small ml-2"/>
                                <Field as='select' name="tipo" className='form-control'>
                                    <option value=''>Selecione um tipo</option>
                                    <option value='texto'>Texto</option>
                                    <option value='numero'>Número</option>
                                    <option value='logico'>Lógico</option>
                                </Field>                            
                            </FormGroup>
                            <FormGroup>
                                <BSForm.Label>Valor</BSForm.Label>
                                <ErrorMessage name="valor_numero" component="span" className="text-danger small ml-2"/>
                                <ErrorMessage name="valor_logico" component="span" className="text-danger small ml-2"/>
                                {field}                      
                            </FormGroup>
                            <FormGroup>
                                <BSForm.Label>Descrição</BSForm.Label><ErrorMessage name="descricao" component="span" className="text-danger small ml-2"/>
                                <Field type="text" name="descricao" className="form-control"/>                            
                            </FormGroup>
                            <FormGroup>
                                <BSForm.Label>Visibilidade</BSForm.Label>
                                <ErrorMessage name="publico" component="span" className="text-danger small ml-2"/>
                                <Field as='select' name="publico" className='form-control'>
                                    <option value=''>Selecione um valor</option>
                                    <option value={true}>Público</option>
                                    <option value={false}>Restrito</option>
                                </Field>
                            </FormGroup>
                            <FormGroup className="text-right">
                                <Button type="button" variant='secondary' onClick={() => this.context.closeModal()}>
                                    <FontAwesomeIcon icon={faTimes} />&nbsp;
                                    Fechar
                                </Button>
                                <Button type="submit" disabled={isSubmitting} variant='primary' className="ml-2">
                                    <FontAwesomeIcon icon={faSave} />&nbsp;
                                    Gravar Alterações
                                </Button>
                            </FormGroup>
                        </Form>
                    );
                }}
            </Formik>
        );
    }
}

export default ConfiguracaoForm;