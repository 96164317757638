import React from "react";
import { AppContext } from "../../../context";
import ModelList from "../ModelList";
import ViaturaForm from "./ViaturaForm";
import ViaturaMap from "./ViaturaMap";

class ViaturaList extends React.Component {
  static contextType = AppContext;

  onAction(action, update, defaultHandler){
    if (action.name === "edit" || action.name === "delete") defaultHandler();
    else if (action.name === "view"){
      this.context.openModal({ titulo: "Histórico de Localizações", conteudo: <ViaturaMap ViaturaId={action.id} />, size: "xl" });
    }
  }


  render() {

    return (
      <ModelList 
      url={this.context.config.BACKEND_URL + "/viatura"}
      onAction={(action, update, defaultHandler) => this.onAction(action, update, defaultHandler)}
      title="Lista de Viaturas"
      modelName="viatura"
      modelIcon="faTruckPickup"
      modelForm={ViaturaForm}
      useAdd={true}
  />
       
    );
  }
}

export default ViaturaList;
