import React from 'react';
import { AppContext } from '../../../context';
import ModelList from '../ModelList';
import DocumentoForm from './DocumentoForm';

class DocumentoList extends React.Component {   

    static contextType = AppContext;
    
    onAction(action, update, defaultHandler){
        if (action.name === 'download'){
            window.open(action.url);
            update();
        } else defaultHandler();
    }

    render(){
        return (
            <ModelList 
                url={this.context.config.BACKEND_URL + '/documento'}
                title="Lista de Documentos Arquivados"
                modelName="documento"
                modelIcon="faArchive"
                modelForm={DocumentoForm}
                modalSize="lg"
                useAdd={this.context.usuario.Permissoes.some(p => p.nome === 'documentos_documento_create')}
                onAction={(action, update, defaultHandler) => this.onAction(action, update, defaultHandler)}
            />
        );
    }
    
}

export default DocumentoList;