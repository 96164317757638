import React from 'react';
import AcessoForm from './admin/AcessoForm';
import ModelList from './ModelList';
import { AppContext } from '../../context';

class MyReportPage extends React.Component {    

    static contextType = AppContext;

    render(){
        return (
            <ModelList 
                url={this.context.config.BACKEND_URL + '/usuario/' + this.context.usuario.id + '/acesso'}
                title="Relatório de Acesso"
                modelName="acesso"
                modelIcon="faHistory"
                modelForm={AcessoForm}
            />
        );
    }
}

export default MyReportPage;