import { useMemo } from "react";
import { Marker, Popup } from "react-leaflet";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import L from 'leaflet';
import { renderToStaticMarkup } from 'react-dom/server';
import { divIcon } from "leaflet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";

const AlertaMarker = ({ alerta, eventHandlers }) => {

    const icon = useMemo(() => {

        const size = 40;

        const variants = {
            Aceito: "success",
            Pendente: "warning",
            Despachado: "info"
        }

        if (!alerta.ClassificacaoIcone || !Icons[alerta.ClassificacaoIcone]) {
            return new L.Icon.Default();
        } else {
            return new divIcon({
                html: renderToStaticMarkup(
                    <span className={`text-${variants[alerta.status]}`} style={{fontSize: `${size}px`, lineHeight: `${size}px` }}>
                        <FontAwesomeIcon icon={Icons[alerta.ClassificacaoIcone]} />
                    </span>),
                iconSize: [size, size],
                iconAnchor: [size / 2, size / 2]

            });
        }
    }, [alerta]);

    console.log(alerta);

    return <Marker
        icon={icon}
        position={[alerta.latitude, alerta.longitude]}
        draggable={false}
        eventHandlers={eventHandlers}>

        <Popup autoPan={false}>
            <h3>{alerta.ClassificacaoNome}</h3>
            <b>Identificação: </b>
            {alerta.identificacao} <br />
            <b>Telefone: </b>
            {alerta.telefone} <br />
            <b>Desenvolvedor: </b>
            {alerta.DesenvolvedorNome} <br />
            <Button className="mt-2 form-control" size="sm" onClick={eventHandlers.dblclick}>Gerenciar Alerta</Button>
        </Popup>
    </Marker>
};

export default AlertaMarker;