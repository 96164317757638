import React from "react";
import { Row, Col, Container, Table } from "react-bootstrap";
import { AppContext } from "../../../context";
import Chart from "../../Chart";
import Map from "../../Map";

class AlertaEscolaPage extends React.Component {
  static contextType = AppContext;

  render() {
    const data = [
      {
        label: "Belém",
        total: [
          { label: "Pendentes", total: 0 },
          { label: "Cancelados", total: 1 },
          { label: "Atendidos", total: 15 },
        ],
      },
      {
        label: "Ananideua",
        total: [
          { label: "Pendentes", total: 0 },
          { label: "Cancelados", total: 0 },
          { label: "Atendidos", total: 3 },
        ],
      },
      {
        label: "Marituba",
        total: [
          { label: "Pendentes", total: 1 },
          { label: "Cancelados", total: 2 },
          { label: "Atendidos", total: 5 },
        ],
      },
      {
        label: "Benevides",
        total: [
          { label: "Pendentes", total: 0 },
          { label: "Cancelados", total: 1 },
          { label: "Atendidos", total: 3 },
        ],
      },
      {
        label: "Santa Izabel",
        total: [
          { label: "Pendentes", total: 1 },
          { label: "Cancelados", total: 1 },
          { label: "Atendidos", total: 3 },
        ],
      },
    ];

    const position = [-1.4631897998991463, -48.4955653127071868];
    return (
      <Container striped>
        <h1>Resultados</h1>
        <Row>
          <Col>
            <h3>Por Cidades</h3>
            <Chart data={data} hide={true}></Chart>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <h3> Mapa de calor das zonas com mais atendimentos</h3>
            <Map position={position}>
            </Map>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <h3>Tabela das Cidades</h3>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Cidade</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {data.map((data, key) => (
                  <tr key={key}>
                    <td>{data.label}</td>
                    <td>
                      <Table>
                        <thead>
                          <th>Status</th>
                          <th>Total</th>
                        </thead>
                        <tbody>
                          {data.total.map((total, index) => (
                            <tr key={index}>
                              <td>{total.label}</td>
                              <td>{total.total}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default AlertaEscolaPage;
