import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  Button,
  Form as BSForm,
  FormControl,
  FormGroup,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import Request from "../../../request";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Select from "react-select";
import moment from "moment";
import { AppContext } from "../../../context";

class MaterialForm extends React.Component {

  static contextType = AppContext;

  state = {
    setores: [],
    tipos: [],
    filtro: "",
  };

  filtroCautela(filtro, cautela) {
    return (
      filtro === "" ||
      cautela.id.toString() === this.state.filtro ||
      cautela.Destino.sigla
        .toLowerCase()
        .includes(this.state.filtro.toLowerCase()) ||
      cautela.status.toLowerCase().includes(this.state.filtro.toLowerCase()) ||
      (moment(cautela.entrega) <= moment(this.state.filtro, "DD/MM/YYYY") &&
        (moment(cautela.devolucao) >= moment(this.state.filtro, "DD/MM/YYYY") ||
          !cautela.devolucao))
    );
  }

  componentDidMount() {
    Request("GET", this.context.config.BACKEND_URL + "/setor/options", this.context.token)
      .then((res) => {
        this.setState((state) => ({ setores: res.body }));
      })
      .catch((err) => {
        this.context.addToast({
          title: "Erro",
          text: "Falha na recuperação da lista de setores: " + err.toString(),
        });
      });

    Request("GET", this.context.config.BACKEND_URL + "/tipoMaterial/options", this.context.token)
      .then((res) => {
        this.setState((state) => ({ tipos: res.body }));
      })
      .catch((err) => {
        this.context.addToast({
          title: "Erro",
          text: "Falha na recuperação da lista de tipos: " + err.toString(),
        });
      });
  }

  save(values, callback) {
    const method = this.props.material.id ? "PUT" : "POST";
    const url =
      this.context.config.BACKEND_URL +
      "/material" +
      (this.props.material.id ? "/" + this.props.material.id : "");

    Request(method, url, this.context.token)
      .send({ ...values })
      .then((res) => {
        this.context.addToast({
          titulo: "Successo",
          conteudo: "Registro atualizado com sucesso.",
        });
        callback();
        if (this.props.onSave) this.props.onSave();
        this.context.closeModal();
      })
      .catch((err) => {
        this.context.addToast({
          titulo: "Erro",
          conteudo: "Houve uma falha na gravação do registro.",
        });
        callback();
      });
  }

  render() {
    return (
      <Formik
        initialValues={{
          ...this.props.material,
        }}
        validate={(values) => {
          const errors = {};
          if (!values.descricao) {
            errors.descricao = "Campo obrigatório";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          this.save(values, () => setSubmitting(false));
        }}
      >
        {({ isSubmitting, values, setFieldValue }) => {
          return (
            <Form>
              <Tabs defaultActiveKey="detalhes">
                <Tab eventKey="detalhes" title="Detalhes">
                  <FormGroup>
                    <BSForm.Label>Setor</BSForm.Label>
                    <ErrorMessage
                      name="setor"
                      component="span"
                      className="text-danger small ml-2"
                    />
                    <Select
                      noOptionsMessage={() => "Nada encontrado."}
                      placeholder="Pesquisar..."
                      options={this.state.setores.filter(
                        (option) =>
                          this.context.usuario.Setor.id === option.value ||
                          (this.context.usuario.Orgao.id === option.OrgaoId &&
                            this.context.usuario.Permissoes.some(
                              (p) => p.nome === "cautela_setor_global"
                            )) ||
                          this.context.usuario.Permissoes.some(
                            (p) => p.nome === "cautela_orgao_global"
                          )
                      )}
                      value={this.state.setores.find(
                        (option) => option.value === values.SetorId
                      )}
                      onChange={(option) =>
                        setFieldValue("SetorId", option ? option.value : null)
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <BSForm.Label>Tipo</BSForm.Label>
                    <ErrorMessage
                      name="tipo"
                      component="span"
                      className="text-danger small ml-2"
                    />
                    <Select
                      noOptionsMessage={() => "Nada encontrado."}
                      placeholder="Pesquisar..."
                      options={this.state.tipos}
                      value={this.state.tipos.find(
                        (option) => option.value === values.TipoId
                      )}
                      onChange={(option) =>
                        setFieldValue("TipoId", option ? option.value : null)
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <BSForm.Label>Descrição</BSForm.Label>
                    <ErrorMessage
                      name="descricao"
                      component="span"
                      className="text-danger small ml-2"
                    />
                    <Field
                      type="text"
                      name="descricao"
                      className="form-control"
                      value={values.descricao}
                    />
                  </FormGroup>
                  <FormGroup>
                    <BSForm.Label>Marca</BSForm.Label>
                    <ErrorMessage
                      name="marca"
                      component="span"
                      className="text-danger small ml-2"
                    />
                    <Field
                      type="text"
                      name="marca"
                      className="form-control"
                      value={values.marca}
                    />
                  </FormGroup>
                  <FormGroup>
                    <BSForm.Label>Modelo</BSForm.Label>
                    <ErrorMessage
                      name="modelo"
                      component="span"
                      className="text-danger small ml-2"
                    />
                    <Field
                      type="text"
                      name="modelo"
                      className="form-control"
                      value={values.modelo}
                    />
                  </FormGroup>
                  <FormGroup>
                    <BSForm.Label>Serial</BSForm.Label>
                    <ErrorMessage
                      name="serial"
                      component="span"
                      className="text-danger small ml-2"
                    />
                    <Field
                      type="text"
                      name="serial"
                      className="form-control"
                      value={values.serial}
                    />
                  </FormGroup>
                  <FormGroup>
                    <BSForm.Label>Registro de Patrimônio</BSForm.Label>
                    <ErrorMessage
                      name="patrimonio"
                      component="span"
                      className="text-danger small ml-2"
                    />
                    <Field
                      type="text"
                      name="patrimonio"
                      className="form-control"
                      value={values.patrimonio}
                    />
                  </FormGroup>
                  <FormGroup className="text-right">
                    <Button
                      type="button"
                      variant="secondary"
                      onClick={() => this.context.closeModal()}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                      &nbsp; Fechar
                    </Button>
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      variant="primary"
                      className="ml-2"
                    >
                      <FontAwesomeIcon icon={faSave} />
                      &nbsp; Gravar Alterações
                    </Button>
                  </FormGroup>
                </Tab>
                {this.props.material.id?
                <Tab eventKey="cautelas" title="Cautelas">
                  <FormGroup className="mt-2">
                    <FormControl
                      type="text"
                      value={this.state.filtro}
                      onChange={(event) =>
                        this.setState((state) => ({
                          filtro: event.target.value,
                        }))
                      }
                    />
                    <Table condensed striped className="mt-2">
                      <thead>
                        <tr>
                          <th>Nº</th>
                          <th>Destino</th>
                          <th>
                            Entrega
                            <br />
                            Devolução
                          </th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.material.Cautelas ? this.props.material.Cautelas.filter((cautela) =>
                          this.filtroCautela(this.state.filtro, cautela)
                        ).map((cautela, key) => (
                          <tr key={key}>
                            <th>{cautela.id}</th>
                            <th>{cautela.Destino.sigla}</th>
                            <th>
                              {moment(cautela.entrega)
                                .utcOffset(0)
                                .format("DD/MM/YYYY")}
                              <br />
                              {cautela.devolucao
                                ? moment(cautela.devolucao)
                                    .utcOffset(0)
                                    .format("DD/MM/YYYY")
                                : "Pendente"}
                            </th>
                            <th>{cautela.status}</th>
                          </tr>
                        )): null}
                      </tbody>
                    </Table>
                  </FormGroup>
                </Tab>: null}
              </Tabs>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

export default MaterialForm;
