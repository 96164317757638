import React from 'react';
import { AppContext } from '../../../context';

class AdminPage extends React.Component {   

    static contextType = AppContext;
     
    render(){
        return <h1>Página do Admin</h1>;
    }
}

export default AdminPage;