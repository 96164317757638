import BensEValoresPage from "./BensEValoresPage";
import DeclaracaoList from "./DeclaracaoList";
import DeclaracaoForm from "./DeclaracaoForm";
import DeclaracaoPrint from "./DeclaracaoPrint";
import DeclaracaoComprovante from "./DeclaracaoComprovante";
import TipoBemList from "./TipoBemList";
import TipoBemForm from "./TipoBemForm";
import VinculoList from "./VinculoList";
import VinculoForm from "./VinculoForm";
import RazaoList from "./RazaoList";
import RazaoForm from "./RazaoForm";





const BensEValoresContents = {
    BensEValoresPage,
    DeclaracaoList,
    DeclaracaoForm,
    DeclaracaoPrint,
    DeclaracaoComprovante,
    TipoBemList,
    TipoBemForm,
    VinculoList,
    VinculoForm,
    RazaoList,
    RazaoForm,
};

export default BensEValoresContents;