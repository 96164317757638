import React from 'react';
import { AppContext } from '../../../context';
import OrgaoForm from './OrgaoForm';
import ModelList from '../ModelList';

class OrgaoList extends React.Component {

    static contextType = AppContext;

    render() {

        return (
            <ModelList
                url={this.context.config.BACKEND_URL + '/orgao'}
                title="Lista de Orgãos"
                modelIcon="faBuilding"
                useAdd={this.context.usuario.Permissoes.some(p => p.nome === 'admin_orgao_create')}
                modelName="orgao"
                modelForm={OrgaoForm}
            />
        );
    }
}

export default OrgaoList;