import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App.js';
import L from 'leaflet';

import 'leaflet/dist/leaflet.css';
import "leaflet-control-geocoder/dist/Control.Geocoder.css";
import "./styles/eleicoes.css";

import "leaflet-control-geocoder/dist/Control.Geocoder.js";
import "bootstrap/dist/js/bootstrap.bundle.js";

import './scss/index.scss';
import './styles/index.css';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png').default,
  iconUrl: require('leaflet/dist/images/marker-icon.png').default,
  shadowUrl: require('leaflet/dist/images/marker-shadow.png').default
});


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);