import React from "react";
import "./css/index.css";
import { AppContext } from "../../../context";
import jsPDF from "jspdf";

class OcorrenciasForm extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            ocorrencia: props.ocorrencia || {}
        };
    }

    gerarPDF = () => {
        const { ocorrencia } = this.state;
        const doc = new jsPDF();

        doc.text("Formulário de Ocorrências", 10, 10);
        Object.entries(ocorrencia).forEach(([key, value], index) => {
            doc.text(`${key}: ${value}`, 10, 20 + index * 10);
        });

        doc.save("ocorrencia.pdf");
    };

    render() {
        const { ocorrencia } = this.state;
        return (
            <>
                <h3>Formulário de Ocorrências</h3>
                <hr />
                <form>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                            type="text"
                            className="form-control"
                            id="email"
                            name="email"
                            value={ocorrencia.email || ''}
                            readOnly
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="orgao_atendimento">Órgão de Atendimento</label>
                        <input
                            type="text"
                            className="form-control"
                            id="orgao_atendimento"
                            name="orgao_atendimento"
                            value={ocorrencia.orgao_atendimento || ''}
                            readOnly
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="municipio">Município</label>
                        <input
                            type="text"
                            className="form-control"
                            id="municipio"
                            name="municipio"
                            value={ocorrencia.municipio || ''}
                            readOnly
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="guarnicao">Especificação da Guarnição de Atendimento</label>
                        <input
                            type="text"
                            className="form-control"
                            id="guarnicao"
                            name="guarnicao"   
                            value={ocorrencia.guarnicao || ''}
                            readOnly
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="numero_programa">Número do Programa</label>
                        <input
                            type="text"
                            className="form-control"
                            id="numero_programa"
                            name="numero_programa"
                            value={ocorrencia.numero_programa || ''}
                            readOnly
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="quantidade_reincidencias">Residencias da Vítima</label>
                        <input
                            type="text"
                            className="form-control"
                            id="quantidade_reincidencias"
                            name="quantidade_reincidencias"
                            value={ocorrencia.quantidade_reincidencias || ''}
                            readOnly
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="tipo_acionamento">Tipo de Acionamento</label>
                        <input
                            type="text"
                            className="form-control"
                            id="tipo_acionamento"
                            name="tipo_acionamento"
                            value={ocorrencia.tipo_acionamento || ''}
                            readOnly
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="situacao_ocorrencia">Situação da Ocorrência</label>
                        <input
                            type="text"
                            className="form-control"
                            id="situacao_ocorrencia"
                            name="situacao_ocorrencia"
                            value={ocorrencia.situacao_ocorrencia || ''}
                            readOnly
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="local_ocorrencia">Local da Ocorrência</label>
                        <input
                            type="text"
                            className="form-control"
                            id="local_ocorrencia"
                            name="local_ocorrencia"
                            value={ocorrencia.local_ocorrencia || ''}
                            readOnly
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="data">Data do fato</label>
                        <input
                            type="text"
                            className="form-control"
                            id="data"
                            name="data"
                            value={ocorrencia.data_fato || ''}
                            readOnly
                        />
                    </div> 
                    <div className="form-group">
                        <label htmlFor="hora">Hora do fato</label>
                        <input
                            type="text"
                            className="form-control"
                            id="hora"
                            name="hora"
                            value={ocorrencia.hora_fato || ''}
                            readOnly
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="nome_vitima">Nome da Vítima</label>
                        <input
                            type="text"
                            className="form-control"
                            id="nome_vitima"
                            name="nome_vitima"
                            value={ocorrencia.nome_vitima || ''}
                            readOnly
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="idade_vitima">Idade da Vítima</label>
                        <input
                            type="text"
                            className="form-control"
                            id="idade_vitima"
                            name="idade_vitima"
                            value={ocorrencia.idade_vitima || ''}
                            readOnly
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="nome_agressor">Nome do Agressor</label>
                        <input
                            type="text"
                            className="form-control"
                            id="nome_agressor"
                            name="nome_agressor"
                            value={ocorrencia.nome_agressor || ''}
                            readOnly
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="idade_agressor">Idade do Agressor</label>
                        <input
                            type="text"
                            className="form-control"
                            id="idade_agressor"
                            name="idade_agressor"
                            value={ocorrencia.idade_agressor || ''}
                            readOnly
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="grau_parentesco">Grau de Parentesco</label>
                        <input
                            type="text"
                            className="form-control"
                            id="grau_parentesco"
                            name="grau_parentesco"
                            value={ocorrencia.grau_parentesco || ''}
                            readOnly
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="relatorio_ocorrencia">Relatório da Ocorrência</label>
                        <input
                            type="text"
                            className="form-control"
                            id="relatorio_ocorrencia"
                            name="relatorio_ocorrencia"
                            value={ocorrencia.relatorio_ocorrencia || ''}
                            readOnly
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="endereco_vitima">Endereço da Vítima</label>
                        <input
                            type="text"
                            className="form-control"
                            id="endereco_vitima"
                            name="endereco_vitima"
                            value={ocorrencia.endereco_vitima || ''}
                            readOnly
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="bairro_vitima">Bairro da Vítima</label>
                        <input
                            type="text"
                            className="form-control"
                            id="bairro_vitima"
                            name="bairro_vitima"
                            value={ocorrencia.bairro_vitima || ''}
                            readOnly
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="conclusao_ocorrencia">Conclusão da Ocorrência</label>
                        <input
                            type="text"
                            className="form-control"
                            id="conclusao_ocorrencia"
                            name="conclusao_ocorrencia"
                            value={ocorrencia.conclusao_ocorrencia || ''}
                            readOnly
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="observacoes">Procedimento Realizado na Delegacia Policial</label>
                        <input
                            type="text"
                            className="form-control"
                            id="observacoes"
                            name="observacoes"
                            value={ocorrencia.procedimento_delegacia || ''}
                            readOnly
                        />
                    </div>
                </form>
                
                <div className="d-flex justify-content-center mt-3">
                    <button type="button" className="btn btn-primary mr-2" onClick={() => this.context.setContent("OcorrenciasList")}>Voltar</button>
                    <button type="button" className="btn btn-primary" onClick={this.gerarPDF}>Gerar PDF da Ocorrência</button>
                </div>
            </>
        );
    }
}

export default OcorrenciasForm;
