import React from "react";
import { AppContext } from "../../../context";
import { faList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Datatable from "../../Datatable";
import Request from "../../../request";

class CartaoProgramaList extends React.Component {
  static contextType = AppContext;

  onAction(action, callback) {
    switch (action.name) {
      case "class":
        Request(
          "GET",
          this.context.config.BACKEND_URL + "/cartaoPrograma/" + action.id,
          this.context.token
        )
          .then((res) => {
            this.context.setContent("CartaoProgramaForm", {
              cartaoPrograma: res.body,
            });
          })
          .catch((err) => {
            this.context.addToast({
              titulo: "Erro",
              conteudo:
                "Houve uma falha na recuperação do registro: " + err.toString(),
            });
          });

        break;

      case "delete":
        if (window.confirm("Deseja realmente excluir este registro?")) {
          Request(
            "DELETE",
            this.context.config.BACKEND_URL + "/cartaoPrograma/" + action.id,
            this.context.token
          )
            .then((res) => {
              callback();
            })
            .catch((err) => {
              this.context.addToast({
                titulo: "Erro",
                conteudo:
                  "Houve uma falha na exclusão do registro: " + err.toString(),
              });
            });
        }
        break;

      default:
    }
  }

  render() {
    return (
      <>
        <h3>
          <FontAwesomeIcon icon={faList} /> Lista de Cartões Programa
        </h3>
        <hr />
        <Datatable
          url={this.context.config.BACKEND_URL + "/cartaoPrograma/"}
          autoSeach={true}
          useAdd={true}
          onError={(err) =>
            this.context.addToast({ titulo: "Erro", conteudo: err.toString() })
          }
          onAction={(action, update) => this.onAction(action, update)}
          onClickAdd={() =>
            this.context.setContent("CartaoProgramaForm", {
              cartaoPrograma: {},
            })
          }
        />
      </>
    );
  }
}

export default CartaoProgramaList;
