import { faDownload, faExclamationCircle, faPlus, faSave, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Col, Form as BSForm, FormGroup, Row, Tab, Table, Tabs, FormControl } from "react-bootstrap";
import Request from "../../../request.js";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Map from "./Map.js";
import Select from 'react-select';
import Diacritics from "diacritic";
import CreatableSelect from 'react-select/creatable';
import AddAnexos from "../../AddAnexos.js";
import { AppContext } from "../../../context.js";
import moment from "moment";
import "./css/index.css"

class VerTodasAsDenunciasForm extends React.Component {

    static contextType = AppContext;
    state = {
        cidades: [],
        bairros: [],
        canais: [],
        denuncias: [],
        orgaos: [],
        buttonClicked: false,

    }

    componentDidMount() {
        const errorHandler = err => this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na pesquisa: " + err.toString() });

        Request('GET', this.context.config.BACKEND_URL + '/canal/options', this.context.token).send()
            .then(options => {
                this.setState(() => ({ canais: options.body }));
            })
            .catch(errorHandler);
        Request('GET', this.context.config.BACKEND_URL + '/natureza/options', this.context.token).send()
            .then(options => {
                this.setState(() => ({ naturezas: options.body }));
            })
            .catch(errorHandler);
        Request('GET', this.context.config.BACKEND_URL + '/orgao/options', this.context.token).send()
            .then(options => {
                this.setState(() => ({ orgaos: options.body }));
            })
            .catch(errorHandler);
        Request('GET', this.context.config.BACKEND_URL + '/setor/options', this.context.token).send()
            .then(options => {
                this.setState(() => ({ setores: options.body }));
            })
            .catch(errorHandler);

        Request('GET', this.context.config.BACKEND_URL + '/cidade/options', this.context.token).send()
            .then(options => {
                this.setState(() => ({ cidades: options.body }));
            })
            .catch(errorHandler);

        Request('GET', this.context.config.BACKEND_URL + '/bairro/options', this.context.token).send()
            .then(options => {
                this.setState(() => ({ bairros: options.body }));
            })
            .catch(errorHandler);
    }

    save(values, update) {
        const url = this.context.config.BACKEND_URL + '/denuncia/denuncia/' + this.props.denuncia.id;
        Request('PUT', url, this.context.token).send(values).then(res => {
            this.context.addToast({ titulo: "Successo", conteudo: "Registro atualizado com sucesso." });
            this.context.setContent("ResponderDenunciasList");
        })
            .catch(err => {

                this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na gravação do registro.", err });
            });

    }
    render() {
        const options = [
            { value: 'Encaminhada', label: 'Encaminhada' },
            { value: 'Recebida', label: 'Recebida' },
            { value: 'Em Diligencia', label: 'Em Diligencia' },
            { value: 'Finalizada', label: 'Finalizada' },
        ]
        const optionsDiligencia = [
            { value: 'Sim', label: 'Sim' },
            { value: 'Não', label: 'Não' },
        ]

        const optionsProcedente = [
            { value: 'Sim', label: 'Sim' },
            { value: 'Não', label: 'Não' },
        ]

        const optionsAveriguada = [
            { value: 'Sim', label: 'Sim' },
            { value: 'Não', label: 'Não' },
        ]

        const optionsProcedimento = [
            { value: 'Sim', label: 'Sim' },
            { value: 'Não', label: 'Não' },
        ]
        const optionsNaoAveriguada = [
            { value: 'ENDEREÇO NÃO LOCALIZADO', label: 'ENDEREÇO NÃO LOCALIZADO' },
            { value: 'DENUNCIADO(S) NÃO LOCALIZADO(S)', label: 'DENUNCIADO(S) NÃO LOCALIZADO(S)' },
            { value: 'VÍTIMA(S) NÃO LOCALIZADA(S)', label: 'VÍTIMA(S) NÃO LOCALIZADA(S)' },
            { value: 'INFORMAÇÕES INSUFICIENTE', label: 'INFORMAÇÕES INSUFICIENTE' },
            { value: 'REDIRECIONAMENTO DE DENÚNCIA', label: 'REDIRECIONAMENTO DE DENÚNCIA' },
            { value: 'OUTROS', label: 'OUTROS' },
        ]

        const optionsTipoProcedimento = [
            { value: 'PROCEDIMENTOS POLICIAIS', label: 'PROCEDIMENTOS POLICIAIS' },
            { value: 'PROCEDIMENTOS CORRECIONAIS', label: 'PROCEDIMENTOS CORRECIONAIS' },
            { value: 'PROCEDIMENTOS DE UNIDADES POLICIAIS ESPECIALIZADAS', label: 'PROCEDIMENTOS DE UNIDADES POLICIAIS ESPECIALIZADAS' },
        ]

        const optionsDescProdecimentoPoliciais = [
            { value: 'IPL (Inquérito Policial)', label: 'IPL (Inquérito Policial)' },
            { value: 'TCO (Termo Circunstanciado de Ocorrências)', label: 'TCO (Termo Circunstanciado de Ocorrências)' },
            { value: 'Flagrante', label: 'Flagrante' },
            { value: 'Portaria', label: 'Portaria' }
        ]
        const optionsDescProdecimentoCorrecionais = [
            { value: 'IPL (Inquérito Policial)', label: 'IPL (Inquérito Policial)' },
            { value: 'PAD (Processo Administrativo Disciplinar)', label: 'PAD (Processo Administrativo Disciplinar)' },
            { value: 'AAI (Apuração Adminitrativa Interna)', label: 'AAI (Apuração Adminitrativa Interna)' },
            { value: 'IPM (Inquérito Policial Militar)', label: 'IPM (Inquérito Policial Militar)' },
            { value: 'PADS (Processo Adminstrativo Disciplinar Simplificado)', label: 'PADS (Processo Adminstrativo Disciplinar Simplificado)' },
            { value: 'SINDICÂNCIA', label: 'SINDICÂNCIA' }

        ]
        const optionsDescProdecimentoUniPolEsp = [
            { value: 'IPL (Inquérito Policial)', label: 'IPL (Inquérito Policial)' },
            { value: 'TCO (Termo Circunstanciado de Ocorrências)', label: 'TCO (Termo Circunstanciado de Ocorrências)' },
            { value: 'AA (Auto de Apreensão)', label: 'AA (Auto de Apreensão)' },
            { value: 'BOC (Boletim de Ocorrência Circunstancial)', label: 'BOC (Boletim de Ocorrência Circunstancial)' },
            { value: 'AI (Alto de Investigação)', label: 'AI (Alto de Investigação)' },
            { value: 'ADVERTÊNCIA', label: 'ADVERTÊNCIA' },
            { value: 'MULTA', label: 'MULTA' },
            { value: 'SUSPENSÃO DAS ATIVIDADES', label: 'SUSPENSÃO DAS ATIVIDADES' },
            { value: 'CASSAÇÃO DO REGISTRO, LICENÇA OU ALVARÁ', label: 'CASSAÇÃO DO REGISTRO, LICENÇA OU ALVARÁ' },

        ]
        const optionTipoPrisao = [
            { value: 'PRISÃO EM FLAGRANTE', label: 'PRISÃO EM FLAGRANTE' },
            { value: 'PRISÃO PREVENTIVA', label: 'PRISÃO PREVENTIVA' },
            { value: 'PRISÃO TEMPORÁRIA', label: 'PRISÃO TEMPORÁRIA' },
            { value: 'APREENSÃO', label: 'APREENSÃO' },
            { value: 'RECAPTURADO', label: 'RECAPTURADO' },
        ]
        const getOptionsBasedOnTipoProcedimento = (tipoProcedimento) => {
            switch (tipoProcedimento) {
                case "PROCEDIMENTOS POLICIAIS":
                    return optionsDescProdecimentoPoliciais;
                case "PROCEDIMENTOS CORRECIONAIS":
                    return optionsDescProdecimentoCorrecionais;
                case "PROCEDIMENTOS DE UNIDADES POLICIAIS ESPECIALIZADAS":
                    return optionsDescProdecimentoUniPolEsp;
                default:
                    return [];
            }
        };

        const optionsCoisa = [
            { value: 'ARMA DE FOGO', label: 'ARMA DE FOGO' },
            { value: 'MUNIÇÕES', label: 'MUNIÇÕES' },
            { value: 'ARMA BRANCA', label: 'ARMA BRANCA' },
            { value: 'ENTORPECENTES', label: 'ENTORPECENTES' },
            { value: 'VEÍCULOS', label: 'VEÍCULOS' },
            { value: 'ANIMAIS', label: 'ANIMAIS' },
            { value: 'APARELHOS ELETRÔNICOS', label: 'APARELHOS ELETRÔNICOS' },
            { value: 'DINHEIRO', label: 'DINHEIRO' },
            { value: 'OUTRAS APREENSÕES', label: 'OUTRAS APREENSÕES' },
        ]

        const optionsTipoArmaMunicao = [
            { value: 'REVÓLVER', label: 'REVÓLVER' },
            { value: 'PISTOLA', label: 'PISTOLA' },
            { value: 'OUTROS', label: 'OUTROS' },
        ]

        const optionsEntorpecentes = [
            { value: 'MACONHA', label: 'MACONHA' },
            { value: 'COCAINA', label: 'COCAINA' },
            { value: 'PASTA BASE', label: 'PASTA BASE' },
            { value: 'CRACK', label: 'CRACK' },
            { value: 'OXI', label: 'OXI' },
            { value: 'LIMÃOZINHO', label: 'LIMÃOZINHO' },
            { value: 'LSD', label: 'LSD' },
            { value: 'ECSTASY', label: 'ECSTASY' },
            { value: 'OUTROS', label: 'OUTROS' },
        ]

        const optionsVeiculos = [
            { value: 'CARRO', label: 'CARRO' },
            { value: 'MOTO', label: 'MOTO' },
            { value: 'OUTROS', label: 'OUTROS' },
        ]
        const optionsAparelhoEletronico = [
            { value: 'CELULAR', label: 'CELULAR' },
            { value: 'COMPUTADOR/NOTEBOOK/TABLET', label: 'COMPUTADOR/NOTEBOOK/TABLET' },
            { value: 'TELEVISÃO', label: 'TELEVISÃO' },
            { value: 'OUTROS', label: 'OUTROS' },
        ]

        const optionsCalibre = [
            { value: '22', label: '22' },
            { value: '32', label: '32' },
            { value: '38', label: '38' },
            { value: '357', label: '357' },
            { value: '765', label: '765' },
            { value: '380', label: '380' },
            { value: '.40', label: '.40' },
            { value: '6.35', label: '6.35' },
            { value: '45', label: '45' },
            { value: '9', label: '9' },
            { value: 'OUTROS', label: 'OUTROS' },
        ]
        const getOptionsBasedOnTipoCoisa = (tipoCoisa) => {
            switch (tipoCoisa) {
                case "ARMA DE FOGO":
                    return optionsTipoArmaMunicao;
                case "MUNIÇÕES":
                    return optionsTipoArmaMunicao;
                case "ENTORPECENTES":
                    return optionsEntorpecentes;
                case "VEÍCULOS":
                    return optionsVeiculos;
                case "APARELHOS ELETRÔNICOS":
                    return optionsAparelhoEletronico;
                default:
                    return [];
            }
        };

        const center = [this.props.denuncia.latitude, this.props.denuncia.longitude];
        const logradouro = this.props.denuncia.logradouro;
        const numero = this.props.denuncia.numero ?? null

        return (
            <Formik
                validateOnBlur={false}
                validateOnChange={false}
                initialValues={{
                    ...this.props.denuncia,
                    Anexos: this.props.denuncia?.Anexos ?? [],
                    Diligencias: (this.props.denuncia.Diligencias ?? []).map(diligencia => ({
                        ...diligencia,
                        Prisoes: (diligencia.Prisoes ?? []).map(prisoes => ({
                            ...prisoes,
                        })),
                        Apreensoes: (diligencia.Apreensoes ?? []).map(apreensoes => ({
                            ...apreensoes,

                        }))

                    })),
                    Apensos: this.props.denuncia?.Apensos ?? [],
                    CidadeId: this.props.denuncia?.CidadeId ?? [],
                    SetorId: this.props.denuncia?.SetorId ?? [],
                    BairroId: this.props.denuncia?.BairroId,
                    NaturezaId: this.props.denuncia?.NaturezaId,
                    relacionamentos: this.props.denuncia?.relacionamentos ?? [],
                    UsuarioId: this.props.denuncia?.UsuarioId ?? this.context.usuario.id,
                    urgente: this.props.denuncia?.urgente ?? false,
                    copia: this.props.denuncia?.copia ?? null,
                }}
                validate={values => {
                    const errors = {};

                    if (values.Diligencias) {
                        values.Diligencias.forEach((diligencia, index) => {
                            if (!diligencia.diligencia) {
                                errors[`Diligencias[${index}].diligencia`] = "Campo obrigatório";
                            }
                            if (!diligencia.status) {
                                errors[`Diligencias[${index}].status`] = "Campo obrigatório";
                            }
                            if (!diligencia.procedente) {
                                errors[`Diligencias[${index}].procedente`] = "Campo obrigatório";
                            } else {
                                if (diligencia.procedente === "Não") {
                                    if (!diligencia.justificativa) {
                                        errors[`Diligencias[${index}].justificativa`] = "Campo obrigatório";
                                    }
                                } else {
                                    if (!diligencia.averiguada) {
                                        errors[`Diligencias[${index}].averiguada`] = "Campo obrigatório";
                                    } else {
                                        if (diligencia.averiguada === "Não") {
                                            if (!diligencia.motivo) {
                                                errors[`Diligencias[${index}].motivo`] = "Campo obrigatório";
                                            }
                                            if (diligencia.motivo === "OUTROS") {
                                                if (!diligencia.outro_motivo) {
                                                    errors[`Diligencias[${index}].outro_motivo`] = "Campo obrigatório";
                                                }
                                            }
                                        } else {
                                            if (!diligencia.procedimento) {
                                                errors[`Diligencias[${index}].procedimento`] = "Campo obrigatório";
                                            } else {
                                                if (diligencia.procedimento === "Sim") {
                                                    if (!diligencia.num_procedimento) {
                                                        errors[`Diligencias[${index}].num_procedimento`] = "Campo obrigatório";
                                                        if (!diligencia.tipo_procedimento) {
                                                            errors[`Diligencias[${index}].tipo_procedimento`] = "Campo obrigatório";
                                                        }
                                                        if (!diligencia.desc_procedimento) {
                                                            errors[`Diligencias[${index}].desc_procedimento`] = "Campo obrigatório";
                                                        };
                                                    }
                                                }


                                            }
                                        }
                                    }
                                }
                                if (diligencia?.Apreensoes) {
                                    diligencia.Apreensoes.forEach((apreensoes, apreensaoIndex) => {
                                        if (!apreensoes.coisa) {
                                            errors[`Diligencias[${index}].Apreensoes[${apreensaoIndex}].coisa`] = "Campo obrigatório";
                                        }
                                        if (apreensoes.coisa === "ARMA DE FOGO" || apreensoes.coisa === "MUNIÇÕES" ||
                                            apreensoes.coisa === "ENTORPECENTES" ||
                                            apreensoes.coisa === "VEÍCULOS" ||
                                            apreensoes.coisa === "APARELHOS ELETRÔNICOS") {
                                            if (!apreensoes.tipo) {
                                                errors[`Diligencias[${index}].Apreensoes[${apreensaoIndex}].tipo`] = "Campo obrigatório";
                                            }
                                        }

                                        if ((apreensoes.coisa === "ARMA DE FOGO" || apreensoes.coisa === "MUNIÇÕES") && (apreensoes.tipo === "REVÓLVER" || apreensoes.tipo === "PISTOLA")) {
                                            if (!apreensoes.calibre) {
                                                errors[`Diligencias[${index}].Apreensoes[${apreensaoIndex}].calibre`] = "Campo obrigatório";
                                            }
                                        }
                                        if (apreensoes.coisa !== "DINHEIRO") {
                                            if (!apreensoes.quantidade) {
                                                errors[`Diligencias[${index}].Apreensoes[${apreensaoIndex}].quantidade`] = "Campo obrigatório";
                                            }
                                        }

                                        if (apreensoes.coisa === "DINHEIRO") {
                                            if (!apreensoes.valor) {
                                                errors[`Diligencias[${index}].Apreensoes[${apreensaoIndex}].valor`] = "Campo obrigatório";
                                            }
                                        }

                                        if (apreensoes.coisa !== "DINHEIRO" && (apreensoes.coisa === "ANIMAIS" || apreensoes.coisa === "OUTRAS APREENSÕES" || apreensoes.calibre === "OUTROS" || apreensoes.coisa === "APARELHOS ELETRÔNICOS" || apreensoes.tipo === "OUTROS" || apreensoes.coisa === "ARMA BRANCA")) {
                                            if (!apreensoes.descricao) {
                                                errors[`Diligencias[${index}].Apreensoes[${apreensaoIndex}].descricao`] = "Campo obrigatório";
                                            }
                                        }


                                    });
                                }

                                if (diligencia?.Prisoes) {
                                    diligencia.Prisoes.forEach((prisao, prisaoIndex) => {
                                        if (!prisao.tipo) {
                                            errors[`Diligencias[${index}].Prisoes[${prisaoIndex}].tipo`] = "Campo obrigatório";
                                        }
                                        if (!prisao.nome_preso) {
                                            errors[`Diligencias[${index}].Prisoes[${prisaoIndex}].nome_preso`] = "Campo obrigatório";
                                        }
                                    });
                                }
                            }
                        });
                    }

                    if (Object.keys(errors).length > 0) {
                        this.context.addToast({ titulo: "Erro", conteudo: "Verifique os campos obrigatórios e tente novamente." });
                    }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    if (values.Diligencias) {
                        values.Diligencias.forEach((diligencia) => {
                            if (diligencia.procedente === "Sim") {
                                diligencia.justificativa = null
                            }
                            else if (diligencia.procedente === "Não") {
                                diligencia.averiguada = "Não"
                                diligencia.motivo = null
                                diligencia.outro_motivo = null
                                diligencia.procedimento = "Não"
                                diligencia.num_procedimento = null
                                diligencia.tipo_procedimento = null
                                diligencia.desc_procedimento = null
                            }
                            if (diligencia.averiguada === "Não") {
                                diligencia.num_procedimento = null
                                diligencia.tipo_procedimento = null
                                diligencia.desc_procedimento = null
                                diligencia.procedimento = "Não"
                            }
                            else if (diligencia.averiguada === "Sim") {

                                diligencia.motivo = null
                                diligencia.outro_motivo = null
                            }
                            if (diligencia.procedimento === "Não") {
                                diligencia.num_procedimento = null
                                diligencia.tipo_procedimento = null
                                diligencia.desc_procedimento = null
                            }
                            if (diligencia?.Apreensoes) {
                                diligencia.Apreensoes.forEach((apreensoes) => {
                                    if (apreensoes.coisa === "DINHEIRO") {
                                        apreensoes.quantidade = null
                                        apreensoes.calibre = null
                                        apreensoes.tipo = null
                                        apreensoes.descricao = null

                                    }
                                    if (apreensoes.coisa === "ANIMAIS") {
                                        apreensoes.calibre = null
                                        apreensoes.valor = null
                                        apreensoes.descricao = null
                                        apreensoes.tipo = null
                                    }
                                    if (
                                        ((apreensoes.coisa === "ARMA DE FOGO" || apreensoes.coisa === "MUNIÇÕES") &&
                                            (apreensoes.tipo === "REVÓLVER" || apreensoes.tipo === "PISTOLA")) ||
                                        apreensoes.coisa === "ENTORPECENTES"
                                    ) {
                                        apreensoes.valor = null
                                        apreensoes.descricao = null
                                    }
                                    if ((apreensoes.coisa === "ARMA DE FOGO" || apreensoes.coisa === "MUNIÇÕES" ||
                                        apreensoes.coisa === "ENTORPECENTES" || apreensoes.coisa === "VEÍCULOS") &&
                                        (apreensoes.tipo === "OUTROS")) {
                                        apreensoes.valor = null
                                        apreensoes.calibre = null

                                    }
                                    if (apreensoes.coisa === "ARMA BRANCA" || apreensoes.coisa === "APARELHOS ELETRÔNICOS") {
                                        apreensoes.calibre = null
                                        apreensoes.tipo = null
                                    }
                                    if (apreensoes.coisa === "VEÍCULOS") {
                                        apreensoes.valor = null
                                        apreensoes.descricao = null
                                        apreensoes.calibre = null
                                    }
                                    if (apreensoes.coisa === "OUTRAS APREENSÕES") {
                                        apreensoes.calibre = null
                                        apreensoes.valor = null
                                        apreensoes.tipo = null
                                    }
                                });
                            }

                        });
                    }
                    this.save(values, () => setSubmitting(false));
                }}
            >
                {({ isSubmitting, values, setFieldValue, errors, setValues }) => {

                    return (
                        <>
                            <Form>
                                <Tabs className="mb-3" defaultActiveKey="dados-basicos">
                                    <Tab eventKey="dados-basicos" title={<span style={{fontWeight:"bold"}}>DENUNCIA: {this.props.denuncia.id}</span>}>
                                        <FormGroup>
                                            <Row>
                                                <Col md={6} className="d-flex flex-column">
                                                    <Row className="flex-grow-1">
                                                        <Col md="12">
                                                            <Map center={center} logradouro={logradouro} numero={numero} />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col >
                                                            <BSForm.Label as="b">Latitude</BSForm.Label>
                                                            <Field type="text" name="latitude" className="form-control" value={values.latitude} readOnly />
                                                        </Col>
                                                        <Col >
                                                            <BSForm.Label as="b">Longitude</BSForm.Label>
                                                            <Field type="text" name="longitude" className="form-control" value={values.longitude} readOnly />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Row>
                                                            <Col lg={4}>
                                                                <BSForm.Label as="b">Canal<span className="text-danger">*</span></BSForm.Label><ErrorMessage name="CanalId" component="span" className="text-danger small ml-2" />
                                                                <Select
                                                                    name="CanalId"
                                                                    isDisabled={true}
                                                                    value={this.state.canais ? this.state.canais.find(option => option.value === values.CanalId) : ''}
                                                                />
                                                            </Col>
                                                            <Col lg={8}>
                                                                <BSForm.Label as="b">Natureza</BSForm.Label>
                                                                <Select
                                                                    name="NaturezaId"
                                                                    isDisabled={true}
                                                                    value={this.state.naturezas ? this.state.naturezas.find(option => option.value === values.NaturezaId) : ''}

                                                                />
                                                            </Col>
                                                        </Row>
                                                    </FormGroup>
                                                    {values.descricao_original != null ?
                                                        <FormGroup>
                                                            <BSForm.Label as="b">Descrição Original</BSForm.Label>
                                                            <Field readOnly as="textarea" name="descricao_original" className="form-control" disabled={true} value={values.descricao_original} />
                                                        </FormGroup>
                                                        : null}
                                                    <FormGroup>
                                                        <BSForm.Label as="b">Descrição<span className="text-danger">*</span></BSForm.Label><ErrorMessage name="descricao" component="span" className="text-danger small ml-2" />
                                                        <Field as="textarea" name="descricao" className="form-control" disabled={true} value={values.descricao} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Row>
                                                            <Col md="9">
                                                                <BSForm.Label as="b">Logradouro</BSForm.Label>
                                                                <Field type="text" name="logradouro" className="form-control" disabled={true} value={values.logradouro} />
                                                            </Col>
                                                            <Col md="3">
                                                                <BSForm.Label as="b">Número</BSForm.Label>
                                                                <Field type="text" name="numero" className="form-control" disabled={true} value={values.numero} />
                                                            </Col>
                                                        </Row>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Row>
                                                            <Col md="6">
                                                                <BSForm.Label as="b">Cidade</BSForm.Label>
                                                                <Select
                                                                    name="CidadeId"
                                                                    isDisabled={true}
                                                                    value={this.state.cidades ? this.state.cidades.find(option => option.value === values.CidadeId) : ''}

                                                                />
                                                            </Col>
                                                            <Col md="6">
                                                                <BSForm.Label as="b">Bairro</BSForm.Label>
                                                                <CreatableSelect
                                                                    name="BairroId"
                                                                    isDisabled={true}
                                                                    value={this.state.bairros.find(bairro => bairro.value === values.BairroId)}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <BSForm.Label as="b">Estabelecimento</BSForm.Label>
                                                        <Field type="text" name="estabelecimento" className="form-control" disabled={true} value={values.estabelecimento} />
                                                    </FormGroup>
                                                    <Row>
                                                        <Col lg={6}>
                                                            <BSForm.Label as="b">Referência</BSForm.Label>
                                                            <Field type="text" name="referencia" className="form-control" disabled={true} value={values.referencia} />
                                                        </Col>
                                                        <Col lg={6}>
                                                            <BSForm.Label as="b">Status</BSForm.Label>
                                                            <Select
                                                                name="status"
                                                                options={options}
                                                                isDisabled
                                                                value={options.find(option => option.value === values.status)}
                                                                onChange={selectedOption => {
                                                                    const selectedValue = selectedOption ? selectedOption.value : null;
                                                                    setFieldValue('status', selectedValue);
                                                                }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row className="mt-2">
                                                        <Col lg={3}>
                                                            <div style={{ backgroundColor: '#ff6947', padding: '5px', borderRadius: '5px' }}>
                                                                <Field type="checkbox" name="urgente" disabled className="text-right" />
                                                                &nbsp;&nbsp;<FontAwesomeIcon icon={faExclamationCircle} />
                                                                <BSForm.Label as="b" style={{ marginLeft: '10px' }}>Urgente</BSForm.Label>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>

                                            </Row>
                                        </FormGroup>

                                    </Tab>
                                    {this.props.denuncia?.Anexos?.length > 0 ?
                                    <Tab eventKey="anexos" title="Anexos">
                                        <Table striped size="sm" responsive className='mt-2'>
                                            <thead className='bg-light'>
                                                <tr>
                                                    <th>Nome</th>
                                                    <th style={{ width: 120, textAlign: 'center' }}>Ações</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {values.Anexos.map((anexo, key) =>
                                                    <tr key={key}>
                                                        <td>
                                                            {anexo.nome ?? ""}
                                                        </td>
                                                        <td className='text-center'>
                                                            {anexo.id ?
                                                                <Button as="a" href={`${this.context.config.BACKEND_URL}/anexo/disquedenuncia/${anexo.id}/${anexo.uuid}`} target="_blank" download={anexo.nome} size="sm" variant='info' title='Download' className='mr-2'>
                                                                    <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                                                                </Button> : null}

                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>
                                    </Tab>:null}
                                    {this.props.denuncia?.Apensos?.length > 0 ?
                                        <Tab eventKey="apensos" title="Apenso">
                                            {values.Apensos && values.Apensos.map((apenso, index) =>
                                                <Row key={index} className="mb-2">
                                                    <Col >
                                                        <BSForm.Label as="b">Apenso #{index + 1}</BSForm.Label>
                                                        <Field as="textarea" type="text"
                                                            disabled={true}
                                                            name={`Apensos[${index}].conteudo`}
                                                            className="form-control"
                                                        />
                                                    </Col>

                                                </Row>
                                            )}
                                        </Tab> : null}
                                    {values.Diligencias.length > 0 ?
                                    <Tab eventKey="diligencias" title="Diligências">
                                        {values.Diligencias.length > 0 ? (
                                            values.Diligencias.map((diligencia, key) => (
                                                <React.Fragment key={key}>
                                                    <hr />
                                                    <Tabs className="mt-2">
                                                        <Tab eventKey={`dados-basicos-diligencia-${key}`} title="Dados Básicos da Diligência">
                                                            <Table striped bordered hover className="mt-1">
                                                                <thead>
                                                                    <td>
                                                                        {`Diligencia Número ${key + 1}`}
                                                                    </td>

                                                                    <td>
                                                                        {diligencia?.createdAt ? <strong>Criado em: {moment(diligencia?.createdAt).format("DD/MM/YYYY")}</strong> : null}
                                                                    </td>

                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td colSpan={2}>
                                                                            <Row className="mt-2">
                                                                                <Col>
                                                                                    <BSForm.Label as="b">ULTIMA ATUALIZAÇÃO <span className="text-danger">*</span></BSForm.Label><ErrorMessage name="data" component="span" className="text-danger small ml-2" />
                                                                                    <FormControl
                                                                                        disabled={true}
                                                                                        value={moment(diligencia?.updatedAt).format("DD/MM/YYYY")}
                                                                                    />
                                                                                </Col>
                                                                                <Col>
                                                                                    <BSForm.Label as="b">USUÁRIO<span className="text-danger">*</span></BSForm.Label><ErrorMessage name="usuario" component="span" className="text-danger small ml-2" />
                                                                                    <FormControl
                                                                                        disabled={true}
                                                                                        value={
                                                                                            diligencia?.Usuario?.nome ? diligencia?.Usuario?.nome
                                                                                                : this.context.usuario.nome
                                                                                        }
                                                                                    />

                                                                                </Col>
                                                                            </Row>
                                                                            <Row className="mt-2">
                                                                                <Col>
                                                                                    <BSForm.Label as="b">DESCRIÇÃO DA DILIGÊNCIA<span className="text-danger">*</span></BSForm.Label><ErrorMessage name={`Diligencias[${key}].diligencia`} component="span" className="text-danger small ml-2" />
                                                                                    <Field
                                                                                        as="textarea"
                                                                                        type="text"
                                                                                        name={`Diligencias[${key}].diligencia`}
                                                                                        disabled={(this.props.denuncia?.Diligencias === undefined ? false : this.props.denuncia?.Diligencias[key]?.status === "Sim")}
                                                                                        className="form-control"
                                                                                        onChange={(e) => setFieldValue(`Diligencias[${key}].diligencia`, e.target.value)}
                                                                                    />
                                                                                </Col>
                                                                                <Col>
                                                                                    <BSForm.Label as="b">FINALIZAR DILIGÊNCIA<span className="text-danger">*</span></BSForm.Label><ErrorMessage name={`Diligencias[${key}].status`} component="span" className="text-danger small ml-2" />
                                                                                    {typeof diligencia.status === 'undefined' ? diligencia.status = 'Não' : null}
                                                                                    <Select
                                                                                        name={`Diligencias[${key}].status`}
                                                                                        menuShouldBlockScroll={true}
                                                                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                                                        options={optionsDiligencia}
                                                                                        isDisabled={(this.props.denuncia?.Diligencias === undefined ? false : this.props.denuncia?.Diligencias[key]?.status === "Sim")}
                                                                                        value={optionsDiligencia.find(option => option.value === diligencia.status)}
                                                                                        onChange={selectedOption => {
                                                                                            const selectedValue = selectedOption ? selectedOption.value : null;
                                                                                            setFieldValue(`Diligencias[${key}].status`, selectedValue);
                                                                                        }}
                                                                                    />
                                                                                </Col>

                                                                            </Row>
                                                                            <Row className="mt-2">
                                                                                <Col lg={3}>
                                                                                    <BSForm.Label as="b">A DENÚNCIA É PROCEDENTE?<span className="text-danger">*</span></BSForm.Label><ErrorMessage name={`Diligencia[${key}].procedente`} component="span" className="text-danger small ml-2" />
                                                                                    <div>
                                                                                        {optionsProcedente.map((procedente, procedenteIndex) => (
                                                                                            <div key={procedenteIndex} className="form-check form-check-inline">
                                                                                                <input
                                                                                                    className="form-check-input"
                                                                                                    disabled={(this.props.denuncia?.Diligencias === undefined ? false : this.props.denuncia?.Diligencias[key]?.status === "Sim")}
                                                                                                    type="radio"
                                                                                                    name={`Diligencia[${key}].procedente`}
                                                                                                    id={`procedente_${key}_${procedenteIndex}`}
                                                                                                    value={procedente.value}
                                                                                                    checked={values.Diligencias[key].procedente === procedente.value}
                                                                                                    onChange={() => {
                                                                                                        const updateProcedente = procedente.value;
                                                                                                        setFieldValue(`Diligencias[${key}].procedente`, updateProcedente);
                                                                                                    }}

                                                                                                />
                                                                                                <label className="form-check-label" htmlFor={`procedente${key}_${procedenteIndex}`}>{procedente.label}</label>
                                                                                            </div>
                                                                                        ))}
                                                                                    </div>


                                                                                </Col>
                                                                                {diligencia?.procedente === "Não" ?
                                                                                    <Col>
                                                                                        <BSForm.Label as="b">JUSTIFICATIVA DE NÃO PROCEDENCIA<span className="text-danger">*</span></BSForm.Label><ErrorMessage name={`Diligencias[${key}].justificativa`} component="span" className="text-danger small ml-2" />
                                                                                        <Field
                                                                                            as="textarea"
                                                                                            type="text"
                                                                                            name={`Diligencias[${key}].justificativa`}
                                                                                            disabled={(this.props.denuncia?.Diligencias === undefined ? false : this.props.denuncia?.Diligencias[key]?.status === "Sim")}
                                                                                            className="form-control"
                                                                                            onChange={(e) => setFieldValue(`Diligencias[${key}].justificativa`, e.target.value)}
                                                                                        />
                                                                                    </Col>
                                                                                    : null
                                                                                }

                                                                            </Row>
                                                                            {diligencia?.procedente === "Sim" ?
                                                                                <Row className="mt-2">
                                                                                    <Col lg={3}>
                                                                                        <BSForm.Label as="b">A DENÚNCIA FOI AVERIGUADA?<span className="text-danger">*</span></BSForm.Label><ErrorMessage name={`Diligencia[${key}].averiguada`} component="span" className="text-danger small ml-2" />
                                                                                        <div>
                                                                                            {optionsAveriguada.map((averiguada, averiguadaIndex) => (
                                                                                                <div key={averiguadaIndex} className="form-check form-check-inline">
                                                                                                    <input
                                                                                                        className="form-check-input"
                                                                                                        disabled={(this.props.denuncia?.Diligencias === undefined ? false : this.props.denuncia?.Diligencias[key]?.status === "Sim")}
                                                                                                        type="radio"
                                                                                                        name={`Diligencia[${key}].averiguada`}
                                                                                                        id={`averiguada_${key}_${averiguadaIndex}`}
                                                                                                        value={averiguada.value}
                                                                                                        checked={values.Diligencias[key].averiguada === averiguada.value}
                                                                                                        onChange={() => {
                                                                                                            const updateaveriguada = averiguada.value;
                                                                                                            setFieldValue(`Diligencias[${key}].averiguada`, updateaveriguada);
                                                                                                        }}

                                                                                                    />
                                                                                                    <label className="form-check-label" htmlFor={`averiguada${key}_${averiguadaIndex}`}>{averiguada.label}</label>
                                                                                                </div>
                                                                                            ))}
                                                                                        </div>
                                                                                    </Col>
                                                                                    {diligencia?.averiguada === "Não" ?
                                                                                        <>
                                                                                            <Col>
                                                                                                <BSForm.Label as="b">MOTIVO DE NÃO AVERIGUAÇÃO<span className="text-danger">*</span></BSForm.Label><ErrorMessage name={`Diligencia[${key}].motivo`} component="span" className="text-danger small ml-2" />
                                                                                                <Select
                                                                                                    name={`Diligencias[${key}].motivo`}
                                                                                                    menuShouldBlockScroll={true}
                                                                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                                                                    options={optionsNaoAveriguada}
                                                                                                    isDisabled={(this.props.denuncia?.Diligencias === undefined ? false : this.props.denuncia?.Diligencias[key]?.status === "Sim")}
                                                                                                    value={optionsNaoAveriguada.find(option => option.value === diligencia.motivo)}
                                                                                                    onChange={selectedOption => {
                                                                                                        const selectedValue = selectedOption ? selectedOption.value : null;
                                                                                                        setFieldValue(`Diligencias[${key}].motivo`, selectedValue);
                                                                                                    }}
                                                                                                />
                                                                                            </Col>
                                                                                            {diligencia.motivo === "OUTROS" ?
                                                                                                <Col>
                                                                                                    <BSForm.Label as="b">Outro(s) Motivo(s)<span className="text-danger">*</span></BSForm.Label><ErrorMessage name={`Diligencia[${key}].outro_motivo`} component="span" className="text-danger small ml-2" />
                                                                                                    <Field
                                                                                                        type="text"
                                                                                                        name={`Diligencias[${key}].outro_motivo`}
                                                                                                        disabled={(this.props.denuncia?.Diligencias === undefined ? false : this.props.denuncia?.Diligencias[key]?.status === "Sim")}
                                                                                                        className="form-control"
                                                                                                        onChange={(e) => setFieldValue(`Diligencias[${key}].outro_motivo`, e.target.value)}
                                                                                                    />
                                                                                                </Col> : null
                                                                                            }
                                                                                        </>

                                                                                        : null
                                                                                    }
                                                                                </Row>

                                                                                : null}
                                                                            {diligencia?.averiguada === "Sim" && diligencia?.procedente === "Sim" ?
                                                                                <Row className="my-2">
                                                                                    <Col lg={3}>
                                                                                        <BSForm.Label as="b">HOUVE PROCEDIMENTO?<span className="text-danger">*</span></BSForm.Label><ErrorMessage name={`Diligencia[${key}].procedimento`} component="span" className="text-danger small ml-2" />
                                                                                        <div>
                                                                                            {optionsProcedimento.map((procedimento, procedimentoIndex) => (
                                                                                                <div key={procedimentoIndex} className="form-check form-check-inline">
                                                                                                    <input
                                                                                                        className="form-check-input"
                                                                                                        type="radio"
                                                                                                        disabled={(this.props.denuncia?.Diligencias === undefined ? false : this.props.denuncia?.Diligencias[key]?.status === "Sim")}
                                                                                                        name={`Diligencia[${key}].procedimento`}
                                                                                                        id={`procedimento_${key}_${procedimentoIndex}`}
                                                                                                        value={procedimento.value}
                                                                                                        checked={values.Diligencias[key].procedimento === procedimento.value}
                                                                                                        onChange={() => {
                                                                                                            const updateprocedimento = procedimento.value;
                                                                                                            setFieldValue(`Diligencias[${key}].procedimento`, updateprocedimento);
                                                                                                        }}

                                                                                                    />
                                                                                                    <label className="form-check-label" htmlFor={`procedimento${key}_${procedimentoIndex}`}>{procedimento.label}</label>
                                                                                                </div>
                                                                                            ))}
                                                                                        </div>
                                                                                    </Col>
                                                                                    {diligencia?.procedimento === "Sim" ?
                                                                                        <>
                                                                                            <Col>
                                                                                                <BSForm.Label as="b">NUMERO DO PROCEDIMENTO<span className="text-danger">*</span></BSForm.Label><ErrorMessage name={`Diligencia[${key}].tipo_procedimento`} component="span" className="text-danger small ml-2" />
                                                                                                <Field
                                                                                                    type="text"
                                                                                                    name={`Diligencias[${key}].num_procedimento`}
                                                                                                    className="form-control"
                                                                                                    isDisabled={(this.props.denuncia?.Diligencias === undefined ? false : this.props.denuncia?.Diligencias[key]?.status === "Sim")}
                                                                                                    onChange={(e) => setFieldValue(`Diligencias[${key}].num_procedimento`, e.target.value)}

                                                                                                />
                                                                                            </Col>
                                                                                            <Col>
                                                                                                <BSForm.Label as="b">TIPO DE PROCEDIMENTO<span className="text-danger">*</span></BSForm.Label><ErrorMessage name={`Diligencia[${key}].tipo_procedimento`} component="span" className="text-danger small ml-2" />
                                                                                                <Select
                                                                                                    name={`Diligencias[${key}].tipo_procedimento`}
                                                                                                    menuShouldBlockScroll={true}
                                                                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                                                                    options={optionsTipoProcedimento}
                                                                                                    isDisabled={(this.props.denuncia?.Diligencias === undefined ? false : this.props.denuncia?.Diligencias[key]?.status === "Sim")}
                                                                                                    value={optionsTipoProcedimento.find(option => option.value === diligencia.tipo_procedimento)}
                                                                                                    onChange={selectedOption => {
                                                                                                        const selectedValue = selectedOption ? selectedOption.value : null;
                                                                                                        setFieldValue(`Diligencias[${key}].tipo_procedimento`, selectedValue);
                                                                                                    }}
                                                                                                />
                                                                                            </Col>
                                                                                            <Col>
                                                                                                <BSForm.Label as="b">DESCRIÇÃO DE PROCEDIMENTO<span className="text-danger">*</span></BSForm.Label><ErrorMessage name={`Diligencia[${key}].desc_procedimento`} component="span" className="text-danger small ml-2" />
                                                                                                <Select
                                                                                                    name={`Diligencias[${key}].desc_procedimento`}
                                                                                                    menuShouldBlockScroll={true}
                                                                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                                                                    options={getOptionsBasedOnTipoProcedimento(diligencia.tipo_procedimento)}
                                                                                                    isDisabled={(this.props.denuncia?.Diligencias === undefined ? false : this.props.denuncia?.Diligencias[key]?.status === "Sim")}
                                                                                                    value={getOptionsBasedOnTipoProcedimento(this.props.denuncia?.Diligencias[key]?.tipo_procedimento).find(option => option.value === diligencia.desc_procedimento)}
                                                                                                    onChange={selectedOption => {
                                                                                                        const selectedValue = selectedOption ? selectedOption.value : null;
                                                                                                        setFieldValue(`Diligencias[${key}].desc_procedimento`, selectedValue);
                                                                                                    }}
                                                                                                />
                                                                                            </Col>
                                                                                        </>

                                                                                        : null
                                                                                    }
                                                                                </Row>
                                                                                : null}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>

                                                        </Tab>
                                                        <Tab eventKey={`apreensoes-${key}`} title="Apreensões">
                                                            {values.Diligencias[key]?.Apreensoes?.length > 0 ? (
                                                                values.Diligencias[key]?.Apreensoes?.map((apreensoes, apreensoesIndex) => (
                                                                    <Table striped bordered hover>
                                                                        <thead>
                                                                            <td>{`Apreensão Número ${apreensoesIndex + 1}`}</td>
                                                                            <td style={{ width: "1%", textAlign: 'center' }}>Excluir</td>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr><td>
                                                                                <Row key={apreensoesIndex} className="mt-2">
                                                                                    <Col lg={6}>

                                                                                        <BSForm.Label as="b">OBJETO APREENDIDO <span className="text-danger">*</span></BSForm.Label><ErrorMessage name={`Diligencias[${key}].Apreensoes[${apreensoesIndex}].coisa`} component="span" className="text-danger small ml-2" />
                                                                                        <Select
                                                                                            name={`Diligencias[${key}].Apreensoes[${apreensoesIndex}].coisa`}
                                                                                            menuShouldBlockScroll={true}
                                                                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                                                            options={optionsCoisa}
                                                                                            isDisabled={(this.props.denuncia?.Diligencias === undefined ? false : this.props.denuncia?.Diligencias[key]?.status === "Sim")}
                                                                                            value={optionsCoisa.find(option => option.value === apreensoes.coisa)}
                                                                                            onChange={selectedOption => {
                                                                                                const selectedValue = selectedOption ? selectedOption.value : null;
                                                                                                setFieldValue(`Diligencias[${key}].Apreensoes[${apreensoesIndex}.coisa]`, selectedValue);
                                                                                            }}
                                                                                        />
                                                                                    </Col>
                                                                                    {(apreensoes.coisa === "ARMA DE FOGO" || apreensoes.coisa === "MUNIÇÕES" ||
                                                                                        apreensoes.coisa === "ENTORPECENTES" ||
                                                                                        apreensoes.coisa === "VEÍCULOS" ||
                                                                                        apreensoes.coisa === "APARELHOS ELETRÔNICOS")
                                                                                        ?
                                                                                        <>
                                                                                            <Col lg={6}>
                                                                                                <BSForm.Label as="b">TIPO DE OBJETO APREENDIDO <span className="text-danger">*</span></BSForm.Label><ErrorMessage name={`Diligencias[${key}].Apreensoes[${apreensoesIndex}].tipo`} component="span" className="text-danger small ml-2" />
                                                                                                <Select
                                                                                                    name={`Diligencias[${key}].Apreensoes[${apreensoesIndex}].tipo`}
                                                                                                    menuShouldBlockScroll={true}
                                                                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                                                                    options={getOptionsBasedOnTipoCoisa(apreensoes.coisa)}
                                                                                                    isDisabled={(this.props.denuncia?.Diligencias === undefined ? false : this.props.denuncia?.Diligencias[key]?.status === "Sim")}
                                                                                                    value={getOptionsBasedOnTipoCoisa(this.props.denuncia?.Diligencias[key]?.Apreensoes[apreensoesIndex]?.coisa).find(option => option.value === apreensoes.tipo)}
                                                                                                    onChange={selectedOption => {
                                                                                                        const selectedValue = selectedOption ? selectedOption.value : null;
                                                                                                        setFieldValue(`Diligencias[${key}].Apreensoes[${apreensoesIndex}].tipo`, selectedValue);
                                                                                                    }}
                                                                                                />
                                                                                            </Col>
                                                                                        </>
                                                                                        : null}
                                                                                    {(apreensoes.coisa === "ARMA DE FOGO" || apreensoes.coisa === "MUNIÇÕES") && (apreensoes.tipo === "REVÓLVER" || apreensoes.tipo === "PISTOLA") ?
                                                                                        <Col lg={6}>
                                                                                            <BSForm.Label as="b">CALIBRE <span className="text-danger">*</span></BSForm.Label><ErrorMessage name={`Diligencias[${key}].Apreensoes[${apreensoesIndex}].calibre`} component="span" className="text-danger small ml-2" />
                                                                                            <Select
                                                                                                name={`Diligencias[${key}].Apreensoes[${apreensoesIndex}.calibre]`}
                                                                                                menuShouldBlockScroll={true}
                                                                                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                                                                options={optionsCalibre}
                                                                                                isDisabled={(this.props.denuncia?.Diligencias === undefined ? false : this.props.denuncia?.Diligencias[key]?.status === "Sim")}
                                                                                                value={optionsCalibre.find(option => option.value === apreensoes.calibre)}
                                                                                                onChange={selectedOption => {
                                                                                                    const selectedValue = selectedOption ? selectedOption.value : null;
                                                                                                    setFieldValue(`Diligencias[${key}].Apreensoes[${apreensoesIndex}.calibre]`, selectedValue);
                                                                                                }}
                                                                                            />
                                                                                        </Col> : null
                                                                                    }
                                                                                    {
                                                                                        apreensoes.coisa !== "DINHEIRO" ?
                                                                                            <Col lg={6}>
                                                                                                <BSForm.Label as="b">QUANTIDADE <span className="text-danger">*</span></BSForm.Label><ErrorMessage name={`Diligencias[${key}].Apreensoes[${apreensoesIndex}].quantidade`} component="span" className="text-danger small ml-2" />
                                                                                                <Field

                                                                                                    type="number"
                                                                                                    name={`Diligencias[${key}].Apreensoes[${apreensoesIndex}].quantidade`}
                                                                                                    disabled={(this.props.denuncia?.Diligencias === undefined ? false : this.props.denuncia?.Diligencias[key]?.status === "Sim")}
                                                                                                    className="form-control"
                                                                                                    onChange={(e) => setFieldValue(`Diligencias[${key}].Apreensoes[${apreensoesIndex}].quantidade`, e.target.value)}
                                                                                                />
                                                                                            </Col> : null
                                                                                    }
                                                                                    {
                                                                                        apreensoes.coisa === "DINHEIRO" ?
                                                                                            <Col lg={6}>
                                                                                                <BSForm.Label as="b">VALOR <span className="text-danger">*</span></BSForm.Label><ErrorMessage name={`Diligencias[${key}].Apreensoes[${apreensoesIndex}].valor`} component="span" className="text-danger small ml-2" />
                                                                                                <Field

                                                                                                    type="text"
                                                                                                    name={`Diligencias[${key}].Apreensoes[${apreensoesIndex}].valor`}
                                                                                                    disabled={(this.props.denuncia?.Diligencias === undefined ? false : this.props.denuncia?.Diligencias[key]?.status === "Sim")}
                                                                                                    className="form-control"
                                                                                                    onChange={(e) => setFieldValue(`Diligencias[${key}].Apreensoes[${apreensoesIndex}].valor`, e.target.value)}
                                                                                                />
                                                                                            </Col> : null
                                                                                    }

                                                                                    {(apreensoes.coisa !== "DINHEIRO") && (apreensoes.coisa === "ANIMAIS" || apreensoes.coisa === "OUTRAS APREENSÕES" || apreensoes.calibre === "OUTROS" || apreensoes.coisa === "APARELHOS ELETRÔNICOS" || apreensoes.tipo === "OUTROS" || apreensoes.coisa === "ARMA BRANCA") ?
                                                                                        <Col lg={6}>
                                                                                            <BSForm.Label as="b">DESCRIÇÃO <span className="text-danger">*</span></BSForm.Label><ErrorMessage name={`Diligencias[${key}].Apreensoes[${apreensoesIndex}].descricao`} component="span" className="text-danger small ml-2" />
                                                                                            <Field

                                                                                                type="text"
                                                                                                name={`Diligencias[${key}].Apreensoes[${apreensoesIndex}].descricao`}
                                                                                                disabled={(this.props.denuncia?.Diligencias === undefined ? false : this.props.denuncia?.Diligencias[key]?.status === "Sim")}
                                                                                                className="form-control"
                                                                                                onChange={(e) => setFieldValue(`Diligencias[${key}].Apreensoes[${apreensoesIndex}].descricao`, e.target.value)}
                                                                                            />
                                                                                        </Col> : null
                                                                                    }
                                                                                </Row></td>
                                                                                <td className='text-center align-middle'>
                                                                                    <Button size="sm" type='button' variant='danger' title='Excluir'
                                                                                        onClick={() => {
                                                                                            if (window.confirm("Deseja realmente excluir essa Apreensão ? ")) {
                                                                                                const updatedApreensoes = [...values.Diligencias[key]?.Apreensoes];
                                                                                                updatedApreensoes.splice(apreensoesIndex, 1);
                                                                                                setFieldValue(`Diligencias[${key}].Apreensoes`, updatedApreensoes);
                                                                                            }
                                                                                        }}                                                                                        >
                                                                                        <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
                                                                                    </Button>
                                                                                </td></tr>

                                                                        </tbody>
                                                                    </Table>


                                                                ))) : (
                                                                <h5 className="text-center mt-3">Nenhuma apreensão criada</h5>
                                                            )}
                                                            <Row className="mt-2">
                                                                <Col mb={3}>
                                                                    {this.props.denuncia?.Diligencias[key]?.status === "Sim" ? null : (
                                                                        <Button
                                                                            type="button"
                                                                            variant='secondary'
                                                                            className=" btn btn-warning my-3"
                                                                            onClick={() => {
                                                                                if (Array.isArray(values.Diligencias[key].Apreensoes)) {
                                                                                    setFieldValue(`Diligencias[${key}].Apreensoes`, [...values.Diligencias[key].Apreensoes, {}]);
                                                                                } else {
                                                                                    setFieldValue(`Diligencias[${key}].Apreensoes`, [{}]);
                                                                                }
                                                                            }}
                                                                        >
                                                                            <FontAwesomeIcon icon={faPlus} />&nbsp; Adicionar Apreensão
                                                                        </Button>

                                                                    )}
                                                                </Col>
                                                            </Row>

                                                        </Tab>
                                                        <Tab eventKey={`prisoes-${key}`} title="Prisões">
                                                            {values.Diligencias[key]?.Prisoes?.length > 0 ? (
                                                                values.Diligencias[key]?.Prisoes?.map((prisao, prisaoIndex) => (
                                                                    <Table striped bordered hover>
                                                                        <thead>
                                                                            <tr>
                                                                                <td>{`Prisão Número ${prisaoIndex + 1}`}</td>
                                                                                <td style={{ width: "1%", textAlign: 'center' }}>Excluir</td>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>
                                                                                    <Row key={prisaoIndex} className="my=3">
                                                                                        <Col>
                                                                                            <BSForm.Label as="b">TIPO DE PRISÃO OU APREENSÃO<span className="text-danger">*</span></BSForm.Label><ErrorMessage name={`Diligencias[${key}].Prisoes[${prisaoIndex}].tipo`} component="span" className="text-danger small ml-2" />
                                                                                            <Select
                                                                                                name={`Diligencias[${key}].Prisoes[${prisaoIndex}].tipo`}
                                                                                                menuShouldBlockScroll={true}
                                                                                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                                                                options={optionTipoPrisao}
                                                                                                isDisabled={(this.props.denuncia?.Diligencias === undefined ? false : this.props.denuncia?.Diligencias[key]?.status === "Sim")}
                                                                                                value={optionTipoPrisao.find(option => option.value === prisao.tipo)}
                                                                                                onChange={selectedOption => {
                                                                                                    const selectedValue = selectedOption ? selectedOption.value : null;
                                                                                                    setFieldValue(`Diligencias[${key}].Prisoes[${prisaoIndex}].tipo`, selectedValue);
                                                                                                }}
                                                                                            />
                                                                                        </Col>
                                                                                        <Col>
                                                                                            <BSForm.Label as="b">NOME COMPLETO DO PRESO<span className="text-danger">*</span></BSForm.Label><ErrorMessage name={`Diligencias[${key}].Prisoes[${prisaoIndex}].nome_preso`} component="span" className="text-danger small ml-2" />
                                                                                            <Field
                                                                                                name={`Diligencias[${key}].Prisoes[${prisaoIndex}].nome_preso`}
                                                                                                type="text"
                                                                                                disabled={this.props.denuncia?.Diligencias[key]?.status === "Sim"}
                                                                                                className="form-control"
                                                                                                onChange={(e) => setFieldValue(`Diligencias[${key}].Prisoes[${prisaoIndex}].nome_preso`, e.target.value)}

                                                                                            />
                                                                                        </Col>
                                                                                    </Row>
                                                                                </td>
                                                                                <td className='text-center align-middle'>
                                                                                    <Button size="sm" type='button' variant='danger' title='Excluir'
                                                                                        onClick={
                                                                                            () => {
                                                                                                if (window.confirm("Deseja realmente excluir essa Prisão ?")) {
                                                                                                    const updatedPrisoes = [...values.Diligencias[key]?.Prisoes];
                                                                                                    updatedPrisoes.splice(prisaoIndex, 1);
                                                                                                    setFieldValue(`Diligencias[${key}].Prisoes`, updatedPrisoes);
                                                                                                }
                                                                                            }

                                                                                        }
                                                                                    >
                                                                                        <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
                                                                                    </Button>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </Table>

                                                                ))
                                                            ) : (
                                                                <h5 className="text-center mt-3">Nenhuma prisão criada</h5>
                                                            )}
                                                            <Row className="mt-2">
                                                                <Col mb={3}>
                                                                    {this.props.denuncia?.Diligencias[key]?.status === "Sim" ? null : (
                                                                        <Button
                                                                            type="button"
                                                                            variant='success'
                                                                            className=" btn btn-warning my-3"
                                                                            onClick={() => {
                                                                                if (Array.isArray(values.Diligencias[key].Prisoes)) {
                                                                                    setFieldValue(`Diligencias[${key}].Prisoes`, [...values.Diligencias[key].Prisoes, {}]);
                                                                                } else {
                                                                                    setFieldValue(`Diligencias[${key}].Prisoes`, [{}]);
                                                                                }
                                                                            }}
                                                                        >
                                                                            <FontAwesomeIcon icon={faPlus} />&nbsp; Adicionar Prisão
                                                                        </Button>

                                                                    )}
                                                                </Col>
                                                            </Row>
                                                        </Tab>

                                                    </Tabs>
                                                </React.Fragment>
                                            ))
                                        ) : (
                                            <h5 className="text-center mt-3"> Nenhuma diligência criada</h5>
                                        )}
                                        
                                    </Tab> : null}
                                </Tabs>
                                <FormGroup className="text-right">
                                    <Button
                                        type="submit" disabled={isSubmitting} variant='primary'
                                        className={`mt-2 ml-2 ${Object.keys(errors).length > 0 ? 'shake' : ''}`
                                        }>
                                        <FontAwesomeIcon icon={faSave} />&nbsp;
                                        Gravar Alterações
                                    </Button>
                                </FormGroup>
                            </Form >
                        </>
                    );
                }
                }
            </Formik >
        );
    }
}

export default VerTodasAsDenunciasForm;