import { faEdit, faPalette, faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Col, Form as BSForm, Row, Tab, Tabs } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { AppContext } from "../../../context";
import CustomEditor from '../../Editor';
import * as Icons from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import * as Picker from 'react-color';

class ComponenteForm extends React.Component {

    static contextType = AppContext;

    state = {
        icones: Object.keys(Icons).sort().filter(icon => icon !== 'fas' && icon !== 'prefix').map(icon => ({ label: <><FontAwesomeIcon icon={Icons[icon]} />&nbsp;{icon.replace(/([A-Z])/g, ' $1').replace(/^fa /g, '')}</>, value: icon }))
    }

    render() {
        return (
            <Formik
                initialValues={{
                    id: this.props.componente.id,
                    CamadaId: this.props.componente.CamadaId,
                    nome: this.props.componente.nome,
                    tipo: this.props.componente.tipo,
                    descricao: this.props.componente.descricao,
                    Pontos: this.props.componente.Pontos ?? [],
                    oculto: this.props.componente.oculto ?? false,
                    linha: this.props.componente.linha ?? "#000000",
                    preenchimento: this.props.componente.preenchimento ?? "#000000",
                    icone: this.props.componente.icone ?? "",
                    tamanho: this.props.componente.tamanho ?? 20
                }}
                validate={values => {
                    const errors = {};
                    if (!values.nome) {
                        errors.nome = 'Campo obrigatório';
                    }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    this.context.isAuthorized("mapas_mapa_edit") && this.props.onSave(values, () => setSubmitting(false));
                }}
            >
                {({ isSubmitting, setFieldValue, values }) => {
                    return (
                        <Form>
                            <Tabs defaultActiveKey="detalhes">
                                <Tab eventKey="detalhes" title={<><FontAwesomeIcon icon={faEdit} /> Detalhes</>} className="mt-3">
                                    <Row className="mb-3">
                                        <Col md={8}>
                                            <BSForm.Label>Nome</BSForm.Label><ErrorMessage name="nome" component="span" className="text-danger small ml-2" />
                                            <Field type="text" name="nome" className="form-control" />
                                        </Col>
                                        <Col md={4}>
                                            <BSForm.Label>Tipo</BSForm.Label>
                                            <Field type="text" value={values.tipo} readonly disabled className="form-control" />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col>
                                            <BSForm.Label>Descrição</BSForm.Label>
                                            <CustomEditor
                                                name="descricao"
                                                value={values.descricao}
                                                setFieldValue={setFieldValue}
                                            />
                                        </Col>
                                    </Row>
                                </Tab>
                                <Tab eventKey="custom" title={<><FontAwesomeIcon icon={faPalette} /> Customizar</>} className="mt-3">
                                    <Row className="mb-3">
                                        <Col>
                                            <BSForm.Label>Linha / Marcador</BSForm.Label>
                                            <Picker.CirclePicker width="100%" color={values.linha} onChangeComplete={color => setFieldValue('linha', color.hex)} />
                                        </Col>
                                    </Row>
                                    {values.tipo === "Área" &&
                                        <Row className="mb-3">
                                            <Col>
                                                <BSForm.Label>Preenchimento</BSForm.Label>
                                                <Picker.CirclePicker width="100%" color={values.preenchimento} onChangeComplete={color => setFieldValue('preenchimento', color.hex)} />
                                            </Col>
                                        </Row>
                                    }
                                    {(values.tipo === "Ponto" || values.tipo === "Nuvem") &&
                                        <Row className="mb-3">
                                            <Col md={8}>
                                                <BSForm.Label>Ícone</BSForm.Label>
                                                <Select
                                                    menuShouldBlockScroll={true}
                                                    isClearable={true}
                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                    noOptionsMessage={() => "Nada encontrado."}
                                                    placeholder="Pesquise ou Digite"
                                                    options={this.state.icones}
                                                    value={this.state.icones.find(option => option.value === values.icone)}
                                                    onChange={option => setFieldValue(`icone`, option ? option.value : null)}
                                                />
                                            </Col>
                                            <Col md={4}>
                                                <BSForm.Label>Tamanho ({values.tipo === "Nuvem" ? "m" : "px"})</BSForm.Label>
                                                <Field type="number" min={0} max={values.tipo === "Nuvem" ? 100000 : 100} step={1} name="tamanho" className="form-control" />
                                            </Col>
                                        </Row>
                                    }
                                </Tab>
                            </Tabs>
                            <Row className="text-right">
                                <Col>
                                    <Button type="button" variant='secondary' onClick={() => this.context.closeModal()}>
                                        <FontAwesomeIcon icon={faTimes} />&nbsp;
                                        Fechar
                                    </Button>
                                    {this.context.isAuthorized("mapas_mapa_edit") &&
                                        <Button type="submit" disabled={isSubmitting} variant='primary' className="ml-2">
                                            <FontAwesomeIcon icon={faSave} />&nbsp;
                                            Gravar Alterações
                                        </Button>
                                    }
                                </Col>
                            </Row>
                        </Form>
                    );
                }}
            </Formik >
        );
    }
}

export default ComponenteForm;