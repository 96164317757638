import React from "react";
import { Col, ResponsiveEmbed, Row } from "react-bootstrap";
import { AppContext } from '../../../context';

class ChapaDetalhes extends React.Component {

  static contextType = AppContext;

  render() {
    const chapa = this.props.chapa;
    return (
      <Row className={`align-items-stretch`}>
        {chapa.iframe_video && (
          <Col md={6} className="mb-2">
            <ResponsiveEmbed
              aspectRatio="16by9"
              style={{ height: "100%" }}
            >
              <div
                className="teste"
                dangerouslySetInnerHTML={{
                  __html: chapa.iframe_video,
                }}
              />
            </ResponsiveEmbed>
          </Col>
        )}
        {chapa.foto_instituicao && (
          <Col md={6} className="text-center mb-2">
            <img
              alt="Foto da Instituição"
              className="img-fluid shadow-4 foto_instituicao"
              src={chapa.foto_instituicao}
              style={{ height: "100%" }}
            />
          </Col>
        )}
        {chapa.descricao && (
          <Col md={6} className="my-3">
            <h5>Histórico</h5>
            <div
              className="historico"
              dangerouslySetInnerHTML={{
                __html: chapa.descricao,
              }}
            />
          </Col>
        )}

        <Col md={3} className="text-center my-3">
          <h5>Titular</h5>
          <img
            alt="Foto do Titular"
            className="img-fluid shadow-4 foto"
            src={chapa.foto_titular}
            style={{ width: "100%", maxWidth: 600 }}
          />
          <br />
          {chapa.nome_titular}
        </Col>
        <Col md={3} className="text-center my-3">
          <h5>Suplente</h5>
          <img
            alt="Foto do Suplente"
            className="img-fluid shadow-4 foto"
            src={chapa.foto_suplente}
            style={{ width: "100%", maxWidth: 600 }}
          />
          <br />
          {chapa.nome_suplente}
        </Col>
      </Row>
    );
  }
}

export default ChapaDetalhes;
