import React from "react";
import { AppContext } from "../../../context";
import Request from "../../../request";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  Button,
  Col,
  Form as BSForm,
  Row,
  Tab,
  Tabs,
  FormGroup,
  Table,
  Card,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faFile,
  faPlus,
  faSave,
  faStarOfLife,
  faTimes,
  faTrash,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

import AddAnexos from "../../AddAnexos";
import moment from "moment";
import ReactInputMask from "react-input-mask";

class CadastroFormPpddh extends React.Component {
  static contextType = AppContext;

  state = {
    risps: [],
    cidades: [],
    bairros: [],
    showModal: false,
    success: false,
    tipo_militancias: [],
  };

  save(values, callback) {
    const method = this.props.defensor.id ? "PUT" : "POST";
    const url =
      this.context.config.BACKEND_URL +
      "/defensor" +
      (this.props.defensor.id ? "/" + this.props.defensor.id : "");
    Request(method, url, this.context.token)
      .send(values)
      .then((res) => {
        this.context.addToast({
          titulo: "Successo",
          conteudo: "Registro atualizado com sucesso.",
        });
        callback();
        this.context.setContent("DefensorList");
      })
      .catch((err) => {
        this.context.addToast({
          titulo: "Erro",
          conteudo: "Houve uma falha na gravação do registro.",
        });
        callback();
      });
  }

  componentDidMount() {
    const errorHandler = (err) =>
      this.context.addToast({
        titulo: "Erro",
        conteudo: "Houve uma falha na pesquisa.",
      });

    Request(
      "GET",
      this.context.config.BACKEND_URL + "/ppddh/tipo_militancia/options",
      this.context.token
    )
      .send()
      .then((res) => {
        this.setState(() => ({ tipo_militancias: res.body }));
      })
      .catch(errorHandler);

    Request(
      "GET",
      this.context.config.BACKEND_URL + "/cidade/options",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ cidades: options.body }));
      })
      .catch(errorHandler);

    Request(
      "GET",
      this.context.config.BACKEND_URL + "/bairro/options",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ bairros: options.body }));
      })
      .catch(errorHandler);
    Request(
      "GET",
      this.context.config.BACKEND_URL + "/risp/options",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ risps: options.body }));
      })
      .catch(errorHandler);
  }

  render() {
    const status = [
      { value: "Incluído", label: "Incluído" },
      { value: "Em Analise", label: "Em Analise" },
      { value: "Desligado", label: "Desligado" },
    ];

    const tipoMilitanciaOptions = this.state.tipo_militancias.map((item) => ({
      value: item.id,
      label: item.descricao,
    }));

    const tipo = [
      {
        value: "Mapeamento e Análise de Risco",
        label: "Mapeamento e Análise de Risco",
      },
      {
        value: "Relatório de Rondas Policiais",
        label: "Relatório de Rondas Policiais",
      },
      { value: "Escoltas Policiais", label: "Escoltas Policiais" },
    ];

    const grau_risco = [
      { value: "Baixo", label: "Baixo" },
      { value: "Moderado", label: "Moderado" },
      { value: "Alto", label: "Alto" },
    ];

    return (
      <Formik
        initialValues={{
          ...this.props.defensor,
          TipoMilitanciaId: this.props.defensor.TipoMilitanciaId ?? null,
          Processos: (this.props.defensor.Processos ?? []).map((processo) => ({
            ...processo,
            AnexoProcesso: {
              ...processo.AnexoProcesso,
            },
          })),
          Anexos: (this.props.defensor.Anexos ?? []).map((anexo) => ({
            ...anexo,
            conteudo:
              anexo.conteudo != null
                ? Buffer.from(anexo.conteudo).toString()
                : null,
          })),
        }}
        validate={(values) => {
          const errors = {};
          if (!values.nome) {
            errors.nome = "Campo obrigatório";
          }
          if (!values.logradouro) {
            errors.logradouro = "Campo obrigatório";
          }
          if (!values.RispId) {
            errors.RispId = "Campo obrigatório";
          }
          if (!values.TipoMilitanciaId) {
            errors.TipoMilitanciaId = "Campo obrigatório";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          this.save(values, () => setSubmitting(false));
        }}
      >
        {({ isSubmitting, values, setFieldValue, setValues }) => {
          return (
            <>
              <Form>
                <Tabs defaultActiveKey="defensor">
                  <Tab
                    eventKey="defensor"
                    title={
                      <div>
                        <FontAwesomeIcon icon={faUser} />{" "}
                        <span className="d-none d-lg-inline">Defensor</span>
                      </div>
                    }
                  >
                    <Row>
                      <Col md={10}>
                        <Card className="mt-3">
                          <Card.Header>
                            <strong>Dados Básicos</strong>
                          </Card.Header>
                          <Card.Body>
                            <Row>
                              <Col md={6}>
                                <BSForm.Label>Nome</BSForm.Label>
                                <span className="text-danger">*</span>
                                <ErrorMessage
                                  name="nome"
                                  component="span"
                                  className="text-danger small ml-2"
                                />
                                <Field
                                  type="text"
                                  name="nome"
                                  className="form-control"
                                  value={values.nome}
                                />
                              </Col>

                              <Col md={6}>
                                <BSForm.Label>Tipo de militância</BSForm.Label>
                                <span className="text-danger">*</span>
                                <ErrorMessage
                                  name="TipoMilitanciaId"
                                  component="span"
                                  className="text-danger small ml-2"
                                />
                                <Select
                                  name="TipoMilitanciaId"
                                  noOptionsMessage={(text) =>
                                    text.inputValue + " não encontrado!"
                                  }
                                  styles={{
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                  }}
                                  menuPortalTarget={document.body}
                                  placeholder="Selecione..."
                                  options={tipoMilitanciaOptions}
                                  value={tipoMilitanciaOptions.find(
                                    (option) =>
                                      values.TipoMilitanciaId === option.value
                                  )}
                                  onChange={(option) => {
                                    setFieldValue(
                                      "TipoMilitanciaId",
                                      option ? option.value : null
                                    );
                                  }}
                                />
                              </Col>

                              <Col md={6}>
                                <BSForm.Label>Contato</BSForm.Label>
                                <ErrorMessage
                                  name="contato"
                                  component="span"
                                  className="text-danger small ml-2"
                                />
                                <ReactInputMask
                                  name="contato"
                                  className="form-control"
                                  mask="(99) 99999-9999"
                                  onChange={(e) =>
                                    setFieldValue("contato", e.target.value)
                                  }
                                  value={values.contato}
                                />
                              </Col>

                              <Col md={4} className="mt-2">
                                <BSForm.Label>Data do Cadastro</BSForm.Label>
                                <ErrorMessage
                                  name="data_inclusao"
                                  component="span"
                                  className="text-danger small ml-2"
                                />
                                <Field
                                  type="date"
                                  name="data_inclusao"
                                  className="form-control"
                                  value={values.data_inclusao}
                                />
                              </Col>

                              <Col md={4} className="mt-2">
                                <BSForm.Label>Grau de Risco</BSForm.Label>
                                <ErrorMessage
                                  name="grau_risco"
                                  component="span"
                                  className="text-danger small ml-2"
                                />
                                <Select
                                  name="grau_risco"
                                  styles={{
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                  }}
                                  menuPortalTarget={document.body}
                                  placeholder="Selecione..."
                                  options={grau_risco}
                                  value={grau_risco.find(
                                    (grau_risco) =>
                                      grau_risco.value === values.grau_risco
                                  )}
                                  onChange={(option) => {
                                    setFieldValue("grau_risco", option.value);
                                  }}
                                />
                              </Col>

                              <Col md={4} className="mt-2">
                                <BSForm.Label>Status</BSForm.Label>
                                <ErrorMessage
                                  name="status"
                                  component="span"
                                  className="text-danger small ml-2"
                                />
                                <Select
                                  name="status"
                                  styles={{
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                  }}
                                  menuPortalTarget={document.body}
                                  placeholder="Selecione..."
                                  options={status}
                                  value={status.find(
                                    (option) => option.value === values.status
                                  )}
                                  onChange={(options) => {
                                    setFieldValue("status", options.value);
                                  }}
                                />
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col md={2}>
                        <Card className="mt-3">
                          <Card.Header>
                            <strong>Foto</strong>
                          </Card.Header>
                          <Card.Body>
                            <Table size="sm" responsive>
                              <tbody>
                                {values.Anexos?.filter(
                                  (anexo) =>
                                    !anexo.deleted &&
                                    !anexo.nome.includes(".pdf")
                                ).map((anexo, key) => (
                                  <tr key={key}>
                                    <td>
                                      <img
                                        alt="Foto"
                                        style={{ width: 100 }}
                                        src={anexo.conteudo}
                                      />
                                      <Button
                                        vertical="true"
                                        size="sm"
                                        type="button"
                                        variant="danger"
                                        title="Excluir"
                                        onClick={() => {
                                          if (
                                            anexo.nome.endsWith(".jpg") ||
                                            anexo.nome.endsWith(".png") ||
                                            anexo.nome.endsWith(".jpeg")
                                          ) {
                                            setFieldValue(
                                              "Anexos",
                                              values.Anexos.filter(
                                                (a) => a !== anexo
                                              )
                                            );
                                          }
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          icon={faTrash}
                                        ></FontAwesomeIcon>
                                      </Button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                            {values.Anexos?.every((anexo) =>
                              anexo.nome.includes(".pdf")
                            ) ? (
                              <AddAnexos
                                accept="image/*"
                                multiple={false}
                                asDataURL={true}
                                onError={(error) => {
                                  this.context.addToast({
                                    titulo: "Erro",
                                    conteudo:
                                      "Falha ao carregar o arquivo " +
                                      error.toString(),
                                  });
                                }}
                                onLoad={(anexos) =>
                                  setFieldValue("Anexos", [
                                    ...values.Anexos,
                                    ...anexos,
                                  ])
                                }
                              />
                            ) : null}
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>

                    <Card className="mt-3">
                      <Card.Header>
                        <strong>Endereço</strong>
                      </Card.Header>
                      <Card.Body>
                        <Row>
                          <Col md={4} className="mt-2">
                            <BSForm.Label>Regiões de Integração</BSForm.Label>
                            <span className="text-danger">*</span>
                            <ErrorMessage
                              name="Risps"
                              component="span"
                              className="text-danger small ml-2"
                            />
                            <Select
                              name="Risps"
                              noOptionsMessage={(text) =>
                                text.inputValue + " não encontrado!"
                              }
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                              }}
                              menuPortalTarget={document.body}
                              placeholder="Selecione..."
                              options={this.state.risps}
                              value={this.state.risps.filter(
                                (option) => values.RispId === option.value
                              )}
                              onChange={(options) => {
                                setFieldValue(
                                  "RispId",
                                  options ? options.value : null
                                );
                              }}
                            />
                          </Col>

                          <Col md={4} className="mt-2">
                            <BSForm.Label>Cidade</BSForm.Label>
                            <ErrorMessage
                              name="CidadeId"
                              component="span"
                              className="text-danger small ml-2"
                            />
                            <Select
                              name="CidadeId"
                              noOptionsMessage={() => "Nada encontrado."}
                              placeholder="Pesquisar..."
                              options={this.state.cidades}
                              value={
                                this.state.cidades
                                  ? this.state.cidades.find(
                                      (option) =>
                                        option.value === values.CidadeId
                                    )
                                  : ""
                              }
                              onChange={(option) => {
                                setFieldValue(
                                  "CidadeId",
                                  option ? option.value : null
                                );
                                setFieldValue("BairroId", null);
                              }}
                            />
                          </Col>

                          <Col md={4} className="mt-2">
                            <BSForm.Label>Bairro</BSForm.Label>
                            <ErrorMessage
                              name="BairroId"
                              component="span"
                              className="text-danger small ml-2"
                            />
                            <CreatableSelect
                              name="BairroId"
                              noOptionsMessage={() => "Nada encontrado."}
                              onCreateOption={(label) => {
                                this.setState(
                                  (state) => ({
                                    bairros: [
                                      {
                                        value: "new",
                                        label: label,
                                        CidadeId: values.CidadeId,
                                      },
                                      ...state.bairros,
                                    ],
                                  }),
                                  () => {
                                    setFieldValue("BairroId", "new");
                                  }
                                );
                              }}
                              placeholder="Pesquisar..."
                              formatCreateLabel={(label) =>
                                "Adicionar " + label
                              }
                              options={this.state.bairros.filter(
                                (bairro) => bairro.CidadeId === values.CidadeId
                              )}
                              value={this.state.bairros.find(
                                (bairro) => bairro.value === values.BairroId
                              )}
                              onChange={(option) => {
                                setFieldValue(
                                  "BairroId",
                                  option ? option.value : null
                                );
                              }}
                            />
                          </Col>

                          <Col md={5} className="mt-2">
                            <BSForm.Label>Logradouro</BSForm.Label>
                            <span className="text-danger">*</span>
                            <ErrorMessage
                              name="logradouro"
                              component="span"
                              className="text-danger small ml-2"
                            />
                            <Field
                              type="text"
                              name="logradouro"
                              className="form-control"
                            />
                          </Col>

                          <Col md={2} className="mt-2">
                            <BSForm.Label>Número</BSForm.Label>
                            <ErrorMessage
                              name="numero"
                              component="span"
                              className="text-danger small ml-2"
                            />
                            <Field
                              type="text"
                              name="numero"
                              className="form-control"
                              value={values.numero}
                            />
                          </Col>

                          <Col md={5} className="mt-2">
                            <BSForm.Label>Referência</BSForm.Label>
                            <ErrorMessage
                              name="referencia"
                              component="span"
                              className="text-danger small ml-2"
                            />
                            <Field
                              type="text"
                              name="referencia"
                              className="form-control"
                              value={values.referencia}
                            />
                          </Col>

                          <Col md={4} className="mt-2">
                            <BSForm.Label>Latitude</BSForm.Label>
                            <ErrorMessage
                              name="latitude"
                              component="span"
                              className="text-danger small ml-2"
                            />
                            <Field
                              type="text"
                              name="latitude"
                              className="form-control"
                              value={values.latitude}
                            />
                          </Col>

                          <Col md={4} className="mt-2">
                            <BSForm.Label>Longitude</BSForm.Label>
                            <ErrorMessage
                              name="longitude"
                              component="span"
                              className="text-danger small ml-2"
                            />
                            <Field
                              type="text"
                              name="longitude"
                              className="form-control"
                              value={values.longitude}
                            />
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>

                    <Card className="mt-3">
                      <Card.Header>
                        <strong>Ponto Focal</strong>
                      </Card.Header>
                      <Card.Body>
                        <Row>
                          <Col md={6} className="mt-2">
                            <BSForm.Label>Nome</BSForm.Label>
                            <ErrorMessage
                              name="focal"
                              component="span"
                              className="text-danger small ml-2"
                            />
                            <Field
                              type="text"
                              name="focal"
                              className="form-control"
                            />
                          </Col>

                          <Col md={6} className="mt-2">
                            <BSForm.Label>Contato</BSForm.Label>
                            <ErrorMessage
                              name="contato_focal"
                              component="span"
                              className="text-danger small ml-2"
                            />
                            <ReactInputMask
                              name="contato_focal"
                              className="form-control"
                              mask="(99) 99999-9999"
                              onChange={(e) =>
                                setFieldValue("contato_focal", e.target.value)
                              }
                              value={values.contato_focal}
                            />
                          </Col>

                          <Col md={4} className="mt-2">
                            <BSForm.Label>Unidade</BSForm.Label>
                            <ErrorMessage
                              name="unidade_focal"
                              component="span"
                              className="text-danger small ml-2"
                            />
                            <Field
                              type="text"
                              name="unidade_focal"
                              className="form-control"
                            />
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>

                    <Card className="mt-3">
                      <Card.Header>
                        <strong>Observações Gerais</strong>
                      </Card.Header>
                      <Card.Body>
                        <Row>
                          <Col md={12}>
                            <ErrorMessage
                              name="observacao"
                              component="span"
                              className="text-danger small ml-2"
                            />
                            <Field
                              as="textarea"
                              name="observacao"
                              className="form-control"
                              value={values.observacao}
                            />
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Tab>

                  <Tab
                    eventKey="anexos"
                    title={
                      <div>
                        <FontAwesomeIcon icon={faFile} />{" "}
                        <span className="d-none d-lg-inline">Anexos</span>
                      </div>
                    }
                  >
                    <Table striped size="sm" responsive className="mt-3">
                      <thead className="bg-light mt-5">
                        <tr>
                          <th>Título</th>
                          <th>Tipo</th>
                          <th>Quantidade de Rondas</th>
                          <th>Número PAE</th>
                          <th style={{ minWidth: "1%" }}>Data</th>
                          <th style={{ width: 120, textAlign: "center" }}>
                            Ações
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {values.Anexos?.filter((anexo) =>
                          anexo.nome.includes(".pdf")
                        ).map((anexo, key) => (
                          <tr key={key}>
                            <td>{anexo.titulo}</td>
                            <td>{anexo.tipo}</td>
                            <td>{anexo.quantidade}</td>
                            <td>{anexo.numero_pae}</td>
                            <td style={{ whiteSpace: "nowrap" }}>
                              {moment(anexo.data_relatorio).format(
                                "DD/MM/YYYY"
                              )}
                            </td>
                            <td className="text-center">
                              {anexo.id ? (
                                <Button
                                  as="a"
                                  href={`${this.context.config.BACKEND_URL}/anexo/defensor/${anexo.id}/${anexo.hash}/${anexo.nome}`}
                                  download={anexo.nome}
                                  size="sm"
                                  variant="info"
                                  title="Download"
                                  className="mr-2"
                                >
                                  <FontAwesomeIcon
                                    icon={faDownload}
                                  ></FontAwesomeIcon>
                                </Button>
                              ) : null}
                              <Button
                                size="sm"
                                type="button"
                                variant="danger"
                                title="Excluir"
                                onClick={() =>
                                  window.confirm(
                                    "Deseja realmente excluir este arquivo?"
                                  ) &&
                                  setFieldValue(
                                    "Anexos",
                                    values.Anexos.filter((a) => a !== anexo)
                                  )
                                }
                              >
                                <FontAwesomeIcon
                                  icon={faTrash}
                                ></FontAwesomeIcon>
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colSpan="5">
                            <AddAnexos
                              multiple={true}
                              onError={(file) =>
                                this.context.addToast({
                                  titulo: "Erro",
                                  conteudo:
                                    "Falha ao carregar o arquivo " + file.name,
                                })
                              }
                              onLoad={(anexos) =>
                                setFieldValue("Anexos", [
                                  ...values.Anexos,
                                  ...anexos,
                                ])
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="4">
                            <ErrorMessage
                              name="Anexos"
                              component="span"
                              className="text-danger small ml-2"
                            />
                          </td>
                        </tr>
                      </tfoot>
                    </Table>

                    <Row className="mt-2">
                      <Col md={6}>
                        <BSForm.Label>Título</BSForm.Label>
                        <ErrorMessage
                          name="titulo"
                          component="span"
                          className="text-danger small ml-2"
                        />
                        <Field
                          type="text"
                          name="titulo"
                          className="form-control"
                          onChange={(e) =>
                            setFieldValue(
                              (values.Anexos.at(-1).titulo = e.target.value)
                            )
                          }
                        />
                      </Col>
                      <Col md={6}>
                        <BSForm.Label>Tipo</BSForm.Label>
                        <ErrorMessage
                          name="tipo"
                          component="span"
                          className="text-danger small ml-2"
                        />
                        <Select
                          name="tipo"
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                          menuPortalTarget={document.body}
                          placeholder="Selecione..."
                          options={tipo}
                          value={tipo.find(
                            (tipo) => tipo.value === values.tipo
                          )}
                          onChange={(option) => {
                            setFieldValue(
                              (values.Anexos.at(-1).tipo = option.value)
                            );
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <BSForm.Label>Quantidade de Rondas</BSForm.Label>
                        <ErrorMessage
                          name="quantidade"
                          component="span"
                          className="text-danger small ml-2"
                        />
                        <Field
                          type="text"
                          name="quantidade"
                          className="form-control"
                          onChange={(e) =>
                            setFieldValue(
                              (values.Anexos.at(-1).quantidade = e.target.value)
                            )
                          }
                        />
                      </Col>
                      <Col md={4}>
                        <BSForm.Label>Número do PAE</BSForm.Label>
                        <ErrorMessage
                          name="numero_pae"
                          component="span"
                          className="text-danger small ml-2"
                        />
                        <Field
                          type="text"
                          name="numero_pae"
                          className="form-control"
                          onChange={(e) =>
                            setFieldValue(
                              (values.Anexos.at(-1).numero_pae = e.target.value)
                            )
                          }
                        />
                      </Col>
                      <Col md={4}>
                        <BSForm.Label>Data do Relatório</BSForm.Label>
                        <ErrorMessage
                          name="data_relatorio"
                          component="span"
                          className="text-danger small ml-2"
                        />
                        <Field
                          type="date"
                          name="data_relatorio"
                          className="form-control"
                          onChange={(e) =>
                            setFieldValue(
                              (values.Anexos.at(-1).data_relatorio =
                                e.target.value)
                            )
                          }
                        />
                      </Col>
                    </Row>
                  </Tab>

                  <Tab
                    eventKey="processos"
                    title={
                      <div>
                        <FontAwesomeIcon icon={faStarOfLife} />{" "}
                        <span className="d-none d-lg-inline">Processos</span>
                      </div>
                    }
                  >
                    <Table striped size="sm" responsive className="mt-3">
                      <thead className="bg-light" width="100%">
                        <tr width="100%">
                          <th width="32%">Número do Pae</th>
                          <th>Assunto</th>
                          <th width="20%"> Anexos</th>
                          <th>Ações</th>
                          <th width="1%"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {values.Processos.map((processo, key) => (
                          <tr key={key}>
                            <td>
                              <Field
                                type="text"
                                placeholder="Número do PAE"
                                className="form-control"
                                name={`Processos[${key}].pae_numero`}
                              />
                            </td>
                            <td>
                              <Field
                                type="text"
                                placeholder="Assunto"
                                className="form-control"
                                name={`Processos[${key}].assunto`}
                              />
                            </td>
                            <td>
                              {(!processo.AnexoProcesso ||
                                !processo.AnexoProcesso.id) && (
                                <AddAnexos
                                  accept="pdf/*"
                                  multiple={false}
                                  onError={(file) =>
                                    this.context.addToast({
                                      titulo: "Erro",
                                      conteudo:
                                        "Falha ao carregar o arquivo " +
                                        file.name,
                                    })
                                  }
                                  onLoad={(anexo) => {
                                    setFieldValue(
                                      `Processos[${key}].AnexoProcesso`,
                                      { ...anexo }
                                    );
                                  }}
                                />
                              )}
                            </td>
                            {processo.AnexoProcesso &&
                            processo.AnexoProcesso.id ? (
                              <td className="text-center" width="2%">
                                <Button
                                  as="a"
                                  href={`${this.context.config.BACKEND_URL}/anexo/processo/${processo.AnexoProcesso.id}/${processo.AnexoProcesso.hash}/${processo.AnexoProcesso.nome}`}
                                  download={processo.AnexoProcesso.nome}
                                  size="sm"
                                  variant="info"
                                  title="Download"
                                  className="mr-2"
                                >
                                  <FontAwesomeIcon icon={faDownload} />
                                </Button>
                              </td>
                            ) : (
                              <td></td>
                            )}
                            <td className="text-center">
                              <Button
                                type="button"
                                variant="danger"
                                size="sm"
                                onClick={() => {
                                  if (
                                    window.confirm(
                                      "Deseja realmente excluir este item?"
                                    )
                                  ) {
                                    setFieldValue(
                                      "Processos",
                                      values.Processos.filter(
                                        (p) => p !== processo
                                      )
                                    );
                                  }
                                }}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colSpan={6}>
                            <Button
                              type="button"
                              variant="success"
                              className="form-control btn btn-success"
                              onClick={() =>
                                setFieldValue("Processos", [
                                  ...values.Processos,
                                  {
                                    pae_numero: null,
                                    assunto: null,
                                    AnexoProcesso: null,
                                  },
                                ])
                              }
                            >
                              <FontAwesomeIcon icon={faPlus} />
                              &nbsp; Adicionar Processo
                            </Button>
                          </td>
                        </tr>
                      </tfoot>
                    </Table>
                    <ErrorMessage
                      name="Processos"
                      component="span"
                      className="text-danger small ml-2"
                    />
                  </Tab>
                </Tabs>
                <FormGroup className="text-right mt-4">
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={() => {
                      this.context.setContent("DefensorList");
                      setValues({
                        ...values,
                        Anexos: [],
                        Processos: [],
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                    &nbsp; Fechar
                  </Button>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    variant="primary"
                    className="ml-2"
                  >
                    <FontAwesomeIcon icon={faSave} />
                    &nbsp; Gravar Alterações
                  </Button>
                </FormGroup>
              </Form>
            </>
          );
        }}
      </Formik>
    );
  }
}

export default CadastroFormPpddh;
