import React from 'react';
import PerfilForm from './PerfilForm';
import { AppContext } from '../../../context';
import ModelList from '../ModelList';

class PerfilList extends React.Component {
    
    static contextType = AppContext;

    render(){
        return (
            <ModelList
                url={this.context.config.BACKEND_URL + '/perfil'}
                title="Lista de Perfis"
                modelIcon="faUserSecret"
                useAdd={this.context.usuario.Permissoes.some(p => p.nome === 'admin_perfil_create')}
                modelName="perfil"
                modelForm={PerfilForm}
            />
        );
    }
}


export default PerfilList;