import { faImage, faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Form as BSForm, FormGroup, Image } from "react-bootstrap";
import Request from "../../../request";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import AddAnexos from "../../AddAnexos";
import { AppContext } from "../../../context";

class OrgaoForm extends React.Component {

    static contextType = AppContext;

    save(values, callback) {
        const method = this.props.orgao.id ? 'PUT' : 'POST';
        const url = this.context.config.BACKEND_URL + '/orgao' + (this.props.orgao.id ? '/' + this.props.orgao.id : '');

        Request(method, url, this.context.token)
            .send(values)
            .then(() => {
                this.context.addToast({ titulo: "Successo", conteudo: "Registro atualizado com sucesso." });
                callback();
                if (this.props.onSave) this.props.onSave();
                this.context.closeModal();
            })
            .catch(err => {
                this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na gravação do registro." });
                callback();
            });

    }

    render() {
        return (
            <Formik
                initialValues={{
                    nome: this.props.orgao.nome,
                    sigla: this.props.orgao.sigla,
                    imagem: this.props.orgao.imagem
                }}
                validate={values => {
                    const errors = {};
                    if (!values.nome) {
                        errors.nome = 'Campo obrigatório';
                    }
                    if (!values.sigla) {
                        errors.sigla = 'Campo obrigatório';
                    }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    this.save(values, () => setSubmitting(false));
                }}
            >
                {({ isSubmitting, values, setFieldValue }) => (
                    <Form>
                        <FormGroup>
                            <BSForm.Label>Nome</BSForm.Label><ErrorMessage name="nome" component="span" className="text-danger small ml-2" />
                            <Field type="text" name="nome" className="form-control" required />

                        </FormGroup>
                        <FormGroup>
                            <BSForm.Label>Sigla</BSForm.Label><ErrorMessage name="sigla" component="span" className="text-danger small ml-2" />
                            <Field type="text" name="sigla" className="form-control" required />
                        </FormGroup>
                        <FormGroup>
                            <BSForm.Label className="d-block">Imagem (Dimensões 240 x 120px)</BSForm.Label>
                            {values.imagem ?
                                <Image src={values.imagem} onClick={() => setFieldValue('imagem', null)} fluid /> :
                                <AddAnexos asDataURL={true} multiple={false} icon={faImage} title="Alterar Imagem" onLoad={files => {
                                    setFieldValue('imagem', files.length ? files[0].conteudo : values.imagem);
                                }} />
                            }
                        </FormGroup>
                        <FormGroup className="text-right">
                            <Button type="button" variant='secondary' onClick={() => this.context.closeModal()}>
                                <FontAwesomeIcon icon={faTimes} />&nbsp;
                                Fechar
                            </Button>
                            <Button type="submit" disabled={isSubmitting} variant='primary' className="ml-2">
                                <FontAwesomeIcon icon={faSave} />&nbsp;
                                Gravar Alterações
                            </Button>
                        </FormGroup>
                    </Form>
                )}
            </Formik>
        );
    }
}

export default OrgaoForm;