import React from 'react';
import { AppContext } from '../../context';


class BlankPage extends React.Component { 
    
    static contextType = AppContext;
       
    render(){
        return null;
    }
}


export default BlankPage;