import { faBackward, faChartBar, faChartPie, faGlobeAmericas, faInfoCircle, faPencilAlt, faTable, faTachometerAlt, faThList, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Card, Col, Container, Image, Row, Table } from 'react-bootstrap';
import { Button} from "react-bootstrap";
import Request from "../../../request";
import moment from 'moment';
import Chart from '../../Chart';
import { AppContext } from '../../../context';
//import ReactBootstrapSlider from 'react-bootstrap-slider';

//import 'bootstrap-slider/dist/css/bootstrap-slider.css';

const formatter = new Intl.NumberFormat('pt-Br', {
    minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
});

class OperacaoDashboard extends React.Component {

    static contextType = AppContext;

    state = {
        imagens: [],
        size: 50,
        dashboard: this.props.dashboard
    }

    componentDidMount(){
        // Desabilitar
        //Promise.all(this.state.dashboard.operacao.Integrados.map(orgao => {                        
        //    return Request('GET', this.context.config.BACKEND_URL + '/orgao/imagem/'+orgao.id)
        //}))
        //.then(results => {
        //    this.setState(() => ({imagens: results.map(res => res.body)}));
        //})                
        //.catch(err => this.context.addToast({ titulo: "Erro", conteudo: "Falha na recuperação das imagens: " + err.toString() }));
    }

    onAcaoAction(action, update){
        switch(action.name){
            case "print":                
                this.context.setContent('AcaoPrint', {acao_id: action.id});
                break;
            case "edit":
                Request("GET", this.context.config.BACKEND_URL + '/acao/' + action.id, this.context.token)
                .then(res => {
                    this.context.setContent('AcaoForm', {operacao: this.state.dashboard.operacao, acao: res.body});
                })
                .catch(err => {
                    this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na recuperação do registro: " + err.toString() });
                });
                break;
            case "relatorio":
                Request("GET", this.context.config.BACKEND_URL + '/acao/relatorio/' + action.id, this.context.token)
                .then(res => {
                    this.context.setContent('AcaoRelatorio', {acao: res.body.acao, recursosDisponiveis: res.body.recursosDisponiveis});
                })
                .catch(err => {
                    this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na recuperação do registro: " + err.toString() });
                });
                break;   
            case "delete":
                if (window.confirm("Deseja realmente excluir esta ação?"))
                Request("DELETE", this.context.config.BACKEND_URL + '/acao/' + action.id, this.context.token)
                .then(res => {
                    this.context.addToast({ titulo: "Sucesso", conteudo: "Registro excluído com sucesso!"});
                    Request('GET', this.context.config.BACKEND_URL + '/operacao/dashboard/' + this.state.dashboard.operacao.id)
                    .then(res => {
                        this.context.setContent("OperacaoDashboard", {dashboard: res.body});
                    })
                    .catch(err => {
                        this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na recuperação do registro: " + err.toString() });
                    })
                    update();
                })
                .catch(err => {
                    this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na recuperação do registro: " + err.toString() });
                });
                break;   
            default:
                break;         
        }
    }

    openDashBoard(){
        Request("GET", this.context.config.BACKEND_URL + '/operacao/dashboard/' + this.props.dashboard.operacao.id, this.context.token)
        .then(res => {
            this.context.setContent('OperacaoDashboard', { dashboard: res.body });
        })
        .catch(err => {
            this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na recuperação do registro: " + err.toString() });
        });
    }

    editOperacao(){
        Request("GET", this.context.config.BACKEND_URL + '/operacao/' + this.props.dashboard.operacao.id, this.context.token)
        .then(res => {
            this.context.setContent('OperacaoForm', { operacao: res.body });
        })
        .catch(err => {
            this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na recuperação do registro: " + err.toString() });
        });
    }

    render(){
        const variants = ['primary', 'danger', 'warning', 'success', 'info'];
        return <Container fluid > 
            <h3 className="d-flex justify-content-between">
                <div>
                    <FontAwesomeIcon icon={faChartBar}/> {this.state.dashboard.operacao.nome}
                </div>
                <div>
                    <Button variant="secondary" className="mr-2" onClick={() => this.context.setContent('OperacaoList')}>
                        <FontAwesomeIcon icon={faBackward} /> Voltar para Lista de Operações
                    </Button>
                    <Button variant="info" className="mr-2" onClick={() => this.openDashBoard()}>
                        <FontAwesomeIcon icon={faTachometerAlt} /> Dashboard
                    </Button>
                    {this.context.usuario.Permissoes.some(p => p.nome === 'operacoes_operacao_update')?
                    <Button variant="primary" onClick={() => this.context.setContent('OperacaoForm', {operacao: this.state.dashboard.operacao})}>
                        <FontAwesomeIcon icon={faPencilAlt} /> Editar
                    </Button> : null}
                </div>
            </h3>
            <hr />
            <Row className="mt-4">
                <Col>
                    <Card>
                        <Card.Header>
                            <FontAwesomeIcon icon={faTable} /> Indicadores
                        </Card.Header>
                        <Card.Body>
                            <div className="d-flex justify-content-around align-items-stretch flex-wrap">
                                {this.state.size > 50 ? this.state.imagens.map((imagem, key) => 
                                    <Image key={key} src={imagem.base64} className="d-block m-3" style={{height: this.state.size}}/>
                                ) : null}
                            </div>
                            <Row className="d-flex">
                            {this.state.dashboard.totalIndicadores.map( (totalIndicador, key) => 
                                <Col md={key < (this.state.dashboard.totalIndicadores.length -  this.state.dashboard.totalIndicadores.length % 4)? 3 : 12 / (this.state.dashboard.totalIndicadores.length % 4) }>
                                    <Card key={key} bg={variants[key % variants.length]} className="text-white mb-3" style={{height: "150px"}}>
                                        <Card.Header>{totalIndicador.label}</Card.Header>
                                        <Card.Body className="d-flex align-items-end justify-content-end">
                                            {formatter.format(totalIndicador.total).length < 10 ? <h1>{formatter.format(totalIndicador.total)}</h1> : <h3>{formatter.format(totalIndicador.total)}</h3>}
                                        </Card.Body>
                                    </Card>
                                </Col>
                            )}
                            </Row>
                        </Card.Body>
                        <Card.Footer><b>Última atualização:</b> {moment(this.state.dashboard.ultimaAtualizacao).format("DD/MM/YYYY HH:mm:ss")}</Card.Footer>
                    </Card>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col>
                    <Card>
                        <Card.Header>
                            <div><FontAwesomeIcon icon={faInfoCircle} /> Informações</div>
                        </Card.Header>
                        <Card.Body>
                            <b>Status</b>
                            <ul>
                                <li>{this.state.dashboard.operacao.execucao}</li>
                            </ul>
                            <b>Início e Término</b>
                            <ul>
                                <li>{moment(this.state.dashboard.operacao.data_inicial).utcOffset(0).format("DD/MM/YYYY HH:mm")}</li>
                                <li>{moment(this.state.dashboard.operacao.data_final).utcOffset(0).format("DD/MM/YYYY HH:mm")}</li>
                            </ul>
                            <b>Resenha</b>
                            <Card bg="light" className="p-3" dangerouslySetInnerHTML={{ __html: this.state.dashboard.operacao.resenha }} />
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <Card.Header>
                            <div><FontAwesomeIcon icon={faGlobeAmericas} /> Órgãos e RISPs</div>
                        </Card.Header>
                        <Card.Body>
                            <b>Organização</b>
                            <ul>
                                <li>{this.state.dashboard.operacao.Lider.nome}</li>
                            </ul>
                            <b>Órgãos Integrados</b>
                            <ul>
                                {this.state.dashboard.operacao.Integrados.map( (orgao, key) => 
                                    <li key={key}>{orgao.nome}</li>
                                )}
                            </ul>
                            <b>RISPs</b>
                            <ul>
                                {this.state.dashboard.operacao.Risps.map( (risp, key) => 
                                    <li key={key}>
                                        {risp.nome}
                                        <ul>
                                            {this.state.dashboard.operacao.Cidades
                                            .filter(cidade => cidade.RispId === risp.id)                                            
                                            .map( (cidade, key) => 
                                                <li key={key}>{cidade.nome}</li>
                                            )}
                                        </ul>
                                    </li>
                                )}
                            </ul>                            
                        </Card.Body>
                    </Card>
                </Col>
            </Row>            
            <Row className="mt-4">
                <Col md={6}>
                    <Card>
                    <Card.Header className="d-flex justify-content-between">
                            <div><FontAwesomeIcon icon={faThList} /> Recursos</div>
                        </Card.Header>
                        <Table striped condensed>
                            <thead>
                                <tr>
                                    <th>Recurso</th>
                                    <th style={{minWidth: "1%"}}>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.dashboard.totalRecursos.map((recurso, key) => 
                                    <tr key={key}>
                                        <td>{recurso.label}</td>
                                        <td>{recurso.total}</td>
                                    </tr>
                                )}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>Total</th>
                                    <th>{this.state.dashboard.totalRecursos.reduce((sum, recurso) => sum + recurso.total, 0)}</th>
                                </tr>
                            </tfoot>
                        </Table>
                    </Card>
                </Col>
                <Col md={6}>
                    <Card>
                    <Card.Header className="d-flex justify-content-between">
                            <div><FontAwesomeIcon icon={faUsers} /> Efetivo por Órgão Participante</div>
                        </Card.Header>
                        <Table striped condensed>
                            <thead>
                                <tr>
                                    <th>Órgão</th>
                                    <th style={{minWidth: "1%"}}>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.dashboard.efetivoParticipantes.map((participante, key) => 
                                    <tr key={key}>
                                        <td>{participante.label}</td>
                                        <td>{participante.total}</td>
                                    </tr>
                                )}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>Total</th>
                                    <th>{this.state.dashboard.efetivoParticipantes.reduce((sum, participante) => sum + participante.total, 0)}</th>
                                </tr>
                            </tfoot>
                        </Table>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col md={12} className="mt-4">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div><FontAwesomeIcon icon={faChartPie} /> Efetivo por Órgão por Origem</div>
                        </Card.Header>
                        <Card.Body>
                            <Chart data={this.state.dashboard.efetivoParticipantesPorOrigem}/>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={12} className="mt-4">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div><FontAwesomeIcon icon={faChartPie} /> Indicadores por Órgão Líder</div>
                        </Card.Header>
                        <Card.Body>
                            <Chart data={this.state.dashboard.totalIndicadoresPorOrgao}/>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={12} className="mt-4">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div><FontAwesomeIcon icon={faChartPie} /> Indicadores por Risp</div>
                        </Card.Header>
                        <Card.Body>
                            <Chart data={this.state.dashboard.totalIndicadoresPorRisp}/>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={12} className="mt-4">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div><FontAwesomeIcon icon={faChartPie} /> Indicadores por Período</div>
                        </Card.Header>
                        <Card.Body>      
                            <Chart data={this.state.dashboard.totalIndicadoresPorPeriodo}/>                      
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={12} className="mt-4">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div><FontAwesomeIcon icon={faChartPie} /> Recursos por Órgão Líder</div>
                        </Card.Header>
                        <Card.Body>
                            <Chart data={this.state.dashboard.totalRecursosPorOrgao}/>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={12} className="mt-4">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div><FontAwesomeIcon icon={faChartPie} /> Recursos por Risp</div>
                        </Card.Header>
                        <Card.Body>
                            <Chart data={this.state.dashboard.totalRecursosPorRisp}/>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={12} className="mt-4">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div><FontAwesomeIcon icon={faChartPie} /> Efetivo por Risp</div>
                        </Card.Header>
                        <Card.Body>       
                            <Chart data={this.state.dashboard.efetivoParticipantesPorRisp}/>                     
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={12} className="mt-4">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div><FontAwesomeIcon icon={faChartPie} /> Recursos por Cidade</div>
                        </Card.Header>
                        <Card.Body>
                            <Chart data={this.state.dashboard.totalRecursosPorCidade}/>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={12} className="mt-4">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div><FontAwesomeIcon icon={faChartPie} /> Efetivo por Cidade</div>
                        </Card.Header>
                        <Card.Body>
                            <Chart data={this.state.dashboard.efetivoParticipantesPorCidade}/>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>;
    }
}

export default OperacaoDashboard;
