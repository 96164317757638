import React from 'react';
import { useParams } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faVoteYea } from '@fortawesome/free-solid-svg-icons';
import { Button, Col, FormControl, Row, Form as BSForm, Container, Navbar } from 'react-bootstrap';
import Request from '../../../request';

import { faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import logo from '../../../img/segup_branco.png';

import EleicoesVotarDetalhes from './EleicoesVotarDetalhes';
import { AppContext } from '../../../context';

class EleicoesVotePage extends React.Component {

    static contextType = AppContext;

    state = {
        loading: true,
        confirmed: false,
        mensagem: "",
        eleitor: {
            Eleicao: {
                Cargos: []
            }
        },
        Votos: []
    }

    votar = (cargo, chapa) => {
        this.context.openModal({
            titulo: chapa.nome_instituicao, size: "xl", conteudo: <EleicoesVotarDetalhes chapa={chapa} confirm={() => {
                this.setState(state => ({ Votos: state.Votos.map(voto => voto.CargoId === cargo.id ? { CargoId: cargo.id, ChapaId: chapa.id } : voto) }));
                this.context.closeModal();
            }} />
        })
    }

    componentDidMount() {
        Request("GET", this.context.config.BACKEND_URL + "/eleicoes/voto/" + this.props.params.uuid, this.context.token)
            .then(res => {
                this.setState({
                    eleitor: res.body,
                    Votos: res.body.Eleicao.Cargos.map(cargo => ({ CargoId: cargo.id, ChapaId: false })),
                    confirmed: true,
                    loading: false
                });
            })
            .catch((err) => {
                window.alert("Voto já registrado ou eleição não encontrada.\nVocê será redirecionado para a página principal.");
                window.location = this.context.config.FRONTEND_URL + "/eleicoes";
            });
    }

    save() {
        if (this.state.Votos.some(voto => voto.ChapaId === false)) {
            window.alert("Preencha o seu voto em todos os cargos, ou clique em Votar em Branco.");
            return;
        }
        const url = this.context.config.BACKEND_URL + '/eleicoes/voto';
        Request('POST', url, this.context.token)
            .send({ Votos: this.state.Votos, uuid: this.props.params.uuid })
            .then(res => {
                this.setState(() => ({ success: true }));
                window.alert("Seu voto foi gravado com sucesso! Você será redirecionado para a página principal.");
                window.location = this.context.config.FRONTEND_URL + "/eleicoes";
            })
            .catch(err => {
                this.setState(() => ({ success: false }));
                this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na gravação do registro: " + err.toString() });
            })
    }

    render() {

        const eleitor = this.state.eleitor;
        const eleicao = this.state.eleitor.Eleicao;

        return (

            <Container id="eleicoes">

                <Row className="bg-danger">
                    <Col md={12}>
                        <h1 className="text-white text-center m-0 px-5 py-4 font-weight-bold d-flex justify-content-between align-items-center">
                            Eleições do Sistema Estadual de Segurança Pública e Defesa Social
                            <img alt="" style={{ height: `80px` }} src={logo} />
                        </h1>
                    </Col>
                </Row>
                {(!this.state.loading && this.state.confirmed &&
                    <div>
                        <Row className="bg-light text-dark py-5">
                            <Col md={12} className="mt-3">
                                <h3>{eleicao.nome}</h3>
                            </Col>
                            <Col md={12} className="mt-3">
                                <div
                                    className="p-3"
                                    dangerouslySetInnerHTML={{ __html: eleicao.descricao }}
                                />
                            </Col>
                            <Col md={8} className="mt-3">
                                <BSForm.Label as="b">Eleitor<span className="text"></span></BSForm.Label>
                                <FormControl type="text" value={eleitor.nome} disabled />
                            </Col>
                            <Col md={4} className="mt-3">
                                <BSForm.Label as="b">CPF<span className="text"></span></BSForm.Label>
                                <FormControl type="text" value={eleitor.cpf} disabled />
                            </Col>
                        </Row>
                        {eleicao.Cargos.map((cargo, keyCargo) =>
                            <Row className={["bg-danger", "bg-primary"][keyCargo % 2] + ' p-3'} key={keyCargo}>
                                <Col className="text-center text-white" lg={4}>
                                    <h2 className="duvidas_titulo p-3 m-3  text-center font-weight-bold">
                                        {cargo.nome}
                                    </h2>
                                    <p className='text-justify'>
                                        {cargo.descricao}
                                    </p>
                                </Col>
                                <Col className="text-center justify-content-center d-flex flex-column" lg={8}>
                                    {cargo.Chapas.map((chapa, key) =>
                                        <Button className="form-control my-2"
                                            size="lg"
                                            variant={this.state.Votos[keyCargo].ChapaId === chapa.id ? "success" : `outline-light`}
                                            onClick={() => this.votar(cargo, chapa)}
                                            key={key}
                                        >
                                            Votar em {chapa.sigla_instituicao}
                                        </Button>
                                    )}
                                    <Button className="form-control my-2"
                                        size="lg"
                                        variant={this.state.Votos[keyCargo].ChapaId === null ? "light" : `outline-light`}
                                        onClick={
                                            () => this.setState(state => ({ Votos: state.Votos.map(voto => voto.CargoId === cargo.id ? { CargoId: cargo.id, ChapaId: null } : voto) }))
                                        }
                                    >
                                        Votar em Branco
                                    </Button>
                                </Col>
                            </Row>)}
                        <Row className="bg-light text-dark py-5">
                            <Col className="text-right">
                                <Button type="button" variant='danger' size="lg" onClick={() => window.location = this.context.config.FRONTEND_URL + "/eleicoes"}>
                                    <FontAwesomeIcon icon={faTimes} />&nbsp;
                                    Cancelar
                                </Button>
                                <Button type="submit" variant='primary' size="lg" className="ml-2" onClick={() => (this.save())}>
                                    <FontAwesomeIcon icon={faVoteYea} />&nbsp;
                                    Enviar Voto
                                </Button>
                            </Col>
                        </Row>
                    </div>) || (this.state.loading &&
                    <Row className="bg-light text-dark py-5">
                        <Col className="lead">
                            Aguarde ...
                        </Col>
                    </Row>) || (
                    <Row className="bg-light text-dark py-5">
                        <Col className="lead text-center">
                            Eleitor não encontrado ou votação fora do prazo para votação.
                        </Col>
                    </Row>)}
                <Row className="bg-dark text-white d-flex align-items-center">

                    <Col className="text-center py-3" lg={4}>
                        <h4>
                            Secretaria de Segurança
                            <br />
                            Pública e Defesa Social
                        </h4>
                        R. Arcipreste Manoel Teodoro, 305.
                        <br />
                        Belém - PA (91) 3184-2500
                    </Col>

                    <Col className="text-center py-3" lg={4}>
                        <h4 className="text-uppercase mb-4">Siga-nos</h4>
                        <a
                            className="btn btn-outline-light btn-social mx-1"
                            href="https://www.facebook.com/Seguppa"
                            rel="noreferrer"
                            target="_blank"
                        >
                            <FontAwesomeIcon icon={faFacebook} />
                        </a>
                        <a
                            className="btn btn-outline-light btn-social mx-1"
                            href="http://www.twitter.com/seguppara"
                            rel="noreferrer"
                            target="_blank"
                        >
                            <FontAwesomeIcon icon={faTwitter} />
                        </a>
                        <a
                            className="btn btn-outline-light btn-social mx-1"
                            href="http://www.instagram.com/segup.pa"
                            rel="noreferrer"
                            target="_blank"
                        >
                            <FontAwesomeIcon icon={faInstagram} />
                        </a>
                    </Col>
                    <Col className="text-center py-3" lg={4}>
                        <h4>
                            Eleições do Sistema Estadual de Segurança Pública e Defesa Social
                        </h4>
                    </Col>
                </Row>
                <Row className="bg-dark text-light py-1">
                    <Col className="text-center" lg={12}>
                        <small>Copyright © Ditel/SEGUP-PA 2020</small>
                    </Col>
                </Row>
                <Navbar fixed='bottom'>

                </Navbar>
            </Container>

        );
    }
}
const EleicoesVotePageWrapped = props => <EleicoesVotePage {...props} params={useParams()} />;
export default EleicoesVotePageWrapped;
