import React from 'react';
import UsuarioForm from './UsuarioForm';
import { AppContext } from '../../../context';
import ModelList from '../ModelList';

class UsuarioList extends React.Component {
    
    static contextType = AppContext;

    render(){
        return (
            <ModelList
                url={this.context.config.BACKEND_URL + '/usuario'}
                title="Lista de Usuários"
                modelIcon="faUsers"
                useAdd={this.context.usuario.Permissoes.some(p => p.nome === 'admin_usuario_create')}
                modelName="usuario"
                modelForm={UsuarioForm}
            />
        );
    }
}


export default UsuarioList;