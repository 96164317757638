import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';

function Map({center, logradouro, numero}) {
    return (
        <MapContainer
            center={center}
            zoom={16}
            style={{ height: '400px', width: '100%' }}
            interative={false}
        >
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={center}>
                <Popup>
                    <p>{`Endereço: ${logradouro}, Nº: ${numero}`}</p>
                </Popup>
            </Marker>
        </MapContainer>
    );
}

export default Map;