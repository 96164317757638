import React from 'react';
import CanalForm from './CanalForm';
import ModelList from '../ModelList';
import { AppContext } from "../../../context";


class CanalList extends React.Component { 
    
    static contextType = AppContext;

    render(){
        return (
            <ModelList 
                url={this.context.config.BACKEND_URL + '/canal'}
                title="Lista de Canais de Atendimento"
                modelName="canal"
                modelIcon="faPhoneAlt"
                modelForm={CanalForm}
                useAdd={true}
            />
        );
    }
}

export default CanalList;