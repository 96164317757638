import React from 'react';
import { AppContext } from '../../../context';

class EventosPage extends React.Component {
    static contextType = AppContext;
    render() {
        return <>
            <h1>Bem vindo ao novo modulo</h1>
        </>;
    }
}


export default EventosPage;