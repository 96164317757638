import DisqueDenunciaPage from "./DisqueDenunciaPage";
import CanalList from "./CanalList";
import CanalForm from "./CanalForm";
import NaturezaList from "./NaturezaList";
import NaturezaForm from "./NaturezaForm";
import DiligenciaList from "./DiligenciaList";
import DiligenciaForm from "./DiligenciaForm";
import CaracteristicaList from "./CaracteristicaList";
import CaracteristicaForm from "./CaracteristicaForm";
import AvaliarDenunciaList from './AvaliarDenunciaList.js'
import AvaliarDenunciaForm from "./AvaliarDenunciaForm";
import RegistrarDenunciaForm from "./RegistrarDenunciaForm";
import ScoreCard from "./graficos/ScoreCard.js";
import DashBoard from "./graficos/DashBoard.js";
import DenunciaRegistradaList from "./DenunciaRegistradaList.js"
import EncaminharDenunciaList from "./EncaminharDenunciaList.js"
import EncaminharDenunciaForm from "./EncaminharDenunciaForm.js"
import DenunciasArquivadasList from "./DenunciasArquivadasList.js"
import DenunciasArquivadasModal from "./DenunciasArquivadasModal.js"
import DenunciaApensoList from "./DenunciaApensoList.js"
import DenunciaApensoModal from "./DenunciaApensoModal.js"
import ResponderDenunciasList from "./ResponderDenunciasList.js"
import ResponderDenunciasForm from "./ResponderDenunciasForm.js";
import AcompanharDenunciasList from "./AcompanharDenunciasList.js"
import AcompanharDenunciasForm from "./AcompanharDenunciasForm.js"
import DenunciasEncaminhadasList from "./DenunciasEncaminhadasList.js";
import DenunciaPage from "./DenunciaPage.js"
import DenunciasFinalizadasForm from "./DenunciasFinalizadasForm.js";
import DenunciasFinalizadasList from "./DenunciasFinalizadasList.js";
import DenunciaHistoricoForm from "./DenunciaHistoricoForm.js";
import DenunciaHistoricoList from "./DenunciaHistoricoList.js";
import UsuarioSetorList from "./UsuarioSetorList.js";
import ObservacaoUsuarioList from "./ObservacaoUsuarioList.js";
import DenunciasRegistradasList from "./DenunciasRegistradasList.js";
import DenunciasRegistradasForm from "./DenunciasRegistradasForm.js";
import VerTodasAsDenunciasList from "./VerTodasAsDenunciasList.js";
import VerTodasAsDenunciasForm from "./VerTodasAsDenunciasForm.js";



const DisqueDenunciaContents = {
    DisqueDenunciaPage,
    DenunciaRegistradaList,
    DenunciaPage,
    NaturezaList,
    NaturezaForm,
    CanalList,
    CanalForm,
    CaracteristicaList,
    CaracteristicaForm,
    DiligenciaList,
    DiligenciaForm,
    RegistrarDenunciaForm,
    AvaliarDenunciaList,
    AvaliarDenunciaForm,
    ScoreCard,
    DashBoard,
    EncaminharDenunciaList,
    EncaminharDenunciaForm,
    DenunciasArquivadasList,
    DenunciasArquivadasModal,
    DenunciaApensoList,
    DenunciaApensoModal,
    DenunciasEncaminhadasList,
    ResponderDenunciasList,
    ResponderDenunciasForm,
    AcompanharDenunciasList,
    AcompanharDenunciasForm,
    DenunciasFinalizadasForm,
    DenunciasFinalizadasList,
    DenunciaHistoricoList,
    DenunciaHistoricoForm,
    UsuarioSetorList,
    ObservacaoUsuarioList,
    DenunciasRegistradasList,
    DenunciasRegistradasForm,
    VerTodasAsDenunciasList,
    VerTodasAsDenunciasForm
    
};

export default DisqueDenunciaContents;