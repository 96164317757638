import React from 'react';
import { AppContext } from '../../../context';
import ModelList from '../ModelList';
import ClassificacaoAlertaForm from './ClassificacaoAlertaForm';

class ClassificacaoAlertaList extends React.Component {
    static contextType = AppContext;

    render() {
        return (
            <ModelList
                url={this.context.config.BACKEND_URL + '/classificacaoAlerta'}
                title="Lista de Classificações de Alerta"
                modelIcon="faList"
                useAdd={true}
                modelName="classificacao"
                modelForm={ClassificacaoAlertaForm}
            />
        );
    }
}

export default ClassificacaoAlertaList;

