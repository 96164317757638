import PpddhPage from "./PpddhPage";
import DefensorList from "./DefensorList";
import CadastroFormPpddh from "./CadastroFormPpddh";
import TipoMilitanciaList from "./TipoMilitanciaList";
import TipoMilitanciaForm from "./TipoMilitanciaForm";

const PppddhContents = {
  PpddhPage,
  DefensorList,
  CadastroFormPpddh,
  TipoMilitanciaList,
  TipoMilitanciaForm,
};

export default PppddhContents;
