import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { Component } from 'react';
import { Button, ProgressBar } from 'react-bootstrap';
import { Base64 } from 'js-base64';
import { AppContext } from '../context';

class AddAnexos extends Component {

    static contextType = AppContext;

    uploadRef = React.createRef();

    state = {
        loading: false,
        progress: 0
    };

    loadFiles(files) {
        const total = Array.from(files).reduce((sum, file) => sum + file.size, 0);
        var uploaded = 0;

        this.setState(() => ({ loading: true, progress: 0 }));

        Promise.all(Array.from(files).map(file => {
            return new Promise((resolve, reject) => {

                const reader = new FileReader();

                reader.onload = () => {
                    resolve({
                        id: null,
                        nome: file.name + (this.props.extraInfo ? ` (${this.props.extraInfo})` : ''), // Adicionando informações extras ao nome do arquivo se a propriedade extraInfo estiver definida
                        tamanho: file.size,
                        updatedAt: moment(),
                        conteudo: this.props.asDataURL ? reader.result : (this.props.asBinaryString ? reader.result : Base64.btoa(reader.result))
                    });
                };

                var atual = 0;
                reader.onprogress = ({ loaded }) => {
                    uploaded += loaded - atual;
                    this.setState(() => ({ progress: parseInt(uploaded / total * 100) }));
                    atual = loaded;
                };

                reader.onerror = () => reject(file);

                if (this.props.asDataURL)
                    reader.readAsDataURL(file);
                else
                    reader.readAsBinaryString(file);
            });
        }))
            .then(anexos => this.props.onLoad ? this.props.onLoad(anexos) : null)
            .catch(error => this.props.onError ? this.props.onError(error) : null)
            .finally(() => this.setState(() => ({ loading: false })));
    }

    render() {
        return <div>
            {this.state.loading ?
                <ProgressBar className="mt-3" animated now={this.state.progress} /> :
                <Button type="button" className="form-control" variant="success" onClick={() => this.uploadRef.current.click()}>
                    {this.props.title ?? <><FontAwesomeIcon icon={this.props.icon ?? faPlus} /> Adicionar Anexo</>}
                </Button>
            }
            <input type="file" accept={this.props.accept} className="d-none" ref={this.uploadRef} multiple={this.props.multiple ?? true} onChange={event => (this.props.onLoad || this.props.onError) ? this.loadFiles(event.target.files, files => this.props.onLoad(files)) : null} />
        </div>
    }
}

AddAnexos.defaultProps = {
    extraInfo: '' // Define uma string vazia como valor padrão para a propriedade extraInfo
};

export default AddAnexos;
