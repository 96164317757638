import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Col, Row } from 'react-bootstrap';
import { faHome, faUserShield } from '@fortawesome/free-solid-svg-icons';
import { AppContext } from '../../../context';

class FacesPage extends React.Component {  
    
    static contextType = AppContext;

    render(){
        return (<>
            <h3><FontAwesomeIcon icon={faHome}/> Página Inicial</h3>
                <hr />
                <Card>
                    <Card.Header>
                        Principais Tarefas
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col style={{textAlign: 'center'}}>
                            <div role ="button" onClick={() => this.context.setContent("CadastroList")}><FontAwesomeIcon size='2x' icon={faUserShield}/><br/>Cadastrar<br/>Faces</div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
        </>);
    }
}

export default FacesPage;
