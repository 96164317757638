import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import MenuLeft from "./MenuLeft.js";
import MenuTop from "./MenuTop.js";
import Toasts from "./Toasts.js";
import Modal from "./Modal.js";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import NotFoundPage from "./contents/NotFoundPage.js";
import RecoverPasswordForm from "./contents/RecoverPasswordForm";
import Contents from "./contents/index.js";
import EleicoesFrontPage from "./contents/eleicoes/EleicoesFrontPage.js";
import EleicoesConfirmPage from "./contents/eleicoes/EleicoesConfirmPage.js";
import EleicaoFrontPage from "./contents/eleicoes/EleicaoFrontPage.js";
import EleicoesVotePage from "./contents/eleicoes/EleicoesVotePage.js"
import { AppContext, createContextValue, defaultValues } from "../context.js";
import ExpiredOverlay from "./ExpiredOverlay.js";
import { WrappedAlertaModal } from "./contents/alertapara/AlertaModal.js";
import PublicContratoList from "./contents/contratos/PublicContratoList.js";
import DenunciaPage from "./contents/disquedenuncia/DenunciaPage.js";


class App extends React.Component {

  static contextType = AppContext;

  state = defaultValues;

  render() {

    const contextValue = createContextValue(this.state, state => this.setState(state));

    const Conteudo = Contents[contextValue.conteudo];

    return (
      <AppContext.Provider value={contextValue}>
        <Router>
          <Switch>
            <Route exact path="/">
              <Container fluid={true} className="d-flex flex-column h-100">
                <ExpiredOverlay />
                <MenuTop />
                <Row className="flex-grow-1 flex-shrink-1 d-flex overflow-auto">
                  {contextValue.menus.length && !contextValue.fullpage ?
                    <Col lg={3} className="d-none d-lg-block h-100"><MenuLeft /></Col>
                    : null}
                  <Col lg={contextValue.menus.length > 0 && !contextValue.fullpage ? 9 : 12} className="h-100">
                    {Conteudo !== undefined ? (
                      <Conteudo {...contextValue.contentProps} />
                    ) : (
                      "Conteúdo Desconhecido: " + contextValue.conteudo
                    )}
                  </Col>
                </Row>
              </Container>
            </Route>
            <Route exact path="/denuncie" component={DenunciaPage} />
            <Route
              exact
              path="/recuperar/:id/:token"
              component={RecoverPasswordForm}
            />
            <Route exact path="/eleicoes" component={EleicoesFrontPage} />
            {/* <Route exact path="/denuncie" component={DenunciaPage} /> */}
            <Route
              exact
              path="/consulta/contrato">
              <Container fluid={true}>
                <MenuTop />
                <PublicContratoList />
              </Container>
            </Route>
            <Route
              exact
              path="/eleicoes/eleicao/:id"
              component={EleicaoFrontPage}
            />
            <Route
              exact
              path="/eleicoes/confirmar/:uuid">
              <Container fluid={true}>
                <MenuTop />
                <EleicoesConfirmPage />
              </Container>
            </Route>
            <Route
              exact
              path="/eleicoes/votar/:uuid"
              component={EleicoesVotePage}
            />
            <Route
              exact
              path="/alertapara/:uuid"
            >
              <Container fluid={true} className="h-100 d-flex flex-column">
                <MenuTop />
                <div className="flex-grow-1 mb-2">
                  <WrappedAlertaModal />
                </div>
              </Container>
            </Route>
            <Route path="*" exact={true}>
              <Container fluid={true}>
                <MenuTop />
                <NotFoundPage />
              </Container>
            </Route>
          </Switch>
          <Toasts />
          <Modal />
        </Router>
      </AppContext.Provider>
    );
  }
}

export default App;
