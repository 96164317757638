import DocumentosPage from './DocumentosPage';
import DocumentoList from './DocumentoList';
import DocumentoForm from './DocumentoForm';
import ClassificacaoDocumentoList from './ClassificacaoDocumentoList';
import ClassificacaoDocumentoForm from './ClassificacaoDocumentoForm';
import AtividadeDocumentoList from './AtividadeDocumentoList';
import AtividadeDocumentoForm from './AtividadeDocumentoForm';
import SubfuncaoDocumentoList from './SubfuncaoDocumentoList';
import SubfuncaoDocumentoForm from './SubfuncaoDocumentoForm';
import FuncaoDocumentoList from './FuncaoDocumentoList';
import FuncaoDocumentoForm from './FuncaoDocumentoForm';


const DocumentosContents = {
    DocumentosPage,
    DocumentoList,
    DocumentoForm,
    ClassificacaoDocumentoList,
    ClassificacaoDocumentoForm,
    AtividadeDocumentoList,
    AtividadeDocumentoForm,
    SubfuncaoDocumentoList,
    SubfuncaoDocumentoForm,
    FuncaoDocumentoList,
    FuncaoDocumentoForm
};

export default DocumentosContents;