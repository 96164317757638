import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button,Form, FormControl, FormGroup, FormLabel, ListGroup, ListGroupItem } from "react-bootstrap";
import moment from "moment";
import { AppContext } from "../../../context";

class AcessoForm extends React.Component {

    static contextType = AppContext;

    render(){
        
        const compact = value => {
            if (Array.isArray(value)){
                return (
                    <ListGroup>
                        {value.map((item, key) => <ListGroupItem key={key} style={{wordBreak: 'break-all'}}>{compact(item)}</ListGroupItem>)}
                    </ListGroup>
                );
            } else if (value instanceof Object){
                return (
                    <ListGroup>
                        {Object.keys(value).map((attr, key) => (
                            <ListGroupItem key={key} style={{wordBreak: 'break-all'}}>
                                <b>{attr}: </b>
                                {compact(value[attr])}
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                );
            } else {
                return value === null? "(Nulo)" : value;
            }
        };

        return (
            <Form>
                <FormGroup>
                    <FormLabel>Usuário</FormLabel>
                    <FormControl readOnly value={this.props.acesso.Usuario.nome} />
                </FormGroup>
                <FormGroup>
                    <FormLabel>Data/Hora</FormLabel>      
                    <FormControl readOnly value={moment(this.props.acesso.createdAt).format("DD/MM/YYYY HH:mm:ss")} />          
                </FormGroup>
                <FormGroup>
                    <FormLabel>Método/URL</FormLabel>
                    <FormControl readOnly value={this.props.acesso.method + " " + this.props.acesso.url} />
                </FormGroup>
                {Object.keys(this.props.acesso.body).length > 0?
                    <FormGroup>
                        <FormLabel>Requisição</FormLabel>
                        {compact(this.props.acesso.body)}
                    </FormGroup>
                : null}
                <FormGroup className="text-right">
                    <Button type="button" variant='secondary' onClick={() => this.context.closeModal()}>
                        <FontAwesomeIcon icon={faTimes} />&nbsp;
                        Fechar
                    </Button>
                </FormGroup>
            </Form>
        );
    }
}


export default AcessoForm;