import { faPencilAlt, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Button, Form as BSForm, FormGroup } from "react-bootstrap";
import Select from 'react-select';
import Request from "../../../request";
import { AppContext } from "../../../context";
import moment from 'moment';
import CustomEditor from '../../Editor';

class OperacaoForm extends React.Component {

    static contextType = AppContext;

    state = {
        orgaos: this.props.operacao.Integrados.map(
            orgao => ({value: orgao.id, label: orgao.nome})
        ).filter(option => 
            this.context.usuario.Orgao.id === option.value || 
            this.context.usuario.Permissoes.some(permissao => 
                permissao.nome === 'operacoes_orgao_global'
            )
        ),

        risps: this.props.operacao.Risps.map(
            risp => ({value: risp.id, label: risp.nome})
        ).filter(option => 
            this.context.usuario.Risps.some(risp => risp.id === option.value) || 
            this.context.usuario.Permissoes.some(permissao => 
                permissao.nome === 'operacoes_risp_global'
            )
        ),   
        
        cidades: []
    }

    componentDidMount() {
        Request('GET', this.context.config.BACKEND_URL + '/cidade/options', this.context.token).send().then(res => {
            this.setState(() => ({ cidades: res.body}));
        })
        .catch(err => this.context.addToast({ titulo: "Erro", conteudo: "Falha na recuperação das cidades: " + err.toString() }));
    }

    save(values, callback) {
        const method = this.props.acao.id ? 'PUT' : 'POST';
        const url = this.context.config.BACKEND_URL + '/acao' + (this.props.acao.id ? '/' + this.props.acao.id : '');

        Request(method, url, this.context.token)
        .send({...values, lider_id: values.LiderId})
        .then(res => {
            this.context.addToast({ titulo: "Successo", conteudo: "Registro atualizado com sucesso." });
            Request('GET', this.context.config.BACKEND_URL + '/operacao/dashboard/' + values.OperacaoId, this.context.token)
            .then(res => {
                this.context.setContent("OperacaoDashboard", {dashboard: res.body});
            })
            .catch(err => {
                this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na recuperação do registro: " + err.toString() });
            })        })
        .catch(err => {
            this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na gravação do registro: " + err.toString() });
        })
        .finally(() => {
            callback();
        });
    }

    cancel() {
        Request('GET', this.context.config.BACKEND_URL + '/operacao/dashboard/' + this.props.operacao.id, this.context.token)
        .then(res => {
            this.context.setContent("OperacaoDashboard", {dashboard: res.body});
        })
        .catch(err => {
            this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na recuperação do registro: " + err.toString() });
        });
    }

    render() {
        
        const execucoes = [
            { value: 'Agendada', label: 'Agendada' },
            { value: 'Em Execução', label: 'Em Execução' },
            { value: 'Finalizada', label: 'Finalizada' },
            { value: 'Cancelada', label: 'Cancelada' }
        ];

        return (
            <Formik
                initialValues={{
                    OperacaoId: this.props.acao.OperacaoId,
                    LiderId: this.props.acao.Lider.id,
                    RispId: this.props.acao.RispId,
                    nome: this.props.acao.nome,
                    data_inicial: moment(this.props.acao.data_inicial ?? this.props.operacao.data_inicial).utcOffset(0).format("YYYY-MM-DDTHH:mm"),
                    data_final: moment(this.props.acao.data_final ?? this.props.operacao.data_final).utcOffset(0).format("YYYY-MM-DDTHH:mm"),
                    execucao: this.props.acao.execucao,
                    resenha: this.props.acao.resenha,
                    Participantes: this.props.acao.Participantes.map(participante => participante.id),
                    Cidades: this.props.acao.Cidades.map(cidade => cidade.id),
                }}
                validateOnChange={false}
                validateOnBlur={true}
                validate={values => {
                    const errors = {};

                    if (!values.nome) {
                        errors.nome = 'Campo obrigatório';
                    }

                    if (!values.LiderId) {
                        errors.LiderId = 'Campo obrigatório';
                    }

                    if (!values.RispId) {
                        errors.RispId = 'Campo obrigatório';
                    }

                    if (!values.data_inicial) {
                        errors.data_inicial = 'Campo obrigatório';
                    }                    

                    if (!values.data_final) {
                        errors.data_final = 'Campo obrigatório';
                    }
                    
                    if (moment(values.data_final) < moment(values.data_inicial)) {
                        errors.data_inicial = 'Data final deve ser posterior à inicial';
                        errors.data_final = 'Data final deve ser posterior à inicial';
                    }

                    if (!values.execucao) {
                        errors.execucao = 'Campo obrigatório';
                    }

                    if (values.Participantes.length < 1) {
                        errors.Participantes = 'Campo obrigatório';
                    }

                    if (values.Cidades.length < 1) {
                        errors.Cidades = 'Campo obrigatório';
                    }

                    if (!values.Participantes.some(participante => participante === values.LiderId)) {
                        errors.Participantes = 'O Órgão Líder deve participar da Ação';
                    }

                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    this.save(values, () => setSubmitting(false));
                }}
            >
                {({ isSubmitting, values, setFieldValue }) => {
                    return (
                        <Form>
                            <h3 className="d-flex justify-content-between">
                                <div>
                                    <FontAwesomeIcon icon={faPencilAlt} />  Cadastro de Ação em {this.props.operacao.nome} 
                                </div>
                                <div>
                                    <Button type="button" variant='secondary' onClick={() => this.cancel()} className="mr-2">
                                        <FontAwesomeIcon icon={faTimes} />&nbsp;
                                        Cancelar
                                    </Button>
                                    <Button type="submit" disabled={isSubmitting} variant='primary'>
                                        <FontAwesomeIcon icon={faSave} />&nbsp;
                                        Gravar Alterações
                                    </Button>
                                </div>
                            </h3>
                            <hr />
                            <FormGroup>
                                <BSForm.Label as="b">Nome</BSForm.Label><ErrorMessage name="nome" component="span" className="text-danger small ml-2" />
                                <Field type="text" name="nome" className="form-control" value={values.nome} />
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Col md="4">
                                        <BSForm.Label as="b">Data Inicial<span className="date"></span></BSForm.Label><ErrorMessage name="data_inicial" component="span" className="text-danger small ml-2" />
                                        <Field type="datetime-local" name="data_inicial" className="form-control" value={values.data_inicial} min={moment(this.props.operacao.data_inicial).utcOffset(0).format("YYYY-MM-DDTHH:mm")} max={moment(values.data_final).format("YYYY-MM-DDTHH:mm")}/>
                                    </Col>
                                    <Col md="4">
                                        <BSForm.Label as="b">Data Final<span className="date"></span></BSForm.Label><ErrorMessage name="data_final" component="span" className="text-danger small ml-2" />
                                        <Field type="datetime-local" name="data_final" className="form-control" value={values.data_final} min={moment(values.data_inicial).format("YYYY-MM-DDTHH:mm")} max={moment(this.props.operacao.data_final).utcOffset(0).format("YYYY-MM-DDTHH:mm")  }/>
                                    </Col>
                                    <Col md="4">
                                        <BSForm.Label as="b">Execução</BSForm.Label><ErrorMessage name="execucao" component="span" className="text-danger small ml-2" />
                                        <Select
                                            name="execucao"
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                            menuPortalTarget={document.body}
                                            placeholder="Selecione..."
                                            options={execucoes}
                                            value={execucoes.find(execucao => execucao.value === values.execucao)}
                                            onChange={option => {
                                                setFieldValue('execucao', option.value);
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>                            
                            <FormGroup>
                                <Row>
                                    <Col md="6">
                                        <BSForm.Label as="b">Órgão Líder</BSForm.Label><ErrorMessage name="LiderId" component="span" className="text-danger small ml-2" />
                                        <Select
                                            name="LiderId"
                                            noOptionsMessage={text => text.inputValue + " não encontrado!"}
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                            menuPortalTarget={document.body}
                                            placeholder="Selecione..."
                                            options={this.state.orgaos}
                                            value={this.state.orgaos.find(option => option.value === values.LiderId)}
                                            onChange={option => {
                                                setFieldValue('LiderId', option.value);
                                            }}
                                        />
                                    </Col>
                                    <Col md="6">
                                        <BSForm.Label as="b">Região de Integração</BSForm.Label><ErrorMessage name="RispId" component="span" className="text-danger small ml-2" />
                                        <Select
                                            name="RispId"
                                            noOptionsMessage={text => text.inputValue + " não encontrado!"}
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                            menuPortalTarget={document.body}
                                            placeholder="Selecione..."
                                            options={this.state.risps}
                                            value={this.state.risps.find(option => option.value === values.RispId)}
                                            onChange={option => {
                                                setFieldValue('RispId', option.value);
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Col md="12">
                                        <BSForm.Label as="b">Cidades</BSForm.Label><ErrorMessage name="Cidades" component="span" className="text-danger small ml-2" />
                                        <Select
                                            name="Cidade"
                                            isMulti={true}
                                            noOptionsMessage={text => text.inputValue + " não encontrado!"}
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                            menuPortalTarget={document.body}
                                            placeholder="Selecione..."
                                            options={this.state.cidades.filter(option => option.risp_id === values.RispId)}
                                            value={this.state.cidades.filter(option => values.Cidades.some(cidade => cidade === option.value))}
                                            onChange={options => {
                                                setFieldValue('Cidades', options.filter(option => option.risp_id === values.RispId).map(option => option.value));
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Col md="12">
                                        <BSForm.Label as="b">Órgãos Participantes</BSForm.Label><ErrorMessage name="Participantes" component="span" className="text-danger small ml-2" />
                                        <Select
                                            name="Particpantes"
                                            isMulti={true}
                                            noOptionsMessage={text => text.inputValue + " não encontrado!"}
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                            menuPortalTarget={document.body}
                                            placeholder="Selecione..."
                                            options={this.state.orgaos}
                                            value={this.state.orgaos.filter(option => values.Participantes.some(participante => participante === option.value))}
                                            onChange={options => {
                                                setFieldValue('Participantes', options.map(option => option.value));
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Col md="12">
                                        <BSForm.Label as="b"> Resenha</BSForm.Label>
                                        <CustomEditor
                                            name="resenha"
                                            value={values.resenha}
                                            setFieldValue={setFieldValue}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>                            
                        </Form>
                    )
                }}
            </Formik >
        );
    }
}


export default OperacaoForm;