import React, { Component } from "react";
import Request from "../../../request";
import { AppContext } from "../../../context";
import { Button, Card, Col, Row, Table } from "react-bootstrap";
import {
  faCalendarAlt,
  faFlag,
  faSearch,
  faShieldAlt,
  faTable,
  faTrashAlt,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";

const formatter = new Intl.NumberFormat("pt-Br", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

class PpddhPage extends Component {
  static contextType = AppContext;
  state = {
    status: [],
    risps: [],
    filteredRisps: [],
    produtividade: [],
    municipio: [],
    quantidadePorTipo: [],
    dataInicial: "",
    dataFinal: "",
    nome: null,
    risp: null,
    grauRisco: null,
    tipos_militancia: [],
    tipo_militancia: null,
  };

  fetchData = () => {
    const errorHandler = (err) =>
      this.context.addToast({
        titulo: "Erro",
        conteudo: "Houve uma falha na pesquisa.",
      });

    let { dataInicial, dataFinal, nome, risp, grauRisco, tipo_militancia } = this.state;

    if (dataInicial && dataFinal) {
      if (dataInicial > dataFinal) {
        this.context.addToast({
          titulo: "Erro",
          conteudo: "Data inicial não pode ser maior que a data final.",
        });
      }
    }

    let url = `${this.context.config.BACKEND_URL}/defensor/dashboard`;


    if (dataInicial && dataFinal) {
      url += `/${dataInicial}/${dataFinal}`;
    } else if (dataInicial) {
      url += `/${dataInicial}/*`;
    } else if (dataFinal) {
      url += `/*/${dataFinal}`;
    }

    if (nome) {
      if (!dataInicial && !dataFinal) {
        url += `/*/*`;
      }
      url += `/${nome}`;
    }

    if (grauRisco) {
      if (!dataInicial && !dataFinal && !nome) {
        url += `/*/*/*`;
      } else if ((dataInicial || dataFinal) && !nome) {
        url += `/*`;
      }
      url += `/${grauRisco}`;
    }

    if (tipo_militancia) {
      if (!dataInicial && !dataFinal && !nome && !grauRisco) {
        url += `/*/*/*/*`;
      } else if (dataInicial && !dataFinal && !nome && !grauRisco) {
        url += `/*/*/*`;
      } else if (dataFinal && !nome && !grauRisco) {
        url += `/*/*`;
      } else if (nome && !grauRisco) {
        url += `/*`;
      }
      url += `/${tipo_militancia}`;
    }

    if (risp) {
      if (!dataInicial && !dataFinal && !nome && !grauRisco && !tipo_militancia) {
        url += `/*/*/*/*/*`;
      } else if (nome && !grauRisco && !tipo_militancia) {
        url += `/*/*`;
      } else if (grauRisco && !tipo_militancia){
        url += `/*`;
      } else if (dataInicial && !dataFinal && !nome && !grauRisco && !tipo_militancia) {
        url += `/*/*/*`;
      } else if (dataFinal && !dataInicial && !nome && !grauRisco && !tipo_militancia) {
        url += `/*/*/*`;
      }

      url += `/${risp}`;
    }

    Request("GET", url, this.context.token)
      .send()
      .then((response) => {
        this.setState({
          status: response.body.countStatus,
          filteredRisps: response.body.countRisp,
          produtividade: response.body.countRelatorio,
          municipio: response.body.countCidade,
          anexos: response.body.countQuantidadeDeAnexos,
          quantidadePorTipo: response.body.countQuantidadePorTipo,
        });
      })
      .catch(errorHandler);
  };

  

  handleFilterButtonClick = () => {
    const { dataInicial, dataFinal } = this.state;

    if (dataInicial && dataFinal) {
      this.setState(
        {
          dataInicial: this.formatarDataFinal(dataInicial),
          dataFinal: this.formatarDataFinal(dataFinal),
        },
        this.fetchData
      );
    } else if (dataFinal) {
      this.setState(
        {
          dataFinal: this.formatarDataFinal(dataFinal),
        },
        this.fetchData
      );
    } else if (dataInicial) {
      this.setState(
        {
          dataInicial: this.formatarDataFinal(dataInicial),
        },
        this.fetchData
      );
    } else {
      this.fetchData();
    }
  };

  componentDidMount() {
    const errorHandler = (err) =>
      this.context.addToast({
        titulo: "Erro",
        conteudo: "Houve uma falha na pesquisa.",
      });

    Request(
      "GET",
      this.context.config.BACKEND_URL + "/risp/options",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ risps: options.body }));
      })
      .catch(errorHandler);

    Request(
      "GET",
      this.context.config.BACKEND_URL + "/ppddh/tipo_militancia/options",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ tipos_militancia: options.body }));
      })
      .catch(errorHandler);
      this.fetchData();
  }

  formatarDataFinal(data) {
    if (typeof data === "string") {
      data = new Date(data);
    }

    if (data instanceof Date && !isNaN(data)) {
      const ano = data.getFullYear();
      const mes = String(data.getMonth() + 1).padStart(2, "0");
      const dia = String(data.getDate()).padStart(2, "0");
      const dataFormatada = `${ano}-${mes}-${dia}`;

      return dataFormatada;
    } else {
      console.error("Erro: data não é uma data válida.");
      return "";
    }
  }

  handleResetFilters = () => {
    this.setState(
      {
        dataInicial: "",
        dataFinal: "",
        nome: null,
        risp: null,
        grauRisco: null,
        tipo_militancia: null,
      },
      () => {
        this.fetchData();
      }
    );
  };

  render() {
    const { status, filteredRisps, quantidadePorTipo, municipio } = this.state;
    const variants = ["primary", "warning", "danger", "success", "info"];

    const tipoMilitanciaOptions = this.state.tipos_militancia.map((item) => ({
      value: item.id,
      label: item.descricao,
    }));


    const randomNumberInRange = (min, max) => {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    };

    return (
      <>
        <h3>
          <FontAwesomeIcon icon={faFlag} /> Dashboard
        </h3>
        <hr />
          <Row
            className="mt-1 d-flex align-items-center"
          >
            <Col className="mt-2 d-flex  align-items-center" md={6} lg={3}>
              <FontAwesomeIcon icon={faCalendarAlt} className="mr-2" />
              <span style={{ whiteSpace: "nowrap" }}>Data Inicial</span>
              <input
                type="date"
                name="dataInicial"
                className="form-control ml-2 mr-2"
                value={this.state.dataInicial}
                onChange={handleInputChange}
              />
            </Col>
            <Col className="mt-2 d-flex align-items-center" md={6} lg={3} >
              <FontAwesomeIcon icon={faCalendarAlt} className="mr-2" />
              <span style={{ whiteSpace: "nowrap" }}>Data Final</span>
              <input
                type="date"
                name="dataFinal"
                className="form-control ml-2 mr-2"
                value={this.state.dataFinal}
                onChange={handleInputChange}
              />
            </Col>   
            <Col className=" mt-2 d-flex align-items-center " md={6} lg={3}>
              <FontAwesomeIcon icon={faShieldAlt}/>
              <span className="ml-2" style={{ whiteSpace: "nowrap" }}>
                Grau de Risco
              </span>
              <Select
                className="ml-1"
                name="grauRisco"
                noOptionsMessage={(text) =>
                  `${text.inputValue} não encontrado!`
                }
                menuPortalTarget={document.body}
                placeholder="Selecione..."
                options={[
                  { value: "Alto", label: "Alto" },
                  { value: "Moderado", label: "Moderado" },
                  { value: "Baixo", label: "Baixo" },
                ]}
                onChange={(selectedOption) => {
                  const selectedRispId = selectedOption
                    ? selectedOption.value
                    : null;
                  this.setState({ grauRisco: selectedRispId });
                }}
                styles={{
                  container: (provided) => ({
                    ...provided,
                    width: '100%',
                  }),
                }}
                
              />
            </Col>
            <Col className=" mt-2 d-flex align-items-center " md={6} lg={3}>
              <FontAwesomeIcon icon={faUsers}/>
              <span className="ml-2" style={{ whiteSpace: "nowrap" }}>
                Tipo de Militância
              </span>
              <Select
                className="ml-1"
                name="tipo_militancia"
                noOptionsMessage={(text) =>
                  `${text.inputValue} não encontrado!`
                }
                menuPortalTarget={document.body}
                placeholder="Selecione..."
                options={tipoMilitanciaOptions}
                onChange={(selectedOption) => {
                  const selectedMilitanciaId = selectedOption
                    ? selectedOption.value
                    : null;
                  this.setState({ tipo_militancia: selectedMilitanciaId });
                }}
                styles={{
                  container: (provided) => ({
                    ...provided,
                    width: '100%',
                  }),
                }}
              />
            </Col>
            <Col className=" mt-2 d-flex align-items-center " md={6} lg={3}>
              <FontAwesomeIcon icon={faUsers} className="mr-2" />
              <span style={{ whiteSpace: "nowrap" }}>Nome</span>
              <input
                type="text"
                name="nome"
                className="form-control ml-2 mr-2"
                style={{ width: "100%" }}
                value={this.state.nome}
                onChange={handleInputChange}
                placeholder="Nome do defensor"
              />
            </Col>
            <Col className="mt-2 d-flex align-items-center " md={6} lg={3}>
              <FontAwesomeIcon icon={faShieldAlt}/>
              <span className="ml-2" style={{ whiteSpace: "nowrap" }}>
                RISP
              </span>
              <Select
                className="ml-1"
                name="risp"
                noOptionsMessage={(text) =>
                  `${text.inputValue} não encontrado!`
                }
                menuPortalTarget={document.body}
                placeholder="Selecione..."
                options={this.state.risps}
                onChange={(selectedOption) => {
                  const selectedRispId = selectedOption
                    ? selectedOption.value
                    : null;
                  this.setState({ risp: selectedRispId });
                }}
                styles={{
                  container: (provided) => ({
                    ...provided,
                    width: '100%',
                  }),
                }}
              />
            </Col>
            <Col className="d-flex align-items-center "  md={6} lg={3}>
              <Button
                className="mt-2"
                variant="warning"
                onClick={this.handleResetFilters}
                style={{ width: "100%" }}
              >
                <FontAwesomeIcon icon={faTrashAlt} /> Resetar filtros{" "}
              </Button>
            </Col>
            <Col className="d-flex align-items-center " md={6} lg={3}>
              <Button
                className="mt-2"
                variant="success"
                onClick={this.handleFilterButtonClick}
                style={{ width: "100%" }}
              >
                <FontAwesomeIcon icon={faSearch} /> Filtrar{" "}
              </Button>
            </Col>
          </Row>
        <hr />
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <FontAwesomeIcon icon={faTable} /> Defensor por RISP
              </Card.Header>
              <Card.Body>
                <Row className="d-flex">
                  {filteredRisps.map((risp, index) => (
                    <Col
                      md={
                        risp.count <
                        filteredRisps.length - (filteredRisps.length % 4)
                          ? 3
                          : 12 / (filteredRisps.length % 4)
                      }
                    >
                      <Card
                        key={index}
                        bg={variants[randomNumberInRange(0, 4)]}
                        className="text-white mb-3"
                        style={{ height: "150px" }}
                      >
                        <Card.Header>{risp.nome}</Card.Header>
                        <Card.Body className="d-flex align-items-end justify-content-end">
                          {formatter.format(filteredRisps).length < 10 ? (
                            <h1>{formatter.format(risp.count)}</h1>
                          ) : (
                            <h3>{formatter.format(risp.count)}</h3>
                          )}
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <FontAwesomeIcon icon={faTable} /> Defensor por STATUS
              </Card.Header>
              <Card.Body>
                <Row className="d-flex">
                  {status.map((status, index) => (
                    <Col md={4}>
                      <Card
                        key={index}
                        bg={variants[randomNumberInRange(0, 4)]}
                        className="text-white mb-3"
                        style={{ height: "150px" }}
                      >
                        <Card.Header>{status.status}</Card.Header>
                        <Card.Body className="d-flex align-items-end justify-content-end">
                          <h1>{status.count}</h1>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <FontAwesomeIcon icon={faTable} /> Produtividade
              </Card.Header>
              <Card.Body>
                <Row className="d-flex">
                  {quantidadePorTipo.map((anexo, index) => (
                    <Col md={4} key={index}>
                      <Card
                        bg={variants[randomNumberInRange(0, 4)]}
                        className="text-white mb-3"
                        style={{ height: "150px" }}
                      >
                        <Card.Header>{anexo.tipo}</Card.Header>
                        <Card.Body className="d-flex align-items-end justify-content-end">
                          <h1>{anexo.totalQuantidade}</h1>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div>
                  <FontAwesomeIcon icon={faUsers} /> Defensor por município
                </div>
              </Card.Header>
              <Table striped bordered>
                <thead>
                  <tr>
                    <th>Município</th>
                    <th style={{ minWidth: "1%" }}>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {municipio.map((municipio, key) => (
                    <tr key={key}>
                      <td>{municipio.nome}</td>
                      <td>{municipio.count}</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <th>Total</th>
                    <th>
                      {municipio.reduce(
                        (sum, municipio) => sum + municipio.count,
                        0
                      )}
                    </th>
                  </tr>
                </tfoot>
              </Table>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default PpddhPage;
