import React from 'react';
import { AppContext } from '../../../context';

class DocumentosPage extends React.Component {
    
    static contextType = AppContext;

    render() {
        return null;
    }
}

export default DocumentosPage;