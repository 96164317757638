import React from 'react';
import ModelList from '../ModelList';
import { AppContext } from '../../../context';
import AreaForm from './AreaForm';

class AreaList extends React.Component {    

    static contextType = AppContext;

    render(){
        return (
            <ModelList 
                url={this.context.config.BACKEND_URL + '/proMulher/area'}
                title="Lista de Áreas"
                modelName="area"
                modelIcon="faThList"
                modelForm={AreaForm}
                useAdd={true}
            />
        );
    }
}


export default AreaList;