import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTicketAlt } from "@fortawesome/free-solid-svg-icons";
import Datatable from "../../Datatable";
import CautelaForm from "./CautelaForm";
import Request from '../../../request';
import { AppContext } from "../../../context";

class CautelaList extends React.Component {

    static contextType = AppContext;

    onAction(action, callback) {
        switch (action.name) {
            case "edit":
                Request('GET', this.context.config.BACKEND_URL + '/cautela/' + action.id, this.context.token)
                    .then(res => {
                        this.context.openModal({ titulo: "Devolução de Cautela", conteudo: <CautelaForm cautela={res.body} onSave={() => callback()} /> });
                    })
                    .catch(err => {
                        this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na recuperação do registro: " + err.toString() });
                    });
                break;
            case "print":
                Request('GET', this.context.config.BACKEND_URL + '/cautela/' + action.id, this.context.token)
                    .then(res => {
                        this.context.setContent("CautelaPrint", { cautela: res.body });
                    })
                    .catch(err => {
                        this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na recuperação do registro: " + err.toString() });
                    });
                break;
            case "delete":
                if (window.confirm("Deseja realmente excluir este registro?")) {
                    Request('DELETE', this.context.config.BACKEND_URL + '/cautela/' + action.id, this.context.token)
                        .then(res => {
                            callback();
                        })
                        .catch(err => {
                            this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na exclusão do registro: " + err.toString() });
                        });
                }
                break;
            default:
                break;
        }
    }

    render() {
        return (<>
            <h3><FontAwesomeIcon icon={faTicketAlt} /> Lista de Cautelas</h3>
            <hr />
            <Datatable
                url={this.context.config.BACKEND_URL + '/cautela'}
                useAdd={true}
                onError={(err) => this.context.addToast({ titulo: "Erro", conteudo: err.toString() })}
                onAction={(action, update) => this.onAction(action, update)}
                onClickAdd={update => this.context.openModal({ titulo: 'Adicionar', conteudo: <CautelaForm cautela={{}} onSave={() => update()} /> })}
            />
        </>);
    }
}

export default CautelaList;
