import React from 'react';
import RazaoForm from './RazaoForm';
import ModelList from '../ModelList';
import { AppContext } from '../../../context';

class RazaoList extends React.Component {  
    
    static contextType = AppContext;

    render(){
        return (
            <ModelList 
                url={this.context.config.BACKEND_URL + '/razao'}
                title="Lista de Razões de Declaração"
                modelName="razao"
                modelIcon="faCheck"
                modelForm={RazaoForm}
                useAdd={true}
            />
        );
    }
}

export default RazaoList;

